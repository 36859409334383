export const TOKENS = {
    17000: [
        {
            "chainId": 17000,
            "address": "0",
            "name": "Ethereum",
            "symbol": "ETH",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880"
        },         
              
    ],
    11155111: [
      {
          "chainId": 11155111,
          "address": "0",
          "name": "Ethereum",
          "symbol": "ETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880"
      } 
            
  ],
    97: [
        {
            "chainId": 97,
            "address": "0",
            "name": "Binance Token",
            "symbol": "BNB",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/825/thumb/bnb-icon2_2x.png?1644979850"
        },
        {
            "chainId": 97,
            "address": "0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
            "name": "Binance USD",
            "symbol": "BUSD",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766"
        },
        {
            "chainId": 97,
            "address": "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06",
            "name": "Chainlink",
            "symbol": "LINK",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700"
        },
    ],
    56: [
        {
            "chainId": 56,
            "address": "0",
            "name": "BNB",
            "symbol": "BNB",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/825/thumb/bnb-icon2_2x.png?1644979850"
          },
        {
          "chainId": 56,
          "address": "0xbe0d3526fc797583dada3f30bc390013062a048b",
          "name": "PLEARN",
          "symbol": "PLN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24723/thumb/plearn200x200.png?1648697974"
        },
        {
          "chainId": 56,
          "address": "0x1beac6df550be0ad146dd99b4726c6bec9c5c6a5",
          "name": "Piggy",
          "symbol": "PIGGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18869/thumb/200x200_piggy.png?1633654942"
        },
        {
          "chainId": 56,
          "address": "0x6421531af54c7b14ea805719035ebf1e3661c44a",
          "name": "BEP20 LEO",
          "symbol": "BLEO",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/18764/thumb/2bP4pJr4wVimqCWjYimXJe2cnCgnAD5Au3rAU7JkDc2.png?1633356467"
        },
        {
          "chainId": 56,
          "address": "0xa7aea81bb187bde731019ee0ca665c751179c102",
          "name": "MetaPlanet AI",
          "symbol": "MPLAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29269/thumb/MPLAI.jpg?1677728075"
        },
        {
          "chainId": 56,
          "address": "0xb6b8ccd230bb4235c7b87986274e7ab550b72261",
          "name": "HALOnft art",
          "symbol": "HALO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29325/thumb/halo.png?1678155531"
        },
        {
          "chainId": 56,
          "address": "0x3843f234b35a311e195608d32283a68284b3c44d",
          "name": "La Peseta  OLD ",
          "symbol": "PTA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21645/thumb/VB_hrqI7_400x400.jpg?1639636015"
        },
        {
          "chainId": 56,
          "address": "0xc98a06e7362789f57c8861e8f33f2ebe3d32eed1",
          "name": "Pitquidity Capital",
          "symbol": "PITQC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27603/thumb/PITQD_LOGO_TWITER.png?1664779799"
        },
        {
          "chainId": 56,
          "address": "0x06dc293c250e2fb2416a4276d291803fc74fb9b5",
          "name": "The Kingdom Coin",
          "symbol": "TKC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30098/thumb/44-DF930-C-7-E3-D-400-A-BDEA-7-F0185-DBFE7-C.jpg?1683026009"
        },
        {
          "chainId": 56,
          "address": "0xa94fb437b8bacb591c6b828bef5a837afe542100",
          "name": "NETZERO",
          "symbol": "NZERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27676/thumb/Untitled_design_%281%29.png?1665211424"
        },
        {
          "chainId": 56,
          "address": "0xd8a2ae43fd061d24acd538e3866ffc2c05151b53",
          "name": "AirCoin",
          "symbol": "AIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16878/thumb/logo200.png?1625755457"
        },
        {
          "chainId": 56,
          "address": "0x83b27de2fca046fa63a11c7ce7743de33ec58822",
          "name": "BUILD",
          "symbol": "BUILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842"
        },
        {
          "chainId": 56,
          "address": "0x0a94ea47de185d6376db4cad70123ec8de4f2841",
          "name": "Synaptic AI",
          "symbol": "SYNAPTICAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29260/thumb/SynapticAI_logo.png?1677656699"
        },
        {
          "chainId": 56,
          "address": "0xbd6ceeef56985b608252c3651dd903a3fcc34910",
          "name": "Twelve Zodiac",
          "symbol": "TWELVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30418/thumb/Twelve_Zodiac.png?1684391416"
        },
        {
          "chainId": 56,
          "address": "0x865a0e15ac5c309e7ea61410cebdea1df686dbb2",
          "name": "DexWallet",
          "symbol": "DWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28504/thumb/IMG_20221214_221815_932.png?1671164411"
        },
        {
          "chainId": 56,
          "address": "0xfc2037829f497ff68cc62771270ed1f409668439",
          "name": "REDMARS",
          "symbol": "RMARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23980/thumb/rmars.png?1645878084"
        },
        {
          "chainId": 56,
          "address": "0x4ccf2e74b29cd6ab63f0125e0e78832e3571261c",
          "name": "Blithe",
          "symbol": "BLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28318/thumb/20221109-200354.png?1669363172"
        },
        {
          "chainId": 56,
          "address": "0xe37f5e9c1e8199bda350243aaa50076959ea13d2",
          "name": "MegaShibaZilla",
          "symbol": "MSZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19941/thumb/msb.png?1636335773"
        },
        {
          "chainId": 56,
          "address": "0xe3e3f8218562a7c9b594bef2946ec72f1b043ae8",
          "name": "Kyberdyne",
          "symbol": "KBD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24003/thumb/mrSWHBjQ_400x400.jpg?1646026119"
        },
        {
          "chainId": 56,
          "address": "0x77140a6f53c09c36abf10ef947655317a7670a3b",
          "name": "CoinMatch AI",
          "symbol": "CMAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29476/thumb/CoinMatch.jpg?1679130116"
        },
        {
          "chainId": 56,
          "address": "0xbb0ae29392ee663b89c7c91429513ad8de32e371",
          "name": "xYSL",
          "symbol": "XYSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18954/thumb/xYSL_Light_Mode.png?1685444332"
        },
        {
          "chainId": 56,
          "address": "0x3220ccbbc29d727bde85b7333d31b21e0d6bc6f4",
          "name": "Pup Doge",
          "symbol": "PUPDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17473/thumb/pupdoge.PNG?1627894702"
        },
        {
          "chainId": 56,
          "address": "0xb72ba371c900aa68bb9fa473e93cfbe212030fcb",
          "name": "Pandora Spirit",
          "symbol": "PSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25871/thumb/171621938-58e12d04-f26e-43f2-9ec3-8c352690defb.png?1654241031"
        },
        {
          "chainId": 56,
          "address": "0x098a138fd939ae6bde61deb9ab82c838e85d98e3",
          "name": "Billionaires Pixel Club",
          "symbol": "BPC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29795/thumb/IMG_20230412_023811_990.jpg?1681347152"
        },
        {
          "chainId": 56,
          "address": "0x3c2e501b08cf5c16061468c96b19b32bae451da3",
          "name": "EverReflect",
          "symbol": "EVRF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22903/thumb/evrf.png?1642935322"
        },
        {
          "chainId": 56,
          "address": "0x09d975c3351dbded28617517fc6982284a787f03",
          "name": "Crypto Island",
          "symbol": "CISLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17897/thumb/200x200_CISLA.png?1629769640"
        },
        {
          "chainId": 56,
          "address": "0x5e7fc3844463745fca858f85d6b90d9a03fcbe93",
          "name": "Safe Nebula",
          "symbol": "SNB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/16944/thumb/SNB-Logo-400x400.png?1625719831"
        },
        {
          "chainId": 56,
          "address": "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
          "name": "TRON  BSC ",
          "symbol": "TRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15468/thumb/NxLfA-LK_400x400.png?1620915726"
        },
        {
          "chainId": 56,
          "address": "0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d",
          "name": "Venus XVS",
          "symbol": "VXVS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13903/thumb/xvs_logo.png?1613018688"
        },
        {
          "chainId": 56,
          "address": "0x557233e794d1a5fbcc6d26dca49147379ea5073c",
          "name": "Alita",
          "symbol": "ALI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17750/thumb/Logo-200x200_%286%29.png?1629173982"
        },
        {
          "chainId": 56,
          "address": "0x64ac7203e106448ac2ec5e05d5045f5b02104e4d",
          "name": "AstroAI",
          "symbol": "ASTROAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29443/thumb/Untitled_design_%2825%29.png?1678866871"
        },
        {
          "chainId": 56,
          "address": "0xe6884e29ffe5c6f68f4958cf201b0e308f982ac9",
          "name": "Vegasino",
          "symbol": "VEGAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25905/thumb/third_about_img.png?1654502494"
        },
        {
          "chainId": 56,
          "address": "0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f",
          "name": "BuffSwap",
          "symbol": "BUFFS",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/21189/thumb/buffsw.PNG?1638515236"
        },
        {
          "chainId": 56,
          "address": "0x9d9fa9dbae391c3fb6866f43de62ff3b393133b2",
          "name": "ProjectX",
          "symbol": "PROX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28708/thumb/prox200b.png?1673487303"
        },
        {
          "chainId": 56,
          "address": "0x91f006ee672f8f39c6e63ca75b1ca14067b3c366",
          "name": "Outrace",
          "symbol": "ORE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20951/thumb/ppLk7GCl_400x400.jpg?1638137567"
        },
        {
          "chainId": 56,
          "address": "0x37ac5f3bfd18a164fc6cf0f0f0ecd334d9179d57",
          "name": "Vulture Peak",
          "symbol": "VPK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23729/thumb/vulture_logo_final-05.png?1645169060"
        },
        {
          "chainId": 56,
          "address": "0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176",
          "name": "Venus BCH",
          "symbol": "VBCH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13922/thumb/vbch.png?1612845455"
        },
        {
          "chainId": 56,
          "address": "0x768d221e81524de52841aed976370b2e4f990416",
          "name": "Moon Maker Protocol",
          "symbol": "MMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16257/thumb/m_mp.PNG?1623401736"
        },
        {
          "chainId": 56,
          "address": "0x198271b868dae875bfea6e6e4045cdda5d6b9829",
          "name": "Animal Farm Dogs",
          "symbol": "AFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28589/thumb/dogg.765111d9.png?1672135724"
        },
        {
          "chainId": 56,
          "address": "0xa6472bc7c0e2266034bb40edd8c6e8961cf45826",
          "name": "TourismX",
          "symbol": "TRMX",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/28164/thumb/trmx.png?1668170021"
        },
        {
          "chainId": 56,
          "address": "0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90",
          "name": "WaultSwap",
          "symbol": "WEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14882/thumb/wex_v3.png?1663867586"
        },
        {
          "chainId": 56,
          "address": "0x9131066022b909c65edd1aaf7ff213dacf4e86d0",
          "name": "Meta Utopia",
          "symbol": "LAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24531/thumb/META-UTOPIA__logo-02.png?1661489980"
        },
        {
          "chainId": 56,
          "address": "0x8abfa6a4f4b9865b0e7acfdce1839a2584636d06",
          "name": "MAGIKAL ai",
          "symbol": "MGKL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29584/thumb/magikal.png?1679883085"
        },
        {
          "chainId": 56,
          "address": "0xdf0816cc717216c8b0863af8d4f0fc20bc65d643",
          "name": "SHIBA BSC",
          "symbol": "SHIBSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15800/thumb/shibsc.png?1621929146"
        },
        {
          "chainId": 56,
          "address": "0x9776191f4ebbba7f358c1663bf82c0a0906c77fa",
          "name": "Phoenix Chain",
          "symbol": "PHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29480/thumb/PHOENlX_%281%29_%281%29_%281%29.png?1680845163"
        },
        {
          "chainId": 56,
          "address": "0x5335e87930b410b8c5bb4d43c3360aca15ec0c8c",
          "name": "Overnight fi USDT ",
          "symbol": "USDT+",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30168/thumb/USDT_.png?1683533091"
        },
        {
          "chainId": 56,
          "address": "0x6d64010596f7ec3b40b40223a5f847a1b243fd99",
          "name": "Digital Trip Advisor",
          "symbol": "DTA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29712/thumb/dta-logo.png?1680767664"
        },
        {
          "chainId": 56,
          "address": "0xc0994af94fee0361a1e1e1ccf72bce19d5fd86fb",
          "name": "RichCity",
          "symbol": "RICH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16985/thumb/Richcity.png?1625909904"
        },
        {
          "chainId": 56,
          "address": "0xf3be1a4a47576208c1592cc027087ce154b00672",
          "name": "Xillion",
          "symbol": "XIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18247/thumb/projectx.PNG?1631134123"
        },
        {
          "chainId": 56,
          "address": "0xa5fbc3520dd4bb85fcd175e1e3b994546a2c1ee8",
          "name": "gotEM",
          "symbol": "GOTEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19108/thumb/logo-transparent-bg.png?1634510489"
        },
        {
          "chainId": 56,
          "address": "0xcfa784a3e9e7e9c88a845ab9afa8f3b95fcdf5d0",
          "name": "SHREE",
          "symbol": "SHR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26457/thumb/Cgupkjb__400x400.jpeg?1658129310"
        },
        {
          "chainId": 56,
          "address": "0xe6df015f66653ece085a5fbba8d42c356114ce4f",
          "name": "Pyrrho",
          "symbol": "PYO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22423/thumb/pyo.png?1659791372"
        },
        {
          "chainId": 56,
          "address": "0xf84fd783e7c38a3c99030535919fb89350387502",
          "name": "Upfront Protocol",
          "symbol": "UP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30559/thumb/IMG_20230524_134842_946.jpg?1685016080"
        },
        {
          "chainId": 56,
          "address": "0xc0cc1e5761ba5786916fd055562551798e50d573",
          "name": "ASYAGRO",
          "symbol": "ASY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10632/thumb/c0Q4z8HJ_400x400.jpg?1581203764"
        },
        {
          "chainId": 56,
          "address": "0x45d486479ed1db8b164d1386b3c89c05cee8ca86",
          "name": "YSL USDy",
          "symbol": "USDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30489/thumb/USDy_Light_Mode.png?1684718190"
        },
        {
          "chainId": 56,
          "address": "0x75b2fdd95418e093fca7db858b36549e5e412076",
          "name": "Belifex",
          "symbol": "BEFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9547/thumb/belifex.png?1586748867"
        },
        {
          "chainId": 56,
          "address": "0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4",
          "name": "StableXSwap",
          "symbol": "STAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12967/thumb/photo_2021-01-12_00.19.13.jpeg?1610381968"
        },
        {
          "chainId": 56,
          "address": "0xf3ed4770e6efe9168c3f2f50a6d9d0f97a550df1",
          "name": "DKEY Bank",
          "symbol": "DKEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20212/thumb/dkey.PNG?1636669147"
        },
        {
          "chainId": 56,
          "address": "0xe0399378f7a92a39da849eb64cddde2940e234bb",
          "name": "ZionTopia",
          "symbol": "ZION",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25902/thumb/logo.png?1654501700"
        },
        {
          "chainId": 56,
          "address": "0x77f2be773ca0887ba2b3ef8344c8cf13c98d8ca7",
          "name": "FloraChain",
          "symbol": "FYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29078/thumb/flora.png?1676438723"
        },
        {
          "chainId": 56,
          "address": "0x2b1b730c997d81db2e792b47d0bc42a64ee6aa55",
          "name": "Edufex",
          "symbol": "EDUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18365/thumb/edufex.PNG?1631665623"
        },
        {
          "chainId": 56,
          "address": "0x759bd4ed07a34b9ea761f8f2ed9f0e102675a29c",
          "name": "Have Fun Token",
          "symbol": "HF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30257/thumb/0XUodgfR_400x400.jpg?1683771703"
        },
        {
          "chainId": 56,
          "address": "0xe5431bcf8769fcc7f2f3dbdd93300b9696747c4c",
          "name": "Gold Guaranteed Coin",
          "symbol": "GGCM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26265/thumb/200x200.png?1656983771"
        },
        {
          "chainId": 56,
          "address": "0xe0167c41bea56432f8588a4ceff0f5f3642120e7",
          "name": "Viking Elon",
          "symbol": "VELON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25346/thumb/Viking_Elon_200.png?1651303559"
        },
        {
          "chainId": 56,
          "address": "0xd3c325848d7c6e29b574cb0789998b2ff901f17e",
          "name": "OneArt",
          "symbol": "1ART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19307/thumb/token_light_3x.png?1642936901"
        },
        {
          "chainId": 56,
          "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
          "name": "PancakeSwap",
          "symbol": "CAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12632/thumb/pancakeswap-cake-logo_%281%29.png?1629359065"
        },
        {
          "chainId": 56,
          "address": "0xbe2d8ac2a370972c4328bed520b224c3903a4941",
          "name": "Novem Pro",
          "symbol": "NVM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24804/thumb/photo_5972293329968282493_x.jpg?1666952119"
        },
        {
          "chainId": 56,
          "address": "0x7c1b2f618569789941b88680966333f3e8fedc61",
          "name": "XPROJECT",
          "symbol": "XPRO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26688/thumb/logo200x200circle.png?1659601728"
        },
        {
          "chainId": 56,
          "address": "0x31b79e34b32239f0c3604db4a451ce9256b92919",
          "name": "Baby Aptos",
          "symbol": "BAPTOS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29883/thumb/baptos_new_200.png?1682405844"
        },
        {
          "chainId": 56,
          "address": "0x4d9f39f7cb7c7444335077223ceef33dbb58096f",
          "name": "Cradle of Sins",
          "symbol": "COS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30007/thumb/200x200_Cos.png?1682484690"
        },
        {
          "chainId": 56,
          "address": "0x459fab6be3b07558e28fecb07b64a481d0e8c6a3",
          "name": "FlokiTer",
          "symbol": "FAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29568/thumb/FlokiTerOfficialLogo-removebg.png?1679737276"
        },
        {
          "chainId": 56,
          "address": "0x3aa6b9a5306d1cd48b0466cfb130b08a70257e12",
          "name": "Gorilla Finance",
          "symbol": "GORILLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29567/thumb/IMG_20230322_210428_858.png?1679736987"
        },
        {
          "chainId": 56,
          "address": "0xefd1c4bc2d22639ea86b70e249eec0ccabf54f06",
          "name": "Plug Power AI",
          "symbol": "PPAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30420/thumb/Logo-plug-power-AI-crypto-project-bsc-bnb-200x200-0xeFd1C4bC2D22639Ea86B70e249EEc0ccabf54f06.png?1684391754"
        },
        {
          "chainId": 56,
          "address": "0x6dc923900b3000bd074d1fea072839d51c76e70e",
          "name": "RIFI United",
          "symbol": "RU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20539/thumb/N7d6LRy.png?1637203621"
        },
        {
          "chainId": 56,
          "address": "0xdfb3c678c32b9a547b3802d4c2f7cb063bdfe9c9",
          "name": "MerryChristmas",
          "symbol": "HOHOHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29263/thumb/merrychristmas.jpg?1677659566"
        },
        {
          "chainId": 56,
          "address": "0xbb95cc1c662d89822bda29d2e147b124406e6e42",
          "name": "Terran Coin",
          "symbol": "TRR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066"
        },
        {
          "chainId": 56,
          "address": "0x3244b3b6030f374bafa5f8f80ec2f06aaf104b64",
          "name": "HeroFi ROFI",
          "symbol": "ROFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21296/thumb/herofi_logo.jpg?1638875897"
        },
        {
          "chainId": 56,
          "address": "0xfb60584f305b3fcf869d4ae48e082b2381fabde8",
          "name": "FreeBnk",
          "symbol": "FRBK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30681/thumb/Diagram_-_FreeBnk___Brand___Marketing_3.jpg?1686272244"
        },
        {
          "chainId": 56,
          "address": "0x6d1a4650e83708b583c35d5e0952a0b46354ca9b",
          "name": "Fidance",
          "symbol": "FDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27244/thumb/FDC_LOGO.jpg?1662883259"
        },
        {
          "chainId": 56,
          "address": "0x94df6e5bc05b6eb9eb65c918902f6f4b8edd8833",
          "name": "DavidCoin",
          "symbol": "DC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24964/thumb/dc.png?1649583985"
        },
        {
          "chainId": 56,
          "address": "0xbf27da33a58de2bc6eb1c7dab6cf2e84e825d7dc",
          "name": "ZambesiGold",
          "symbol": "ZGD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25544/thumb/lD1w22TUaUn2AkWmkgI5WWSufl5kZ-1Gu3s-g-2A9qZ-DyW5g9UrrBRaOxKp5fcLEghB2VR-XBxw-4mu4M76SjH3YDCvh27CH4RTfKAbtlrEWHNP7oHN9Y6eBrqwfGu_-M0SAJf-dqDRnMNLWIxP8hKtAxaIFR0_JmxdInlMpBJa2WONniOHCeGomlyYeGfBKa4bYytvpIKDwZNBC-b_-enFwb.jpg?1652326768"
        },
        {
          "chainId": 56,
          "address": "0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51",
          "name": "Pancake Bunny",
          "symbol": "BUNNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13148/thumb/wallet-logo-bunny.png?1621840208"
        },
        {
          "chainId": 56,
          "address": "0xfbc4f3f645c4003a2e4f4e9b51077d2daa9a9341",
          "name": "Zedxion",
          "symbol": "ZEDXION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png?1633585913"
        },
        {
          "chainId": 56,
          "address": "0x0627e7ee0d14fcdd2ff30d1563aebdbccec678be",
          "name": "JackpotDoge",
          "symbol": "JPD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28001/thumb/62caad33f3c5f30014065a1d-image-p-1660157227220-File-name.png?1666927257"
        },
        {
          "chainId": 56,
          "address": "0xd1ecfdd45c49057479c4ca95e045855370bc4f5b",
          "name": "Griffin Art",
          "symbol": "GART",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17504/thumb/gart.png?1647695407"
        },
        {
          "chainId": 56,
          "address": "0x405be90996e7f995a08c2fbd8d8822ef5b03466c",
          "name": "Jetset",
          "symbol": "JTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25295/thumb/lAsTdP6-_400x400.jpg?1651147535"
        },
        {
          "chainId": 56,
          "address": "0x5d5c5c1d14faf8ff704295b2f502daa9d06799a0",
          "name": "Novem Gold",
          "symbol": "NNN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11852/thumb/nnn.png?1648987249"
        },
        {
          "chainId": 56,
          "address": "0xac55b04af8e9067d6c53785b34113e99e10c2a11",
          "name": "Doom Hero Dao",
          "symbol": "DHD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22234/thumb/dhd.PNG?1641249359"
        },
        {
          "chainId": 56,
          "address": "0xb139ed26b743c7254a246cf91eb594d097238524",
          "name": "FALCONS",
          "symbol": "FALCONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19164/thumb/FalconSwapsLogo.png?1678163675"
        },
        {
          "chainId": 56,
          "address": "0x30dcf96a8a0c742aa1f534fac79e99d320c97901",
          "name": "Sourceless",
          "symbol": "STR",
          "decimals": 13,
          "logoURI": "https://assets.coingecko.com/coins/images/25931/thumb/https___s3.us-east-2.amazonaws.com_nomics-api_static_images_currencies_STR6.png?1654747973"
        },
        {
          "chainId": 56,
          "address": "0x934c9198582bf2631128c5d4b051aacef9a6224f",
          "name": "Wanaka Farm WAIRERE",
          "symbol": "WAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19591/thumb/wai.png?1635473878"
        },
        {
          "chainId": 56,
          "address": "0x626cab57051e80f4b84551856588b62983bdb94e",
          "name": "RabbitKing",
          "symbol": "RB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28994/thumb/rabbit.JPG?1676107094"
        },
        {
          "chainId": 56,
          "address": "0x73bc158e84873888cfc8b617524eebb1cfce8d4e",
          "name": "Twelve Legions",
          "symbol": "CTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21282/thumb/eEuyEtJ.png?1638863963"
        },
        {
          "chainId": 56,
          "address": "0x4ba0057f784858a48fe351445c672ff2a3d43515",
          "name": "KALM",
          "symbol": "KALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15849/thumb/kalmar.png?1660289892"
        },
        {
          "chainId": 56,
          "address": "0x84fd7cc4cd689fc021ee3d00759b6d255269d538",
          "name": "panKUKU",
          "symbol": "KUKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26657/thumb/Official_Logo_Round_200x200.png?1659413819"
        },
        {
          "chainId": 56,
          "address": "0xaef0d72a118ce24fee3cd1d43d383897d05b4e99",
          "name": "WINkLink BSC",
          "symbol": "WIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15472/thumb/hDLzwfqu_400x400.jpg?1620922090"
        },
        {
          "chainId": 56,
          "address": "0x545f90dc35ca1e6129f1fed354b3e2df12034261",
          "name": "NewB Farm",
          "symbol": "NEWB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16029/thumb/NewB_Token_FA_CMC.png?1622624885"
        },
        {
          "chainId": 56,
          "address": "0x9f3bcbe48e8b754f331dfc694a894e8e686ac31d",
          "name": "Acet",
          "symbol": "ACT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18205/thumb/ACET-LOGO.png?1630975189"
        },
        {
          "chainId": 56,
          "address": "0x87230146e138d3f296a9a77e497a2a83012e9bc5",
          "name": "Squid Game",
          "symbol": "SQUID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20506/thumb/squid.png?1638506382"
        },
        {
          "chainId": 56,
          "address": "0x5c10e4660ec45a89de85a1fd1b22355b0398fb66",
          "name": "NFTCloud",
          "symbol": "CLOUD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29242/thumb/cloud.png?1677575684"
        },
        {
          "chainId": 56,
          "address": "0x155e8a74dac3d8560ddabbc26aa064b764535193",
          "name": "Filipcoin",
          "symbol": "FCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG?1639000706"
        },
        {
          "chainId": 56,
          "address": "0xe37dbf20a4fff3b88233e456355dc49b76b6fe19",
          "name": "Love Earn Enjoy",
          "symbol": "LEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31158/thumb/LEE_200x200_PNG.png?1691046835"
        },
        {
          "chainId": 56,
          "address": "0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a",
          "name": "Bedrock",
          "symbol": "ROCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20030/thumb/Bedrock.png?1681375170"
        },
        {
          "chainId": 56,
          "address": "0xe2efe9d38e21293347018914ee1d23913ecb811c",
          "name": "Intelly",
          "symbol": "INTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28782/thumb/logo_200x200.jpeg?1674195662"
        },
        {
          "chainId": 56,
          "address": "0x065a74c744eb69b4975629c1a89823c694d2efdb",
          "name": "Web3Tools",
          "symbol": "WEB3T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29050/thumb/logo.png?1676330965"
        },
        {
          "chainId": 56,
          "address": "0x9a26e6d24df036b0b015016d1b55011c19e76c87",
          "name": "Dragon Mainland Shards",
          "symbol": "DMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20702/thumb/DMS.png?1637630616"
        },
        {
          "chainId": 56,
          "address": "0xd3af8e09894d69e937e37eef46df1cbf82b35c81",
          "name": "Nano Dogecoin",
          "symbol": "INDC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17477/thumb/Untitled-design-6.png?1627923682"
        },
        {
          "chainId": 56,
          "address": "0x261510dd6257494eea1dda7618dbe8a7b87870dd",
          "name": "Dehero Community",
          "symbol": "HEROES",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/17917/thumb/33b73b5a11f3391dd9ef009985308cf5.png?1629781766"
        },
        {
          "chainId": 56,
          "address": "0x966f75a3a48bd6133220bf83a62429bf04adf29f",
          "name": "Bankers Dream",
          "symbol": "BANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23017/thumb/C47722-F6-F58-D-4945-AEE0-04020-AB24731.png?1643093867"
        },
        {
          "chainId": 56,
          "address": "0x61fa01129ac0bb124d1c60dc9f735c6c579a858b",
          "name": "Kyte One",
          "symbol": "KTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25225/thumb/3l4OKCIt_400x400.png?1650935126"
        },
        {
          "chainId": 56,
          "address": "0xb8501a9a9aaae239a2490f44e00b284baa0b131a",
          "name": "Cremation Coin",
          "symbol": "CREMAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29793/thumb/photo_2023-02-07_04-43-14.jpg?1681346838"
        },
        {
          "chainId": 56,
          "address": "0x8a5d7fcd4c90421d21d30fcc4435948ac3618b2f",
          "name": "Cake Monster",
          "symbol": "MONSTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16441/thumb/Monster-Icon-CG200x200transparent.png?1656392071"
        },
        {
          "chainId": 56,
          "address": "0xd24616870ca41bc01074446988faeb0085a71190",
          "name": "Lord of Dragons Reward Token",
          "symbol": "LORT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30630/thumb/logo_200.png?1685815640"
        },
        {
          "chainId": 56,
          "address": "0x55a9ee0bd79fb0fc0ac71a35d6f481fb4bdc9a5c",
          "name": "OSL AI",
          "symbol": "OSL",
          "decimals": 1,
          "logoURI": "https://assets.coingecko.com/coins/images/30690/thumb/photo_2023-06-05_13-54-02.jpg?1686279946"
        },
        {
          "chainId": 56,
          "address": "0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1",
          "name": "FaraLand",
          "symbol": "FARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16273/thumb/FARA.png?1690337466"
        },
        {
          "chainId": 56,
          "address": "0x4e53522932608e61b6bd8d50cf15a5501054db4e",
          "name": "Aboat Token",
          "symbol": "ABOAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30132/thumb/aboat.jpg?1683270108"
        },
        {
          "chainId": 56,
          "address": "0x939dd9e433552e325d78c33a16ef4cd8004d2f9c",
          "name": "SpaceN",
          "symbol": "SN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27576/thumb/sn.png?1664694186"
        },
        {
          "chainId": 56,
          "address": "0x6f3aaf802f57d045efdd2ac9c06d8879305542af",
          "name": "ProximaX",
          "symbol": "XPX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/3735/thumb/proximax.png?1548332126"
        },
        {
          "chainId": 56,
          "address": "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
          "name": "BSCEX",
          "symbol": "BSCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13582/thumb/icon-bscex-200x200.png?1609917200"
        },
        {
          "chainId": 56,
          "address": "0x5138868ed1814be113227b8c6025cdc46d9d1d16",
          "name": "Forge Finance",
          "symbol": "FORGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24533/thumb/io0E0FB2_400x400.jpg?1648043609"
        },
        {
          "chainId": 56,
          "address": "0x0faf802036e30b4b58a20c359012821152872397",
          "name": "DeCredit",
          "symbol": "CDTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18921/thumb/horizontal-logo.png?1633924734"
        },
        {
          "chainId": 56,
          "address": "0xac41fb8013c0b63588fc63997785a5d79e73eb28",
          "name": "Frz Solar System",
          "symbol": "FRZSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24368/thumb/1100x800_cropped.jpg?1647441326"
        },
        {
          "chainId": 56,
          "address": "0x995c0e3b9af4da89fc76c5c0784e97c7a36eec06",
          "name": "Pige",
          "symbol": "PIGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25885/thumb/Pige.png?1681376641"
        },
        {
          "chainId": 56,
          "address": "0x4181d0f55a5f455ab611b2a0c062714467d7a999",
          "name": "Dragon Fly",
          "symbol": "DRFLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29864/thumb/Dragon_fly.jpeg?1681889266"
        },
        {
          "chainId": 56,
          "address": "0xd68e5c52f7563486cc1a15d00efa12c8644a907e",
          "name": "Egoras Credit",
          "symbol": "EGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20987/thumb/RSGoYMG.png?1638169232"
        },
        {
          "chainId": 56,
          "address": "0xf859bf77cbe8699013d6dbc7c2b926aaf307f830",
          "name": "Berry Data",
          "symbol": "BRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13987/thumb/berry.jpg?1613476736"
        },
        {
          "chainId": 56,
          "address": "0x7111e5c9b01ffa18957b1aa27e9cb0e8fba214f5",
          "name": "Unilab",
          "symbol": "ULAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22330/thumb/unilab-logo-black-text-200.png?1641517618"
        },
        {
          "chainId": 56,
          "address": "0x7b9f36a2f331ece03a7483d2713cfd806f9beef2",
          "name": "Iris Ecosystem",
          "symbol": "IRISTOKEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26518/thumb/200x200.png?1658454212"
        },
        {
          "chainId": 56,
          "address": "0x7b0409a3a3f79baa284035d48e1dfd581d7d7654",
          "name": "HyruleSwap",
          "symbol": "RUPEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14760/thumb/2_%284%29.png?1618274227"
        },
        {
          "chainId": 56,
          "address": "0xe3c1bdeec4db91cd90c336776332fae2e00fddd9",
          "name": "Ash Token",
          "symbol": "ASH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23870/thumb/Ash-Logo-256.png?1645599536"
        },
        {
          "chainId": 56,
          "address": "0x55671114d774ee99d653d6c12460c780a67f1d18",
          "name": "Pacoca",
          "symbol": "PACOCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16567/thumb/pacoca.PNG?1624494018"
        },
        {
          "chainId": 56,
          "address": "0xd0840d5f67206f865aee7cce075bd4484cd3cc81",
          "name": "AFEN Blockchain",
          "symbol": "AFEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14957/thumb/cropped-cropped-afen-e1616095076861.png?1619144008"
        },
        {
          "chainId": 56,
          "address": "0xf77f55995dfac3786dc341ec1d6342a803441613",
          "name": "Capone",
          "symbol": "CAPONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30500/thumb/CAPONE_200.png?1684734239"
        },
        {
          "chainId": 56,
          "address": "0x58ed5ff234efd928a66e84dd3d61bd809ac07a7f",
          "name": "FlokiDash",
          "symbol": "FLOKIDASH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29347/thumb/FlokiDash_200x200.png?1678253258"
        },
        {
          "chainId": 56,
          "address": "0x8893d5fa71389673c5c4b9b3cb4ee1ba71207556",
          "name": "Squirrel Finance",
          "symbol": "NUTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12415/thumb/transparent_logo200.png?1599690422"
        },
        {
          "chainId": 56,
          "address": "0xe5bc80382f1ebad820167714118201ae468a9929",
          "name": "Figments Club",
          "symbol": "FIGMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30476/thumb/IMG-20230519-WA0005.jpg?1684673622"
        },
        {
          "chainId": 56,
          "address": "0xfaa0fc7b803919b091dbe5ff709b2dabb61b93d9",
          "name": "Notable",
          "symbol": "NBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21613/thumb/Progetto_senza_titolo_%284%29.png?1668677309"
        },
        {
          "chainId": 56,
          "address": "0x5f2f6c4c491b690216e0f8ea753ff49ef4e36ba6",
          "name": "Metaland Shares",
          "symbol": "MLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25665/thumb/I3pUwLZp_400x400.jpg?1653287346"
        },
        {
          "chainId": 56,
          "address": "0x09f062cbf43d56df6df8e5381f8a9df2a64c68c0",
          "name": "DecentralFree",
          "symbol": "FREELA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30003/thumb/DecentralFee_logo.png?1682476391"
        },
        {
          "chainId": 56,
          "address": "0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89",
          "name": "Impossible Finance Launchpad",
          "symbol": "IDIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17803/thumb/IDIA.png?1629292292"
        },
        {
          "chainId": 56,
          "address": "0xfc9f81b107f51f2383fce56650fedb59c5fd59bd",
          "name": "Bikerush",
          "symbol": "BRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25677/thumb/X0ZVax0H_400x400.jpg?1653366491"
        },
        {
          "chainId": 56,
          "address": "0x21c7e9ed228242dfd527f456dbf66fee2af67e31",
          "name": "CriptoVille Coins 2",
          "symbol": "CVLC2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28614/thumb/cvlc2.png?1672478376"
        },
        {
          "chainId": 56,
          "address": "0x577ad06f635b402fc2724efd6a53a3a0aed3d155",
          "name": "BeFaster Holder Token",
          "symbol": "BFHT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13205/thumb/BFHT_Logo_200.png?1666607647"
        },
        {
          "chainId": 56,
          "address": "0x57f5c1a40f1e847e50ebdd29cb3dbfef777d2d3e",
          "name": "ConsciousDao",
          "symbol": "CVN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30557/thumb/IMG_20230524_125149_275.jpg?1685014185"
        },
        {
          "chainId": 56,
          "address": "0x10a12969cb08a8d88d4bfb5d1fa317d41e0fdab3",
          "name": "MetaGods",
          "symbol": "MGOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21666/thumb/n9xlniyOphEZp40cR7OnXO1p5H8kI8rLGn6vbx8AaVGiYi250HIeNISTx7eMHU-Ym5XRc9nFPmNXfRZmr3QglH1Svte4Tisu2EZPU3JVFKlPLLnQwDXhodgYcSt0Xb33kyrA_yqFEZnMw1q5qi-4KgdHd4bHv7MKNz2rF3MuA_RWdylgpB3xdUoaxoiMLqzlVbEatJYnDQyhqvh.jpg?1639704500"
        },
        {
          "chainId": 56,
          "address": "0xef9481115ff33e94d3e28a52d3a8f642bf3521e5",
          "name": "CasperPad",
          "symbol": "CSPD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21469/thumb/CasperPad_New_Logo.png?1644477033"
        },
        {
          "chainId": 56,
          "address": "0xe52c5a3590952f3ed6fccf89a0bd7f38e11c5b98",
          "name": "DekBox",
          "symbol": "DEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15968/thumb/DEKbox-02%281%29.png?1622522389"
        },
        {
          "chainId": 56,
          "address": "0x20dd734594dadc69df313cd143b34a70a3d9214e",
          "name": "Origen DEFI",
          "symbol": "ORIGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29561/thumb/200x200.png?1679725478"
        },
        {
          "chainId": 56,
          "address": "0xa179248e50ce5afb507fd8c54e08a66fbac7b6ff",
          "name": "Freedom  Jobs  Business",
          "symbol": "FJB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21300/thumb/FJB_150.png?1666514854"
        },
        {
          "chainId": 56,
          "address": "0x3a5fccbdcc2684be588575f063acba78e09aad4a",
          "name": "VeldoraBSC",
          "symbol": "VDORA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22737/thumb/CMC-VDORA-LOGO-200x200.png?1642491365"
        },
        {
          "chainId": 56,
          "address": "0x8de5aa37a7c40a53062ead382b8eead3b08a7a46",
          "name": "Victory Gem",
          "symbol": "VTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25270/thumb/vtg.png?1651117782"
        },
        {
          "chainId": 56,
          "address": "0x26e463f985a25fe6a20ca122410be10bb5782e0e",
          "name": "Tribe Token",
          "symbol": "TRIBEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20294/thumb/tribe.PNG?1636814986"
        },
        {
          "chainId": 56,
          "address": "0xd6163cec51f2e7c5974f3f4ce8fdb9c80abf142e",
          "name": "Mythic Ore",
          "symbol": "MORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28825/thumb/cg.png?1674724360"
        },
        {
          "chainId": 56,
          "address": "0x799e1cf88a236e42b4a87c544a22a94ae95a6910",
          "name": "MContent",
          "symbol": "MCONTENT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg?1653476766"
        },
        {
          "chainId": 56,
          "address": "0xc77dd3ade7b717583e0924466e4e474a5673332c",
          "name": "Magic Beasties",
          "symbol": "BSTS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18189/thumb/jqhZlCH.png?1630907745"
        },
        {
          "chainId": 56,
          "address": "0xcec747d9f32fc8f7270a79263f7b10eaa6263bd3",
          "name": "EverestCoin",
          "symbol": "EVCOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19137/thumb/evcoin.png?1634525553"
        },
        {
          "chainId": 56,
          "address": "0x6746e37a756da9e34f0bbf1c0495784ba33b79b4",
          "name": "EFUN",
          "symbol": "EFUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21882/thumb/6GyaKgva_400x400.jpg?1640214177"
        },
        {
          "chainId": 56,
          "address": "0xa037f4cb3d319876cdbe585c89663c246615c975",
          "name": "Chains",
          "symbol": "CHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28596/thumb/chains.jpeg?1672229808"
        },
        {
          "chainId": 56,
          "address": "0x000851476180bfc499ea68450a5327d21c9b050e",
          "name": "Slam",
          "symbol": "SLAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15023/thumb/slam2-0-logo.png?1656643117"
        },
        {
          "chainId": 56,
          "address": "0x4823a096382f4fa583b55d563afb9f9a58c72fc0",
          "name": "Arabic",
          "symbol": "ABIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19549/thumb/abic.png?1635400194"
        },
        {
          "chainId": 56,
          "address": "0xf81628edeb110a73c016ab7afa57d80afae07f59",
          "name": "LOT TRADE",
          "symbol": "LOTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21080/thumb/logo-small.png?1638309089"
        },
        {
          "chainId": 56,
          "address": "0x688ec465111ed639267cb17c47e790c9cc7279c1",
          "name": "BB Gaming",
          "symbol": "BB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24616/thumb/bb.png?1648375444"
        },
        {
          "chainId": 56,
          "address": "0x8d875abca035858c901fb3b61a98179aa2ca7ed9",
          "name": "BabyDoge CEO",
          "symbol": "BCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29345/thumb/IMG_20230308_000315_115.jpg?1678240407"
        },
        {
          "chainId": 56,
          "address": "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991",
          "name": "Planet Finance",
          "symbol": "AQUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15710/thumb/aqua-icon.png?1621583770"
        },
        {
          "chainId": 56,
          "address": "0xe77011ed703ed06927dbd78e60c549bababf913e",
          "name": "Micro Bitcoin Finance",
          "symbol": "MBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16086/thumb/bitcoin-96x96.png?1622797624"
        },
        {
          "chainId": 56,
          "address": "0xe9d78bf51ae04c7e1263a76ed89a65537b9ca903",
          "name": "Game Coin",
          "symbol": "GMEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19893/thumb/gmex.png?1636093554"
        },
        {
          "chainId": 56,
          "address": "0xe0e81c29a68bfdd7c48072fd94e7c58f1f0146c1",
          "name": "H2O Securities",
          "symbol": "H2ON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26326/thumb/H2ON_logo_200_200.png?1657674887"
        },
        {
          "chainId": 56,
          "address": "0x36bfbb1d5b3c9b336f3d64976599b6020ca805f1",
          "name": "Planet Sandbox",
          "symbol": "PSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18804/thumb/PS_logo_shape_%282%29.png?1633474856"
        },
        {
          "chainId": 56,
          "address": "0xacb2d47827c9813ae26de80965845d80935afd0b",
          "name": "MacaronSwap",
          "symbol": "MCRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14633/thumb/macaron.png?1623038435"
        },
        {
          "chainId": 56,
          "address": "0x5e3344216adb2c19caa7f3fe90078e7c4ac71bb6",
          "name": "Wrapped BESC",
          "symbol": "WBESC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29849/thumb/photo_2023-04-16_05-49-19.jpg?1681782223"
        },
        {
          "chainId": 56,
          "address": "0xc9c0133f5d5227b059bffee0dbdcfc3f6a8ba434",
          "name": "Pepe Dash AI",
          "symbol": "PEPEDASHAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30478/thumb/IMG-1457_1_.png?1684708537"
        },
        {
          "chainId": 56,
          "address": "0x81372c18c87f6d2fe91f416d7c8a109cea48c332",
          "name": "Swinca",
          "symbol": "SWI",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/25534/thumb/LOGO-TOKEN-2.jpeg?1652251811"
        },
        {
          "chainId": 56,
          "address": "0x404f83279c36e3e0e2771b7ae9f9b0b2b50ee27c",
          "name": "Agro Global Token",
          "symbol": "AGRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21511/thumb/200x200.png?1639375342"
        },
        {
          "chainId": 56,
          "address": "0x021f48697343a6396bafc01795a4c140b637f4b4",
          "name": "Magic Yearn Share",
          "symbol": "MYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29343/thumb/mys.png?1678186682"
        },
        {
          "chainId": 56,
          "address": "0x6d86f0a41c3966cef8ea139648db707e912563c9",
          "name": "mCoin",
          "symbol": "MCOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24942/thumb/mcoin_Logo_%281%29.png?1649429516"
        },
        {
          "chainId": 56,
          "address": "0xfa4ba88cf97e282c505bea095297786c16070129",
          "name": "Coin98 Dollar",
          "symbol": "CUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049"
        },
        {
          "chainId": 56,
          "address": "0x1ebc21f65ffed4836e51b9c5d680b28c01ed0e8d",
          "name": "NeoCortexAI",
          "symbol": "NCORAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31111/thumb/NCORAI.jpg?1690522978"
        },
        {
          "chainId": 56,
          "address": "0x3c730718c97a77562866b5d29b33228c019eac68",
          "name": "BNB Diamond",
          "symbol": "BNBD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15354/thumb/J6gLEc0.png?1620631478"
        },
        {
          "chainId": 56,
          "address": "0x00f97c17f4dc4f3bfd2dd9ce5e67f3a339a8a261",
          "name": "Shield Protocol",
          "symbol": "SHIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24286/thumb/output-200.png?1680142980"
        },
        {
          "chainId": 56,
          "address": "0x13b8abb1cfd7bbe1f5764fe967ed049d488d9d1d",
          "name": "Joe Yo Coin",
          "symbol": "JYC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25337/thumb/Joe_Yo_Coin_200.png?1651299958"
        },
        {
          "chainId": 56,
          "address": "0x4ef29f3b804c316ba8ba464a765c601fc092a2e9",
          "name": "Quantum Assets",
          "symbol": "QA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18194/thumb/QA-logo-coinMarketCap.png?1630913079"
        },
        {
          "chainId": 56,
          "address": "0x7361de48dde2d05e379d9e6641b7e7b2e323a464",
          "name": "TrumpArmy",
          "symbol": "TRUMPARMY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30439/thumb/IMG_1128_%281%29.png?1684472697"
        },
        {
          "chainId": 56,
          "address": "0x73fbd93bfda83b111ddc092aa3a4ca77fd30d380",
          "name": "SophiaVerse",
          "symbol": "SOPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30050/thumb/Icon_200x200.png?1682996025"
        },
        {
          "chainId": 56,
          "address": "0xa1a5ad28c250b9383c360c0f69ad57d70379851e",
          "name": "Synergy Crystal",
          "symbol": "CRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28960/thumb/AB268A5A-5DBC-4A4A-B4FC-605DFC4B54FE.png?1675830418"
        },
        {
          "chainId": 56,
          "address": "0xe8e0d396f0881c0fab9319e441223f9780b8305c",
          "name": "Supreme Finance HYPES",
          "symbol": "HYPES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27322/thumb/N9aAdTFH_400x400.jpeg?1663320033"
        },
        {
          "chainId": 56,
          "address": "0xbcb3ac7a3ceb2d0c5e162a03901d6d7bb8602912",
          "name": "Ltradex",
          "symbol": "LTEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22001/thumb/photo1637249460-removebg-preview-1.png?1640587693"
        },
        {
          "chainId": 56,
          "address": "0xbef05cf52d8b244eeca6033fb8b9b69e1974f681",
          "name": "Shiba CEO",
          "symbol": "SHIBCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29376/thumb/A0D7C0ED-60CE-4B76-B279-E6901F2A4449.jpeg?1678351104"
        },
        {
          "chainId": 56,
          "address": "0x8c851d1a123ff703bd1f9dabe631b69902df5f97",
          "name": "BinaryX  OLD ",
          "symbol": "BNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18095/thumb/BinaryX-RGB-01_%282%29.png?1630462722"
        },
        {
          "chainId": 56,
          "address": "0x8ebc361536094fd5b4ffb8521e31900614c9f55d",
          "name": "Konstellation",
          "symbol": "DARC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2943/thumb/darctoken.png?1645230834"
        },
        {
          "chainId": 56,
          "address": "0x1a515bf4e35aa2df67109281de6b3b00ec37675e",
          "name": "2G Carbon Coin",
          "symbol": "2GCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27514/thumb/Ojh3Dm3-_400x400.jpeg?1664352445"
        },
        {
          "chainId": 56,
          "address": "0x80262f604acac839724f66846f290a2cc8b48662",
          "name": "Ari10",
          "symbol": "ARI10",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19904/thumb/ari10.PNG?1636100074"
        },
        {
          "chainId": 56,
          "address": "0xe870aec45088bfd4a43852b39d9c3560a8cb9b14",
          "name": "CentroFi",
          "symbol": "CENTRO",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/28353/thumb/logo.png?1669772112"
        },
        {
          "chainId": 56,
          "address": "0xc41689a727469c1573009757200371edf36d540e",
          "name": "Dynamix",
          "symbol": "DYNA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18747/thumb/12275.png?1633316967"
        },
        {
          "chainId": 56,
          "address": "0x3548e7c2af658957692ec0397eed431e85bf952b",
          "name": "GPT AI",
          "symbol": "AI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29501/thumb/NW5Qz5wR_400x400.jpg?1679372438"
        },
        {
          "chainId": 56,
          "address": "0xf29cccc3460506e8f9bc038d4716c05b76b0441e",
          "name": "ReadFi",
          "symbol": "RDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26780/thumb/readfi.png?1660903974"
        },
        {
          "chainId": 56,
          "address": "0x2a17dc11a1828725cdb318e0036acf12727d27a2",
          "name": "ArenaSwap",
          "symbol": "ARENA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17273/thumb/1A08E0A4-6486-4D35-BC0E-436551ECC078.jpg?1627008978"
        },
        {
          "chainId": 56,
          "address": "0x67d8e0080b612afae75a7f7229bfed3cdb998462",
          "name": "CyberHarbor",
          "symbol": "CHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29509/thumb/logo_4x.png?1682393133"
        },
        {
          "chainId": 56,
          "address": "0x29c55f1b02a95f0b30e61976835a3eee2359ad92",
          "name": "EMP Shares",
          "symbol": "ESHAREV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31131/thumb/ESHARES.png?1690789889"
        },
        {
          "chainId": 56,
          "address": "0xcd392021084683803525fe5e6c00cae9c6be5774",
          "name": "Adonis",
          "symbol": "ADON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26793/thumb/Jugvt5z5_400x400.jpeg?1660129722"
        },
        {
          "chainId": 56,
          "address": "0xf7b6d7e3434cb9441982f9534e6998c43eef144a",
          "name": "Asva Labs",
          "symbol": "ASVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22134/thumb/1_YAFDez1BKcVUHzwDoYcgPQ.png?1640907036"
        },
        {
          "chainId": 56,
          "address": "0xe4f3252f268b6b5beae4b02a4b99b369bbea12f1",
          "name": "Enigma Gaming",
          "symbol": "ENG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29998/thumb/Logo-gradient_%281%29_%284%29.png?1682472658"
        },
        {
          "chainId": 56,
          "address": "0x54626300818e5c5b44db0fcf45ba4943ca89a9e2",
          "name": "CheCoin",
          "symbol": "CHECOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17399/thumb/hmvUIO89jIpjmOD6astn5S5pTSJqnhc3KowWEcI2.png?1627528698"
        },
        {
          "chainId": 56,
          "address": "0x42a7da71a6b553d20ac656cc33540ac784e68072",
          "name": "Bumblebot",
          "symbol": "BUMBLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29654/thumb/Bumblebot.png?1680493778"
        },
        {
          "chainId": 56,
          "address": "0x25b24b3c47918b7962b3e49c4f468367f73cc0e0",
          "name": "AXL INU",
          "symbol": "AXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png?1641188792"
        },
        {
          "chainId": 56,
          "address": "0xaf099ef77575a9f981660b1c9e3b78a3ba89ffd9",
          "name": "CoinSale Token",
          "symbol": "COINSALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29760/thumb/photo_2023-04-01_20-35-19.jpg?1681178302"
        },
        {
          "chainId": 56,
          "address": "0x53e4b7aa6caccb9576548be3259e62de4ddd4417",
          "name": "DAOLaunch",
          "symbol": "DAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20208/thumb/dal.png?1636642223"
        },
        {
          "chainId": 56,
          "address": "0x9e1170c12fddd3b00fec42ddf4c942565d9be577",
          "name": "Space Token BSC",
          "symbol": "SPACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20676/thumb/jYw3kgsY_400x400.png?1637550733"
        },
        {
          "chainId": 56,
          "address": "0x7edc0ec89f987ecd85617b891c44fe462a325869",
          "name": "Gunstar Metaverse",
          "symbol": "GSTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21079/thumb/gst.png?1639046007"
        },
        {
          "chainId": 56,
          "address": "0x38fab266089aaf3bc2f11b791213840ea3d587c7",
          "name": "Aquachain",
          "symbol": "AQUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5596/thumb/aqua.png?1586745922"
        },
        {
          "chainId": 56,
          "address": "0x8106789b240e5e1b34643c052f1dc1b7a1a451a5",
          "name": "Kasa Central",
          "symbol": "KASA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28354/thumb/kasa_blue_logo_200x200.png?1671178637"
        },
        {
          "chainId": 56,
          "address": "0x245d9f531757f83064ad808b4c9b220c703a4934",
          "name": "Gode Chain",
          "symbol": "GODE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25140/thumb/KQkiY5pe_400x400.jpg?1650433701"
        },
        {
          "chainId": 56,
          "address": "0xaaaafdc2e08371b956be515b3f3ff735ab9c9d74",
          "name": "Azbit",
          "symbol": "AZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4504/thumb/azbit200x200.png?1665646620"
        },
        {
          "chainId": 56,
          "address": "0x59769630b236398c2471eb26e6a529448030d94f",
          "name": "NKYC Token",
          "symbol": "NKYC",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/30337/thumb/NKYC-Logo.png?1684156847"
        },
        {
          "chainId": 56,
          "address": "0xbb46693ebbea1ac2070e59b4d043b47e2e095f86",
          "name": "BetFury",
          "symbol": "BFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18108/thumb/bfg.png?1630491817"
        },
        {
          "chainId": 56,
          "address": "0x991bb6093fa735d27cd1444b2ad8fdd95876fed5",
          "name": "La Peseta",
          "symbol": "PTAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30631/thumb/ptas.jpg?1685968715"
        },
        {
          "chainId": 56,
          "address": "0xa49c8cbbddfe4dbc8605f0f5c8f2845c5e225d22",
          "name": "Apidae",
          "symbol": "APT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26276/thumb/ApidaeTokenLogo.png?1657016442"
        },
        {
          "chainId": 56,
          "address": "0xa469d8e55afcd58003d6cbdc770c0ecc2dd71945",
          "name": "PlatinX",
          "symbol": "PTX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23726/thumb/logo200x200.png?1645162319"
        },
        {
          "chainId": 56,
          "address": "0xb583961e033dfe0fff161952f7ba21c411b6103d",
          "name": "Youwho",
          "symbol": "YOU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25353/thumb/youwho_200.png?1651312259"
        },
        {
          "chainId": 56,
          "address": "0xda2c0cdf7d764f8c587380cadf7129e5ecb7efb7",
          "name": "Puppets Coin",
          "symbol": "PUPPETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30178/thumb/200x200.png?1687102029"
        },
        {
          "chainId": 56,
          "address": "0x7eefb6aeb8bc2c1ba6be1d4273ec0758a1321272",
          "name": "Endless Board Game",
          "symbol": "ENG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23981/thumb/ENG_Logo.png?1677750423"
        },
        {
          "chainId": 56,
          "address": "0x95ee03e1e2c5c4877f9a298f1c0d6c98698fab7b",
          "name": "Duet Protocol",
          "symbol": "DUET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16164/thumb/Duet.jpg?1623143640"
        },
        {
          "chainId": 56,
          "address": "0xae90ca218f9c3b1aa84af33a7907e4890ec6a167",
          "name": "ECLAT",
          "symbol": "ELT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27812/thumb/Eclat_Logo_-_100kb.png?1665907477"
        },
        {
          "chainId": 56,
          "address": "0x827d24bb2aad813fe6f49b798f44cce4c48bd478",
          "name": "FADO Go",
          "symbol": "FADO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23593/thumb/fado.png?1647078009"
        },
        {
          "chainId": 56,
          "address": "0xe9d6d6d7cde5c7d45927f8c37460d932e612c902",
          "name": "Stella Fantasy Token",
          "symbol": "SFTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29717/thumb/Token_Logo_SFTY.png?1680769510"
        },
        {
          "chainId": 56,
          "address": "0x26734add0650719ea29087fe5cc0aab81b4f237d",
          "name": "STEMX",
          "symbol": "STEMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19739/thumb/13702.png?1635813079"
        },
        {
          "chainId": 56,
          "address": "0x4d61577d8fd2208a0afb814ea089fdeae19ed202",
          "name": "VFOX",
          "symbol": "VFOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15363/thumb/vfox2.png?1629870083"
        },
        {
          "chainId": 56,
          "address": "0x7cc1c126be3128c1f0441a893cd6220498b27650",
          "name": "XROW",
          "symbol": "XROW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29725/thumb/XROW_logo_%281%29.png?1680850520"
        },
        {
          "chainId": 56,
          "address": "0xef843fb4c112e618b262f6897f479474e4586f05",
          "name": "Monaco Planet",
          "symbol": "MONA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21742/thumb/mona.PNG?1639974886"
        },
        {
          "chainId": 56,
          "address": "0xfc111b40ad299572f74f1c119c036508c621bb19",
          "name": "Rocket Raccoon",
          "symbol": "ROC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19623/thumb/racoon.PNG?1635498212"
        },
        {
          "chainId": 56,
          "address": "0xfb526228ff1c019e4604c7e7988c097d96bd5b70",
          "name": "Virgo",
          "symbol": "VGO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19209/thumb/vgoLogo200.png?1634689795"
        },
        {
          "chainId": 56,
          "address": "0x9400aa8eb5126d20cde45c7822836bfb70f19878",
          "name": "Drife",
          "symbol": "DRF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17566/thumb/7BAWjuKB_400x400.jpg?1628394436"
        },
        {
          "chainId": 56,
          "address": "0x9767203e89dcd34851240b3919d4900d3e5069f1",
          "name": "A4 Finance",
          "symbol": "A4",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786"
        },
        {
          "chainId": 56,
          "address": "0xf376807dcdbaa0d7fa86e7c9eacc58d11ad710e4",
          "name": "Blitz Labs",
          "symbol": "BLITZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25554/thumb/cmc2.png?1652421101"
        },
        {
          "chainId": 56,
          "address": "0xe5765e33e349b2dcf22a37b2b4e87c10ad43f165",
          "name": "Christmas Floki",
          "symbol": "FLOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28396/thumb/Christmas_Floki.png?1670290527"
        },
        {
          "chainId": 56,
          "address": "0xcb1ddc8f705e2737685a9c1e6b84a63d04d200e5",
          "name": "Decentralized United",
          "symbol": "DCU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26968/thumb/dcu.png?1661088678"
        },
        {
          "chainId": 56,
          "address": "0xa4050aa9b76ccdae1a6a8b2f3e8627cdc1546d86",
          "name": "Lepasa",
          "symbol": "LEPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975"
        },
        {
          "chainId": 56,
          "address": "0xbceee918077f63fb1b9e10403f59ca40c7061341",
          "name": "Papa Doge",
          "symbol": "PAPADOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16721/thumb/PAPADOGE.jpg?1624848914"
        },
        {
          "chainId": 56,
          "address": "0xde619a9e0eeeaa9f8cd39522ed788234837f3b26",
          "name": "Hungarian Vizsla Inu",
          "symbol": "HVI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16812/thumb/k%C3%B6r-nincs_h%C3%A1tt%C3%A9r.png?1625148179"
        },
        {
          "chainId": 56,
          "address": "0x1e05f5a582e45b58cba5fa318d10344a77fb1d94",
          "name": "ALLINAI",
          "symbol": "AIAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29132/thumb/200x200.png?1676958030"
        },
        {
          "chainId": 56,
          "address": "0x27d51bca0b145b3f701178c3da5b66cdf66bd04e",
          "name": "Suneku",
          "symbol": "SUNEKU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26672/thumb/Logo_%286%29.png?1659512761"
        },
        {
          "chainId": 56,
          "address": "0x10adf50e15611d5a4de3bd21f0db7f3491a8ae0f",
          "name": "Monetas  OLD ",
          "symbol": "MNTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18700/thumb/logo_-_2021-10-01T091320.526.png?1633050813"
        },
        {
          "chainId": 56,
          "address": "0xd869eb44cffa0276ba5c757cda77e25102054eb6",
          "name": "PEPE OPTIMUS",
          "symbol": "PEPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30472/thumb/IMG_7307.png?1684671894"
        },
        {
          "chainId": 56,
          "address": "0x6c1efbed2f57dd486ec091dffd08ee5235a570b1",
          "name": "Pandora Finance",
          "symbol": "PNDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18062/thumb/_Profile_Picture.png?1680773832"
        },
        {
          "chainId": 56,
          "address": "0x9133049fb1fddc110c92bf5b7df635abb70c89dc",
          "name": "Dot Finance",
          "symbol": "PINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16446/thumb/dot_finance.PNG?1624331635"
        },
        {
          "chainId": 56,
          "address": "0xf7844cb890f4c339c497aeab599abdc3c874b67a",
          "name": "NFT Art Finance",
          "symbol": "NFTART",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15027/thumb/nft-art-coingecko-logo.png?1622443455"
        },
        {
          "chainId": 56,
          "address": "0x6c13a45101bd70561daf6186da86d7bdb018754f",
          "name": "Dewae",
          "symbol": "DEWAE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30567/thumb/IMG_20230525_005455_597.png?1685075280"
        },
        {
          "chainId": 56,
          "address": "0xf3e07812ebc8604fddb0aa35ff79a03f48f48948",
          "name": "JournArt",
          "symbol": "JART",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/29412/thumb/JournArt%28200%29.png?1678597820"
        },
        {
          "chainId": 56,
          "address": "0xf339e8c294046e6e7ef6ad4f6fa9e202b59b556b",
          "name": "Battle of Guardians Share",
          "symbol": "BGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22285/thumb/K3hU77wS_400x400.jpg?1641365642"
        },
        {
          "chainId": 56,
          "address": "0x80e7dc4e726e052b0db04ec8b506596458809c11",
          "name": "BOCOIN",
          "symbol": "BOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26756/thumb/200-200_bo_logo.png?1660032505"
        },
        {
          "chainId": 56,
          "address": "0x7a2277f34f275ded630deff758fbc818409ca36d",
          "name": "Optimus",
          "symbol": "OPTCM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21678/thumb/rsz_logo%281%29.png?1639712718"
        },
        {
          "chainId": 56,
          "address": "0x62d70e77038c4009645d7de007373e4873bb4898",
          "name": "Force of Nature",
          "symbol": "FON",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25752/thumb/20278.png?1653465872"
        },
        {
          "chainId": 56,
          "address": "0x5dcc2cfcaf202a9373005fa65905a7169c6c53d5",
          "name": "Warp Cash",
          "symbol": "WARP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28895/thumb/Logo_warp_black_200x200.png?1675217936"
        },
        {
          "chainId": 56,
          "address": "0x199f788ddb566b7ebb59bf35b36914f2acdb33de",
          "name": "DeFido",
          "symbol": "DEFIDO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18861/thumb/defido.png?1635638445"
        },
        {
          "chainId": 56,
          "address": "0x72ff5742319ef07061836f5c924ac6d72c919080",
          "name": "Gifto",
          "symbol": "GFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1359/thumb/Logo-Sign-Small.png?1672477201"
        },
        {
          "chainId": 56,
          "address": "0x7c5e8a22a4e8f9da2797a9e30e9d64abf5493c43",
          "name": "Web AI",
          "symbol": "WEBAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29423/thumb/WebAi_Logo.png?1678684744"
        },
        {
          "chainId": 56,
          "address": "0x9c67638c4fa06fd47fb8900fc7f932f7eab589de",
          "name": "Arker",
          "symbol": "ARKER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21313/thumb/coin_%285%29.png?1638919418"
        },
        {
          "chainId": 56,
          "address": "0x9720ca160bbd4e7f3dd4bb3f8bd4227ca0342e63",
          "name": "GamesPad",
          "symbol": "GMPD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21842/thumb/MdE0tFS0_400x400.jpg?1640128674"
        },
        {
          "chainId": 56,
          "address": "0x17b6115a4d0292745d122a49ade76ed5ac16b19d",
          "name": "Connect Stela",
          "symbol": "CNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24927/thumb/61f1d2b3bae74faea4ea34ef_32x32.png?1649389084"
        },
        {
          "chainId": 56,
          "address": "0x8094e772fa4a60bdeb1dfec56ab040e17dd608d5",
          "name": "Koda Cryptocurrency",
          "symbol": "KODA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15577/thumb/koda.png?1639224696"
        },
        {
          "chainId": 56,
          "address": "0xe9c1b765c3b69ff6178c7310fe3eb106421870a5",
          "name": "Buff Coin",
          "symbol": "BUFF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28370/thumb/logo.png?1671682595"
        },
        {
          "chainId": 56,
          "address": "0x1dc84fc11e48ae640d48044f22a603bbe914a612",
          "name": "LocalTrade",
          "symbol": "LTT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22838/thumb/odbAFHBFZOoBqBWusCYcMjubLI8GJOArvJtuAWzDBRw3bZf2vRmJogFe1sOaIKfGtQmLTcRSzSDskopEHk3Gkpq6gYmE4IPJjMTxAppDTH4s7878Ht-LjC-ejUOD6krZ55SvvZtyZSfiZoxPE_aQGO62-m_B2tpQrFXlJk4orXx49TP-ggeZ8pc3-3j97fRqDegEm1F89eq3gL.jpg?1642745247"
        },
        {
          "chainId": 56,
          "address": "0xb78e0ff3a82c487295074465ff714e45a6e7b39c",
          "name": "Cosmic Chain",
          "symbol": "COSMIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30000/thumb/photo_2023-04-06_06-19-48.jpg?1682473467"
        },
        {
          "chainId": 56,
          "address": "0x6500197a2488610aca288fd8e2dfe88ec99e596c",
          "name": "Aquari",
          "symbol": "AQUARI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15177/thumb/aquari-blue-200-notext.png?1622439133"
        },
        {
          "chainId": 56,
          "address": "0xf3147987a00d35eecc10c731269003ca093740ca",
          "name": "My Master War",
          "symbol": "MAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18772/thumb/JiuEEvte_400x400.jpg?1633393017"
        },
        {
          "chainId": 56,
          "address": "0xb15488af39bd1de209d501672a293bcd05f82ab4",
          "name": "Nanomatic",
          "symbol": "NANO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30339/thumb/60D90D25-960C-4260-BB27-63E3FD37AFDC.png?1684158891"
        },
        {
          "chainId": 56,
          "address": "0xec43d3153c1f08946fa71cdd3a14af64fd58f27e",
          "name": "Pawn My NFT",
          "symbol": "PNFT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19998/thumb/FcVyUcDq_400x400.jpg?1636363335"
        },
        {
          "chainId": 56,
          "address": "0x926ecc7687fcfb296e97a2b4501f41a6f5f8c214",
          "name": "BRN Metaverse",
          "symbol": "BRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25363/thumb/brn.png?1651316601"
        },
        {
          "chainId": 56,
          "address": "0xdbf04058821bee93a04ffa5b70d441a51a9afb22",
          "name": "Nippon Lagoon",
          "symbol": "NLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27441/thumb/nlc.png?1663937288"
        },
        {
          "chainId": 56,
          "address": "0x13abec309d958c7900e8dea7d930d794981507ad",
          "name": "The Bend",
          "symbol": "BEND",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25616/thumb/bend200x200.png?1652855567"
        },
        {
          "chainId": 56,
          "address": "0x27348c55a6ca17f0d3cb75049c21a04e08cf6e78",
          "name": "Octavus Prime",
          "symbol": "OCTAVUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30215/thumb/Octavus_Prime_logo.png?1683626271"
        },
        {
          "chainId": 56,
          "address": "0x11d1ac5ec23e3a193e8a491a198f5fc9ee715839",
          "name": "MultiPad",
          "symbol": "MPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18145/thumb/200_x_200_%281%29.png?1630670156"
        },
        {
          "chainId": 56,
          "address": "0x6cb8065f96d63630425fd95a408a0d6cd697c662",
          "name": "Embr",
          "symbol": "EMBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20904/thumb/embr.png?1659854613"
        },
        {
          "chainId": 56,
          "address": "0xbd8ccac4bd523cb2fdb34876de2f596dbf75885e",
          "name": "Trendsy",
          "symbol": "TRNDZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23088/thumb/Trendsy-logo-200x200-1.png?1643181548"
        },
        {
          "chainId": 56,
          "address": "0xab785054251db0fc44538f5deebe7507b748b692",
          "name": "Jasan Wellness",
          "symbol": "JW",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26143/thumb/jw.png?1656039344"
        },
        {
          "chainId": 56,
          "address": "0x7646cbf726b39a5417834aec7fc4d57428111a00",
          "name": "iTeller",
          "symbol": "ITLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30094/thumb/logo_200x200.png?1683023335"
        },
        {
          "chainId": 56,
          "address": "0xdf0121a3ba5c10816ea2943c722650c4a4b0dbe6",
          "name": "Octopus Protocol",
          "symbol": "OPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16383/thumb/ops_logo.jpg?1623853585"
        },
        {
          "chainId": 56,
          "address": "0xbcd192f38457619fbdef609a194e8ab467cc3a58",
          "name": "Cherish",
          "symbol": "CHC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/24274/thumb/logo-svg.png?1647180153"
        },
        {
          "chainId": 56,
          "address": "0xa6c59de838f1eeb82d86f5af0750f5f656439999",
          "name": "ShopNext Loyalty Token",
          "symbol": "NEXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28681/thumb/shopnex.jpg?1673245875"
        },
        {
          "chainId": 56,
          "address": "0x52c1751c89fc913ed274d72e8d56dce4ee44a5cf",
          "name": "Wizarre Scroll",
          "symbol": "SCRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21046/thumb/scroll_200.png?1638257148"
        },
        {
          "chainId": 56,
          "address": "0xa3276b9366a3aa6f76fa3b39173577718012d7da",
          "name": "ENEFTIVERSE",
          "symbol": "EVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29529/thumb/eneftitoken.logo-removebg-preview-1.png?1679545416"
        },
        {
          "chainId": 56,
          "address": "0xfa53a4778431712af31a11621edee4d0926df1ac",
          "name": "Petals",
          "symbol": "PTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26154/thumb/pts.png?1656078078"
        },
        {
          "chainId": 56,
          "address": "0xf892bb5a36c4457901130e041bdeb470bd72242f",
          "name": "AstroX",
          "symbol": "ATX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28975/thumb/21908_1_.png?1675750224"
        },
        {
          "chainId": 56,
          "address": "0x33af7644b1f60c8e8d62689b19d60a5d132164fc",
          "name": "Shib Generating",
          "symbol": "SHG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21650/thumb/shg.png?1639640589"
        },
        {
          "chainId": 56,
          "address": "0x186866858aef38c05829166a7711b37563e15994",
          "name": "Hodl Finance",
          "symbol": "HFT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20213/thumb/hft_coingecko.png?1645690810"
        },
        {
          "chainId": 56,
          "address": "0x897f2be515373cf1f899d864b5be2bd5efd4e653",
          "name": "T23",
          "symbol": "T23",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28604/thumb/T23-Icon-200x200.png?1672383767"
        },
        {
          "chainId": 56,
          "address": "0x499954f9c977b74a48d4bb14ba9904bea6cb7b01",
          "name": "Cosanta",
          "symbol": "COSA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29313/thumb/cosanta_logo.png?1678001242"
        },
        {
          "chainId": 56,
          "address": "0xb72962568345253f71a18318d67e13a282b187e6",
          "name": "ETH Fan Token Ecosystem",
          "symbol": "EFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22612/thumb/eft.png?1642236393"
        },
        {
          "chainId": 56,
          "address": "0xaed8bd0608ef3cc45290a8d0e4223ef4c92dd3dc",
          "name": "Avoteo",
          "symbol": "AVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27659/thumb/photo_2022-10-06_13-03-48.jpg?1665100954"
        },
        {
          "chainId": 56,
          "address": "0xe1918c21ad0e6ee0f48a723830a2c5e2f6296f23",
          "name": "Insurabler",
          "symbol": "INSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30735/thumb/Insurabler.png?1686710231"
        },
        {
          "chainId": 56,
          "address": "0xc60dee4852ee6190cc440e87fc06796ec5ed4bb0",
          "name": "LionCEO",
          "symbol": "LCEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29738/thumb/IMG_20230406_222831_168.png?1681096071"
        },
        {
          "chainId": 56,
          "address": "0x00ceb4868501b456207856bb6f949c3d2af09a66",
          "name": "tudaBirds",
          "symbol": "BURD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22839/thumb/_TOvRxfx_400x400.jpg?1642745695"
        },
        {
          "chainId": 56,
          "address": "0x9e0335fb61958fe19bb120f3f8408b4297921820",
          "name": "Forbidden Fruit Energy",
          "symbol": "FFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30453/thumb/FFE_TOKEN.png?1684491407"
        },
        {
          "chainId": 56,
          "address": "0x3f9de0de2abc8e0460c26533e031799c6ceb141d",
          "name": "Smart Donation Coin",
          "symbol": "SDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23822/thumb/nlogo-200px.png?1645514609"
        },
        {
          "chainId": 56,
          "address": "0xc73d9b9616e855df4a0b32c3f1ef2ed5dc9c1990",
          "name": "Coinflect",
          "symbol": "WCFLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20815/thumb/CMC_Coinflect_Logo-01.png?1665792802"
        },
        {
          "chainId": 56,
          "address": "0x76e112203ef59d445452ef7556386dd2df3ed914",
          "name": "Canadian Inuit Dog",
          "symbol": "CADINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28508/thumb/JCM3FITW_400x400.jpg?1671173266"
        },
        {
          "chainId": 56,
          "address": "0x631e20a3b5f523499ae4f5d6239d36f1cd0ddf8e",
          "name": "Cacom",
          "symbol": "CACOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30951/thumb/IMG_20230710_221155_578.png?1689214855"
        },
        {
          "chainId": 56,
          "address": "0xe4fae3faa8300810c835970b9187c268f55d998f",
          "name": "CateCoin",
          "symbol": "CATE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15364/thumb/logo.png?1620647627"
        },
        {
          "chainId": 56,
          "address": "0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b",
          "name": "Venus LTC",
          "symbol": "VLTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13908/thumb/ltc.png?1612799575"
        },
        {
          "chainId": 56,
          "address": "0x1bd55090b8878630e57fae6ebd11fd61d16dfc9f",
          "name": "ArchLoot",
          "symbol": "ALT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28919/thumb/7.png?1675591504"
        },
        {
          "chainId": 56,
          "address": "0x5cf8ea4278f689b301c4a17ddca9d5ec8b0b0511",
          "name": "Balance Network",
          "symbol": "BLN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26797/thumb/Balanced_Network.png?1683101437"
        },
        {
          "chainId": 56,
          "address": "0xc7091aa18598b87588e37501b6ce865263cd67ce",
          "name": "CheesecakeSwap",
          "symbol": "CCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14547/thumb/CCAKElogo.png?1616984147"
        },
        {
          "chainId": 56,
          "address": "0x40c8225329bd3e28a043b029e0d07a5344d2c27c",
          "name": "AgeOfGods",
          "symbol": "AOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22133/thumb/aog.PNG?1640905715"
        },
        {
          "chainId": 56,
          "address": "0x18d3be5ecddf79279004e2d90d507594c2d46f85",
          "name": "Koakuma",
          "symbol": "KKMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28745/thumb/logo_200.png?1674720233"
        },
        {
          "chainId": 56,
          "address": "0x400613f184d1207f5c07a67d67040a4e23e92feb",
          "name": "UpDog",
          "symbol": "UPDOG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15369/thumb/wKA2xZr.png?1620665433"
        },
        {
          "chainId": 56,
          "address": "0x0614cbfb5c0e9c9be5a460f383edddf884080c99",
          "name": "YearnTogether",
          "symbol": "YEARN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30560/thumb/YEARN-logo.png?1685018826"
        },
        {
          "chainId": 56,
          "address": "0xe81257d932280ae440b17afc5f07c8a110d21432",
          "name": "Zuki Moba",
          "symbol": "ZUKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20655/thumb/C1A1yP2J_400x400.jpg?1637528993"
        },
        {
          "chainId": 56,
          "address": "0x82bec5483dbab4305f32b191d76dc6cb020ae76d",
          "name": "7Pixels",
          "symbol": "7PXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26153/thumb/7pxs.png?1656067961"
        },
        {
          "chainId": 56,
          "address": "0xa9cf023a5f6de6863f02761f6166799ec2595758",
          "name": "Blast Frontiers",
          "symbol": "BLAST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30857/thumb/IMG_20230625_123548_616.jpg?1688366501"
        },
        {
          "chainId": 56,
          "address": "0xdc847755343c3a2b94d6afc0aae57651e1b14064",
          "name": "BODA",
          "symbol": "BODAV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19168/thumb/BODAV2-New-logo-round-200.png?1644477982"
        },
        {
          "chainId": 56,
          "address": "0xcbcd9c0f344960f40c5ce7eb17a17e837fe8bb92",
          "name": "WOLFCOIN",
          "symbol": "WOLF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23934/thumb/6E433721-C204-48ED-8467-EFA2FBA79794.png?1645713559"
        },
        {
          "chainId": 56,
          "address": "0xc15e89f2149bcc0cbd5fb204c9e77fe878f1e9b2",
          "name": "HUH",
          "symbol": "HUH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21549/thumb/huh.png?1639462106"
        },
        {
          "chainId": 56,
          "address": "0xae9269f27437f0fcbc232d39ec814844a51d6b8f",
          "name": "BurgerCities",
          "symbol": "BURGER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12563/thumb/burger.png?1600786553"
        },
        {
          "chainId": 56,
          "address": "0xfd0ed86319bbf02359266d5fa1cf10bc1720b2e0",
          "name": "MonsterQuest",
          "symbol": "MQST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22912/thumb/o3Dcp_Qi_400x400.jpg?1642984196"
        },
        {
          "chainId": 56,
          "address": "0x4dcaaa68170053afbbde15774931adba09272a55",
          "name": "Dpad Finance",
          "symbol": "DPAD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24312/thumb/0vrp38DQ_400x400.jpg?1647352973"
        },
        {
          "chainId": 56,
          "address": "0x08426874d46f90e5e527604fa5e3e30486770eb3",
          "name": "Moonshots Farm",
          "symbol": "BONES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22487/thumb/moonshots200.png?1641904223"
        },
        {
          "chainId": 56,
          "address": "0xca861e289f04cb9c67fd6b87ca7eafa59192f164",
          "name": "UnityMeta Token",
          "symbol": "UMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30859/thumb/Unitymeta_Token_Logo_200.png?1688366893"
        },
        {
          "chainId": 56,
          "address": "0x29745314b4d294b7c77cdb411b8aaa95923aae38",
          "name": "PalmSwap",
          "symbol": "PALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27604/thumb/22000.png?1664782743"
        },
        {
          "chainId": 56,
          "address": "0xdd8b490001d081ed065239644dae8d1a77b8a91f",
          "name": "BitValley",
          "symbol": "BITV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22825/thumb/Screenshot-2022-01-19-at-17-00-20.png?1642663544"
        },
        {
          "chainId": 56,
          "address": "0x6507458bb53aec6be863161641ec28739c41cc97",
          "name": "FootballStars",
          "symbol": "FTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15627/thumb/footballstars.PNG?1621892838"
        },
        {
          "chainId": 56,
          "address": "0x4d7fa587ec8e50bd0e9cd837cb4da796f47218a1",
          "name": "SAFE AnWang ",
          "symbol": "SAFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26604/thumb/logo.png?1658992439"
        },
        {
          "chainId": 56,
          "address": "0xa58950f05fea2277d2608748412bf9f802ea4901",
          "name": "Wall Street Games",
          "symbol": "WSG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15872/thumb/X3Awe42.png?1622181358"
        },
        {
          "chainId": 56,
          "address": "0x00855c21754fe85fd4e38ac23d2b3e091b04a042",
          "name": "YourKiss",
          "symbol": "YKS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25333/thumb/YourKiss_200.png?1651298071"
        },
        {
          "chainId": 56,
          "address": "0x1d84850c9716c5130b114f0795a4552036b55bd4",
          "name": "Anubit",
          "symbol": "ANB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28773/thumb/anubit-200px.png?1674098089"
        },
        {
          "chainId": 56,
          "address": "0xd07e82440a395f3f3551b42da9210cd1ef4f8b24",
          "name": "The Parallel",
          "symbol": "PRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22064/thumb/prl.png?1640744907"
        },
        {
          "chainId": 56,
          "address": "0x7665cb7b0d01df1c9f9b9cc66019f00abd6959ba",
          "name": "Ownly",
          "symbol": "OWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18167/thumb/ownly.PNG?1630883498"
        },
        {
          "chainId": 56,
          "address": "0xcdb96d3aef363a036c6cf6c9b5736d79f0e404e2",
          "name": "InpulseX",
          "symbol": "IPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30112/thumb/inpulse-x.jpg?1683101137"
        },
        {
          "chainId": 56,
          "address": "0x36b24b2f78725495e858af9e72f7df69dade3dca",
          "name": "SeaChain",
          "symbol": "SEACHAIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18466/thumb/seachain_INLINE-logo-nosubtext-nostripes_darkBG01.png?1632114970"
        },
        {
          "chainId": 56,
          "address": "0x8f1408171eae06aec4549fd0a5808a42cee6dd84",
          "name": "Galactic Arena  The NFTverse",
          "symbol": "GAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19925/thumb/gan.png?1636446810"
        },
        {
          "chainId": 56,
          "address": "0x4e5ab517719a2bdbafefc22c712d7b5bc5f5544e",
          "name": "Brick",
          "symbol": "BRICK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16741/thumb/TILE1.png?1649223169"
        },
        {
          "chainId": 56,
          "address": "0xc75cbd824d5d40b30739905e8b857ba091b8bf2a",
          "name": "MetaXCosmos",
          "symbol": "METAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29763/thumb/1212.png?1681181143"
        },
        {
          "chainId": 56,
          "address": "0x10b9dd394467f2cfbc769e07e88dc7e2c41b0965",
          "name": "Renewable Energy",
          "symbol": "RET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25457/thumb/LOGO.png?1651815990"
        },
        {
          "chainId": 56,
          "address": "0x7268b479eb7ce8d1b37ef1ffc3b82d7383a1162d",
          "name": "Meblox Protocol",
          "symbol": "MEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21885/thumb/create-meblox.png?1640215801"
        },
        {
          "chainId": 56,
          "address": "0x1f88e9956c8f8f64c8d5fef5ed8a818e2237112c",
          "name": "YouCoin",
          "symbol": "UCON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29841/thumb/ucoin.jpg?1681719637"
        },
        {
          "chainId": 56,
          "address": "0x700d6eca747892fbad8f51cae938a56b84aae207",
          "name": "Xerium",
          "symbol": "XERM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26284/thumb/Xerium_Logo_200x200_PNG.png?1657077128"
        },
        {
          "chainId": 56,
          "address": "0xee5b03b769ca6c690d140cafb52fc8de3f38fc28",
          "name": "HyperChainX",
          "symbol": "HYPER",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/27847/thumb/IMG_20221015_231037_796.png?1666076945"
        },
        {
          "chainId": 56,
          "address": "0x6aac56305825f712fd44599e59f2ede51d42c3e7",
          "name": "Brewlabs",
          "symbol": "BREWLABS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21928/thumb/7xgmOCBW_400x400.jpg?1640298648"
        },
        {
          "chainId": 56,
          "address": "0x74e2572a1c579b4df80d57e9698098b255f23c9e",
          "name": "Last Survivor",
          "symbol": "LSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23607/thumb/lsc.png?1644746161"
        },
        {
          "chainId": 56,
          "address": "0x02e22eb7f6e73ef313dd71248cd164b1bdc5aadd",
          "name": "Orbitau Taureum",
          "symbol": "TAUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23905/thumb/_Token_TAUM_final.png?1645681470"
        },
        {
          "chainId": 56,
          "address": "0x822c0e73e31b680ee03258655edcff31a90b9d3c",
          "name": "CryptoBill",
          "symbol": "CRB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28687/thumb/CRB-Logo.jpg?1673549331"
        },
        {
          "chainId": 56,
          "address": "0x13c944ef30a2b9b1e4d5cfcec65411278b7b7524",
          "name": "Bullet",
          "symbol": "BLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25951/thumb/101215144.png?1654843384"
        },
        {
          "chainId": 56,
          "address": "0xf3dbb49999b25c9d6641a9423c7ad84168d00071",
          "name": "Hydro",
          "symbol": "HYDRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3678/thumb/hydro-drop.png?1671962568"
        },
        {
          "chainId": 56,
          "address": "0xa4d92138537bb0bbeaeab095381be422d785e7c4",
          "name": "SwapDEX",
          "symbol": "SDXB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13717/thumb/sdx.png?1611116537"
        },
        {
          "chainId": 56,
          "address": "0x7d38315b92d0e7a85b35b2b7fe969b25935619d7",
          "name": "Ecoin Finance",
          "symbol": "ECOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15901/thumb/ecoin.png?1648148099"
        },
        {
          "chainId": 56,
          "address": "0x95a035a4e8738c6f7bf1c2a29996bcd79fcc074f",
          "name": "Rich Santa",
          "symbol": "SANTA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28097/thumb/logo-circle-padding-200px.png?1678504238"
        },
        {
          "chainId": 56,
          "address": "0xfb2c085a8b266b264ff0a38b9687ae14ef8a67fb",
          "name": "HalisWorld",
          "symbol": "HLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29338/thumb/logo.png?1678175325"
        },
        {
          "chainId": 56,
          "address": "0x9babcd3a6f62d9adc709e919d5faa39aa85749fc",
          "name": "Indian Shiba Inu",
          "symbol": "INDSHIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24941/thumb/mvnnSVVU_400x400.jpg?1649424156"
        },
        {
          "chainId": 56,
          "address": "0x43a0c5eb1763a211aa3c05849a617f2ee0452767",
          "name": "PokeDX",
          "symbol": "PDX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19075/thumb/200x200_%2834%29.png?1634289460"
        },
        {
          "chainId": 56,
          "address": "0x4e5ef3493bcfb5e7548913c3f2a40623be7d7f98",
          "name": "Cash Driver",
          "symbol": "CD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28293/thumb/20221123_013230.jpg?1669263396"
        },
        {
          "chainId": 56,
          "address": "0x8f1fe4e6707cd4236b704759d2ee15166c68183a",
          "name": "Tom Coin",
          "symbol": "TMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25849/thumb/65e20074cf9e4bba897431f80da64ffd.jpeg?1654150541"
        },
        {
          "chainId": 56,
          "address": "0x152ad7dc399269fa65d19bd7a790ea8aa5b23dad",
          "name": "Tao Te Ching",
          "symbol": "TTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26621/thumb/5888.png?1659074068"
        },
        {
          "chainId": 56,
          "address": "0x4d9927a8dc4432b93445da94e4084d292438931f",
          "name": "BNBPot",
          "symbol": "BNBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27629/thumb/bnbp-logocircle.png?1664955503"
        },
        {
          "chainId": 56,
          "address": "0x3cf04a59b7bfd6299be4a2104b6e1ca1334750e3",
          "name": "Proton Protocol",
          "symbol": "PROTON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29586/thumb/logo.png?1679884460"
        },
        {
          "chainId": 56,
          "address": "0x4b87f578d6fabf381f43bd2197fbb2a877da6ef8",
          "name": "The Big Five",
          "symbol": "BFT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27429/thumb/photo_2022-08-01_16-49-53.jpg?1663919791"
        },
        {
          "chainId": 56,
          "address": "0x142cdfb01002bbc9b58b387ffdb215642160e081",
          "name": "Lion Fai",
          "symbol": "LIONF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30863/thumb/LionF.jpg?1688368280"
        },
        {
          "chainId": 56,
          "address": "0x53940d46a35162255511ff7cade811891d49533c",
          "name": "OxyO2",
          "symbol": "KRPZA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27591/thumb/oxyo2_logo_200x200.png?1665027489"
        },
        {
          "chainId": 56,
          "address": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
          "name": "Venus",
          "symbol": "XVS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12677/thumb/download.jpg?1648049688"
        },
        {
          "chainId": 56,
          "address": "0x3b76374cc2dfe28cc373dca6d5024791b2586335",
          "name": "ADADao",
          "symbol": "ADAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24372/thumb/dkw8F6mr_400x400.jpg?1647443499"
        },
        {
          "chainId": 56,
          "address": "0x175facdd947c995ad547f6ad952d26826758a4da",
          "name": "Mata",
          "symbol": "MATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22934/thumb/AvPezpY4TzT4sYmaRRELb7Vyg4GfJNv2CX0nmJehBlBpyNoU9onhbM6-HbcXhz_7yhCjkZlNh8H-gPk5R0IMtfZXyKw00Xc4Zvf_EpvjmcD-Q6liwiladoBHVFpJwj0nf4cdLJm71fs6mW_1wd9P68uabxHOb94gDljk_CmrWG4KHNaEEmITOKoUO39Vc55FML6qYoNiyXYGBo.jpg?1643004421"
        },
        {
          "chainId": 56,
          "address": "0x3a986b934724c65741d31119f9613fd6811ee949",
          "name": "ShibZilla2 0",
          "symbol": "SBZ20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31070/thumb/SBZ2.0.jpg?1690247368"
        },
        {
          "chainId": 56,
          "address": "0x989b8f0219eb7aa0bed22e24f053eb2b155f4394",
          "name": "Mommy Doge",
          "symbol": "MOMMYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16956/thumb/icon200x200_%281%29.png?1625795339"
        },
        {
          "chainId": 56,
          "address": "0x14016e85a25aeb13065688cafb43044c2ef86784",
          "name": "Bridged TrueUSD",
          "symbol": "TUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30837/thumb/tusd.jpeg?1687837876"
        },
        {
          "chainId": 56,
          "address": "0x13e1070e3a388e53ec35480ff494538f9ffc5b8d",
          "name": "MyBricks",
          "symbol": "BRICKS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17311/thumb/DEV6onU.png?1627278432"
        },
        {
          "chainId": 56,
          "address": "0x1fc71fe3e333d5262828f3d348c12c7e52306b8a",
          "name": "Glory Token",
          "symbol": "GLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29854/thumb/Logo_icon_200x200.png?1681906976"
        },
        {
          "chainId": 56,
          "address": "0xe5b5d4bea7468b4994fa676949308a79497aa24c",
          "name": "Sheikh Inu",
          "symbol": "SHINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28948/thumb/photo_2023-02-04_17-32-00_prev_ui_%282%29.png?1675572486"
        },
        {
          "chainId": 56,
          "address": "0xdb20f6a8665432ce895d724b417f77ecac956550",
          "name": "EMP Shares  OLD ",
          "symbol": "ESHARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23013/thumb/sEEkbqt.jpeg?1643093201"
        },
        {
          "chainId": 56,
          "address": "0x6f9f0c4ad9af7ebd61ac5a1d4e0f2227f7b0e5f9",
          "name": "Era7",
          "symbol": "ERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24061/thumb/18483.png?1646204630"
        },
        {
          "chainId": 56,
          "address": "0x030ce78aa5be014976bca9b8448e78d1d87fce0b",
          "name": "Big Digital Shares",
          "symbol": "BDS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17877/thumb/60ztTDu.jpg?1629702327"
        },
        {
          "chainId": 56,
          "address": "0x971341c2e487bb51643573bc8b9f08b44dbc92e6",
          "name": "MoonPot Finance",
          "symbol": "MOONPOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28888/thumb/IMG_20230130_210408.png?1675154885"
        },
        {
          "chainId": 56,
          "address": "0x1b391f9d0fffa86a6088a73ac4ac28d12c9ccfbd",
          "name": "Sustainable Energy",
          "symbol": "SET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15592/thumb/new_logo_200.png?1621292943"
        },
        {
          "chainId": 56,
          "address": "0x91d5546564a31ce5f0f4f3302c55f6921e1916af",
          "name": "ShadowFi",
          "symbol": "SDF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30208/thumb/shaowfi.jpeg?1683619224"
        },
        {
          "chainId": 56,
          "address": "0x4027d91ecd3140e53ae743d657549adfeebb27ab",
          "name": "Chain of Legends",
          "symbol": "CLEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26440/thumb/logo1.png?1658108765"
        },
        {
          "chainId": 56,
          "address": "0x1476e96fadb37668d7680921297e2ab98ec36c2f",
          "name": "Floki Rocket",
          "symbol": "RLOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20299/thumb/200-x-200.png?1636816304"
        },
        {
          "chainId": 56,
          "address": "0x8ffdcb0cabccf2767366a2eba6e2fdcc37baa1b2",
          "name": "Kepple",
          "symbol": "KPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30177/thumb/logo_16.png?1683549008"
        },
        {
          "chainId": 56,
          "address": "0x7db21353a0c4659b6a9a0519066aa8d52639dfa5",
          "name": "Joltify",
          "symbol": "JOLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25262/thumb/NSj-jWHR_400x400.jpg?1651044237"
        },
        {
          "chainId": 56,
          "address": "0xd4099a517f2fbe8a730d2ecaad1d0824b75e084a",
          "name": "The Monopolist",
          "symbol": "MONO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21184/thumb/ab05Nt4UN1q5.png?1638512515"
        },
        {
          "chainId": 56,
          "address": "0x28337d750194c17769bf31324512ca4e217174fa",
          "name": "DTNG",
          "symbol": "DTNG",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24128/thumb/dtng.png?1646467707"
        },
        {
          "chainId": 56,
          "address": "0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab",
          "name": "CryptoBlades",
          "symbol": "SKILL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15334/thumb/cryptoblade.PNG?1620596874"
        },
        {
          "chainId": 56,
          "address": "0x6f5b3258c0848e1b9be4c10a4d768db9c735ba12",
          "name": "PepeUSDT",
          "symbol": "PPUSDT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30209/thumb/OUR_LOGO.png?1683619341"
        },
        {
          "chainId": 56,
          "address": "0x747d74db20cc422f39ab54edb2a3ce21f3c98af1",
          "name": "Crypto Global United",
          "symbol": "CGU",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png?1666606977"
        },
        {
          "chainId": 56,
          "address": "0x6f0ad7c4044a3474ccb29caefa182549dc70e802",
          "name": "Social AI",
          "symbol": "SOCIALAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29278/thumb/photo_2023-03-02_13.38.53.jpeg?1677738609"
        },
        {
          "chainId": 56,
          "address": "0x2348b010fa9c0ce30bb042d54c298a3411361a01",
          "name": "Shattered Legion",
          "symbol": "SLG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26436/thumb/shattered_legion.png?1682647718"
        },
        {
          "chainId": 56,
          "address": "0x67915e893b68fbc436a288564fff1476b632b009",
          "name": "Bone",
          "symbol": "BONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24860/thumb/Bone_200_x_200.jpeg?1656560269"
        },
        {
          "chainId": 56,
          "address": "0xa90da9e3c71ddfcc2d793f80029acbd21a4a0db6",
          "name": "GAGARIN",
          "symbol": "GGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28898/thumb/GGR-200x200.png?1675227706"
        },
        {
          "chainId": 56,
          "address": "0x27ae27110350b98d564b9a3eed31baebc82d878d",
          "name": "CumRocket",
          "symbol": "CUMMIES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15088/thumb/cr.png?1635174499"
        },
        {
          "chainId": 56,
          "address": "0xbfbee3dac982148ac793161f7362344925506903",
          "name": "CatzCoin",
          "symbol": "CATZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15519/thumb/MX0hFr7.jpeg?1621118815"
        },
        {
          "chainId": 56,
          "address": "0xd7c5d2a3b7868e6dd539e145c98a565f29ef3fa4",
          "name": "WeGro",
          "symbol": "WEGRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21675/thumb/wegrocoin_logo_favicon.png?1639711396"
        },
        {
          "chainId": 56,
          "address": "0x238f5cc8bd082895d1f832cef967e7bb7ba4f992",
          "name": "QatarGrow",
          "symbol": "QATARGROW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28312/thumb/photo_2022-11-07_08-53-10.png?1669350836"
        },
        {
          "chainId": 56,
          "address": "0xb149b030cfa47880af0bde4cd36539e4c928b3eb",
          "name": "NUTGAIN",
          "symbol": "NUTGV2",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25567/thumb/nutgv2.png?1652527105"
        },
        {
          "chainId": 56,
          "address": "0xa9f059f63cd432b65a723eeeff69304fd780070c",
          "name": "Sanji Inu",
          "symbol": "SANJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28301/thumb/photo_2022-04-04_23-49-34.jpg?1669275180"
        },
        {
          "chainId": 56,
          "address": "0x0573780eb18d5c847d89e745e94149b9e9d0cde8",
          "name": "iStable",
          "symbol": "I-STABLE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28843/thumb/I-STABLE-05.png?1674800723"
        },
        {
          "chainId": 56,
          "address": "0x3e07a8a8f260edeeca24139b6767a073918e8674",
          "name": "Catge Coin",
          "symbol": "CATGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16045/thumb/Pics-Art-05-16-06-08-53.png?1622687404"
        },
        {
          "chainId": 56,
          "address": "0x8717e80eff08f53a45b4a925009957e14860a8a8",
          "name": "BHO Network",
          "symbol": "BHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18625/thumb/12280.png?1650867353"
        },
        {
          "chainId": 56,
          "address": "0xb49988a9ecbf0455b3b43fff7e64960d8399ccb8",
          "name": "BitZipp",
          "symbol": "BZP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19122/thumb/91uivPTI_400x400.png?1634520429"
        },
        {
          "chainId": 56,
          "address": "0xfe073c3b891325ae8686d9cf2c8b3586674f7be2",
          "name": "PokerFi",
          "symbol": "POKERFI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18907/thumb/oy6nhrI0_400x400.jpg?1633910069"
        },
        {
          "chainId": 56,
          "address": "0x155dab50f1dded25c099e209e7b375456a70e504",
          "name": "Mercor Finance",
          "symbol": "MRCR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15938/thumb/6LIc1-Sm_400x400.png?1622459039"
        },
        {
          "chainId": 56,
          "address": "0xf28db5d3ddaa505937cfe27ee52d91da73b1d740",
          "name": "OreoFi",
          "symbol": "OREO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30113/thumb/photo_2022-12-24_17-22-46.jpg?1683101176"
        },
        {
          "chainId": 56,
          "address": "0x68d10dfe87a838d63bbef6c9a0d0b44beb799dc1",
          "name": "MagnetGold",
          "symbol": "MTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19302/thumb/mtg.PNG?1634917649"
        },
        {
          "chainId": 56,
          "address": "0x71d03a620646f8b572282ef39228d36add67ee20",
          "name": "Sekuya",
          "symbol": "SKUY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23788/thumb/VlXE8u0E_400x400.jpg?1685433152"
        },
        {
          "chainId": 56,
          "address": "0x4437743ac02957068995c48e08465e0ee1769fbe",
          "name": "Fortress Loans",
          "symbol": "FTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15036/thumb/FTS_logo_200x200.png?1619504869"
        },
        {
          "chainId": 56,
          "address": "0xc8b44fc9e6b8fd806111a04b1f208a0087baf9b1",
          "name": "Galaxy Finance GLF",
          "symbol": "GLF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28609/thumb/glx200.png?1672392498"
        },
        {
          "chainId": 56,
          "address": "0xc003f5193cabe3a6cbb56948dfeaae2276a6aa5e",
          "name": "TruBadger",
          "symbol": "TRUBGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16398/thumb/trubadger_logo.PNG?1632984133"
        },
        {
          "chainId": 56,
          "address": "0x4f0ed527e8a95ecaa132af214dfd41f30b361600",
          "name": "vBSWAP",
          "symbol": "VBSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14133/thumb/vbswap_22.png?1614601485"
        },
        {
          "chainId": 56,
          "address": "0xc74cd0042c837ce59210857504ebb0859e06aa22",
          "name": "SAFU Protocol",
          "symbol": "SAFU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17021/thumb/safuyield.png?1626098281"
        },
        {
          "chainId": 56,
          "address": "0xb10be3f4c7e1f870d86ed6cfd412fed6615feb6f",
          "name": "Privateum Global",
          "symbol": "PRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14995/thumb/c9C2piFC_400x400.png?1654586618"
        },
        {
          "chainId": 56,
          "address": "0xf24d63e8b354736c97148b8fc2ffb4f7789fe453",
          "name": "CakeSwap",
          "symbol": "CAKESWAP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21953/thumb/BRZnDZMh.png?1640324332"
        },
        {
          "chainId": 56,
          "address": "0x6519cb1f694ccbcc72417570b364f2d051eefb9d",
          "name": "NoLimitCoin",
          "symbol": "NLC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/758/thumb/nolimitcoin.png?1548329142"
        },
        {
          "chainId": 56,
          "address": "0xc94595b56e301f3ffedb8ccc2d672882d623e53a",
          "name": "ACY Finance",
          "symbol": "ACY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg?1640214613"
        },
        {
          "chainId": 56,
          "address": "0x9c2b1b3780a8b36b695f0b2781668664ac1bf25a",
          "name": "SpookyShiba",
          "symbol": "SPKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26324/thumb/H2vW_x5A_400x400.jpeg?1657249034"
        },
        {
          "chainId": 56,
          "address": "0x45289007706e7ee7b42b1fa506661d97740edfb4",
          "name": "FLOKI CEO",
          "symbol": "FLOKICEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29199/thumb/IMG_20230225_005553_020.jpg?1677305978"
        },
        {
          "chainId": 56,
          "address": "0xae7c682ba26ad6835b6150ffb35f22db9987f509",
          "name": "Infinity Games",
          "symbol": "ING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27651/thumb/ing.png?1672986642"
        },
        {
          "chainId": 56,
          "address": "0xa6630b22974f908a98a8139cb12ec2ebabfbe9d4",
          "name": "ILUS Coin",
          "symbol": "ILUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22662/thumb/Jez4bAM4.png?1642402319"
        },
        {
          "chainId": 56,
          "address": "0x9953170dcaac530ad7d6949c7295207c6ec5669d",
          "name": "MetaDoge V2",
          "symbol": "METADOGEV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20053/thumb/metadoge.png?1636435934"
        },
        {
          "chainId": 56,
          "address": "0xdd041e030ade3db3b2221ce681b65f9650f250d7",
          "name": "Biblecoin",
          "symbol": "BIBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26406/thumb/tLvtI1NT_400x400.jpeg?1657924146"
        },
        {
          "chainId": 56,
          "address": "0x5b6bf0c7f989de824677cfbd507d9635965e9cd3",
          "name": "Gamium",
          "symbol": "GMM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22749/thumb/14304.png?1642557454"
        },
        {
          "chainId": 56,
          "address": "0x7b86b0836f3454e50c6f6a190cd692bb17da1928",
          "name": "Bemil Coin",
          "symbol": "BEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20896/thumb/12878.png?1637875878"
        },
        {
          "chainId": 56,
          "address": "0x71f69afed8825d6d9300ba4d74103e1dcc263b93",
          "name": "Simpli Finance",
          "symbol": "SIMPLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21264/thumb/iT_XqHrd_400x400.jpg?1638837165"
        },
        {
          "chainId": 56,
          "address": "0x1991501f1398663f69dd7391c055bb0df6514f76",
          "name": "HotDoge  OLD ",
          "symbol": "HOTDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15404/thumb/525ea2_88a58501323f44c7a806c008ae2b4858_mv2.png?1622197208"
        },
        {
          "chainId": 56,
          "address": "0xdfdec49462f7d3c3b0a48e729f77a0645cdfa7c0",
          "name": "Safegem",
          "symbol": "GEMS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15550/thumb/safegem_avatar_200x200.png?1656317802"
        },
        {
          "chainId": 56,
          "address": "0xe919facc09ce21f98d1693e9781af9ea61460e2a",
          "name": "Infinity Box",
          "symbol": "IBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29941/thumb/yMaHVj13_400x400.jpg?1682308074"
        },
        {
          "chainId": 56,
          "address": "0x70b6c6a555507ee4ac91c15e5c80b7dc8ff3b489",
          "name": "XTblock",
          "symbol": "XTT-B20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17921/thumb/XTblock-Icon-round-200.png?1632122725"
        },
        {
          "chainId": 56,
          "address": "0x1606940bb5cd6de59a7e25367f4fb8965f38f122",
          "name": "Aqua Goat",
          "symbol": "AQUAGOAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18676/thumb/ag.png?1634864671"
        },
        {
          "chainId": 56,
          "address": "0x7db13e8b9eaa42fc948268b954dd4e6218cc4cb1",
          "name": "Fight Win AI",
          "symbol": "FWIN-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29006/thumb/IMG_20230208_235738_212.png?1675918095"
        },
        {
          "chainId": 56,
          "address": "0xf7bcac494fd9530c183dae30db48d45144ff2c77",
          "name": "Crypto Classic",
          "symbol": "CRC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19813/thumb/5cd5E8xZ_400x400.jpg?1635922329"
        },
        {
          "chainId": 56,
          "address": "0x31471e0791fcdbe82fbf4c44943255e923f1b794",
          "name": "Plant vs Undead",
          "symbol": "PVU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17461/thumb/token-200x200.png?1627883446"
        },
        {
          "chainId": 56,
          "address": "0xf300e4f1a193dd047b0c6747ad4e16dedf886297",
          "name": "Gaming Stars",
          "symbol": "GAMES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1729/thumb/logo-yt_1_1_1_200x200.png?1650868415"
        },
        {
          "chainId": 56,
          "address": "0x8263cd1601fe73c066bf49cc09841f35348e3be0",
          "name": "Altura",
          "symbol": "ALU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15127/thumb/logo-dark.png?1667292591"
        },
        {
          "chainId": 56,
          "address": "0x3405ff989f8bb8efd6c85ad6b29219d3383a5fb0",
          "name": "Metaverse Kombat",
          "symbol": "MVK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30180/thumb/Untitled_design_%282%29.png?1683598415"
        },
        {
          "chainId": 56,
          "address": "0xb86126b872e3f23bc62d6a4a3152ec65202a2073",
          "name": "Pin Token",
          "symbol": "PIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30988/thumb/coin.png?1689563622"
        },
        {
          "chainId": 56,
          "address": "0xa78628ecba2bf5fedf3fe27a7cedaa363b46708f",
          "name": "Little Bunny Rocket",
          "symbol": "LBR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19233/thumb/lrb.PNG?1634768519"
        },
        {
          "chainId": 56,
          "address": "0x48ee0cc862143772feabaf9b4757c36735d1052e",
          "name": "AtomPad",
          "symbol": "ATPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20700/thumb/PU0GdfUG_400x400.jpg?1637565993"
        },
        {
          "chainId": 56,
          "address": "0x37ac4d6140e54304d77437a5c11924f61a2d976f",
          "name": "SparkPoint Fuel",
          "symbol": "SFUEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13564/thumb/SFUEL.png?1614675479"
        },
        {
          "chainId": 56,
          "address": "0xae20bc46300bab5d85612c6bc6ea87ea0f186035",
          "name": "CrossFi",
          "symbol": "CRFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png?1620006681"
        },
        {
          "chainId": 56,
          "address": "0x8578eb576e126f67913a8bc0622e0a22eba0989a",
          "name": "HashPanda",
          "symbol": "PANDA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15903/thumb/logo_-_2021-05-31T060013.983.png?1622412025"
        },
        {
          "chainId": 56,
          "address": "0x29132062319aa375e764ef8ef756f2b28c77a9c9",
          "name": "BlokPad",
          "symbol": "BPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23309/thumb/bpad.PNG?1643752909"
        },
        {
          "chainId": 56,
          "address": "0x3a91a88ef4bb813bb99ef5a745bcb56e3a3b403b",
          "name": "Income Island",
          "symbol": "INCOME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21125/thumb/income_island.PNG?1638342832"
        },
        {
          "chainId": 56,
          "address": "0xf8759de7f2c8d3f32fd8f8e4c0c02d436a05ddeb",
          "name": "Urubit",
          "symbol": "URUB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19902/thumb/54UYxwFE_400x400.jpg?1636099182"
        },
        {
          "chainId": 56,
          "address": "0xf5d8a096cccb31b9d7bce5afe812be23e3d4690d",
          "name": "HappyFans",
          "symbol": "HAPPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19027/thumb/O1pUoR8G_400x400.jpeg?1654248996"
        },
        {
          "chainId": 56,
          "address": "0x6cad12b3618a3c7ef1feb6c91fdc3251f58c2a90",
          "name": "Ninneko",
          "symbol": "NINO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19936/thumb/CXZox2iX_400x400.jpg?1636327291"
        },
        {
          "chainId": 56,
          "address": "0x52721d159cd90dd76014f73c1440e4ff983420ac",
          "name": "Troll Face",
          "symbol": "TROLL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30246/thumb/photo_2023-05-04_16-06-17.jpg?1683694224"
        },
        {
          "chainId": 56,
          "address": "0x87e0ce18ce0ce0a86b22537b48c15e03a519b112",
          "name": "Shinjiru Inu",
          "symbol": "SHINJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28851/thumb/logo_%282%29_%282%29_%281%29_%281%29.png?1674891283"
        },
        {
          "chainId": 56,
          "address": "0x2cd14cba3f26254beed1d78158cd2b6f91809600",
          "name": "Genshiro",
          "symbol": "GENS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16444/thumb/genshiro.PNG?1624234640"
        },
        {
          "chainId": 56,
          "address": "0xaa3ed6e6ea3ed78d4d57e373aabd6f54df5bb508",
          "name": "NGATiger",
          "symbol": "NGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29633/thumb/nga200.png?1680158811"
        },
        {
          "chainId": 56,
          "address": "0xe68b79e51bf826534ff37aa9cee71a3842ee9c70",
          "name": "CZUSD",
          "symbol": "CZUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24048/thumb/czusd200.png?1679984442"
        },
        {
          "chainId": 56,
          "address": "0xe8c93310af068aa50bd7bf0ebfa459df2a02ceba",
          "name": "HoneyMOON",
          "symbol": "MOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18187/thumb/download_%2833%29.png?1630906145"
        },
        {
          "chainId": 56,
          "address": "0x5275e602238c85b204413116c1057aff5c60b282",
          "name": "VINCI",
          "symbol": "VINCI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30643/thumb/IMG_6935.png?1686037743"
        },
        {
          "chainId": 56,
          "address": "0x350494bcc94efb5c6080f6a6f0043da27be2ad2c",
          "name": "DFS Mafia V2",
          "symbol": "DFSM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27931/thumb/dfsm.png?1666426557"
        },
        {
          "chainId": 56,
          "address": "0x43b35e89d15b91162dea1c51133c4c93bdd1c4af",
          "name": "Sakai Vault",
          "symbol": "SAKAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29128/thumb/96264149-D876-4069-BFE1-9346-A01-E85-B6.jpg?1679289313"
        },
        {
          "chainId": 56,
          "address": "0xb7dacf54a54bfea818f21472d3e71a89287841a7",
          "name": "WealthSecrets",
          "symbol": "WSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20106/thumb/logo-box.png?1636512377"
        },
        {
          "chainId": 56,
          "address": "0xeb33cbbe6f1e699574f10606ed9a495a196476df",
          "name": "DeFi Coin",
          "symbol": "DEFC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17020/thumb/deficoin.png?1626094280"
        },
        {
          "chainId": 56,
          "address": "0x8c11c352731fcec7ea9d16357b69d91c13743dd1",
          "name": "RevolutionGames",
          "symbol": "RVLNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24420/thumb/XQokIAeH_400x400.jpg?1647612029"
        },
        {
          "chainId": 56,
          "address": "0x95b0fffabd2817959ce410070600d77bce93d454",
          "name": "Shockwaves",
          "symbol": "NEUROS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30105/thumb/NEUROS_Logo.png?1683083293"
        },
        {
          "chainId": 56,
          "address": "0xde83180dd1166d4f8e5c2b7de14a2163b1bb4a87",
          "name": "Diamond Launch",
          "symbol": "DLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27323/thumb/dlc.png?1663320489"
        },
        {
          "chainId": 56,
          "address": "0x10f6f2b97f3ab29583d9d38babf2994df7220c21",
          "name": "Teddy Doge",
          "symbol": "TEDDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23389/thumb/17859.png?1644112621"
        },
        {
          "chainId": 56,
          "address": "0x7e3784220740e61dc700501bd6771226e11d8897",
          "name": "Cyber Arena",
          "symbol": "CAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30589/thumb/Coin-200x200_%281%29.png?1685497117"
        },
        {
          "chainId": 56,
          "address": "0x211fa9e7e390c29b0ab1a9248949a0ab716c4154",
          "name": "Mother of Memes",
          "symbol": "MOM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22388/thumb/bQ1PGpaR_400x400.jpg?1641790045"
        },
        {
          "chainId": 56,
          "address": "0xdaca7c8e16ddfa5d5b266380228ca9e2288f3931",
          "name": "DYZilla",
          "symbol": "DYZILLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28532/thumb/Dyzilla.png?1671439769"
        },
        {
          "chainId": 56,
          "address": "0x8b62e98b3ad434cc6491c8d0dd97da05e7710fcb",
          "name": "Oreto Network",
          "symbol": "ORT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30024/thumb/IMG_20230426_014937_239.png?1682569640"
        },
        {
          "chainId": 56,
          "address": "0x85cec9d09529c4239dcd89018889238abdd3ede6",
          "name": "FIDELIS",
          "symbol": "FDLS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24778/thumb/fdls.png?1648900950"
        },
        {
          "chainId": 56,
          "address": "0xb9d35811424600fa9e8cd62a0471fbd025131cb8",
          "name": "Yes World",
          "symbol": "YES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26492/thumb/Yes-World-200-X-200-Pixl-PNG.png?1658284131"
        },
        {
          "chainId": 56,
          "address": "0x7d4984490c4c68f8ead9dddca6d04c514ef77324",
          "name": "Golden Inu",
          "symbol": "GOLDEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29583/thumb/200x200_logo_golden_inu.png?1679818052"
        },
        {
          "chainId": 56,
          "address": "0x6bf2be9468314281cd28a94c35f967cafd388325",
          "name": "Synth oUSD",
          "symbol": "OUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16830/thumb/cUixn42.png?1625580669"
        },
        {
          "chainId": 56,
          "address": "0x086ddd008e20dd74c4fb216170349853f8ca8289",
          "name": "MxmBoxcEus Token",
          "symbol": "MBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28418/thumb/MBE.png?1670457722"
        },
        {
          "chainId": 56,
          "address": "0xcdf204cbbaa96ed34be4497d6794dfb54e4c66bc",
          "name": "Virtual Reality Glasses",
          "symbol": "VRG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28648/thumb/IMG_20230104_154530_217.png?1672887783"
        },
        {
          "chainId": 56,
          "address": "0xca830317146bfdde71e7c0b880e2ec1f66e273ee",
          "name": "PolyGod",
          "symbol": "GULL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20590/thumb/polygod.PNG?1637275789"
        },
        {
          "chainId": 56,
          "address": "0xd3987cb8a59e8ef6aab0d95b92254344ed9c3c6f",
          "name": "WEBFOUR",
          "symbol": "WEBFOUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22323/thumb/WebFourLogoNoText.png?1641457540"
        },
        {
          "chainId": 56,
          "address": "0x15d1dafbcc97f606bd02120d170fdac33b1a4a86",
          "name": "CashBackPro",
          "symbol": "CBP",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/12893/thumb/logo_%2818%29.png?1603766120"
        },
        {
          "chainId": 56,
          "address": "0xb27607d439751555003506455dd9e763a53e5b1d",
          "name": "Timeseries AI",
          "symbol": "TIMESERIES",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29516/thumb/timeseries_200.png?1679394016"
        },
        {
          "chainId": 56,
          "address": "0x1446f3cedf4d86a9399e49f7937766e6de2a3aab",
          "name": "KROWN",
          "symbol": "KRW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058"
        },
        {
          "chainId": 56,
          "address": "0xb3ba14f6a482dfdebc3c2fb726ac10df91ee504c",
          "name": "City Tycoon Games",
          "symbol": "CTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28118/thumb/ctg.png?1667566182"
        },
        {
          "chainId": 56,
          "address": "0x4fdd92bd67acf0676bfc45ab7168b3996f7b4a3b",
          "name": "Revault Network",
          "symbol": "REVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19048/thumb/61920010aaba4d16b1d57215_gradient-bg_200.png?1637155545"
        },
        {
          "chainId": 56,
          "address": "0x88c55b3255ae1e6628c953c5cdff27ad3cc33c81",
          "name": "My DeFi Legends",
          "symbol": "DLEGENDS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18006/thumb/my_defi_legends.png?1630157431"
        },
        {
          "chainId": 56,
          "address": "0x69bfa36d50d92e8985d27e6aa6e685c0325ce7b4",
          "name": "Cryptorg",
          "symbol": "CTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11474/thumb/crystal_200.png?1590450209"
        },
        {
          "chainId": 56,
          "address": "0xcbe5bca571628894a38836b0bae833ff012f71d8",
          "name": "Infinity Rocket",
          "symbol": "IRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20619/thumb/irt.PNG?1637317783"
        },
        {
          "chainId": 56,
          "address": "0x9202de1818d6e366fe09428244ef9d3152fb24d7",
          "name": "Pig Inu",
          "symbol": "PIGINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29742/thumb/PGINU.png?1681096785"
        },
        {
          "chainId": 56,
          "address": "0xfe8e0e9a7a5ae744aaebeac38e3b9b1da7b17af3",
          "name": "WatchDO",
          "symbol": "WDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22279/thumb/4409.jpg?1641364025"
        },
        {
          "chainId": 56,
          "address": "0x0aa086e7a108d387de63294fe2a88b05820a9855",
          "name": "MMOCoin",
          "symbol": "MMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4364/thumb/xWGfHzg.png?1547039713"
        },
        {
          "chainId": 56,
          "address": "0x3910db0600ea925f63c36ddb1351ab6e2c6eb102",
          "name": "Spartan Protocol",
          "symbol": "SPARTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12638/thumb/coin_sparta_black_bg.png?1622515206"
        },
        {
          "chainId": 56,
          "address": "0x546dded7c535e192c4132f5c998db017af824d96",
          "name": "Baby Pepe",
          "symbol": "BABYPEPE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29990/thumb/200x200.png?1682415517"
        },
        {
          "chainId": 56,
          "address": "0x9bedce29f79076b21dd04958a9fd4b22f63fd86d",
          "name": "Freebie Life Finance",
          "symbol": "FRB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png?1656942812"
        },
        {
          "chainId": 56,
          "address": "0x90422d35496e8ed3391971dbec894e4a8057081f",
          "name": "Idle Ninja Online",
          "symbol": "NINKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22762/thumb/NINKY.png?1642574339"
        },
        {
          "chainId": 56,
          "address": "0x91c1f07b7815d68c176321ead61d7bfae211d392",
          "name": "Pine World",
          "symbol": "PWLC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29518/thumb/PWLC200.png?1679403772"
        },
        {
          "chainId": 56,
          "address": "0x2b2ff80c489dad868318a19fd6f258889a026da5",
          "name": "Dexit Network",
          "symbol": "DXT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17611/thumb/Dexit_Logo.png?1645084928"
        },
        {
          "chainId": 56,
          "address": "0x2167afa1c658dc5c4ec975f4af608ff075a8b8ae",
          "name": "Evai",
          "symbol": "EV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25313/thumb/EVAI_LOGO.png?1667522856"
        },
        {
          "chainId": 56,
          "address": "0x31801b15215c021e7988fa0bc37dcfa6a303bd00",
          "name": "Shihtzu Exchange",
          "symbol": "STZU",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27599/thumb/hero-shihtzu_2.png?1664715424"
        },
        {
          "chainId": 56,
          "address": "0xedd52d44de950ccc3b2e6abdf0da8e99bb0ec480",
          "name": "Crazy Tiger",
          "symbol": "CRAZYTIGER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29820/thumb/output-onlinepngtools_%281%29.png?1681447848"
        },
        {
          "chainId": 56,
          "address": "0x5ca4e7294b14ea5745ee2a688990e0cb68503219",
          "name": "Green Beli",
          "symbol": "GRBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18776/thumb/Logo-200x200_%288%29.png?1633402162"
        },
        {
          "chainId": 56,
          "address": "0x921d3a6ed8223afb6358410f717e2fb13cbae700",
          "name": "Qrkita",
          "symbol": "QRT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19320/thumb/logo_-_2021-10-25T080744.116.png?1635120472"
        },
        {
          "chainId": 56,
          "address": "0xd20738760aededa73f6cd91a3d357746e0283a0e",
          "name": "Tanks",
          "symbol": "TANKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19819/thumb/tank-200.png?1635930932"
        },
        {
          "chainId": 56,
          "address": "0xc1a59a17f87ba6651eb8e8f707db7672647c45bd",
          "name": "Lunar",
          "symbol": "LNR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27664/thumb/LNRTokenLogo2_%281%29.png?1665479443"
        },
        {
          "chainId": 56,
          "address": "0x181c0f81d56102f64ec805ca444638b18a191db3",
          "name": "PEPE DAO",
          "symbol": "PEPED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30286/thumb/IMG_20230510_010937_683.jpg?1683864154"
        },
        {
          "chainId": 56,
          "address": "0x6af2f57f61cec0883c71f3175774ebeb290a10e6",
          "name": "Scientia",
          "symbol": "SCIE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20450/thumb/scienta.PNG?1637044446"
        },
        {
          "chainId": 56,
          "address": "0x8182ac1c5512eb67756a89c40fadb2311757bd32",
          "name": "Nether",
          "symbol": "NTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18009/thumb/Icon_-_Logo_600x600px.png?1630228246"
        },
        {
          "chainId": 56,
          "address": "0x794a23b6b8a543b58f995ba590979e0785b60eb2",
          "name": "Gateway Protocol",
          "symbol": "GWP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24093/thumb/gwp.PNG?1646353570"
        },
        {
          "chainId": 56,
          "address": "0xf483af09917ba63f1e274056978036d266eb56e6",
          "name": "Bull Coin",
          "symbol": "BULL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15975/thumb/bull_ms.94cd70ff.png?1622525834"
        },
        {
          "chainId": 56,
          "address": "0xbbdac6ca30ba9189c7bf67a1f7160379f7e25835",
          "name": "ViteX Coin",
          "symbol": "VX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10346/thumb/ViteX.png?1578364192"
        },
        {
          "chainId": 56,
          "address": "0x4f3266a56589357b4f8082918b14b923693e57f0",
          "name": "Liquid Collectibles",
          "symbol": "LICO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19772/thumb/AjjErjeF_400x400.png?1635838249"
        },
        {
          "chainId": 56,
          "address": "0x6cb755c4b82e11e727c05f697c790fdbc4253957",
          "name": "9D NFT",
          "symbol": "COGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19619/thumb/COGI_coin.png?1635496367"
        },
        {
          "chainId": 56,
          "address": "0x4819c2e71ebdd5e3c91d8b735ccea8fd37f89be5",
          "name": "CEASports",
          "symbol": "CSPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28528/thumb/Logo_Ceasports_new.png?1671680118"
        },
        {
          "chainId": 56,
          "address": "0x5e5c9001aa81332d405d993ffd1468751d659d1e",
          "name": "Baby Doge Inu",
          "symbol": "BABYDOGEINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17012/thumb/KEtLxnLH_400x400.jpg?1633713669"
        },
        {
          "chainId": 56,
          "address": "0x15b09ca8a1ef990c726d1fd82939a7cf9a48d5c8",
          "name": "Business Universe",
          "symbol": "BUUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29162/thumb/transparan1_%283%29.png?1677043264"
        },
        {
          "chainId": 56,
          "address": "0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700",
          "name": "BSCPAD",
          "symbol": "BSCPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14135/thumb/bscpad_logo.jpg?1614611664"
        },
        {
          "chainId": 56,
          "address": "0x7559c49c3aec50e763a486bb232fa8d0d76078e4",
          "name": "Artrade",
          "symbol": "ATR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24894/thumb/ATR-BSC.png?1670063604"
        },
        {
          "chainId": 56,
          "address": "0xd8126b749e4ec149c97bffbe875ab9960bdb8916",
          "name": "Hey Floki Ai",
          "symbol": "A2E",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29178/thumb/200x200.png?1677141478"
        },
        {
          "chainId": 56,
          "address": "0xe0387845f8289fd5875e7193064392e061f46e58",
          "name": "BSDium",
          "symbol": "BSCD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24753/thumb/BSCD.png?1648787100"
        },
        {
          "chainId": 56,
          "address": "0x968f9c44879f67a29b1bfccf93ea82d46a72881f",
          "name": "Orclands Metaverse",
          "symbol": "ORC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22720/thumb/favicon-200x200.png?1642486261"
        },
        {
          "chainId": 56,
          "address": "0x824a50df33ac1b41afc52f4194e2e8356c17c3ac",
          "name": "Kick",
          "symbol": "KICK",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/981/thumb/kick.png?1568643013"
        },
        {
          "chainId": 56,
          "address": "0x8fff93e810a2edaafc326edee51071da9d398e83",
          "name": "Bitgert",
          "symbol": "BRISE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17388/thumb/bitgert.png?1660272192"
        },
        {
          "chainId": 56,
          "address": "0x63290fc683d11ea077aba09596ff7387d49df912",
          "name": "Ramifi Protocol",
          "symbol": "RAM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14602/thumb/76572462.png?1618003618"
        },
        {
          "chainId": 56,
          "address": "0x19af9dab517746257dba18fcd74f7df5383bdf1b",
          "name": "Pomo",
          "symbol": "POMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25355/thumb/200x200.png?1651312794"
        },
        {
          "chainId": 56,
          "address": "0x808fac147a9c02723d0be300ac4753eaf93c0e1f",
          "name": "Baby Floki Coin",
          "symbol": "BABYFLOKICOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18882/thumb/n8hZe-5I_400x400.jpg?1633693483"
        },
        {
          "chainId": 56,
          "address": "0xf29480344d8e21efeab7fde39f8d8299056a7fea",
          "name": "TBCC",
          "symbol": "TBCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13353/thumb/200-200.png?1625755548"
        },
        {
          "chainId": 56,
          "address": "0x1603441703472aff8cdf1871961176cc494588dc",
          "name": "Finance AI",
          "symbol": "FINANCEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29113/thumb/B351-E2-C5-8-F1-C-404-D-854-C-068996-C1-B476.jpg?1676693388"
        },
        {
          "chainId": 56,
          "address": "0x780207b8c0fdc32cf60e957415bfa1f2d4d9718c",
          "name": "Cashaa",
          "symbol": "CAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2979/thumb/Logo.png?1564502509"
        },
        {
          "chainId": 56,
          "address": "0x239ec95667e37929d33066a8df8ddc9444dbcbca",
          "name": "DfiStarter",
          "symbol": "DFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19037/thumb/Dfistarter-LOGO-4-02.png?1634202836"
        },
        {
          "chainId": 56,
          "address": "0xa43d3e03d001492eb586db0990cb90d0c3bbe511",
          "name": "Coinlocally",
          "symbol": "CLYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28808/thumb/light.png?1674304252"
        },
        {
          "chainId": 56,
          "address": "0x444ddc9ab6e938511fdd56f89ec334d38cadee0f",
          "name": "RxcDna",
          "symbol": "DNA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24892/thumb/dna-icon.png?1649285788"
        },
        {
          "chainId": 56,
          "address": "0x33714356e2a3e216d055440eb24d0e23458b1b85",
          "name": "SafeZone",
          "symbol": "SAFEZONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28204/thumb/ykjBAkw9_400x400.jpeg?1668392527"
        },
        {
          "chainId": 56,
          "address": "0x6ca5fac496bf94345958635e6e6171dfe78f36bb",
          "name": "MoonsDust",
          "symbol": "MOOND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22050/thumb/moondlogo.png?1640676772"
        },
        {
          "chainId": 56,
          "address": "0x75bded6bf44bb01527138673dcc064dbe3e7d96d",
          "name": "Etermon",
          "symbol": "ETM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21694/thumb/Etermon-Logo-200x200.png?1639721390"
        },
        {
          "chainId": 56,
          "address": "0x580cf2c36b913228dd0194a833f0ead8938f18ae",
          "name": "Kitty",
          "symbol": "KIT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/19378/thumb/13019.png?1635141889"
        },
        {
          "chainId": 56,
          "address": "0xd7730681b1dc8f6f969166b29d8a5ea8568616a3",
          "name": "Nafter",
          "symbol": "NAFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15493/thumb/Nafter_White_logo_1_200x200.png?1621047922"
        },
        {
          "chainId": 56,
          "address": "0xe5977835a013e3a5a52f44f8422734bd2dc545f0",
          "name": "Balto Token",
          "symbol": "BALTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28928/thumb/Balto-Token-logo_sq.png?1675489816"
        },
        {
          "chainId": 56,
          "address": "0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f",
          "name": "WolfSafePoorPeople",
          "symbol": "WSPP",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/17090/thumb/wspplogo.png?1626187594"
        },
        {
          "chainId": 56,
          "address": "0xcf87ccf958d728f50d8ae5e4f15bc4ca5733cdf5",
          "name": "Atlantis",
          "symbol": "ATLAS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20922/thumb/15211.png?1637926873"
        },
        {
          "chainId": 56,
          "address": "0xd675ff2b0ff139e14f86d87b7a6049ca7c66d76e",
          "name": "Defily",
          "symbol": "DFL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18261/thumb/defily.PNG?1631157196"
        },
        {
          "chainId": 56,
          "address": "0xb3a95bdbe4ac65b0628db1e6600f71ed59b89255",
          "name": "Unityventures",
          "symbol": "UV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19673/thumb/IMG_0302_%281%29.JPG?1678945248"
        },
        {
          "chainId": 56,
          "address": "0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0",
          "name": "Venus SXP",
          "symbol": "VSXP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13918/thumb/sxp.png?1612844350"
        },
        {
          "chainId": 56,
          "address": "0xbfb1a68962fb4ed040fd3a0a71dc2c2015bcc667",
          "name": "4JNET",
          "symbol": "4JNET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21145/thumb/4jnet.PNG?1638399067"
        },
        {
          "chainId": 56,
          "address": "0xa64455a4553c9034236734faddaddbb64ace4cc7",
          "name": "Santos FC Fan Token",
          "symbol": "SANTOS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21132/thumb/santos.png?1638357080"
        },
        {
          "chainId": 56,
          "address": "0xd70d1dd91ecd79c6f8de86924571a454ff587818",
          "name": "SeedLaunch",
          "symbol": "SLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29673/thumb/logosl.png?1680574970"
        },
        {
          "chainId": 56,
          "address": "0x71e72dde4152d274afd1f2db43531ed9e44a78fa",
          "name": "LordToken",
          "symbol": "LTT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24193/thumb/D8YzkZxo_400x400.png?1646829681"
        },
        {
          "chainId": 56,
          "address": "0x5755e18d86c8a6d7a6e25296782cb84661e6c106",
          "name": "SideKick",
          "symbol": "SK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16295/thumb/kyvXrEK.png?1623665144"
        },
        {
          "chainId": 56,
          "address": "0xaa88fd757fa81ebbbce0eb1f324172d0e446093e",
          "name": "Reign of Terror",
          "symbol": "REIGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29804/thumb/Reign_of_Terror_Symbol.png?1681358308"
        },
        {
          "chainId": 56,
          "address": "0xfef0c1670cb569008bb3d070918922dbb4f92de4",
          "name": "EverSAFUv2",
          "symbol": "ES2",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/27878/thumb/IMG_20221018_193306_362.png?1666230019"
        },
        {
          "chainId": 56,
          "address": "0x62175af6d9b045d8435cdedd9bf542c7bcc56dcc",
          "name": "Safe",
          "symbol": "SAFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22451/thumb/safelogo_%282%29.png?1641824877"
        },
        {
          "chainId": 56,
          "address": "0x36dbcbca106353d49e1e0e8974492ffb862a0c92",
          "name": "SafeMeme",
          "symbol": "SME",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15529/thumb/safememe.png?1621134539"
        },
        {
          "chainId": 56,
          "address": "0xe5ba47fd94cb645ba4119222e34fb33f59c7cd90",
          "name": "SAFUU",
          "symbol": "SAFUU",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/24290/thumb/86tZ8IKj_400x400.jpg?1647257304"
        },
        {
          "chainId": 56,
          "address": "0x4ac81e3631dcda62109e3117c4cae7bf70bbbbd2",
          "name": "SavePlanetEarth",
          "symbol": "SPE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19759/thumb/spe.png?1635829598"
        },
        {
          "chainId": 56,
          "address": "0xe6906717f129427eebade5406de68cadd57aa0c0",
          "name": "Galaxy Blitz",
          "symbol": "MIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23003/thumb/gJ21fw3N_400x400.jpg?1643091458"
        },
        {
          "chainId": 56,
          "address": "0x0151f08a29142e07d075664e2ecf3c949635c31e",
          "name": "Shibot",
          "symbol": "SHIBOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29695/thumb/SHIBOT_LOGO_512.jpg?1680681466"
        },
        {
          "chainId": 56,
          "address": "0x0851ad49cff57c024594da73095e6e05d8b1676a",
          "name": "Optimism PEPE",
          "symbol": "OPEPE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29987/thumb/OPEPE.png?1682411572"
        },
        {
          "chainId": 56,
          "address": "0xa310017e40e687c8670d218e3c86a0d09786574f",
          "name": "Yoda Coin Swap",
          "symbol": "JEDALS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26117/thumb/YODA_COIN_SWAP_200x200.png?1655939290"
        },
        {
          "chainId": 56,
          "address": "0x3b2cb8b2a9baf200142456c550a328e6c45c176b",
          "name": "Forever Burn",
          "symbol": "FBURN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28156/thumb/Forever_Burn.jpeg?1668161617"
        },
        {
          "chainId": 56,
          "address": "0x07728696ee70a28c9c032926577af1d524df30f9",
          "name": "PlaceWar Governance",
          "symbol": "PLACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19993/thumb/place.png?1636361494"
        },
        {
          "chainId": 56,
          "address": "0x1a9b49e9f075c37fe5f86c916bac9deb33556d7e",
          "name": "ASPO World",
          "symbol": "ASPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20329/thumb/12599.png?1636900554"
        },
        {
          "chainId": 56,
          "address": "0x167fcfed3aad2d11052fcde0cbf704d879939473",
          "name": "Triathon",
          "symbol": "GEON",
          "decimals": 18
        },
        {
          "chainId": 56,
          "address": "0x38ffa52c7628f5ccf871472e40c462e4483215c9",
          "name": "SwirlToken  OLD ",
          "symbol": "SWIRL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22575/thumb/swirl200.png?1679455443"
        },
        {
          "chainId": 56,
          "address": "0xd71239a33c8542bd42130c1b4aca0673b4e4f48b",
          "name": "Linked Finance World",
          "symbol": "LFW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19570/thumb/LFW_New_logo.png?1671869339"
        },
        {
          "chainId": 56,
          "address": "0x55533be59de022d585a57e29539452d708d4a410",
          "name": "Zenc Coin",
          "symbol": "ZENC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25055/thumb/newzenc-logo.png?1678359371"
        },
        {
          "chainId": 56,
          "address": "0x18359cf655a444204e46f286edc445c9d30ffc54",
          "name": "Dogemoon",
          "symbol": "DOGEMOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15308/thumb/DOGEMOON.png?1629434312"
        },
        {
          "chainId": 56,
          "address": "0x0d4992e48278aa7f7c915f820743d9fab7fea713",
          "name": "MetaZilla",
          "symbol": "MZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20369/thumb/metazilla_auto_x2.png?1636948937"
        },
        {
          "chainId": 56,
          "address": "0xb09fe1613fe03e7361319d2a43edc17422f36b09",
          "name": "Bogged Finance",
          "symbol": "BOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15980/thumb/bog.png?1638183823"
        },
        {
          "chainId": 56,
          "address": "0xf6c2d8d863a325846ac4f37d2c4432f6683ae442",
          "name": "Fastswap  BSC ",
          "symbol": "FAST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30134/thumb/FAST.jpg?1683271931"
        },
        {
          "chainId": 56,
          "address": "0x257a8d1e03d17b8535a182301f15290f11674b53",
          "name": "Kawaii Islands",
          "symbol": "KWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18880/thumb/8f4IyY3_%281%29.jpg?1634095004"
        },
        {
          "chainId": 56,
          "address": "0x7317da9c15303bfb434690586c3373b94fb2dd31",
          "name": "MonoMoney",
          "symbol": "MONO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24399/thumb/ToL6DsC7_400x400.jpg?1647528782"
        },
        {
          "chainId": 56,
          "address": "0xf9db7dbad683dc7868be8b03ff0f4aa25f5cc6f6",
          "name": "Floki Santa",
          "symbol": "FLOKISANTA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28535/thumb/XHR83fw.png?1671506895"
        },
        {
          "chainId": 56,
          "address": "0x52f9ca8cac2827d379068b069580f9fd730cc9f3",
          "name": "STAN Token",
          "symbol": "STAN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30277/thumb/STAN.png?1683789446"
        },
        {
          "chainId": 56,
          "address": "0x9f485c50a611199fde9c849c56be7df888a90725",
          "name": "The Cat Inu",
          "symbol": "THECAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24624/thumb/200.png?1648449338"
        },
        {
          "chainId": 56,
          "address": "0x8a505d5cb3db9fcf404c0a72af3df8be4efb707c",
          "name": "Eng Crypto",
          "symbol": "ENG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26579/thumb/CoinGecko_ENG_Logo.png?1658888031"
        },
        {
          "chainId": 56,
          "address": "0x16350ac2153a1d6322c90197129076b747d3222a",
          "name": "Morpho Network",
          "symbol": "MORPHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28541/thumb/MORPHO_NETWORK_LOGO.PNG?1671581894"
        },
        {
          "chainId": 56,
          "address": "0x701d9a068d1eec64fbc10299b9f1b18fbb355ddb",
          "name": "Argonon Helium",
          "symbol": "ARG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25542/thumb/EP_f1090image_story.jpeg?1652266154"
        },
        {
          "chainId": 56,
          "address": "0xfc4f5a4d1452b8dc6c3cb745db15b29c00812b19",
          "name": "Operon Origins",
          "symbol": "ORO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20965/thumb/oro.png?1638152385"
        },
        {
          "chainId": 56,
          "address": "0x5a8261214a6c5fe68a6a4c81aec4f68bcd73ebc1",
          "name": "ShibFalcon",
          "symbol": "SHFLCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29218/thumb/photo_2023-02-26_11-32-46.jpg?1677462760"
        },
        {
          "chainId": 56,
          "address": "0x742f1fbec70b81ed0a32093d0c9054264fd850b2",
          "name": "GianniDoge Esport",
          "symbol": "GDE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28360/thumb/200x200.png?1669853470"
        },
        {
          "chainId": 56,
          "address": "0x93bb13e90678ccd8bbab07d1daef15086746dc9b",
          "name": "PlayPad",
          "symbol": "PPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20202/thumb/playpad.PNG?1636622174"
        },
        {
          "chainId": 56,
          "address": "0x1bf7aedec439d6bfe38f8f9b20cf3dc99e3571c4",
          "name": "TRONPAD",
          "symbol": "TRONPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16066/thumb/weUUiy33_400x400.jpg?1622737145"
        },
        {
          "chainId": 56,
          "address": "0x4f1a6fc6a7b65dc7ebc4eb692dc3641be997c2f2",
          "name": "Santa Coin",
          "symbol": "SANTA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19654/thumb/eXsu0oNf_400x400.jpg?1637117362"
        },
        {
          "chainId": 56,
          "address": "0x4d4af26f52c7e96a5f42d7a70caa43f2dab5acb4",
          "name": "Metal Friends",
          "symbol": "MTLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28441/thumb/VI9Adz37_400x400.jpeg?1670572770"
        },
        {
          "chainId": 56,
          "address": "0x3c70260eee0a2bfc4b375feb810325801f289fbd",
          "name": "Omni Consumer Protocol",
          "symbol": "OCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15243/thumb/ocp-200px.png?1622175368"
        },
        {
          "chainId": 56,
          "address": "0xd83cec69ed9d8044597a793445c86a5e763b0e3d",
          "name": "StopElon",
          "symbol": "STOPELON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16136/thumb/photo_2021-06-04_18-28-00.png?1623057366"
        },
        {
          "chainId": 56,
          "address": "0x0f208f5ea1d4fbca61ac6b6754f765950d3840de",
          "name": "YachtingVerse",
          "symbol": "YACHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30334/thumb/yachtingversep.png?1684155442"
        },
        {
          "chainId": 56,
          "address": "0xf899e83e6c6df1a0d4887cf0209193aa97236322",
          "name": "MBD Financials",
          "symbol": "MBD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25617/thumb/mbd-transparent_%281%29.png?1652856277"
        },
        {
          "chainId": 56,
          "address": "0xa7266989b0df675cc8257d53b6bc1358faf6626a",
          "name": "Infinity PAD",
          "symbol": "IPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27443/thumb/idiDT_BJ_400x400.jpeg?1663974533"
        },
        {
          "chainId": 56,
          "address": "0x5344c20fd242545f31723689662ac12b9556fc3d",
          "name": "Wakanda Inu",
          "symbol": "WKD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20121/thumb/wakanda.PNG?1636522997"
        },
        {
          "chainId": 56,
          "address": "0xd57cfb45347573f4f2471fa1572eb79d0ba29fd0",
          "name": "SAFEONE CHAIN",
          "symbol": "SAFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28042/thumb/3.png?1674720901"
        },
        {
          "chainId": 56,
          "address": "0x16b8dba442cc9faa40d0dd53f698087546ccf096",
          "name": "Exobots",
          "symbol": "EXOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25528/thumb/16521.png?1652242218"
        },
        {
          "chainId": 56,
          "address": "0x0ba45f3aa9aca408dcbacebd21096602498b7b10",
          "name": "LBViVi",
          "symbol": "LBVV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30777/thumb/IMG_20230615_211100_123.jpg?1687166284"
        },
        {
          "chainId": 56,
          "address": "0xcc6c9773a8a70c4642dffceb017742830aaac1a6",
          "name": "Virtual Meta",
          "symbol": "VMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25993/thumb/200200.png?1655193595"
        },
        {
          "chainId": 56,
          "address": "0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd",
          "name": "Polis",
          "symbol": "POLIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2452/thumb/circlePolisLogo.png?1573787189"
        },
        {
          "chainId": 56,
          "address": "0x438fc473ba340d0734e2d05acdf5bee775d1b0a4",
          "name": "GOAL Token",
          "symbol": "GOAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20197/thumb/Goal_Token_200_x_200.png?1656397827"
        },
        {
          "chainId": 56,
          "address": "0x5492ef6aeeba1a3896357359ef039a8b11621b45",
          "name": "Chumbi Valley",
          "symbol": "CHMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21686/thumb/_CHMB.png?1639718905"
        },
        {
          "chainId": 56,
          "address": "0x1f39dd2bf5a27e2d4ed691dcf933077371777cb0",
          "name": "SnowCrash",
          "symbol": "NORA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17993/thumb/Nora-200x200.png?1630047165"
        },
        {
          "chainId": 56,
          "address": "0x734d66f635523d7ddb7d2373c128333da313041b",
          "name": "Zedxion USDZ",
          "symbol": "USDZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25909/thumb/20475.png?1654587346"
        },
        {
          "chainId": 56,
          "address": "0x888888888888f195e27a2e0f98d712952ab9348e",
          "name": "Shorter Finance",
          "symbol": "IPISTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27002/thumb/box-1.png?1661329648"
        },
        {
          "chainId": 56,
          "address": "0xacfc95585d80ab62f67a14c566c1b7a49fe91167",
          "name": "FEG BSC  OLD ",
          "symbol": "FEG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14533/thumb/feg.png?1680236100"
        },
        {
          "chainId": 56,
          "address": "0xc3ca60a7176f6614b538b9e056480accac4e7999",
          "name": "Crypto Pepe Mines",
          "symbol": "CPM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31025/thumb/cpm-token.png?1689838125"
        },
        {
          "chainId": 56,
          "address": "0x8ea5219a16c2dbf1d6335a6aa0c6bd45c50347c5",
          "name": "OpenOcean",
          "symbol": "OOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17014/thumb/ooe_log.png?1626074195"
        },
        {
          "chainId": 56,
          "address": "0x8ad8e9b85787ddd0d31b32ecf655e93bfc0747ef",
          "name": "Sombra",
          "symbol": "SMBR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204"
        },
        {
          "chainId": 56,
          "address": "0x502b8136c48977b975a6c62b08ac4e15dabc8172",
          "name": "Child Support",
          "symbol": "CS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30414/thumb/child_support.jpg?1684389637"
        },
        {
          "chainId": 56,
          "address": "0x840590a04dd494c980d70a380e10bec739432a8a",
          "name": "Synopti",
          "symbol": "SYNOPTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29077/thumb/Synopti_200x200.png?1676438356"
        },
        {
          "chainId": 56,
          "address": "0xbcc608002765339db153d07250d516bc4356531b",
          "name": "Leonidasbilic",
          "symbol": "LIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27084/thumb/output-onlinepngtools-55.png?1661765239"
        },
        {
          "chainId": 56,
          "address": "0x6e61579c22f9a6da63a33e819f29b6697d2a126e",
          "name": "RocketFi",
          "symbol": "ROCKETFI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26378/thumb/30jxINoE_400x400.jpeg?1657665435"
        },
        {
          "chainId": 56,
          "address": "0x211ffbe424b90e25a15531ca322adf1559779e45",
          "name": "BUX",
          "symbol": "BUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2316/thumb/BUXC.png?1600760731"
        },
        {
          "chainId": 56,
          "address": "0x2824d8ecded273e296ca57d583d80614093c87d4",
          "name": "MetaQ",
          "symbol": "METAQ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24584/thumb/PoQiwrce8gcDf9KxXDk_fU9WEP7ljoPRJnYounF7pgJi-_Jnt3AFXQqB1lLtKDiezguUYEGvntBGCKMCB22Cecf5AcU9bbFV38Sdihravj6x65pVunbXmrl39hyfqQAUyjum_l5KqJgUcsTtLJTBqdpLiAmcg_igURSkEaef_APdfkYepHZ0tJh8TglKgCzLpAle_F-aNMfmAX4.jpg?1648218007"
        },
        {
          "chainId": 56,
          "address": "0x000351d035d8bbf2aa3131ebfecd66fb21836f6c",
          "name": "Scotty Beam",
          "symbol": "SCOTTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21755/thumb/sign-logo.png?1639986799"
        },
        {
          "chainId": 56,
          "address": "0x606379220ab266bbe4b0fef8469e6e602f295a84",
          "name": "Glow Token",
          "symbol": "GLOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29003/thumb/output-onlinepngtools.png?1675908631"
        },
        {
          "chainId": 56,
          "address": "0xb92c5e0135a510a4a3a8803f143d2cb085bbaf73",
          "name": "Metaverser",
          "symbol": "MTVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27472/thumb/s4Ce6y1r_400x400.jpeg?1664195404"
        },
        {
          "chainId": 56,
          "address": "0x940230b6b7ef1979a28f32196a8e3439c645ba49",
          "name": "Shib Army",
          "symbol": "SHIBARMY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19779/thumb/shiba_army.PNG?1635843834"
        },
        {
          "chainId": 56,
          "address": "0x797bb0beea437d2762a755ea911c0046c1284568",
          "name": "Vulkania",
          "symbol": "VLK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25424/thumb/7DBYi_Zc_400x400.jpg?1651737050"
        },
        {
          "chainId": 56,
          "address": "0xbdc3b3639f7aa19e623a4d603a3fb7ab20115a91",
          "name": "Coin of the champions",
          "symbol": "COC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18478/thumb/COC-Yellow-Transparent-1.png?1632148454"
        },
        {
          "chainId": 56,
          "address": "0x26193c7fa4354ae49ec53ea2cebc513dc39a10aa",
          "name": "StarSharks SEA",
          "symbol": "SEA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21711/thumb/12432.png?1639784107"
        },
        {
          "chainId": 56,
          "address": "0x000000000ca5171087c18fb271ca844a2370fc0a",
          "name": "Merkle Network",
          "symbol": "MERKLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19838/thumb/merkle.PNG?1636032921"
        },
        {
          "chainId": 56,
          "address": "0x6e551e88d0ed3ebd56f6b1f42b03bf9e4d68c47f",
          "name": "Infinity Arena",
          "symbol": "INAZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24315/thumb/0tMkBSx9_400x400.jpg?1647353638"
        },
        {
          "chainId": 56,
          "address": "0x4aadad81487c3fadd9f162b851e6a61b729200cb",
          "name": "Shiba Floki Inu",
          "symbol": "FLOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17115/thumb/shiba-logo-v2_2x.png?1642928563"
        },
        {
          "chainId": 56,
          "address": "0x1dacbcd9b3fc2d6a341dca3634439d12bc71ca4d",
          "name": "Bovineverse BVT",
          "symbol": "BVT",
          "decimals": 18
        },
        {
          "chainId": 56,
          "address": "0x30b5e345c79255101b8af22a19805a6fb96ddebb",
          "name": "REV3AL",
          "symbol": "REV3L",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26121/thumb/rev3l.png?1661498194"
        },
        {
          "chainId": 56,
          "address": "0x90500b067a9b24dcb4834a839c44eec90b2cd9ac",
          "name": "FGDSwap",
          "symbol": "FGDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29206/thumb/FGDS.png?1677312998"
        },
        {
          "chainId": 56,
          "address": "0x3d17e0abd8d2f023970d8df8b43776a1a2bd737c",
          "name": "PEPE Chain",
          "symbol": "PEPECHAIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30276/thumb/logo.png?1683787847"
        },
        {
          "chainId": 56,
          "address": "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7",
          "name": "Vai",
          "symbol": "VAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13861/thumb/VAI_logo.png?1612413571"
        },
        {
          "chainId": 56,
          "address": "0x284225aa4a0f0477fe900ab9a79befb03940db8d",
          "name": "DOGE CEO AI",
          "symbol": "DOGECEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29444/thumb/200x200_copy.png?1679364588"
        },
        {
          "chainId": 56,
          "address": "0xa2954b5734a9136bf648dce5bd2f9d2062551faa",
          "name": "ReduX",
          "symbol": "REDUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29179/thumb/ReduX_icon.png?1677142709"
        },
        {
          "chainId": 56,
          "address": "0x6289812163af9421e566b3d74774074fac2a0441",
          "name": "Crusaders of Crypto",
          "symbol": "CRUSADER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17178/thumb/crusaders.PNG?1626817123"
        },
        {
          "chainId": 56,
          "address": "0xac5d23ce5e4a5eb11a5407a5fbee201a75e8c8ad",
          "name": "Internet Money  BSC ",
          "symbol": "IM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29449/thumb/IM_Circle_200.png?1682405944"
        },
        {
          "chainId": 56,
          "address": "0x98c6fd0281a9a0300cb88553bf386a3492bb70f7",
          "name": "Broovs Projects",
          "symbol": "BRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25020/thumb/OnV-HqI1_400x400.jpg?1649831519"
        },
        {
          "chainId": 56,
          "address": "0x9ebbeb7f6b842377714eadd987caa4510205107a",
          "name": "Boba Brewery",
          "symbol": "BRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24953/thumb/bre.png?1649507380"
        },
        {
          "chainId": 56,
          "address": "0xf48f91df403976060cc05dbbf8a0901b09fdefd4",
          "name": "Minu",
          "symbol": "MINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29151/thumb/Minu_Logo.jpg?1676972329"
        },
        {
          "chainId": 56,
          "address": "0x9428f4cd18896eda03633429c3f52e5244504d14",
          "name": "Metahamster",
          "symbol": "MHAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26632/thumb/metahamster200x200.png?1659326340"
        },
        {
          "chainId": 56,
          "address": "0xa4c53189ec5e6b14c0cc98651f6be8a2b4a749e6",
          "name": "Ryoma",
          "symbol": "RYOMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27047/thumb/OabnykXw_400x400.jpeg?1661503607"
        },
        {
          "chainId": 56,
          "address": "0xa4e208c627f3adc60165c2a9fe79d73f4e8dd760",
          "name": "Kaby Gaming",
          "symbol": "KGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22937/thumb/xnSx6H.f960d9.KGTcoin.png?1643004882"
        },
        {
          "chainId": 56,
          "address": "0x90e1f81b298f6c180ce6f71a6bdb4acf41be8e01",
          "name": "Byepix",
          "symbol": "EPIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28163/thumb/byepix.png?1668169693"
        },
        {
          "chainId": 56,
          "address": "0x9c6b7221cdda3b8136fbf9d27ac07aeecc1087b5",
          "name": "Chain Wars",
          "symbol": "CWE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21376/thumb/token200.png?1639033012"
        },
        {
          "chainId": 56,
          "address": "0x14ab462a88e33d026a687f6d99f3af6e0ea73f9b",
          "name": "Nemesis PRO",
          "symbol": "NMSP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20389/thumb/nmsp-cg_200x200px.jpg?1678156389"
        },
        {
          "chainId": 56,
          "address": "0xe2c5fcf777a2b860921116b275951a50e8135eeb",
          "name": "Shera",
          "symbol": "SHR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25146/thumb/NtobKtpi_400x400.jpg?1650436989"
        },
        {
          "chainId": 56,
          "address": "0x1384555d00144c7725ac71da2bb1fd67b9ad889a",
          "name": "Dsun Token",
          "symbol": "DSUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29973/thumb/500-200.png?1682395330"
        },
        {
          "chainId": 56,
          "address": "0x43a8a925c1930a313d283359184a64c51a2bc0e9",
          "name": "Navis",
          "symbol": "NVS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30019/thumb/nvslogo1-min.png?1682564609"
        },
        {
          "chainId": 56,
          "address": "0xc91324601b20ea0e238b63c9fafca18d32600722",
          "name": "Sword BSC Token",
          "symbol": "SWDB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28715/thumb/swdb_200x200.png?1673576341"
        },
        {
          "chainId": 56,
          "address": "0x8526ff6bbd8a976127443b1ce451ca1044aa3ce2",
          "name": "Yuse",
          "symbol": "YUSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25416/thumb/NlEpysOy_400x400.png?1651734883"
        },
        {
          "chainId": 56,
          "address": "0xddfd6382a18ad7279eb6db4dfb78922ddc33d6e7",
          "name": "ShredN",
          "symbol": "SHRED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30996/thumb/shredn.png?1689569884"
        },
        {
          "chainId": 56,
          "address": "0x6ad2b6d5d8f96c8e581d3100c12878b2151a0423",
          "name": "WOLF PUPS",
          "symbol": "WOLFIES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25133/thumb/wolfies.png?1679899948"
        },
        {
          "chainId": 56,
          "address": "0xba625fdcc8d35e43e9b9e337779d4e4794a80dac",
          "name": "kiwi",
          "symbol": "KIWI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28986/thumb/kiwi.png?1675818316"
        },
        {
          "chainId": 56,
          "address": "0x7806dad5651d657986f5cb27932d59d90f1646c7",
          "name": "The Last Pepe",
          "symbol": "FROGGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30447/thumb/FROGGO.png?1684489151"
        },
        {
          "chainId": 56,
          "address": "0xe52bb35344cceedd014be2896d01e604ad992c85",
          "name": "Crypto Snack",
          "symbol": "SNACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31133/thumb/SNACK.jpg?1690812686"
        },
        {
          "chainId": 56,
          "address": "0x11fd9ed04f1eb43ef9df6425a6990609f2468895",
          "name": "SmartFi",
          "symbol": "SMTF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25264/thumb/618d410e849c474a30fe91ae_SMTF_token_-_transparent_background.png?1651044829"
        },
        {
          "chainId": 56,
          "address": "0x185674a45c57ebb884c609c2619740f2994767e9",
          "name": "Helena Financial V2",
          "symbol": "HELENA2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30545/thumb/Helena_v2.jpg?1684986648"
        },
        {
          "chainId": 56,
          "address": "0x56aa0237244c67b9a854b4efe8479cca0b105289",
          "name": "WalletNow",
          "symbol": "WNOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG?1636582255"
        },
        {
          "chainId": 56,
          "address": "0x23f9a46a2c06f5249702aad1b9b1fd1b6e6833cf",
          "name": "Nero",
          "symbol": "NPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29651/thumb/Nero_Black_200x200.png?1680492171"
        },
        {
          "chainId": 56,
          "address": "0x6f64cc61d0d5542e40e6f2828cbdda84507d214d",
          "name": "MetaniaGames",
          "symbol": "METANIA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22927/thumb/metania-200.png?1643003026"
        },
        {
          "chainId": 56,
          "address": "0x5d681b9839e237c4f1dc7d7486e6cb0a12b9654f",
          "name": "iOWN",
          "symbol": "IOWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8270/thumb/iOWN-Lion-Face.png?1585695262"
        },
        {
          "chainId": 56,
          "address": "0x0747cda49c82d3fc6b06df1822e1de0c16673e9f",
          "name": "Flux Protocol",
          "symbol": "FLUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png?1619402947"
        },
        {
          "chainId": 56,
          "address": "0xc45de8ab31140e9ced1575ec53ffffa1e3062576",
          "name": "Mad Viking Games Token",
          "symbol": "MVG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30104/thumb/MVG_Token_Logo_200x200p.png?1683081054"
        },
        {
          "chainId": 56,
          "address": "0xe9a5c635c51002fa5f377f956a8ce58573d63d91",
          "name": "Tethereum",
          "symbol": "T99",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30864/thumb/200x200.png?1688368860"
        },
        {
          "chainId": 56,
          "address": "0x0f005dfe97c5041e538b7075915b2ee706677c26",
          "name": "JeToken",
          "symbol": "JETS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21104/thumb/fav-icon.png?1638327812"
        },
        {
          "chainId": 56,
          "address": "0xdc42c3a92c4a03f9b9f3fbaba0125286fdaa6772",
          "name": "Drunk Skunks Drinking Club",
          "symbol": "STINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28390/thumb/Cg_size.jpg?1670137189"
        },
        {
          "chainId": 56,
          "address": "0xa677bc9bdb10329e488a4d8387ed7a08b2fc9005",
          "name": "Magic Power",
          "symbol": "MGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22011/thumb/https-Mpg-com-Logo.jpg?1640590454"
        },
        {
          "chainId": 56,
          "address": "0xfdad8edc724277e975f4955d288c6eb5b20a3146",
          "name": "Nulswap",
          "symbol": "NSWAP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30099/thumb/nswap-circ_%283%29.png?1683026350"
        },
        {
          "chainId": 56,
          "address": "0x83451a4e3585fda74feb348ad929f2c4ca189660",
          "name": "Hunter Token",
          "symbol": "HNTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27793/thumb/Hunter-Logo-large.png?1665819905"
        },
        {
          "chainId": 56,
          "address": "0x6fb8889b3c5d79a4c855d4de56ce3b742d8e0eba",
          "name": "Recycle X",
          "symbol": "RCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28616/thumb/Recycle.png?1672558146"
        },
        {
          "chainId": 56,
          "address": "0xadaae082237cb1772c9e079db95c117e6dd0c5af",
          "name": "VizslaSwap",
          "symbol": "VIZSLASWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25809/thumb/E66D3A9B-3FB7-4D14-AAE9-7476F3C3F546.png?1653980204"
        },
        {
          "chainId": 56,
          "address": "0xc23be03f64a834b3fa6ae62c97ac8b40f3eec6a9",
          "name": "Peoplez",
          "symbol": "LEZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21185/thumb/peoplez.PNG?1638513746"
        },
        {
          "chainId": 56,
          "address": "0x93442c6fb58a197bd5562891f9e76f07c57df2ba",
          "name": "WAGMI Token",
          "symbol": "WAG",
          "decimals": 9
        },
        {
          "chainId": 56,
          "address": "0xe6ffa2e574a8bbeb5243d2109b6b11d4a459f88b",
          "name": "Hippo",
          "symbol": "HIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17386/thumb/hip.PNG?1627520087"
        },
        {
          "chainId": 56,
          "address": "0x70e48eb0881a8c56baad37eb4491ea85eb47b4b2",
          "name": "Bored APEmove",
          "symbol": "BAPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26576/thumb/Group_48096525.png?1658887505"
        },
        {
          "chainId": 56,
          "address": "0xe9ed9f1dde9c831937969f18ab047393a53b07da",
          "name": "Onlinebase",
          "symbol": "ONLINE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30953/thumb/image.jpg?1689215485"
        },
        {
          "chainId": 56,
          "address": "0x0b34d4a7c5bfc7004b9a193f8309523e99ca766e",
          "name": "Shon",
          "symbol": "SHON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17135/thumb/200x200_LOGO.png?1626425839"
        },
        {
          "chainId": 56,
          "address": "0xa528d8b9cd90b06d373373c37f8f188e44cad3be",
          "name": "Monster Ball",
          "symbol": "MFB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29145/thumb/05E42231-5338-4104-A074-65A00964BA6F.png?1676965245"
        },
        {
          "chainId": 56,
          "address": "0xfc7104975fde57040fce04ccee6a9e97d559dc12",
          "name": "DevOps",
          "symbol": "DEV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30013/thumb/devops_200.png?1682497864"
        },
        {
          "chainId": 56,
          "address": "0x908ef6b57a6bb5b043ea6ef84142895b519c713c",
          "name": "Matchcup",
          "symbol": "MATCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28488/thumb/logo_%286%29.png?1671090799"
        },
        {
          "chainId": 56,
          "address": "0xaee234825dc4687fae606485c1ebd06336052bcc",
          "name": "Duke Inu",
          "symbol": "DUKE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16121/thumb/3axfBIw.jpeg?1623036247"
        },
        {
          "chainId": 56,
          "address": "0xe1a0ce8b94c6a5e4791401086763d7bd0a6c18f5",
          "name": "DeFiAI",
          "symbol": "DFAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23345/thumb/dfai.png?1644056152"
        },
        {
          "chainId": 56,
          "address": "0xa797fa4bda7c5a4b3afe73573b9d2ab942365c6f",
          "name": "Hololoot",
          "symbol": "HOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21538/thumb/hol.PNG?1639446836"
        },
        {
          "chainId": 56,
          "address": "0x38b967d090fe06f7927a2b65cc57987c8594766b",
          "name": "Infinity Protocol",
          "symbol": "INFINITY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28291/thumb/200x200.png?1669256067"
        },
        {
          "chainId": 56,
          "address": "0xcb2b25e783a414f0d20a65afa741c51b1ad84c49",
          "name": "Starpad",
          "symbol": "SRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18631/thumb/starpunk.PNG?1632713040"
        },
        {
          "chainId": 56,
          "address": "0x484312a0aaeae3ae36a74ff3e294246f35dddf4f",
          "name": "Baby Shark Tank",
          "symbol": "BASHTANK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15390/thumb/IqTGmFXy_400x400.jpg?1620703139"
        },
        {
          "chainId": 56,
          "address": "0x12de91acb5f544b37b1e66438324b8db26a91d8a",
          "name": "MetaFootball",
          "symbol": "MTF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21945/thumb/2duK7I_h_400x400.png?1640317237"
        },
        {
          "chainId": 56,
          "address": "0x49a516bd4406b2d4074c738a58de6db397d0abc9",
          "name": "IKOLF",
          "symbol": "IKOLF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26868/thumb/IMG_20220814_132323_400.jpg?1660537118"
        },
        {
          "chainId": 56,
          "address": "0x10c9524dbf934b3b625dce3bdc0efdc367f4e84b",
          "name": "Mavaverse",
          "symbol": "MVX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23830/thumb/mvx.png?1652433931"
        },
        {
          "chainId": 56,
          "address": "0xce6b8b2787c657f1b98b7a66b5b63178863fd719",
          "name": "ZFMCOIN",
          "symbol": "ZFM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28232/thumb/20221106_193725.png?1668574239"
        },
        {
          "chainId": 56,
          "address": "0xb0a480e2fa5af51c733a0af9fcb4de62bc48c38b",
          "name": "Starly",
          "symbol": "STARLY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23713/thumb/15139.png?1645086528"
        },
        {
          "chainId": 56,
          "address": "0x883da316c7971b19bfe25df9ace93b9529c50f22",
          "name": "Clinq Gold Token",
          "symbol": "CGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30264/thumb/Logo_-_200x200.png?1683774608"
        },
        {
          "chainId": 56,
          "address": "0x9e711221b34a2d4b8f552bd5f4a6c4e7934920f7",
          "name": "Okratech",
          "symbol": "ORT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18352/thumb/fav.png?1631604240"
        },
        {
          "chainId": 56,
          "address": "0xc05f9174eb7f8b5102d8c83f441a5f65a684aefc",
          "name": "Amgen",
          "symbol": "AMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25291/thumb/WorYFccvCfc0SQzHHCSJyVHyE7StfNOVU9NajqXcKv3G7hvinJGlISSFBgjuamai1N6aZTUCuDQD8Lm2Nod2loHKAedAtagu-DNv_r7PlxyiAr8pP6TqPHRwmLMkYeVIMAXjUIZtDwS8B1WKLdM1qYfbZb6_9aMD9WMEh2g58Oa2tac0Y1HPSy_Q1ZGIFXSMntGbeRs2-fqMPLjeFztRR0_%281%29.jpg?1651132162"
        },
        {
          "chainId": 56,
          "address": "0x26165a5a3dd21fa528becf3ff7f114d00a517344",
          "name": "Meta BSC",
          "symbol": "META",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22243/thumb/RXfj1lF_d.png?1641434287"
        },
        {
          "chainId": 56,
          "address": "0x02a40c048ee2607b5f5606e445cfc3633fb20b58",
          "name": "Kaby Arena",
          "symbol": "KABY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18090/thumb/URPKhnop_400x400.jpg?1630416255"
        },
        {
          "chainId": 56,
          "address": "0x7ae1cbec5c315b31948dd2a5a7c2a6a6040d3d5b",
          "name": "NoriGO ",
          "symbol": "GO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29300/thumb/go-token-medium.png?1681907719"
        },
        {
          "chainId": 56,
          "address": "0x631c2f0edabac799f07550aee4ff0bf7fd35212b",
          "name": "Poollotto finance",
          "symbol": "PLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21425/thumb/polotto.png?1639116841"
        },
        {
          "chainId": 56,
          "address": "0xad1898d4d222ccb2ae8e36585c7bf2a59984a736",
          "name": "Edgeware",
          "symbol": "EDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8452/thumb/logo-edgeware.png?1678947608"
        },
        {
          "chainId": 56,
          "address": "0xc1cbfb96a1d5361590b8df04ef78de2fa3178390",
          "name": "Peachfolio",
          "symbol": "PCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16529/thumb/7d578071-601e-4ef6-9a98-cc7984b258c4.png?1624342728"
        },
        {
          "chainId": 56,
          "address": "0x64619f611248256f7f4b72fe83872f89d5d60d64",
          "name": "Quint",
          "symbol": "QUINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25194/thumb/logo-200x200.png?1650608504"
        },
        {
          "chainId": 56,
          "address": "0x8ae14ce43f71201bb79babd00cc8d00a7fadb3bd",
          "name": "Savant AI",
          "symbol": "SAVANTAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29354/thumb/savant_200.png?1678263016"
        },
        {
          "chainId": 56,
          "address": "0x485d37ca1c8d4e0b5b11b87604816a4843c079ed",
          "name": "Digimon Rabbit",
          "symbol": "DRB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28429/thumb/logo_DRB%281%29.png?1670549665"
        },
        {
          "chainId": 56,
          "address": "0x9d4451151a8de5b545a1bc6c8fdeb9d94a2868e1",
          "name": "Lunar  OLD ",
          "symbol": "LNR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20446/thumb/rsz_lunarprofileiconcircleblack_1.png?1646380831"
        },
        {
          "chainId": 56,
          "address": "0x55a633b3fce52144222e468a326105aa617cc1cc",
          "name": "Truth Seekers",
          "symbol": "TRUTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29507/thumb/Truth_Logo.jpg?1679455422"
        },
        {
          "chainId": 56,
          "address": "0xd631d33f2c3f38d9abdae08ebc0b69fa636e8ec2",
          "name": "LovePot",
          "symbol": "LOVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19567/thumb/13433.png?1635422764"
        },
        {
          "chainId": 56,
          "address": "0x6a1225c87f15da91e2fa5ee7b2075e2e3a9dbc39",
          "name": "Soroosh Smart Ecosystem",
          "symbol": "SSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30049/thumb/SSEToken-200x200.png?1682994364"
        },
        {
          "chainId": 56,
          "address": "0x5cd0c2c744caf04cda258efc6558a3ed3defe97b",
          "name": "CZRed",
          "symbol": "CZR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28524/thumb/200x200.png?1671346389"
        },
        {
          "chainId": 56,
          "address": "0x9528cceb678b90daf02ca5ca45622d5cbaf58a30",
          "name": "GoCryptoMe",
          "symbol": "GCME",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23946/thumb/WDBxWle.png?1645771102"
        },
        {
          "chainId": 56,
          "address": "0x2f25d402829ca4085b8ea4d3bc68bf203f5a9fab",
          "name": "EagonSwap",
          "symbol": "EAGON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180"
        },
        {
          "chainId": 56,
          "address": "0x8347ac51ec4ef29324591d55e2b585a38b2ead5b",
          "name": "Game X Change Potion",
          "symbol": "GXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18471/thumb/gxp.png?1640072326"
        },
        {
          "chainId": 56,
          "address": "0x33be7644c0e489b3a0c639d103392d4f3e338158",
          "name": "Marvelous NFTs",
          "symbol": "MNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22613/thumb/mnft.png?1642238172"
        },
        {
          "chainId": 56,
          "address": "0xa78775bba7a542f291e5ef7f13c6204e704a90ba",
          "name": "Metafluence",
          "symbol": "METO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22998/thumb/Asset_1.png?1643087185"
        },
        {
          "chainId": 56,
          "address": "0x7704d0ead6f74e625d7371b079d8b2475bc852d4",
          "name": "PulseAI",
          "symbol": "PULSE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30342/thumb/logo.png?1684194561"
        },
        {
          "chainId": 56,
          "address": "0xae7300b51a8cd43d5b74be0dd5047715b7017e3e",
          "name": "Fragments of Arker",
          "symbol": "FOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22103/thumb/16642.png?1640824684"
        },
        {
          "chainId": 56,
          "address": "0x6448be0ca45a7581d9c4c9dd665e14ec60b25113",
          "name": "Biokript",
          "symbol": "BKPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30887/thumb/dNuo9C5F_400x400.jpg?1688535198"
        },
        {
          "chainId": 56,
          "address": "0x16e79e09b3b56bcbba83667aff88dc6ca727af2e",
          "name": "Bart Simpson Coin",
          "symbol": "BART",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30233/thumb/png_20230513_190136_0000.png?1684228972"
        },
        {
          "chainId": 56,
          "address": "0xf018aea0a08a5d88674f0837bdac27ab89824dee",
          "name": "Mad Monkey Guild",
          "symbol": "MMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27226/thumb/LogoAnimate.png?1662702513"
        },
        {
          "chainId": 56,
          "address": "0xe500acdbd53a8fcbe2b01c0f9c2ccc676d0fc6f6",
          "name": "TiraVerse",
          "symbol": "TVRS",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/24591/thumb/tvrs.png?1648298059"
        },
        {
          "chainId": 56,
          "address": "0x69b0af16fdd2e80968eb505cd41dc26efb2b80bf",
          "name": "BlockBlend  OLD ",
          "symbol": "BBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24858/thumb/blockblend.png?1649146225"
        },
        {
          "chainId": 56,
          "address": "0x01bd7acb6ff3b6dd5aefa05cf085f2104f3fc53f",
          "name": "TreatDAO",
          "symbol": "TREAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19156/thumb/TREAT.jpg?1634543260"
        },
        {
          "chainId": 56,
          "address": "0x0d1afece252ff513c5d210aeae88f6c7d37e6ab2",
          "name": "EarthByt",
          "symbol": "EBYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23491/thumb/Untitled-design-17.png?1644221335"
        },
        {
          "chainId": 56,
          "address": "0x4b0f1812e5df2a09796481ff14017e6005508003",
          "name": "Trust Wallet",
          "symbol": "TWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11085/thumb/Trust.png?1588062702"
        },
        {
          "chainId": 56,
          "address": "0xadccd9b784b1398d95c2e952df4c78dc62c7dad9",
          "name": "AUREO",
          "symbol": "AUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18427/thumb/W7l4Jxsh_400x400.jpg?1631882540"
        },
        {
          "chainId": 56,
          "address": "0x4c769928971548eb71a3392eaf66bedc8bef4b80",
          "name": "HarryPotterObamaSonic10Inu",
          "symbol": "BITCOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21453/thumb/PYNP2xv.jpeg?1639268312"
        },
        {
          "chainId": 56,
          "address": "0x89c1af791d7b4cf046dca8fa10a41dd2298a6a3f",
          "name": "Derify Protocol",
          "symbol": "DRF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24757/thumb/9VyY40eb_400x400.jpg?1648789821"
        },
        {
          "chainId": 56,
          "address": "0x738ea75b01d8db931b4374c6ebd3de82d7d3a272",
          "name": "DUSD",
          "symbol": "DUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30196/thumb/LOGO_200X200.png?1683609196"
        },
        {
          "chainId": 56,
          "address": "0x6359f0d2004433d2a38b03dce8f966cc243f1da9",
          "name": "Food Bank",
          "symbol": "FOOD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24147/thumb/food.png?1646560102"
        },
        {
          "chainId": 56,
          "address": "0xdb238123939637d65a03e4b2b485650b4f9d91cb",
          "name": "TasteNFT",
          "symbol": "TASTE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15532/thumb/3C1Qq1yf_400x400.jpg?1621138873"
        },
        {
          "chainId": 56,
          "address": "0x1b2fdb1626285b94782af2fda8e270e95cebc3b4",
          "name": "Soakmont",
          "symbol": "SKMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19014/thumb/Soakmont_-_Icone_RGB_Normal.jpeg?1653013817"
        },
        {
          "chainId": 56,
          "address": "0x9d7f4f7d036bdf08740d18557c63e50284e73231",
          "name": "RocketVerse  OLD ",
          "symbol": "RKV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28425/thumb/LOGO.png?1670477877"
        },
        {
          "chainId": 56,
          "address": "0x95c91eef65f50570cfc3f269961a00108cf7bf59",
          "name": "The Dons",
          "symbol": "DONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30347/thumb/dons.png?1684392353"
        },
        {
          "chainId": 56,
          "address": "0xcd03f8a59252f317a679eddb5315150f40d06e5e",
          "name": "Mooner",
          "symbol": "MNR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24391/thumb/logo-200x200.png?1647518867"
        },
        {
          "chainId": 56,
          "address": "0x7eb567f5c781ee8e47c7100dc5046955503fc26a",
          "name": "Koji",
          "symbol": "KOJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16124/thumb/koji-token-200.png?1623039354"
        },
        {
          "chainId": 56,
          "address": "0x1d27d2b52da2f93ae29aa69fbfe65e7087a2cbaa",
          "name": "Zeknd Superchain",
          "symbol": "OPZEKND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30868/thumb/Zeknd_logo_%281%29.png?1688371362"
        },
        {
          "chainId": 56,
          "address": "0xe82546b56b1b8a5f031bcd23ff6332282cb0124b",
          "name": "Brain Sync",
          "symbol": "SYNCBRAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29472/thumb/our_logo.png?1679129115"
        },
        {
          "chainId": 56,
          "address": "0x52419258e3fa44deac7e670eadd4c892b480a805",
          "name": "StarShip",
          "symbol": "STARSHIP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15580/thumb/starship.png?1630921863"
        },
        {
          "chainId": 56,
          "address": "0x9bcab88763c33a95e73bc6dcf80fcf27a77090b2",
          "name": "RoboFi",
          "symbol": "VICS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17582/thumb/s6cPN83.jpeg?1628562295"
        },
        {
          "chainId": 56,
          "address": "0xacbe25ad33b998c7c4b8442f21d914da0902aeb6",
          "name": "SwirlToken",
          "symbol": "SWIRL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31143/thumb/gAV4qo_s_400x400.jpg?1690969534"
        },
        {
          "chainId": 56,
          "address": "0x5b41028f250204fae2d96a8b1ffe33e2944dc9a6",
          "name": "Mutant Pepe",
          "symbol": "MUTANT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30131/thumb/200x200-MUTANT.png?1683266176"
        },
        {
          "chainId": 56,
          "address": "0xf2e00684457de1a3c87361bc4bfe2de92342306c",
          "name": "Shield Network",
          "symbol": "SHIELDNET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14909/thumb/ShieldNet.png?1623317875"
        },
        {
          "chainId": 56,
          "address": "0x42be29132756ddd6e8b3b94584ca0bab20545eec",
          "name": "Bali Token",
          "symbol": "BLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21989/thumb/logo.jpg?1640582239"
        },
        {
          "chainId": 56,
          "address": "0x617ba3d39e96c084e60c6db3f7b365a96ee4e555",
          "name": "Interstellar Domain Order",
          "symbol": "IDO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20523/thumb/logo_-_2021-11-18T065417.612.png?1637189699"
        },
        {
          "chainId": 56,
          "address": "0x7a3a3b747ea9078235f995ad4327828377ce4fa4",
          "name": "ETWInfinity",
          "symbol": "ETW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28724/thumb/IMG_20230111_170205_646.jpg?1673604514"
        },
        {
          "chainId": 56,
          "address": "0x012a6a39eec345a0ea2b994b17875e721d17ee45",
          "name": "1Reward Token",
          "symbol": "1RT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27348/thumb/1RT.png?1663654775"
        },
        {
          "chainId": 56,
          "address": "0xb850cac12ab85d4400db61ac78dc5fc2418b6868",
          "name": "Ctomorrow Platform",
          "symbol": "CTP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/22256/thumb/dchSzDny_400x400.jpg?1641283473"
        },
        {
          "chainId": 56,
          "address": "0xaa20c2e278d99f978989daa4460f933745f862d5",
          "name": "Ark Rivals",
          "symbol": "ARKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23852/thumb/logo1--1-.png?1645602721"
        },
        {
          "chainId": 56,
          "address": "0xf709e948daed701a6a018e6f6090ca1930055966",
          "name": "AI DogeMini",
          "symbol": "AIDOGEMINI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28995/thumb/Logo_200px_x_200px.jpg?1675823705"
        },
        {
          "chainId": 56,
          "address": "0x72eb7ca07399ec402c5b7aa6a65752b6a1dc0c27",
          "name": "AstroSwap",
          "symbol": "ASTRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18816/thumb/astro.png?1633506850"
        },
        {
          "chainId": 56,
          "address": "0xaed361021d6fb2ef2ce65049e7b0e814ce5fa2c1",
          "name": "FINX",
          "symbol": "FINX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30263/thumb/FINX_logo.png?1684138378"
        },
        {
          "chainId": 56,
          "address": "0x241c9ef7602f031bef4f14458c3083dcd37b9be5",
          "name": "PushSwap",
          "symbol": "PUSH",
          "decimals": 19,
          "logoURI": "https://assets.coingecko.com/coins/images/30885/thumb/Pushwap_Logo_200x200.png?1688520613"
        },
        {
          "chainId": 56,
          "address": "0xc19fe21b4ef356f2f65894392dde4252aa083605",
          "name": "Widi Soul",
          "symbol": "WSO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22832/thumb/wso2_200x200.png?1642668402"
        },
        {
          "chainId": 56,
          "address": "0xfeb4e9b932ef708c498cc997abe51d0ee39300cf",
          "name": "GetKicks",
          "symbol": "KICKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27873/thumb/GetKicks.jpg?1666169072"
        },
        {
          "chainId": 56,
          "address": "0xff5e804896c75e6eb294ec971904958de5b95b6a",
          "name": "MetaPuss",
          "symbol": "MTP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29141/thumb/Twitter_Profile_%281%29.png?1676963620"
        },
        {
          "chainId": 56,
          "address": "0x57528b45134f09f2e0069334a36a7e14af74745f",
          "name": "SugarYield",
          "symbol": "SUGAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29026/thumb/Safeimagekit-resized-img.png?1676018993"
        },
        {
          "chainId": 56,
          "address": "0xb46049c79d77ff1d555a67835fba6978536581af",
          "name": "MoonFarm Finance",
          "symbol": "MFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16337/thumb/logo-mf-new-200px-trans.png?1628512247"
        },
        {
          "chainId": 56,
          "address": "0x424aa711301c82252eccaccf01301ad7ad7b5d40",
          "name": "Taroverse",
          "symbol": "TARO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25276/thumb/Taroverse.png?1651124099"
        },
        {
          "chainId": 56,
          "address": "0x03887c177c18140209c0f93adc230d4c5515bf4a",
          "name": "Pegasus DEX",
          "symbol": "PEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29743/thumb/IMG_4903.PNG?1681097485"
        },
        {
          "chainId": 56,
          "address": "0x35f3ffffcb622bc9f64fa561d74e983fd488d90c",
          "name": "PirateCash",
          "symbol": "PIRATE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png?1637683371"
        },
        {
          "chainId": 56,
          "address": "0xbaea9aba1454df334943951d51116ae342eab255",
          "name": "Poolz Finance",
          "symbol": "POOLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29625/thumb/logomark-black_%282%29.png?1680079914"
        },
        {
          "chainId": 56,
          "address": "0x843d4a358471547f51534e3e51fae91cb4dc3f28",
          "name": "Loser Coin",
          "symbol": "LOWB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15378/thumb/loser.PNG?1620693920"
        },
        {
          "chainId": 56,
          "address": "0x38fc43bbddb64cd23bc8d085f88722ab1f9a6c50",
          "name": "CryptoJetski",
          "symbol": "CJET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22907/thumb/cjet.png?1642941799"
        },
        {
          "chainId": 56,
          "address": "0x859c940f080b197659b3effc804fd622df66f0a1",
          "name": "FitBurn",
          "symbol": "CAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29876/thumb/Logo_%283%29.png?1683283548"
        },
        {
          "chainId": 56,
          "address": "0x5b52bfb8062ce664d74bbcd4cd6dc7df53fd7233",
          "name": "ZENIQ",
          "symbol": "ZENIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29284/thumb/zeniq.png?1677754455"
        },
        {
          "chainId": 56,
          "address": "0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493",
          "name": "Feeder Finance",
          "symbol": "FEED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15151/thumb/feeder_finance_logo.png?1619968857"
        },
        {
          "chainId": 56,
          "address": "0xad3063fe9df7355fc6e008c04f8da6e02b40304e",
          "name": "Optimus Al  BSC ",
          "symbol": "OPTIMUSAL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29724/thumb/IMG_20230327_231606_933.png?1680849405"
        },
        {
          "chainId": 56,
          "address": "0x31de61d9a39cb9f479570bd3dc3ac93bc0402cdb",
          "name": "NFTPunk Finance",
          "symbol": "NFTPUNK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15544/thumb/200x200%281%29.png?1628508990"
        },
        {
          "chainId": 56,
          "address": "0xeb05ac86959725f9e7284cf67b052ba82aeb446e",
          "name": "All In GPT",
          "symbol": "AIGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29679/thumb/Logo.png?1680588410"
        },
        {
          "chainId": 56,
          "address": "0x08a84af1368cd333073ac5dfb2254208e06b3a70",
          "name": "marumaruNFT",
          "symbol": "MARU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27672/thumb/90e23f86-426d-4a0f-9d66-10da3d58baf5.png?1665158519"
        },
        {
          "chainId": 56,
          "address": "0x1311b352467d2b5c296881badea82850bcd8f886",
          "name": "TOOLS",
          "symbol": "TOOLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14330/thumb/bsctools.png?1615434943"
        },
        {
          "chainId": 56,
          "address": "0xca1e6f584e0d7eec74f553e9a7b64a4ded8a4b61",
          "name": "Valentine Floki",
          "symbol": "FLOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23311/thumb/flov.png?1644053849"
        },
        {
          "chainId": 56,
          "address": "0xf939cce5b35fb465c920b6602d0de7d40498d5a8",
          "name": "Cryptosroom",
          "symbol": "CROOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28511/thumb/609AAA5C-5E4B-49B8-86D6-61B5C46BE5A7.jpeg?1671181256"
        },
        {
          "chainId": 56,
          "address": "0x041e714aa0dce7d4189441896486d361e98bad5f",
          "name": "Rich",
          "symbol": "RCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22896/thumb/rch.png?1642851498"
        },
        {
          "chainId": 56,
          "address": "0xe702c303173f90094ce8c9c6ca3f198eca0e027c",
          "name": "LonelyFans",
          "symbol": "LOF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21317/thumb/lof.png?1638926928"
        },
        {
          "chainId": 56,
          "address": "0xb3cb6d2f8f2fde203a022201c81a96c167607f15",
          "name": "Green Planet",
          "symbol": "GAMMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20895/thumb/gamma.png?1638528110"
        },
        {
          "chainId": 56,
          "address": "0x61ceb7a1c334c297cd9ac3c7e6b6150690de10eb",
          "name": "RabbitSwap",
          "symbol": "RABBIT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28519/thumb/200x200.png?1671265248"
        },
        {
          "chainId": 56,
          "address": "0x2304ae9af71a5ae1b92f0091ac3caff105c67766",
          "name": "LaunchVerse",
          "symbol": "XLV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28844/thumb/final_200.png?1677139694"
        },
        {
          "chainId": 56,
          "address": "0x7ffc1243232da3ac001994208e2002816b57c669",
          "name": "CryptoZoo",
          "symbol": "ZOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17337/thumb/cryptozoo.PNG?1627354086"
        },
        {
          "chainId": 56,
          "address": "0xadcfc6bf853a0a8ad7f9ff4244140d10cf01363c",
          "name": "TrustPad",
          "symbol": "TPAD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15272/thumb/p3zMHrp1_400x400.jpg?1620336854"
        },
        {
          "chainId": 56,
          "address": "0x15e596aecb199d94bbf7869b42273f9e8bcc9fa1",
          "name": "Merry Christmas Token",
          "symbol": "MCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28538/thumb/MCT_200.png?1671514174"
        },
        {
          "chainId": 56,
          "address": "0x441bb79f2da0daf457bad3d401edb68535fb3faa",
          "name": "Hey Reborn  NEW ",
          "symbol": "RB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29364/thumb/rb.png?1678266586"
        },
        {
          "chainId": 56,
          "address": "0xb350aebaedb1ed3269b0e25d5e593a9bb4b9f9d5",
          "name": "Ryoshi",
          "symbol": "RYOSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16394/thumb/RyoshiLogo.png.jpg?1626869344"
        },
        {
          "chainId": 56,
          "address": "0xd522a1dce1ca4b138dda042a78672307eb124cc2",
          "name": "SWAPZ app",
          "symbol": "SWAPZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16811/thumb/EWV5xQB.jpeg?1625147828"
        },
        {
          "chainId": 56,
          "address": "0x23f07a1c03e7c6d0c88e0e05e79b6e3511073fd5",
          "name": "Crypto Development Services",
          "symbol": "CDS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21304/thumb/JhUZ3Rk.png?1638883308"
        },
        {
          "chainId": 56,
          "address": "0x002d3c280f719c4b0444680a8c4b1785b6cc2a59",
          "name": "ETHFan Burn",
          "symbol": "EFB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24268/thumb/efb.png?1647174388"
        },
        {
          "chainId": 56,
          "address": "0x07f5ceded6b3dba557b3663edc8941fb37b63945",
          "name": "Little Angry Bunny v2",
          "symbol": "LAB-V2",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20327/thumb/lab_v2.png?1636888545"
        },
        {
          "chainId": 56,
          "address": "0xe39e2861ae9a45fa321c1b0155d2f99196b2a992",
          "name": "Eloin",
          "symbol": "ELOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17656/thumb/Pics-Art-08-01-02-43-25.png?1628756148"
        },
        {
          "chainId": 56,
          "address": "0x1ef72a1df5e4d165f84fc43b20d56caa7dad46e1",
          "name": "AMMYI Coin",
          "symbol": "AMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14469/thumb/AMMYI.png?1630917084"
        },
        {
          "chainId": 56,
          "address": "0xf750a26eb0acf95556e8529e72ed530f3b60f348",
          "name": "GreenTrust",
          "symbol": "GNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15171/thumb/U4tF68c.png?1620013363"
        },
        {
          "chainId": 56,
          "address": "0x7cd8c22d3f4b66230f73d7ffcb48576233c3fe33",
          "name": "Metagalaxy Land",
          "symbol": "MEGALAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20983/thumb/dud5mv4kNaj1PZwSQ3Eazt5voNIycBwyhJQFaXdE.png?1638165470"
        },
        {
          "chainId": 56,
          "address": "0xf85be0902a16fb87d447021d6e4517b38a15087d",
          "name": "PalmPay",
          "symbol": "PALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28216/thumb/logoGreen_%282%29.png?1668428951"
        },
        {
          "chainId": 56,
          "address": "0x3c0fe6c4acd3a21615a51372d2a430eb68ccde43",
          "name": "Red Floki CEO",
          "symbol": "REDFLOKICEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30039/thumb/FcOcx_B7_400x400.jpg?1682661431"
        },
        {
          "chainId": 56,
          "address": "0xc8c488fdbbb2e72e41710ade67784f0812160210",
          "name": "ZAT Project",
          "symbol": "ZPRO",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/25633/thumb/cmc.png?1654593879"
        },
        {
          "chainId": 56,
          "address": "0xf3bebb4f2d348e44cc4547cba2f96c214fe5a25a",
          "name": "Shilly Bar",
          "symbol": "SHBAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20982/thumb/photo_2021-11-29_13.40.47.jpeg?1638164760"
        },
        {
          "chainId": 56,
          "address": "0x9550ba92515fa17b2df02a31b02e93400f185b98",
          "name": "Avatar Musk Verse",
          "symbol": "AMV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29802/thumb/IMG_20230403_223159_410.png?1681350295"
        },
        {
          "chainId": 56,
          "address": "0xceb244a0e126f6ccbf37d631c7b102cf3e11a536",
          "name": "RevivalX",
          "symbol": "RVLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26534/thumb/circle.png?1658715362"
        },
        {
          "chainId": 56,
          "address": "0x57c81885faad67fc4de892102f6fead3b9215f6b",
          "name": "Zenith Chain",
          "symbol": "ZENITH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png?1638837643"
        },
        {
          "chainId": 56,
          "address": "0xc3d912863152e1afc935ad0d42d469e7c6b05b77",
          "name": "Lucky Lion",
          "symbol": "LUCKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18712/thumb/lucky-token-200px.png?1633079262"
        },
        {
          "chainId": 56,
          "address": "0x651189c8c0abbd79d51af276aa241915ca782b21",
          "name": "Pong Heroes",
          "symbol": "PONG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29139/thumb/Pong_Token_Symbol_Logo.png?1676963133"
        },
        {
          "chainId": 56,
          "address": "0xa5dec77c4d1b4eba2807c9926b182812a0cbf9eb",
          "name": "Protocon",
          "symbol": "PEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25175/thumb/_symbol_200x200.png?1673638496"
        },
        {
          "chainId": 56,
          "address": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
          "name": "Coin98",
          "symbol": "C98",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904"
        },
        {
          "chainId": 56,
          "address": "0xd3513dc0df25f60c71822e7bacac14f9391845ff",
          "name": "ReelFi",
          "symbol": "REELFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29447/thumb/logo_200x200.png?1678867912"
        },
        {
          "chainId": 56,
          "address": "0x879d239bcc0356cf9df8c90442488bce99554c66",
          "name": "Metan Evolutions",
          "symbol": "METAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23762/thumb/metan.png?1645420372"
        },
        {
          "chainId": 56,
          "address": "0x78e624070871831842730b43f77467af3e8b580c",
          "name": "ALINK AI",
          "symbol": "ALINK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29336/thumb/IMG_20230302_185330_175.jpg?1678173552"
        },
        {
          "chainId": 56,
          "address": "0x0409633a72d846fc5bbe2f98d88564d35987904d",
          "name": "Phoenix",
          "symbol": "PHB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20052/thumb/Phoenix_logo_blue_bird.png?1666335691"
        },
        {
          "chainId": 56,
          "address": "0xb82beb6ee0063abd5fc8e544c852237aa62cbb14",
          "name": "Square",
          "symbol": "SQUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22530/thumb/logo_square_token_200x200.png?1641997078"
        },
        {
          "chainId": 56,
          "address": "0x6c015277b0f9b8c24b20bd8bbbd29fdb25738a69",
          "name": "Wrapped NewYorkCoin",
          "symbol": "WNYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17123/thumb/J53IJI7.png?1626399992"
        },
        {
          "chainId": 56,
          "address": "0x6ad0f087501eee603aeda0407c52864bc7f83322",
          "name": "Metaverse Face",
          "symbol": "MEFA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21375/thumb/ZBC4FWKR_400x400.jpg?1639032278"
        },
        {
          "chainId": 56,
          "address": "0x49324d59327fb799813b902db55b2a118d601547",
          "name": "Boss",
          "symbol": "BOSS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17927/thumb/XL7jo4j.png?1629809801"
        },
        {
          "chainId": 56,
          "address": "0x1bdaf9ddd7658d8049391971d1fd48c0484f66ec",
          "name": "Cogito Protocol",
          "symbol": "CGV",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/30731/thumb/CGV_logo_32.png?1686648683"
        },
        {
          "chainId": 56,
          "address": "0x4dfad9a4cba318efc53743b803588b113f8a84bd",
          "name": "Chihuahua",
          "symbol": "HUA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15651/thumb/chihua.PNG?1621476950"
        },
        {
          "chainId": 56,
          "address": "0xa11ff9976018fda2a8c4ccfa6ffbe8423c5ab668",
          "name": "Floki Cash",
          "symbol": "FLOKICASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29865/thumb/E68106E0-F8A1-406A-9C25-3B22644936A9.jpeg?1681890065"
        },
        {
          "chainId": 56,
          "address": "0x43adc41cf63666ebb1938b11256f0ea3f16e6932",
          "name": "Dogewhale",
          "symbol": "DOGEWHALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22068/thumb/dogewhale_logo_3d_x200.png?1642148107"
        },
        {
          "chainId": 56,
          "address": "0x4a645fb8ae60979edf7f47c5c1a4569b7fb07851",
          "name": "MEME Token",
          "symbol": "MEME",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30244/thumb/1683302825646-e392bbea67a6fcfbfec3a0c14e10ade0.png?1683692294"
        },
        {
          "chainId": 56,
          "address": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
          "name": "Binance Peg Cardano",
          "symbol": "ADA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15455/thumb/ZE8LxNBf_400x400.jpg?1620894681"
        },
        {
          "chainId": 56,
          "address": "0x8f046a2457a8f1618cae4706fa57bf790e2532a6",
          "name": "Restore Truth",
          "symbol": "RTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23525/thumb/W5sB5UGT_400x400.jpg?1644314969"
        },
        {
          "chainId": 56,
          "address": "0xd04c116c4f02f3cca44b7d4e5209225c8779c8b8",
          "name": "BunnyPark Game",
          "symbol": "BG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21422/thumb/BG_TOKEN.png?1639115205"
        },
        {
          "chainId": 56,
          "address": "0x4133ae786381c028c3d7aa738a07368d96eab9dc",
          "name": "FitR Metaverse Token",
          "symbol": "FMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27960/thumb/FMT.png?1666751173"
        },
        {
          "chainId": 56,
          "address": "0xd0aa796e2160ed260c668e90ac5f237b4ebd4b0d",
          "name": "Waifu",
          "symbol": "WAIFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28181/thumb/200.png?1668254108"
        },
        {
          "chainId": 56,
          "address": "0xa177bdd433aea3702beb46652adcfc64248d4ab3",
          "name": "Probinex",
          "symbol": "PBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23677/thumb/ezgif-4-225dbc8377.jpg?1651217748"
        },
        {
          "chainId": 56,
          "address": "0xb2192a2829a5220bafd5b0a67f3328e209887bee",
          "name": "Getaverse",
          "symbol": "GETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30939/thumb/GETA_LOGO_2.jpg?1689083302"
        },
        {
          "chainId": 56,
          "address": "0x181de8c57c4f25eba9fd27757bbd11cc66a55d31",
          "name": "Beluga fi",
          "symbol": "BELUGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164"
        },
        {
          "chainId": 56,
          "address": "0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261",
          "name": "Shakita Inu",
          "symbol": "SHAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19026/thumb/shak.png?1634192974"
        },
        {
          "chainId": 56,
          "address": "0x80d04e44955aa9c3f24041b2a824a20a88e735a8",
          "name": "Multiverse Capital",
          "symbol": "MVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21172/thumb/mvc.PNG?1638439038"
        },
        {
          "chainId": 56,
          "address": "0x1b9dec855e98d1c01426a3ed615dd25d2947290e",
          "name": "Pepe AI Token",
          "symbol": "PEPEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30293/thumb/our_logo.png?1683867450"
        },
        {
          "chainId": 56,
          "address": "0xce186ad6430e2fe494a22c9edbd4c68794a28b35",
          "name": "LoopNetwork",
          "symbol": "LOOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24259/thumb/loop.png?1647170665"
        },
        {
          "chainId": 56,
          "address": "0xd9de2b1973e57dc9dba90c35d6cd940ae4a3cbe1",
          "name": "Milo Inu",
          "symbol": "MILO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23896/thumb/milo.png?1645719021"
        },
        {
          "chainId": 56,
          "address": "0x9b26e16377ad29a6ccc01770bcfb56de3a36d8b2",
          "name": "HERO",
          "symbol": "HERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16245/thumb/HERO-200.png?1628255352"
        },
        {
          "chainId": 56,
          "address": "0x40235f0575bf12c0e13fd4ec41fece7ea2a98011",
          "name": "MerryChristmas  OLD ",
          "symbol": "HOHOHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28558/thumb/My_project-1_%283%29.png?1671706384"
        },
        {
          "chainId": 56,
          "address": "0x2659cb928f6f705c1e574464361882fd6e92e1f9",
          "name": "iPulse",
          "symbol": "PLS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21902/thumb/Ipulse-con.png?1640234165"
        },
        {
          "chainId": 56,
          "address": "0x7ed86d2769fe9a2cad7bac4ceac45e40c82295d6",
          "name": "Absolute Sync",
          "symbol": "AST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24590/thumb/ast.png?1648297028"
        },
        {
          "chainId": 56,
          "address": "0x7758a52c1bb823d02878b67ad87b6ba37a0cdbf5",
          "name": "Ookeenga",
          "symbol": "OKG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27297/thumb/ookeenga_32.png?1663209031"
        },
        {
          "chainId": 56,
          "address": "0xffaa85705ae216363e4e843b67ff3c238fcf0de2",
          "name": "Euro Shiba Inu",
          "symbol": "ESHIB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26955/thumb/200_png.png?1660994255"
        },
        {
          "chainId": 56,
          "address": "0x0b49e5c0640105c5f63f7cd9a3473948ef323bda",
          "name": "GGbond",
          "symbol": "GGBOND",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30600/thumb/Frame_7.png?1685515135"
        },
        {
          "chainId": 56,
          "address": "0x36530092b1413c21ab7e5203197cf76d4d598888",
          "name": "ShopNEXT Reward Token",
          "symbol": "STE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28662/thumb/STE_Logo.png?1672979978"
        },
        {
          "chainId": 56,
          "address": "0xed0c1c9c64ff7c7cc37c3af0dfcf5b02efe0bb5f",
          "name": "BitOrbit",
          "symbol": "BITORB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19850/thumb/bitorbit.PNG?1636060265"
        },
        {
          "chainId": 56,
          "address": "0xa1a0c7849e6916945a78f8af843738c051ab15f3",
          "name": "MetaMoon",
          "symbol": "METAMOON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15744/thumb/ezgif-3-96b1d03006c5.png?1621778407"
        },
        {
          "chainId": 56,
          "address": "0x09c704c1eb9245af48f058878e72129557a10f04",
          "name": "Sweep Token",
          "symbol": "SWEEP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27848/thumb/sweep200.png?1666083170"
        },
        {
          "chainId": 56,
          "address": "0xbd817c2e531b1b31f211f50f59fbc87ebc50ef48",
          "name": "Quick Transfer Coin Plus",
          "symbol": "QTCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30448/thumb/200x200.png?1684489211"
        },
        {
          "chainId": 56,
          "address": "0x23125108bc4c63e4677b2e253fa498ccb4b3298b",
          "name": "Buff Doge Coin",
          "symbol": "DOGECOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18516/thumb/BUFF_KOIN.png?1632231567"
        },
        {
          "chainId": 56,
          "address": "0x5e8f6244a57f4f6c47daafc14aa0723d2a9f594c",
          "name": "Pay It Now",
          "symbol": "PIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22482/thumb/PINlogo.png?1668686774"
        },
        {
          "chainId": 56,
          "address": "0xf209ce1960fb7e750ff30ba7794ea11c6acdc1f3",
          "name": "Graphene",
          "symbol": "GFN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19159/thumb/Graphene-Logo.png?1634547901"
        },
        {
          "chainId": 56,
          "address": "0x3aeff4e27e1f9144ed75ba65a80bdfee345f413e",
          "name": "BUMooN",
          "symbol": "BUMN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17570/thumb/bumn-logo-200.png?1628514543"
        },
        {
          "chainId": 56,
          "address": "0x8013d731f429b3ad418f5467f9f68285efd67ca7",
          "name": "KRYZA Network",
          "symbol": "KRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18416/thumb/Je71mCoZ_400x400.png?1631841515"
        },
        {
          "chainId": 56,
          "address": "0x220e6a613f00c79025d5611b73639e045b186ff8",
          "name": "Dhabicoin",
          "symbol": "DBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22803/thumb/Ia0yVef.png?1642584684"
        },
        {
          "chainId": 56,
          "address": "0x11582ef4642b1e7f0a023804b497656e2663bc9b",
          "name": "KCCPad",
          "symbol": "KCCPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16994/thumb/kcc.png?1625999319"
        },
        {
          "chainId": 56,
          "address": "0xd051b29c5cb6c19532529d7544dd0718dd40ecc8",
          "name": "RocketCoin",
          "symbol": "ROCKET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21108/thumb/Screenshot-2021-12-01-at-03-39-43.png?1638330515"
        },
        {
          "chainId": 56,
          "address": "0xb65528415eb3d737033fe9101a536f6e4c27a8b0",
          "name": "BabyPepeEntire",
          "symbol": "BABYPEPE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30480/thumb/Frame_2197_%281%29.png?1684709051"
        },
        {
          "chainId": 56,
          "address": "0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18",
          "name": "Wizard BSC",
          "symbol": "WIZARD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17132/thumb/Untitled-design-1.png?1626413294"
        },
        {
          "chainId": 56,
          "address": "0xabc69f2025bdb12efcdb8fd048d240fff943ca82",
          "name": "Vanity",
          "symbol": "VNY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15899/thumb/GGkuplD_%281%29.png?1627396656"
        },
        {
          "chainId": 56,
          "address": "0x33333ee26a7d02e41c33828b42fb1e0889143477",
          "name": "Topshelf Finance",
          "symbol": "LIQR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21580/thumb/b-MDs-Uqg-Q-400x400.jpg?1639532412"
        },
        {
          "chainId": 56,
          "address": "0x6b0a03c7bd8441e0f8959394761e29bd6afbfdf7",
          "name": "onetokenburn",
          "symbol": "ONE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30171/thumb/one.png?1683535182"
        },
        {
          "chainId": 56,
          "address": "0xca0d640a401406f3405b4c252a5d0c4d17f38ebb",
          "name": "Metarun",
          "symbol": "MRUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24219/thumb/GDgQSjgZ_400x400.jpg?1646918963"
        },
        {
          "chainId": 56,
          "address": "0x85ee8e3e0068edeeee960979958d7f61416a9d84",
          "name": "Story",
          "symbol": "STORY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18128/thumb/logo_-_2021-09-03T064032.320.png?1630622531"
        },
        {
          "chainId": 56,
          "address": "0xa03110800894b3ccf8723d991d80875561f96777",
          "name": "Bit Game Verse Token",
          "symbol": "BGVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27975/thumb/bgvt.png?1667124585"
        },
        {
          "chainId": 56,
          "address": "0x25382fb31e4b22e0ea09cb0761863df5ad97ed72",
          "name": "Paragen",
          "symbol": "RGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24198/thumb/TH-Y4qWF_400x400.jpg?1646863203"
        },
        {
          "chainId": 56,
          "address": "0xc98a8ec7a07f1b743e86896a52434c4c6a0dbc42",
          "name": "ASIX",
          "symbol": "ASIX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23282/thumb/asix.png?1643535598"
        },
        {
          "chainId": 56,
          "address": "0x9b7a8f7895c58d85c63fba7a57a382c03c55a652",
          "name": "TrustBit Finance",
          "symbol": "TRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30122/thumb/trust_%281%29.png?1688376648"
        },
        {
          "chainId": 56,
          "address": "0x2e42c9eac96833c6e16dc71c743cecc114ccd7e3",
          "name": "MetaCash",
          "symbol": "META",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22217/thumb/hzBuuMFM_400x400.jpg?1641198854"
        },
        {
          "chainId": 56,
          "address": "0x874966221020d6ac1aed0e2cfad9cbfee0ba713b",
          "name": "bHBD",
          "symbol": "BHBD",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/27597/thumb/bHBD_2_200x200.png?1664709710"
        },
        {
          "chainId": 56,
          "address": "0xdd848e0cbfd3771dc7845b10072d973c375271e2",
          "name": "Lanceria",
          "symbol": "LANC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17589/thumb/Logo_200.png?1684827793"
        },
        {
          "chainId": 56,
          "address": "0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b",
          "name": "Venus BTC",
          "symbol": "VBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13904/thumb/xbtc.png?1612795131"
        },
        {
          "chainId": 56,
          "address": "0xd5d0322b6bab6a762c79f8c81a0b674778e13aed",
          "name": "Binance Peg Firo",
          "symbol": "FIRO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20128/thumb/firocoingecko.png?1636537460"
        },
        {
          "chainId": 56,
          "address": "0xc54a4640907044283e8e4885090e205992b9813e",
          "name": "Forus",
          "symbol": "FORS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24090/thumb/Foruslogo.png?1646299099"
        },
        {
          "chainId": 56,
          "address": "0x35bedbf9291b22218a0da863170dcc9329ef2563",
          "name": "Tap Fantasy",
          "symbol": "TAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23854/thumb/17718.png?1645576059"
        },
        {
          "chainId": 56,
          "address": "0x39dc1f91fee49c03a0db558254707116101518bf",
          "name": "Fantaverse",
          "symbol": "UT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29539/thumb/ut.png?1679544389"
        },
        {
          "chainId": 56,
          "address": "0xaf41054c1487b0e5e2b9250c0332ecbce6ce9d71",
          "name": "Ellipsis X",
          "symbol": "EPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25444/thumb/ellipsis-light_%281%29.png?1651786591"
        },
        {
          "chainId": 56,
          "address": "0xd15d3baf3f40988810c5f9da54394ffb5246ded6",
          "name": "Seba",
          "symbol": "SEBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24475/thumb/seba.png?1647775173"
        },
        {
          "chainId": 56,
          "address": "0xda4714fee90ad7de50bc185ccd06b175d23906c1",
          "name": "Godzilla",
          "symbol": "GODZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19672/thumb/13559.png?1635738708"
        },
        {
          "chainId": 56,
          "address": "0x5e95a952a7f79f978585afd54a053af0f51372fa",
          "name": "SB Group",
          "symbol": "SBG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29221/thumb/SB_Group.png?1677480078"
        },
        {
          "chainId": 56,
          "address": "0xfdfc1ab8bf1e2d6920caf405aa488987a077fc4b",
          "name": "CBYTE Network",
          "symbol": "CBYTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29248/thumb/photo_2023-02-06_02-15-30.jpg?1677641533"
        },
        {
          "chainId": 56,
          "address": "0xb4ebc6fd70a852d052163f25949c70fb9506d6a0",
          "name": "Social Capitalism",
          "symbol": "SOCAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26083/thumb/oPT2utjj_400x400.png?1655724337"
        },
        {
          "chainId": 56,
          "address": "0x52f24a5e03aee338da5fd9df68d2b6fae1178827",
          "name": "Ankr Staked BNB",
          "symbol": "ANKRBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28451/thumb/ankrBNB.png?1670740364"
        },
        {
          "chainId": 56,
          "address": "0xc7981767f644c7f8e483dabdc413e8a371b83079",
          "name": "Liquidus",
          "symbol": "LIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18749/thumb/liq.png?1635004173"
        },
        {
          "chainId": 56,
          "address": "0x5f54b428f08bcf68c8c1dc07db9971040e5997ec",
          "name": "BlockRock",
          "symbol": "BRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27846/thumb/Logo-200x200-1.png?1666263832"
        },
        {
          "chainId": 56,
          "address": "0x3ca994d9f723736381d44388bc8dd1e7ee8c1653",
          "name": "Samurai Legends",
          "symbol": "SMG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24440/thumb/e20nuH89_400x400.png?1647670350"
        },
        {
          "chainId": 56,
          "address": "0x10051147418c42218986cedd0adc266441f8a14f",
          "name": "DYOR",
          "symbol": "DYOR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23654/thumb/13987.png?1644926775"
        },
        {
          "chainId": 56,
          "address": "0xf4914e6d97a75f014acfcf4072f11be5cffc4ca6",
          "name": "dexSHARE",
          "symbol": "DEXSHARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25749/thumb/dexSHARE_200x200.png?1653463225"
        },
        {
          "chainId": 56,
          "address": "0x71d03f5cbf039febcc6ee8dbe20bc9ba3ea874fe",
          "name": "Web3Shot",
          "symbol": "W3S",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29723/thumb/W3S.jpg?1687319393"
        },
        {
          "chainId": 56,
          "address": "0x4d1e90ab966ae26c778b2f9f365aa40abb13f53c",
          "name": "STA",
          "symbol": "STA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26189/thumb/200x200.png?1656393801"
        },
        {
          "chainId": 56,
          "address": "0x83d8ea5a4650b68cd2b57846783d86df940f7458",
          "name": "Hudi",
          "symbol": "HUDI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18454/thumb/coin_200x200.png?1632105535"
        },
        {
          "chainId": 56,
          "address": "0x7b665b2f633d9363b89a98b094b1f9e732bd8f86",
          "name": "Amazy",
          "symbol": "AZY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26315/thumb/photo_2022-07-07_12-38-02.jpg?1657240464"
        },
        {
          "chainId": 56,
          "address": "0xc8ab61bed1d2baa1237f7aa4641e68342c58824f",
          "name": "kang3n",
          "symbol": "KANG3N",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23898/thumb/27C4962A-FC83-42E1-B644-2B6F66E4C3A2.png?1645626736"
        },
        {
          "chainId": 56,
          "address": "0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70",
          "name": "Market Making Pro",
          "symbol": "MMPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20386/thumb/MMPRO_LOGO_W.png?1636960601"
        },
        {
          "chainId": 56,
          "address": "0x747b1223b23e53070c54df355fac2e198aa54708",
          "name": "Wrapped Syscoin",
          "symbol": "WSYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23916/thumb/xDiIFaKO_400x400.jpg?1645684034"
        },
        {
          "chainId": 56,
          "address": "0x68dd887d012abdf99d3492621e4d576a3f75019d",
          "name": "Philcoin",
          "symbol": "PHL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24178/thumb/2ZFyoMSk.png?1646901982"
        },
        {
          "chainId": 56,
          "address": "0x123458c167a371250d325bd8b1fff12c8af692a7",
          "name": "DRAC Network",
          "symbol": "DRAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27438/thumb/200X200.png?1663934782"
        },
        {
          "chainId": 56,
          "address": "0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936",
          "name": "Catgirl",
          "symbol": "CATGIRL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16472/thumb/catgirl-200x200.png?1626961369"
        },
        {
          "chainId": 56,
          "address": "0xbd100d061e120b2c67a24453cf6368e63f1be056",
          "name": "iDypius",
          "symbol": "IDYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21976/thumb/iDYP-200x200px.png?1671016252"
        },
        {
          "chainId": 56,
          "address": "0xbfb8f92e8f3a9034019ac97fd9f85c6dfb513834",
          "name": "CeBioLabs",
          "symbol": "CBSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27523/thumb/XQ1K7Vy1_400x400.jpeg?1664369061"
        },
        {
          "chainId": 56,
          "address": "0xbf151f63d8d1287db5fc7a3bc104a9c38124cdeb",
          "name": "AVNRich",
          "symbol": "AVN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14819/thumb/avn.png?1618556244"
        },
        {
          "chainId": 56,
          "address": "0xaffeabc20b2cafa80d2d7ff220ad37e4ec7541d7",
          "name": "Links",
          "symbol": "LINKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21351/thumb/getlinks-coin.png?1638999202"
        },
        {
          "chainId": 56,
          "address": "0x8beabaa4f025d00b4699d56a683758d692d17f20",
          "name": "BabyXrp",
          "symbol": "BBYXRP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16862/thumb/Final.png?1625469041"
        },
        {
          "chainId": 56,
          "address": "0x641ec142e67ab213539815f67e4276975c2f8d50",
          "name": "DogeKing",
          "symbol": "DOGEKING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23349/thumb/kq245eMw_400x400.jpg?1643924448"
        },
        {
          "chainId": 56,
          "address": "0xf2caabf67f99d3ac5d0a4529722cfb874c9b35bf",
          "name": "DollarBack",
          "symbol": "BACK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26973/thumb/logoring200x200.png?1661153856"
        },
        {
          "chainId": 56,
          "address": "0x29a1e54de0fce58e1018535d30af77a9d2d940c4",
          "name": "Hero Cat",
          "symbol": "HCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20905/thumb/hct.png?1640573431"
        },
        {
          "chainId": 56,
          "address": "0x53f0e242ea207b6e9b63e0a53e788267aa99ff9b",
          "name": "Medping",
          "symbol": "MPG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20971/thumb/MEDPING_LOGO.png?1638154197"
        },
        {
          "chainId": 56,
          "address": "0xbabacc135bbf2ce30f9c0f12665b244d3689a29c",
          "name": "Cosmic FOMO",
          "symbol": "COSMIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30315/thumb/cosmic_logo.png?1683882994"
        },
        {
          "chainId": 56,
          "address": "0x2db0d5cb907014c67dc201886624716fb5c71123",
          "name": "Ainu",
          "symbol": "AINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17022/thumb/AINU.jpg?1629342905"
        },
        {
          "chainId": 56,
          "address": "0x250632378e573c6be1ac2f97fcdf00515d0aa91b",
          "name": "Binance ETH staking",
          "symbol": "BETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13804/thumb/Binnace.png?1611997829"
        },
        {
          "chainId": 56,
          "address": "0x599beec263fa6ea35055011967597b259fc012a4",
          "name": "FoxGirl",
          "symbol": "FOXGIRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19448/thumb/foxgirl.png?1635639329"
        },
        {
          "chainId": 56,
          "address": "0xc9bcf3f71e37579a4a42591b09c9dd93dfe27965",
          "name": "Milkshake Swap",
          "symbol": "MILK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18654/thumb/Logo200x200_%2810%29.png?1632780043"
        },
        {
          "chainId": 56,
          "address": "0x6e4a971b81ca58045a2aa982eaa3d50c4ac38f42",
          "name": "Bridge Oracle",
          "symbol": "BRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12512/thumb/brg.png?1661754280"
        },
        {
          "chainId": 56,
          "address": "0xdd6978f36c98aff4287e5ac803c9cf1b865641f6",
          "name": "Jerry Inu",
          "symbol": "JERRY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30067/thumb/photo_6174436240632428438_y_%281%29.png?1683008946"
        },
        {
          "chainId": 56,
          "address": "0xa1ac3b22b102caa62c9ecaf418585528855b0ddd",
          "name": "Stripto",
          "symbol": "STRIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23383/thumb/17856.png?1643963358"
        },
        {
          "chainId": 56,
          "address": "0xaa9826732f3a4973ff8b384b3f4e3c70c2984651",
          "name": "CryptoXpress",
          "symbol": "XPRESS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20467/thumb/xvlKHAMJ_400x400.jpg?1637064606"
        },
        {
          "chainId": 56,
          "address": "0xd536851e2bd9b3b8ea6a46b32016d7f52b1e4327",
          "name": "ExodusExt",
          "symbol": "EXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28241/thumb/IMG_20220614_095726_079.jpg?1670144043"
        },
        {
          "chainId": 56,
          "address": "0x0ec674fabb4ea1935514a7be760f7e13aa466a39",
          "name": "MusicAI",
          "symbol": "MUSICAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28974/thumb/200X200-2-removebg-preview.png?1675750075"
        },
        {
          "chainId": 56,
          "address": "0x5d37abafd5498b0e7af753a2e83bd4f0335aa89f",
          "name": "WECOIN",
          "symbol": "WECO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30644/thumb/wecoin-logo-200x200.png?1686039111"
        },
        {
          "chainId": 56,
          "address": "0x708955db0d4c52ffbf9aa34af7f3ca8bf07390a8",
          "name": "Battle Saga",
          "symbol": "BTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21990/thumb/200x200.png?1640581733"
        },
        {
          "chainId": 56,
          "address": "0x9ae0290cd677dc69a5f2a1e435ef002400da70f5",
          "name": "Civilization Network",
          "symbol": "CVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29101/thumb/CVL_logo_green.png?1676605108"
        },
        {
          "chainId": 56,
          "address": "0x0cbfdea4f45a486cc7db53cb6e37b312a137c605",
          "name": "SEEDx",
          "symbol": "SEEDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29462/thumb/200px.png?1679019547"
        },
        {
          "chainId": 56,
          "address": "0x1894251aebcff227133575ed3069be9670e25db0",
          "name": "Halo Coin",
          "symbol": "HALO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27758/thumb/200.png?1680070591"
        },
        {
          "chainId": 56,
          "address": "0xd4d55b811d9ede2adce61a98d67d7f91bffce495",
          "name": "PulseDoge",
          "symbol": "PULSEDOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19695/thumb/pulse.png?1635750667"
        },
        {
          "chainId": 56,
          "address": "0x2eabcb730ca72f0afcbc9da24d105345cb0852aa",
          "name": "X Social Network",
          "symbol": "X-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29214/thumb/log.png?1677398800"
        },
        {
          "chainId": 56,
          "address": "0xbd2c43da85d007b0b3cd856fd55c299578d832bc",
          "name": "Lifty",
          "symbol": "LQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16384/thumb/ktQzdAe.png?1628601338"
        },
        {
          "chainId": 56,
          "address": "0x961e149db8bfbdb318c182152725ac806d7be3f4",
          "name": "Utility Web3Shot",
          "symbol": "UW3S",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29445/thumb/logo.png?1690875946"
        },
        {
          "chainId": 56,
          "address": "0x3523d58d8036b1c5c9a13493143c97aefc5ad422",
          "name": "The Killbox Game",
          "symbol": "KBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20828/thumb/icon_abe_token-200.png?1637726119"
        },
        {
          "chainId": 56,
          "address": "0x4c496592fd52c2810651b4862cc9fe13940fea31",
          "name": "Baby Alvey",
          "symbol": "BALVEY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28438/thumb/logo_200.png?1670570344"
        },
        {
          "chainId": 56,
          "address": "0x394bba8f309f3462b31238b3fd04b83f71a98848",
          "name": "Pocoland",
          "symbol": "POCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18293/thumb/poco.PNG?1631312700"
        },
        {
          "chainId": 56,
          "address": "0x1cc1aca0dae2d6c4a0e8ae7b4f2d01eabbc435ee",
          "name": "StrongHands Finance",
          "symbol": "ISHND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898"
        },
        {
          "chainId": 56,
          "address": "0xebc148d40313be9c9f214d3beb9f2ddebec0ec52",
          "name": "StereoAI",
          "symbol": "STAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29187/thumb/icon-200x200.png?1677211210"
        },
        {
          "chainId": 56,
          "address": "0x2136cd209bb3d8e4c008ec2791b5d6790b5e33a9",
          "name": "Able Finance",
          "symbol": "ABLE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17819/thumb/aac9bfb0-f27f-48f3-9b3e-ec090e10d3e1.png?1629341559"
        },
        {
          "chainId": 56,
          "address": "0xcd7287113496cff7705792081d973b13a465e51e",
          "name": "PioneerPay",
          "symbol": "PPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29451/thumb/Untitled_design_%2815%29.png?1678948622"
        },
        {
          "chainId": 56,
          "address": "0xe81a79e9d951759aef3fccef17022276b3a0c7e5",
          "name": "Momentum",
          "symbol": "MASS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28342/thumb/GLCoOxW.png?1684827692"
        },
        {
          "chainId": 56,
          "address": "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1",
          "name": "Venus DAI",
          "symbol": "VDAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13920/thumb/venus_dai.png?1612844411"
        },
        {
          "chainId": 56,
          "address": "0xe061156135b7b7847fd4db74992ac8555c0cd5a7",
          "name": "Zahnymous",
          "symbol": "ZAH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30184/thumb/200x200.png?1683600183"
        },
        {
          "chainId": 56,
          "address": "0xdd17629d05e068a9d118ee35d11101d4140d0586",
          "name": "YocoinYOCO",
          "symbol": "YOCO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17395/thumb/yocoin.PNG?1627526814"
        },
        {
          "chainId": 56,
          "address": "0xf7722aa0714096f1fb5ef83e6041cebb4d58a08e",
          "name": "Ruby Play Network",
          "symbol": "RUBY",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/24301/thumb/v7j5bIgX_400x400.jpg?1647269369"
        },
        {
          "chainId": 56,
          "address": "0xca1262e77fb25c0a4112cfc9bad3ff54f617f2e6",
          "name": "Jax Network",
          "symbol": "WJXN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg?1633360887"
        },
        {
          "chainId": 56,
          "address": "0x3cef8d4cc106a169902ea985cec2dc6ab055ad4c",
          "name": "Crypto AI",
          "symbol": "CAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29817/thumb/logo_200px.png?1681437799"
        },
        {
          "chainId": 56,
          "address": "0x9562ca0c2b05d089063f562fc3ecc95e4424ad02",
          "name": "Shield BSC Token",
          "symbol": "SHDB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28716/thumb/shdb_200x200.png?1673576432"
        },
        {
          "chainId": 56,
          "address": "0x000000ef379ee7f4c051f4b9af901a9219d9ec5c",
          "name": "Infam",
          "symbol": "INF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21175/thumb/Avatar_32.png?1669265769"
        },
        {
          "chainId": 56,
          "address": "0x18905200183e2c6f33653f469ec10a2a16027957",
          "name": "Starterpool",
          "symbol": "SPOL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31045/thumb/input-onlinepngtools.png?1689859591"
        },
        {
          "chainId": 56,
          "address": "0x426c72701833fddbdfc06c944737c6031645c708",
          "name": "Defina Finance",
          "symbol": "FINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18970/thumb/favicon.png?1634075931"
        },
        {
          "chainId": 56,
          "address": "0x6bfd4ca8ec078d613ed6a5248eb2c7a0d5c38b7b",
          "name": "Ecochain Finance",
          "symbol": "ECT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18304/thumb/Logo-Transparency.png?1631503556"
        },
        {
          "chainId": 56,
          "address": "0x1bec41a36356d5574aeb068b599ab7e48dd008b8",
          "name": "DogeFood",
          "symbol": "DOGEFOOD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22475/thumb/LNbEc9.png?1643275794"
        },
        {
          "chainId": 56,
          "address": "0x0dfcb45eae071b3b846e220560bbcdd958414d78",
          "name": "Libero Financial",
          "symbol": "LIBERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23971/thumb/libero.png?1645869416"
        },
        {
          "chainId": 56,
          "address": "0xab287e6d370c61f105630e656b5468acb4d00423",
          "name": "BinStarter",
          "symbol": "BSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17554/thumb/BSR200X200.png?1628227724"
        },
        {
          "chainId": 56,
          "address": "0x5bcd91c734d665fe426a5d7156f2ad7d37b76e30",
          "name": "VicMove",
          "symbol": "VIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25188/thumb/1_trans.png?1650610033"
        },
        {
          "chainId": 56,
          "address": "0xda2e636a6b6d3eaacb3a5fb00f86ead84e0d908f",
          "name": "Equilibrium Exchange",
          "symbol": "EDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29435/thumb/logo.png?1678785158"
        },
        {
          "chainId": 56,
          "address": "0xa53e61578ff54f1ad70186be99332a6e20b6ffa9",
          "name": "Golden Doge",
          "symbol": "GDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17190/thumb/Golden-Doge-Logo-200x200-White-Background.png?1626832299"
        },
        {
          "chainId": 56,
          "address": "0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50",
          "name": "Pitbull",
          "symbol": "PIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15927/thumb/pitbull2.png?1649142523"
        },
        {
          "chainId": 56,
          "address": "0xd01adc16ce42d678800b3fe67e1f053433d8e9f7",
          "name": "SMG",
          "symbol": "SMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23612/thumb/smg.png?1644753960"
        },
        {
          "chainId": 56,
          "address": "0x3efe3bee4dbeb77d260bc12aeb62072cf6e68478",
          "name": "BabyKitty",
          "symbol": "BABYKITTY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22127/thumb/2021-12-26-12-51-04-1-1.png?1640870060"
        },
        {
          "chainId": 56,
          "address": "0x0fd98b8c58560167a236f1d0553a9c2a42342ccf",
          "name": "DeVolution",
          "symbol": "DEVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20353/thumb/Devolution-200x200.png?1636943721"
        },
        {
          "chainId": 56,
          "address": "0xa25199a79a34cc04b15e5c0bba4e3a557364e532",
          "name": "MetaRim",
          "symbol": "RIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23904/thumb/rim.png?1645680141"
        },
        {
          "chainId": 56,
          "address": "0x19857937848c02afbde8b526610f0f2f89e9960d",
          "name": "AmpleSwap",
          "symbol": "AMPLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19475/thumb/ample.PNG?1635282418"
        },
        {
          "chainId": 56,
          "address": "0x6d3a160b86edcd46d8f9bba25c2f88cccade19fc",
          "name": "Football World Community",
          "symbol": "FWC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24258/thumb/IMG_20221202_222442_239.jpg?1670297518"
        },
        {
          "chainId": 56,
          "address": "0x9e24415d1e549ebc626a13a482bb117a2b43e9cf",
          "name": "Lovely Inu finance",
          "symbol": "LOVELY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19053/thumb/lovely-inu-logo-new.png?1634264028"
        },
        {
          "chainId": 56,
          "address": "0x9d39ef3bbca5927909dde44476656b81bbe4ee75",
          "name": "MetaWear",
          "symbol": "WEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24339/thumb/MW_icon.png?1647363556"
        },
        {
          "chainId": 56,
          "address": "0xc06e83828aaf80a2b4883f1e260b1896bfc5bca3",
          "name": "Hudex",
          "symbol": "HU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29887/thumb/logo200x200.png?1681980966"
        },
        {
          "chainId": 56,
          "address": "0x74c22834744e8d5e36c79420ff7b057964aba8a7",
          "name": "NFTBomb",
          "symbol": "NBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20517/thumb/nft_bomb.PNG?1637188326"
        },
        {
          "chainId": 56,
          "address": "0x00e1656e45f18ec6747f5a8496fd39b50b38396d",
          "name": "BombCrypto",
          "symbol": "BCOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18567/thumb/bcoin.png?1638171024"
        },
        {
          "chainId": 56,
          "address": "0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275",
          "name": "Stader BNBx",
          "symbol": "BNBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26842/thumb/BNBx_Logo.png?1660387908"
        },
        {
          "chainId": 56,
          "address": "0xae98e63db1c4646bf5b40b29c664bc922f71bc65",
          "name": "Energyfi",
          "symbol": "EFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23498/thumb/fbb562_28b2de266adc4c63b6d58e3021158c44_mv2.png?1656132886"
        },
        {
          "chainId": 56,
          "address": "0x3606f220daeaeb3d47ac1923a8ce2a61205c88cd",
          "name": "Race Kingdom",
          "symbol": "ATOZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26472/thumb/Race_Kingdom_Logo.png?1658195315"
        },
        {
          "chainId": 56,
          "address": "0xb4bf64b17e270b50d00658e3c0e2fbdefabdd87b",
          "name": "Cheese Swap",
          "symbol": "CHEESE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17730/thumb/CHEESE_Transparent_%28200px%29.png?1629103740"
        },
        {
          "chainId": 56,
          "address": "0x0ff534801e98a4976246d1f418e441783fc9aa15",
          "name": "Future AI",
          "symbol": "FUTURE-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28841/thumb/200x200.png?1674797020"
        },
        {
          "chainId": 56,
          "address": "0x523821d20a283d955f6205b4c9252779cd0f964b",
          "name": "Okcash",
          "symbol": "OK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/274/thumb/ok-logo-200px.png?1665792994"
        },
        {
          "chainId": 56,
          "address": "0x558ad2b02ce979ca54f88206ed8597c8c740774e",
          "name": "MetaVerse M",
          "symbol": "M",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26104/thumb/M.png?1656656415"
        },
        {
          "chainId": 56,
          "address": "0x4cda4daad72340b28925ccd6fa78db631267d3c4",
          "name": "Baby Doge Cash",
          "symbol": "BABYDOGECASH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16846/thumb/babydogecash-cmc-logo.png?1625391403"
        },
        {
          "chainId": 56,
          "address": "0xa25d074d5300f9f997a76994840a3266a72f77e4",
          "name": "AssaPlay",
          "symbol": "ASSA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25575/thumb/assa_coin_logo-02.png?1652676833"
        },
        {
          "chainId": 56,
          "address": "0xb465f3cb6aba6ee375e12918387de1eac2301b05",
          "name": "Trivians",
          "symbol": "TRIVIA",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/26303/thumb/trv-icon-full.png?1657180183"
        },
        {
          "chainId": 56,
          "address": "0x800a25741a414ea6e6e2b382435081a479a8cc3c",
          "name": "SEOR Network",
          "symbol": "SEOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24397/thumb/-yycZmh7_400x400.png?1647523110"
        },
        {
          "chainId": 56,
          "address": "0xbbf8b05ef7af53ccbff8e3673e73714f939bfd84",
          "name": "FROG CEO",
          "symbol": "FROGCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29500/thumb/200.png?1679371048"
        },
        {
          "chainId": 56,
          "address": "0xf027e525d491ef6ffcc478555fbb3cfabb3406a6",
          "name": "StaFi Staked BNB",
          "symbol": "RBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29613/thumb/rBNB.png?1680069749"
        },
        {
          "chainId": 56,
          "address": "0x31c573d1a50a745b01862edaf2ae72017cea036a",
          "name": "Souls of Meta",
          "symbol": "SOM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25515/thumb/soulsofmeta.7527682e.jpg?1652164537"
        },
        {
          "chainId": 56,
          "address": "0x301ff7c013ec7043ffb9453cd3fb32754ccaa1a5",
          "name": "NUDES",
          "symbol": "NUDES",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24888/thumb/NUDES.png?1649252682"
        },
        {
          "chainId": 56,
          "address": "0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42",
          "name": "YSOY Chain",
          "symbol": "YSOY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16822/thumb/ewnD8Bb.png?1625177403"
        },
        {
          "chainId": 56,
          "address": "0x536627083e216c3cf4476ec504eb51bbd47ca4ac",
          "name": "Gaming Doge",
          "symbol": "GAMINGDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19783/thumb/gamingdoge.png?1635846659"
        },
        {
          "chainId": 56,
          "address": "0xe28832f94aa99d3ed4c61ef805330168556b4179",
          "name": "Matrix Protocol",
          "symbol": "MTX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20084/thumb/MTX-200x200.png?1643187548"
        },
        {
          "chainId": 56,
          "address": "0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6",
          "name": "Doggy",
          "symbol": "DOGGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15826/thumb/doggy.png?1622023530"
        },
        {
          "chainId": 56,
          "address": "0xed3d88d3321f82e5c25ca9ac6d5b427ec93f567e",
          "name": "APEmove",
          "symbol": "APE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26594/thumb/Group_48096531.png?1658972586"
        },
        {
          "chainId": 56,
          "address": "0x96a16178edaff58736567cfcaff570c06617f0fd",
          "name": "Eco DeFi",
          "symbol": "ECOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG?1635374624"
        },
        {
          "chainId": 56,
          "address": "0x190b589cf9fb8ddeabbfeae36a813ffb2a702454",
          "name": "bDollar",
          "symbol": "BDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13487/thumb/bdollar-yellow.png?1609291587"
        },
        {
          "chainId": 56,
          "address": "0x01832e3346fd3a0d38ca589d836bd78d1de7030c",
          "name": "SwapTracker",
          "symbol": "SWPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20420/thumb/swaptracker-logo-200x200.png?1637029259"
        },
        {
          "chainId": 56,
          "address": "0x68b5edb385b59e30a7a7db1e681a449e94df0213",
          "name": "Silva",
          "symbol": "SILVA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19689/thumb/13592.png?1635748090"
        },
        {
          "chainId": 56,
          "address": "0xf8dfc70a422ccf0ab206adf7b043fdfb410caab5",
          "name": "Elysium Token",
          "symbol": "ELYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30176/thumb/our_logo_in_png.png?1683547019"
        },
        {
          "chainId": 56,
          "address": "0x617724974218a18769020a70162165a539c07e8a",
          "name": "Olive Cash",
          "symbol": "OLIVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14880/thumb/2_%285%29.png?1618887745"
        },
        {
          "chainId": 56,
          "address": "0xe19a9626aef55e20400a3b82a25c003403e88b7f",
          "name": "Spinada Cash",
          "symbol": "SPIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19881/thumb/7sV8g8d.png?1636085279"
        },
        {
          "chainId": 56,
          "address": "0x3498cfa7d24f7a7d9702cfc33e5fe14226ffcc99",
          "name": "Red Falcon",
          "symbol": "RFN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28722/thumb/23100.png?1673594787"
        },
        {
          "chainId": 56,
          "address": "0xac378638ace29fe9288fe2215fa947dbf5ad59d2",
          "name": "Tikky Inu",
          "symbol": "TIKKY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27945/thumb/tikkyinu_logo.png?1666605799"
        },
        {
          "chainId": 56,
          "address": "0xede5020492be8e265db6141cb0a1d2df9dbae9bb",
          "name": "Dough",
          "symbol": "DOUGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18800/thumb/dough.png?1647078128"
        },
        {
          "chainId": 56,
          "address": "0x60322971a672b81bcce5947706d22c19daecf6fb",
          "name": "MarsDAO",
          "symbol": "MDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24126/thumb/mdao.png?1647700233"
        },
        {
          "chainId": 56,
          "address": "0xf73d8276c15ce56b2f4aee5920e62f767a7f3aea",
          "name": "TCGCoin 2 0",
          "symbol": "TCG2",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18035/thumb/TCG2.jpg?1630306761"
        },
        {
          "chainId": 56,
          "address": "0xb919149a7f1fcf4b5e7a05e6ea6f951ca6d73b5b",
          "name": "To The Moon Token",
          "symbol": "TON",
          "decimals": 18
        },
        {
          "chainId": 56,
          "address": "0x4f39c3319188a723003670c3f9b9e7ef991e52f3",
          "name": "Crypto Fight Club",
          "symbol": "FIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21695/thumb/zGwwAgL.png?1639721544"
        },
        {
          "chainId": 56,
          "address": "0x3780e00d4c60887af38345ccd44f7617dbfb10a0",
          "name": "Dogecoin 2 0",
          "symbol": "DOGE2",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17539/thumb/k4NUHHaO_400x400.jpg?1628141758"
        },
        {
          "chainId": 56,
          "address": "0x40ffafcd7415ed2f7a902312407181140ad14e68",
          "name": "DracooMaster",
          "symbol": "BAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25509/thumb/19587.png?1652162023"
        },
        {
          "chainId": 56,
          "address": "0x5ddb331c3ba48a1d68cbf50dd3bc7aac407dc115",
          "name": "NanoMeter Bitcoin",
          "symbol": "NMBTC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20059/thumb/logo_-_2021-11-09T161322.006.png?1636445625"
        },
        {
          "chainId": 56,
          "address": "0x19048172b5a0893ad008e7c52c11a6dd3c8784a1",
          "name": "Wild Island Game",
          "symbol": "WILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22196/thumb/logo-moneda200.png?1641191848"
        },
        {
          "chainId": 56,
          "address": "0x1baadfd674c641149b0d5a39e697ec877ab47083",
          "name": "CCGDS",
          "symbol": "CCGDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29796/thumb/IMG_20230406_224552_782.jpg?1681347743"
        },
        {
          "chainId": 56,
          "address": "0x24ef78c7092d255ed14a0281ac1800c359af3afe",
          "name": "Rabbit Wallet",
          "symbol": "RAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29433/thumb/200x200.png?1678784505"
        },
        {
          "chainId": 56,
          "address": "0xc632f90affec7121120275610bf17df9963f181c",
          "name": "The Debt Box",
          "symbol": "DEBT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26349/thumb/imgonline-com-ua-Resize-FsfTbGcy5U.png?1657589484"
        },
        {
          "chainId": 56,
          "address": "0x52eb6c887a4691f10bee396778603927c23be1fc",
          "name": "Golden Ball",
          "symbol": "GLB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20274/thumb/glb.PNG?1636707869"
        },
        {
          "chainId": 56,
          "address": "0xcc9b175e4b88a22543c44f1cc65b73f63b0d4efe",
          "name": "Baby Shark",
          "symbol": "SHARK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15653/thumb/Resized_Shark_Logo.png?1624260129"
        },
        {
          "chainId": 56,
          "address": "0x501cb2cd15198a4853bf8944b04c2053410a912b",
          "name": "Helper Coin",
          "symbol": "HLPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29716/thumb/helper.jpg?1680769090"
        },
        {
          "chainId": 56,
          "address": "0x034d4fda3860ea9cc0274e602fb9d9732712480f",
          "name": "Bloody Bunny",
          "symbol": "BONY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28622/thumb/logo_ok_1_%282%29.png?1672708538"
        },
        {
          "chainId": 56,
          "address": "0xdf7434d15656809f56dd17371b2339baacd9b494",
          "name": "Great Bounty Dealer",
          "symbol": "GBD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19686/thumb/13606.png?1635746153"
        },
        {
          "chainId": 56,
          "address": "0x25ee36b6eb10da8225a40462d725b15ed259c51a",
          "name": "X Project",
          "symbol": "X-TOKEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30756/thumb/Frame_12_%281%29.png?1686821283"
        },
        {
          "chainId": 56,
          "address": "0x716de634227c1831f78d1989c92c6495e279a084",
          "name": "SwiftSwap",
          "symbol": "SWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29534/thumb/logo_200x200-01.png?1679478703"
        },
        {
          "chainId": 56,
          "address": "0x14c358b573a4ce45364a3dbd84bbb4dae87af034",
          "name": "DungeonSwap",
          "symbol": "DND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15207/thumb/logo-200x200.png?1620102338"
        },
        {
          "chainId": 56,
          "address": "0xb695806cc5a3cd8623b92bbd221e3bec6e8e3bed",
          "name": "Neutrinos",
          "symbol": "NEUTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29886/thumb/QLDnycl.png?1681980796"
        },
        {
          "chainId": 56,
          "address": "0x6eda890c7c914e9c6b42415d2a3273b022e7b5e7",
          "name": "Risitas Coin",
          "symbol": "RISITA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30781/thumb/meme200.png?1687238168"
        },
        {
          "chainId": 56,
          "address": "0x70294370d5e0a60290493e09b0cf05a6e90dc65a",
          "name": "FREEMOON BINANCE",
          "symbol": "FMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28618/thumb/fmb.png?1672561094"
        },
        {
          "chainId": 56,
          "address": "0x6e02be885fca1138038420fddd4b41c59a8cea6d",
          "name": "Smart Block Chain City",
          "symbol": "SBCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24822/thumb/V6xfvLzv_400x400.jpg?1649040978"
        },
        {
          "chainId": 56,
          "address": "0x43f102bbd52259f2cfd0ef82e8807e3610ae3e40",
          "name": "Save Baby Doge",
          "symbol": "BABYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16923/thumb/BABYDOGE.png?1625635407"
        },
        {
          "chainId": 56,
          "address": "0xfa4a5c4ce029fd6872400545df44675219c2e037",
          "name": "Kephi Gallery",
          "symbol": "KPHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17696/thumb/XJb3jROS_400x400.jpg?1628978969"
        },
        {
          "chainId": 56,
          "address": "0xfc12242996ed64382286d765572f19e9b843f196",
          "name": "Wallet Defi",
          "symbol": "WDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28430/thumb/WDF_200x200.png?1670550477"
        },
        {
          "chainId": 56,
          "address": "0xf9d6ddf44016953dbf7ab135a0f64d7a41870ede",
          "name": "Doge Floki Coin",
          "symbol": "DOFI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19563/thumb/dofi.png?1635408618"
        },
        {
          "chainId": 56,
          "address": "0xbbca42c60b5290f2c48871a596492f93ff0ddc82",
          "name": "Domi",
          "symbol": "DOMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21633/thumb/Transparent_Circle_Logo_2.png?1639627183"
        },
        {
          "chainId": 56,
          "address": "0x4efab39b14167da54aebed2094a61aa1fd384056",
          "name": "Leopard",
          "symbol": "LEOPARD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16270/thumb/IMG_20210430_200245_458.jpg?1623564509"
        },
        {
          "chainId": 56,
          "address": "0x8626264b6a1b4e920905efd381002aba52ea0eea",
          "name": "BlackHat Coin",
          "symbol": "BLKC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png?1622537905"
        },
        {
          "chainId": 56,
          "address": "0xf0997486d784c0ec4ad2ee5b413bd318813dd518",
          "name": "Big Crypto Game",
          "symbol": "CRYPTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28306/thumb/bcg-token-symbol-500px-1.png?1669279388"
        },
        {
          "chainId": 56,
          "address": "0x552594612f935441c01c6854edf111f343c1ca07",
          "name": "Galaxy War",
          "symbol": "GWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg?1641166277"
        },
        {
          "chainId": 56,
          "address": "0xd48d639f72ef29458b72cdc9a47a95fa46101529",
          "name": "HelpKidz Coin",
          "symbol": "HKC",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/25348/thumb/HelpKidz_Coin_200.png?1651304012"
        },
        {
          "chainId": 56,
          "address": "0xbfdfa2143d1aa3efea094e5177295df9e77202a8",
          "name": "Hokkaido Inu",
          "symbol": "HOKA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29595/thumb/HOKKA.png?1679887935"
        },
        {
          "chainId": 56,
          "address": "0xa7deade7bbba2bf0af1572a25b47e477749ef383",
          "name": "Primo DAO",
          "symbol": "PRIMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25508/thumb/19700.png?1652161306"
        },
        {
          "chainId": 56,
          "address": "0x7dc3577681038522d796335e73f2efeccca1878d",
          "name": "SwirlTokenX",
          "symbol": "SWIRLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29410/thumb/swirl2x200.png?1679458658"
        },
        {
          "chainId": 56,
          "address": "0xd2b6bf88b7d9da599331719e338fcdeb235a0b99",
          "name": "Limoverse",
          "symbol": "LIMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29732/thumb/limoverse-blue-logo_200x200.jpg?1681094366"
        },
        {
          "chainId": 56,
          "address": "0x14a83bb74da432258c4051e3976a3f8f6b02a4d9",
          "name": "Me in",
          "symbol": "MEIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27820/thumb/mein-symbol.png?1665978960"
        },
        {
          "chainId": 56,
          "address": "0x162c2332e92be409254ac7c59ad559c16a3f506e",
          "name": "DareNFT",
          "symbol": "DNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19006/thumb/dnft.PNG?1634170397"
        },
        {
          "chainId": 56,
          "address": "0x50ba8bf9e34f0f83f96a340387d1d3888ba4b3b5",
          "name": "RugZombie",
          "symbol": "ZMBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18670/thumb/MKHznEfH_400x400.jpg?1632875374"
        },
        {
          "chainId": 56,
          "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
          "name": "Binance Bitcoin",
          "symbol": "BTCB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14108/thumb/Binance-bitcoin.png?1617332330"
        },
        {
          "chainId": 56,
          "address": "0x8b303d5bbfbbf46f1a4d9741e491e06986894e18",
          "name": "Woonkly Power",
          "symbol": "WOOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14494/thumb/Icono_Infinito_Circular_Blanco_Fondo_Azul_2x.png?1658830979"
        },
        {
          "chainId": 56,
          "address": "0x163f182c32d24a09d91eb75820cde9fd5832b329",
          "name": "ElonDoge io",
          "symbol": "EDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15806/thumb/Edoge.png?1621934765"
        },
        {
          "chainId": 56,
          "address": "0xe8176d414560cfe1bf82fd73b986823b89e4f545",
          "name": "Step Hero",
          "symbol": "HERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17700/thumb/stephero.PNG?1629072670"
        },
        {
          "chainId": 56,
          "address": "0x32767ca0b39a1261e4ba392a605f7fab37d059c7",
          "name": "Viva Classic",
          "symbol": "VIVA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25293/thumb/EeIoXr_0_400x400.jpg?1651140109"
        },
        {
          "chainId": 56,
          "address": "0xe0191fefdd0d2b39b1a2e4e029ccda8a481b7995",
          "name": "CryptoMines Reborn",
          "symbol": "CRUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24538/thumb/Webp.net-resizeimage_%281%29.png?1648072028"
        },
        {
          "chainId": 56,
          "address": "0x960cc8f437165b7362a34d95d1ec62dd2a940f00",
          "name": "CosmicSwap",
          "symbol": "COSMIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16197/thumb/logo_-_2021-06-10T084120.309.png?1623285688"
        },
        {
          "chainId": 56,
          "address": "0x016cf83732f1468150d87dcc5bdf67730b3934d3",
          "name": "AirNFT",
          "symbol": "AIRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18370/thumb/AIRT-Logo.png?1631676503"
        },
        {
          "chainId": 56,
          "address": "0x49c7295ff86eabf5bf58c6ebc858db4805738c01",
          "name": "Hero Arena",
          "symbol": "HERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19160/thumb/hera.png?1634549874"
        },
        {
          "chainId": 56,
          "address": "0x4be63a9b26ee89b9a3a13fd0aa1d0b2427c135f8",
          "name": "XCarnival",
          "symbol": "XCV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19232/thumb/82644942.png?1634768005"
        },
        {
          "chainId": 56,
          "address": "0x7969dc3c6e925bccbea9f7fc466a63c74f0115b8",
          "name": "Lion",
          "symbol": "LION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15314/thumb/lion.PNG?1620376502"
        },
        {
          "chainId": 56,
          "address": "0xdcb624c870d73cdd0b3345762977cb14de598cd0",
          "name": "H2Finance",
          "symbol": "YFIH2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23077/thumb/wnzHxD5M_400x400.jpg?1643176541"
        },
        {
          "chainId": 56,
          "address": "0xdd5a149740c055bdcdc5c066888f739dbe0bf2d0",
          "name": "MoneySwap",
          "symbol": "MSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13576/thumb/logo_%281%29.png?1609888424"
        },
        {
          "chainId": 56,
          "address": "0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68",
          "name": "Manga",
          "symbol": "MANGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17982/thumb/logo-200x200_%287%29.png?1630024708"
        },
        {
          "chainId": 56,
          "address": "0xa9e22e82d5a497c764a9fcd566bc8df933b74fbe",
          "name": "Agritech",
          "symbol": "AGT",
          "decimals": 19,
          "logoURI": "https://assets.coingecko.com/coins/images/30052/thumb/Agritech_Final_200x200_.png?1682996742"
        },
        {
          "chainId": 56,
          "address": "0xb9c255c115636d8cbe107fc953364b243cacdbce",
          "name": "Neural AI",
          "symbol": "NEURALAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29049/thumb/Neural-AI-logo_%281%29.png?1676294231"
        },
        {
          "chainId": 56,
          "address": "0x305bbd18f9a3b55047740843889521722dab1fde",
          "name": "Arora",
          "symbol": "AROR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28654/thumb/cg_200x200.png?1672903532"
        },
        {
          "chainId": 56,
          "address": "0xb25583e5e2db32b7fcbffe3f5e8e305c36157e54",
          "name": "Rex",
          "symbol": "XRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26434/thumb/token-icon-rex.jpg?1658107237"
        },
        {
          "chainId": 56,
          "address": "0x4732a86106064577933552fcea993d30bec950a5",
          "name": "Digichain Coin",
          "symbol": "DIGICHAIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22213/thumb/digichain.png?1641195867"
        },
        {
          "chainId": 56,
          "address": "0x66696ab8c6aaeb22dc14a2dc4a833682388ea901",
          "name": "BongWeedCoin",
          "symbol": "BWC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16138/thumb/bongweed.PNG?1623115285"
        },
        {
          "chainId": 56,
          "address": "0xe91cd52bd65fe23a3eae40e3eb87180e8306399f",
          "name": "Real Realm",
          "symbol": "REAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20880/thumb/vYjuvOhQ_400x400.jpg?1637819845"
        },
        {
          "chainId": 56,
          "address": "0xf14d3692b0055db9ca4c04065165d59b87e763f1",
          "name": "MetaBUSDCoin",
          "symbol": "MBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22156/thumb/P3gIa5oH_400x400.jpg?1640941577"
        },
        {
          "chainId": 56,
          "address": "0xfafbc48f6aa3587984ea50e472304802b39c2604",
          "name": "DogyRace",
          "symbol": "DOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21514/thumb/DogyRaceLogo.png?1639376008"
        },
        {
          "chainId": 56,
          "address": "0x54523d5fb56803bac758e8b10b321748a77ae9e9",
          "name": "Dreams Quest",
          "symbol": "DREAMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19012/thumb/DQ-token.png?1635856569"
        },
        {
          "chainId": 56,
          "address": "0x3a806a3315e35b3f5f46111adb6e2baf4b14a70d",
          "name": "Libera Financial",
          "symbol": "LIBERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26194/thumb/20791.png?1656403503"
        },
        {
          "chainId": 56,
          "address": "0x6f13b1fb6b2897bb40adbc09f7f6cfad181c0904",
          "name": "GEURO",
          "symbol": "GEURO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29688/thumb/CG.png?1680668398"
        },
        {
          "chainId": 56,
          "address": "0xe6a768464b042a6d029394db1fdef360cb60bbeb",
          "name": "Friend",
          "symbol": "FREN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31147/thumb/fren.jpg?1691029866"
        },
        {
          "chainId": 56,
          "address": "0xe96a53af382c669848a87d42df3af6e08c34fa5e",
          "name": "GucciPepe",
          "symbol": "GUCCIPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30425/thumb/200x200.png?1684400842"
        },
        {
          "chainId": 56,
          "address": "0xa2f46fe221f34dac4cf078e6946a7cb4e373ad28",
          "name": "Bafi Finance",
          "symbol": "BAFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13512/thumb/BAFI.png?1617161337"
        },
        {
          "chainId": 56,
          "address": "0x958cc5ac2efa569cd9ad9b9b88245e1f038b02be",
          "name": "Smart World Union",
          "symbol": "SWU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27442/thumb/swu.png?1663937574"
        },
        {
          "chainId": 56,
          "address": "0x71e67b8d88718d113fc7edbd95f7ca380222b3c6",
          "name": "Humanize",
          "symbol": "HMT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27439/thumb/hmt.png?1663935057"
        },
        {
          "chainId": 56,
          "address": "0xb0df5519f460e96117c12ea667557b161866189c",
          "name": "Dx Spot",
          "symbol": "DXS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24224/thumb/DX-ICON.png?1646920676"
        },
        {
          "chainId": 56,
          "address": "0xd1e007d66470d3f775f1d4de52ed158fcc3b7189",
          "name": "ART CAN DIE",
          "symbol": "DIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30457/thumb/Logo_ACD.png?1684654900"
        },
        {
          "chainId": 56,
          "address": "0x127415d59e508c70a3990175c8267eb9c49b84fc",
          "name": "STAY",
          "symbol": "STAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25479/thumb/stay.png?1652008638"
        },
        {
          "chainId": 56,
          "address": "0x2bb06b3f1953f3579dda3bc6cbbd9c78d7ff1340",
          "name": "Potfolio",
          "symbol": "PTF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28839/thumb/200x200potfolio.png?1674796841"
        },
        {
          "chainId": 56,
          "address": "0x71648f61d21d3c75b08414ffa817c4ac2157f89e",
          "name": "CoinRadr",
          "symbol": "RADR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23334/thumb/Vt0zWsHQ_400x400.jpg?1643881443"
        },
        {
          "chainId": 56,
          "address": "0x0a4e1bdfa75292a98c15870aef24bd94bffe0bd4",
          "name": "Fight Of The Ages",
          "symbol": "FOTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26023/thumb/logo_thunder.jpg?1655283704"
        },
        {
          "chainId": 56,
          "address": "0x04c747b40be4d535fc83d09939fb0f626f32800b",
          "name": "ITAM Games",
          "symbol": "ITAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11063/thumb/ITAMGames.png?1587735926"
        },
        {
          "chainId": 56,
          "address": "0x5396734569e26101677eb39c89413f7fa7d8006f",
          "name": "Silver Stonks",
          "symbol": "SSTX",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/17000/thumb/Logo-Silver-Stonks.png?1630048454"
        },
        {
          "chainId": 56,
          "address": "0x3bb55ba11f1a220c7c1e15b56e6cf9c69519c50f",
          "name": "King Dog Inu",
          "symbol": "KINGDOG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23703/thumb/200.png?1645081581"
        },
        {
          "chainId": 56,
          "address": "0xab4e026a2f6f6265c29bbb4c565e66968e3d4962",
          "name": "ScapesMania",
          "symbol": "MANIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30406/thumb/token_scapesmania_200x200px.png?1684374712"
        },
        {
          "chainId": 56,
          "address": "0x97a143545c0f8200222c051ac0a2fc93acbe6ba2",
          "name": "DefiConnect V2",
          "symbol": "DFC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28521/thumb/dfc.png?1671276843"
        },
        {
          "chainId": 56,
          "address": "0xc79d1fd14f514cd713b5ca43d288a782ae53eab2",
          "name": "Xcel Defi",
          "symbol": "XLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19318/thumb/PNG_XLD.png?1642750313"
        },
        {
          "chainId": 56,
          "address": "0x9334e37fad7c41cd6c9565bff3a97ce31cee52a3",
          "name": "Swych",
          "symbol": "SWYCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29594/thumb/6brcelg1_400x400.png?1679887806"
        },
        {
          "chainId": 56,
          "address": "0x5c0d3c165dc46cfd5ec80bbb1bf7cb8631f9d6c7",
          "name": "Wallet SAFU",
          "symbol": "WSAFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29092/thumb/IMG_20230216_011917_274.jpg?1676526671"
        },
        {
          "chainId": 56,
          "address": "0x9321bc6185adc9b9cb503cc211e17cb311c3fa95",
          "name": "SafuuGO",
          "symbol": "SGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30668/thumb/SafuuGO_200x200.png?1686209881"
        },
        {
          "chainId": 56,
          "address": "0xe097bceb09bfb18047cf259f321cc129b7beba5e",
          "name": "TipsyCoin",
          "symbol": "TIPSY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24821/thumb/TipsyCoin-Icon.png?1649040735"
        },
        {
          "chainId": 56,
          "address": "0x61a802de6327a05dda95812ae1535109599f7df2",
          "name": "VICUNA",
          "symbol": "VINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28261/thumb/light-removebg-preview.png?1668765757"
        },
        {
          "chainId": 56,
          "address": "0xd5ff3786ce4a75156d27ab026eb04c9ed53b365f",
          "name": "SHIBCAT",
          "symbol": "SHIBCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29062/thumb/photo_2023-02-13_17-38-39-removebg-preview.png?1676358861"
        },
        {
          "chainId": 56,
          "address": "0xa93e4bbe09b834b5a13dcd832daeaefe79fb4ae9",
          "name": "Red Pepe",
          "symbol": "REDPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30449/thumb/redpepelogo.png?1684489227"
        },
        {
          "chainId": 56,
          "address": "0x3022d80e02075f5a2a442a318229487f9ea66d82",
          "name": "Cells Token",
          "symbol": "CELLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30218/thumb/logo200.png?1683628843"
        },
        {
          "chainId": 56,
          "address": "0xd18a65de8dd028139f088cbd87cdd7fd196c00d7",
          "name": "MatchNova Champion Coin",
          "symbol": "MCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30260/thumb/Logo200*200MatchNova.png?1683772138"
        },
        {
          "chainId": 56,
          "address": "0xec126e20e7cb114dd3ba356100eaca2cc2921322",
          "name": "Solar Energy",
          "symbol": "SEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20935/thumb/seg.png?1638010532"
        },
        {
          "chainId": 56,
          "address": "0x7a9c8d33963aecca9a821802adfaf5bd9392351f",
          "name": "Duckie Land Multi Metaverse",
          "symbol": "MMETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24186/thumb/MMETA_200X200.png?1646808063"
        },
        {
          "chainId": 56,
          "address": "0xd618ad98e6557532d3c65e88bf1ff765724f21c9",
          "name": "Trade Tech AI",
          "symbol": "TTAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29502/thumb/TAI__logo_200.png?1679372549"
        },
        {
          "chainId": 56,
          "address": "0x708739980021a0b0b2e555383fe1283697e140e9",
          "name": "Metacourt",
          "symbol": "BLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15708/thumb/metacourt.png?1661489311"
        },
        {
          "chainId": 56,
          "address": "0x7dc6dc21ce07e6499fdcc30dcdb943a8ee4db802",
          "name": "GPT Guru",
          "symbol": "GPTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30727/thumb/200.png?1686634322"
        },
        {
          "chainId": 56,
          "address": "0x138218c8e064ed2a011c9ff203759a8a1e23e6c8",
          "name": "MintMe com Coin",
          "symbol": "MINTME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801"
        },
        {
          "chainId": 56,
          "address": "0x05f816b53a4c7b6b3bfbe1f759da7fe188689c5b",
          "name": "Shaun Inu",
          "symbol": "SHAUN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30258/thumb/Shauninu_%281%29.png?1683771808"
        },
        {
          "chainId": 56,
          "address": "0x8e2d8f40818fbaba663db6a24fb9b527fc7100be",
          "name": "ORE",
          "symbol": "ORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19047/thumb/12752.png?1634253604"
        },
        {
          "chainId": 56,
          "address": "0x230f6e7904ffc156abd8adfd7556e56e2a358cb1",
          "name": "Posschain",
          "symbol": "POSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25994/thumb/Posschain-200x200.png?1655447861"
        },
        {
          "chainId": 56,
          "address": "0x0de08c1abe5fb86dd7fd2ac90400ace305138d5b",
          "name": "Idena",
          "symbol": "IDNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10548/thumb/idena200x200.png?1580852687"
        },
        {
          "chainId": 56,
          "address": "0x309d43cb7bb1e07371eee4947103aa019121a973",
          "name": "AiWallet",
          "symbol": "AIWALLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29118/thumb/logo-ai.png?1676778904"
        },
        {
          "chainId": 56,
          "address": "0x4634d58982138e93c951c1485d25bc619cbd1f75",
          "name": "AiONE",
          "symbol": "AIONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30431/thumb/200_%281%29.png?1684463739"
        },
        {
          "chainId": 56,
          "address": "0xdfa1c5fcd4d64729cdf6d553b2fb1def11a7c689",
          "name": "DCOREUM",
          "symbol": "DCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29230/thumb/1677138170437-155492507193bb3547fcf8ce0ad2e340__1_-removebg-preview.png?1677542867"
        },
        {
          "chainId": 56,
          "address": "0x454b90716a9435e7161a9aea5cf00e0acbe565ae",
          "name": "Source Protocol",
          "symbol": "SRCX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25898/thumb/80368b_7b0a30441d7c4134a35067553ef6de3e_mv2_%281%29.png?1654591482"
        },
        {
          "chainId": 56,
          "address": "0x6d342877fc199c629f49a5c6c521c297b15bc92d",
          "name": "CookieSale",
          "symbol": "COOKIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27898/thumb/logo.png?1666320004"
        },
        {
          "chainId": 56,
          "address": "0x524df384bffb18c0c8f3f43d012011f8f9795579",
          "name": "YAY Network",
          "symbol": "YAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18251/thumb/Av_%281%29.png?1678850065"
        },
        {
          "chainId": 56,
          "address": "0x4861ba0ce919fee66b41c85a08a7476557914275",
          "name": "Continuum Finance",
          "symbol": "CTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24338/thumb/LMWqfjP6_400x400.png?1647362901"
        },
        {
          "chainId": 56,
          "address": "0x8d9fb713587174ee97e91866050c383b5cee6209",
          "name": "ScarQuest",
          "symbol": "SCAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20421/thumb/velhalla_DP.png?1683167831"
        },
        {
          "chainId": 56,
          "address": "0x5d9cb4c6f988f8ed166f175a5b8be7e3cbca850d",
          "name": "Lamden",
          "symbol": "TAU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2155/thumb/Lamden_Logo_2020_-_White_w_Black_Borders.png?1597732013"
        },
        {
          "chainId": 56,
          "address": "0x0733618ab62eeec815f2d1739b7a50bf9e74d8a2",
          "name": "Pomerium Ecosystem Token",
          "symbol": "PMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29544/thumb/pomerium-logo-PMG_%281%29.png?1680148705"
        },
        {
          "chainId": 56,
          "address": "0x9573c88ae3e37508f87649f87c4dd5373c9f31e0",
          "name": "Monsta Infinite",
          "symbol": "MONI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18396/thumb/moni.png?1631763118"
        },
        {
          "chainId": 56,
          "address": "0x509a51394cc4d6bb474fefb2994b8975a55a6e79",
          "name": "Fufu",
          "symbol": "FUFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18812/thumb/fufu_icon_RGB.png?1633497540"
        },
        {
          "chainId": 56,
          "address": "0x9faf07d1fbc130d698e227e50d1fb72657c0a342",
          "name": "bHIVE",
          "symbol": "BHIVE",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/27596/thumb/hive.png?1664709654"
        },
        {
          "chainId": 56,
          "address": "0xc628d60b7ec7504b7482bc8a65348f3b7afccbe0",
          "name": "OKEYCOIN",
          "symbol": "OKEY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26821/thumb/OKEYCOIN_LOGO.png?1660280496"
        },
        {
          "chainId": 56,
          "address": "0x9b4102909d03331e01596169a95de6748486f856",
          "name": "Decentify",
          "symbol": "DFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30495/thumb/cgassets.png?1684722914"
        },
        {
          "chainId": 56,
          "address": "0xcae3d82d63e2b0094bc959752993d3d3743b5d08",
          "name": "BTAF token",
          "symbol": "BTAF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28871/thumb/BTAF_BSC200x200-CoinGecko.png?1675130943"
        },
        {
          "chainId": 56,
          "address": "0x5c0d0111ffc638802c9efccf55934d5c63ab3f79",
          "name": "Dynamic Finance",
          "symbol": "DYNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28962/thumb/d-200px.png?1675743061"
        },
        {
          "chainId": 56,
          "address": "0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28",
          "name": "xWIN Finance",
          "symbol": "XWIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15400/thumb/200x200_%28transparent_background%29.png?1622515538"
        },
        {
          "chainId": 56,
          "address": "0x68de53b47be0dc566bf4673c748d58bbbad3deb1",
          "name": "DogeGrow",
          "symbol": "DGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29532/thumb/IMG_20230323_110317_093.jpg?1679561556"
        },
        {
          "chainId": 56,
          "address": "0x116204ba071bb5c8b203e2a5f903f557b54ef577",
          "name": "Cavatar",
          "symbol": "CAVAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30064/thumb/cavatar_1024x1024.png?1683006037"
        },
        {
          "chainId": 56,
          "address": "0x57bc18f6177cdaffb34ace048745bc913a1b1b54",
          "name": "Bit Hotel",
          "symbol": "BTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20363/thumb/BIT_HOTEL_LOGO.png?1636946715"
        },
        {
          "chainId": 56,
          "address": "0xcfefa64b0ddd611b125157c41cd3827f2e8e8615",
          "name": "KickPad",
          "symbol": "KPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14531/thumb/kickpad_logo.jpg?1616750879"
        },
        {
          "chainId": 56,
          "address": "0x4d993ec7b44276615bb2f6f20361ab34fbf0ec49",
          "name": "BrandPad Finance",
          "symbol": "BRAND",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19532/thumb/brandpad.PNG?1635373732"
        },
        {
          "chainId": 56,
          "address": "0xaa988635cdb9ca6dedb1d97ddc8e7de4285fe5f6",
          "name": "BlockChainGames",
          "symbol": "BCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29895/thumb/photo_2023-04-17_13.35.14.jpeg?1681982284"
        },
        {
          "chainId": 56,
          "address": "0x73cba57ad8bc775a5345d9a0de2e90c74621d802",
          "name": "LooksCoin",
          "symbol": "LOOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3080/thumb/lookscoin200.png?1662543370"
        },
        {
          "chainId": 56,
          "address": "0xb6d48fcef36e19681ee29896b19c1b6cbd1eab1b",
          "name": "Fanadise",
          "symbol": "FAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17417/thumb/logo_fanadise_new_200x200.png?1638260115"
        },
        {
          "chainId": 56,
          "address": "0xddae5f343b7768eadaad88a7f520fff54f198211",
          "name": "Bitcoiva",
          "symbol": "BCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13016/thumb/Untitled-design-6-removebg-preview.png?1604389289"
        },
        {
          "chainId": 56,
          "address": "0x36f1f32c728c3f330409ec1f0928fa3ab3c8a76f",
          "name": "Adroverse",
          "symbol": "ADR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24556/thumb/92mtj657_400x400.jpg?1648136168"
        },
        {
          "chainId": 56,
          "address": "0x84f4f7cdb4574c9556a494dab18ffc1d1d22316c",
          "name": "King Shiba",
          "symbol": "KINGSHIB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19215/thumb/kingshib.png?1661498524"
        },
        {
          "chainId": 56,
          "address": "0x9e3a9f1612028eee48f85ca85f8bed2f37d76848",
          "name": "Quidax",
          "symbol": "QDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16032/thumb/3k5qGOw1_400x400.jpg?1622673737"
        },
        {
          "chainId": 56,
          "address": "0xf6d2657ebb5602bf823901412c5e41e030f3ece2",
          "name": "Chitaverse",
          "symbol": "BCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29769/thumb/baby-chita-head-logo.png?1681198698"
        },
        {
          "chainId": 56,
          "address": "0x0cf8e180350253271f4b917ccfb0accc4862f262",
          "name": "Bitcoin BR",
          "symbol": "BTCBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691"
        },
        {
          "chainId": 56,
          "address": "0x06082951efdb5095e45e3c08cb103782645a2e69",
          "name": "Wateenswap",
          "symbol": "WTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26333/thumb/Xs5yrJym_400x400.jpeg?1657492066"
        },
        {
          "chainId": 56,
          "address": "0xa2e3e2817f00822f3c1e71a604eca4196af923d6",
          "name": "Torekko",
          "symbol": "TRK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23357/thumb/trk.PNG?1643927454"
        },
        {
          "chainId": 56,
          "address": "0xc07f685bcf67ed7069b67d28556835b7c3bda55f",
          "name": "zkNFTex",
          "symbol": "ZKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28610/thumb/_ZKN.jpg?1672468352"
        },
        {
          "chainId": 56,
          "address": "0x963556de0eb8138e97a85f0a86ee0acd159d210b",
          "name": "Melega",
          "symbol": "MARCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27568/thumb/MELEGA_logo_halo_200x200px.png?1664524259"
        },
        {
          "chainId": 56,
          "address": "0x515e62a3ac560fd5df08536d08336e63aed3b182",
          "name": "SMP Finance",
          "symbol": "SMPF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30891/thumb/tokenlogo.png?1688540743"
        },
        {
          "chainId": 56,
          "address": "0xcffd4d3b517b77be32c76da768634de6c738889b",
          "name": "ESPL Arena",
          "symbol": "ARENA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28728/thumb/200x200_Logo.png?1673688766"
        },
        {
          "chainId": 56,
          "address": "0x657b632714e08ac66b79444ad3f3875526ee6689",
          "name": "Ftribe Fighters",
          "symbol": "F2C",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22250/thumb/d2pxPhBW_400x400.png?1641278639"
        },
        {
          "chainId": 56,
          "address": "0x037b202ca88d2028d82936d5615ee5088cb9fd78",
          "name": "Distributed Autonomous Organization",
          "symbol": "DAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30667/thumb/Untitled_3.png?1686209335"
        },
        {
          "chainId": 56,
          "address": "0x802c68730212295149f2bea78c25e2cf5a05b8a0",
          "name": "Corgidoge",
          "symbol": "CORGI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17156/thumb/corgi-200.png?1626671116"
        },
        {
          "chainId": 56,
          "address": "0x450dcf93160a30be156a4600802c91bf64dffd2e",
          "name": "CorgiCoin",
          "symbol": "CORGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16096/thumb/BMfphzgF_400x400.png?1622881650"
        },
        {
          "chainId": 56,
          "address": "0xee246aa7e2ecf136466c6fe4808f395861c6a04e",
          "name": "Punk Panda Messenger",
          "symbol": "PPM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28115/thumb/PunkPanda_200x200.png?1667553649"
        },
        {
          "chainId": 56,
          "address": "0xf91d58b5ae142dacc749f58a49fcbac340cb0343",
          "name": "Venus FIL",
          "symbol": "VFIL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13925/thumb/vfil_final.png?1612966425"
        },
        {
          "chainId": 56,
          "address": "0x14eb173d2b4b8d888f9886bbec41ec1b0e18f2b6",
          "name": "Tourist Shiba Inu",
          "symbol": "TOURISTS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23878/thumb/TOURISTS-200x200px.png?1645600641"
        },
        {
          "chainId": 56,
          "address": "0x734548a9e43d2d564600b1b2ed5be9c2b911c6ab",
          "name": "Meta Apes PEEL",
          "symbol": "PEEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26450/thumb/PEEL200x200.png?1658112274"
        },
        {
          "chainId": 56,
          "address": "0xb5102cee1528ce2c760893034a4603663495fd72",
          "name": "dForce USD",
          "symbol": "USX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920"
        },
        {
          "chainId": 56,
          "address": "0xa4a66d8a705260c8cb1ebb59224e018015294f48",
          "name": "TED BNB",
          "symbol": "TED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30240/thumb/logo_%281%29.png?1683690619"
        },
        {
          "chainId": 56,
          "address": "0x0cca2f5561bb0fca88e5b9b48b7fbf000349c357",
          "name": "Zodium",
          "symbol": "ZODI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21181/thumb/s4TSr4S_-_Imgur.png?1638505121"
        },
        {
          "chainId": 56,
          "address": "0x4756cd85cd07769c2ce07a73497f208d56d48ec1",
          "name": "DOGECOLA",
          "symbol": "DOGECOLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17371/thumb/11271.png?1669461804"
        },
        {
          "chainId": 56,
          "address": "0x4b71bd5e1db6cce4179e175a3a2033e4f17b7432",
          "name": "Gameology",
          "symbol": "GMY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16364/thumb/CRfFHQ7.png?1623815522"
        },
        {
          "chainId": 56,
          "address": "0xd635b32688f36ee4a7fe117b4c91dd811277acb6",
          "name": "Copycat Finance",
          "symbol": "COPYCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18276/thumb/copycat.PNG?1631233811"
        },
        {
          "chainId": 56,
          "address": "0x014a087b646bd90e7dcead3993f49eb1f4b5f30a",
          "name": "GulfCoin",
          "symbol": "GULF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26053/thumb/gulf-coin-logo-A2F3BBC063-seeklogo.com.png?1655453831"
        },
        {
          "chainId": 56,
          "address": "0x25574cad6f03ffacd9d08b288e8d5d88997fb2f3",
          "name": "RedFeg",
          "symbol": "REDFEG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17292/thumb/redfeg.PNG?1627253259"
        },
        {
          "chainId": 56,
          "address": "0x37407d1cabc422155a148bc7a3a0587c64225ea2",
          "name": "Meta FPS",
          "symbol": "MFPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29993/thumb/Logo200x200.png?1682470297"
        },
        {
          "chainId": 56,
          "address": "0xcfa65d49541a0a930a19321c797e442123822fb4",
          "name": "STAR QUACKS",
          "symbol": "QUACKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30302/thumb/IMG-20230510-WA0029.jpg?1683872552"
        },
        {
          "chainId": 56,
          "address": "0x8ec7e8c12947642a6bd232a73bbd7b2ff310a588",
          "name": "ByteEx",
          "symbol": "BX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/22668/thumb/0dc42cf2df4ec721142dc8b64b39c7cd.png?1642403952"
        },
        {
          "chainId": 56,
          "address": "0xd74b782e05aa25c50e7330af541d46e18f36661c",
          "name": "Rich Quack",
          "symbol": "QUACK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16356/thumb/57198446-0-Get-Rich-Quick-Gober.png?1623812294"
        },
        {
          "chainId": 56,
          "address": "0xf1ca73caa1c7ad66af11147ba7d5636243af0493",
          "name": "RegularPresale",
          "symbol": "REGU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20797/thumb/W1byu.png?1637677488"
        },
        {
          "chainId": 56,
          "address": "0x3ac0f8cecc1fb0ee6c2017a072d52e85b00c6694",
          "name": "KingSpeed",
          "symbol": "KSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21246/thumb/attachment.png?1645412281"
        },
        {
          "chainId": 56,
          "address": "0x61909950e1bfb5d567c5463cbd33dc1cdc85ee93",
          "name": "Lithosphere",
          "symbol": "LITHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21128/thumb/6gizpBLn.png?1638347028"
        },
        {
          "chainId": 56,
          "address": "0xa2315cc5a1e4ae3d0a491ed4fe45ebf8356feac7",
          "name": "Ripae pBNB",
          "symbol": "PBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25765/thumb/OadXb6ll_400x400.jpg?1653549689"
        },
        {
          "chainId": 56,
          "address": "0x984811e6f2695192add6f88615df637bf52a5cae",
          "name": "HOPPY",
          "symbol": "HOP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16844/thumb/TOKEN-ICON-T.png?1625834520"
        },
        {
          "chainId": 56,
          "address": "0x517396bd11d750e4417b82f2b0fcfa62a4f2bb96",
          "name": "ITEMVERSE",
          "symbol": "ITEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26716/thumb/%ED%86%A0%ED%81%B0%ED%8B%B0%EC%BB%A4%28%EC%8B%AC%EB%B2%8C%29.jpg?1659857195"
        },
        {
          "chainId": 56,
          "address": "0x36a8fcb1f8bca02dc74eb34281de3b9143eae8e3",
          "name": "Ember",
          "symbol": "EMBER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28480/thumb/Ember_Logo.jpg?1671006672"
        },
        {
          "chainId": 56,
          "address": "0xd73e883e203646e87f6d073baf3d7719bda68bcb",
          "name": "Rhythm",
          "symbol": "RHYTHM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17698/thumb/rhythm.png?1629037432"
        },
        {
          "chainId": 56,
          "address": "0xc2eaaf69e6439abab12dd21f560ba0ec7f17cff7",
          "name": "Pepe Original Version",
          "symbol": "POV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30121/thumb/our_logo.png?1683257729"
        },
        {
          "chainId": 56,
          "address": "0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a",
          "name": "Dark Energy Crystals",
          "symbol": "DEC",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png?1603683753"
        },
        {
          "chainId": 56,
          "address": "0xcf9f991b14620f5ad144eec11f9bc7bf08987622",
          "name": "PornRocket",
          "symbol": "PORNROCKET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16207/thumb/PornRocket.png?1623307659"
        },
        {
          "chainId": 56,
          "address": "0xb64e280e9d1b5dbec4accedb2257a87b400db149",
          "name": "Level",
          "symbol": "LVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28628/thumb/Token.png?1672717607"
        },
        {
          "chainId": 56,
          "address": "0x0fc0b3f6f5c769c138088266ac21760ab33f76ca",
          "name": "Cracle",
          "symbol": "CRA",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/29621/thumb/logo.jpg?1680076988"
        },
        {
          "chainId": 56,
          "address": "0xd73f32833b6d5d9c8070c23e599e283a3039823c",
          "name": "Waterfall Governance",
          "symbol": "WTF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19189/thumb/wtf_icon_avatar_200x200.png?1634631004"
        },
        {
          "chainId": 56,
          "address": "0x9a3321e1acd3b9f6debee5e042dd2411a1742002",
          "name": "PIGS",
          "symbol": "AFP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25910/thumb/Screenshot_from_2022-06-07_15-42-58.png?1654587865"
        },
        {
          "chainId": 56,
          "address": "0x0f1c6791a8b8d764c78dd54f0a151ec4d3a0c090",
          "name": "Tama Finance",
          "symbol": "TAMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15702/thumb/Q0bhsxl.png?1621576078"
        },
        {
          "chainId": 56,
          "address": "0xe65725fedb66586cbe32615e097a01c0aa43ae89",
          "name": "Gym AI",
          "symbol": "GYMAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29061/thumb/200x200-removebg-preview.png?1676358295"
        },
        {
          "chainId": 56,
          "address": "0xef7d50069406a2f5a53806f7250a6c0f17ad9dcd",
          "name": "beFITTER",
          "symbol": "FIU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25995/thumb/FIU_Token.jpg?1655194561"
        },
        {
          "chainId": 56,
          "address": "0x906089721cc5cb41c87d35bb05bea70bdf47a269",
          "name": "BabyDogeARMY",
          "symbol": "ARMY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29974/thumb/200x200-transparent.png?1682400003"
        },
        {
          "chainId": 56,
          "address": "0x5c65badf7f97345b7b92776b22255c973234efe7",
          "name": "Local Traders",
          "symbol": "LCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27936/thumb/LT-Logo-200x200_%281%29_%281%29.png?1666510280"
        },
        {
          "chainId": 56,
          "address": "0x182fd4f68695f1951018b5f8c1b2f778919ff0ce",
          "name": "Dogepad Finance",
          "symbol": "DPF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28468/thumb/Doge-Pad-15.png?1671000541"
        },
        {
          "chainId": 56,
          "address": "0x4da8265be61b9da59c8e207bfcbc075f1d611f36",
          "name": "Rin Finance Coin",
          "symbol": "RIFICO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25332/thumb/rifico_logo_png_1.png?1665371994"
        },
        {
          "chainId": 56,
          "address": "0xbfea674ce7d16e26e39e3c088810367a708ef94c",
          "name": "April",
          "symbol": "APRIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15689/thumb/200-by-200-01.png?1621565063"
        },
        {
          "chainId": 56,
          "address": "0x580e2b3170aa36e7018ead248298c8cc18b0f019",
          "name": "Zibu",
          "symbol": "ZIBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28448/thumb/zibu_x200.png?1670663376"
        },
        {
          "chainId": 56,
          "address": "0x95d07a6b1dd22cfc6f775e9574e4374995e7ef89",
          "name": "Athenas",
          "symbol": "ATHENASV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23883/thumb/banner-atn-2.png?1645601382"
        },
        {
          "chainId": 56,
          "address": "0x167e5455e4c978883b414e7f02c0147eec9a18e9",
          "name": "Life v2",
          "symbol": "LTNV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20568/thumb/1-j2MSwg_400x400.jpg?1637225507"
        },
        {
          "chainId": 56,
          "address": "0x62529d7de8293217c8f74d60c8c0f6481de47f0e",
          "name": "Daylight Protocol",
          "symbol": "DAYL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29173/thumb/DAYL_Ticker.png?1677127392"
        },
        {
          "chainId": 56,
          "address": "0x859ac2100b85868bf7387d27e339da502fd1eabc",
          "name": "Body Ai",
          "symbol": "BAIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29588/thumb/Logo_200_white.jpg?1679885373"
        },
        {
          "chainId": 56,
          "address": "0x77f86d401e067365dd911271530b0c90dec3e0f7",
          "name": "LOFI",
          "symbol": "LOFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27762/thumb/lofi.jpeg?1671676321"
        },
        {
          "chainId": 56,
          "address": "0x89671544190ee39e469c8393009875df6565457a",
          "name": "SpaceGrime",
          "symbol": "GRIMEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15902/thumb/spacegrime.PNG?1622411596"
        },
        {
          "chainId": 56,
          "address": "0x4adc604a0261e3d340745533964fff6bb130f3c3",
          "name": "Pesabase",
          "symbol": "PESA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25437/thumb/PESA1.jpg?1687400839"
        },
        {
          "chainId": 56,
          "address": "0xc85a2576693e5a6206398fe1c498c4bfe214df58",
          "name": "CLIQ",
          "symbol": "CT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21687/thumb/SpottR_logo.png?1639719040"
        },
        {
          "chainId": 56,
          "address": "0x21004b11939359e7e962db6675d56f50353df29c",
          "name": "EternalFlow",
          "symbol": "EFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21536/thumb/EFTLogoWP.png?1639401769"
        },
        {
          "chainId": 56,
          "address": "0x3eac3819403ff5aec83dc87c93e3ec3951183799",
          "name": "Bee Token",
          "symbol": "BGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25941/thumb/logoBeeco.png?1654765502"
        },
        {
          "chainId": 56,
          "address": "0xf33893de6eb6ae9a67442e066ae9abd228f5290c",
          "name": "GroveCoin",
          "symbol": "GRV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25549/thumb/200x200.png?1652335189"
        },
        {
          "chainId": 56,
          "address": "0x71809c4ff017ceade03038a8b597ecabb6519918",
          "name": "Cockapoo",
          "symbol": "CPOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16852/thumb/cpoo.png?1644648415"
        },
        {
          "chainId": 56,
          "address": "0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d",
          "name": "BSC Station",
          "symbol": "BSCS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14963/thumb/BSCS_LOGO.png?1619158072"
        },
        {
          "chainId": 56,
          "address": "0x43ce64a4fba3c30c0164e935b0095862a67dc0c9",
          "name": "Nekocoin",
          "symbol": "NEKOS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/24122/thumb/sVQIaHgA_400x400.jpg?1646461535"
        },
        {
          "chainId": 56,
          "address": "0x7e60c74b9096f8fa6fb5a9fd88405ded8b7d44f3",
          "name": "Infiblue World",
          "symbol": "MONIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30236/thumb/Monie_logo_200.png?1683710114"
        },
        {
          "chainId": 56,
          "address": "0xf397680d99a92e4b60637e9f5c71a4def1f6c7b5",
          "name": "FlightClupcoin",
          "symbol": "FLIGHT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/28647/thumb/IO74iBmD_200x200-4.png?1672883991"
        },
        {
          "chainId": 56,
          "address": "0x59f4f336bf3d0c49dbfba4a74ebd2a6ace40539a",
          "name": "Catcoin",
          "symbol": "CAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25279/thumb/cat5.png?1679887181"
        },
        {
          "chainId": 56,
          "address": "0xe8a8db73f7fb1ab5f05c619a8a631c8930c782ae",
          "name": "Future Of Fintech",
          "symbol": "FOF",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26837/thumb/FOF_logo.png?1660304045"
        },
        {
          "chainId": 56,
          "address": "0x192eb3e89c09590ddb86af1172094b0719a67b34",
          "name": "Pepeki",
          "symbol": "PEPEKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30412/thumb/logo.png?1684386980"
        },
        {
          "chainId": 56,
          "address": "0x1e446cbea52badeb614fbe4ab7610f737995fb44",
          "name": "Saturna",
          "symbol": "SAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15545/thumb/dkejn0.png?1621296947"
        },
        {
          "chainId": 56,
          "address": "0x545356d4d69d8cd1213ee7e339867574738751ca",
          "name": "KTX Finance",
          "symbol": "KTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30537/thumb/KTX.jpeg?1684912193"
        },
        {
          "chainId": 56,
          "address": "0x147e07976e1ae78287c33aafaab87760d32e50a5",
          "name": "dexIRA",
          "symbol": "DEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18872/thumb/dexIRA_Icon_2.jpg?1653470414"
        },
        {
          "chainId": 56,
          "address": "0xc350caa89eb963d5d6b964324a0a7736d8d65533",
          "name": "Infinitee",
          "symbol": "INFTEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17010/thumb/infinitee_logo.png?1626067348"
        },
        {
          "chainId": 56,
          "address": "0x3afe2901489f0cd7503a2815a655829f00305270",
          "name": "Meta Space 2045",
          "symbol": "MTW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28168/thumb/KakaoTalk_20221111_171601048.png?1668239034"
        },
        {
          "chainId": 56,
          "address": "0x0e08093f9f4d695ad12837db11277baf5feb34f2",
          "name": "Galaxy Survivor",
          "symbol": "GLXY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30095/thumb/Group_3.png?1683023761"
        },
        {
          "chainId": 56,
          "address": "0x23396cf899ca06c4472205fc903bdb4de249d6fc",
          "name": "Wrapped USTC",
          "symbol": "USTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058"
        },
        {
          "chainId": 56,
          "address": "0xf7a086bff67ded4aa785e8a0a81d4345d9bb4740",
          "name": "MetaSafeMoon",
          "symbol": "METASFM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21257/thumb/logo-200x200.png?1638778766"
        },
        {
          "chainId": 56,
          "address": "0xc2ebaa5f640b30c0d6712a6e0656fb816c10a7d4",
          "name": "BIDZ Coin",
          "symbol": "BIDZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26177/thumb/O99u27zU_400x400.png?1656380941"
        },
        {
          "chainId": 56,
          "address": "0x2541be91fe0d220ffcbe65f11d88217a87a43bda",
          "name": "Meme Lordz",
          "symbol": "LORDZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17412/thumb/meme-lordz-icon-green.png?1627570797"
        },
        {
          "chainId": 56,
          "address": "0x84b381692ae5e14b22f4217ff0ff1ad12877b34e",
          "name": "zkVAULT",
          "symbol": "ZKVAULT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30960/thumb/0xfi_v.png?1689220971"
        },
        {
          "chainId": 56,
          "address": "0x6fc39ac154cfd20f1951a2823abab7ec471b783a",
          "name": "SpritzMoon Crypto Token",
          "symbol": "SPRITZMOON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24401/thumb/SpritzMoon_logo_new.png?1675186709"
        },
        {
          "chainId": 56,
          "address": "0x4505e2ae4f9c512fd2e7e4d99c99dc94e0e93ccb",
          "name": "Mudra MDR",
          "symbol": "MDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26145/thumb/MudraLogo200x200.png?1656039972"
        },
        {
          "chainId": 56,
          "address": "0x7fa92c33fdfa1050256437b302832a2ed530859f",
          "name": "BergerDoge",
          "symbol": "BERGERDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28384/thumb/photo_2022-12-02_22.18.34.png?1670059798"
        },
        {
          "chainId": 56,
          "address": "0x19cd9b8e42d4ef62c3ea124110d5cfd283ceac43",
          "name": "Battle Infinity",
          "symbol": "IBAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27069/thumb/ibat_logo.png?1661674405"
        },
        {
          "chainId": 56,
          "address": "0x0cbd6fadcf8096cc9a43d90b45f65826102e3ece",
          "name": "CheckDot",
          "symbol": "CDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20370/thumb/token-200x200_%281%29.png?1636949075"
        },
        {
          "chainId": 56,
          "address": "0xdb612e3b52d8eda1a3e27bde2c82cc2d9ca9a61d",
          "name": "PE PE POKEMOON",
          "symbol": "PEMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30393/thumb/Artboard_10_%281%29.png?1684314760"
        },
        {
          "chainId": 56,
          "address": "0x8b025f2dad998c2983cc1bf23d5ebe65124f0fe1",
          "name": "BabyAMA",
          "symbol": "BAMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30790/thumb/Asset_5.png?1687334398"
        },
        {
          "chainId": 56,
          "address": "0xbb6cdedac5cab4a420211a4a8e8b5dca879b31de",
          "name": "MetaFighter",
          "symbol": "MF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23261/thumb/TXirEzAw_400x400.jpg?1643362619"
        },
        {
          "chainId": 56,
          "address": "0xd467d078fa363985805b1c3624f26045ba5709df",
          "name": "Geek Protocol",
          "symbol": "GEEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28553/thumb/logo300black2.png?1671697091"
        },
        {
          "chainId": 56,
          "address": "0xf625069dce62df95b4910f83446954b871f0fc4f",
          "name": "Amazy Move Token",
          "symbol": "AMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27246/thumb/AMT_200x200.png?1662884836"
        },
        {
          "chainId": 56,
          "address": "0xd38de430d355a7a8337204c2a4c80392e61475a6",
          "name": "DuckyCoinAI",
          "symbol": "DUCKYAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29791/thumb/1681233790515-ea5417cc3842eeef09aa84987708f438.png?1681293667"
        },
        {
          "chainId": 56,
          "address": "0x9d3e14b607b2f569cfafe29af71e811d7e575cfe",
          "name": "FlokiBonk",
          "symbol": "FLOBO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20118/thumb/communityIcon_wz9dy5ga39x71.png?1636522288"
        },
        {
          "chainId": 56,
          "address": "0xb0458283033e5a3f7867f409477f53754b667dcc",
          "name": "Blurt",
          "symbol": "BLURT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12996/thumb/8SzwQc8j2KJa5zQFn3ArqGwN8arxoZj3EEz8h4AtHCdLzKWsjWiXAgTPKaNsZAkzyJZdMHrdemGtWAUeeQuaxEks6SKbYTWEoHj9gRv7t322LQ7xh1p.png?1604243870"
        },
        {
          "chainId": 56,
          "address": "0x29a5daf6e3fdf964def07706ea1abee7ec03d021",
          "name": "Trillioner",
          "symbol": "TLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30314/thumb/4_%286%29_%281%29.jpg?1683881956"
        },
        {
          "chainId": 56,
          "address": "0xbe3fd4d1e0d244ddd98686a28f67355efe223346",
          "name": "Monopoly Millionaire Control",
          "symbol": "MMC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26608/thumb/zdtbAbZJ_400x400.jpeg?1659019021"
        },
        {
          "chainId": 56,
          "address": "0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1",
          "name": "Cub Finance",
          "symbol": "CUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14743/thumb/cub.png?1618019618"
        },
        {
          "chainId": 56,
          "address": "0x9d173e6c594f479b4d47001f8e6a95a7adda42bc",
          "name": "CryptoZoon",
          "symbol": "ZOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17419/thumb/logo200_%2820%29.png?1627599450"
        },
        {
          "chainId": 56,
          "address": "0x965b85d4674f64422c4898c8f8083187f02b32c0",
          "name": "Filecoin Standard Full Hashrate",
          "symbol": "SFIL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg?1639706531"
        },
        {
          "chainId": 56,
          "address": "0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389",
          "name": "ACryptoSI",
          "symbol": "ACSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13307/thumb/acryptos2_32.png?1681110923"
        },
        {
          "chainId": 56,
          "address": "0x0c59d2dc24798ff6869de428aa931a862c4e9994",
          "name": "AppleSwap AI",
          "symbol": "AP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30817/thumb/logo_200.png?1687749622"
        },
        {
          "chainId": 56,
          "address": "0x49f2145d6366099e13b10fbf80646c0f377ee7f6",
          "name": "FC Porto",
          "symbol": "PORTO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20459/thumb/fcp.jpg?1637062333"
        },
        {
          "chainId": 56,
          "address": "0x2cfc48cdfea0678137854f010b5390c5144c0aa5",
          "name": "Scientix",
          "symbol": "SCIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18367/thumb/LBNawZoQ_400x400.jpg?1631666115"
        },
        {
          "chainId": 56,
          "address": "0x14aad57fb5f9a0c9ce136cf93521cbebe14ec2e6",
          "name": "Shiba Inu Empire",
          "symbol": "SHIBEMP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20201/thumb/shibemp_200_200.png?1636768787"
        },
        {
          "chainId": 56,
          "address": "0xfbef7220dfefd788a18ee634721a1c82a229f8c6",
          "name": "LeisurePay",
          "symbol": "LPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24146/thumb/lpy.png?1646559850"
        },
        {
          "chainId": 56,
          "address": "0xb4c20bb1c75300fa724ec3196b5d1c854a7d58a0",
          "name": "yTOFU",
          "symbol": "YTOFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17918/thumb/IEpQGhf.png?1629782029"
        },
        {
          "chainId": 56,
          "address": "0x972207a639cc1b374b893cc33fa251b55ceb7c07",
          "name": "Venus BETH",
          "symbol": "VBETH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13924/thumb/vbeth.png?1612851527"
        },
        {
          "chainId": 56,
          "address": "0xf224ade71c20f9823e34e0792f72437596b4e28c",
          "name": "ShibonkBSC",
          "symbol": "SHIBO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22215/thumb/shibonk-MAINLOGO-CG.png?1645602871"
        },
        {
          "chainId": 56,
          "address": "0xa2214039c2ccb9b86d351000ba2f126f45ce44a4",
          "name": "Alon Mars",
          "symbol": "ALONMARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29054/thumb/IMG_20230213_020850_600.jpg?1676336737"
        },
        {
          "chainId": 56,
          "address": "0x1562c99ad7179b7d1d862ff4e8bff6cc016a97ee",
          "name": "Bridge Network",
          "symbol": "BRDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25139/thumb/bridge-logo-blue.png?1650433261"
        },
        {
          "chainId": 56,
          "address": "0x9452d45d33490234b8c96f42342f1be28c0fe097",
          "name": "Swaperry",
          "symbol": "PERRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18005/thumb/Swaperry_token-2.png?1630145847"
        },
        {
          "chainId": 56,
          "address": "0x2a8557c1ca81573d33771d0f57a975c2388c5f38",
          "name": "MetaFishing",
          "symbol": "DGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28307/thumb/2P8unjSE_400x400.jpeg?1669339235"
        },
        {
          "chainId": 56,
          "address": "0x93c27727e72ec7510a06ea450366c1418c4ce547",
          "name": "Pirate x Pirate",
          "symbol": "PXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23881/thumb/AJLTxV30.png?1645601132"
        },
        {
          "chainId": 56,
          "address": "0x496cc0b4ee12aa2ac4c42e93067484e7ff50294b",
          "name": "Metastrike",
          "symbol": "MTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22892/thumb/weD5ZQkc_400x400.jpg?1642832800"
        },
        {
          "chainId": 56,
          "address": "0xbfff3571f9fd637ae7cfb63ac2112fd18264ce62",
          "name": "Tarmex",
          "symbol": "TARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28935/thumb/Tarmex_Logo.png?1675501814"
        },
        {
          "chainId": 56,
          "address": "0x9186359f82c3c0cc005a0b3563dc4ccd2627d82a",
          "name": "AntNetworX  OLD ",
          "symbol": "ANTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27628/thumb/photo_2023-07-17_19-38-50.jpg?1689846412"
        },
        {
          "chainId": 56,
          "address": "0x2326c7395d02a8c89a9d7a0b0c1cf159d49ce51c",
          "name": "BlockAura",
          "symbol": "TBAC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26081/thumb/20655.png?1655707151"
        },
        {
          "chainId": 56,
          "address": "0x1d8e589379cf74a276952b56856033ad0d489fb3",
          "name": "MilkAI",
          "symbol": "MILKAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29055/thumb/MilkAI.jpeg?1676337492"
        },
        {
          "chainId": 56,
          "address": "0x079f0f5f3ad15e01a5cd919564a8f52dde03431b",
          "name": "MegaToken",
          "symbol": "MEGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20671/thumb/undefined-Imgur-5.png?1637548941"
        },
        {
          "chainId": 56,
          "address": "0x62891201468a517eeec00fe72f33595a3f9047ee",
          "name": "Supreme Finance",
          "symbol": "HYPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24675/thumb/8130.png?1648547739"
        },
        {
          "chainId": 56,
          "address": "0xdd6c6c114db071efe0bab6051268227ce64c3ffe",
          "name": "GameFi Protocol",
          "symbol": "GFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18421/thumb/w2FyVpg.jpg?1631856660"
        },
        {
          "chainId": 56,
          "address": "0xd98560689c6e748dc37bc410b4d3096b1aa3d8c2",
          "name": "Defi For You",
          "symbol": "DFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14561/thumb/DFY-200x200.png?1617015032"
        },
        {
          "chainId": 56,
          "address": "0x8005d97e993668a528008d16338b42f9e976ed0f",
          "name": "EGold Project",
          "symbol": "EGOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30717/thumb/egold_32.png?1686558655"
        },
        {
          "chainId": 56,
          "address": "0x0e7beec376099429b85639eb3abe7cf22694ed49",
          "name": "Bunicorn",
          "symbol": "BUNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17366/thumb/buni.PNG?1627436421"
        },
        {
          "chainId": 56,
          "address": "0xbac1df744df160877cdc45e13d0394c06bc388ff",
          "name": "NFTmall",
          "symbol": "GEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471"
        },
        {
          "chainId": 56,
          "address": "0x549cc5df432cdbaebc8b9158a6bdfe1cbd0ba16d",
          "name": "Howl City",
          "symbol": "HWL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20344/thumb/hwl.png?1636942327"
        },
        {
          "chainId": 56,
          "address": "0x3b0e967ce7712ec68131a809db4f78ce9490e779",
          "name": "Souni",
          "symbol": "SON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24297/thumb/WcOQ_Ees_400x400.jpg?1647267814"
        },
        {
          "chainId": 56,
          "address": "0xb1ebdd56729940089ecc3ad0bbeeb12b6842ea6f",
          "name": "Valas Finance",
          "symbol": "VALAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24733/thumb/valas-finance.png?1648738556"
        },
        {
          "chainId": 56,
          "address": "0xc748673057861a797275cd8a068abb95a902e8de",
          "name": "Baby Doge Coin",
          "symbol": "BABYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16125/thumb/babydoge.jpg?1676303229"
        },
        {
          "chainId": 56,
          "address": "0xfcb8a4b1a0b645e08064e05b98e9cc6f48d2aa57",
          "name": "ZMINE",
          "symbol": "ZMN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2103/thumb/zmn.png?1547036420"
        },
        {
          "chainId": 56,
          "address": "0x572c9ab47977d7d909572f3b8bce076a858a8763",
          "name": "We2net",
          "symbol": "WE2NET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29846/thumb/logo200.png?1681780272"
        },
        {
          "chainId": 56,
          "address": "0x5d0e95c15ca50f13fb86938433269d03112409fe",
          "name": "Knight War Spirits",
          "symbol": "KWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18635/thumb/logo-200x200_game_kn.png?1632979979"
        },
        {
          "chainId": 56,
          "address": "0x2fc9e0f34d86f65b495de7ee3bb5cbeac7f92b04",
          "name": "SeamlessSwap",
          "symbol": "SEAMLESS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20836/thumb/h9aSYw1B_400x400.jpg?1637736753"
        },
        {
          "chainId": 56,
          "address": "0xb8167c0e58f4ca0ec7a6d967a8d138f05b3a981f",
          "name": "JEN COIN",
          "symbol": "JEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26912/thumb/200x200.png?1663998982"
        },
        {
          "chainId": 56,
          "address": "0x066aee69d93dee28b32a57febd1878a2d94f6b0c",
          "name": "GOLD8",
          "symbol": "GOLD8",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27481/thumb/gold8-200x200.png?1664246277"
        },
        {
          "chainId": 56,
          "address": "0x6ffcebb2f9360bc233cc0aa35d15b4999cd6af29",
          "name": "Winterdog",
          "symbol": "WDOG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27907/thumb/WD300x300.png?1666337555"
        },
        {
          "chainId": 56,
          "address": "0x7a983559e130723b70e45bd637773dbdfd3f71db",
          "name": "Diamond Boyz Coin",
          "symbol": "DBZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17308/thumb/IMG_0026.png?1627271288"
        },
        {
          "chainId": 56,
          "address": "0xb700597d8425ced17677bc68042d7d92764acf59",
          "name": "FaceDAO",
          "symbol": "FACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24309/thumb/1I7nII0c_400x400.jpg?1653526250"
        },
        {
          "chainId": 56,
          "address": "0x231cf6f78620e42fe00d0c5c3088b427f355d01c",
          "name": "MarketMove",
          "symbol": "MOVE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18148/thumb/61f2944781aa2fadbd51bade_circle.png?1643290037"
        },
        {
          "chainId": 56,
          "address": "0x426c1c971fb00caaf1883bd801323a8becb0c919",
          "name": "Alaska Gold Rush",
          "symbol": "CARAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29911/thumb/O5Z-nlcb_400x400.jpg?1682000321"
        },
        {
          "chainId": 56,
          "address": "0x1f36fb2d91d9951cf58ae4c1956c0b77e224f1e9",
          "name": "VCGamers",
          "symbol": "VCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png?1643187590"
        },
        {
          "chainId": 56,
          "address": "0x8626f099434d9a7e603b8f0273880209eabfc1c5",
          "name": "BerrySwap",
          "symbol": "BERRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14609/thumb/Berry.jpg?1617245229"
        },
        {
          "chainId": 56,
          "address": "0x317eb4ad9cfac6232f0046831322e895507bcbeb",
          "name": "Tidex",
          "symbol": "TDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3137/thumb/1.png?1656309141"
        },
        {
          "chainId": 56,
          "address": "0x78aae7e000bf6fc98a6b717d5ec8ef2bcd04f428",
          "name": "GemPad",
          "symbol": "GEMS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25319/thumb/KQLfrRw.png?1651217242"
        },
        {
          "chainId": 56,
          "address": "0x1c25222994531c4ac35e4d94bbf7552c9aa92e32",
          "name": "Wrapped PKT",
          "symbol": "WPKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19003/thumb/200x200wpkt-04.png?1634138697"
        },
        {
          "chainId": 56,
          "address": "0x4c0415a6e340eccebff58131799c6c4127cc39fa",
          "name": "Xdoge",
          "symbol": "XDOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19222/thumb/token3.png?1634709710"
        },
        {
          "chainId": 56,
          "address": "0xe33012187af219072dff81f54060febed2a91337",
          "name": "Hubin Network",
          "symbol": "HBN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28467/thumb/kBBc9jeM_400x400.jpg?1670925125"
        },
        {
          "chainId": 56,
          "address": "0xc1357d32bf23fd5fe3280681a36755b6f150442e",
          "name": "HotMoon",
          "symbol": "HOTMOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25206/thumb/hotmoon-coinGecko.png?1650770774"
        },
        {
          "chainId": 56,
          "address": "0xd0c4bc4520854bafe7bfd699089d648726ab349b",
          "name": "Cex Trade",
          "symbol": "CEXD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29635/thumb/cex-logo-200.png?1680160183"
        },
        {
          "chainId": 56,
          "address": "0x5a29c96fa93ffa8845fb7f8616a03aa85fcc11d6",
          "name": "FRANCE REV FINANCE",
          "symbol": "FRF",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/22224/thumb/LOGO200_FRF.png?1641218289"
        },
        {
          "chainId": 56,
          "address": "0x89e4818ed21f668d65f7851839d2dd8ce5d208b0",
          "name": "Monetas",
          "symbol": "MNTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29667/thumb/MNTG_ICON_%281%29.png?1680593763"
        },
        {
          "chainId": 56,
          "address": "0xb972c4027818223bb7b9399b3ca3ca58186e1590",
          "name": "Supe Infinity",
          "symbol": "SUPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21874/thumb/nF9bWyQe_400x400.jpg?1640209950"
        },
        {
          "chainId": 56,
          "address": "0xf97c30f0b31aee9b1ab087f8ccf5b14bf354d29f",
          "name": "Real Estate Token",
          "symbol": "R3T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29569/thumb/R3T.png?1679737424"
        },
        {
          "chainId": 56,
          "address": "0x569f4957176ffa0dff76c507604f6a66d4b9c578",
          "name": "Cryptostone",
          "symbol": "CPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26068/thumb/Cryptostone.png?1655539372"
        },
        {
          "chainId": 56,
          "address": "0xaafa10755b3b1dbf46e86d973c3f27f3671ed9db",
          "name": "HyperVerse",
          "symbol": "HVT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23102/thumb/1_03RIVsRDc8ZvCojuBZ-obg.png?1643185572"
        },
        {
          "chainId": 56,
          "address": "0x3051cfb958dcd408fba70256073adba943fdf552",
          "name": "TheForce Trade",
          "symbol": "FOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15490/thumb/force.PNG?1621040891"
        },
        {
          "chainId": 56,
          "address": "0xfdd2374be7ae7a71138b9f6b93d9ef034a49edb6",
          "name": "Virtual Reality Game World",
          "symbol": "VRGW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27395/thumb/IMG_20220921_091002_981.png?1663826038"
        },
        {
          "chainId": 56,
          "address": "0x8c22881c7a92db25d1666f276299dde1795bad00",
          "name": "PokePlay Token",
          "symbol": "PPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28398/thumb/PokePlay-LOGO_200x200.png?1670291045"
        },
        {
          "chainId": 56,
          "address": "0xe8ebcf4fd1faa9b77c0ec0b26e7cc32a251cd799",
          "name": "SweetToken",
          "symbol": "SWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27730/thumb/logo200.png?1665560462"
        },
        {
          "chainId": 56,
          "address": "0xdacc0417add48b63cbefb77efbe4a3801aad51ba",
          "name": "Bitzen",
          "symbol": "BZEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27311/thumb/bzen.png?1663311420"
        },
        {
          "chainId": 56,
          "address": "0xb534b21082e44a9c5865876f41f8dd348278fdf2",
          "name": "Homie Wars",
          "symbol": "HOMIECOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28699/thumb/logo-homie_wars.png?1673414893"
        },
        {
          "chainId": 56,
          "address": "0x11a38e06699b238d6d9a0c7a01f3ac63a07ad318",
          "name": "USDFI",
          "symbol": "USDFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31149/thumb/USDFI_200.png?1691031391"
        },
        {
          "chainId": 56,
          "address": "0xfa1ba18067ac6884fb26e329e60273488a247fc3",
          "name": "Scrooge  OLD ",
          "symbol": "SCROOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21510/thumb/top-hat-200x200.png?1668344782"
        },
        {
          "chainId": 56,
          "address": "0x1e553688199d99d063c0300a12395f7cfedb03e1",
          "name": "REDANCOIN",
          "symbol": "REDAN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8292/thumb/REDAN.png?1563944337"
        },
        {
          "chainId": 56,
          "address": "0x23e3981052d5280c658e5e18d814fa9582bfbc9e",
          "name": "Youclout",
          "symbol": "YCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18808/thumb/7W5vEIz-_400x400.jpg?1633480030"
        },
        {
          "chainId": 56,
          "address": "0x235d650fc6d9eb7d4bac77e128265295a0054304",
          "name": "KWAI",
          "symbol": "KWAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28599/thumb/KWAI_Logo.jpg?1672293890"
        },
        {
          "chainId": 56,
          "address": "0x74f08af7528ffb751e3a435ddd779b5c4565e684",
          "name": "King Finance",
          "symbol": "KING",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28978/thumb/logo200x200.png?1676105255"
        },
        {
          "chainId": 56,
          "address": "0xca3c1dc12b0dd0d65964abaa533106cf4f372c78",
          "name": "ChubbyAkita",
          "symbol": "CAKITA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15563/thumb/cakita.jpg?1621217438"
        },
        {
          "chainId": 56,
          "address": "0xdf9e1a85db4f985d5bb5644ad07d9d7ee5673b5e",
          "name": "MM72",
          "symbol": "MM72",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25564/thumb/mm72.png?1652521419"
        },
        {
          "chainId": 56,
          "address": "0x2b618835a1eefcbf41e33497451ca1f3aa62f2d8",
          "name": "Serey Coin",
          "symbol": "SRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16260/thumb/serey.png?1663139968"
        },
        {
          "chainId": 56,
          "address": "0x9fd87aefe02441b123c3c32466cd9db4c578618f",
          "name": "Thetan Arena",
          "symbol": "THG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18414/thumb/c4N4n8k-_400x400.png?1631831447"
        },
        {
          "chainId": 56,
          "address": "0x0aec1e4ce3cd3ccee64ff1a2ee47770fd2b0d8d3",
          "name": "Capybara BSC",
          "symbol": "CAPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29563/thumb/logo200.png?1679735973"
        },
        {
          "chainId": 56,
          "address": "0xd517ce9206c09dfaa7e7f40f98e59f54fb10e09f",
          "name": "Meta Merge Mana",
          "symbol": "MMM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30881/thumb/M3_1.png?1688461313"
        },
        {
          "chainId": 56,
          "address": "0x1a131f7b106d58f33eaf0fe5b47db2f2045e5732",
          "name": "DegenReborn",
          "symbol": "DEGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29696/thumb/logo.png?1680681985"
        },
        {
          "chainId": 56,
          "address": "0xf0dcf7ac48f8c745f2920d03dff83f879b80d438",
          "name": "Gami",
          "symbol": "GAMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24482/thumb/IMG_20220319_170534_629.jpg?1647822338"
        },
        {
          "chainId": 56,
          "address": "0xc0366a104b429f0806bfa98d0008daa9555b2bed",
          "name": "Safemars Protocol",
          "symbol": "SMARS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16143/thumb/83904828.png?1623118918"
        },
        {
          "chainId": 56,
          "address": "0xdfaabaa57dec10c049335bdaa2e949b4ce2ead30",
          "name": "Catbonk",
          "symbol": "CABO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20917/thumb/cabo.png?1637923769"
        },
        {
          "chainId": 56,
          "address": "0xebbaeff6217d22e7744394061d874015709b8141",
          "name": "Wam",
          "symbol": "WAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21576/thumb/KrJueEpI_400x400.jpg?1639529423"
        },
        {
          "chainId": 56,
          "address": "0xfa40d8fc324bcdd6bbae0e086de886c571c225d4",
          "name": "Wizardia",
          "symbol": "WZRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24434/thumb/Icon_%281%29.jpg?1648177628"
        },
        {
          "chainId": 56,
          "address": "0x5b65cd9feb54f1df3d0c60576003344079f8dc06",
          "name": "Uniwhale",
          "symbol": "UNW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29531/thumb/token-256x256.png?1680260871"
        },
        {
          "chainId": 56,
          "address": "0x238d02ee3f80fbf5e381f049616025c186889b68",
          "name": "Metars Genesis",
          "symbol": "MRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26625/thumb/PRmUU8O1_400x400.jpeg?1659322669"
        },
        {
          "chainId": 56,
          "address": "0xca0c1c12466a57b26850b05a0ba2fb39f9763a0c",
          "name": "Ezillion",
          "symbol": "EZI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25472/thumb/ezi.jpg?1652004435"
        },
        {
          "chainId": 56,
          "address": "0x80cd73badb406ea36b9a7cdeb8df06aefa7e12d9",
          "name": "SleepFuture",
          "symbol": "SLEEPEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png?1651916574"
        },
        {
          "chainId": 56,
          "address": "0x6397de0f9aedc0f7a8fa8b438dde883b9c201010",
          "name": "Sinverse",
          "symbol": "SIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19291/thumb/Sin_Verse_Logo_White_Text_White_Windows.png?1645276533"
        },
        {
          "chainId": 56,
          "address": "0xbaf928369eb10c71461cda6972f35eede6d2f5fd",
          "name": "TopDown Survival Shooter",
          "symbol": "SHOOTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29940/thumb/TD_Survival_Shooter_LOGO.png?1682229162"
        },
        {
          "chainId": 56,
          "address": "0x47c454ca6be2f6def6f32b638c80f91c9c3c5949",
          "name": "Games for a Living",
          "symbol": "GFAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29430/thumb/token.png?1688375038"
        },
        {
          "chainId": 56,
          "address": "0xb1ced2e320e3f4c8e3511b1dc59203303493f382",
          "name": "Moonlight",
          "symbol": "MOONLIGHT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15908/thumb/logo-200x200_%284%29.png?1622413557"
        },
        {
          "chainId": 56,
          "address": "0xb8e3bb633f7276cc17735d86154e0ad5ec9928c0",
          "name": "VelasPad",
          "symbol": "VLXPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18535/thumb/11654.png?1632297834"
        },
        {
          "chainId": 56,
          "address": "0x73e8dd9d52bd26a2134698e2aff964e121f4759b",
          "name": "GoFitterAI",
          "symbol": "FITAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29089/thumb/1676022551247-4393c71ca4b98e3e3e8484939623e2bf.png?1676523348"
        },
        {
          "chainId": 56,
          "address": "0xc864019047b864b6ab609a968ae2725dfaee808a",
          "name": "Biconomy Exchange Token",
          "symbol": "BIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18198/thumb/mGky0OOh_400x400.jpg?1630965301"
        },
        {
          "chainId": 56,
          "address": "0x8303a44c770c666b8a45eebab0879c1a880d39e7",
          "name": "Leonicorn Swap LEONS",
          "symbol": "LEONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29560/thumb/leons.png?1679725351"
        },
        {
          "chainId": 56,
          "address": "0x861f1e1397dad68289e8f6a09a2ebb567f1b895c",
          "name": "Menzy",
          "symbol": "MNZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26291/thumb/Menzy-logo.jpeg?1657160543"
        },
        {
          "chainId": 56,
          "address": "0x8ddeec6b677c7c552c9f3563b99e4ff90b862ebc",
          "name": "Block Ape Scissors",
          "symbol": "BAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17404/thumb/Logo_White_Face_%28200%29.png?1659093852"
        },
        {
          "chainId": 56,
          "address": "0x7791d80425cb542937fa499afe835d26ee7284fb",
          "name": "Rabbit2023",
          "symbol": "RABBIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28476/thumb/200.png?1671003986"
        },
        {
          "chainId": 56,
          "address": "0xee7e8c85956d32c64bafdcded3f43b3c39b1ce2f",
          "name": "WEB4 AI",
          "symbol": "WEB4",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29165/thumb/photo_2023-02-21_16.29.27.png?1677047100"
        },
        {
          "chainId": 56,
          "address": "0x3735f21175ff2292f3b05e105852e8ee90b36af9",
          "name": "DreamPad Capital",
          "symbol": "DREAMPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29801/thumb/JCmWx56h_400x400.png?1681349535"
        },
        {
          "chainId": 56,
          "address": "0xf78a2e1824638d09571172368bbe1d8d399893ab",
          "name": "BotopiaFinance",
          "symbol": "BTOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22129/thumb/GqSruKVo_400x400.jpg?1640872303"
        },
        {
          "chainId": 56,
          "address": "0xd306c124282880858a634e7396383ae58d37c79c",
          "name": "The Wasted Lands",
          "symbol": "WAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png?1647178350"
        },
        {
          "chainId": 56,
          "address": "0x1ec58fe5e681e35e490b5d4cbecdf42b29c1b063",
          "name": "JK Coin",
          "symbol": "JK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20392/thumb/14488.png?1636962392"
        },
        {
          "chainId": 56,
          "address": "0x11ac6af070fe1991a457c56fb85c577efe57f0e4",
          "name": "DragonKing",
          "symbol": "DRAGONKING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29794/thumb/IMG_20230411_222000_357.png?1681347000"
        },
        {
          "chainId": 56,
          "address": "0x4197c6ef3879a08cd51e5560da5064b773aa1d29",
          "name": "ACryptoS",
          "symbol": "ACS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13276/thumb/acryptos_32.png?1681110873"
        },
        {
          "chainId": 56,
          "address": "0x722cb8e411d40942c0f581b919ecce3e4d759602",
          "name": "Osean",
          "symbol": "OSEAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30968/thumb/oceana.png?1689588231"
        },
        {
          "chainId": 56,
          "address": "0xbb689057fe1c4bfc573a54c0679ae1a7a1982f26",
          "name": "Shelling",
          "symbol": "SHL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21502/thumb/shelling.png?1639373437"
        },
        {
          "chainId": 56,
          "address": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
          "name": "Wrapped BNB",
          "symbol": "WBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313"
        },
        {
          "chainId": 56,
          "address": "0xa7f552078dcc247c2684336020c03648500c6d9f",
          "name": "Ellipsis  OLD ",
          "symbol": "EPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14498/thumb/ellipsis-light.png?1651625258"
        },
        {
          "chainId": 56,
          "address": "0x9767c8e438aa18f550208e6d1fdf5f43541cc2c8",
          "name": "MangoMan Intelligent",
          "symbol": "MMIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26687/thumb/Mr._B.png?1659601403"
        },
        {
          "chainId": 56,
          "address": "0x0255af6c9f86f6b0543357bacefa262a2664f80f",
          "name": "Immutable",
          "symbol": "DARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17640/thumb/darav2.png?1662516483"
        },
        {
          "chainId": 56,
          "address": "0x79ca48c3f6bfb3ef284b4d4c39ca51286830f9ae",
          "name": "Polka Classic",
          "symbol": "DOTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22307/thumb/Polka-Classic.png?1641446596"
        },
        {
          "chainId": 56,
          "address": "0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f",
          "name": "Anime",
          "symbol": "ANI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13792/thumb/ani.png?1636333218"
        },
        {
          "chainId": 56,
          "address": "0x04260673729c5f2b9894a467736f3d85f8d34fc8",
          "name": "CryptoPlanes",
          "symbol": "CPAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19103/thumb/span.PNG?1634506128"
        },
        {
          "chainId": 56,
          "address": "0x2e8799f0a26d8a9f37a0b4747fa534f039c2d234",
          "name": "Birb",
          "symbol": "BIRB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26373/thumb/birb-logo.png?1671766772"
        },
        {
          "chainId": 56,
          "address": "0xae459484c895a335cec08058290d94551dbf5fbb",
          "name": "eFin Decentralized",
          "symbol": "WEFIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23117/thumb/We-Fin-Logo.png?1643266308"
        },
        {
          "chainId": 56,
          "address": "0x77fc65deda64f0cca9e3aea7b9d8521f4151882e",
          "name": "Bitindi Chain",
          "symbol": "BNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27811/thumb/160.png?1665906992"
        },
        {
          "chainId": 56,
          "address": "0xa3870fbbeb730ba99e4107051612af3465ca9f5e",
          "name": "Stable",
          "symbol": "STABLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31148/thumb/stable_200.png?1691031324"
        },
        {
          "chainId": 56,
          "address": "0x14b13e06f75e1f0fd51ca2e699589ef398e10f4c",
          "name": "IDM Coop",
          "symbol": "IDM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19665/thumb/8jVtkWu.png?1635736981"
        },
        {
          "chainId": 56,
          "address": "0xa531a733070a5ff4866d1327d82e403fa35290a6",
          "name": "Catvills Coin",
          "symbol": "CATVILLS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28400/thumb/JkwPmJ7.png?1670299668"
        },
        {
          "chainId": 56,
          "address": "0x330f4fe5ef44b4d0742fe8bed8ca5e29359870df",
          "name": "Jade Currency",
          "symbol": "JADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10072/thumb/photo_2021-05-31_15.02.34.jpeg?1622444576"
        },
        {
          "chainId": 56,
          "address": "0xdd1b6b259986571a85da82a84f461e1c212591c0",
          "name": "BlazeX",
          "symbol": "BLAZEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31194/thumb/200x200.png?1691146811"
        },
        {
          "chainId": 56,
          "address": "0x0938a5d325a8496c186cf122946e9dd22f8a625b",
          "name": "Digital Files",
          "symbol": "DIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29282/thumb/DIFI_logo.png?1683022800"
        },
        {
          "chainId": 56,
          "address": "0x41b2f7acc00035f9b1cec868b5054a6238c0a910",
          "name": "Kochi Ken",
          "symbol": "KOCHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29296/thumb/Kochi.jpeg?1677914650"
        },
        {
          "chainId": 56,
          "address": "0xd18dcd429c4c44b98242042cc35a3e03bfabdb08",
          "name": "Streakk Chain",
          "symbol": "STKC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30730/thumb/STREAKK_Logo_Round-1.png?1686646864"
        },
        {
          "chainId": 56,
          "address": "0xfc76ba9157ee5d079de8c1e969ee54096aaa6c9c",
          "name": "CRED COIN PAY",
          "symbol": "CRED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29453/thumb/cred.png?1679159115"
        },
        {
          "chainId": 56,
          "address": "0x2bc8c2ae9dad57948fa4168e56e177a29ae0c0b1",
          "name": "BattleForTEN",
          "symbol": "BFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28355/thumb/BFT_LOGO_200X200.png?1669774806"
        },
        {
          "chainId": 56,
          "address": "0x057aff3e314e1ca15bed75510df81a20098ce456",
          "name": "Potent Coin",
          "symbol": "PTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17651/thumb/Untitled-design_%281%29.png?1628751305"
        },
        {
          "chainId": 56,
          "address": "0xab8c98491816fede394582f7758a5effeb4368d7",
          "name": "TrumpCoin",
          "symbol": "DTC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30391/thumb/photo_2023-05-12_01-14-11.png?1684309247"
        },
        {
          "chainId": 56,
          "address": "0xf1b6460e7fa76e7afddfe20740c260b0ec6807a8",
          "name": "Speciex",
          "symbol": "SPEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29079/thumb/SPECIEX-LOGO.png?1676440418"
        },
        {
          "chainId": 56,
          "address": "0xd4c73fd18f732bc6ee9fb193d109b2eed815df80",
          "name": "Monsterra MAG",
          "symbol": "MAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27152/thumb/MAG.png?1662203851"
        },
        {
          "chainId": 56,
          "address": "0x1610bc33319e9398de5f57b33a5b184c806ad217",
          "name": "Venus DOT",
          "symbol": "VDOT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13919/thumb/venus_dot.png?1612844407"
        },
        {
          "chainId": 56,
          "address": "0x7268192a0e5882b21f13fc857cf78299d8e3d75b",
          "name": "IONZ",
          "symbol": "IONZ",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26113/thumb/Token-IONZ.png?1655898375"
        },
        {
          "chainId": 56,
          "address": "0x4c97c901b5147f8c1c7ce3c5cf3eb83b44f244fe",
          "name": "Mound",
          "symbol": "MND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17922/thumb/token-mnd.83a59964.png?1629790253"
        },
        {
          "chainId": 56,
          "address": "0x8ab7ef0eb25aad36dff0661f81fb81b144af5b87",
          "name": "Kumamon Finance",
          "symbol": "KUMAMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29391/thumb/kumamon200px.png?1678503702"
        },
        {
          "chainId": 56,
          "address": "0xae2df9f730c54400934c06a17462c41c08a06ed8",
          "name": "DogeBonk",
          "symbol": "DOBO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19153/thumb/dobo.png?1640080606"
        },
        {
          "chainId": 56,
          "address": "0x5f50411cde3eec27b0eac21691b4e500c69a5a2e",
          "name": "Singularity",
          "symbol": "SGLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22071/thumb/sgly_square.png?1640751285"
        },
        {
          "chainId": 56,
          "address": "0x256d1fce1b1221e8398f65f9b36033ce50b2d497",
          "name": "Alvey Chain",
          "symbol": "WALV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png?1661079637"
        },
        {
          "chainId": 56,
          "address": "0xaf96a19c2dd4a0f6b077d9481fcc8c9102faa141",
          "name": "Moonarch",
          "symbol": "MOONARCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15567/thumb/android-chrome-256x256.png?1621222240"
        },
        {
          "chainId": 56,
          "address": "0xf5bde7eb378661f04c841b22ba057326b0370153",
          "name": "Pink BNB",
          "symbol": "PNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29479/thumb/200px.png?1679130446"
        },
        {
          "chainId": 56,
          "address": "0xf0186490b18cb74619816cfc7feb51cdbe4ae7b9",
          "name": "Zasset zUSD",
          "symbol": "ZUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17664/thumb/zusd_200.png?1628812160"
        },
        {
          "chainId": 56,
          "address": "0x777994409c6b7e2393f6098a33a9cd8b7e9d0d28",
          "name": "Cryptotem",
          "symbol": "TOTEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21315/thumb/QEHj_fZJ_400x400.jpg?1638924445"
        },
        {
          "chainId": 56,
          "address": "0x55493e35e33fcf811571707ac5bf1dbcb658bafc",
          "name": "FAT CAT",
          "symbol": "FATCAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29095/thumb/IMG_9113.PNG?1678596869"
        },
        {
          "chainId": 56,
          "address": "0x2e2ea48c9412e0abb2d6acccec571c6b6411725a",
          "name": "LunaOne",
          "symbol": "XLN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28227/thumb/input-onlinepngtools.png?1668502565"
        },
        {
          "chainId": 56,
          "address": "0x1f1c90aeb2fd13ea972f0a71e35c0753848e3db0",
          "name": "Cheelee",
          "symbol": "CHEEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28573/thumb/CHEEL_%D1%82%D0%BE%D0%BD%D0%BA%D0%B0%D1%8F_%D0%BE%D0%B1%D0%B2%D0%BE%D0%B4%D0%BA%D0%B0_%283%29.png?1684141438"
        },
        {
          "chainId": 56,
          "address": "0x947fe50c2ae24d5ff56f0e4e7c9043eb6ee1abe7",
          "name": "ElvishMagic",
          "symbol": "EMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29198/thumb/IMG_20230222_112333_651_%281%29.png?1677305300"
        },
        {
          "chainId": 56,
          "address": "0x84cfc0427147026368c2aac4f502d98aac47eb48",
          "name": "Shanum",
          "symbol": "SHAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27559/thumb/shanlogo.png?1669369674"
        },
        {
          "chainId": 56,
          "address": "0x3fcca8648651e5b974dd6d3e50f61567779772a8",
          "name": "Moonpot",
          "symbol": "POTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17517/thumb/moonpot.PNG?1628067384"
        },
        {
          "chainId": 56,
          "address": "0xec3422ef92b2fb59e84c8b02ba73f1fe84ed8d71",
          "name": "Venus DOGE",
          "symbol": "VDOGE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15058/thumb/doge.f7fbdf1d.png?1619590113"
        },
        {
          "chainId": 56,
          "address": "0x77e5cce02139814e7eff377244cac8b802cddab8",
          "name": "ZeLoop Eco Reward",
          "symbol": "ERW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19781/thumb/4767_ZeLoop_Icon_PNG.png?1635842015"
        },
        {
          "chainId": 56,
          "address": "0x550d7984b7adfff88815e5528e12e322df6d3b9b",
          "name": "PandAI",
          "symbol": "PANDAI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29203/thumb/%C5%AFlll.jpeg?1677308621"
        },
        {
          "chainId": 56,
          "address": "0x4e6415a5727ea08aae4580057187923aec331227",
          "name": "Refinable",
          "symbol": "FINE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14925/thumb/profilepic_square.png?1621497134"
        },
        {
          "chainId": 56,
          "address": "0xbcdfd50ead6b6da1970464985fab894fb83d17c0",
          "name": "NFT Tone",
          "symbol": "TONE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15669/thumb/nft_tone.PNG?1621495741"
        },
        {
          "chainId": 56,
          "address": "0xfdff7a8eda6a3739132867f989be4bf84e803c15",
          "name": "New Year",
          "symbol": "NYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21839/thumb/nyt_200.png?1640101725"
        },
        {
          "chainId": 56,
          "address": "0x18e596bcefdd0ff9dc8c50d0b9d329ea770d5ef1",
          "name": "BTS Chain",
          "symbol": "BTSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19386/thumb/mDVn3lgM_400x400.jpg?1635147385"
        },
        {
          "chainId": 56,
          "address": "0x1e7e0efb87e609b87f12f1cea1dac48569da2328",
          "name": "Matic Launchpad",
          "symbol": "MATICPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24421/thumb/ILN63o4w_400x400.jpg?1647612315"
        },
        {
          "chainId": 56,
          "address": "0xc209831f7349d4e200d420326b3401899ab9ae68",
          "name": "Crypteriumcoin",
          "symbol": "CCOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28026/thumb/363216.png?1667292647"
        },
        {
          "chainId": 56,
          "address": "0x04df8c91fccfd703cd15047bf6c1ce76d335c6ce",
          "name": "Baby Lovely Inu",
          "symbol": "BLOVELY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23608/thumb/blovely.png?1644746419"
        },
        {
          "chainId": 56,
          "address": "0x8e984e03ab35795c60242c902ece2450242c90e9",
          "name": "Kampay",
          "symbol": "KAMPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8289/thumb/6130e86152bdb07e2848de00_200_4x.png?1630738336"
        },
        {
          "chainId": 56,
          "address": "0xadfaed188291ae479f458ee2d3f88fe7e8cc712f",
          "name": "Pepe CEO BSC",
          "symbol": "PEPECEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30678/thumb/IMG_20230529_212005_413.png?1686215108"
        },
        {
          "chainId": 56,
          "address": "0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf",
          "name": "ThreeFold",
          "symbol": "TFT",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/6704/thumb/black_logo_on_white_background_%282%29.jpg?1649742107"
        },
        {
          "chainId": 56,
          "address": "0x48378891d6e459ca9a56b88b406e8f4eab2e39bf",
          "name": "Furio",
          "symbol": "FUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26035/thumb/furio.png?1655448700"
        },
        {
          "chainId": 56,
          "address": "0xf3abd8caced73f4113206ec275cee965f6ed1d44",
          "name": "Radical Chess",
          "symbol": "CHESS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29355/thumb/radical-chess_-full__%281%29.png?1678263117"
        },
        {
          "chainId": 56,
          "address": "0x9aeb2e6dd8d55e14292acfcfc4077e33106e4144",
          "name": "Platform of meme coins",
          "symbol": "PAYU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30615/thumb/200px.png?1685769855"
        },
        {
          "chainId": 56,
          "address": "0xe615c5e7219f9801c3b75bc76e45a4dab3c38e51",
          "name": "Vemate",
          "symbol": "VMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27953/thumb/Vemate.jpeg?1666668775"
        },
        {
          "chainId": 56,
          "address": "0x7e2a35c746f2f7c240b664f1da4dd100141ae71f",
          "name": "aiRight",
          "symbol": "AIRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16428/thumb/alright.PNG?1623995586"
        },
        {
          "chainId": 56,
          "address": "0xa3abe68db1b8467b44715eb94542b20dc134f005",
          "name": "Fluffy Coin",
          "symbol": "FLUF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20656/thumb/fluf.png?1638775190"
        },
        {
          "chainId": 56,
          "address": "0x3b831d36ed418e893f42d46ff308c326c239429f",
          "name": "PancakeTools",
          "symbol": "TCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14620/thumb/sygnet-e1616750584571.png?1617268153"
        },
        {
          "chainId": 56,
          "address": "0x9d55f5a65c4e8a7563a668c12364ecc42c4481a6",
          "name": "Bitscrow",
          "symbol": "BTSCRW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28634/thumb/HFDZGM.jpg?1672823525"
        },
        {
          "chainId": 56,
          "address": "0xd911375e7e9582c70fc98b1c6c2fbaad86f457ef",
          "name": "Retawars GoldRose Token",
          "symbol": "GRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27648/thumb/GRT_200.png?1665025536"
        },
        {
          "chainId": 56,
          "address": "0x9589014f7a8547b89a6331eeee32b7fbd5852af9",
          "name": "Age Of Tanks",
          "symbol": "AOT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21757/thumb/ageoftanks_logo_metal_%281%29.png?1642749913"
        },
        {
          "chainId": 56,
          "address": "0xdd97ab35e3c0820215bc85a395e13671d84ccba2",
          "name": "AutoShark",
          "symbol": "JAWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16335/thumb/osP9madX_400x400.jpg?1623750298"
        },
        {
          "chainId": 56,
          "address": "0x39bff8613defd221b0410ed3d4e5c07512d55f2d",
          "name": "Cubiex Power",
          "symbol": "CBIX-P",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16183/thumb/Cubiex.jpg?1623218662"
        },
        {
          "chainId": 56,
          "address": "0x07b7ddf2257f633fed7dc97129edac9e519af172",
          "name": "Master CEO",
          "symbol": "MCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30467/thumb/MasterCEO_LOGO.png?1684665495"
        },
        {
          "chainId": 56,
          "address": "0xc0924edefb2c0c303de2d0c21bff07ab763163b5",
          "name": "Steam Exchange",
          "symbol": "STEAMX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16914/thumb/steamx.png?1632824252"
        },
        {
          "chainId": 56,
          "address": "0x08b87b1cfdba00dfb79d77cac1a5970ba6c9cde2",
          "name": "Metarix",
          "symbol": "MTRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27565/thumb/output-onlinepngtools_%287%29.png?1664520885"
        },
        {
          "chainId": 56,
          "address": "0x4841181ae4079072ebe83a29b718388a387169e3",
          "name": "Salmonation",
          "symbol": "SUI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24408/thumb/256x256.png?1675840942"
        },
        {
          "chainId": 56,
          "address": "0xceaebce0cd2b0075eaa13c972f60e7dc449cd2df",
          "name": "thxone",
          "symbol": "THX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29602/thumb/thx.png?1679993511"
        },
        {
          "chainId": 56,
          "address": "0xaf3287cae99c982586c07401c0d911bf7de6cd82",
          "name": "H2O Dao",
          "symbol": "H2O",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24588/thumb/M5-QvqZ8_400x400.jpg?1648226430"
        },
        {
          "chainId": 56,
          "address": "0xb27b68431c9a1819c8633ff75a2dd14f54799a21",
          "name": "Piratera",
          "symbol": "PIRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21979/thumb/PIRA_coin_copy.png?1640573411"
        },
        {
          "chainId": 56,
          "address": "0x8b6fa031c7d2e60fbfe4e663ec1b8f37df1ba483",
          "name": "CashCow",
          "symbol": "COW",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19388/thumb/cash_cow.png?1648265503"
        },
        {
          "chainId": 56,
          "address": "0x32d7da6a7cf25ed1b86e1b0ee9a62b0252d46b16",
          "name": "Ginza Network",
          "symbol": "GINZA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19906/thumb/GDZGB-Qw_400x400.png?1636101895"
        },
        {
          "chainId": 56,
          "address": "0x56156fb7860d7eb0b4b1a5356c5354b295194a45",
          "name": "Sportium",
          "symbol": "SPRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20981/thumb/sportium_logo_symbolmark_skyblue.png?1638163975"
        },
        {
          "chainId": 56,
          "address": "0xbdeae1ca48894a1759a8374d63925f21f2ee2639",
          "name": "Open Campus",
          "symbol": "EDU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29948/thumb/EDU_Logo.png?1682327357"
        },
        {
          "chainId": 56,
          "address": "0x213fd3c787b6c452f50fd91f14e12ddf04a7ab4a",
          "name": "ZodiacsV2",
          "symbol": "ZDCV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22589/thumb/LUl0lEMr_400x400.jpg?1642144242"
        },
        {
          "chainId": 56,
          "address": "0xaec23008b1098e39c0f8de90bf7431d185efe8b3",
          "name": "Crazy Bunny Equity",
          "symbol": "CBUNNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19675/thumb/500x500px.png?1635739677"
        },
        {
          "chainId": 56,
          "address": "0x416947e6fc78f158fd9b775fa846b72d768879c2",
          "name": "Ouro Governance Share",
          "symbol": "OGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21369/thumb/ogs.png?1639025935"
        },
        {
          "chainId": 56,
          "address": "0x965d3704de812f5e1e7eef1ac22fe92174258bd9",
          "name": "Metaxy",
          "symbol": "MXY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22842/thumb/261052729_115071754344881_6053416489823202921_n.jpg?1642746147"
        },
        {
          "chainId": 56,
          "address": "0x08b450e4a48c04cdf6db2bd4cf24057f7b9563ff",
          "name": "Quoll Finance",
          "symbol": "QUO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28078/thumb/logo_quoll_asset.png?1667394645"
        },
        {
          "chainId": 56,
          "address": "0xa79b1dea0d35d39003630ba3e10e5ba2d7318082",
          "name": "Spider Man",
          "symbol": "SPIDER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30819/thumb/Frame_2229.png?1687750411"
        },
        {
          "chainId": 56,
          "address": "0xa77346760341460b42c230ca6d21d4c8e743fa9c",
          "name": "MicroPets",
          "symbol": "PETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19175/thumb/pets.png?1644048894"
        },
        {
          "chainId": 56,
          "address": "0x34e942859469c9db9c22f4eaf866e2c2401bb795",
          "name": "CoinMooner",
          "symbol": "MOONER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19537/thumb/coinmooner.PNG?1635382243"
        },
        {
          "chainId": 56,
          "address": "0x9ee75952e3408ed7005225855aa1835d6d0023ca",
          "name": "Stamen Tellus Token",
          "symbol": "STT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21155/thumb/normal_bean_stt-200x200.png?1638413168"
        },
        {
          "chainId": 56,
          "address": "0x2921872530f53eb8e6fc388676b141ef41ee2d4e",
          "name": "Space Soldier",
          "symbol": "SOLDIER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15575/thumb/F63vqcy.png?1621235076"
        },
        {
          "chainId": 56,
          "address": "0x933bcd9a03d350f040d2fe7e36d60a9c73d42ef5",
          "name": "Snaps",
          "symbol": "SNPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30719/thumb/snaps.png?1686559255"
        },
        {
          "chainId": 56,
          "address": "0xdffdfd03a65a4f5a55f826c9f7a96579d7a1fd05",
          "name": "Musashi Finance",
          "symbol": "MUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17777/thumb/192092455_101594072164832_4485220528670171699_n.jpg?1629213298"
        },
        {
          "chainId": 56,
          "address": "0x491347561cec563ad7d91135f92dbdc700277505",
          "name": "BFX Finance",
          "symbol": "BFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30694/thumb/BFX.png?1686282879"
        },
        {
          "chainId": 56,
          "address": "0x16f9cc3c6f8d8006cfc0ee693cef9d76b0d44c36",
          "name": "Baby Bali",
          "symbol": "BB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20789/thumb/New-Era-of-Baby-Bali-200x200pxl.png?1637671831"
        },
        {
          "chainId": 56,
          "address": "0x366d71ab095735b7dae83ce2b82d5262ef655f10",
          "name": "Anypad",
          "symbol": "APAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18385/thumb/vxBgiYKj_400x400.png?1631746435"
        },
        {
          "chainId": 56,
          "address": "0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c",
          "name": "DEEPSPACE",
          "symbol": "DPS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17953/thumb/f1LFu897_400x400.jpg?1629898614"
        },
        {
          "chainId": 56,
          "address": "0xde2a66c92583332e8e1f0aeeb03deb749a3fd33a",
          "name": "Goblin",
          "symbol": "GOBLIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22726/thumb/goblin.png?1654329580"
        },
        {
          "chainId": 56,
          "address": "0x67745484b7e8b80ab0d2a7de12dc30345b2bcc56",
          "name": "Trackers Token",
          "symbol": "TRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29989/thumb/trackers_2002.png?1682414883"
        },
        {
          "chainId": 56,
          "address": "0x2d862c9fc46608d7ff83293ceb83330d6135be5e",
          "name": "FalconX",
          "symbol": "FALCX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19992/thumb/L55TBQhW_400x400.jpg?1636359801"
        },
        {
          "chainId": 56,
          "address": "0x2cd24aaf0aeabde7f830d6719eeb8eb3837712de",
          "name": "Two Monkey Juice Bar",
          "symbol": "TMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24585/thumb/Gm_8ewah_400x400.jpg?1648218167"
        },
        {
          "chainId": 56,
          "address": "0x730cb2ba0f654ddec32470d265555f26fe545eb8",
          "name": "ElonDoge DAO",
          "symbol": "EDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17323/thumb/edao_logo.png?1627305989"
        },
        {
          "chainId": 56,
          "address": "0x98afac3b663113d29dc2cd8c2d1d14793692f110",
          "name": "MVS Multiverse",
          "symbol": "MVS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22135/thumb/logo-200-white.png?1640913611"
        },
        {
          "chainId": 56,
          "address": "0x83f41c98d028842ccc8060b4ec7738df3eb9a2e6",
          "name": "Baby WOJ",
          "symbol": "BWJ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22948/thumb/WOJ.png?1643009571"
        },
        {
          "chainId": 56,
          "address": "0xe4c797d43631f4d660ec67b5cb0b78ef5c902532",
          "name": "Monsters Clan",
          "symbol": "MONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18382/thumb/Monstersclan_Icon_200X200.png?1631723283"
        },
        {
          "chainId": 56,
          "address": "0x4f745c0c7da552a348c384da1a5baeb28f2c607c",
          "name": "Xpansion Game",
          "symbol": "XPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21539/thumb/15997.png?1639447406"
        },
        {
          "chainId": 56,
          "address": "0xc732b6586a93b6b7cf5fed3470808bc74998224d",
          "name": "Kryptomon",
          "symbol": "KMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17886/thumb/kryptomon.png?1629713536"
        },
        {
          "chainId": 56,
          "address": "0x04073d16c6a08c27e8bbebe262ea4d1c6fa4c772",
          "name": "Metaverse Miner",
          "symbol": "META",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18408/thumb/d360aee2b1bd9fd9.png?1631795640"
        },
        {
          "chainId": 56,
          "address": "0x4ce5f6bf8e996ae54709c75865709aca5127dd54",
          "name": "Amateras",
          "symbol": "AMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21759/thumb/logo.png?1639988777"
        },
        {
          "chainId": 56,
          "address": "0x53b3338e3345758ae88b930e3d9759a95c5ce05c",
          "name": "GenshinFlokiInu",
          "symbol": "GFLOKI",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/20292/thumb/sUdVrvb2_400x400.jpg?1636814111"
        },
        {
          "chainId": 56,
          "address": "0x45d0f429790bec7ad4b68330b1051c7b8fe9d8af",
          "name": "Bunscake",
          "symbol": "BSCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19826/thumb/bunscake.PNG?1635978566"
        },
        {
          "chainId": 56,
          "address": "0x43bee29430a2dda4bc053dd5669a56efd6e0556a",
          "name": "DogeZilla Ai",
          "symbol": "DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29352/thumb/Dogezila_ai_logo.PNG?1678262261"
        },
        {
          "chainId": 56,
          "address": "0xb148df3c114b1233b206160a0f2a74999bb2fbf3",
          "name": "DeHealth",
          "symbol": "DHLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24839/thumb/DeHealth_FB.png?1658911320"
        },
        {
          "chainId": 56,
          "address": "0xad04ac36791d923deb082da4f91ab71675dd18fb",
          "name": "Meli Games",
          "symbol": "MELI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20211/thumb/meli_games.PNG?1636668571"
        },
        {
          "chainId": 56,
          "address": "0xb1528a7be5a96b77de5337988ba69029ca6e2c7a",
          "name": "Barking",
          "symbol": "BARK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15828/thumb/bark.png?1622025223"
        },
        {
          "chainId": 56,
          "address": "0x6759565574de509b7725abb4680020704b3f404e",
          "name": "VIP",
          "symbol": "VIP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20994/thumb/hVOVuxlC_400x400.jpg?1638172062"
        },
        {
          "chainId": 56,
          "address": "0x7cf551258d6871b72ee1bd1624588a6245bf48c4",
          "name": "ChitCAT",
          "symbol": "CHITCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30289/thumb/ChitCAT.png?1683865504"
        },
        {
          "chainId": 56,
          "address": "0x3a36dc12efaa14a3f692b94f97450594459661b6",
          "name": "Profit Blue",
          "symbol": "BLUE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30696/thumb/logo.png?1686538387"
        },
        {
          "chainId": 56,
          "address": "0x4f1960e29b2ca581a38c5c474e123f420f8092db",
          "name": "UBXS",
          "symbol": "UBXS",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/22558/thumb/logo_200x200.png?1642056380"
        },
        {
          "chainId": 56,
          "address": "0xa1a6d574728c0125b730cad5092b7d855f0bd920",
          "name": "Limocoin Swap",
          "symbol": "LMCSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20943/thumb/limo.png?1638027455"
        },
        {
          "chainId": 56,
          "address": "0xe3233fdb23f1c27ab37bd66a19a1f1762fcf5f3f",
          "name": "Elemon",
          "symbol": "ELMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20295/thumb/11663.png?1636815090"
        },
        {
          "chainId": 56,
          "address": "0xfdce54744801c9eb88d8445673ad267f8d43a9ee",
          "name": "SOLARIX",
          "symbol": "SOLARIX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26757/thumb/237712644422d81b00a920ad9f9255e.png?1660032647"
        },
        {
          "chainId": 56,
          "address": "0x18acf236eb40c0d4824fb8f2582ebbecd325ef6a",
          "name": "Oikos",
          "symbol": "OKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11486/thumb/B49FnNM.png?1625754985"
        },
        {
          "chainId": 56,
          "address": "0x5b0dfe077b16479715c9838eb644892008abbfe6",
          "name": "Baby Bitcoin",
          "symbol": "BBTC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16997/thumb/logo_p_200.png?1626054508"
        },
        {
          "chainId": 56,
          "address": "0x697bd938e7e572e787ecd7bc74a31f1814c21264",
          "name": "Digital Financial Exchange",
          "symbol": "DIFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23241/thumb/difx.png?1647167550"
        },
        {
          "chainId": 56,
          "address": "0x469acf8e1f29c1b5db99394582464fad45a1fc6f",
          "name": "Himo World",
          "symbol": "HIMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23000/thumb/16726.png?1643090812"
        },
        {
          "chainId": 56,
          "address": "0xf606bd19b1e61574ed625d9ea96c841d4e247a32",
          "name": "Guardian GUARD",
          "symbol": "GUARD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17995/thumb/LS_wolfDen_logo.0025_Light_200x200.png?1665110310"
        },
        {
          "chainId": 56,
          "address": "0x08ba0619b1e7a582e0bce5bbe9843322c954c340",
          "name": "Binamon",
          "symbol": "BMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16346/thumb/bmon.png?1633937084"
        },
        {
          "chainId": 56,
          "address": "0x1e4ffa373d94c95717fb83ec026b2e0e2f443bb0",
          "name": "Green Climate World",
          "symbol": "WGC",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/17776/thumb/Green_Climate.png?1629463942"
        },
        {
          "chainId": 56,
          "address": "0x339c72829ab7dd45c3c52f965e7abe358dd8761e",
          "name": "Wanaka Farm",
          "symbol": "WANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18020/thumb/wanaka.PNG?1630282979"
        },
        {
          "chainId": 56,
          "address": "0x522d0f9f3eff479a5b256bb1c1108f47b8e1a153",
          "name": "IguVerse IGUP",
          "symbol": "IGUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28434/thumb/IGUP_500x500.png?1670566898"
        },
        {
          "chainId": 56,
          "address": "0x29a75ec2d2b8a57fdc45094dc51fefd147c908d8",
          "name": "Turex",
          "symbol": "TUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15539/thumb/turex-logo.png?1621155664"
        },
        {
          "chainId": 56,
          "address": "0x026db614f070cb4c7e421da22df84ea1021236eb",
          "name": "Rage On Wheels",
          "symbol": "ROW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29905/thumb/row200.png?1681985945"
        },
        {
          "chainId": 56,
          "address": "0x5546600f77eda1dcf2e8817ef4d617382e7f71f5",
          "name": "Sonar",
          "symbol": "PING",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16463/thumb/sonar_logo-200.png?1651140004"
        },
        {
          "chainId": 56,
          "address": "0x0e4b5ea0259eb3d66e6fcb7cc8785817f8490a53",
          "name": "SokuSwap",
          "symbol": "SOKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png?1653547690"
        },
        {
          "chainId": 56,
          "address": "0xed00fc7d48b57b81fe65d1ce71c0985e4cf442cb",
          "name": "Chirpley",
          "symbol": "CHRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27243/thumb/Chirpley-Logo_200x200.png?1662882824"
        },
        {
          "chainId": 56,
          "address": "0x563ca064e41f3b5d80adeecfe49ab375fd7afbef",
          "name": "Rare Ball Potion",
          "symbol": "RBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25824/thumb/6295aea8b46cd60001d5ac44_RBS160.png?1654063768"
        },
        {
          "chainId": 56,
          "address": "0x40f75ed09c7bc89bf596ce0ff6fb2ff8d02ac019",
          "name": "Bitpaid",
          "symbol": "BTP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24867/thumb/32F4D530-6587-4498-B9E3-CC99B6A73357.png?1649166657"
        },
        {
          "chainId": 56,
          "address": "0x0f8754b36a767c5579178bd8a04d2fcd9d530b70",
          "name": "Yield Generating Enreach",
          "symbol": "YGNRCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28840/thumb/ygNRCH_%281%29.png?1674796954"
        },
        {
          "chainId": 56,
          "address": "0x4c403b1879aa6a79ba9c599a393ccc5d9fd2e788",
          "name": "Artificial Intelligence",
          "symbol": "AI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20632/thumb/200x200.png?1637333095"
        },
        {
          "chainId": 56,
          "address": "0x1b219aca875f8c74c33cff9ff98f3a9b62fcbff5",
          "name": "Fins",
          "symbol": "FINS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18568/thumb/fins_200x200.png?1632448775"
        },
        {
          "chainId": 56,
          "address": "0x5e7f472b9481c80101b22d0ba4ef4253aa61dabc",
          "name": "Hero Blaze  Three Kingdoms",
          "symbol": "MUDOL2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26432/thumb/iShot_2022-07-16_09.59.28.png?1657936904"
        },
        {
          "chainId": 56,
          "address": "0x1c796c140de269e255372ea687ef7644bab87935",
          "name": "Demole",
          "symbol": "DMLG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19195/thumb/dmlg.png?1634638943"
        },
        {
          "chainId": 56,
          "address": "0x1cfd6813a59d7b90c41dd5990ed99c3bf2eb8f55",
          "name": "The Corgi of PolkaBridge",
          "symbol": "CORGIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16069/thumb/corbi.PNG?1622766692"
        },
        {
          "chainId": 56,
          "address": "0xf3dbdf718667874e19ef368721a10409345fc218",
          "name": "MoveCash",
          "symbol": "MCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25610/thumb/qnmFw6Qw_400x400.jpeg?1652845231"
        },
        {
          "chainId": 56,
          "address": "0x31e5e2b990cc9f03540b488fdc78d3806826f161",
          "name": "MetaMecha",
          "symbol": "MM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31034/thumb/mm_icon_200-200.png?1689841332"
        },
        {
          "chainId": 56,
          "address": "0xb28a7f8f5328faffdd862985177583c2bb71e016",
          "name": "NftyPlay",
          "symbol": "POLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17572/thumb/polkaplay_logo_transparent_200.png?1628519230"
        },
        {
          "chainId": 56,
          "address": "0x36f84ee7f720312443c02389a08185e3ecf0ebed",
          "name": "Meta Launcher",
          "symbol": "MTLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29331/thumb/logo_circle.png?1678172488"
        },
        {
          "chainId": 56,
          "address": "0x11fe7a37f2923566caa0de7d37c868631c695205",
          "name": "Aurora Dimension",
          "symbol": "ADTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21198/thumb/aurora.png?1643525166"
        },
        {
          "chainId": 56,
          "address": "0x7c56d81ecb5e1d287a1e22b89b01348f07be3541",
          "name": "1Move Token",
          "symbol": "1MT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27347/thumb/1MT.png?1663654594"
        },
        {
          "chainId": 56,
          "address": "0xfa134985a4d9d10dbf2d7dcf811055aa25d0807c",
          "name": "CMC Coin",
          "symbol": "CMCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21494/thumb/cmc.png?1639365775"
        },
        {
          "chainId": 56,
          "address": "0x9073b858a7cdf121e6bf8d1367e200e5d0cc0188",
          "name": "Moonions",
          "symbol": "MOONION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27454/thumb/200.png?1664090487"
        },
        {
          "chainId": 56,
          "address": "0xbf0cf158e84ebacca1b7746e794d507073e5adfe",
          "name": "Blockchain Euro Project",
          "symbol": "BEPR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18743/thumb/BEPR-1-e1633173706568.png?1633315667"
        },
        {
          "chainId": 56,
          "address": "0x87e04a05499cb8d352c2e367870d4cf0ead460f0",
          "name": "FLOSHIDO INU",
          "symbol": "FLOSHIDO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29004/thumb/photo_2023-02-08_23-51-29.jpg?1675913281"
        },
        {
          "chainId": 56,
          "address": "0x156df0dd6300c73ac692d805720967cf4464776e",
          "name": "AstroSpaces io",
          "symbol": "SPACES",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22394/thumb/logo.jpg?1676288048"
        },
        {
          "chainId": 56,
          "address": "0xe3894cb9e92ca78524fb6a30ff072fa5e533c162",
          "name": "The Everlasting Parachain",
          "symbol": "ELP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16230/thumb/y24nMGj.png?1624274301"
        },
        {
          "chainId": 56,
          "address": "0x55f96c7005d7c684a65ee653b07b5fe1507c56ab",
          "name": "Wojak Finance",
          "symbol": "WOJ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18823/thumb/200x200.png?1660620693"
        },
        {
          "chainId": 56,
          "address": "0xde1a0f6c7078c5da0a6236eeb04261f4699905c5",
          "name": "Soccer Crypto",
          "symbol": "SOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28339/thumb/wYXOL79.png?1669684256"
        },
        {
          "chainId": 56,
          "address": "0xfaaa87943bfca6d97434be3d26c589647fea4375",
          "name": "ACEToken",
          "symbol": "ACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23990/thumb/ace.png?1645949619"
        },
        {
          "chainId": 56,
          "address": "0x6ae7e3bcb49a405061c6c5e18122aed853be26ba",
          "name": "NFTFundArt",
          "symbol": "NFA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20340/thumb/200x200_%2839%29.png?1636941345"
        },
        {
          "chainId": 56,
          "address": "0xb922aa024e71a25077d78b593bd6f11f2f412e72",
          "name": "RecoveryDAO",
          "symbol": "REC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29378/thumb/847604A0-DA56-434C-9215-E320CAD098C5.jpeg?1678352368"
        },
        {
          "chainId": 56,
          "address": "0xe11f1d5eee6be945bee3fa20dbf46febbc9f4a19",
          "name": "NUSA",
          "symbol": "NUSA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27967/thumb/Asset_1_2x.png?1666769269"
        },
        {
          "chainId": 56,
          "address": "0xaf027427dc6d31a3e7e162a710a5fe27e63e275f",
          "name": "LinkDao",
          "symbol": "LKD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26446/thumb/linkdao200x200.png?1658110843"
        },
        {
          "chainId": 56,
          "address": "0x42907d9f7e3d4291c801bbd1f601066eb1dfa956",
          "name": "Bless Global Credit",
          "symbol": "BLEC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28977/thumb/blec_logo.png?1675751169"
        },
        {
          "chainId": 56,
          "address": "0xba08da6b46e3dd153dd8b66a6e4cfd37a6359559",
          "name": "CUSTODIY",
          "symbol": "CTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26702/thumb/IMG_20220804_011032_777.jpg?1659662673"
        },
        {
          "chainId": 56,
          "address": "0x6249428345819cac8b8c7f1f68771073cb689ab1",
          "name": "BNB Bank",
          "symbol": "BBK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22510/thumb/Final-Logo_1.png?1641965441"
        },
        {
          "chainId": 56,
          "address": "0x768ed2e8b05e3c1fd8361f1ba769750e108d7af4",
          "name": "Virtual Trader",
          "symbol": "VTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28738/thumb/PvKkGuRiRdK9hHzU37Tw_3FPB3ZpbuiVHg3rn.png?1673781523"
        },
        {
          "chainId": 56,
          "address": "0xe350b08079f9523b24029b838184f177baf961ff",
          "name": "Helena Financial  OLD ",
          "symbol": "HELENA",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/28364/thumb/helenav2.png?1670815154"
        },
        {
          "chainId": 56,
          "address": "0xaf7c3e578621aabab184c706bad94ffb1a2e0179",
          "name": "Dao Space",
          "symbol": "DAOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30066/thumb/daospacelogo200.png?1683008832"
        },
        {
          "chainId": 56,
          "address": "0xd347deffbb2e750c752b2d4aa5c26fd57ab90d64",
          "name": "VeriBlock",
          "symbol": "VBK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8075/thumb/photo_2021-02-03_11.54.55.jpeg?1612324612"
        },
        {
          "chainId": 56,
          "address": "0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3",
          "name": "French Connection Finance",
          "symbol": "FCF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19089/thumb/transparent_background.png?1665646769"
        },
        {
          "chainId": 56,
          "address": "0x64f36701138f0e85cc10c34ea535fdbadcb54147",
          "name": "Anon Inu",
          "symbol": "AINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17151/thumb/LOGO-AINU-COIN-GECKO.png?1649142760"
        },
        {
          "chainId": 56,
          "address": "0x8cd0d76c0ad377378ab6ce878a7be686223497ee",
          "name": "Hydraverse",
          "symbol": "HDV",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/24114/thumb/HDV_token.png?1646379605"
        },
        {
          "chainId": 56,
          "address": "0x0bc89aa98ad94e6798ec822d0814d934ccd0c0ce",
          "name": "Battle Hero",
          "symbol": "BATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19231/thumb/5n04AmM4_400x400.jpg?1634767796"
        },
        {
          "chainId": 56,
          "address": "0xc34326533e8606e742c7e3775f59994a3ed9fb2f",
          "name": "bYSL",
          "symbol": "BYSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30588/thumb/bYSL_Light_Mode.png?1685456766"
        },
        {
          "chainId": 56,
          "address": "0x078efa21a70337834788c3e6f0a99275f71393f0",
          "name": "Kinect Finance",
          "symbol": "KNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28680/thumb/nK_ijQXW_400x400.jpg?1673233858"
        },
        {
          "chainId": 56,
          "address": "0x7ba3cd00229c1becc95a4b056ff15c123dcb456d",
          "name": "SpaceVikings",
          "symbol": "SVT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15820/thumb/pf-46468a65--TRANS.png?1621984077"
        },
        {
          "chainId": 56,
          "address": "0x92a42db88ed0f02c71d439e55962ca7cab0168b5",
          "name": "TRDGtoken",
          "symbol": "TRDG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14371/thumb/200x200.png?1675163127"
        },
        {
          "chainId": 56,
          "address": "0x795d38678d5a3f7ad80dded5e27644e9c0352904",
          "name": "upShib",
          "symbol": "UPSHIB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15533/thumb/upshib.png?1621153135"
        },
        {
          "chainId": 56,
          "address": "0xdb29192fc2b487bb5185e155752328d4f249743c",
          "name": "Ultra NFT",
          "symbol": "UNFT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15506/thumb/VkZQUF7q_400x400.jpg?1621096185"
        },
        {
          "chainId": 56,
          "address": "0x891e4554227385c5c740f9b483e935e3cbc29f01",
          "name": "Robust",
          "symbol": "RBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17137/thumb/CG-200x.png?1626438233"
        },
        {
          "chainId": 56,
          "address": "0x7b8779e01d117ec7e220f8299a6f93672e8eae23",
          "name": "IMOV",
          "symbol": "IMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26558/thumb/IMOV.png?1658796898"
        },
        {
          "chainId": 56,
          "address": "0x8d1427a32f0a4c4bf052252e68e7ff1b2ba80c01",
          "name": "RIMAUNANGIS",
          "symbol": "RXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27598/thumb/Logo_rimaunangis_200x200_%283%29.png?1666607725"
        },
        {
          "chainId": 56,
          "address": "0x56513627e7cea535b9b5a18da7643a4b21999994",
          "name": "Fathom",
          "symbol": "FATHOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29848/thumb/Fathom_Logo%28200x200%29.png?1681781717"
        },
        {
          "chainId": 56,
          "address": "0x0a6e6d2f58d22e267fdc9bfb295f0d43985febb4",
          "name": "HelpSeed",
          "symbol": "HELPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23858/thumb/helpslogo.png?1645594947"
        },
        {
          "chainId": 56,
          "address": "0xef00278d7eadf3b2c05267a2f185e468ad7eab7d",
          "name": "PepePAD",
          "symbol": "PEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30321/thumb/logo200.png?1684115693"
        },
        {
          "chainId": 56,
          "address": "0xc22e8114818a918260662375450e19ac73d32852",
          "name": "KittyCake",
          "symbol": "KCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17319/thumb/logo_-_2021-07-26T173233.519.png?1627291990"
        },
        {
          "chainId": 56,
          "address": "0x0557a288a93ed0df218785f2787dac1cd077f8f3",
          "name": "Make DeFi Better",
          "symbol": "MDB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27062/thumb/20039.png?1661670436"
        },
        {
          "chainId": 56,
          "address": "0x432b4f994760ea0c5f48baab6217e82a2b7f2c55",
          "name": "FirstHare",
          "symbol": "FIRSTHARE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22788/thumb/17453.png?1642580538"
        },
        {
          "chainId": 56,
          "address": "0x564f184692952adf6a9512a13dc49caf45e76b12",
          "name": "Sheesh",
          "symbol": "SHEESH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15483/thumb/sheesh.png?1638093700"
        },
        {
          "chainId": 56,
          "address": "0x2ee8ca014fdab5f5d0436c866937d32ef97373b0",
          "name": "Crimson Network",
          "symbol": "CRIMSON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29211/thumb/26-FFDE23-A9-B4-43-A6-8-A4-F-5-B6242-A8-EAC5.jpg?1677397019"
        },
        {
          "chainId": 56,
          "address": "0x2f0c6e147974bfbf7da557b88643d74c324053a2",
          "name": "CatCoin Token",
          "symbol": "CATS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/23569/thumb/17318.png?1653115443"
        },
        {
          "chainId": 56,
          "address": "0xab3bcb0e39b505de2a3545ce721e117de75d1e1d",
          "name": "Strelka AI",
          "symbol": "STRELKAAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29208/thumb/Strelka-logo.png?1677390546"
        },
        {
          "chainId": 56,
          "address": "0x41f831c60c7051cffa756ab5f9fee81a670ecde0",
          "name": "Baby Lambo Inu",
          "symbol": "BLINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23252/thumb/BabyLamboInuLogo.png?1643359369"
        },
        {
          "chainId": 56,
          "address": "0x1eaffd6b9ef0f45d663f3fbf402226c98609600e",
          "name": "Storepay",
          "symbol": "SPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28601/thumb/spc.png?1672380880"
        },
        {
          "chainId": 56,
          "address": "0xe5e0ebb9982ccb9c6e46efee69cbe02ddeb6fcdd",
          "name": "BeatGen NFT",
          "symbol": "BGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29814/thumb/Beatgen_Logo_200x200.png?1681434755"
        },
        {
          "chainId": 56,
          "address": "0x8ea2f890cb86dfb0e376137451c6fd982afefc15",
          "name": "AutoCrypto",
          "symbol": "AU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19157/thumb/mrineaq.png?1643187340"
        },
        {
          "chainId": 56,
          "address": "0x037d70234eea7d05e8cd6796d89bc37a2ac45de9",
          "name": "MaoRabbit",
          "symbol": "MAORABBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28375/thumb/MAORabbit.png?1669963919"
        },
        {
          "chainId": 56,
          "address": "0x0f76142d83ddef359753cca33647cc4dcee1c6d1",
          "name": "EarnX V2",
          "symbol": "EARNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24234/thumb/earnx.PNG?1646990052"
        },
        {
          "chainId": 56,
          "address": "0x6d23970ce32cb0f1929bece7c56d71319e1b4f01",
          "name": "MFET",
          "symbol": "MFET",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25555/thumb/200x200.png?1652421384"
        },
        {
          "chainId": 56,
          "address": "0x00ee89f7f21b60b72dd5d4070a4310f796c38c32",
          "name": "TradeFlow",
          "symbol": "TFLOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27843/thumb/photo_2022-10-17_12-17-44.jpg?1666061785"
        },
        {
          "chainId": 56,
          "address": "0x59c49b54d4425c7ff1e49f40958a14d48cc87c26",
          "name": "Tap Fantasy MC",
          "symbol": "TFMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26483/thumb/Tap-Fantasy-MC-Logo.png?1658279327"
        },
        {
          "chainId": 56,
          "address": "0xdf677713a2c661ecd0b2bd4d7485170aa8c1eceb",
          "name": "Metacraft",
          "symbol": "MCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22332/thumb/F2iEsigu_400x400.jpg?1641515143"
        },
        {
          "chainId": 56,
          "address": "0x237ace23ab2c36a004aa5e4fb134fe5c1cedf06c",
          "name": "CEO",
          "symbol": "CEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29210/thumb/IMG-20230225-WA0002.png?1677394544"
        },
        {
          "chainId": 56,
          "address": "0x8800e9902879ac7fb9823086d1749030c9a5eb91",
          "name": "Basketball Legends",
          "symbol": "BBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22131/thumb/200x200.png?1640872674"
        },
        {
          "chainId": 56,
          "address": "0x45d51acc587574536cb292500d35dd3060796c63",
          "name": "SincroniX",
          "symbol": "SNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29637/thumb/logo.png?1680160781"
        },
        {
          "chainId": 56,
          "address": "0x6b471d5ab9f3d92a600e7d49a0b135bf6d4c6a5b",
          "name": "iAssets",
          "symbol": "ASSET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25475/thumb/asset.png?1652006656"
        },
        {
          "chainId": 56,
          "address": "0x94b69263fca20119ae817b6f783fc0f13b02ad50",
          "name": "League of Ancients",
          "symbol": "LOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21442/thumb/loa.png?1639139331"
        },
        {
          "chainId": 56,
          "address": "0x5c46c55a699a6359e451b2c99344138420c87261",
          "name": "iBG Finance  BSC ",
          "symbol": "IBG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18026/thumb/IBG.png?1630291248"
        },
        {
          "chainId": 56,
          "address": "0xdde3ed0bb77c1cafabf8b38f9a1e81edddc7ddc9",
          "name": "Metaverse VR",
          "symbol": "MEVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23437/thumb/cmc3.png?1656560704"
        },
        {
          "chainId": 56,
          "address": "0x14e419554163a0bb4a3bce3ef3d4c73dde5bd4c9",
          "name": "Utility Meta Token",
          "symbol": "UMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30481/thumb/photo_2023-03-05_08-18-12.jpg?1684709410"
        },
        {
          "chainId": 56,
          "address": "0x938223700d3a22fb36fe5406193c607f8192d3e8",
          "name": "Minions Finance",
          "symbol": "MINIONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30677/thumb/logo_200.png?1686214945"
        },
        {
          "chainId": 56,
          "address": "0x2c8d970d7c8c878db422c4bcd7d2542104ecfa2c",
          "name": "EZZY Game",
          "symbol": "GEZY",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/30906/thumb/200.png?1688967604"
        },
        {
          "chainId": 56,
          "address": "0x5ca09af27b8a4f1d636380909087536bc7e2d94d",
          "name": "Alitas",
          "symbol": "ALT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16330/thumb/ALT.png?1623748504"
        },
        {
          "chainId": 56,
          "address": "0x6d830e1d0179b4fe656683c9d14c05f8cd95db75",
          "name": "Griffin Art Ecosystem",
          "symbol": "GART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28309/thumb/hItUGzio_400x400.jpeg?1669340857"
        },
        {
          "chainId": 56,
          "address": "0x5b1baec64af6dc54e6e04349315919129a6d3c23",
          "name": "DNAxCAT",
          "symbol": "DXCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17603/thumb/dxct.png?1634545609"
        },
        {
          "chainId": 56,
          "address": "0x1162e2efce13f99ed259ffc24d99108aaa0ce935",
          "name": "CluCoin",
          "symbol": "CLU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15685/thumb/CoinGecko.png?1623337199"
        },
        {
          "chainId": 56,
          "address": "0x347dc3036defc7ac9b28f4d83c65502827693414",
          "name": "Meme AI",
          "symbol": "MEMEAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29481/thumb/memeai_200.png?1679131471"
        },
        {
          "chainId": 56,
          "address": "0xee7b7c840de85ad277cdddaef63b3b29672a3c58",
          "name": "Scouthub",
          "symbol": "HUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26282/thumb/hubtoken-2-200x200.png?1657076232"
        },
        {
          "chainId": 56,
          "address": "0xb81408a1cc2f4be70a6a3178d351ca95a77c5a06",
          "name": "Xodex",
          "symbol": "XODEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25551/thumb/200_%281%29.png?1677658063"
        },
        {
          "chainId": 56,
          "address": "0xa532cfaa916c465a094daf29fea07a13e41e5b36",
          "name": "Hibiki Finance",
          "symbol": "HIBIKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17936/thumb/logo_%281%29.png?1629860624"
        },
        {
          "chainId": 56,
          "address": "0x07e551e31a793e20dc18494ff6b03095a8f8ee36",
          "name": "Qmall",
          "symbol": "QMALL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg?1644891636"
        },
        {
          "chainId": 56,
          "address": "0x81aa4d3ad2a86e7a2cd44630c36ccaacd6b30568",
          "name": "BLUEART TOKEN",
          "symbol": "BLA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28040/thumb/200x200_blueart_logo.png?1667465129"
        },
        {
          "chainId": 56,
          "address": "0x5b180109332b079c44447f52a8adad5b1cd9dcfd",
          "name": "Litherium",
          "symbol": "LITH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20080/thumb/rHeGfsvH_400x400.jpg?1636453426"
        },
        {
          "chainId": 56,
          "address": "0xcd76bc49a69bcdc5222d81c18d4a04dc8a387297",
          "name": "Vision Metaverse",
          "symbol": "VS",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/24542/thumb/W0jkQVDm_400x400.png?1648100235"
        },
        {
          "chainId": 56,
          "address": "0x937dcca207128af363470a711d0c2b1cf76c49b1",
          "name": "skyup",
          "symbol": "SU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27465/thumb/%EC%8A%A4%EC%B9%B4%EC%9D%B4%EC%97%85_%EB%A1%9C%EA%B3%A0.jpg?1664177973"
        },
        {
          "chainId": 56,
          "address": "0x3125d4767d4e85434f7873f5c5dd59a86e850d44",
          "name": "Green Foundation",
          "symbol": "TRIPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31031/thumb/Logo_200x200.png?1689839572"
        },
        {
          "chainId": 56,
          "address": "0x2f053e33bd590830858161d42c67e9e8a9390019",
          "name": "Vention",
          "symbol": "VENTION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17497/thumb/logo-black-200.png?1627973980"
        },
        {
          "chainId": 56,
          "address": "0xf8f95f20cd1fb6f1fa6f7776c359214220f49aa6",
          "name": "Gangs Rabbit",
          "symbol": "RABBIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29705/thumb/200x200.jpg?1680765309"
        },
        {
          "chainId": 56,
          "address": "0xef032f652fce3a0effce3796a68eb978b465a336",
          "name": "Moochii",
          "symbol": "MOOCHII",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15444/thumb/QUVbHkC.png?1622196413"
        },
        {
          "chainId": 56,
          "address": "0x84b1270f5a858dc25db8cc005fff27fbf6c8afd2",
          "name": "Yeon",
          "symbol": "YEON",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27874/thumb/download_%283%29.jpeg?1666175588"
        },
        {
          "chainId": 56,
          "address": "0x2d69c55baecefc6ec815239da0a985747b50db6e",
          "name": "Tutti Frutti",
          "symbol": "TFF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14445/thumb/VOUyAE3u_400x400.png?1616117977"
        },
        {
          "chainId": 56,
          "address": "0x940580db429da7fa8662d66f7a4d312443f09f52",
          "name": "Solalgo",
          "symbol": "SLGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30152/thumb/solalgo_200x200.png?1683517107"
        },
        {
          "chainId": 56,
          "address": "0x5d33e26336c445c71f206dd18b64ce11c2eee3f0",
          "name": "NFTStyle",
          "symbol": "NFTSTYLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19928/thumb/nftstyle.PNG?1636323387"
        },
        {
          "chainId": 56,
          "address": "0xab725d0a10c3f24725c89f5765ae5794a26c1336",
          "name": "Inery",
          "symbol": "INR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26215/thumb/R9hQxqQK_400x400.jpg?1656577686"
        },
        {
          "chainId": 56,
          "address": "0x7859b01bbf675d67da8cd128a50d155cd881b576",
          "name": "Mars Ecosystem",
          "symbol": "XMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16349/thumb/MARS.png?1623795527"
        },
        {
          "chainId": 56,
          "address": "0x41912648442e1800d90f5e76b4c3a45eba1fab49",
          "name": "JerryInu",
          "symbol": "JERRYINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30705/thumb/IMG_20230604_033819_839.png?1686546728"
        },
        {
          "chainId": 56,
          "address": "0x746b725a05d08a5829d0b4898abc79dee3928ea9",
          "name": "Arcstar",
          "symbol": "ARCSTAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30974/thumb/Arc.png?1689307796"
        },
        {
          "chainId": 56,
          "address": "0x8eca5c1b51a801a822912167153041ed0b92a397",
          "name": "Fame Reward Plus",
          "symbol": "FRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27675/thumb/fameRewardPlus-icon.png?1665211110"
        },
        {
          "chainId": 56,
          "address": "0xba5eab68a7203c9ff72e07b708991f07f55ef40e",
          "name": "KODI",
          "symbol": "KODI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18794/thumb/ahAWxPp.png?1633430523"
        },
        {
          "chainId": 56,
          "address": "0x2fd2799e83a723b19026a979899dfb70bbf6bf6b",
          "name": "Jaiho Crypto",
          "symbol": "JAIHO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20311/thumb/jaiho.PNG?1636842000"
        },
        {
          "chainId": 56,
          "address": "0x3916984fa787d89b648ccd8d60b5ff07e0e8e4f4",
          "name": "Pube Finance",
          "symbol": "PUBE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15425/thumb/2618620f.jpg?1622440947"
        },
        {
          "chainId": 56,
          "address": "0x5d07edaba2151a3c6802b28636ecc23082398edb",
          "name": "Zuna",
          "symbol": "ZUNA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19997/thumb/ZUNA_logo_for_CMC.png?1666262979"
        },
        {
          "chainId": 56,
          "address": "0xfe8bf5b8f5e4eb5f9bc2be16303f7dab8cf56aa8",
          "name": "BIBI",
          "symbol": "BIBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30242/thumb/bibilogo.jpg?1683690982"
        },
        {
          "chainId": 56,
          "address": "0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0",
          "name": "Unidef",
          "symbol": "U",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26691/thumb/Profile_Picture_-_Final.png?1690439966"
        },
        {
          "chainId": 56,
          "address": "0xb020805e0bc7f0e353d1343d67a239f417d57bbf",
          "name": "Krypton DAO",
          "symbol": "KRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25467/thumb/krd.png?1651915442"
        },
        {
          "chainId": 56,
          "address": "0x48ed9372169ef0bf2b901bbe45e52b6a6b8f1ecc",
          "name": "Crazy Bunny",
          "symbol": "CRAZYBUNNY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29706/thumb/IMG_20230404_214539_862.png?1680765393"
        },
        {
          "chainId": 56,
          "address": "0x9bad6c75b5a4e72df8147cc89d068cc848648e59",
          "name": "LYO Credit",
          "symbol": "LYO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25093/thumb/imgonline-com-ua-Resize-lFBg5rEIdAB.png?1650270325"
        },
        {
          "chainId": 56,
          "address": "0xbf37f781473f3b50e82c668352984865eac9853f",
          "name": "The Crypto You",
          "symbol": "MILK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21308/thumb/milk200.png?1638886288"
        },
        {
          "chainId": 56,
          "address": "0x5392ff4a9bd006dc272c1855af6640e17cc5ec0b",
          "name": "SafeLaunch",
          "symbol": "SFEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16938/thumb/sfex.png?1640336533"
        },
        {
          "chainId": 56,
          "address": "0x3a599e584075065eaaac768d75eaef85c2f2ff64",
          "name": "Frutti Dino",
          "symbol": "FDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27626/thumb/FDT_color_symbol_200.png?1668587251"
        },
        {
          "chainId": 56,
          "address": "0xaf2f53cc6cc0384aba52275b0f715851fb5aff94",
          "name": "Mobipad",
          "symbol": "MBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26082/thumb/20182.png?1655708024"
        },
        {
          "chainId": 56,
          "address": "0x76ef2a25b1ea6eb5dc4d079ae82c767d55b0a99e",
          "name": "ShibaLite",
          "symbol": "SHIBLITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19830/thumb/shibalite.PNG?1635980650"
        },
        {
          "chainId": 56,
          "address": "0x86c86ffdc0482d8dd918fc657c3fc51c4a1e3e5c",
          "name": "Dog Boss",
          "symbol": "DOGBOSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24934/thumb/278095103_108041131873840_7864245311638218164_n.jpg?1649412689"
        },
        {
          "chainId": 56,
          "address": "0xe0b0c16038845bed3fcf70304d3e167df81ce225",
          "name": "CrossSwap",
          "symbol": "CSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18002/thumb/cross.png?1630078540"
        },
        {
          "chainId": 56,
          "address": "0x264387ad73d19408e34b5d5e13a93174a35cea33",
          "name": "Rare FND",
          "symbol": "FND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26690/thumb/fnd.png?1674896811"
        },
        {
          "chainId": 56,
          "address": "0xea51801b8f5b88543ddad3d1727400c15b209d8f",
          "name": "Inuko Finance",
          "symbol": "INUKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27821/thumb/inuko-logo-200x200.png?1666263695"
        },
        {
          "chainId": 56,
          "address": "0xae4e9243755a6dcf1809f6ee6809635fa2e4fd0b",
          "name": "Cashback",
          "symbol": "CBK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30729/thumb/Cashback_Token_Logo.png?1686635836"
        },
        {
          "chainId": 56,
          "address": "0xe916ead212ccbd0df36d0328891300adc9096021",
          "name": "Christmas Shiba",
          "symbol": "XSHIB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28534/thumb/Qf4TU5kH_400x400.png?1671504897"
        },
        {
          "chainId": 56,
          "address": "0x6ef238e9e8cd2a96740897761c18894fc086b9d0",
          "name": "Mytheria",
          "symbol": "MYRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20470/thumb/mytheria.PNG?1637099647"
        },
        {
          "chainId": 56,
          "address": "0xd96e43fb44be20e9e9a5872ce1904ebaa9975ead",
          "name": "Caw CEO",
          "symbol": "CAWCEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30125/thumb/200x.png?1683260024"
        },
        {
          "chainId": 56,
          "address": "0xb2e841894b1c3d638948517f6234c6e06d3b8e1c",
          "name": "Zone of Avoidance",
          "symbol": "ZOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25202/thumb/ZoA-CMC.png?1650954699"
        },
        {
          "chainId": 56,
          "address": "0x4c8a3a1025ab87ef184cb7f0691a5a371fe783a6",
          "name": "Rake in",
          "symbol": "RAKE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29456/thumb/rakein2.png?1678962839"
        },
        {
          "chainId": 56,
          "address": "0xede1f9cdb98b4ca6a804de268b0aca18dce192e8",
          "name": "HeroPark",
          "symbol": "HP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21951/thumb/FA_Hero_Park_Logo_Blue.png?1640323382"
        },
        {
          "chainId": 56,
          "address": "0xfe11f9e474ce9bdb4efd15f0038634c70586fed0",
          "name": "BOJI",
          "symbol": "BOJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25290/thumb/bojiv2-2.png?1674305905"
        },
        {
          "chainId": 56,
          "address": "0xee11db54b66e4dbb99d497573106615bb6ac03ab",
          "name": "Crypto Puffs",
          "symbol": "PUFFS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17672/thumb/puff.PNG?1628829844"
        },
        {
          "chainId": 56,
          "address": "0x890cc7d14948478c98a6cd7f511e1f7f7f99f397",
          "name": "StaySAFU",
          "symbol": "SAFU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17781/thumb/staysafu.PNG?1629247764"
        },
        {
          "chainId": 56,
          "address": "0x8c18ffd66d943c9b0ad3dc40e2d64638f1e6e1ab",
          "name": "Herity Network",
          "symbol": "HER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22724/thumb/Herity-logo.png?1642487662"
        },
        {
          "chainId": 56,
          "address": "0x9cae753b661142ae766374cefa5dc800d80446ac",
          "name": "Safehaven DeFi",
          "symbol": "HAVEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17453/thumb/haven.png?1636339617"
        },
        {
          "chainId": 56,
          "address": "0xabc66bfd5fba9c5985c01805413d0beedd5d4267",
          "name": "Floki CEO Coin",
          "symbol": "FCC",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/29414/thumb/LOGO.png?1678599330"
        },
        {
          "chainId": 56,
          "address": "0x4954e0062e0a7668a2fe3df924cd20e6440a7b77",
          "name": "ByteNext",
          "symbol": "BNU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16000/thumb/logo.png?1641777102"
        },
        {
          "chainId": 56,
          "address": "0x59f6b2435cd1421f409907ad2d9f811849ca555f",
          "name": "Tank Battle",
          "symbol": "TBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22917/thumb/15695.png?1643000287"
        },
        {
          "chainId": 56,
          "address": "0xd41c4805a9a3128f9f7a7074da25965371ba50d5",
          "name": "Coinscope",
          "symbol": "COINSCOPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20649/thumb/IpSQZCFv_400x400.jpg?1637527440"
        },
        {
          "chainId": 56,
          "address": "0x9036b7ff80d87e4d4894d7765ef065fb98282a4c",
          "name": "OxyMetaToken",
          "symbol": "OMT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27562/thumb/200X200_OMT.png?1664519936"
        },
        {
          "chainId": 56,
          "address": "0xe550a593d09fbc8dcd557b5c88cea6946a8b404a",
          "name": " Doge",
          "symbol": "TDOGE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15959/thumb/photo_2021-06-01_11-30-59.png?1622518356"
        },
        {
          "chainId": 56,
          "address": "0x136bfcdec5db31b565052451ad806014ab9f2b38",
          "name": "Candylad",
          "symbol": "CANDYLAD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20938/thumb/8Q1tiqi0_400x400.jpg?1638022978"
        },
        {
          "chainId": 56,
          "address": "0xb626213cb1d52caa1ed71e2a0e62c0113ed8d642",
          "name": "HUGHUG",
          "symbol": "HGHG",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20172/thumb/512hug.png?1636603036"
        },
        {
          "chainId": 56,
          "address": "0xd02d45df2d9e8ee28a15d199689aefb1b4a74043",
          "name": "ROVI Protocol",
          "symbol": "ROVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28936/thumb/rovi-coin.png?1675503123"
        },
        {
          "chainId": 56,
          "address": "0x9a257c90fa239fba07771ef7da2d554d148c2e89",
          "name": "MEDIA EYE",
          "symbol": "EYE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20643/thumb/eye.png?1656052464"
        },
        {
          "chainId": 56,
          "address": "0x57ac045f3553882e0e1e4cb44faffdc1bdfee249",
          "name": "Compound Meta",
          "symbol": "COMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28686/thumb/logo_%283%29.png?1673358054"
        },
        {
          "chainId": 56,
          "address": "0x419264d79b92b8de3c710ab0cd3406cd11990e02",
          "name": "Blockscape",
          "symbol": "BLC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29914/thumb/BLC_logo.jpg?1682049146"
        },
        {
          "chainId": 56,
          "address": "0xf1b602fc211e3e2976ef44e4017b764a778197e0",
          "name": "MTG Token",
          "symbol": "MTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28167/thumb/MTG.png?1673959597"
        },
        {
          "chainId": 56,
          "address": "0x988f7c894e4001eeb7b570cde80dffe21cf7b6b9",
          "name": "Lucro",
          "symbol": "LCR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27335/thumb/LCR_logo_200x200-1.png?1663483321"
        },
        {
          "chainId": 56,
          "address": "0xc7b7844494c516b840a7a4575ff3e60ff0f056a9",
          "name": "Mech Master",
          "symbol": "MECH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19403/thumb/mech_master.PNG?1635198336"
        },
        {
          "chainId": 56,
          "address": "0x0328a69b363a16f66810b23cb0b8d32abadb203d",
          "name": "Kanaloa Network",
          "symbol": "KANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17981/thumb/koa.PNG?1630023378"
        },
        {
          "chainId": 56,
          "address": "0x9b3a01f8b4abd2e2a74597b21b7c269abf4e9f41",
          "name": "Altbase",
          "symbol": "ALTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19550/thumb/615635338527975b0314223a_Altbase_app_icon.png?1635401149"
        },
        {
          "chainId": 56,
          "address": "0x56083560594e314b5cdd1680ec6a493bb851bbd8",
          "name": "Transhuman Coin",
          "symbol": "THC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20328/thumb/kdEastmD_400x400.jpg?1636900381"
        },
        {
          "chainId": 56,
          "address": "0x00f80a8f39bb4d04a3038c497e3642bf1b0a304e",
          "name": "Bountie Hunter",
          "symbol": "BOUNTIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24559/thumb/BountieHunter_LogoDesign_TransparentBackground.png?1648186808"
        },
        {
          "chainId": 56,
          "address": "0xa0b9bb05da11e3b19ffd64554400f59d4a378515",
          "name": "Hashtagger",
          "symbol": "MOOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21139/thumb/Hashtagger_Logo_No_text_200_200.png?1638384345"
        },
        {
          "chainId": 56,
          "address": "0x06278743169ec1d910c0a8bb5a6d3b522a425dd1",
          "name": "Crypto Real Estate",
          "symbol": "CRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29387/thumb/cre-logo-200.png?1678423956"
        },
        {
          "chainId": 56,
          "address": "0xb0461d7e8212d311b842a58e9989ede849ac6816",
          "name": "OceanLand",
          "symbol": "OLAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24841/thumb/jP1nV5sb_400x400.jpg?1649077799"
        },
        {
          "chainId": 56,
          "address": "0x9f8a75436e7e808f3fb348182e0ea42d2dd467cd",
          "name": "S Wallet Protocol",
          "symbol": "SWP",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/22843/thumb/P6jfqnuo.png?1643194948"
        },
        {
          "chainId": 56,
          "address": "0x68590a47578e5060a29fd99654f4556dbfa05d10",
          "name": "Secured MoonRat",
          "symbol": "SMRAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15241/thumb/SMRAT_200x200.png?1620199864"
        },
        {
          "chainId": 56,
          "address": "0xb2ea51baa12c461327d12a2069d47b30e680b69d",
          "name": "Space Misfits",
          "symbol": "SMCW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24394/thumb/6htFQIdk_400x400.jpg?1647521132"
        },
        {
          "chainId": 56,
          "address": "0x57d2a45653b329fac354b04cead92c4db71cf09f",
          "name": "IBS",
          "symbol": "IBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26003/thumb/a.jpg?1655277968"
        },
        {
          "chainId": 56,
          "address": "0x66e1ecb238b2976fcbd1aeef0e9800b4f03c09f3",
          "name": "Layer Network",
          "symbol": "LAYER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29920/thumb/Layer_Network_Logo.png?1682051447"
        },
        {
          "chainId": 56,
          "address": "0xeac9873291ddaca754ea5642114151f3035c67a2",
          "name": "Decubate",
          "symbol": "DCB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16909/thumb/Logo-Decubate_200x200.png?1625595024"
        },
        {
          "chainId": 56,
          "address": "0x14e0980675ada43085c6c69c3cd207a03e43549b",
          "name": "Figure DAO",
          "symbol": "FDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29342/thumb/LOGO.png?1678186506"
        },
        {
          "chainId": 56,
          "address": "0xc7ad2ce38f208eed77a368613c62062fce88f125",
          "name": "Aerdrop",
          "symbol": "AER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15528/thumb/BsoQCUVu_400x400.jpg?1621129095"
        },
        {
          "chainId": 56,
          "address": "0x3944ac66b9b9b40a6474022d6962b6caa001b5e3",
          "name": "Elpis Battle",
          "symbol": "EBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20642/thumb/B%E1%BA%A3n_sao_c%E1%BB%A7a_token_EBA.png?1637547442"
        },
        {
          "chainId": 56,
          "address": "0x6ec7ad5a76dd866f07ddf293d4f5bc89c8bd2e09",
          "name": "Nole Inu",
          "symbol": "N0LE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29913/thumb/200x200_%282%29_%281%29.jpg?1682048996"
        },
        {
          "chainId": 56,
          "address": "0x28b9aed756de31b6b362aa0f23211d13093ebb79",
          "name": "LunaGens",
          "symbol": "LUNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26592/thumb/4-FAECD18-620-B-421-E-A27-E-70-D58-DDCE11-B.png?1658972100"
        },
        {
          "chainId": 56,
          "address": "0x6679eb24f59dfe111864aec72b443d1da666b360",
          "name": "Ariva",
          "symbol": "ARV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18103/thumb/logo-200.png?1630479340"
        },
        {
          "chainId": 56,
          "address": "0x2efdff1e566202f82e774bb7add18c56cbb9427d",
          "name": "ZakumiFi",
          "symbol": "ZAFI",
          "decimals": 17,
          "logoURI": "https://assets.coingecko.com/coins/images/28138/thumb/Logo-Zakumi.png?1667786381"
        },
        {
          "chainId": 56,
          "address": "0xf5d8015d625be6f59b8073c8189bd51ba28792e1",
          "name": "JulSwap",
          "symbol": "JULD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13839/thumb/Logo-D-Unicorn.png?1622435397"
        },
        {
          "chainId": 56,
          "address": "0x34e4a7454cae15990850166a8771cb8408b62a26",
          "name": "Felix",
          "symbol": "FLX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23492/thumb/Felix.jpeg?1644224052"
        },
        {
          "chainId": 56,
          "address": "0xc75aa1fa199eac5adabc832ea4522cff6dfd521a",
          "name": "HedgePay",
          "symbol": "HPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27725/thumb/200x200CG.png?1665547140"
        },
        {
          "chainId": 56,
          "address": "0xa29b6f4e762874846c081e20ed1142ff83faafef",
          "name": "MooMonster",
          "symbol": "MOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21177/thumb/moo.png?1638495997"
        },
        {
          "chainId": 56,
          "address": "0x33aa7797ac6cb8b4652b68e33e5add8ad1218a8d",
          "name": "Aquanee",
          "symbol": "AQDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25771/thumb/aqdc.png?1653624068"
        },
        {
          "chainId": 56,
          "address": "0xb8b932d41d6be935ce1666aaf41f056093f9faee",
          "name": "Trickle",
          "symbol": "H2O",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20830/thumb/gFiD-RNL_400x400.jpg?1637726434"
        },
        {
          "chainId": 56,
          "address": "0x84afb95ca5589674e02d227bdd6da7e7dcf31a3e",
          "name": "Perpetuum Coin",
          "symbol": "PRP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19605/thumb/LOGO_PRP_200x200.png?1641311277"
        },
        {
          "chainId": 56,
          "address": "0x21adb1c644663069e83059ac3f9d9ca1133d29e4",
          "name": "Eggplant Finance",
          "symbol": "EGGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15481/thumb/eggp.png?1621010694"
        },
        {
          "chainId": 56,
          "address": "0x201bc9f242f74c47bbd898a5dc99cdcd81a21943",
          "name": "IguVerse IGU",
          "symbol": "IGU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29112/thumb/IGU_500x500.png?1676692453"
        },
        {
          "chainId": 56,
          "address": "0xb897d0a0f68800f8be7d69ffdd1c24b69f57bf3e",
          "name": "Electra Protocol",
          "symbol": "XEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13589/thumb/Apple-iPhone-Icon-Retina.png?1609939024"
        },
        {
          "chainId": 56,
          "address": "0xb0e1fc65c1a741b4662b813eb787d369b8614af1",
          "name": "Impossible Finance",
          "symbol": "IF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15009/thumb/if.PNG?1619415244"
        },
        {
          "chainId": 56,
          "address": "0x07663837218a003e66310a01596af4bf4e44623d",
          "name": "rUSD",
          "symbol": "RUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16184/thumb/rUSD-Logo-200.png?1623231145"
        },
        {
          "chainId": 56,
          "address": "0xe8670901e86818745b28c8b30b17986958fce8cc",
          "name": "Citadel one",
          "symbol": "XCT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17852/thumb/logo200on200.png?1648487794"
        },
        {
          "chainId": 56,
          "address": "0xcae4f3977c084ab12b73a920e670e1665b3fa7d5",
          "name": "Synergy Diamonds",
          "symbol": "DIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28961/thumb/866C1AC4-3F58-4E4A-9EAC-FAA5DC7B04FA.png?1675831354"
        },
        {
          "chainId": 56,
          "address": "0x9f7b0f336f7fc969b4713615d6c42af86bb7c903",
          "name": "Small Fish Cookie",
          "symbol": "SFC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21190/thumb/15536.png?1638515883"
        },
        {
          "chainId": 56,
          "address": "0x3da4f9e86deef7c50a8b167493f26e894edcd7d5",
          "name": "TriipMiles",
          "symbol": "TIIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12185/thumb/fOf8ZxL.png?1597961299"
        },
        {
          "chainId": 56,
          "address": "0x0fe178b9a471b3698cb6fcb4625df9a756a2c55c",
          "name": "Strip Finance",
          "symbol": "STRIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20289/thumb/strip_finance.PNG?1636813662"
        },
        {
          "chainId": 56,
          "address": "0xb3a6381070b1a15169dea646166ec0699fdaea79",
          "name": "CyberDragon Gold",
          "symbol": "GOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18010/thumb/htvlAXaQ3yqLeOn.png?1642395633"
        },
        {
          "chainId": 56,
          "address": "0x23c5d1164662758b3799103effe19cc064d897d6",
          "name": "Aura Network  OLD ",
          "symbol": "AURA",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/30364/thumb/aura.jpeg?1684247915"
        },
        {
          "chainId": 56,
          "address": "0xf20c8afd1391c41a4185bb7c2d038de7097ba1b9",
          "name": "LittleGhosts Ectoplasm",
          "symbol": "ECTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21325/thumb/PDjB5PI.png?1638945800"
        },
        {
          "chainId": 56,
          "address": "0x3d0e93bfcb8fb46331ea8c98b6ab8c575ab424c3",
          "name": "Smash Cash",
          "symbol": "SMASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21286/thumb/30537055.png?1638865813"
        },
        {
          "chainId": 56,
          "address": "0xa62a8a65013f789367be37e5c0afc83445f77cc2",
          "name": "Nugencoin",
          "symbol": "NUGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28403/thumb/Nugen-coin-logo-200px_%282%29.png?1670301144"
        },
        {
          "chainId": 56,
          "address": "0xfd42728b76772a82ccad527e298dd15a55f4ddd6",
          "name": "KarenCoin",
          "symbol": "KAREN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16191/thumb/karen.PNG?1623274611"
        },
        {
          "chainId": 56,
          "address": "0xa155464b1566cdddf9782205602651b8b871b3d5",
          "name": "DaoVerse",
          "symbol": "DVRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26762/thumb/DV-200x200-logo.png?1660730975"
        },
        {
          "chainId": 56,
          "address": "0xfbb4f2f342c6daab63ab85b0226716c4d1e26f36",
          "name": "Coinracer",
          "symbol": "CRACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19529/thumb/13376.png?1635372064"
        },
        {
          "chainId": 56,
          "address": "0x86fac768241b4133d131edccbd47f143a4fa9d32",
          "name": "WrappedARC",
          "symbol": "WARC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29860/thumb/WARC-COIN-PNG-200x200.png?1681871097"
        },
        {
          "chainId": 56,
          "address": "0x08f725d2809fda409bc23493f3615a4c85a22d7d",
          "name": "TrustNFT",
          "symbol": "TRUSTNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20478/thumb/logo-3_%281%29.png?1637114682"
        },
        {
          "chainId": 56,
          "address": "0xef869b840ab248c2c90f9d8a7c7a6c9298cab888",
          "name": "Rottolabs",
          "symbol": "ROTTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29201/thumb/ROTTO.png?1689843865"
        },
        {
          "chainId": 56,
          "address": "0xe7498f332c35a346b486f3f6a68f05934e92a228",
          "name": "NevaCoin",
          "symbol": "NEVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1421/thumb/Neva_logo_600_600.png?1678677631"
        },
        {
          "chainId": 56,
          "address": "0x4803ac6b79f9582f69c4fa23c72cb76dd1e46d8d",
          "name": "TopManager",
          "symbol": "TMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22490/thumb/TMT-200.png?1643188285"
        },
        {
          "chainId": 56,
          "address": "0x0c6ed1e73ba73b8441868538e210ebd5dd240fa0",
          "name": "Sable Coin",
          "symbol": "USDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31192/thumb/eiJHqwTU_400x400.jpg?1691145928"
        },
        {
          "chainId": 56,
          "address": "0xfc3514474306e2d4aa8350fd8fa9c46c165fe8cd",
          "name": "Cloudname",
          "symbol": "CNAME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23868/thumb/_NsbVq86_400x400.jpg?1645598280"
        },
        {
          "chainId": 56,
          "address": "0x47db24e17c0c4622523449a239b3de746e2b0b23",
          "name": "PixelVerse",
          "symbol": "PIXEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG?1636325521"
        },
        {
          "chainId": 56,
          "address": "0xbd29490383edfd560426c3b63d01534408bc2da6",
          "name": "Avaxlauncher",
          "symbol": "AVXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18270/thumb/avaxlauncher.png?1631174466"
        },
        {
          "chainId": 56,
          "address": "0xf7b5fb4607abfe0ecf332c23cbdcc9e425b443a8",
          "name": "AcknoLedger",
          "symbol": "ACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19110/thumb/179336914_101598385424354_5729429129362932203_n.jpeg?1634514417"
        },
        {
          "chainId": 56,
          "address": "0x5711f19b7b21938d31d07e5736b4660c1159d7d3",
          "name": "PrivaCoin",
          "symbol": "PRVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28140/thumb/PrivaCoin-logo.png?1673525438"
        },
        {
          "chainId": 56,
          "address": "0x1f76aadeaba6a6a39fae849c20c5c5638058b4c2",
          "name": "TrumpCEO",
          "symbol": "TRUMPCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29942/thumb/492AE6EA-453A-460E-B2CC-84C2D55D9D9C.png?1682325787"
        },
        {
          "chainId": 56,
          "address": "0xf378acd7f4f04d96de4ebd492fa31d3d2f394567",
          "name": "Bull Game ToKens",
          "symbol": "BGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28324/thumb/20221122_112541.png?1669446314"
        },
        {
          "chainId": 56,
          "address": "0x8f2714b178676ba0f9cfb226f6519b92dd8def9d",
          "name": "Big Panda",
          "symbol": "PANDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30889/thumb/Panda_logo_.png?1688524544"
        },
        {
          "chainId": 56,
          "address": "0xad5452be97e257f7312ac7c681407a8b49ff1408",
          "name": "Superstake",
          "symbol": "SUPERSTAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29008/thumb/logo200x200.png?1675926807"
        },
        {
          "chainId": 56,
          "address": "0xe932902b2f8b7d4959a311b16b2672501c42b3f7",
          "name": "Memeverse",
          "symbol": "MEME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25098/thumb/meme.jpg?1650369722"
        },
        {
          "chainId": 56,
          "address": "0x50a9eb8a53f2c2993f46b354bd5f24f1c880bf24",
          "name": "TeleTreon",
          "symbol": "TTN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29809/thumb/Teletron_SYM_N_200x200.png?1681431623"
        },
        {
          "chainId": 56,
          "address": "0x9000cac49c3841926baac5b2e13c87d43e51b6a4",
          "name": "Portuma",
          "symbol": "POR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21567/thumb/coin-icon.png?1639474887"
        },
        {
          "chainId": 56,
          "address": "0x78a499a998bdd5a84cf8b5abe49100d82de12f1c",
          "name": "JOJO",
          "symbol": "JOJO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17383/thumb/JOJO.png?1627462497"
        },
        {
          "chainId": 56,
          "address": "0x5a119762b09ed0bcb3b16075159ae43a62651383",
          "name": "DALI",
          "symbol": "DALI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20245/thumb/dali.png?1636699012"
        },
        {
          "chainId": 56,
          "address": "0xca981cb99478d190cac2de266ed04e06e341e30e",
          "name": "DashSports",
          "symbol": "DASS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16793/thumb/200_200.png?1625034692"
        },
        {
          "chainId": 56,
          "address": "0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07",
          "name": "ZCore Finance",
          "symbol": "ZEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15346/thumb/icone_marca.png?1620623867"
        },
        {
          "chainId": 56,
          "address": "0x383e64ac8808dce10a39f0dda8a0484f44e68f5a",
          "name": "Paywong",
          "symbol": "PWG",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/29979/thumb/logo200x200-oval.png?1682405244"
        },
        {
          "chainId": 56,
          "address": "0x9505dbd77dacd1f6c89f101b98522d4b871d88c5",
          "name": "HunnyDAO",
          "symbol": "LOVE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20748/thumb/hunnydaologo-200x200.png?1637634867"
        },
        {
          "chainId": 56,
          "address": "0x76aecb353abf596bd61ee6bdb07d70787dec4fd6",
          "name": "CURE V2",
          "symbol": "CURE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18881/thumb/CURE-v2-square.png?1633692953"
        },
        {
          "chainId": 56,
          "address": "0xa52262da176186105199a597ac8cf094ff71b0c5",
          "name": "CleanCarbon",
          "symbol": "CARBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26311/thumb/cleancarbologo.png?1657238879"
        },
        {
          "chainId": 56,
          "address": "0x68e374f856bf25468d365e539b700b648bf94b67",
          "name": "Mist",
          "symbol": "MIST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14841/thumb/pKmPjCN-_200x200.jpg?1648630930"
        },
        {
          "chainId": 56,
          "address": "0x31acfce536b824ad0739e8d7b27cefaa4b8e4673",
          "name": "Lockness",
          "symbol": "LKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20176/thumb/lockness_bubles.png?1636603769"
        },
        {
          "chainId": 56,
          "address": "0x5e90253fbae4dab78aa351f4e6fed08a64ab5590",
          "name": "Bonfire",
          "symbol": "BONFIRE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15020/thumb/Logo_-_2021-04-27T062421.226.png?1619475866"
        },
        {
          "chainId": 56,
          "address": "0x78b2425fc305c0266143eaa527b01b043af83fb8",
          "name": "Vetter Skylabs",
          "symbol": "VSL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28208/thumb/Vetter-Skylabs-200x200.png?1668417213"
        },
        {
          "chainId": 56,
          "address": "0x52f8d048ba279556dd981036e7fa0345b5a90c7a",
          "name": "HeroesTD CGC",
          "symbol": "CGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22840/thumb/17508.png?1642745721"
        },
        {
          "chainId": 56,
          "address": "0xb8edd4261031c620431fe070b9e4b516571e9918",
          "name": "TAHU",
          "symbol": "TAHU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18284/thumb/TAHU_LOGO_trans_200x200.png?1631252700"
        },
        {
          "chainId": 56,
          "address": "0x7c59a57fc16eac270421b74615c4bc009ecd486d",
          "name": "RXCGames",
          "symbol": "RXCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20989/thumb/evyjvQB.jpeg?1638169876"
        },
        {
          "chainId": 56,
          "address": "0xd44fd09d74cd13838f137b590497595d6b3feea4",
          "name": "CryptoMines Eternal",
          "symbol": "ETERNAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18344/thumb/rn78txF.png?1631602371"
        },
        {
          "chainId": 56,
          "address": "0x4fde90e783abaa07996eff44f10edb132de15dd4",
          "name": "ShibAI Labs",
          "symbol": "SLAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28947/thumb/ShibAI-Labs-Logo-PNG.png?1675572323"
        },
        {
          "chainId": 56,
          "address": "0x44ece1031e5b5e2d9169546cc10ea5c95ba96237",
          "name": "AmazingTeamDAO",
          "symbol": "AMAZINGTEAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25064/thumb/EXlV29q.jpg?1650013930"
        },
        {
          "chainId": 56,
          "address": "0xbb0fa2fbe9b37444f5d1dbd22e0e5bdd2afbbe85",
          "name": "USD Mars",
          "symbol": "USDM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21252/thumb/WeChat_Image_20211230181747.jpg?1640859506"
        },
        {
          "chainId": 56,
          "address": "0x51e6ac1533032e72e92094867fd5921e3ea1bfa0",
          "name": "LUCA",
          "symbol": "LUCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21278/thumb/luca.PNG?1638862587"
        },
        {
          "chainId": 56,
          "address": "0x168e3b1746aa249a9b3603b70605924fe255ee1a",
          "name": "GAMER",
          "symbol": "GMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21288/thumb/ezgif-1-7f6a016717.jpg?1645270886"
        },
        {
          "chainId": 56,
          "address": "0x85c128ee1feeb39a59490c720a9c563554b51d33",
          "name": "MoMo Key",
          "symbol": "KEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14812/thumb/2e7a347a975a232693d4467bd4d6546.png?1618542804"
        },
        {
          "chainId": 56,
          "address": "0x91656750bc364ff38adb51157acbb76f9f5ec2fe",
          "name": "Safemoon Zilla",
          "symbol": "SFZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22483/thumb/BXZ1CNbO_400x400.jpg?1641889347"
        },
        {
          "chainId": 56,
          "address": "0x6a6ccf15b38da4b5b0ef4c8fe9fefcb472a893f9",
          "name": "MoonStarter",
          "symbol": "MNST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16146/thumb/MoonStarter.png?1623120360"
        },
        {
          "chainId": 56,
          "address": "0xa6c897caaca3db7fd6e2d2ce1a00744f40ab87bb",
          "name": "DeathRoad",
          "symbol": "DRACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18178/thumb/DeathRoad_token_logo_200x200-25.png?1630897737"
        },
        {
          "chainId": 56,
          "address": "0x967784950655b8e74a2d3d3503933f0015660074",
          "name": "AliF Coin",
          "symbol": "ALIF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29392/thumb/aliflogo200x200.png?1678779526"
        },
        {
          "chainId": 56,
          "address": "0x4a76a1eaa365c10bd9b895acab5760d52ff2f7c9",
          "name": "Safemoon 1996",
          "symbol": "SM96",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28300/thumb/IMG_20221120_122930_391.jpg?1669267451"
        },
        {
          "chainId": 56,
          "address": "0x5aad894b3015904265aeac1db64fd0f028e8448f",
          "name": "Floki GPT",
          "symbol": "FGPT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29966/thumb/photo_2023-04-19_19-00-14.jpg?1682390001"
        },
        {
          "chainId": 56,
          "address": "0xefdb93e14cd63b08561e86d3a30aae0f3aabad9a",
          "name": "Nexuspad",
          "symbol": "NEXUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28345/thumb/NEXUS.png?1669692130"
        },
        {
          "chainId": 56,
          "address": "0x1e4f2ab406aa9764ff05a9a8c8bf6b1c8b6f531f",
          "name": "Sacred Tails",
          "symbol": "ST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22543/thumb/STLogo200x.png?1657688459"
        },
        {
          "chainId": 56,
          "address": "0xa87584cfeb892c33a1c9a233e4a733b45c4160e6",
          "name": "Digihealth",
          "symbol": "DGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28391/thumb/Its.png?1670139269"
        },
        {
          "chainId": 56,
          "address": "0x7efb55d9ac57b23cc6811c9068db3cf83cbdfe39",
          "name": "nSights",
          "symbol": "NSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19607/thumb/nsi.png?1635641149"
        },
        {
          "chainId": 56,
          "address": "0x19b99162adaab85134e781ac0048c275c31b205a",
          "name": "Marnotaur",
          "symbol": "TAUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18655/thumb/taur.PNG?1632795749"
        },
        {
          "chainId": 56,
          "address": "0xd0f4afa85a667d27837e9c07c81169869c16dd16",
          "name": "Privapp Network",
          "symbol": "BPRIVA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15252/thumb/priv.png?1620265611"
        },
        {
          "chainId": 56,
          "address": "0xd48474e7444727bf500a32d5abe01943f3a59a64",
          "name": "BitBook",
          "symbol": "BBT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15965/thumb/bitbook.PNG?1622520145"
        },
        {
          "chainId": 56,
          "address": "0x67343c29c0fd9827f33e675e0eb80773f9444444",
          "name": "iSTEP",
          "symbol": "ISTEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25888/thumb/ISTEP.png?1654420630"
        },
        {
          "chainId": 56,
          "address": "0x26a1bdfa3bb86b2744c4a42ebfdd205761d13a8a",
          "name": "KAKA NFT World",
          "symbol": "KAKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20877/thumb/10768.png?1637818350"
        },
        {
          "chainId": 56,
          "address": "0x9dfee72aea65dc7e375d50ea2bd90384313a165a",
          "name": "Scrooge",
          "symbol": "SCROOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30937/thumb/scrooge.jpeg?1689077707"
        },
        {
          "chainId": 56,
          "address": "0x0556551f81623ae32c5c7cf853c67fafbe61648a",
          "name": "Solar Full Cycle",
          "symbol": "SFC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21204/thumb/SFC-200x200-Transparent-logo.png?1638548613"
        },
        {
          "chainId": 56,
          "address": "0xc409ec8a33f31437ed753c82eed3c5f16d6d7e22",
          "name": "Tokyo AU",
          "symbol": "TOKAU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16182/thumb/TOKAU.jpg?1623217919"
        },
        {
          "chainId": 56,
          "address": "0x2b339d46e157cf93de6a919aa05350e952f67359",
          "name": "BIB Token",
          "symbol": "BIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28462/thumb/BIB.png?1670899507"
        },
        {
          "chainId": 56,
          "address": "0x80aa21b19c2fa7aa29a654859ffec161aa6f04a4",
          "name": "Rule Token",
          "symbol": "RULE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29985/thumb/our_logo.png?1682411065"
        },
        {
          "chainId": 56,
          "address": "0x256be284fea694f1bb11f76d556a28ecb496eee9",
          "name": "Nirvana Meta MNU Chain",
          "symbol": "MNU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28669/thumb/mnu.png?1673002554"
        },
        {
          "chainId": 56,
          "address": "0x829555f1197171d35ec51c095e27b47a246ac6a6",
          "name": "Realital Metaverse",
          "symbol": "RETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22791/thumb/reta_logo_200.png?1642581119"
        },
        {
          "chainId": 56,
          "address": "0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657",
          "name": "BabySwap",
          "symbol": "BABY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16169/thumb/baby.PNG?1623190282"
        },
        {
          "chainId": 56,
          "address": "0xeb90a6273f616a8ed1cf58a05d3ae1c1129b4de6",
          "name": "Ancient Raid",
          "symbol": "RAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26990/thumb/raid_token_transparent.png?1661222820"
        },
        {
          "chainId": 56,
          "address": "0x205afd08cefe438377a0abc5a20cb4462e1a8c5c",
          "name": "OBRok",
          "symbol": "OBROK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22078/thumb/logo_-_2021-12-29T130335.005.png?1640754224"
        },
        {
          "chainId": 56,
          "address": "0xda0638ea374c4c5bf2914e6f4d5b2335deb8d80d",
          "name": "GBANK APY",
          "symbol": "GBK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30533/thumb/IMG-20230519-WA0000.jpg?1684891767"
        },
        {
          "chainId": 56,
          "address": "0x8da6113655309f84127e0837fcf5c389892578b3",
          "name": "OB",
          "symbol": "OBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19241/thumb/Group_%281%29.png?1667397411"
        },
        {
          "chainId": 56,
          "address": "0x5d31aa2330efa65acca9567796fdf7d1caa6fa9a",
          "name": "MongBNB",
          "symbol": "MONGBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30474/thumb/IMG_20230519_194220_419.png?1684672578"
        },
        {
          "chainId": 56,
          "address": "0xf82aa46120314904cd8119dac84f6bcc7d90ed2e",
          "name": "Lucrosus Capital",
          "symbol": "LUCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22445/thumb/luca.png?1644047967"
        },
        {
          "chainId": 56,
          "address": "0xfb4b7ee058828b5fbf2e475da88f1903f453334e",
          "name": "OleCoin",
          "symbol": "OLE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17791/thumb/icon-olecoin-200x200-1.png?1629261482"
        },
        {
          "chainId": 56,
          "address": "0x3ad9594151886ce8538c1ff615efa2385a8c3a88",
          "name": "Safemars",
          "symbol": "SAFEMARS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14451/thumb/MARTIAN.?1616142739"
        },
        {
          "chainId": 56,
          "address": "0x9096b4309224d751fcb43d7eb178dcffc122ad15",
          "name": "Legion Network",
          "symbol": "LGX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21816/thumb/1_%284%29.png?1640071919"
        },
        {
          "chainId": 56,
          "address": "0x4aaf59dee18ecc1bbd2bf68b3f7ba3af47eb9cfc",
          "name": "YourWallet",
          "symbol": "YOURWALLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28882/thumb/logo_200.png?1675140355"
        },
        {
          "chainId": 56,
          "address": "0x48a356df5140ed37034afada32d03b74d4271d6a",
          "name": "Armour Wallet",
          "symbol": "ARMOUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30068/thumb/200X200.jpg?1683009525"
        },
        {
          "chainId": 56,
          "address": "0x22517fa2a5341453f1f9ebd5ecb8620a90dc8e4b",
          "name": "Vanilla Network",
          "symbol": "VNLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13223/thumb/vanilla_network_logo.jpeg?1606352291"
        },
        {
          "chainId": 56,
          "address": "0x783fe4a84645431b31b914b609b86127b96057ea",
          "name": "SpaceGoat",
          "symbol": "SGT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16077/thumb/Space-Goat-200-x-200-01.png?1622772917"
        },
        {
          "chainId": 56,
          "address": "0x365b475bc0eaf3c92f6b248b3f09d10f55ec900d",
          "name": "CORGI CEO",
          "symbol": "CORGICEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29526/thumb/200x200.png?1679473452"
        },
        {
          "chainId": 56,
          "address": "0x5fc6179fcf814fcd4344ee03376ba717a95992b6",
          "name": "Victorum",
          "symbol": "VCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17391/thumb/Victorum-logo200X200.png?1627525058"
        },
        {
          "chainId": 56,
          "address": "0x57022edd5c7ed7b6bd870488853fe961dfdd3fb6",
          "name": "Octaplex Network",
          "symbol": "PLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18013/thumb/octaplex-icon.png?1630232850"
        },
        {
          "chainId": 56,
          "address": "0x633237c6fa30fae46cc5bb22014da30e50a718cc",
          "name": "Defi Warrior",
          "symbol": "FIWA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18208/thumb/defi_warrior.PNG?1630986310"
        },
        {
          "chainId": 56,
          "address": "0xbf758f2afec32b92e8008b5671088d42af616515",
          "name": "DogeArmy",
          "symbol": "DOGRMY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29212/thumb/dogearmy.png?1678947339"
        },
        {
          "chainId": 56,
          "address": "0xe02dee9267e21a43e19658b50983102765594854",
          "name": "Bearium",
          "symbol": "BRIUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30169/thumb/bearium_200.png?1683533138"
        },
        {
          "chainId": 56,
          "address": "0x6120ba0b3538e40aa7aac32558e5dd0737b7ac90",
          "name": "JesusCoin",
          "symbol": "JESUS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30625/thumb/logo_200px.png?1685777416"
        },
        {
          "chainId": 56,
          "address": "0x9789df6753b7f813a1c55ed20ecf83571dfde428",
          "name": "ULAND",
          "symbol": "ULAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22040/thumb/logo_globe_200.png?1640669852"
        },
        {
          "chainId": 56,
          "address": "0x4aac18de824ec1b553dbf342829834e4ff3f7a9f",
          "name": "AnchorSwap",
          "symbol": "ANCHOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18909/thumb/anchorswap.PNG?1633910516"
        },
        {
          "chainId": 56,
          "address": "0x8a8a6930ea308042d6d2d4676444d2af9b35a418",
          "name": "FinToken",
          "symbol": "FTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28509/thumb/ftc.png?1671175193"
        },
        {
          "chainId": 56,
          "address": "0xef4c37e914243322a32a81abd34de76af1b47da0",
          "name": "YSL",
          "symbol": "YSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17466/thumb/YSL_Light_Mode.png?1685443984"
        },
        {
          "chainId": 56,
          "address": "0x42414624c55a9cba80789f47c8f9828a7974e40f",
          "name": "Doge KaKi",
          "symbol": "KAKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29202/thumb/DOGEKAKI.jpg?1677307558"
        },
        {
          "chainId": 56,
          "address": "0x3837e18b901629fcb200e0ad9c2f2441804bd6c8",
          "name": "Caketools",
          "symbol": "CKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20620/thumb/caketools-round-200.png?1637769605"
        },
        {
          "chainId": 56,
          "address": "0x761626b095a85131646403c158d8fe81fc7092d9",
          "name": "DollarSqueeze",
          "symbol": "DSQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30764/thumb/DS.png?1690430235"
        },
        {
          "chainId": 56,
          "address": "0x14357d294fbabbe0fbf59503370c772d563b35b6",
          "name": "Hummingbird Finance",
          "symbol": "HMNG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15403/thumb/Untitled-design-8-removebg-preview.png?1623050079"
        },
        {
          "chainId": 56,
          "address": "0x739e81bcd49854d7bdf526302989f14a2e7994b2",
          "name": "Centcex",
          "symbol": "CENX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20624/thumb/centx.PNG?1637318819"
        },
        {
          "chainId": 56,
          "address": "0x5ac5e6af46ef285b3536833e65d245c49b608d9b",
          "name": "NIOB",
          "symbol": "NIOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24141/thumb/niob.png?1646548221"
        },
        {
          "chainId": 56,
          "address": "0x0e9729a1db9e45ff08f64e6c4342be3921e993e0",
          "name": "Day of Defeat 2 0",
          "symbol": "DOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20621/thumb/Untitled_design_%2836%29.png?1674818183"
        },
        {
          "chainId": 56,
          "address": "0x2f3a64922e82d23ced259545d4d13bdad97209ed",
          "name": "Cryptolic",
          "symbol": "CPTLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27744/thumb/cptlc-logo-200.png?1665631864"
        },
        {
          "chainId": 56,
          "address": "0x24dcd565ba10c64daf1e9faedb0f09a9053c6d07",
          "name": "BLU",
          "symbol": "BLU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30674/thumb/Blu.png?1686212083"
        },
        {
          "chainId": 56,
          "address": "0x6dc3d0d6ec970bf5522611d8eff127145d02b675",
          "name": "Revolotto",
          "symbol": "RVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19044/thumb/rvl.PNG?1634253070"
        },
        {
          "chainId": 56,
          "address": "0x864f20c06fff47e3130de2e1269d6067b67aa69d",
          "name": "ShibCEO",
          "symbol": "SHIBCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29259/thumb/shibceo.png?1677656403"
        },
        {
          "chainId": 56,
          "address": "0x6cbc2e98e16c28775637d59342f0c8d04ba66e39",
          "name": "Vaulteum",
          "symbol": "VAULT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28611/thumb/IMG_20221231_040125_514.jpg?1672470565"
        },
        {
          "chainId": 56,
          "address": "0x4d496efc21754481fe7a9f3f0f758785ade8e1d3",
          "name": "Santa Inu",
          "symbol": "SANINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20647/thumb/wzP7T0v1_400x400.jpg?1637527137"
        },
        {
          "chainId": 56,
          "address": "0xde3dbbe30cfa9f437b293294d1fd64b26045c71a",
          "name": "NFTb",
          "symbol": "NFTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15484/thumb/nftb_logo.png?1624437899"
        },
        {
          "chainId": 56,
          "address": "0x2d7a47908d817dd359f9aba7feaa89c92a289c7e",
          "name": "Lenda",
          "symbol": "LENDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22925/thumb/logo-200x200.png?1643002526"
        },
        {
          "chainId": 56,
          "address": "0xe700ba35998fad8e669e3cca7b3a350f1fdcacf8",
          "name": "Purchasa",
          "symbol": "PCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28951/thumb/PCA.png?1675577498"
        },
        {
          "chainId": 56,
          "address": "0xfc646d0b564bf191b3d3adf2b620a792e485e6da",
          "name": "Half Pizza",
          "symbol": "PIZA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17785/thumb/piza.PNG?1629250720"
        },
        {
          "chainId": 56,
          "address": "0x7deb9906bd1d77b410a56e5c23c36340bd60c983",
          "name": "ChargeDeFi Static",
          "symbol": "STATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21404/thumb/1_Ve6PPOEmEu1LRjg5loJkkQ.png?1639087027"
        },
        {
          "chainId": 56,
          "address": "0x4c48cca6153db911002f965d22fdefcd95f33be9",
          "name": "The Essential Coin",
          "symbol": "ESC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21913/thumb/The-Essential-Coin-200x200.png?1640241497"
        },
        {
          "chainId": 56,
          "address": "0x119555f26df9386982fa9f0245d643840fbe8a49",
          "name": "SPEXY",
          "symbol": "SPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30831/thumb/SPX-01.png?1687766223"
        },
        {
          "chainId": 56,
          "address": "0xc183062db25fc96325485ea369c979ce881ac0ea",
          "name": "ShibElon",
          "symbol": "SHIBELON",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/20434/thumb/shibelon.png?1637037218"
        },
        {
          "chainId": 56,
          "address": "0x267022751e06d97b9ee4e5f26cc1023670bdb349",
          "name": "Rps League",
          "symbol": "RPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20918/thumb/nS5mB7aP_400x400.jpg?1637924138"
        },
        {
          "chainId": 56,
          "address": "0x52b7c9d984ea17e9ee31159ca3fff3790981b64a",
          "name": "Medamon",
          "symbol": "MON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25081/thumb/B1-F2-FD78-8375-439-A-B7-B0-88492-B55-F6-FD.png?1650263137"
        },
        {
          "chainId": 56,
          "address": "0x2d5c9167fdd5c068c8fcb8992e6af639b42fbf70",
          "name": "Merge",
          "symbol": "MERGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8769/thumb/Merge_Icon_Colored.png?1607391272"
        },
        {
          "chainId": 56,
          "address": "0x2963dcc52549573bbfbe355674724528532c0867",
          "name": "Pexcoin",
          "symbol": "PEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20916/thumb/RC5dZfC4_400x400.jpg?1637923227"
        },
        {
          "chainId": 56,
          "address": "0x2a49840ba994486dd3ceb93e1124308f7226f219",
          "name": "Garfield  BSC ",
          "symbol": "GARFIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30511/thumb/garfield.jpg?1684830292"
        },
        {
          "chainId": 56,
          "address": "0x36953b5ec00a13edceceb3af258d034913d2a79d",
          "name": "ManuFactory",
          "symbol": "MNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21677/thumb/logo1_%288%29.png?1639712452"
        },
        {
          "chainId": 56,
          "address": "0x1ab7e7deda201e5ea820f6c02c65fce7ec6bed32",
          "name": "Bot Planet",
          "symbol": "BOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24650/thumb/McLc4iA.png?1648612075"
        },
        {
          "chainId": 56,
          "address": "0xc9882def23bc42d53895b8361d0b1edc7570bc6a",
          "name": "Fistbump",
          "symbol": "FIST",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25051/thumb/download.png?1649918598"
        },
        {
          "chainId": 56,
          "address": "0x9678e42cebeb63f23197d726b29b1cb20d0064e5",
          "name": "Binance Peg IoTeX",
          "symbol": "IOTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15978/thumb/iotex-logo.png?1622527411"
        },
        {
          "chainId": 56,
          "address": "0x411ec510c85c9e56271bf4e10364ffa909e685d9",
          "name": "Moniwar",
          "symbol": "MOWA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18665/thumb/spqKFki.png?1634177911"
        },
        {
          "chainId": 56,
          "address": "0x73f67ae7f934ff15beabf55a28c2da1eeb9b56ec",
          "name": "NFT11",
          "symbol": "NFT11",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23792/thumb/token-icon.png?1645438525"
        },
        {
          "chainId": 56,
          "address": "0x095956b142431eb9cf88b99f392540b91acbf4ad",
          "name": "One Basis Cash",
          "symbol": "OBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16521/thumb/OBS.50eb9474.png?1624330479"
        },
        {
          "chainId": 56,
          "address": "0x2cb63fcd1380a8ad0ff5ba16ddcbdf4935154da8",
          "name": "TopTrade",
          "symbol": "TTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30159/thumb/IMG_20230505_073247_596.jpg?1683526616"
        },
        {
          "chainId": 56,
          "address": "0x8d047f4f57a190c96c8b9704b39a1379e999d82b",
          "name": "Etherconnect",
          "symbol": "ECC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20287/thumb/14404.png?1636813272"
        },
        {
          "chainId": 56,
          "address": "0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19",
          "name": "OwlDAO",
          "symbol": "OWL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18717/thumb/owldao.PNG?1633089590"
        },
        {
          "chainId": 56,
          "address": "0x7606267a4bfff2c5010c92924348c3e4221955f2",
          "name": "Talkado",
          "symbol": "TALK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20979/thumb/Tzu4wc-k_400x400.jpg?1638163270"
        },
        {
          "chainId": 56,
          "address": "0x97a30c692ece9c317235d48287d23d358170fc40",
          "name": "CryptEx",
          "symbol": "CRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14225/thumb/cryptex_logo.png?1615106295"
        },
        {
          "chainId": 56,
          "address": "0x7e8db69dcff9209e486a100e611b0af300c3374e",
          "name": "Traders Coin",
          "symbol": "TRDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18675/thumb/logo-200x200.png?1633360593"
        },
        {
          "chainId": 56,
          "address": "0xe356337a72d4990a3cfd4d13367659f14f304545",
          "name": "Stroke Prevention GenomicDAO",
          "symbol": "PCSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29200/thumb/GenomicDao_logo.png?1677306957"
        },
        {
          "chainId": 56,
          "address": "0x26d3163b165be95137cee97241e716b2791a7572",
          "name": "Dibs Share",
          "symbol": "DSHARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22194/thumb/dshares.png?1641191230"
        },
        {
          "chainId": 56,
          "address": "0xece1688ccde78c6d511c57c30a05d05f014b0234",
          "name": "Zetos",
          "symbol": "ZES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30700/thumb/8hWUfQNF_400x400.jpg?1686560290"
        },
        {
          "chainId": 56,
          "address": "0x4c906b99a2f45a47c8570b7a41ffe940f71676af",
          "name": "OpiPets",
          "symbol": "OPIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28806/thumb/OP_LOGO.png?1674303006"
        },
        {
          "chainId": 56,
          "address": "0x5fdab5bdbad5277b383b3482d085f4bfef68828c",
          "name": "DeFiHorse",
          "symbol": "DFH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24293/thumb/uxwvJpWa_400x400.jpg?1647265261"
        },
        {
          "chainId": 56,
          "address": "0x7b65b489fe53fce1f6548db886c08ad73111ddd8",
          "name": "Iron BSC",
          "symbol": "IRON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16409/thumb/qBa5_yCL_400x400.jpg?1623927990"
        },
        {
          "chainId": 56,
          "address": "0xfcadd830ff2d6cf3ad1681e1e8fc5ddce9d59e74",
          "name": "Zada",
          "symbol": "ZADA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20602/thumb/Screenshot-2021-11-18-at-22-55-25.png?1637287967"
        },
        {
          "chainId": 56,
          "address": "0x1d229b958d5ddfca92146585a8711aecbe56f095",
          "name": "ZOO Crypto World",
          "symbol": "ZOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17269/thumb/zoo.PNG?1627004863"
        },
        {
          "chainId": 56,
          "address": "0x200c234721b5e549c3693ccc93cf191f90dc2af9",
          "name": "Drunk Robots",
          "symbol": "METAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24376/thumb/metal.png?1649571721"
        },
        {
          "chainId": 56,
          "address": "0x2f4e9c97aaffd67d98a640062d90e355b4a1c539",
          "name": "Afrostar",
          "symbol": "AFRO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19784/thumb/afrostar.PNG?1635848538"
        },
        {
          "chainId": 56,
          "address": "0xb8c3e8ff71513afc8cfb2dddc5a994a501db1916",
          "name": "YESorNO",
          "symbol": "YON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21214/thumb/K91jws5t_400x400.png?1638572522"
        },
        {
          "chainId": 56,
          "address": "0xa5e279e14efd60a8f29e5ac3b464e3de0c6bb6b8",
          "name": "ZAMZAM",
          "symbol": "ZAMZAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22671/thumb/d7168acd189f475ea38e113af81ebf41.png?1642404780"
        },
        {
          "chainId": 56,
          "address": "0x4afc8c2be6a0783ea16e16066fde140d15979296",
          "name": "Hare  OLD ",
          "symbol": "HARE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16327/thumb/200ico.png?1623745151"
        },
        {
          "chainId": 56,
          "address": "0x17bd2e09fa4585c15749f40bb32a6e3db58522ba",
          "name": "Ethernal Finance",
          "symbol": "ETHFIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20190/thumb/CMC_%282%29.png?1636612448"
        },
        {
          "chainId": 56,
          "address": "0x315be92aba5c3aaaf82b0c0c613838342c1416e7",
          "name": "BlueBenx",
          "symbol": "BENX",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/27460/thumb/B3S1F8VA_400x400.jpeg?1664167125"
        },
        {
          "chainId": 56,
          "address": "0xbbcf57177d8752b21d080bf30a06ce20ad6333f8",
          "name": "Zam io",
          "symbol": "ZAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19522/thumb/zam.png?1635324134"
        },
        {
          "chainId": 56,
          "address": "0xa0a4c12aa90fe439b07b16657cd2c12e4d41e25f",
          "name": "Moovy",
          "symbol": "MOIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30058/thumb/output-onlinepngtools_%281%29.png?1683000502"
        },
        {
          "chainId": 56,
          "address": "0x565b72163f17849832a692a3c5928cc502f46d69",
          "name": "Hunny Finance",
          "symbol": "HUNNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16043/thumb/hunny-logo.c87da9c4.png?1622684248"
        },
        {
          "chainId": 56,
          "address": "0x6e22bfc7236e95c3aef6acdbd7218bcf59a483ac",
          "name": "Risecoin",
          "symbol": "RSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28302/thumb/risecoin-200-200.png?1669276167"
        },
        {
          "chainId": 56,
          "address": "0x6aa91cbfe045f9d154050226fcc830ddba886ced",
          "name": "Pledge",
          "symbol": "PLGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18243/thumb/PLGR.png?1644808236"
        },
        {
          "chainId": 56,
          "address": "0x3e098c23dcfbbe0a3f468a6bed1cf1a59dc1770d",
          "name": "BountyKinds YU",
          "symbol": "YU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30454/thumb/YU_TOKEN_%281%29.png?1684491577"
        },
        {
          "chainId": 56,
          "address": "0x70a30bb133f7b5038d3c28d8b77d8da258fc784a",
          "name": "DeHeroGame Amazing Token",
          "symbol": "AMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29233/thumb/23548.png?1677549681"
        },
        {
          "chainId": 56,
          "address": "0xe64017bdacbe7dfc84886c3704a26d566e7550de",
          "name": "Kingdom Karnage",
          "symbol": "KKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22598/thumb/KKT_200x200.png?1643193056"
        },
        {
          "chainId": 56,
          "address": "0x1b239abe619e74232c827fbe5e49a4c072bd869d",
          "name": "Gyro",
          "symbol": "GYRO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19020/thumb/gyro.jpg?1634185058"
        },
        {
          "chainId": 56,
          "address": "0x82555cc48a532fa4e2194ab883eb6d465149f80e",
          "name": "MetaPioneers",
          "symbol": "MPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29088/thumb/mpi.png?1676520632"
        },
        {
          "chainId": 56,
          "address": "0x6775729fad1438116b2e3b4fb2878539795297a7",
          "name": "CoinAlpha",
          "symbol": "ALP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18797/thumb/alp.PNG?1633472202"
        },
        {
          "chainId": 56,
          "address": "0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0",
          "name": "Yieldwatch",
          "symbol": "WATCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14186/thumb/WATCH1.png?1614825718"
        },
        {
          "chainId": 56,
          "address": "0x86cd1faf05abbb705842ec3c98ef5006173fb4d6",
          "name": "JP",
          "symbol": "JP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28967/thumb/Jp.png?1675746186"
        },
        {
          "chainId": 56,
          "address": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
          "name": "Binance Peg Polkadot",
          "symbol": "DOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15457/thumb/-Tj2WF_6_400x400.jpg?1620895613"
        },
        {
          "chainId": 56,
          "address": "0x63e77cf206801782239d4f126cfa22b517fb4edb",
          "name": "Sensi",
          "symbol": "SENSI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25449/thumb/xdirLdlX_400x400.png?1651802649"
        },
        {
          "chainId": 56,
          "address": "0x6aa150fff813e0bec1273691f349ad080df7216d",
          "name": "Ferma",
          "symbol": "FERMA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14482/thumb/ferma.png?1616463681"
        },
        {
          "chainId": 56,
          "address": "0xdef49c195099e30e41b2df7dad55e0bbbe60a0c5",
          "name": "Syrup Finance",
          "symbol": "SRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28620/thumb/srx.png?1672638818"
        },
        {
          "chainId": 56,
          "address": "0x4f5f7a7dca8ba0a7983381d23dfc5eaf4be9c79a",
          "name": "Seek Tiger",
          "symbol": "STI",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/24628/thumb/50d6850967efa02b20fb24c6c7ba518.png?1648457363"
        },
        {
          "chainId": 56,
          "address": "0xfd0fd32a20532ad690731c2685d77c351015ebba",
          "name": "Quarashi",
          "symbol": "QUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25589/thumb/Lk2A7ta.png?1652769197"
        },
        {
          "chainId": 56,
          "address": "0x47fd014706081068448b89fc6baca2730977216a",
          "name": "Pepe the Frog",
          "symbol": "PEPEBNB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29893/thumb/pepenewlogo.PNG?1683520634"
        },
        {
          "chainId": 56,
          "address": "0xc6f509274fcc1f485644167cb911fd0c61545e6c",
          "name": "Obsidium",
          "symbol": "OBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19499/thumb/New-Obsidium-Logo-Circle-200x200.png?1656574019"
        },
        {
          "chainId": 56,
          "address": "0xb6d053e260d410eac02ea28755696f90a8ecca2b",
          "name": "Shikoku Inu",
          "symbol": "SHIKO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16442/thumb/shiko.PNG?1624234177"
        },
        {
          "chainId": 56,
          "address": "0x885c5fb8f0e67b2b0cf3a437e6cc6ebc0f9f9014",
          "name": "GemGuardian",
          "symbol": "GEMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18614/thumb/gemg.PNG?1632697389"
        },
        {
          "chainId": 56,
          "address": "0x03ac6ab6a9a91a0fcdec7d85b38bdfbb719ec02f",
          "name": "Metagame Arena",
          "symbol": "MGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20947/thumb/mga.png?1638073074"
        },
        {
          "chainId": 56,
          "address": "0xd21a1f1fcec9479d38b570a2eca3277b1b2a959a",
          "name": "Malgo Finance",
          "symbol": "MGXG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29315/thumb/CMC_Logo_Round__%286%29.png?1678010586"
        },
        {
          "chainId": 56,
          "address": "0x0ccd575bf9378c06f6dca82f8122f570769f00c2",
          "name": "CryptoBlades Kingdoms",
          "symbol": "KING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18728/thumb/cryptoblades-kingdoms.jpeg?1633228872"
        },
        {
          "chainId": 56,
          "address": "0x6ec90334d89dbdc89e08a133271be3d104128edb",
          "name": "Wiki Cat",
          "symbol": "WKC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26037/thumb/Wiki-Logo.png?1655886306"
        },
        {
          "chainId": 56,
          "address": "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
          "name": "Binance Peg Litecoin",
          "symbol": "LTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15456/thumb/LrysCc5Q_400x400.jpg?1620895206"
        },
        {
          "chainId": 56,
          "address": "0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8",
          "name": "Helmet Insure",
          "symbol": "HELMET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13680/thumb/ZMdK-1J4_400x400.png?1610834469"
        },
        {
          "chainId": 56,
          "address": "0xf8028b65005b0b45f76988d2a77910186e7af4ef",
          "name": "NDB",
          "symbol": "NDB",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/26545/thumb/ba98gdMa_400x400.jpeg?1658752427"
        },
        {
          "chainId": 56,
          "address": "0x34ba3af693d6c776d73c7fa67e2b2e79be8ef4ed",
          "name": "Shambala",
          "symbol": "BALA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19635/thumb/shambala.PNG?1635715476"
        },
        {
          "chainId": 56,
          "address": "0xfd7b3a77848f1c2d67e05e54d78d174a0c850335",
          "name": "Binance Peg Ontology",
          "symbol": "ONT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18559/thumb/ont.png?1632395038"
        },
        {
          "chainId": 56,
          "address": "0x854f7cd3677737241e3eed0dc3d7f33dfaf72bc4",
          "name": "ETHAX",
          "symbol": "ETHAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25571/thumb/ethax.png?1652611600"
        },
        {
          "chainId": 56,
          "address": "0x1c5a65ededa96e7daf0715d978cc643184fbbd6c",
          "name": "Warrior Empires",
          "symbol": "CHAOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29237/thumb/Untitled_design_%2817%29.png?1677558979"
        },
        {
          "chainId": 56,
          "address": "0xface67c5ce2bb48c29779b0dede5360cc9ef5fd5",
          "name": "Affinity",
          "symbol": "AFNTY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27345/thumb/YPtUpB2I_400x400.jpeg?1663647788"
        },
        {
          "chainId": 56,
          "address": "0x041640ea980e3fe61e9c4ca26d9007bc70094c15",
          "name": "PirateCoin",
          "symbol": "PIRATECOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18278/thumb/logo_200_%284%29.png?1631234630"
        },
        {
          "chainId": 56,
          "address": "0x72ef0cf8dc02fe91150a2472cc551de929e22fac",
          "name": "Waifer",
          "symbol": "WAIF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19424/thumb/cropped-LogoMakr-3GX0jv-300dpi-1.png?1635214905"
        },
        {
          "chainId": 56,
          "address": "0xa75e7928d3de682e3f44da60c26f33117c4e6c5c",
          "name": "Propel",
          "symbol": "PEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21290/thumb/img_circle_1.png?1677318804"
        },
        {
          "chainId": 56,
          "address": "0xd585f9c5953ca97da3551f20725a274c9e442ff3",
          "name": "Pegazus Finance",
          "symbol": "PEG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15610/thumb/Pegazus.png?1621317167"
        },
        {
          "chainId": 56,
          "address": "0xee0736c93e98b2e9fbf11f47db19e7b68db71e94",
          "name": "FarmerDoge",
          "symbol": "CROP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19910/thumb/farmer.jpeg?1636107276"
        },
        {
          "chainId": 56,
          "address": "0x232fb065d9d24c34708eedbf03724f2e95abe768",
          "name": "Sheesha Finance  BEP20 ",
          "symbol": "SHEESHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15105/thumb/MLBmh4z0.png?1643114683"
        },
        {
          "chainId": 56,
          "address": "0x88c676fc777c225b69869aeef5d10535de1e4f5d",
          "name": "OragonX",
          "symbol": "ORGN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22010/thumb/oragonlnew200.png?1640590142"
        },
        {
          "chainId": 56,
          "address": "0xfeea0bdd3d07eb6fe305938878c0cadbfa169042",
          "name": "8Pay",
          "symbol": "8PAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14667/thumb/8pay.jpeg?1617639682"
        },
        {
          "chainId": 56,
          "address": "0x6a061bc3bd2f90fc3fe0b305488c32d121d0093e",
          "name": "Network Capital Token",
          "symbol": "NETC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28655/thumb/Network_Capital_Token.png?1672904146"
        },
        {
          "chainId": 56,
          "address": "0x17fd3caa66502c6f1cbd5600d8448f3af8f2aba1",
          "name": "Smarty Pay",
          "symbol": "SPY",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/18813/thumb/logo_smarty-pay.png?1633499996"
        },
        {
          "chainId": 56,
          "address": "0x6a0b66710567b6beb81a71f7e9466450a91a384b",
          "name": "PearDAO",
          "symbol": "PEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24253/thumb/pex.png?1647154069"
        },
        {
          "chainId": 56,
          "address": "0xcf3bb6ac0f6d987a5727e2d15e39c2d6061d5bec",
          "name": "SHELTERZ",
          "symbol": "TERZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29251/thumb/TERZ_200.png?1677642907"
        },
        {
          "chainId": 56,
          "address": "0x5c7bb1e6c45b055a7831f0c82740f9677bbf6d43",
          "name": "InteractWith",
          "symbol": "INTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29587/thumb/Pfp_Transparent_1.png?1679885064"
        },
        {
          "chainId": 56,
          "address": "0xdfd7b0dd7bf1012dfdf3307a964c36b972300ac8",
          "name": "Wonderman Nation",
          "symbol": "WNDR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25461/thumb/Wonderman_Logo_Menu.png?1651827108"
        },
        {
          "chainId": 56,
          "address": "0xf3b185ab60128e4c08008fd90c3f1f01f4b78d50",
          "name": "MetaDoge BSC",
          "symbol": "METADOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28815/thumb/Dogemeta.jpeg?1674457567"
        },
        {
          "chainId": 56,
          "address": "0x3f8a14f5a3ee2f4a3ed61ccf5eea3c9535c090c8",
          "name": "LoopSwap",
          "symbol": "LSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24300/thumb/5D7QqYm.png?1647269009"
        },
        {
          "chainId": 56,
          "address": "0xacff4e9e9110971e1a4d8f013f5f97dd8fb4f430",
          "name": "KING FOREVER",
          "symbol": "KFR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21706/thumb/king200.png?1639747034"
        },
        {
          "chainId": 56,
          "address": "0x24086eab82dbdaa4771d0a5d66b0d810458b0e86",
          "name": "PepeAI",
          "symbol": "PEPEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30248/thumb/pepeai-200.png?1683697762"
        },
        {
          "chainId": 56,
          "address": "0xb2aa453b56b495cb95bc9fa72e7706b84e41ee54",
          "name": "WonderHero  OLD ",
          "symbol": "WND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19260/thumb/wnd.png?1634812369"
        },
        {
          "chainId": 56,
          "address": "0xacb8f52dc63bb752a51186d1c55868adbffee9c1",
          "name": "BunnyPark",
          "symbol": "BP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15382/thumb/8zt0tjy.jpg?1620698176"
        },
        {
          "chainId": 56,
          "address": "0x18d9d0d6e42bb52a13236f4fa33d9d2485d9015a",
          "name": "Shira Cat",
          "symbol": "CATSHIRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29274/thumb/IMG_20230302_120852_281.jpg?1677736994"
        },
        {
          "chainId": 56,
          "address": "0x18b5f22266343ccd180c6285a66cc9a23dc262e9",
          "name": "Evulus",
          "symbol": "EVU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22081/thumb/isotipo-evulus.png?1640759271"
        },
        {
          "chainId": 56,
          "address": "0x311e85452ec46d03d056317b979d444ea717dc7e",
          "name": "Vitteey",
          "symbol": "VITY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24796/thumb/vity.png?1648980069"
        },
        {
          "chainId": 56,
          "address": "0xacf34edcc424128cccc730bf85cdaceebcb3eece",
          "name": "Voice Street",
          "symbol": "VST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg?1643348841"
        },
        {
          "chainId": 56,
          "address": "0x92400f8b8c4658153c10c98500b63ac9f87571c2",
          "name": "Pricetools",
          "symbol": "PTOOLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29334/thumb/Logo_square_200.png?1678173302"
        },
        {
          "chainId": 56,
          "address": "0x566a9eeac9a589bf0825222bca083ecdb9c86c82",
          "name": "Libra Protocol",
          "symbol": "LBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29389/thumb/Libra_Protocol.png?1678424896"
        },
        {
          "chainId": 56,
          "address": "0x17b7163cf1dbd286e262ddc68b553d899b93f526",
          "name": "Qubit",
          "symbol": "QBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17957/thumb/qubit.PNG?1629937058"
        },
        {
          "chainId": 56,
          "address": "0xed66ec1acb7dbd0c01cccff33e3ff1f423057c21",
          "name": "Virtual Tourist",
          "symbol": "VT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25186/thumb/200x.png?1650599907"
        },
        {
          "chainId": 56,
          "address": "0x07b36f2549291d320132712a1e64d3826b1fb4d7",
          "name": "Wifedoge",
          "symbol": "WIFEDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17346/thumb/w_doge_logo200.png?1627365473"
        },
        {
          "chainId": 56,
          "address": "0x24697e20c1921ebd5846c5b025a5fab1a43fe316",
          "name": "Zenithereum",
          "symbol": "ZEN-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28987/thumb/Untitled_design_%2811%29.png?1675819249"
        },
        {
          "chainId": 56,
          "address": "0xe0e514c71282b6f4e823703a39374cf58dc3ea4f",
          "name": "Belt",
          "symbol": "BELT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14319/thumb/belt_logo.jpg?1615387083"
        },
        {
          "chainId": 56,
          "address": "0xb47e21328b4f1c6d685c213d707fab3edb234fa0",
          "name": "beFITTER Health",
          "symbol": "HEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26569/thumb/HEE.png?1658816013"
        },
        {
          "chainId": 56,
          "address": "0x308fc5cdd559be5cb62b08a26a4699bbef4a888f",
          "name": "Decentralized Community Investment Prot",
          "symbol": "DCIP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17274/thumb/DCIP200.png?1627009259"
        },
        {
          "chainId": 56,
          "address": "0x2ff90b0c29ededdaf11c847925ea4a17789e88c3",
          "name": "Ginoa",
          "symbol": "GINOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24033/thumb/gintoken.png?1646117177"
        },
        {
          "chainId": 56,
          "address": "0x42c1613dd2236cfd60b5cbec846b5ead71be99df",
          "name": "Walter Inu",
          "symbol": "WINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23429/thumb/Walter-Bigger-Circle-Logo.jpg?1654070957"
        },
        {
          "chainId": 56,
          "address": "0x2ffee7b4df74f7c6508a4af4d6d91058da5420d0",
          "name": "ChainCade",
          "symbol": "CHAINCADE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17001/thumb/chaincade.png?1637045853"
        },
        {
          "chainId": 56,
          "address": "0x2d77863f3586e1e4e6d91706b6c8a1e1f628b4ad",
          "name": "Based AI",
          "symbol": "BAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29294/thumb/23753.png?1677903691"
        },
        {
          "chainId": 56,
          "address": "0xa5f249f401ba8931899a364d8e2699b5fa1d87a9",
          "name": "Main",
          "symbol": "MAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25258/thumb/logo_circle.png?1651042603"
        },
        {
          "chainId": 56,
          "address": "0x129385c4acd0075e45a0c9a5177bdfec9678a138",
          "name": "Metakings",
          "symbol": "MTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21495/thumb/mtk.png?1639367403"
        },
        {
          "chainId": 56,
          "address": "0x7da07fb98f16c26a6417ca5627719194a6944211",
          "name": "8BITEARN",
          "symbol": "8BIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28908/thumb/WhatsApp_Image_2023-01-18_at_12.38.16.jpeg?1675307617"
        },
        {
          "chainId": 56,
          "address": "0xd6edbb510af7901b2c049ce778b65a740c4aeb7f",
          "name": "Hollywood Capital Group WARRIOR",
          "symbol": "WOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29400/thumb/Name_3.png?1678514724"
        },
        {
          "chainId": 56,
          "address": "0xaecf6d1aff214fef70042740054f0f6d0caa98ab",
          "name": "Baby Shiba Inu",
          "symbol": "BABYSHIBAINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16805/thumb/BabyShiba.png?1625127854"
        },
        {
          "chainId": 56,
          "address": "0x4aa22532e3e8b051eae48e60c58426c8553d5df5",
          "name": "Telegram Inu",
          "symbol": "TINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27085/thumb/TELEGRAMINU_logo.png?1661765748"
        },
        {
          "chainId": 56,
          "address": "0xe1bb77c8e012c1514373a40cfbb8645293075125",
          "name": "Sports Artificial",
          "symbol": "SPORTS-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29196/thumb/IMG_20230220_003747_737.jpg?1677304810"
        },
        {
          "chainId": 56,
          "address": "0x853a8ab1c365ea54719eb13a54d6b22f1fbe7feb",
          "name": "InvestDex",
          "symbol": "INVEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16382/thumb/FINAL_FINAL.png?1624330606"
        },
        {
          "chainId": 56,
          "address": "0x74afe449d1beffc90456cfebd700ab391abd7daf",
          "name": "EG Token",
          "symbol": "EG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29174/thumb/eg.png?1677128137"
        },
        {
          "chainId": 56,
          "address": "0x9bee0c15676a65ef3c8cdb38cb3dd31c675bbd12",
          "name": "BattleVerse",
          "symbol": "BVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23750/thumb/bvc.png?1645338259"
        },
        {
          "chainId": 56,
          "address": "0x3cc20cf966b25be8538a8bc70e53c720c7133f35",
          "name": "Genesis Wink",
          "symbol": "GWINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28772/thumb/g_3d.png?1674095831"
        },
        {
          "chainId": 56,
          "address": "0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef",
          "name": "unshETH Ether",
          "symbol": "UNSHETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30365/thumb/ush.png?1684250737"
        },
        {
          "chainId": 56,
          "address": "0xb99172949554e6c10c28c880ec0306d2a9d5c753",
          "name": "LunaDoge",
          "symbol": "LOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15647/thumb/LunaDoge.png?1621426586"
        },
        {
          "chainId": 56,
          "address": "0x8f2588f8627107b233d65c7b65c93c17ac11c871",
          "name": "Astro Pepe",
          "symbol": "ASTROPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30350/thumb/our_logo.png?1684218378"
        },
        {
          "chainId": 56,
          "address": "0xf729f4d13a9c9556875d20bacf9ebd0bf891464c",
          "name": "BUSDX",
          "symbol": "BUSDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20980/thumb/FgHuTG1.png?1638163513"
        },
        {
          "chainId": 56,
          "address": "0xa3499dd7dbbbd93cb0f8303f8a8ace8d02508e73",
          "name": "Lovelace World",
          "symbol": "LACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20801/thumb/voOmcwrV_400x400.jpg?1637704443"
        },
        {
          "chainId": 56,
          "address": "0x6ae0a238a6f51df8eee084b1756a54dd8a8e85d3",
          "name": "AutoMiningToken",
          "symbol": "AMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28918/thumb/AMT_-_Logo.png?1675392338"
        },
        {
          "chainId": 56,
          "address": "0x7082ff3a22e707136c80a9efcb215ec4c1fda810",
          "name": "Metathings",
          "symbol": "METT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29552/thumb/Mettlogo200x200.png?1679622255"
        },
        {
          "chainId": 56,
          "address": "0xe0e0fbc7e8d881953d39cf899409410b50b8c924",
          "name": "Coin of Nature",
          "symbol": "CON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19834/thumb/logo_coinofnature-200.png?1635991553"
        },
        {
          "chainId": 56,
          "address": "0xbe80ffaab00fc3a6d590959c551bb670bc50c717",
          "name": "Kalkulus",
          "symbol": "KLKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3952/thumb/kalkulus.png?1547975015"
        },
        {
          "chainId": 56,
          "address": "0x9498c4383406a5bdd9100cff3fe0b550a111dd3d",
          "name": "Pepe Coin BSC",
          "symbol": "PPC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30044/thumb/200x200pepe.png?1682672209"
        },
        {
          "chainId": 56,
          "address": "0xbdd2e3fdb879aa42748e9d47b7359323f226ba22",
          "name": "Predictcoin",
          "symbol": "PRED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21684/thumb/pc.png?1639717172"
        },
        {
          "chainId": 56,
          "address": "0x293faca2e5d6ac7a0e704eafeda628528b3b3f0a",
          "name": "LS Coin",
          "symbol": "LSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30084/thumb/logo-200.png?1683017389"
        },
        {
          "chainId": 56,
          "address": "0x4e2434294a722329b6b64e0c2fca51b2533d7015",
          "name": "PEPE FLOKI",
          "symbol": "PEPEF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30195/thumb/logo200.jpg?1683608565"
        },
        {
          "chainId": 56,
          "address": "0x35207068e057a01861a654463c01b04cc111e760",
          "name": "Elo Inu",
          "symbol": "ELOINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21570/thumb/5gLcVCKI.png?1639486080"
        },
        {
          "chainId": 56,
          "address": "0x3271d12d5ba36b6582fafa029598fee0f5f6db35",
          "name": "Lil Floki",
          "symbol": "LILFLOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19198/thumb/lilfloki200x200coingecko.png?1634656630"
        },
        {
          "chainId": 56,
          "address": "0xab15b79880f11cffb58db25ec2bc39d28c4d80d2",
          "name": "StarMon",
          "symbol": "SMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18130/thumb/StarMon.png?1630626408"
        },
        {
          "chainId": 56,
          "address": "0x277ae79c42c859ca858d5a92c22222c8b65c6d94",
          "name": "Astro Babies",
          "symbol": "ABB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28158/thumb/Untitled_design.png?1668162808"
        },
        {
          "chainId": 56,
          "address": "0x54c159b71262878bf096b45a3c6a8fd0a3250b10",
          "name": "Be Meta Famous",
          "symbol": "BMF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25192/thumb/Meta_Fame_Token.png?1650605900"
        },
        {
          "chainId": 56,
          "address": "0xd23811058eb6e7967d9a00dc3886e75610c4abba",
          "name": "KnightSwap",
          "symbol": "KNIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24132/thumb/knightswap.png?1646539967"
        },
        {
          "chainId": 56,
          "address": "0xfafd4cb703b25cb22f43d017e7e0d75febc26743",
          "name": "WEYU",
          "symbol": "WEYU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18112/thumb/weyu.PNG?1630542552"
        },
        {
          "chainId": 56,
          "address": "0xf87940f78f2f4d99a0c5c22e3fcc21795cd53245",
          "name": "Kamaleont",
          "symbol": "KLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26386/thumb/Dise%C3%B1o_sin_t%C3%ADtulo_%288%29.png?1688549888"
        },
        {
          "chainId": 56,
          "address": "0x0f5d54b27bdb556823f96f2536496550f8816dc5",
          "name": "ritestream",
          "symbol": "RITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24130/thumb/rite.png?1674802806"
        },
        {
          "chainId": 56,
          "address": "0x0f6266a9e9214ea129d4a001e9541d643a34c772",
          "name": "OLOID",
          "symbol": "OLOID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25950/thumb/OLOID-COIN-3-200x200.png?1654843124"
        },
        {
          "chainId": 56,
          "address": "0x36e714d63b676236b72a0a4405f726337b06b6e5",
          "name": "Genesis Universe",
          "symbol": "GUT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27065/thumb/token_logo.jpg?1661671814"
        },
        {
          "chainId": 56,
          "address": "0x80f2c1e25391bbe615ef1f5ce82297fb0a624cb7",
          "name": "LULU Market Luck",
          "symbol": "LUCK",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/26619/thumb/lucktoken_200.png?1659073809"
        },
        {
          "chainId": 56,
          "address": "0x52da44b5e584f730005dac8d2d2acbdee44d4ba3",
          "name": "KingdomX",
          "symbol": "KT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23411/thumb/kt.png?1644151409"
        },
        {
          "chainId": 56,
          "address": "0x5f84ce30dc3cf7909101c69086c50de191895883",
          "name": "Venus Reward",
          "symbol": "VRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15734/thumb/vrt-venus-token.png?1621750113"
        },
        {
          "chainId": 56,
          "address": "0x8fc1a944c149762b6b578a06c0de2abd6b7d2b89",
          "name": "Main Street",
          "symbol": "MAINST",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20268/thumb/MainstCoinLogo.png?1669366943"
        },
        {
          "chainId": 56,
          "address": "0x20d39a5130f799b95b55a930e5b7ebc589ea9ed8",
          "name": "Heroes   Empires",
          "symbol": "HE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18903/thumb/he.png?1635385428"
        },
        {
          "chainId": 56,
          "address": "0xe90d1567ecef9282cc1ab348d9e9e2ac95659b99",
          "name": "CoinxPad",
          "symbol": "CXPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18626/thumb/cxpad.png?1659854954"
        },
        {
          "chainId": 56,
          "address": "0x2290c6bd9560e6498dfdf10f9ecb17997ca131f2",
          "name": "Monsterra",
          "symbol": "MSTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24377/thumb/MSTR.png?1660801591"
        },
        {
          "chainId": 56,
          "address": "0x73fc38b104b45da06f4b68782ab75b45d0b225b7",
          "name": "SOWL",
          "symbol": "SOWL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27917/thumb/F4494FF2-2914-49A7-8AE1-49056B82D837.png?1666350560"
        },
        {
          "chainId": 56,
          "address": "0xde009cb3371825bafb80a01004c58f8166ee13d5",
          "name": "Little Ugly Duck",
          "symbol": "LUD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17764/thumb/whitelogo200x200.png?1629203328"
        },
        {
          "chainId": 56,
          "address": "0xddbb3e6f8413d0e3adc700a731da304aec97bcbb",
          "name": "Wednesday Inu",
          "symbol": "WED",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30008/thumb/Wednesday_Inu.png?1682485663"
        },
        {
          "chainId": 56,
          "address": "0xed236c32f695c83efde232c288701d6f9c23e60e",
          "name": "Vertek",
          "symbol": "VRTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28958/thumb/vertek-mark-color-round%282%29.png?1675738383"
        },
        {
          "chainId": 56,
          "address": "0x227a3ef4d41d0215123f3197faa087bf71d2236a",
          "name": "Space Corsair Key",
          "symbol": "SCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24371/thumb/sck.png?1647443091"
        },
        {
          "chainId": 56,
          "address": "0xa776f5b86cc520861f55a261515264e3bd86e72e",
          "name": "Sphynx Labs",
          "symbol": "SPHYNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30142/thumb/photo_2023-05-03_21.44.17.jpeg?1683280938"
        },
        {
          "chainId": 56,
          "address": "0x12da2f2761038486271c99da7e0fb4413e2b5e38",
          "name": "NFTBlackmarket",
          "symbol": "NBM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17606/thumb/200_%289%29.png?1628647597"
        },
        {
          "chainId": 56,
          "address": "0xbbbcb350c64fe974e5c42a55c7070644191823f3",
          "name": "CheersLand",
          "symbol": "CHEERS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23906/thumb/15236.png?1645681595"
        },
        {
          "chainId": 56,
          "address": "0x21f1ce0fcf1e9e39f8e79b7762801e8096d9f6cd",
          "name": "BCPAY FinTech",
          "symbol": "BCPAY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18342/thumb/bcpay.jpg?1631601832"
        },
        {
          "chainId": 56,
          "address": "0x7c38870e93a1f959cb6c533eb10bbc3e438aac11",
          "name": "Alium Finance",
          "symbol": "ALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806"
        },
        {
          "chainId": 56,
          "address": "0x02678125fb30d0fe77fc9d10ea531f8b4348c603",
          "name": "LakeViewMeta",
          "symbol": "LVM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27097/thumb/200x200logo.png?1661999646"
        },
        {
          "chainId": 56,
          "address": "0x996f56299a5b7c4f825a44886e07dafc4660b794",
          "name": "Newdex",
          "symbol": "DEX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7622/thumb/newdex_token.jpg?1548839589"
        },
        {
          "chainId": 56,
          "address": "0xae28714390e95b8df1ef847c58aeac23ed457702",
          "name": "GIBX Swap",
          "symbol": "X",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18412/thumb/x.PNG?1631829219"
        },
        {
          "chainId": 56,
          "address": "0x5ece3f1542c4e1a06767457e4d8286bea772fc41",
          "name": "Porta",
          "symbol": "KIAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14844/thumb/Kianite.png?1626060939"
        },
        {
          "chainId": 56,
          "address": "0x9df90628d40c72f85137e8cee09dde353a651266",
          "name": "Mechaverse",
          "symbol": "MC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27200/thumb/mc.jpeg?1662568257"
        },
        {
          "chainId": 56,
          "address": "0x33678c7b2a58480ef599ce73ad0d002dc6b6f7dc",
          "name": "Pepe CEO",
          "symbol": "PEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29409/thumb/ceo.png?1678517491"
        },
        {
          "chainId": 56,
          "address": "0xe369fec23380f9f14ffd07a1dc4b7c1a9fdd81c9",
          "name": "Froyo Games",
          "symbol": "FROYO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22386/thumb/95039059.png?1641788131"
        },
        {
          "chainId": 56,
          "address": "0x56672ecb506301b1e32ed28552797037c54d36a9",
          "name": "Bismuth",
          "symbol": "BIS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/974/thumb/bis200px.png?1615787741"
        },
        {
          "chainId": 56,
          "address": "0x464863745ed3af8b9f8871f1082211c55f8f884d",
          "name": "CryptoTycoon",
          "symbol": "CTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15882/thumb/ctt.PNG?1622191728"
        },
        {
          "chainId": 56,
          "address": "0x1006ea3289b833b6720aaa82746990ec77de8c36",
          "name": "Digital Bank of Africa",
          "symbol": "DBA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19177/thumb/DBA-icon-black-08-01.jpg?1635159644"
        },
        {
          "chainId": 56,
          "address": "0xec15a508a187e8ddfe572a5423faa82bbdd65120",
          "name": "Babylons",
          "symbol": "BABI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17838/thumb/CW32Ubsk_400x400.jpg?1629462119"
        },
        {
          "chainId": 56,
          "address": "0xe8b4c953a204430a7ce064532d6283c70a609956",
          "name": "Apedoge",
          "symbol": "APED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27899/thumb/photo_2022-10-20_14-01-21.jpg?1666321065"
        },
        {
          "chainId": 56,
          "address": "0xe045fc25581cfdc3cfb5c282501f3cd1a133a7ec",
          "name": "MatrixGPT",
          "symbol": "MAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29160/thumb/200-200.png?1677042662"
        },
        {
          "chainId": 56,
          "address": "0x83adb07bb91ddde95a24982f1b2d343963ba3995",
          "name": "Legends",
          "symbol": "FWCL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28315/thumb/fwcl.png?1669358316"
        },
        {
          "chainId": 56,
          "address": "0xb60501346240fcde1615de56ea9fff1ac1da5673",
          "name": "BSClaunch",
          "symbol": "BSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16044/thumb/Logo_BSCLaunch_Final_Expand-03.png?1622685068"
        },
        {
          "chainId": 56,
          "address": "0xbcbdecf8e76a5c32dba69de16985882ace1678c6",
          "name": "Revenue Coin",
          "symbol": "RVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21625/thumb/RVC_logo_200_200.png?1639625596"
        },
        {
          "chainId": 56,
          "address": "0x888ed27c3ab248868c29dabe3d1b3d7cc5c89c5b",
          "name": "Arbitrum Charts",
          "symbol": "ARCS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29550/thumb/arcs_200.png?1679621539"
        },
        {
          "chainId": 56,
          "address": "0xed1a89fa419e3d1042d4ea2e938fb62a216594c6",
          "name": "Baby Doge CEO",
          "symbol": "BABYCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29406/thumb/200x200.png?1678516422"
        },
        {
          "chainId": 56,
          "address": "0xe2604c9561d490624aa35e156e65e590eb749519",
          "name": "GoldMiner",
          "symbol": "GM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20416/thumb/LWzVDEkR_400x400.jpg?1637016491"
        },
        {
          "chainId": 56,
          "address": "0x29abc4d03d133d8fd1f1c54318428353ce08727e",
          "name": "KyotoSwap",
          "symbol": "KSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29662/thumb/Frame_79.png?1680508424"
        },
        {
          "chainId": 56,
          "address": "0x5fead99998788ac1bca768796483d899f1aef4c4",
          "name": "Jindo Inu",
          "symbol": "JIND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15521/thumb/_POP28D3_400x400.jpg?1621119276"
        },
        {
          "chainId": 56,
          "address": "0xca0823d3d04b9faea7803ccb87fa8596775190dd",
          "name": "Berylbit Layer 3 Network  OLD ",
          "symbol": "BRB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22239/thumb/IMG-20230330-081241-471.jpg?1680230398"
        },
        {
          "chainId": 56,
          "address": "0x04524f05cce069af9bce7879616773a94bb46c84",
          "name": "SCI Coin",
          "symbol": "SCI+",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29680/thumb/Logo_SCIPlus.jpeg?1680666007"
        },
        {
          "chainId": 56,
          "address": "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
          "name": "Binance Peg XRP",
          "symbol": "XRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15458/thumb/ryyrCapt_400x400.jpg?1620895978"
        },
        {
          "chainId": 56,
          "address": "0x5f320c3b8f82acfe8f2bb1c85d63aa66a7ff524f",
          "name": "Nelore Coin",
          "symbol": "NLC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26208/thumb/NLC.jpg?1656554961"
        },
        {
          "chainId": 56,
          "address": "0xd10332818d6a9b4b84bf5d87dbf9d80012fdf913",
          "name": "MarhabaDeFi",
          "symbol": "MRHB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18613/thumb/MRHB_DeFi-02.png?1632670864"
        },
        {
          "chainId": 56,
          "address": "0x641a6dc991a49f7be9fe3c72c5d0fbb223edb12f",
          "name": "Realfinance Network",
          "symbol": "REFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15194/thumb/high.png?1620082636"
        },
        {
          "chainId": 56,
          "address": "0xa0a9961b7477d1a530f06a1ee805d5e532e73d97",
          "name": "Ariadne",
          "symbol": "ARDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19401/thumb/Frame_5_%287%29_%281%29.png?1635176608"
        },
        {
          "chainId": 56,
          "address": "0x178fbe1cf4775fbdb9756d6349195a05799c0fe5",
          "name": "Espento",
          "symbol": "SPENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29818/thumb/espento.png?1681437937"
        },
        {
          "chainId": 56,
          "address": "0x16dcc0ec78e91e868dca64be86aec62bf7c61037",
          "name": "EverETH",
          "symbol": "EVERETH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18221/thumb/200x200.png?1631007784"
        },
        {
          "chainId": 56,
          "address": "0x14f1ec1ba0f8a8e9a3b8178c9dcc32155e82c70b",
          "name": "KAIF",
          "symbol": "KAF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29842/thumb/kaif-cmcLogo200x200.png?1681721981"
        },
        {
          "chainId": 56,
          "address": "0xdc3541806d651ec79ba8639a1b495acf503eb2dd",
          "name": "Metoshi",
          "symbol": "METO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22097/thumb/logo200x200.png?1640796397"
        },
        {
          "chainId": 56,
          "address": "0x0c1253a30da9580472064a91946c5ce0c58acf7f",
          "name": "Titan Hunters",
          "symbol": "TITA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20588/thumb/tita.png?1638343393"
        },
        {
          "chainId": 56,
          "address": "0x639fc0c006bd7050e2c359295b41a79cb28694ba",
          "name": "Gunstar Metaverse Currency",
          "symbol": "GSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23258/thumb/GSC.png?1643360904"
        },
        {
          "chainId": 56,
          "address": "0x934b0633f4874ca9340341ad66ff2f6ce3124b4c",
          "name": "Sangkara",
          "symbol": "MISA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25360/thumb/misa.png?1651314232"
        },
        {
          "chainId": 56,
          "address": "0xce1b3e5087e8215876af976032382dd338cf8401",
          "name": "Thoreum V3",
          "symbol": "THOREUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22759/thumb/Thoreum_V3_logo_200x200.png?1658885596"
        },
        {
          "chainId": 56,
          "address": "0xd3b77ac07c963b8cead47000a5208434d9a8734d",
          "name": "Safetrees",
          "symbol": "TREES",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16202/thumb/ST_newLogo_200x.png?1649045576"
        },
        {
          "chainId": 56,
          "address": "0x9a2478c4036548864d96a97fbf93f6a3341fedac",
          "name": "Zillion Aakar XO",
          "symbol": "ZILLIONXO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26515/thumb/tg_image_4289710331.jpeg?1658450397"
        },
        {
          "chainId": 56,
          "address": "0x71e80e96af604afc23ca2aed4c1c7466db6dd0c4",
          "name": "Baby Floki",
          "symbol": "BABYFLOKI",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/18436/thumb/9FkEA4bN.png?1631959196"
        },
        {
          "chainId": 56,
          "address": "0xfecca80ff6deb2b492e93df3b67f0c523cfd3a48",
          "name": "AlgoBlocks",
          "symbol": "ALGOBLK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25120/thumb/tncthV9K_400x400.jpg?1650370062"
        },
        {
          "chainId": 56,
          "address": "0xf7f0dc9fd88e436847580d883319137ec2aa6b94",
          "name": "Parma Calcio 1913 Fan Token",
          "symbol": "PARMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29258/thumb/parma-token%28200x200%29.png?1677654299"
        },
        {
          "chainId": 56,
          "address": "0x2189455051a1c1e6190276f84f73ec6fb2fe62df",
          "name": "BitConey",
          "symbol": "BITCONEY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28672/thumb/BITCONEY_TOKEN_LOGO-200x200.png?1673075122"
        },
        {
          "chainId": 56,
          "address": "0xb6090a50f66046e3c6afb9311846a6432e45060a",
          "name": "PinkMoon",
          "symbol": "PINKM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15018/thumb/pink-logo-200.png?1619465255"
        },
        {
          "chainId": 56,
          "address": "0x9521728bf66a867bc65a93ece4a543d817871eb7",
          "name": "Creo Engine",
          "symbol": "CREO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25419/thumb/17000.png?1651736080"
        },
        {
          "chainId": 56,
          "address": "0xb0cb8dd3b2aa9558ba632a350e242f58d2e289b0",
          "name": "X MASK",
          "symbol": "XMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28348/thumb/200.png?1689582748"
        },
        {
          "chainId": 56,
          "address": "0xb6b91269413b6b99242b1c0bc611031529999999",
          "name": "Calo",
          "symbol": "CALO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21875/thumb/CALO-Token_200x200.png?1649846375"
        },
        {
          "chainId": 56,
          "address": "0x01e0d17a533e5930a349c2bb71304f04f20ab12b",
          "name": "Revolve Games",
          "symbol": "RPG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18481/thumb/HQ3.png?1632150968"
        },
        {
          "chainId": 56,
          "address": "0xa9d75cc3405f0450955050c520843f99aff8749d",
          "name": "Warena",
          "symbol": "RENA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18888/thumb/RENA_Web_Black_Square.png?1633748637"
        },
        {
          "chainId": 56,
          "address": "0xc0dc5adfae1dada9111f376810d772cabd9b6f13",
          "name": "CPOS Cloud Payment",
          "symbol": "CPOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19229/thumb/cpos.PNG?1634765828"
        },
        {
          "chainId": 56,
          "address": "0xc841780c34c17190bceeefb6d986aaca4fb95e31",
          "name": "Alterna Network",
          "symbol": "ALTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30087/thumb/alternalogo.png?1683018882"
        },
        {
          "chainId": 56,
          "address": "0xe665dd6b4a2af39fadbee280f005df78dab3c7e2",
          "name": "MDCx",
          "symbol": "MDCX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29114/thumb/FlD2hQPWQAAtbTb.png?1676705662"
        },
        {
          "chainId": 56,
          "address": "0xeca88125a5adbe82614ffc12d0db554e2e2867c8",
          "name": "Venus USDC",
          "symbol": "VUSDC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13906/thumb/usdc.png?1612799563"
        },
        {
          "chainId": 56,
          "address": "0xb52575ce47c4534f7b0ba88acfba7546350197d8",
          "name": "Perry The BNB",
          "symbol": "PERRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30207/thumb/009836CD-8341-4057-BAB9-C01AD6BC6166.png?1683618125"
        },
        {
          "chainId": 56,
          "address": "0x6cf271270662be1c4fc1b7bb7d7d7fc60cc19125",
          "name": "Kunci Coin",
          "symbol": "KUNCI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/23723/thumb/xZX34B26_400x400.jpg?1645161855"
        },
        {
          "chainId": 56,
          "address": "0xa1611e8d4070dee36ef308952cf34255c92a01c5",
          "name": "Poochain",
          "symbol": "POOP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26790/thumb/poochain_1.png?1660103857"
        },
        {
          "chainId": 56,
          "address": "0x2e7f28f0d27ffa238fdf7517a3bbe239b8189741",
          "name": "HoppyInu",
          "symbol": "HOPPYINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29209/thumb/HOPPYINU_200x200.png?1677390890"
        },
        {
          "chainId": 56,
          "address": "0xf68d4d917592f3a62417ace42592f15296cc33a0",
          "name": "COINHUB",
          "symbol": "CHB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26818/thumb/a08jRqRh_400x400.jpg?1687342978"
        },
        {
          "chainId": 56,
          "address": "0xf20f2ad6a36e9a4f585755aceb0da750de80ed4e",
          "name": "Babyrabbit",
          "symbol": "BABYRABBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28537/thumb/Babyrabbit.jpeg?1671511436"
        },
        {
          "chainId": 56,
          "address": "0x7764bdfc4ab0203a7d4e3edf33b181f395458870",
          "name": "Cryptegrity Dao",
          "symbol": "ESCROW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29220/thumb/IMG_20230220_132937_102_%281%29.png?1677466862"
        },
        {
          "chainId": 56,
          "address": "0x98d4c60deea495c94d50d162d331278658a6b681",
          "name": "CryptHub",
          "symbol": "CRHT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30744/thumb/IMG_8697.PNG?1687342676"
        },
        {
          "chainId": 56,
          "address": "0xa7751516e06e1715264306a51437774bf94266dc",
          "name": "Billiard Crypto",
          "symbol": "BIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28635/thumb/200x200_%283%29.png?1672824553"
        },
        {
          "chainId": 56,
          "address": "0x39703a67bac0e39f9244d97f4c842d15fbad9c1f",
          "name": "The Three Kingdoms",
          "symbol": "TTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18948/thumb/TTK_-_Square_Icon_01.png?1634008903"
        },
        {
          "chainId": 56,
          "address": "0xc4736f2611a62d545dc2d0d8f0766283617e6fcb",
          "name": "TopGoal",
          "symbol": "GOAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29229/thumb/YRDnlXib_400x400.jpg?1677496052"
        },
        {
          "chainId": 56,
          "address": "0xe792f64c582698b8572aaf765bdc426ac3aefb6b",
          "name": "SWG",
          "symbol": "SWG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16177/thumb/swf.PNG?1623208094"
        },
        {
          "chainId": 56,
          "address": "0x28ce223853d123b52c74439b10b43366d73fd3b5",
          "name": "Fame MMA",
          "symbol": "FAME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25267/thumb/FJTwuXxE_400x400.jpg?1651066664"
        },
        {
          "chainId": 56,
          "address": "0xde27c2c13d1eeb87744bf3c2a9168c1cbd62ee81",
          "name": "Viddli",
          "symbol": "MTY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29689/thumb/500__px.png?1680668630"
        },
        {
          "chainId": 56,
          "address": "0x166295ebd6a938c7aaf61350eb5161a9939ab2b7",
          "name": "Murasaki",
          "symbol": "MURA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28570/thumb/mura.png?1671795851"
        },
        {
          "chainId": 56,
          "address": "0xdc49d53330317cbc6924fa53042e0c9bca0a8d63",
          "name": "DOGEDI",
          "symbol": "DOGEDI",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/21334/thumb/dogedi.png?1638948569"
        },
        {
          "chainId": 56,
          "address": "0x298632d8ea20d321fab1c9b473df5dbda249b2b6",
          "name": "World of Defish",
          "symbol": "WOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22126/thumb/Logo.jpg?1640869389"
        },
        {
          "chainId": 56,
          "address": "0x92dd5b17bdacbbe3868a09be5a3df93032c29ddb",
          "name": "Kubic",
          "symbol": "KUBIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16895/thumb/kubic.png?1636253785"
        },
        {
          "chainId": 56,
          "address": "0xd9a44c40584288505931880c9915c6a5eb5f2fb1",
          "name": "TypeIt",
          "symbol": "TYPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30962/thumb/Type-It-logo-200-x-200.png?1689222093"
        },
        {
          "chainId": 56,
          "address": "0x4444a19c8bb86e9bdbc023709a363bbce91af33e",
          "name": "CryptoTanks",
          "symbol": "TANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21966/thumb/LN-MRxWg_400x400.jpg?1640531630"
        },
        {
          "chainId": 56,
          "address": "0xa2f89a3be1bada5eb9d58d23edc2e2fe0f82f4b0",
          "name": "Option Panda Platform",
          "symbol": "OPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16435/thumb/logo.jpg?1624018811"
        },
        {
          "chainId": 56,
          "address": "0x9fb677928dd244befcd0bbebdf6068dd4bef559c",
          "name": "Bela Aqua",
          "symbol": "AQUA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15566/thumb/d5bnQ0H.png?1625578396"
        },
        {
          "chainId": 56,
          "address": "0xaa9e582e5751d703f85912903bacaddfed26484c",
          "name": "Hacken",
          "symbol": "HAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11081/thumb/hacken-symbol-with-bg.png?1676512641"
        },
        {
          "chainId": 56,
          "address": "0x63bc9770ea9a2f21df6cc1224d64d8dec9c61a74",
          "name": "Popcoin",
          "symbol": "POP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28160/thumb/Popcoin-logo-200x200.png?1668163551"
        },
        {
          "chainId": 56,
          "address": "0x22168882276e5d5e1da694343b41dd7726eeb288",
          "name": "WallStreetBets DApp",
          "symbol": "WSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15093/thumb/Pe1mrDu.png?1629882480"
        },
        {
          "chainId": 56,
          "address": "0xe0f7c8682f865b417aeb80bf237025b4cb5ebaef",
          "name": "SatoshiSwap",
          "symbol": "SWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27298/thumb/7q2EUICG_400x400.jpeg?1663209691"
        },
        {
          "chainId": 56,
          "address": "0xdb0170e2d0c1cc1b2e7a90313d9b9afa4f250289",
          "name": "ADAPad",
          "symbol": "ADAPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg?1631181091"
        },
        {
          "chainId": 56,
          "address": "0xe580074a10360404af3abfe2d524d5806d993ea3",
          "name": "PayBolt",
          "symbol": "PAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269"
        },
        {
          "chainId": 56,
          "address": "0x1a3057027032a1af433f6f596cab15271e4d8196",
          "name": "Yellow Road",
          "symbol": "ROAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14747/thumb/yellowroad.PNG?1618093885"
        },
        {
          "chainId": 56,
          "address": "0x95a1199eba84ac5f19546519e287d43d2f0e1b41",
          "name": "Rabbit Finance",
          "symbol": "RABBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16240/thumb/Rabbit_Finance_Logo1.png?1623372426"
        },
        {
          "chainId": 56,
          "address": "0x7fa7df4996ac59f398476892cfb195ed38543520",
          "name": "WagyuSwap",
          "symbol": "WAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18225/thumb/maoZ8h22_400x400.jpg?1631017339"
        },
        {
          "chainId": 56,
          "address": "0x17179f506b89d80291b581f200f34b17ff172cd3",
          "name": "Ixirswap",
          "symbol": "IXIR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23884/thumb/CMC_CG_%282%29.png?1645601643"
        },
        {
          "chainId": 56,
          "address": "0x5dc2085fe510bbaaba2119d71b09c25098caca3f",
          "name": "RYI Unity",
          "symbol": "RYIU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14512/thumb/4.png?1679725611"
        },
        {
          "chainId": 56,
          "address": "0xc669a70e0b3d07e3514afd97ebfb3d134077a4a1",
          "name": "PIAS",
          "symbol": "PIAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28080/thumb/41DF3535-B5AA-44E0-994D-ABC56B3DE2F4.png?1667396562"
        },
        {
          "chainId": 56,
          "address": "0x650b940a1033b8a1b1873f78730fcfc73ec11f1f",
          "name": "Venus LINK",
          "symbol": "VLINK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13923/thumb/vlink.png?1612845463"
        },
        {
          "chainId": 56,
          "address": "0xd3253fc0a42e6dcf4f66ab147f628e3f29e9b214",
          "name": "Sandwich Network",
          "symbol": "SANDWICH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23562/thumb/Sandwich_Token_Logo.png?1644474250"
        },
        {
          "chainId": 56,
          "address": "0x29a63f4b209c29b4dc47f06ffa896f32667dad2c",
          "name": "Pundi X PURSE",
          "symbol": "PURSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20419/thumb/HobiV7k__400x400.jpg?1637018086"
        },
        {
          "chainId": 56,
          "address": "0xafe3321309a994831884fc1725f4c3236ac79f76",
          "name": "Memeflate",
          "symbol": "MFLATE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19598/thumb/mflate.PNG?1635489960"
        },
        {
          "chainId": 56,
          "address": "0x1ae369a6ab222aff166325b7b87eb9af06c86e57",
          "name": "Tenset",
          "symbol": "10SET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14629/thumb/PNG_Tenset_Sign-back_RGB.png?1674442519"
        },
        {
          "chainId": 56,
          "address": "0x2456493e757fdeedf569781f053998a72adfad03",
          "name": "Definder Network",
          "symbol": "DNT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/2073/thumb/2aSL-Tvl_400x400.jpeg?1654659741"
        },
        {
          "chainId": 56,
          "address": "0xa38898a4ae982cb0131104a6746f77fa0da57aaa",
          "name": "Project Quantum",
          "symbol": "QBIT",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/15773/thumb/quantum200.png?1621834749"
        },
        {
          "chainId": 56,
          "address": "0xc0eff7749b125444953ef89682201fb8c6a917cd",
          "name": "Horizon Protocol",
          "symbol": "HZN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14795/thumb/horizon-logo-200-cg.png?1618475792"
        },
        {
          "chainId": 56,
          "address": "0xa3a7f7ccc7b3f5be5828f92cc33cf5cafb027443",
          "name": "PlanetCats",
          "symbol": "CATCOIN",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/28404/thumb/logo_coingecko.png?1670320428"
        },
        {
          "chainId": 56,
          "address": "0xc8a11f433512c16ed895245f34bcc2ca44eb06bd",
          "name": "Kissan",
          "symbol": "KSN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26414/thumb/200x200-2.png?1657925697"
        },
        {
          "chainId": 56,
          "address": "0xfa139cc2f5c5b8c72309be8e63c3024d03b7e63c",
          "name": "Genie Protocol",
          "symbol": "GNP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18753/thumb/colori.png?1633322631"
        },
        {
          "chainId": 56,
          "address": "0x20de22029ab63cf9a7cf5feb2b737ca1ee4c82a6",
          "name": "Tranchess",
          "symbol": "CHESS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16964/thumb/logo_transparent_bg_200x200.png?1625800982"
        },
        {
          "chainId": 56,
          "address": "0xa896928209881bcf9fc363210473bb10eb0c0a10",
          "name": "NEWTOWNGAMING",
          "symbol": "NTG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26778/thumb/IMG_20220809_093430_348.jpg?1660098829"
        },
        {
          "chainId": 56,
          "address": "0xadf6d29572af16de3c44e6b89d2d8e0380044fa6",
          "name": "NXD Next",
          "symbol": "NXDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27578/thumb/nxdt.png?1664695725"
        },
        {
          "chainId": 56,
          "address": "0xdd80c9625e13db655840ed47af90cc78702367ed",
          "name": "Pepelon",
          "symbol": "PEPELON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30394/thumb/logo.png?1684314832"
        },
        {
          "chainId": 56,
          "address": "0x724a32dfff9769a0a0e1f0515c0012d1fb14c3bd",
          "name": "Superpower Squad",
          "symbol": "SQUAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28466/thumb/SQUAD200X200.png?1670912726"
        },
        {
          "chainId": 56,
          "address": "0x2789604fe261adc1aed3927f41277d8bffe33c36",
          "name": "Catchy",
          "symbol": "CATCHY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20252/thumb/Catchy-200x200.png?1636701403"
        },
        {
          "chainId": 56,
          "address": "0x02b84f8b64e6c67ae6c797ff4ec4efb06ff19b44",
          "name": "Bitcoin Legend",
          "symbol": "BCL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27932/thumb/DjB0X_rP_400x400.jpeg?1666775010"
        },
        {
          "chainId": 56,
          "address": "0xba26397cdff25f0d26e815d218ef3c77609ae7f1",
          "name": "Lyptus",
          "symbol": "LYPTUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15992/thumb/logo_-_2021-06-01T193417.635.png?1622547404"
        },
        {
          "chainId": 56,
          "address": "0x44f0e42ea6fd05f8fc5a03697438487d04632dc5",
          "name": "PayBit",
          "symbol": "PAYBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28671/thumb/photo_2022-12-29_14-10-52.jpg?1673009620"
        },
        {
          "chainId": 56,
          "address": "0xa89bf95c5f15a847c8eb8d348cd7fed719ad7d80",
          "name": "Chat AI",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29441/thumb/200.png?1678865520"
        },
        {
          "chainId": 56,
          "address": "0x339fe932809e39a95b621a7f88bbf6c08eb6c978",
          "name": "Txbit",
          "symbol": "TXBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21122/thumb/Ft4mjdHu_400x400.jpg?1638342134"
        },
        {
          "chainId": 56,
          "address": "0x9d70a3ee3079a6fa2bb16591414678b7ad91f0b5",
          "name": "MemePad",
          "symbol": "MEPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15141/thumb/1619694441492-Memepad_coin.png?1619779985"
        },
        {
          "chainId": 56,
          "address": "0x1fd991fb6c3102873ba68a4e6e6a87b3a5c10271",
          "name": "Atlantis Loans",
          "symbol": "ATL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19362/thumb/atl.png?1635209629"
        },
        {
          "chainId": 56,
          "address": "0xa4e068e2b8f58e6c3090174a9e4e95f5bb025ca4",
          "name": "Babydoge2 0",
          "symbol": "BABYDOGE20",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30921/thumb/babydoge_%286%29.png?1688958716"
        },
        {
          "chainId": 56,
          "address": "0x5a04565ee1c90c84061ad357ae9e2f1c32d57dc6",
          "name": "BabyBNBTiger",
          "symbol": "BABYBNBTIG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29709/thumb/IMG_20230405_051743_609.png?1680766713"
        },
        {
          "chainId": 56,
          "address": "0xb27adaffb9fea1801459a1a81b17218288c097cc",
          "name": "PooCoin",
          "symbol": "POOCOIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14855/thumb/w4_9ayk3_400x400.png?1618802191"
        },
        {
          "chainId": 56,
          "address": "0x26d6e280f9687c463420908740ae59f712419147",
          "name": "BakeryTools",
          "symbol": "TBAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15184/thumb/tbakelogo-1.png?1620034899"
        },
        {
          "chainId": 56,
          "address": "0x02a9d7162bd73c2b35c5cf6cdd585e91928c850a",
          "name": "Baby Floki Inu",
          "symbol": "BFLOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16759/thumb/floki.PNG?1624934064"
        },
        {
          "chainId": 56,
          "address": "0x0e2b41ea957624a314108cc4e33703e9d78f4b3c",
          "name": "Greenheart CBD",
          "symbol": "CBD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15284/thumb/GreenHeart-CBD-Logo-200x200px.png?1622197407"
        },
        {
          "chainId": 56,
          "address": "0x121235cff4c59eec80b14c1d38b44e7de3a18287",
          "name": "DarkShield",
          "symbol": "DKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21737/thumb/dks.jpg?1639971988"
        },
        {
          "chainId": 56,
          "address": "0xfce3efebc79d21a6b7cc7f3a7216e66e81f4b23b",
          "name": "Meta World Game",
          "symbol": "MTW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22361/thumb/IMG-20220107-151227-500.jpg?1641565323"
        },
        {
          "chainId": 56,
          "address": "0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb",
          "name": "SafePal",
          "symbol": "SFP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13905/thumb/sfp.png?1660381192"
        },
        {
          "chainId": 56,
          "address": "0x405ef490862ad5fb2d80995b35459594290a7aa9",
          "name": "Green Life Energy",
          "symbol": "GLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20350/thumb/gnl.jpeg?1636943243"
        },
        {
          "chainId": 56,
          "address": "0x4a1ad6a5aee1915c5bc0104bd7e2671ed37aaf0e",
          "name": "GreenDex",
          "symbol": "GED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30093/thumb/photo_2023-04-14_23-00-33.jpg?1683023195"
        },
        {
          "chainId": 56,
          "address": "0x4a824ee819955a7d769e03fe36f9e0c3bd3aa60b",
          "name": "Kabosu",
          "symbol": "KABOSU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15396/thumb/o7LLTW8.png?1620713528"
        },
        {
          "chainId": 56,
          "address": "0xa645995e9801f2ca6e2361edf4c2a138362bade4",
          "name": "Sell Token",
          "symbol": "SELLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29972/thumb/IMG_20230423_185116_124.png?1682395234"
        },
        {
          "chainId": 56,
          "address": "0x71b87be9ccbabe4f393e809dfc26df3c9720e0a2",
          "name": "T mac DAO",
          "symbol": "TMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28117/thumb/tmg.png?1667565644"
        },
        {
          "chainId": 56,
          "address": "0x9e6b3e35c8f563b45d864f9ff697a144ad28a371",
          "name": "DogemonGo",
          "symbol": "DOGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17480/thumb/dogemongo.PNG?1627950869"
        },
        {
          "chainId": 56,
          "address": "0x3a766862fac4fc0d971de3836dfcc99b03f6cedd",
          "name": "Totocat",
          "symbol": "TOTOCAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29419/thumb/logo_hd.png?1678677666"
        },
        {
          "chainId": 56,
          "address": "0x9abdba20edfba06b782126b4d8d72a5853918fd0",
          "name": "Taboo",
          "symbol": "TABOO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/6111/thumb/taboo.PNG?1624500776"
        },
        {
          "chainId": 56,
          "address": "0x23ce9e926048273ef83be0a3a8ba9cb6d45cd978",
          "name": "Mines of Dalarnia",
          "symbol": "DAR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223"
        },
        {
          "chainId": 56,
          "address": "0xca8a893a7464e82bdee582017c749b92e5b45b48",
          "name": "MetaWorld",
          "symbol": "MW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21918/thumb/8QIPjNO.png?1640266083"
        },
        {
          "chainId": 56,
          "address": "0x31d0a7ada4d4c131eb612db48861211f63e57610",
          "name": "Starter xyz",
          "symbol": "START",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386"
        },
        {
          "chainId": 56,
          "address": "0xb661f4576d5e0b622fee6ab041fd5451fe02ba4c",
          "name": "Defigram",
          "symbol": "DFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25162/thumb/19590.png?1650519619"
        },
        {
          "chainId": 56,
          "address": "0xb84cbbf09b3ed388a45cd875ebba41a20365e6e7",
          "name": "BitShiba",
          "symbol": "SHIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20205/thumb/3g2LGTkS_400x400.jpg?1636624175"
        },
        {
          "chainId": 56,
          "address": "0xc6759a4fc56b3ce9734035a56b36e8637c45b77e",
          "name": "Grimace Coin",
          "symbol": "GRIMACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24207/thumb/ustF4Y_V_400x400.jpg?1646863015"
        },
        {
          "chainId": 56,
          "address": "0xc029a12e4a002c6858878fd9d3cc74e227cc2dda",
          "name": "Velorex",
          "symbol": "VEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15793/thumb/vex.png?1637824735"
        },
        {
          "chainId": 56,
          "address": "0x9ce116224459296abc7858627abd5879514bc629",
          "name": "Clash of Lilliput",
          "symbol": "COL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26070/thumb/col.png?1655540086"
        },
        {
          "chainId": 56,
          "address": "0xba7e020d5a463f29535b35137ccb4aa6f4359272",
          "name": "PulseFolio",
          "symbol": "PULSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29664/thumb/our_logo.png?1680531674"
        },
        {
          "chainId": 56,
          "address": "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
          "name": "Binance Peg Filecoin",
          "symbol": "FIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15776/thumb/filecoin.png?1621835224"
        },
        {
          "chainId": 56,
          "address": "0xf8acf86194443dcde55fc5b9e448e183c290d8cb",
          "name": "ColossusXT",
          "symbol": "COLX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/966/thumb/colossusxt.png?1547034843"
        },
        {
          "chainId": 56,
          "address": "0xddc0dbd7dc799ae53a98a60b54999cb6ebb3abf0",
          "name": "SafeBlast",
          "symbol": "BLAST",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png?1621561206"
        },
        {
          "chainId": 56,
          "address": "0x29cabf2a1e5de6f0ebc39ca6fe83c687fe90fb6c",
          "name": "Fish Crypto",
          "symbol": "FICO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22627/thumb/fico.png?1642336838"
        },
        {
          "chainId": 56,
          "address": "0x9eeb6c5ff183e6001c65a12d70026b900ae76781",
          "name": "Irena Coin Apps",
          "symbol": "IRENA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24507/thumb/Irena_coin_apps.png?1683517703"
        },
        {
          "chainId": 56,
          "address": "0xb1a1d06d42a43a8fcfdc7fdcd744f7ef03e8ad1a",
          "name": "HongKongDAO",
          "symbol": "HKD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29871/thumb/IMG_20230418_130235_323.png?1681894376"
        },
        {
          "chainId": 56,
          "address": "0x518afa06aeca8dd0946b89a565e51f5a91d81176",
          "name": "CRB Coin",
          "symbol": "CRB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19399/thumb/crb.png?1635158925"
        },
        {
          "chainId": 56,
          "address": "0x1c310cd730d36c0b34c36bd881cd3cbfac6f17e5",
          "name": "zkSVM",
          "symbol": "ZKSVM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29853/thumb/zkSVM_v.png?1681802257"
        },
        {
          "chainId": 56,
          "address": "0x2abdb5903171071ac29cc0779d7efdf0faf14228",
          "name": "TruthGPT  BSC ",
          "symbol": "TRUTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29885/thumb/LOGO.png?1681980561"
        },
        {
          "chainId": 56,
          "address": "0x3e89a5e7cf9dfcff5a9aedf19ab6246c6b506582",
          "name": "SquadFund",
          "symbol": "SQF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29904/thumb/squad_fund_3_copy_7.png?1681985815"
        },
        {
          "chainId": 56,
          "address": "0xf3f3d7f713df0447e9595d9b830a5f00297070e4",
          "name": "Mother Earth",
          "symbol": "MOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22205/thumb/5NKM1gkZ_400x400.jpg?1641194152"
        },
        {
          "chainId": 56,
          "address": "0xfcb520b47f5601031e0eb316f553a3641ff4b13c",
          "name": "LizardToken Finance",
          "symbol": "LIZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23570/thumb/download_%2847%29.png?1644475361"
        },
        {
          "chainId": 56,
          "address": "0x8850d2c68c632e3b258e612abaa8fada7e6958e5",
          "name": "Pig Finance",
          "symbol": "PIG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14338/thumb/pig.png?1638345420"
        },
        {
          "chainId": 56,
          "address": "0x02ff5065692783374947393723dba9599e59f591",
          "name": "YooShi",
          "symbol": "YOOSHI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15614/thumb/logo.png?1622520684"
        },
        {
          "chainId": 56,
          "address": "0xa5331bb3a3f1e1cb98ba8160176569ac0a80e61d",
          "name": "Cats Coin  BSC ",
          "symbol": "CTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29123/thumb/cats_logo.png?1676788225"
        },
        {
          "chainId": 56,
          "address": "0x86a53fcd199212fea44fa7e16eb1f28812be911d",
          "name": "Inflation Hedging Coin",
          "symbol": "IHC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18835/thumb/imgonline-com-ua-Resize-QspvgKzsspQpANbd.png?1642665432"
        },
        {
          "chainId": 56,
          "address": "0x3c45a24d36ab6fc1925533c1f57bc7e1b6fba8a4",
          "name": "OptionRoom",
          "symbol": "ROOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13889/thumb/option_room_logo.png?1612518313"
        },
        {
          "chainId": 56,
          "address": "0xb452bc9cead0b08c4ef99da0feb8e10ef6bb86bb",
          "name": "EZZY Game",
          "symbol": "EZY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28250/thumb/ezzy_app_icon.png?1668670666"
        },
        {
          "chainId": 56,
          "address": "0x2a45a892877ef383c5fc93a5206546c97496da9e",
          "name": "X AI",
          "symbol": "X",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31116/thumb/logo_200px.png?1690534245"
        },
        {
          "chainId": 56,
          "address": "0x54e951e513bc09abaff971641947bc69e31068bd",
          "name": "OdysseyWallet",
          "symbol": "ODYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28944/thumb/2023-01-23_00.04.47.jpg?1675570254"
        },
        {
          "chainId": 56,
          "address": "0xc99a0ad9fb77e74dda20ac805223b760ad3bdfd5",
          "name": "Myteamcoin",
          "symbol": "MYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16041/thumb/200x2001.png?1622683811"
        },
        {
          "chainId": 56,
          "address": "0x8cf8238abf7b933bf8bb5ea2c7e4be101c11de2a",
          "name": "XP Network",
          "symbol": "XPNET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18363/thumb/200.png?1646363211"
        },
        {
          "chainId": 56,
          "address": "0x0b3f42481c228f70756dbfa0309d3ddc2a5e0f6a",
          "name": "UltraSafe",
          "symbol": "ULTRA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15503/thumb/YjaL3nG.png?1621086488"
        },
        {
          "chainId": 56,
          "address": "0x1236a887ef31b4d32e1f0a2b5e4531f52cec7e75",
          "name": "GAMI World",
          "symbol": "GAMI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/19841/thumb/GAMI_LOGO_200x200.png?1679725601"
        },
        {
          "chainId": 56,
          "address": "0x25c30340e6f9f6e521827cf03282943da00c0ece",
          "name": "Pi Protocol",
          "symbol": "PIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27476/thumb/LOGO_%281%29.png?1664197877"
        },
        {
          "chainId": 56,
          "address": "0x9ab70e92319f0b9127df78868fd3655fb9f1e322",
          "name": "WeWay",
          "symbol": "WWY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22418/thumb/wwy.png?1661499324"
        },
        {
          "chainId": 56,
          "address": "0x48077400faf11183c043feb5184a13ea628bb0db",
          "name": "Coinzix Token",
          "symbol": "ZIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27886/thumb/logo_coinzix_200x200_transparent_png.png?1666239312"
        },
        {
          "chainId": 56,
          "address": "0x9528b1166381fe60f24a952315a3e528a56407a0",
          "name": "Optimism Doge",
          "symbol": "ODOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29790/thumb/photo_2023-04-08_11-51-58.jpg?1681291322"
        },
        {
          "chainId": 56,
          "address": "0xb2cb83e8e1b326373b7f1068d10c50ebfa04f070",
          "name": "Pepito",
          "symbol": "PEPI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30062/thumb/IMG_20230428_101018_444.png?1683002169"
        },
        {
          "chainId": 56,
          "address": "0x2a374d02e244aaa175b38ba1ba9ee443d20e7e41",
          "name": "Peach Inu  BSC ",
          "symbol": "PEACH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29786/thumb/20230410_202521.png?1681287995"
        },
        {
          "chainId": 56,
          "address": "0xd6bf5a972e0c18d38f3a5e132880e39e6871f8a2",
          "name": "Oscarswap",
          "symbol": "OSCAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30724/thumb/oscar_swap_post_10_june_2023_o-03.png?1686633862"
        },
        {
          "chainId": 56,
          "address": "0x08bd7f9849f8eec12fd78c9fed6ba4e47269e3d5",
          "name": "BabyDot",
          "symbol": "BDOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17213/thumb/bdot.PNG?1626854166"
        },
        {
          "chainId": 56,
          "address": "0x37e5da11b6a4dc6d2f7abe232cdd30b0b8fc63b1",
          "name": "Bull BTC Club",
          "symbol": "BBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28030/thumb/bbc.png?1667048849"
        },
        {
          "chainId": 56,
          "address": "0xcc7a91413769891de2e9ebbfc96d2eb1874b5760",
          "name": "GovWorld",
          "symbol": "GOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22261/thumb/govworld.PNG?1641335263"
        },
        {
          "chainId": 56,
          "address": "0xc45c56bf1aaf119a3c266f97bb28bf19646d0b1d",
          "name": "Self Token",
          "symbol": "SELF",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/29857/thumb/Self-Token-logo-yellow-200x200.png?1681867689"
        },
        {
          "chainId": 56,
          "address": "0x421f9d1b2147f534e3aefc6af95edd4cf2430874",
          "name": "Wrestling Shiba",
          "symbol": "WWE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28361/thumb/IMG_20221130_044817_342.jpg?1669853876"
        },
        {
          "chainId": 56,
          "address": "0x039cd22cb49084142d55fcd4b6096a4f51ffb3b4",
          "name": "MoveZ",
          "symbol": "MOVEZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25661/thumb/JYwFw77.png?1654768153"
        },
        {
          "chainId": 56,
          "address": "0x50e756a22ff5cee3559d18b9d9576bc38f09fa7c",
          "name": "MetaWars",
          "symbol": "WARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19534/thumb/13105.png?1635374115"
        },
        {
          "chainId": 56,
          "address": "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf",
          "name": "Binance Peg Bitcoin Cash",
          "symbol": "BCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15774/thumb/bitcoin-cash-circle.png?1621834955"
        },
        {
          "chainId": 56,
          "address": "0x75afa9915b2210cd6329e820af0365e932bc1dd5",
          "name": "SmurfsINU",
          "symbol": "SMURF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26804/thumb/200x200.png?1660207921"
        },
        {
          "chainId": 56,
          "address": "0xd7d5d7bcd0680cd8aa5afc34bc7037c3040f8210",
          "name": "Green Block",
          "symbol": "GBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28927/thumb/photo_2023-02-02_02-44-01.jpg?1675488160"
        },
        {
          "chainId": 56,
          "address": "0xfa7d4d3fde48e7d70840b6947c4065f8fcfe796d",
          "name": "VNetwork",
          "symbol": "VNW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30041/thumb/VNetwork.png?1682669323"
        },
        {
          "chainId": 56,
          "address": "0x7ac8a920cf1f7e7cc4f698c9c5cbc1e26f604790",
          "name": "Allium Finance",
          "symbol": "ALM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15552/thumb/idEZxWx.png?1621202117"
        },
        {
          "chainId": 56,
          "address": "0x1068a279aee90c4033660425406658f4474fe2b5",
          "name": "RealMoneyWorld",
          "symbol": "RMW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28615/thumb/chyksjm.png?1672485379"
        },
        {
          "chainId": 56,
          "address": "0xd069599e718f963bd84502b49ba8f8657faf5b3a",
          "name": "XCAD Network PLAY",
          "symbol": "PLAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29565/thumb/play2_%281%29.png?1679736265"
        },
        {
          "chainId": 56,
          "address": "0xf19cfb40b3774df6eed83169ad5ab0aaf6865f25",
          "name": "Primal Hoop",
          "symbol": "HOOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27405/thumb/HOOP_TOKEN.png?1663837803"
        },
        {
          "chainId": 56,
          "address": "0xcf2d2ce89aed0073540c497fcf894ea22d37c7af",
          "name": "DChess King",
          "symbol": "KING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23706/thumb/king.png?1645082337"
        },
        {
          "chainId": 56,
          "address": "0x78f5d389f5cdccfc41594abab4b0ed02f31398b3",
          "name": "ApolloX",
          "symbol": "APX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21972/thumb/apx.png?1640765293"
        },
        {
          "chainId": 56,
          "address": "0xfa17b330bcc4e7f3e2456996d89a5a54ab044831",
          "name": "Cardence",
          "symbol": "CRDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17744/thumb/logo_-_2021-08-17T084037.897.png?1629160880"
        },
        {
          "chainId": 56,
          "address": "0xbc12ad556581ff7162e595e5956f5f3845fdb38c",
          "name": "Helicopter Finance",
          "symbol": "COPTER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15217/thumb/att1YJDb_400x400.jpg?1620115502"
        },
        {
          "chainId": 56,
          "address": "0xc3028fbc1742a16a5d69de1b334cbce28f5d7eb3",
          "name": "StarSharks",
          "symbol": "SSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21667/thumb/FLjJKQd0_400x400.jpg?1639705037"
        },
        {
          "chainId": 56,
          "address": "0x46f275321107d7c49cf80216371abf1a1599c36f",
          "name": "TG DAO",
          "symbol": "TGDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22834/thumb/JjRiXuqw_400x400.jpg?1642670277"
        },
        {
          "chainId": 56,
          "address": "0x0cd5c0e24a29225b2e0eae25c3ab8f62ef70df9d",
          "name": "AI Floki",
          "symbol": "AIFLOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29261/thumb/uOcyUk9.jpg?1677657639"
        },
        {
          "chainId": 56,
          "address": "0x602ba546a7b06e0fc7f58fd27eb6996ecc824689",
          "name": "PinkSale",
          "symbol": "PINKSALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17377/thumb/4cC4Fgs.png?1627450990"
        },
        {
          "chainId": 56,
          "address": "0x73ffdf2d2afb3def5b10bf967da743f2306a51db",
          "name": "Collector Coin",
          "symbol": "AGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16918/thumb/AGS.png?1625626120"
        },
        {
          "chainId": 56,
          "address": "0x9d7c580e0bc4ea441db96eebc7e1440d264bce51",
          "name": "Moonlift Capital",
          "symbol": "MLTPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16226/thumb/moonlift.jpeg?1640765727"
        },
        {
          "chainId": 56,
          "address": "0x1f3af095cda17d63cad238358837321e95fc5915",
          "name": "Mint Club",
          "symbol": "MINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17174/thumb/mint.PNG?1626815527"
        },
        {
          "chainId": 56,
          "address": "0x4db2495afad4c0e481ffc40fdaf66e13a786b619",
          "name": "Arix",
          "symbol": "ARIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13485/thumb/ARIX.png?1632731110"
        },
        {
          "chainId": 56,
          "address": "0xde51d1599339809cafb8194189ce67d5bdca9e9e",
          "name": "Cowrie",
          "symbol": "COWRIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28879/thumb/photo_2023-01-29_22.18.24.jpeg?1675137771"
        },
        {
          "chainId": 56,
          "address": "0x40f906e19b14100d5247686e08053c4873c66192",
          "name": "SugarBounce",
          "symbol": "TIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21487/thumb/sugarbounce.PNG?1639363106"
        },
        {
          "chainId": 56,
          "address": "0x4937e7d93dd8d8e76eb83659f109cdc633ffdee9",
          "name": "CATCEO",
          "symbol": "CATCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29307/thumb/CATCEO_logo_200*200.png?1677993078"
        },
        {
          "chainId": 56,
          "address": "0x7a3ba320d44192ae9f6c061f15bcebd7a6217242",
          "name": "KingPad",
          "symbol": "CROWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21182/thumb/IMG-20211202-223646-744.jpg?1638506560"
        },
        {
          "chainId": 56,
          "address": "0x5b1f874d0b0c5ee17a495cbb70ab8bf64107a3bd",
          "name": "BinaryX",
          "symbol": "BNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29176/thumb/RqJfmXQV_400x400.jpg?1677135119"
        },
        {
          "chainId": 56,
          "address": "0xd2ed1973d55488b7118ea81d5a30cd7b61c68a49",
          "name": "Ultron Vault",
          "symbol": "ULTRON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29956/thumb/logo.png?1682385357"
        },
        {
          "chainId": 56,
          "address": "0x77d547256a2cd95f32f67ae0313e450ac200648d",
          "name": "Lazio Fan Token",
          "symbol": "LAZIO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19263/thumb/B4Lla6V6_400x400.png?1634819219"
        },
        {
          "chainId": 56,
          "address": "0x4927b4d730ae6f5a9a9115cf81848a3b9cfad891",
          "name": "iFortune",
          "symbol": "IFC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29035/thumb/LOGO.jpg?1676097193"
        },
        {
          "chainId": 56,
          "address": "0xd9025e25bb6cf39f8c926a704039d2dd51088063",
          "name": "Coinary",
          "symbol": "CYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17622/thumb/CYT-LOGO-1.png?1628669366"
        },
        {
          "chainId": 56,
          "address": "0x7c56c79a454cbfaf63badb39f82555109a2a80bf",
          "name": "Axle Games",
          "symbol": "AXLE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28766/thumb/Artboard_6_3x.png?1674003244"
        },
        {
          "chainId": 56,
          "address": "0x567bbef0efdf53355c569b7aedde4c4f7c008014",
          "name": "redFireAnts",
          "symbol": "RANTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25269/thumb/website-rants-300x300.png?1651068334"
        },
        {
          "chainId": 56,
          "address": "0xaee4164c1ee46ed0bbc34790f1a3d1fc87796668",
          "name": "Poly Peg Mdex",
          "symbol": "HMDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18504/thumb/Capture.PNG?1632211012"
        },
        {
          "chainId": 56,
          "address": "0xa4904cc19c4fd9bf3152ff96cdf72a8f135b5286",
          "name": "Cloud Pet",
          "symbol": "CPET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28343/thumb/Cpet_Logo.png?1669691304"
        },
        {
          "chainId": 56,
          "address": "0x94d79c325268c898d2902050730f27a478c56cc1",
          "name": "IMO",
          "symbol": "IMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14831/thumb/nPpe2Js.png?1618564641"
        },
        {
          "chainId": 56,
          "address": "0x543c7ebb52d56985f63f246a5b3558aff79037d7",
          "name": "Stabledoc",
          "symbol": "SDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19124/thumb/stabledoc.PNG?1634521177"
        },
        {
          "chainId": 56,
          "address": "0x6169b3b23e57de79a6146a2170980ceb1f83b9e0",
          "name": "Vetter",
          "symbol": "VETTER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19235/thumb/Vetter_Logo.jpg?1649412533"
        },
        {
          "chainId": 56,
          "address": "0xf9114498b7f38f3337d6295a3a0f3edf8da71326",
          "name": "DEXO",
          "symbol": "DEXO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28506/thumb/DEXO.png?1671166181"
        },
        {
          "chainId": 56,
          "address": "0xc868642d123289f0a6cb34a3c2810a0a46141947",
          "name": "Bitcoin E wallet",
          "symbol": "BITWALLET",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/27378/thumb/photo.jpg?1663741312"
        },
        {
          "chainId": 56,
          "address": "0xee7f7c9459d8e910209849ed010c96f2dfe39d3b",
          "name": "Almira Wallet",
          "symbol": "ALMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29562/thumb/Untitled_design_%282%29.png?1679735867"
        },
        {
          "chainId": 56,
          "address": "0x83b79f74f225e8f9a29fc67cb1678e7909d7d73d",
          "name": "Avatly",
          "symbol": "AVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28368/thumb/17ddcf3cae174463a018a9566c4f3424.png?1669947004"
        },
        {
          "chainId": 56,
          "address": "0x837a130aed114300bab4f9f1f4f500682f7efd48",
          "name": "WeSendit",
          "symbol": "WSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28386/thumb/wesendit_token_logo_%281%29.png?1670061013"
        },
        {
          "chainId": 56,
          "address": "0x7cce94c0b2c8ae7661f02544e62178377fe8cf92",
          "name": "Daddy Doge",
          "symbol": "DADDYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16931/thumb/icon-200x200_%281%29.png?1625650538"
        },
        {
          "chainId": 56,
          "address": "0xed0353560c43f3e0336d197e4081c81e1015dd51",
          "name": "FLOKI SHIBA PEPE CEO",
          "symbol": "3CEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29599/thumb/24108.png?1679983586"
        },
        {
          "chainId": 56,
          "address": "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
          "name": "Binance Peg Avalanche",
          "symbol": "AVAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18674/thumb/avax_logo_1.png?1632889651"
        },
        {
          "chainId": 56,
          "address": "0x3e9d6430144485873248251fcb92bd856e95d1cd",
          "name": "Qube",
          "symbol": "QUBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21599/thumb/hYjrsaHU_400x400.png?1639547873"
        },
        {
          "chainId": 56,
          "address": "0x3496212ec43cc49f5151ec4405efd4975e036f89",
          "name": "LiveGreen Coin",
          "symbol": "LGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28197/thumb/lgclogo_final-200px.png?1675187416"
        },
        {
          "chainId": 56,
          "address": "0xfe2f3580856376377c14e2287fa15bcb703e5fb5",
          "name": "Alphabet",
          "symbol": "ALT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29810/thumb/logo.png?1681431840"
        },
        {
          "chainId": 56,
          "address": "0xe283d0e3b8c102badf5e8166b73e02d96d92f688",
          "name": "Elephant Money",
          "symbol": "ELEPHANT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15549/thumb/elephant-200.png?1621200793"
        },
        {
          "chainId": 56,
          "address": "0x42f6f551ae042cbe50c739158b4f0cac0edb9096",
          "name": "Nerve Finance",
          "symbol": "NRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14233/thumb/nerve_finance_logo.png?1615176392"
        },
        {
          "chainId": 56,
          "address": "0xbccd27062ae1a2bea5731c904b96edfb163aba21",
          "name": "Dogcoin",
          "symbol": "DOGS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29065/thumb/1024_%281%29.png?1676366022"
        },
        {
          "chainId": 56,
          "address": "0x05ad6e30a855be07afa57e08a4f30d00810a402e",
          "name": "Tiny Coin",
          "symbol": "TINC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24176/thumb/IMG_20220307_100854_672.png?1646748436"
        },
        {
          "chainId": 56,
          "address": "0x9cbb03effd6fb7d79c9bab1b0ceaf4232e957521",
          "name": "Doge CEO",
          "symbol": "DOGECEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29298/thumb/IMG_20230302_230329_558.png?1677915108"
        },
        {
          "chainId": 56,
          "address": "0x79114cc342a4c2a82ce6cc649992e26511b4d95d",
          "name": "BitSniper",
          "symbol": "BULLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30715/thumb/BitSniper.jpg?1686555950"
        },
        {
          "chainId": 56,
          "address": "0xa77d560e34bd6a8d7265f754b4fcd65d9a8e5619",
          "name": "MrWeb Finance",
          "symbol": "AMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27173/thumb/jd0i3xri_400x400.jpeg?1662352468"
        },
        {
          "chainId": 56,
          "address": "0x56b6fb708fc5732dec1afc8d8556423a2edccbd6",
          "name": "Binance Peg EOS",
          "symbol": "EOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15771/thumb/eos-eos-logo.png?1621834238"
        },
        {
          "chainId": 56,
          "address": "0x673da443da2f6ae7c5c660a9f0d3dd24d1643d36",
          "name": "RainbowToken",
          "symbol": "RAINBOWTOKEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17828/thumb/WsLiOeJ.png?1637337787"
        },
        {
          "chainId": 56,
          "address": "0xc8354507f0361712143efa635cce060788888888",
          "name": "AICoin",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30308/thumb/HZixmXSs_400x400.jpg?1683875819"
        },
        {
          "chainId": 56,
          "address": "0xcb5327ed4649548e0d73e70b633cdfd99af6da87",
          "name": "PRIMAL",
          "symbol": "PRIMAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28435/thumb/PRIMAL_ICON_200px.jpg?1670567426"
        },
        {
          "chainId": 56,
          "address": "0xeda3866f07566d9379a91a07f8d62e19c03183e0",
          "name": "HashBit",
          "symbol": "HBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16375/thumb/Coin_Design_2_200.png?1675309904"
        },
        {
          "chainId": 56,
          "address": "0x722f41f6511ff7cda73a1cb0a9ea2f731738c4a0",
          "name": "Street Runner",
          "symbol": "SRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24382/thumb/kKx4Ivoy_400x400.jpg?1647447765"
        },
        {
          "chainId": 56,
          "address": "0xc30f12cd65f61ded24db6c415c84f999c9704ebc",
          "name": "Sabaka Inu",
          "symbol": "SABAKAINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15748/thumb/Logo.png?1621782284"
        },
        {
          "chainId": 56,
          "address": "0x1851ccd370c444ff494d7505e6103959bce9f9d9",
          "name": "ONUS",
          "symbol": "ONUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24599/thumb/ONUS_200.png?1669452755"
        },
        {
          "chainId": 56,
          "address": "0xe56842ed550ff2794f010738554db45e60730371",
          "name": "Binemon",
          "symbol": "BIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17932/thumb/logo-v3.png?1629858800"
        },
        {
          "chainId": 56,
          "address": "0x19a4866a85c652eb4a2ed44c42e4cb2863a62d51",
          "name": "HoDooi com",
          "symbol": "HOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16301/thumb/Monogram_2x-100.jpg?1645205976"
        },
        {
          "chainId": 56,
          "address": "0x12fc07081fab7de60987cad8e8dc407b606fb2f8",
          "name": "Dark Frontiers",
          "symbol": "DARK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18856/thumb/dark.png?1633597226"
        },
        {
          "chainId": 56,
          "address": "0xee9801669c6138e84bd50deb500827b776777d28",
          "name": "O3 Swap",
          "symbol": "O3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316"
        },
        {
          "chainId": 56,
          "address": "0xfc279e6ff1fb1c7f5848d31561cab27d34a2856b",
          "name": "Revoland",
          "symbol": "REVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25892/thumb/revo.jpg?1654428983"
        },
        {
          "chainId": 56,
          "address": "0x62823659d09f9f9d2222058878f89437425eb261",
          "name": "Ertha",
          "symbol": "ERTHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20317/thumb/Ry9tgUal_400x400.jpg?1636856709"
        },
        {
          "chainId": 56,
          "address": "0x9798df2f5d213a872c787bd03b2b91f54d0d04a1",
          "name": "TeraBlock",
          "symbol": "TBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15622/thumb/200x200.png?1671681562"
        },
        {
          "chainId": 56,
          "address": "0x7e52a123ed6db6ac872a875552935fbbd2544c86",
          "name": "myDid",
          "symbol": "SYL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14847/thumb/myDid.png?1679116502"
        },
        {
          "chainId": 56,
          "address": "0x8267d169e1483e01aacbcfd22d294c47ece9f07f",
          "name": "DragonMoon",
          "symbol": "DMOON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17177/thumb/dmoon.PNG?1626816912"
        },
        {
          "chainId": 56,
          "address": "0x680d3113caf77b61b510f332d5ef4cf5b41a761d",
          "name": "DeHub",
          "symbol": "DHB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18094/thumb/dehub.PNG?1630462516"
        },
        {
          "chainId": 56,
          "address": "0x922722e9ef614ec9a3e94b78496e92abfbb5a624",
          "name": "ILCAPO",
          "symbol": "CAPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30282/thumb/w3oWSkCz_400x400.jpg?1684392122"
        },
        {
          "chainId": 56,
          "address": "0x4da996c5fe84755c80e108cf96fe705174c5e36a",
          "name": "WOWswap",
          "symbol": "WOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14101/thumb/Group-423.png?1614317472"
        },
        {
          "chainId": 56,
          "address": "0x2ab0e9e4ee70fff1fb9d67031e44f6410170d00e",
          "name": "XEN Crypto  BSC ",
          "symbol": "BXEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29726/thumb/bxen.jpg?1680852992"
        },
        {
          "chainId": 56,
          "address": "0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95",
          "name": "ApeSwap",
          "symbol": "BANANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14870/thumb/banana.png?1648887183"
        },
        {
          "chainId": 56,
          "address": "0x88d7e9b65dc24cf54f5edef929225fc3e1580c25",
          "name": "JumpToken",
          "symbol": "JMPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22603/thumb/200x200.png?1642162597"
        },
        {
          "chainId": 56,
          "address": "0xf700d4c708c2be1463e355f337603183d20e0808",
          "name": "Galactic Quadrant",
          "symbol": "GQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24059/thumb/logo_gg.png?1648630827"
        },
        {
          "chainId": 56,
          "address": "0x89af13a10b32f1b2f8d1588f93027f69b6f4e27e",
          "name": "GameFi",
          "symbol": "GAFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18292/thumb/gamefi.PNG?1631312307"
        },
        {
          "chainId": 56,
          "address": "0xf6b52a29671e74e6b3a7592ef79039abb64e2885",
          "name": "Humanoid AI",
          "symbol": "HUMAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29365/thumb/Humaniod_Ai_copy.png?1678326576"
        },
        {
          "chainId": 56,
          "address": "0xf2c96e402c9199682d5ded26d3771c6b192c01af",
          "name": "Scallop",
          "symbol": "SCLP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19059/thumb/scallop.PNG?1634266022"
        },
        {
          "chainId": 56,
          "address": "0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4",
          "name": "BillionHappiness",
          "symbol": "BHC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12803/thumb/BHC_BLACK_TRANSPARENT.png?1602665544"
        },
        {
          "chainId": 56,
          "address": "0x9f589e3eabe42ebc94a44727b3f3531c0c877809",
          "name": "Tokocrypto",
          "symbol": "TKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14577/thumb/tko-logo.png?1617093467"
        },
        {
          "chainId": 56,
          "address": "0xe02df9e3e622debdd69fb838bb799e3f168902c5",
          "name": "BakerySwap",
          "symbol": "BAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12588/thumb/bakerytoken_logo.jpg?1600940032"
        },
        {
          "chainId": 56,
          "address": "0xca3f508b8e4dd382ee878a314789373d80a5190a",
          "name": "Beefy",
          "symbol": "BIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12704/thumb/token.png?1601876182"
        },
        {
          "chainId": 56,
          "address": "0x78650b139471520656b9e7aa7a5e9276814a38e9",
          "name": "BTC Standard Hashrate Token",
          "symbol": "BTCST",
          "decimals": 17,
          "logoURI": "https://assets.coingecko.com/coins/images/14449/thumb/4a3IskOf_400x400.png?1616137396"
        },
        {
          "chainId": 56,
          "address": "0xb248a295732e0225acd3337607cc01068e3b9c10",
          "name": "Venus XRP",
          "symbol": "VXRP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13921/thumb/vxrp.png?1612845452"
        },
        {
          "chainId": 56,
          "address": "0xa260e12d2b924cb899ae80bb58123ac3fee1e2f0",
          "name": "Hooked Protocol",
          "symbol": "HOOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28288/thumb/hooked2.png?1669195270"
        },
        {
          "chainId": 56,
          "address": "0x88479186bac914e4313389a64881f5ed0153c765",
          "name": "SquidGrow",
          "symbol": "SQUIDGROW",
          "decimals": 19,
          "logoURI": "https://assets.coingecko.com/coins/images/26573/thumb/Squidgrow-Logo.png?1673589821"
        },
        {
          "chainId": 56,
          "address": "0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6",
          "name": "KingdomStarter",
          "symbol": "KDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10914/thumb/KDG_200x200.png?1689327014"
        },
        {
          "chainId": 56,
          "address": "0xfb62ae373aca027177d1c18ee0862817f9080d08",
          "name": "My DeFi Pet",
          "symbol": "DPET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG?1620512455"
        },
        {
          "chainId": 56,
          "address": "0x99aa29ac023057951781dc5d1784e9a4c362ce23",
          "name": "Seascape Crowns",
          "symbol": "CWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13835/thumb/crowns_logo.png?1612176905"
        },
        {
          "chainId": 56,
          "address": "0x851f7a700c5d67db59612b871338a85526752c25",
          "name": "Argon",
          "symbol": "ARGON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14280/thumb/200x200-no-bg.png?1623038929"
        },
        {
          "chainId": 56,
          "address": "0x8f7ebc23212f6adcc7a5f1c86197ec337c2f4997",
          "name": "BeNFT Solutions",
          "symbol": "BEAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30669/thumb/BeAI-Coin.png?1686210015"
        },
        {
          "chainId": 56,
          "address": "0x82030cdbd9e4b7c5bb0b811a61da6360d69449cc",
          "name": "RealFevr",
          "symbol": "FEVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849"
        },
        {
          "chainId": 56,
          "address": "0xfea292e5ea4510881bdb840e3cec63abd43f936f",
          "name": "Cornucopias",
          "symbol": "COPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg?1639174968"
        },
        {
          "chainId": 56,
          "address": "0xc598275452fa319d75ee5f176fd3b8384925b425",
          "name": "StreamCoin",
          "symbol": "STRM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25438/thumb/17464.png?1651743438"
        },
        {
          "chainId": 56,
          "address": "0x936b6659ad0c1b244ba8efe639092acae30dc8d6",
          "name": "Corite",
          "symbol": "CO",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25969/thumb/Corite-symbol-red-neg-2000px.png?1655021506"
        },
        {
          "chainId": 56,
          "address": "0x1d0ac23f03870f768ca005c84cbb6fb82aa884fd",
          "name": "Galeon",
          "symbol": "GALEON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24261/thumb/Logo-Galeon-Coinmarketcap.png?1647171045"
        },
        {
          "chainId": 56,
          "address": "0x287880ea252b52b63cc5f40a2d3e5a44aa665a76",
          "name": "Alpine F1 Team Fan Token",
          "symbol": "ALPINE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23717/thumb/18112.png?1645154685"
        },
        {
          "chainId": 56,
          "address": "0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404",
          "name": "BullPerks",
          "symbol": "BLP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16471/thumb/horizontal-standard-black.png?1624268460"
        },
        {
          "chainId": 56,
          "address": "0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f",
          "name": "MagicCraft",
          "symbol": "MCRT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21318/thumb/mcrt.png?1638932009"
        },
        {
          "chainId": 56,
          "address": "0x7565ab68d3f9dadff127f864103c8c706cf28235",
          "name": "TrustFi Network",
          "symbol": "TFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16297/thumb/frny3dSs_400x400.jpg?1623676476"
        },
        {
          "chainId": 56,
          "address": "0x391088b4594e41c003463c7e3a56a8ba00b8e7a9",
          "name": "PodFast",
          "symbol": "FAST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29408/thumb/Currency_Symbol_Round_250px_%281%29.png?1678943370"
        },
        {
          "chainId": 56,
          "address": "0xa9b038285f43cd6fe9e16b4c80b4b9bccd3c161b",
          "name": "Flourishing AI",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17127/thumb/logo-circle.png?1632374596"
        },
        {
          "chainId": 56,
          "address": "0x510975eda48a97e0ca228dd04d1217292487bea6",
          "name": "PROJECT XENO",
          "symbol": "GXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29000/thumb/gxe.png?1675845303"
        },
        {
          "chainId": 56,
          "address": "0x477bc8d23c634c154061869478bce96be6045d12",
          "name": "Seedify fund",
          "symbol": "SFUND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14614/thumb/Favicon_Icon.png?1632121435"
        },
        {
          "chainId": 56,
          "address": "0x6aa217312960a21adbde1478dc8cbcf828110a67",
          "name": "Spintop",
          "symbol": "SPIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21209/thumb/51683048395_1cb5de34ca_o.png?1638568244"
        },
        {
          "chainId": 56,
          "address": "0xbbf1889f22d37640bc70c58b2f643106db0542de",
          "name": "Galaxy Arena Metaverse",
          "symbol": "ESNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27923/thumb/esnc.png?1666606196"
        },
        {
          "chainId": 56,
          "address": "0x16153214e683018d5aa318864c8e692b66e16778",
          "name": "PolkaWar",
          "symbol": "PWAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16776/thumb/polkawar.PNG?1624947747"
        },
        {
          "chainId": 56,
          "address": "0x7536c00711e41df6aebcca650791107645b6bc52",
          "name": "Oxbull Tech",
          "symbol": "OXB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27781/thumb/CG_logo_%282%29.png?1665973528"
        },
        {
          "chainId": 56,
          "address": "0x2e40565771cf2bf8622c41cdd85701519f22418a",
          "name": "ZCore",
          "symbol": "ZCR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31008/thumb/Zcore.jpg?1689674581"
        },
        {
          "chainId": 56,
          "address": "0x6ad9e9c098a45b2b41b519119c31c3dcb02accb2",
          "name": "PlayZap",
          "symbol": "PZP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24727/thumb/200X200-111.png?1682408907"
        },
        {
          "chainId": 56,
          "address": "0xd15c444f1199ae72795eba15e8c1db44e47abf62",
          "name": "TEN",
          "symbol": "TENFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15631/thumb/TEN-Square200.png?1652945159"
        },
        {
          "chainId": 56,
          "address": "0x93749e69560efe1ad6661903e47df538492c50a4",
          "name": "Electric Vehicle Direct Currency",
          "symbol": "EVDC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17406/thumb/EVDC-01-1-2.png?1627539880"
        },
        {
          "chainId": 56,
          "address": "0x769c6f0c5c2bcd1b76638bd58e5350f5c94128f3",
          "name": "What Do You Meme",
          "symbol": "WDYM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30763/thumb/Pinksle.png?1686891636"
        },
        {
          "chainId": 56,
          "address": "0xdcd103bc6d14829c39afc9c10c9c373ce385d2c5",
          "name": "Frog Token",
          "symbol": "FROG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30776/thumb/FROG_200x200.png?1687165773"
        },
        {
          "chainId": 56,
          "address": "0x7c63f96feafacd84e75a594c00fac3693386fbf0",
          "name": "Australian Safe Shepherd",
          "symbol": "ASS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14912/thumb/XCsenIJ.png?1618988361"
        },
        {
          "chainId": 56,
          "address": "0x0611686a2558de495617685b3da12448657170fe",
          "name": "CATpay",
          "symbol": "CATPAY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24731/thumb/catpay.png?1661765563"
        },
        {
          "chainId": 56,
          "address": "0xcb492c701f7fe71bc9c4b703b84b0da933ff26bb",
          "name": "Nimbus Utility",
          "symbol": "NIMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28579/thumb/nimb.png?1671949086"
        },
        {
          "chainId": 56,
          "address": "0x522348779dcb2911539e76a1042aa922f9c47ee3",
          "name": "Bomb Money",
          "symbol": "BOMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21406/thumb/CD9qxg9l_400x400.jpg?1639087343"
        },
        {
          "chainId": 56,
          "address": "0xea89199344a492853502a7a699cc4230854451b8",
          "name": "ONINO",
          "symbol": "ONI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9405/thumb/Oni_Round.png?1643035358"
        },
        {
          "chainId": 56,
          "address": "0xf508fcd89b8bd15579dc79a6827cb4686a3592c8",
          "name": "Venus ETH",
          "symbol": "VETH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13900/thumb/venus_eth.png?1612773542"
        },
        {
          "chainId": 56,
          "address": "0x3e7f1039896454b9cb27c53cc7383e1ab9d9512a",
          "name": "MetFi",
          "symbol": "METFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29676/thumb/logo-200.png?1681887195"
        },
        {
          "chainId": 56,
          "address": "0x5b8650cd999b23cf39ab12e3213fbc8709c7f5cb",
          "name": "MaziMatic",
          "symbol": "MAZI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30490/thumb/4de906d9d4104a16b4334d8ec8f75284.png?1684718591"
        },
        {
          "chainId": 56,
          "address": "0x4a68c250486a116dc8d6a0c5b0677de07cc09c5d",
          "name": "Poodl",
          "symbol": "POODL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14405/thumb/poodl-logo200x200.jpg?1615900163"
        },
        {
          "chainId": 56,
          "address": "0x5eeb28b5aef44b6664b342d23b1aadce84196386",
          "name": "World of Legends",
          "symbol": "WOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29504/thumb/WOL-200x200n_copy.png?1679380722"
        },
        {
          "chainId": 56,
          "address": "0x0e9766df73973abcfedde700497c57110ee5c301",
          "name": "HODL",
          "symbol": "HODL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15546/thumb/hodl.png?1643459301"
        },
        {
          "chainId": 56,
          "address": "0x58c764c18b6263ce97c8a22fec857e0139026b06",
          "name": "LindaYacc Ceo",
          "symbol": "LINDACEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30523/thumb/lindaceo.jpg?1684837588"
        },
        {
          "chainId": 56,
          "address": "0x3194e783fdbaff5edacb71afb6e4c8d7aa67ac61",
          "name": "Bananace",
          "symbol": "NANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30586/thumb/IMG-9193-2_%281%29.jpg?1685452578"
        },
        {
          "chainId": 56,
          "address": "0x69c2fcae7e30b429166bd616a322e32bec036bcf",
          "name": "MuratiAI",
          "symbol": "MURATIAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30433/thumb/Muratilogo.png?1684466716"
        },
        {
          "chainId": 56,
          "address": "0x13616f44ba82d63c8c0dc3ff843d36a8ec1c05a9",
          "name": "Travala com",
          "symbol": "AVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3014/thumb/Travala.png?1558020611"
        },
        {
          "chainId": 56,
          "address": "0xd40bedb44c081d2935eeba6ef5a3c8a31a1bbe13",
          "name": "Metahero",
          "symbol": "HERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16911/thumb/AVATAR2.png?1681208843"
        },
        {
          "chainId": 56,
          "address": "0x4b5decb9327b4d511a58137a1ade61434aacdd43",
          "name": "Poken",
          "symbol": "PKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg?1639490463"
        },
        {
          "chainId": 56,
          "address": "0xb003c68917bab76812797d1b8056822f48e2e4fe",
          "name": "Yummy",
          "symbol": "YUMMY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15589/thumb/eFjXvUxN_400x400.jpg?1624904467"
        },
        {
          "chainId": 56,
          "address": "0xa19d3f4219e2ed6dc1cb595db20f70b8b6866734",
          "name": "Wirtual",
          "symbol": "WIRTUAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24435/thumb/jxtu9s6F_400x400_%281%29.jpg?1647668413"
        },
        {
          "chainId": 56,
          "address": "0xa184088a740c695e156f91f5cc086a06bb78b827",
          "name": "Auto",
          "symbol": "AUTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13751/thumb/autofarm_icon_200x200.png?1611494288"
        },
        {
          "chainId": 56,
          "address": "0x965f527d9159dce6288a2219db51fc6eef120dd1",
          "name": "Biswap",
          "symbol": "BSW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16845/thumb/biswap.png?1625388985"
        },
        {
          "chainId": 56,
          "address": "0x6fe3d0f096fc932a905accd1eb1783f6e4cec717",
          "name": "Milky",
          "symbol": "MILKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20269/thumb/milky.png?1638884521"
        },
        {
          "chainId": 56,
          "address": "0x62858686119135cc00c4a3102b436a0eb314d402",
          "name": "MetaVPad",
          "symbol": "METAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21397/thumb/metav.png?1639044315"
        },
        {
          "chainId": 56,
          "address": "0x95c78222b3d6e262426483d42cfa53685a67ab9d",
          "name": "Venus BUSD",
          "symbol": "VBUSD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13902/thumb/vbusd.png?1612775895"
        },
        {
          "chainId": 56,
          "address": "0x073d31b72e5444e6f00e24d31f66f100dee40e74",
          "name": "Twitter CEO Floki",
          "symbol": "FLOKICEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29246/thumb/23697.png?1677641125"
        },
        {
          "chainId": 56,
          "address": "0x84c97300a190676a19d1e13115629a11f8482bd1",
          "name": "Dot Dot Finance",
          "symbol": "DDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25212/thumb/batkXqm.png?1650859186"
        },
        {
          "chainId": 56,
          "address": "0xac68931b666e086e9de380cfdb0fb5704a35dc2d",
          "name": "BNB Tiger Inu",
          "symbol": "BNBTIGER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22565/thumb/IMG-20220111-212721-633.jpg?1642058717"
        },
        {
          "chainId": 56,
          "address": "0x520ebccc63e4d0804b35cda25978beb7159bf0cc",
          "name": "Lego Coin V2",
          "symbol": "LEGO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27545/thumb/jP0kpqR6_400x400.jpeg?1664441103"
        },
        {
          "chainId": 56,
          "address": "0xb19289b436b2f7a92891ac391d8f52580d3087e4",
          "name": "ProjectOasis",
          "symbol": "OASIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18755/thumb/Oasis_Logo_1.2%28scaled%29.png?1656397634"
        },
        {
          "chainId": 56,
          "address": "0x1ee098cbaf1f846d5df1993f7e2d10afb35a878d",
          "name": "Sable",
          "symbol": "SABLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30989/thumb/SableFinance_Coin-08.png?1689563687"
        },
        {
          "chainId": 56,
          "address": "0xd2a685ed5c93f149ee876958a278f981625f28da",
          "name": "Coffeeswap",
          "symbol": "CF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30873/thumb/676f733b4f3dbad89513c161af7aadc.png?1688374783"
        },
        {
          "chainId": 56,
          "address": "0xb46584e0efde3092e04010a13f2eae62adb3b9f0",
          "name": "PepeCoin  BSC ",
          "symbol": "PEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30450/thumb/IMG_20230518_230955_871.png?1684489335"
        },
        {
          "chainId": 56,
          "address": "0xb194d48350bc336520080d0732069ebf26cdf648",
          "name": "Bogey",
          "symbol": "BOGEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30948/thumb/photo_2023-07-09_10-37-56.jpg?1689126073"
        },
        {
          "chainId": 56,
          "address": "0xf952fc3ca7325cc27d15885d37117676d25bfda6",
          "name": "Goose Finance",
          "symbol": "EGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13951/thumb/JNnBWAE4XEJD9Ty7x8A1RlJ8dvA7hcOe7C1LkQSWwuSlD6HcCz3V8fN44B6du2k4owhlLJZU3r6M6HcK0dkpMe53kkqYsESpfx9w8vU8t_PnykuyaGnLKXLJeZqEAP1C9Wi3G3sqfbTeHi32_msxtMMyUmIRgzBbDdp-adg6mIbst_8a7JknMgmdkORNydmX4Ptk8YUtZhoWewY.png?1613250907"
        },
        {
          "chainId": 56,
          "address": "0x8a74bc8c372bc7f0e9ca3f6ac0df51be15aec47a",
          "name": "PulsePad",
          "symbol": "PLSPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20102/thumb/Mypk8PG_-_Imgur.jpg?1636511829"
        },
        {
          "chainId": 56,
          "address": "0x0ee7292bd28f4a490f849fb30c28cabab9440f9e",
          "name": "GemLink",
          "symbol": "GLINK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24212/thumb/18770.png?1647240001"
        },
        {
          "chainId": 56,
          "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
          "name": "SPACE ID",
          "symbol": "ID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29468/thumb/sid_token_logo_%28green2%29.png?1679454702"
        },
        {
          "chainId": 56,
          "address": "0x679d5b2d94f454c950d683d159b87aa8eae37c9e",
          "name": "Hamster",
          "symbol": "HAM",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/16115/thumb/hamster.png?1623033031"
        },
        {
          "chainId": 56,
          "address": "0xc001bbe2b87079294c63ece98bdd0a88d761434e",
          "name": "EverGrow Coin",
          "symbol": "EGC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18645/thumb/cmc_egc_200x200_circ.png?1674473630"
        },
        {
          "chainId": 56,
          "address": "0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa",
          "name": "Vita Inu",
          "symbol": "VINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20594/thumb/vita-inu-head-wallet-icon-transparent.png?1637286122"
        },
        {
          "chainId": 56,
          "address": "0x3e24bbb2c9a0f2faecfdbdca20bba6f35b73c4cb",
          "name": "DELOT IO",
          "symbol": "DELOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25561/thumb/Logo_200.png?1654230685"
        },
        {
          "chainId": 56,
          "address": "0xffad7f9f704a5fdc6265e24b436b4b35ed52def2",
          "name": "CloudTx",
          "symbol": "CLOUD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27634/thumb/Untitled_Artwork_%281%29.png?1664961377"
        },
        {
          "chainId": 56,
          "address": "0x6c46422a0f7dbbad9bec3bbbc1189bfaf9794b05",
          "name": "Little Rabbit V2",
          "symbol": "LTRBT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27327/thumb/ltrbt.png?1663326133"
        },
        {
          "chainId": 56,
          "address": "0xc3137c696796d69f783cd0be4ab4bb96814234aa",
          "name": "Pepa Inu",
          "symbol": "PEPA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29557/thumb/IMG_20230324_010229_652.jpg?1679642907"
        },
        {
          "chainId": 56,
          "address": "0x475bfaa1848591ae0e6ab69600f48d828f61a80e",
          "name": "Everdome",
          "symbol": "DOME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23267/thumb/Ix-ms0fq_400x400.jpg?1643414048"
        },
        {
          "chainId": 56,
          "address": "0x1d3437e570e93581bd94b2fd8fbf202d4a65654a",
          "name": "NanoByte",
          "symbol": "NBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23698/thumb/WpcmdLW.png?1645080885"
        },
        {
          "chainId": 56,
          "address": "0x1f7e8fe01aeba6fdaea85161746f4d53dc9bda4f",
          "name": "GGTKN",
          "symbol": "GGTKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26275/thumb/ggtokenofficiallogo.png?1657016270"
        },
        {
          "chainId": 56,
          "address": "0x717fb7b6d0c3d7f1421cc60260412558283a6ae5",
          "name": "Tag Protocol",
          "symbol": "TAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20627/thumb/favicon-32x32.png?1637320093"
        },
        {
          "chainId": 56,
          "address": "0xa045e37a0d1dd3a45fefb8803d22457abc0a728a",
          "name": "Grizzly Honey",
          "symbol": "GHNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26816/thumb/GHNY-token-logo.png?1660277670"
        },
        {
          "chainId": 56,
          "address": "0xf365920c476491f855891dc67b23bd2d0c4c7a72",
          "name": "Vagabond",
          "symbol": "VGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21229/thumb/14073.png?1638756833"
        },
        {
          "chainId": 56,
          "address": "0x4a2c860cec6471b9f5f5a336eb4f38bb21683c98",
          "name": "STEPN Green Satoshi Token on BSC",
          "symbol": "GST-BSC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25484/thumb/gst.png?1652064157"
        },
        {
          "chainId": 56,
          "address": "0x0012365f0a1e5f30a5046c680dcb21d07b15fcf7",
          "name": "Gym Network",
          "symbol": "GYMNET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24654/thumb/Blue.png?1661408547"
        },
        {
          "chainId": 56,
          "address": "0x9c65ab58d8d978db963e63f2bfb7121627e3a739",
          "name": "Mdex  BSC ",
          "symbol": "MDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29751/thumb/tdmmZdko_400x400.png?1681115692"
        },
        {
          "chainId": 56,
          "address": "0x116526135380e28836c6080f1997645d5a807fae",
          "name": "Martik",
          "symbol": "MTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29264/thumb/48b2b503-fe47-494c-b696-2c89ca28ea4b-removebg-preview-PhotoRoom.png-PhotoRoom.png?1677665982"
        },
        {
          "chainId": 56,
          "address": "0xea395dfafed39924988b475f2ca7f4c72655203a",
          "name": "Cryptopolis",
          "symbol": "CPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19163/thumb/PgnW_SGk_400x400.jpg?1652861818"
        },
        {
          "chainId": 56,
          "address": "0x16fdd1edb14ac4012395a0617a682d81595db486",
          "name": "VEED",
          "symbol": "VEED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15919/thumb/VEED.png?1622429588"
        },
        {
          "chainId": 56,
          "address": "0xfd5840cd36d94d7229439859c0112a4185bc0255",
          "name": "Venus USDT",
          "symbol": "VUSDT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13901/thumb/venus_usdt.png?1612775382"
        },
        {
          "chainId": 56,
          "address": "0x8f0528ce5ef7b51152a59745befdd91d97091d2f",
          "name": "Alpaca Finance",
          "symbol": "ALPACA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14165/thumb/Logo200.png?1614748442"
        },
        {
          "chainId": 56,
          "address": "0x9840652dc04fb9db2c43853633f0f62be6f00f98",
          "name": "ChainGPT",
          "symbol": "CGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29306/thumb/200x200.png?1677992972"
        },
        {
          "chainId": 56,
          "address": "0x8789337a176e6e7223ff115f1cd85c993d42c25c",
          "name": "Copiosa",
          "symbol": "COP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14923/thumb/cop.png?1634697690"
        },
        {
          "chainId": 56,
          "address": "0xf93f6b686f4a6557151455189a9173735d668154",
          "name": "Gamerse",
          "symbol": "LFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19582/thumb/gamerse.PNG?1635470285"
        },
        {
          "chainId": 56,
          "address": "0x0f1cbed8efa0e012adbccb1638d0ab0147d5ac00",
          "name": "HELLO",
          "symbol": "HELLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27891/thumb/uFXN2S1N_400x400.jpeg?1666248413"
        },
        {
          "chainId": 56,
          "address": "0xf6cb4ad242bab681effc5de40f7c8ff921a12d63",
          "name": "Centric Swap",
          "symbol": "CNS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11864/thumb/CNS.png?1626960631"
        },
        {
          "chainId": 56,
          "address": "0x6f51a1674befdd77f7ab1246b83adb9f13613762",
          "name": "Seedify NFT Space",
          "symbol": "SNFTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27131/thumb/seedify.jpg?1662516745"
        },
        {
          "chainId": 56,
          "address": "0xad6742a35fb341a9cc6ad674738dd8da98b94fb1",
          "name": "Wombat Exchange",
          "symbol": "WOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26946/thumb/Wombat_Token.png?1660982422"
        },
        {
          "chainId": 56,
          "address": "0xd8047afecb86e44eff3add991b9f063ed4ca716b",
          "name": "Good Games Guild",
          "symbol": "GGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19269/thumb/Logo_%282%29_%281%29.jpg?1634866116"
        },
        {
          "chainId": 56,
          "address": "0x32f1518baace69e85b9e5ff844ebd617c52573ac",
          "name": "Dexsport",
          "symbol": "DESU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20583/thumb/desu.png?1638775497"
        },
        {
          "chainId": 56,
          "address": "0x4238e5ccc619dcc8c00ade4cfc5d3d9020b24898",
          "name": "AI Trader",
          "symbol": "AIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29618/thumb/ait.png?1680058786"
        },
        {
          "chainId": 56,
          "address": "0xa37100bb05271853766af8ed7a32ca20c8311acc",
          "name": "Elon Musk CEO",
          "symbol": "ELONMUSKCE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29797/thumb/photo1681262243_%281%29.png?1681348234"
        },
        {
          "chainId": 56,
          "address": "0x5a726a26edb0df8fd55f03cc30af8a7cea81e78d",
          "name": "CrossWallet",
          "symbol": "CWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16460/thumb/AAoUhOw.png?1624259013"
        },
        {
          "chainId": 56,
          "address": "0x04756126f044634c9a0f0e985e60c88a51acc206",
          "name": "Carbon Browser",
          "symbol": "CSIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28905/thumb/csix.png?1675495203"
        },
        {
          "chainId": 56,
          "address": "0x56b331c7e3d68306f26e07492125f0faa9d95343",
          "name": "Alcazar",
          "symbol": "ALCAZAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30133/thumb/photo_2023-06-27_14.06.31.jpeg?1688371609"
        },
        {
          "chainId": 56,
          "address": "0xbededdf2ef49e87037c4fb2ca34d1ff3d3992a11",
          "name": "FEG BSC",
          "symbol": "FEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29644/thumb/feg.png?1680236579"
        },
        {
          "chainId": 56,
          "address": "0x45808ce43eb2d7685ff0242631f0feb6f3d8701a",
          "name": "Ekta",
          "symbol": "EKTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22350/thumb/token.jpg?1641882684"
        },
        {
          "chainId": 56,
          "address": "0xe57f73eb27da9d17f90c994744d842e95700c100",
          "name": "Pepe AI",
          "symbol": "PEPEAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30148/thumb/logo_200px.png?1683513273"
        },
        {
          "chainId": 56,
          "address": "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5",
          "name": "Destablecoin HAY",
          "symbol": "HAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26947/thumb/HAY.png?1660984091"
        },
        {
          "chainId": 56,
          "address": "0x510ca7d22a84599e7d0f15f09e674056a6255389",
          "name": "LABSV2",
          "symbol": "LABSV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14401/thumb/CMC_logo.jpg?1664964940"
        },
        {
          "chainId": 56,
          "address": "0x6ddd36f6c7c021ebf7d4b9753537d7bca8ed4e37",
          "name": "Hamster Groomers",
          "symbol": "GROOMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30038/thumb/token_500.png?1682659956"
        },
        {
          "chainId": 56,
          "address": "0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
          "name": "Mobox",
          "symbol": "MBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14751/thumb/mobox.PNG?1618146979"
        },
        {
          "chainId": 56,
          "address": "0xa9be3cd803fa19f2af24412ff0a2a4a67a29de88",
          "name": "LavaX Labs",
          "symbol": "LAVAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15494/thumb/image0.jpeg?1645583247"
        },
        {
          "chainId": 56,
          "address": "0xb38a5cdc7304ad3d53ce280a8dc63b2921d0a72f",
          "name": "Doge Blue",
          "symbol": "DOGEBLUE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30030/thumb/tmhaYKc.png?1682585558"
        },
        {
          "chainId": 56,
          "address": "0x12bb890508c125661e03b09ec06e404bc9289040",
          "name": "Radio Caca",
          "symbol": "RACA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17841/thumb/ez44_BSs_400x400.jpg?1629464170"
        },
        {
          "chainId": 56,
          "address": "0x1dc5779ed65bcc1f0a42d654444fb0ce59d7783b",
          "name": "Pomerium",
          "symbol": "PMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25854/thumb/pomerium-PMR_2000x2000.png?1668653725"
        },
        {
          "chainId": 56,
          "address": "0xfc619ffcc0e0f30427bf938f9a1b2bfae15bdf84",
          "name": "Anji",
          "symbol": "ANJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20000/thumb/anji.PNG?1636364408"
        },
        {
          "chainId": 56,
          "address": "0x066cda0cca84e9c6ed0a4ecb92aa036a9582544b",
          "name": "Sonic Inu",
          "symbol": "SONIC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30259/thumb/Sonic_inu-1.png?1683772012"
        },
        {
          "chainId": 56,
          "address": "0x674aa28ac436834051fff3fc7b6e59d6f9c57a1c",
          "name": "Optimus Inu",
          "symbol": "OPINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29358/thumb/IMG_20230307_083613_204.png?1678263636"
        },
        {
          "chainId": 56,
          "address": "0x6b23c89196deb721e6fd9726e6c76e4810a464bc",
          "name": "X World Games",
          "symbol": "XWG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17847/thumb/xworld.PNG?1629498895"
        },
        {
          "chainId": 56,
          "address": "0xf4c8e32eadec4bfe97e0f595add0f4450a863a11",
          "name": "Thena",
          "symbol": "THE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28864/thumb/IMG_20230129_155910_852.png?1674984924"
        },
        {
          "chainId": 56,
          "address": "0x547a9dca1f033bb6c78ef1bd5cd3574e546cf2d9",
          "name": "BOB Coin",
          "symbol": "BOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30558/thumb/photo_2023-05-24_08.54.52.jpeg?1685014353"
        },
        {
          "chainId": 56,
          "address": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
          "name": "Binance Peg Dogecoin",
          "symbol": "DOGE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15768/thumb/dogecoin.png?1621833687"
        },
        {
          "chainId": 56,
          "address": "0x64e93084a4e539b7b60a1b247756373c8b4a1db3",
          "name": "KabosuCEO",
          "symbol": "KCEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29498/thumb/555.png?1679370742"
        },
        {
          "chainId": 56,
          "address": "0x17c8c198c06f16a8db68148d9ac460f5aed029d8",
          "name": "NeoCortexAI  OLD ",
          "symbol": "CORAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29591/thumb/Untitled_design_%281%29.png?1679886568"
        },
        {
          "chainId": 56,
          "address": "0xff12afb3841b737289d1b02dfedbe4c85a8ec6e6",
          "name": "Saba Finance",
          "symbol": "SABA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30107/thumb/saba.png?1683086742"
        },
        {
          "chainId": 56,
          "address": "0x50c34995a273075a80c23625f69f40d56ce414de",
          "name": "SELFCrypto",
          "symbol": "SELF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30805/thumb/Isotype_1_200x200.png?1687414126"
        },
        {
          "chainId": 56,
          "address": "0xff71e87a2e7b818eee86f3f1c2e94a06cac85866",
          "name": "Catcoin BSC",
          "symbol": "CAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29426/thumb/Cat.jpeg?1678763538"
        },
        {
          "chainId": 56,
          "address": "0xb6307cc320c76101e87456e06557fa939accc71f",
          "name": "Girlfriend",
          "symbol": "GF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30642/thumb/Untitled_design_%281%29_%281%29.png?1687764576"
        },
        {
          "chainId": 56,
          "address": "0x606fb7969fc1b5cad58e64b12cf827fb65ee4875",
          "name": "Okse",
          "symbol": "OKSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png?1662432854"
        },
        {
          "chainId": 56,
          "address": "0x0391be54e72f7e001f6bbc331777710b4f2999ef",
          "name": "Trava Finance",
          "symbol": "TRAVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17553/thumb/TRAVA_OFFICIAL_LOGO.png?1628509820"
        },
        {
          "chainId": 56,
          "address": "0x465707181acba42ed01268a33f0507e320a154bd",
          "name": "Step",
          "symbol": "STEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22352/thumb/fOag4nRa_400x400.jpg?1647429563"
        },
        {
          "chainId": 56,
          "address": "0x9fbff386a9405b4c98329824418ec02b5c20976b",
          "name": "FutureCoin",
          "symbol": "FUTURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23806/thumb/3ptM03y.png?1645509244"
        },
        {
          "chainId": 56,
          "address": "0x4136129ac3ac90cf9817548b24d35e73e9457e05",
          "name": "El Dorado Exchange",
          "symbol": "EDE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28450/thumb/ede.png?1670677727"
        },
        {
          "chainId": 56,
          "address": "0x40079f576625b764e191af9c38ecb2e19d69b675",
          "name": "Goge DAO",
          "symbol": "GOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20631/thumb/14766.png?1637332567"
        },
        {
          "chainId": 56,
          "address": "0x3cb26f04223e948b8d810a7bd170620afbd42e67",
          "name": "Areon Network",
          "symbol": "AREA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28764/thumb/photo_2023-01-04_22-48-03.jpg?1674002274"
        },
        {
          "chainId": 56,
          "address": "0xbcfe392e778dbb59dcad624f10f7fa8c4a910b1e",
          "name": "Spartans",
          "symbol": "SPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22787/thumb/Logo_200x200.png?1650437413"
        },
        {
          "chainId": 56,
          "address": "0x9f5c37e0fd9bf729b1f0a6f39ce57be5e9bfd435",
          "name": "Bitcoin Pay",
          "symbol": "BTCPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26994/thumb/photo_2022-08-22_16-38-30-removebg-preview.png?1661250811"
        },
        {
          "chainId": 56,
          "address": "0x98a2500a2c3b8877b0ed5ac3acc300c50bf7064b",
          "name": "NOOT",
          "symbol": "NOOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30045/thumb/20230426_124808.png?1682672467"
        },
        {
          "chainId": 56,
          "address": "0xbe2b6c5e31f292009f495ddbda88e28391c9815e",
          "name": "Level Governance",
          "symbol": "LGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28629/thumb/LGO-256.png?1672717698"
        },
        {
          "chainId": 56,
          "address": "0x92ed61fb8955cc4e392781cb8b7cd04aadc43d0c",
          "name": "Oggy Inu",
          "symbol": "OGGY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29691/thumb/OGGY_logo_200x200.png?1687764543"
        },
        {
          "chainId": 56,
          "address": "0xe2ecc66e14efa96e9c55945f79564f468882d24c",
          "name": "Disney",
          "symbol": "DIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30751/thumb/DIS200x200_%282%29.png?1686728885"
        },
        {
          "chainId": 56,
          "address": "0x340724464cf51a551106cc6657606ee7d87b28b9",
          "name": "Satoshi Island",
          "symbol": "STC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26975/thumb/2KstL9si_400x400.jpeg?1661163830"
        },
        {
          "chainId": 56,
          "address": "0xc17c30e98541188614df99239cabd40280810ca3",
          "name": "EverRise",
          "symbol": "RISE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670"
        },
        {
          "chainId": 56,
          "address": "0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16",
          "name": "pSTAKE Staked BNB",
          "symbol": "STKBNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26725/thumb/stkBNB.png?1659878803"
        },
        {
          "chainId": 56,
          "address": "0x4dd1984a706e1c2c227bea67ad2f92dbde30afce",
          "name": "BasketCoin",
          "symbol": "BSKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14766/thumb/logo.jpg?1674807900"
        },
        {
          "chainId": 56,
          "address": "0xf4341fa52669cea0c1836095529a7e9b04b8b88d",
          "name": "Satozhi",
          "symbol": "SATOZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg?1618416420"
        },
        {
          "chainId": 56,
          "address": "0x529c79f6918665ebe250f32eeeaa1d410a0798c6",
          "name": "HyperGPT",
          "symbol": "HGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30428/thumb/favicon.png?1684462077"
        },
        {
          "chainId": 56,
          "address": "0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65",
          "name": "Nominex",
          "symbol": "NMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14424/thumb/NMX_c_200px.png?1616385215"
        },
        {
          "chainId": 56,
          "address": "0xb4404dab7c0ec48b428cf37dec7fb628bcc41b36",
          "name": "MetaGear",
          "symbol": "GEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21460/thumb/token-gear.png?1639292292"
        },
        {
          "chainId": 56,
          "address": "0x44c99ca267c2b2646ceec72e898273085ab87ca5",
          "name": "Raptor Finance",
          "symbol": "RPTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25265/thumb/Vy7-XGjj_400x400.jpg?1651053305"
        },
        {
          "chainId": 56,
          "address": "0xc9ad37e9baf41377540df5a77831db98c1915128",
          "name": "Green Shiba Inu",
          "symbol": "GINUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15649/thumb/inu-logo-new-200x200.png?1625578449"
        },
        {
          "chainId": 56,
          "address": "0x2cd96e8c3ff6b5e01169f6e3b61d28204e7810bb",
          "name": "Lucky Block",
          "symbol": "LBLOCK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png?1643268144"
        },
        {
          "chainId": 56,
          "address": "0xb6adb74efb5801160ff749b1985fd3bd5000e938",
          "name": "GameZone",
          "symbol": "GZONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18697/thumb/Th98fbg__400x400.jpg?1633007475"
        },
        {
          "chainId": 56,
          "address": "0x092bbec1342afffd16cfb41b56343d5a299cdf0d",
          "name": "ShibaCorgi",
          "symbol": "SHICO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15608/thumb/shibacorgi-200.jpg?1621316524"
        },
        {
          "chainId": 56,
          "address": "0xde301d6a2569aefcfe271b9d98f318baee1d30a4",
          "name": "Luna Rush",
          "symbol": "LUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22295/thumb/16197.png?1641420414"
        },
        {
          "chainId": 56,
          "address": "0x23e8a70534308a4aaf76fb8c32ec13d17a3bd89e",
          "name": "LUSD",
          "symbol": "LUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25223/thumb/lusd_200x200.png?1650957327"
        },
        {
          "chainId": 56,
          "address": "0x7d18f3fe6e638fad0adacc5db1a47f871a2c2cc4",
          "name": "DollarMoon",
          "symbol": "DMOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28587/thumb/200x200_%282%29.png?1672135123"
        },
        {
          "chainId": 56,
          "address": "0xa75d9ca2a0a1d547409d82e1b06618ec284a2ced",
          "name": "Wombex",
          "symbol": "WMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27844/thumb/WMX_logo.png?1666062328"
        },
        {
          "chainId": 56,
          "address": "0x90e3414e00e231b962666bd94adb811d5bcd0c2a",
          "name": "Parex",
          "symbol": "PRX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23650/thumb/cmc.png?1645602955"
        },
        {
          "chainId": 56,
          "address": "0x17e65e6b9b166fb8e7c59432f0db126711246bc0",
          "name": "TiFi",
          "symbol": "TIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24990/thumb/logo200_%281%29.png?1649686507"
        },
        {
          "chainId": 56,
          "address": "0x1633b7157e7638c4d6593436111bf125ee74703f",
          "name": "Splintershards",
          "symbol": "SPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17332/thumb/splinter.PNG?1627339560"
        },
        {
          "chainId": 56,
          "address": "0x440758df68a045db3f2517257f27330a12438656",
          "name": "Froggies",
          "symbol": "FRGST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30575/thumb/bAwE66My_400x400.jpg?1685331593"
        },
        {
          "chainId": 56,
          "address": "0x826e5ec70dbc5607ff9218011fbb97f9a8d97953",
          "name": "Travel Care",
          "symbol": "TRAVEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24164/thumb/zDm5HX8I_400x400.jpg?1646641993"
        },
        {
          "chainId": 56,
          "address": "0x7b0e7e40ee4672599f7095d1ddd730b0805195ba",
          "name": "Signum",
          "symbol": "SIGNA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/154/thumb/SIGNA.png?1625033010"
        },
        {
          "chainId": 56,
          "address": "0x7ad7242a99f21aa543f9650a56d141c57e4f6081",
          "name": "Jade Protocol",
          "symbol": "JADE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20663/thumb/jade.jpg?1637545864"
        },
        {
          "chainId": 56,
          "address": "0x7b86f5ca09dc00502e342b0fef5117e1c32222ce",
          "name": "SOLCash",
          "symbol": "SOLCASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21546/thumb/solcash.png?1639456567"
        },
        {
          "chainId": 56,
          "address": "0xd06716e1ff2e492cc5034c2e81805562dd3b45fa",
          "name": "Magpie",
          "symbol": "MGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27972/thumb/MagpieLogo.png?1666771448"
        },
        {
          "chainId": 56,
          "address": "0xeb34de0c4b2955ce0ff1526cdf735c9e6d249d09",
          "name": "Obyte",
          "symbol": "GBYTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361"
        },
        {
          "chainId": 56,
          "address": "0x651a89fed302227d41425235f8e934502fb94c48",
          "name": "ADAcash",
          "symbol": "ADACASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19509/thumb/adacash.png?1638250717"
        },
        {
          "chainId": 56,
          "address": "0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec",
          "name": "StrikeX",
          "symbol": "STRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15839/thumb/StrikeX-Icon-200px.png?1635161631"
        },
        {
          "chainId": 56,
          "address": "0x24802247bd157d771b7effa205237d8e9269ba8a",
          "name": "Thetan Coin",
          "symbol": "THC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20959/thumb/coin_ui.png?1638142381"
        },
        {
          "chainId": 56,
          "address": "0x019992270e95b800671d3bc1d763e07e489687b2",
          "name": "Lovely Swap",
          "symbol": "LST",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26465/thumb/square2.png?1658192916"
        },
        {
          "chainId": 56,
          "address": "0x20f663cea80face82acdfa3aae6862d246ce0333",
          "name": "Drip Network",
          "symbol": "DRIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21340/thumb/11093.png?1638951857"
        },
        {
          "chainId": 56,
          "address": "0x842668e2b9a73240abf6532dedc89c9c3e050c98",
          "name": "Light Defi",
          "symbol": "LIGHT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17880/thumb/bW0DtiU.png?1658286896"
        },
        {
          "chainId": 56,
          "address": "0x16faf9daa401aa42506af503aa3d80b871c467a3",
          "name": "DexCheck",
          "symbol": "DCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26412/thumb/DexCheck_logo_%282%29.png?1687250248"
        },
        {
          "chainId": 56,
          "address": "0x037838b556d9c9d654148a284682c55bb5f56ef4",
          "name": "Lightning Protocol",
          "symbol": "LIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14357/thumb/ClPrk1N.png?1617948123"
        },
        {
          "chainId": 56,
          "address": "0x42981d0bfbaf196529376ee702f2a9eb9092fcb5",
          "name": "SafeMoon",
          "symbol": "SFM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21863/thumb/photo_2021-12-22_14.43.36.jpeg?1640155440"
        },
        {
          "chainId": 56,
          "address": "0xa73164db271931cf952cbaeff9e8f5817b42fa5c",
          "name": "Landshare",
          "symbol": "LAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17507/thumb/Landshare.png?1628149620"
        },
        {
          "chainId": 56,
          "address": "0x44969fdfeeab1d7f6e500a5a8f1aeebd74785aef",
          "name": "BitCash",
          "symbol": "BITC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7895/thumb/B_GPCvVG_400x400.jpg?1551430603"
        },
        {
          "chainId": 56,
          "address": "0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9",
          "name": "TrueUSD",
          "symbol": "TUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3449/thumb/tusd.png?1618395665"
        },
        {
          "chainId": 56,
          "address": "0xd61ec800066d4b8b1b3609ef91d50817193e6056",
          "name": "impactMarket",
          "symbol": "PACT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21907/thumb/PACT_Token_Ticker_Blue_2x.png?1640236076"
        },
        {
          "chainId": 56,
          "address": "0xd691d9a68c887bdf34da8c36f63487333acfd103",
          "name": "Maverick Protocol",
          "symbol": "MAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30850/thumb/MAV_Logo.png?1687945366"
        },
        {
          "chainId": 56,
          "address": "0xc5f0f7b66764f6ec8c8dff7ba683102295e16409",
          "name": "First Digital USD",
          "symbol": "FDUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31079/thumb/firstfigital.jpeg?1690347713"
        },
        {
          "chainId": 56,
          "address": "0x382ec3f9f2e79b03abf0127f3aa985b148cef6d7",
          "name": "Zenland",
          "symbol": "ZENF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29477/thumb/tokenicon200px.png?1679130231"
        },
        {
          "chainId": 56,
          "address": "0x4e22ab2bbcb3e7f74249c87f62bb35ef92c3d964",
          "name": "Black Phoenix",
          "symbol": "BPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16948/thumb/photo_2021-05-11_01-35-19.jpg?1625721219"
        },
        {
          "chainId": 56,
          "address": "0xb3ed0a426155b79b898849803e3b36552f7ed507",
          "name": "Pendle",
          "symbol": "PENDLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15069/thumb/Pendle_Logo_Normal-03.png?1634196276"
        },
        {
          "chainId": 56,
          "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
          "name": "XDAO",
          "symbol": "XDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27363/thumb/token_2.png?1688375985"
        },
        {
          "chainId": 56,
          "address": "0x29df52dbd2a73ae6f4ee3a397fd7706216af12da",
          "name": "ZilPepe",
          "symbol": "ZILPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30166/thumb/logo.png?1683532207"
        },
        {
          "chainId": 56,
          "address": "0x8ec1877698acf262fe8ad8a295ad94d6ea258988",
          "name": "Davos Protocol",
          "symbol": "DUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28775/thumb/dusd_logo_200x200.png?1685432656"
        },
        {
          "chainId": 56,
          "address": "0x4d3dc895a9edb234dfa3e303a196c009dc918f84",
          "name": "Zeebu",
          "symbol": "ZBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31145/thumb/200x200_Front_token.png?1691028767"
        },
        {
          "chainId": 56,
          "address": "0xca6d678e74f553f0e59cccc03ae644a3c2c5ee7d",
          "name": "Planet Token",
          "symbol": "PLANET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30767/thumb/Logo-Planet.png?1686903378"
        },
        {
          "chainId": 56,
          "address": "0xaa076b62efc6f357882e07665157a271ab46a063",
          "name": "Pleasure Coin",
          "symbol": "NSFW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15315/thumb/pleasurecoin-icon.png?1648724879"
        },
        {
          "chainId": 56,
          "address": "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
          "name": "Fantom Bomb",
          "symbol": "BOMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24109/thumb/logo-blue.png?1646376874"
        },
        {
          "chainId": 56,
          "address": "0xd2e7b964770fcf51df088a5f0bb2d33a3c60cccf",
          "name": "Ispolink",
          "symbol": "ISP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG?1620352267"
        },
        {
          "chainId": 56,
          "address": "0x94c6b279b5df54b335ae51866d6e2a56bf5ef9b7",
          "name": "Morphex",
          "symbol": "MPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28965/thumb/mpx_logo_256.png?1675744910"
        },
        {
          "chainId": 56,
          "address": "0xb7bda6a89e724f63572ce68fddc1a6d1d5d24bcf",
          "name": "OGzClub",
          "symbol": "OGZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31121/thumb/25832.png?1690772601"
        },
        {
          "chainId": 56,
          "address": "0x61dc650c10ec3c758d251cd2d1ab45af1a43e941",
          "name": "Rangers Protocol",
          "symbol": "RPG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18791/thumb/tO8MlqiM_400x400.png?1633421196"
        },
        {
          "chainId": 56,
          "address": "0x9cc83d580180f0d37d00e5d86ce868f73b6e3d0a",
          "name": "Bitcointry Token",
          "symbol": "BTTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28641/thumb/bitcointry_token_logo_100x100.png?1672839869"
        },
        {
          "chainId": 56,
          "address": "0x644192291cc835a93d6330b24ea5f5fedd0eef9e",
          "name": "AllianceBlock Nexera",
          "symbol": "NXRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29181/thumb/nxra.png?1677148772"
        },
        {
          "chainId": 56,
          "address": "0xe55d97a97ae6a17706ee281486e98a84095d8aaf",
          "name": "AIPad",
          "symbol": "AIPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28894/thumb/JZadeHu.jpeg?1675214438"
        },
        {
          "chainId": 56,
          "address": "0xf98b660adf2ed7d9d9d9daacc2fb0cace4f21835",
          "name": "Symbiosis",
          "symbol": "SIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20805/thumb/SymbiosisFinance_logo-150x150.jpeg?1637707064"
        },
        {
          "chainId": 56,
          "address": "0x1912a3504e59d1c1b060bf2d371deb00b70e8796",
          "name": "NFTEarth",
          "symbol": "NFTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29116/thumb/20230223_224134.jpg?1677224110"
        },
        {
          "chainId": 56,
          "address": "0x1d4268a58ee7ec2cc2af5d70a2fd2b3a896527a2",
          "name": "Arken Finance",
          "symbol": "ARKEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26137/thumb/arken.png?1678783976"
        },
        {
          "chainId": 56,
          "address": "0xfdc66a08b0d0dc44c17bbd471b88f49f50cdd20f",
          "name": "SmarDex",
          "symbol": "SDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29470/thumb/SDEX_logo_transparent.png?1690430205"
        },
        {
          "chainId": 56,
          "address": "0x52242cbab41e290e9e17ccc50cc437bb60020a9d",
          "name": "Wrapped NCG",
          "symbol": "WNCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17747/thumb/WNCG_Color.png?1676455921"
        },
        {
          "chainId": 56,
          "address": "0x374ca32fd7934c5d43240e1e73fa9b2283468609",
          "name": "Equilibria Finance",
          "symbol": "EQB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30645/thumb/QLLK8pmR_400x400.jpg?1686103901"
        },
        {
          "chainId": 56,
          "address": "0x637c2173f6e678ac3c9b43b6665c760dc6021c13",
          "name": "World Mobile Token",
          "symbol": "WMT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17333/thumb/Colored_Token.png?1627346708"
        },
        {
          "chainId": 56,
          "address": "0x63eaeb6e33e11252b10553900a9f38a9ed172871",
          "name": "Tenup",
          "symbol": "TUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6254/thumb/uphIf44J_400x400.jpg?1547042290"
        },
        {
          "chainId": 56,
          "address": "0x4d4d883f920f7c0c36a1be71a02aa0cde2aa22d1",
          "name": "Opticash",
          "symbol": "OPCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29362/thumb/opticash_logo_256*256.jpg?1678265000"
        },
        {
          "chainId": 56,
          "address": "0x56d06a78ef8e95d6043341f24759e2834be6f97b",
          "name": "Degen Zoo",
          "symbol": "DZOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29224/thumb/FAGWW5fe_400x400.jpeg?1677481253"
        },
        {
          "chainId": 56,
          "address": "0x117eefdde5e5aed6626ffedbb5d2ac955f64dbf3",
          "name": "StaFi Staked MATIC",
          "symbol": "RMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29610/thumb/rMATIC.png?1680074998"
        },
        {
          "chainId": 56,
          "address": "0x61b83edf87ea662c695439a807c386455c9e797c",
          "name": "Ignore Fud",
          "symbol": "4TOKEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29626/thumb/200x200.png?1680079963"
        },
        {
          "chainId": 56,
          "address": "0x3235b13708f178af6f110de7177ed5de10c1093d",
          "name": "Mongol NFT",
          "symbol": "MNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23718/thumb/swI93LWg_400x400.jpg?1645160446"
        },
        {
          "chainId": 56,
          "address": "0xce1e3cc1950d2aaeb47de04de2dec2dc86380e0a",
          "name": "Ethos Reserve Note",
          "symbol": "ERN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29744/thumb/ERN200x200.png?1681106290"
        },
        {
          "chainId": 56,
          "address": "0xde5ed76e7c05ec5e4572cfc88d1acea165109e44",
          "name": "DEUS Finance",
          "symbol": "DEUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18778/thumb/Black_Background_200x200.png?1681374184"
        },
        {
          "chainId": 56,
          "address": "0x6f769e65c14ebd1f68817f5f1dcdb61cfa2d6f7e",
          "name": "ARPA",
          "symbol": "ARPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357"
        },
        {
          "chainId": 56,
          "address": "0x87837b7b4850687e200254f78c0af0a34329a491",
          "name": "Lucretius",
          "symbol": "LUC",
          "decimals": 15,
          "logoURI": "https://assets.coingecko.com/coins/images/24205/thumb/luc.png?1646836215"
        },
        {
          "chainId": 56,
          "address": "0xae36155a55f04a696b8362777620027882b31db5",
          "name": "Kishimoto",
          "symbol": "KISHIMOTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28011/thumb/kishimoto.png?1666944015"
        },
        {
          "chainId": 56,
          "address": "0x7bd6fabd64813c48545c9c0e312a0099d9be2540",
          "name": "Dogelon Mars",
          "symbol": "ELON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413"
        },
        {
          "chainId": 56,
          "address": "0xa0ed3c520dc0632657ad2eaaf19e26c4fd431a84",
          "name": "Hippo Wallet",
          "symbol": "HPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200"
        },
        {
          "chainId": 56,
          "address": "0x3bc5ac0dfdc871b365d159f728dd1b9a0b5481e8",
          "name": "Stader",
          "symbol": "SD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20658/thumb/SD_Token_Logo.png?1684410841"
        },
        {
          "chainId": 56,
          "address": "0x6d6554939d646f274d0fc3cecb7dab5d76bc908f",
          "name": "Morphswap",
          "symbol": "MS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773"
        },
        {
          "chainId": 56,
          "address": "0x6eed9140f80f9e989cb23aecbd20b97a29ffc80f",
          "name": "LEAP Token",
          "symbol": "LEAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png?1663053803"
        },
        {
          "chainId": 56,
          "address": "0xeb52620b04e8eacfd795353f2827673887f292e0",
          "name": "GOLCOIN",
          "symbol": "GOLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png?1663551501"
        },
        {
          "chainId": 56,
          "address": "0x4268b8f0b87b6eae5d897996e6b845ddbd99adf3",
          "name": "Axelar USDC",
          "symbol": "AXLUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26476/thumb/uausdc_D_3x.png?1690776252"
        },
        {
          "chainId": 56,
          "address": "0xf538030ba4b39e35a3576bd6698cfcc6ac34a81f",
          "name": "NEMO",
          "symbol": "NEMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27473/thumb/NEMO_logo.png?1672731824"
        },
        {
          "chainId": 56,
          "address": "0x1851ccd370c444ff494d7505e6103959bce9f9d9",
          "name": "ONUS",
          "symbol": "ONUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24599/thumb/ONUS_200.png?1669452755"
        },
        {
          "chainId": 56,
          "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
          "name": "BOB",
          "symbol": "BOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27266/thumb/Bob-logo.png?1663073030"
        },
        {
          "chainId": 56,
          "address": "0x44ec807ce2f4a6f2737a92e985f318d035883e47",
          "name": "Hashflow",
          "symbol": "HFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26136/thumb/hashflow-icon-cmc.png?1668345672"
        },
        {
          "chainId": 56,
          "address": "0x41080ca7be4b3f0cacbd95164e9a56b582382caa",
          "name": "ZOGI",
          "symbol": "ZOGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28446/thumb/ZOGI_Token_Logo.png?1670659477"
        },
        {
          "chainId": 56,
          "address": "0x4e7f408be2d4e9d60f49a64b89bb619c84c7c6f5",
          "name": "Perpetual Protocol",
          "symbol": "PERP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771"
        },
        {
          "chainId": 56,
          "address": "0x66207e39bb77e6b99aab56795c7c340c08520d83",
          "name": "Rupiah Token",
          "symbol": "IDRT",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531"
        },
        {
          "chainId": 56,
          "address": "0xebd949aacfc681787d3d091fa2929e4413e0e4e1",
          "name": "Arable Protocol",
          "symbol": "ACRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23659/thumb/acre_token-02.png?1644989289"
        },
        {
          "chainId": 56,
          "address": "0xad29abb318791d579433d831ed122afeaf29dcfe",
          "name": "Wrapped Fantom",
          "symbol": "WFTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16036/thumb/Fantom.png?1622679930"
        },
        {
          "chainId": 56,
          "address": "0x80640a39cfc2b1b7c792821c462376aa7083f5a8",
          "name": "Zombie Inu",
          "symbol": "ZINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28562/thumb/zinu_new.png?1671770347"
        },
        {
          "chainId": 56,
          "address": "0x444444444444c1a66f394025ac839a535246fcc8",
          "name": "Genius",
          "symbol": "GENI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28621/thumb/GENI200x200.png?1672707726"
        },
        {
          "chainId": 56,
          "address": "0x7ddc52c4de30e94be3a6a0a2b259b2850f421989",
          "name": "Gomining Token",
          "symbol": "GMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15662/thumb/gmt.png?1640069038"
        },
        {
          "chainId": 56,
          "address": "0x9f402f44684574f3535ea6f1bb5cfbffef42ee28",
          "name": "Prime Numbers Ecosystem",
          "symbol": "PRNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22669/thumb/primenumers_favicon_01.png?1685956148"
        },
        {
          "chainId": 56,
          "address": "0x9467f15f44a8641389556387b43d9ed3f6981818",
          "name": "Wrapped USDR",
          "symbol": "WUSDR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28800/thumb/wUSDRlogo.png?1674283345"
        },
        {
          "chainId": 56,
          "address": "0x80d2fe89b6c6c24edfb553daf35599649ac55283",
          "name": "LIF3",
          "symbol": "LIF3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26007/thumb/LIF3.png?1655279569"
        },
        {
          "chainId": 56,
          "address": "0xf70b6d6acd652612f24f7dd2ca2f1727eb20793a",
          "name": "LIF3 LSHARE",
          "symbol": "LSHARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26038/thumb/LSHARE.png?1655363885"
        },
        {
          "chainId": 56,
          "address": "0xdea12c8c23994ea2d88ed99ee1bdc0ff56f7f9d1",
          "name": "L3USD",
          "symbol": "L3USD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26937/thumb/L3USD.png?1660999834"
        },
        {
          "chainId": 56,
          "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
          "name": "Elk Finance",
          "symbol": "ELK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17813/thumb/elk.png?1629336971"
        },
        {
          "chainId": 56,
          "address": "0x3a9eed92422abdd7566fba8c34bb74b3f656dbb3",
          "name": "Kambria",
          "symbol": "KAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4784/thumb/kambria.png?1547040127"
        },
        {
          "chainId": 56,
          "address": "0x58b40ac5cbeeea651dc5512ea81a0bc8575f04a8",
          "name": "TIPO Token",
          "symbol": "TIPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28718/thumb/%281%29_TIPO_Token_logo.png?1673587215"
        },
        {
          "chainId": 56,
          "address": "0x71be881e9c5d4465b3fff61e89c6f3651e69b5bb",
          "name": "Brazilian Digital",
          "symbol": "BRZ",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/8472/thumb/MicrosoftTeams-image_%286%29.png?1674480131"
        },
        {
          "chainId": 56,
          "address": "0x2f29bc0ffaf9bff337b31cbe6cb5fb3bf12e5840",
          "name": "DOLA",
          "symbol": "DOLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14287/thumb/dola.png?1667738374"
        },
        {
          "chainId": 56,
          "address": "0x42f3008f6945f052c31e7680f7f78c512099b904",
          "name": "AllianceBlock",
          "symbol": "ALBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12392/thumb/alliance_block_logo.jpg?1599546617"
        },
        {
          "chainId": 56,
          "address": "0xd3c6ceedd1cc7bd4304f72b011d53441d631e662",
          "name": "OATH",
          "symbol": "OATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24075/thumb/OATH_ICON_Updated.png?1677061912"
        },
        {
          "chainId": 56,
          "address": "0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b",
          "name": "Onyxcoin",
          "symbol": "XCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24210/thumb/onyxlogo.jpg?1675049876"
        },
        {
          "chainId": 56,
          "address": "0xdde5b33a56f3f1c22e5a6bd8429e6ad508bff24e",
          "name": "VNDC",
          "symbol": "VNDC",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png?1571032826"
        },
        {
          "chainId": 56,
          "address": "0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52",
          "name": "Tarot",
          "symbol": "TAROT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17881/thumb/tarot-200px.png?1629704943"
        },
        {
          "chainId": 56,
          "address": "0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07",
          "name": "JOE",
          "symbol": "JOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17569/thumb/traderjoe.png?1685690062"
        },
        {
          "chainId": 56,
          "address": "0x8f01d597d2022656494e30fb76eccf1eea2e092e",
          "name": "Tomb",
          "symbol": "TOMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16133/thumb/tomb_icon_noBG.png?1623055476"
        },
        {
          "chainId": 56,
          "address": "0xce6c9c70f91c6797873efc80505f972290a88f5d",
          "name": "IceCreamSwap",
          "symbol": "ICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26237/thumb/icecream.png?1656902080"
        },
        {
          "chainId": 56,
          "address": "0xff2f5e8e796b6739ac9d73b8fe916568abb871b5",
          "name": "OxAI com",
          "symbol": "OXAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28992/thumb/oxai-logo-200.png?1675821031"
        },
        {
          "chainId": 56,
          "address": "0xe985e923b6c52b420dd33549a0ebc2cdeb0ae173",
          "name": "Mindsync",
          "symbol": "MAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7581/thumb/mindsync-logo.jpg?1548636745"
        },
        {
          "chainId": 56,
          "address": "0xf124ed9ec309907808b1fbc6bedb2a76927b3665",
          "name": "Empire Network",
          "symbol": "EMPIRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29240/thumb/empire.jpeg?1677572473"
        },
        {
          "chainId": 56,
          "address": "0x6c9297be2e3ce9c10c480a25b7157a43fd992942",
          "name": "Mean DAO",
          "symbol": "MEAN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21557/thumb/89934951.png?1639466364"
        },
        {
          "chainId": 56,
          "address": "0x74ddf70a11cfd3ecff3f78589c2233808b5ceebe",
          "name": "CryptoBank",
          "symbol": "CBEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29682/thumb/our_logo_%281%29.png?1680596042"
        },
        {
          "chainId": 56,
          "address": "0xa991fa7fa94dfe2e59d58954bce0f0a8e082710f",
          "name": "Alpha Intelligence",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29127/thumb/alpha.png?1677663278"
        },
        {
          "chainId": 56,
          "address": "0x1354e1c028ad93ed9992ee3bfd3ee5608489439c",
          "name": "Truefeedback",
          "symbol": "TFBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8842/thumb/5rd7a55q_400x400.png?1562902557"
        },
        {
          "chainId": 56,
          "address": "0x414f9e74ba3a9d0acce65182809492f41ac671e0",
          "name": "Jarvis Reward",
          "symbol": "JRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10390/thumb/cfeii0y.png?1578868949"
        },
        {
          "chainId": 56,
          "address": "0x842defb310cace2b923c1fd7b3db067d3d0fcc34",
          "name": "Cheems Inu  NEW ",
          "symbol": "CINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29393/thumb/cinu.png?1678506316"
        },
        {
          "chainId": 56,
          "address": "0x1e3c6c53f9f60bf8aae0d7774c21fa6b1afddc57",
          "name": "xShrap",
          "symbol": "XSHRAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29346/thumb/xshrapnel.png?1678253037"
        },
        {
          "chainId": 56,
          "address": "0x20b4620a767d6dccbb9314104d5cf0d08d1f7045",
          "name": "Kilopi",
          "symbol": "LOP",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29496/thumb/logo_kilopi_200sq.png?1679368797"
        },
        {
          "chainId": 56,
          "address": "0xeba457b55fb145ff4451bc50fb6c373e5caa493f",
          "name": "SoliMax",
          "symbol": "SLM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29440/thumb/slmlogo.png?1678864547"
        },
        {
          "chainId": 56,
          "address": "0x54012cdf4119de84218f7eb90eeb87e25ae6ebd7",
          "name": "Luffy",
          "symbol": "LUFFY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17736/thumb/LUFFY_LOGO.png?1668675291"
        },
        {
          "chainId": 56,
          "address": "0x1135883a1bc6776bf90c97845adc491922106dfb",
          "name": "Rejuve AI",
          "symbol": "RJV",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29366/thumb/2023_Rejuve_Logo_-_Square_-_Teal.jpg?1678345338"
        },
        {
          "chainId": 56,
          "address": "0x64048a7eecf3a2f1ba9e144aac3d7db6e58f555e",
          "name": "Frax Ether",
          "symbol": "FRXETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28284/thumb/frxETH_icon.png?1679886981"
        },
        {
          "chainId": 56,
          "address": "0x3cd55356433c89e50dc51ab07ee0fa0a95623d53",
          "name": "Staked Frax Ether",
          "symbol": "SFRXETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28285/thumb/sfrxETH_icon.png?1679886768"
        },
        {
          "chainId": 56,
          "address": "0x7588df009c3d82378be6ab81f2108fa963c10fc8",
          "name": "Step App",
          "symbol": "FITFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25015/thumb/801485424e1f49bc8d0facff9287eb9b_photo.png?1649827972"
        },
        {
          "chainId": 56,
          "address": "0xea917ec08ab64c030e67a80559ad569f48aa360a",
          "name": "Perpetual Wallet",
          "symbol": "PWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29648/thumb/logo.png?1680489735"
        },
        {
          "chainId": 56,
          "address": "0xa697e272a73744b343528c3bc4702f2565b2f422",
          "name": "Bonk",
          "symbol": "BONK",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/28600/thumb/bonk.jpg?1672304290"
        },
        {
          "chainId": 56,
          "address": "0x8f87a7d376821c7b2658a005aaf190ec778bf37a",
          "name": "Granary",
          "symbol": "GRAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29740/thumb/Grain.png?1681096624"
        },
        {
          "chainId": 56,
          "address": "0x818835503f55283cd51a4399f595e295a9338753",
          "name": "Delysium",
          "symbol": "AGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29299/thumb/AGI_logo_200.png?1677915840"
        },
        {
          "chainId": 56,
          "address": "0x2dd1b4d4548accea497050619965f91f78b3b532",
          "name": "Frax Price Index",
          "symbol": "FPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24945/thumb/FPI_icon.png?1679886853"
        },
        {
          "chainId": 56,
          "address": "0xd1738eb733a636d1b8665f48bc8a24da889c2562",
          "name": "Frax Price Index Share",
          "symbol": "FPIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24944/thumb/FPIS_icon.png?1679886817"
        },
        {
          "chainId": 56,
          "address": "0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df",
          "name": "Radiant Capital",
          "symbol": "RDNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26536/thumb/Radiant-Logo-200x200.png?1658715865"
        },
        {
          "chainId": 56,
          "address": "0xa026ad2ceda16ca5fc28fd3c72f99e2c332c8a26",
          "name": "XCAD Network",
          "symbol": "XCAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15857/thumb/logoWhiteX.jpg?1666230966"
        },
        {
          "chainId": 56,
          "address": "0xc2eb046621b59f604c7abdb1600d01636adc4fed",
          "name": "ZENEX",
          "symbol": "ZNX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29638/thumb/znx-logo.png?1680160898"
        },
        {
          "chainId": 56,
          "address": "0xa71dcaaf5ba390b1b2012323a8810fac121f90dd",
          "name": "SafeGrow",
          "symbol": "SFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29715/thumb/IMG_20230402_021040_191.png?1680768593"
        },
        {
          "chainId": 56,
          "address": "0x91d6d6af7635b7b23a8ced9508117965180e2362",
          "name": "unshETHing Token",
          "symbol": "USH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29337/thumb/unsheth_large_logo.png?1678175082"
        },
        {
          "chainId": 56,
          "address": "0x02030d968558fd429eafa6e5b0c7a95a4903233b",
          "name": "GoSleep NGT",
          "symbol": "NGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29826/thumb/NGT200_200.png?1681705868"
        },
        {
          "chainId": 56,
          "address": "0xd9907fcda91ac644f70477b8fc1607ad15b2d7a8",
          "name": "MultiBTC",
          "symbol": "MULTIBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29495/thumb/MultiBTC.png?1679364709"
        },
        {
          "chainId": 56,
          "address": "0x8276b85b1504c495d7eb7f8e37537644c6d64313",
          "name": "Mind Connect",
          "symbol": "MIND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29749/thumb/our_logo.png?1681112823"
        },
        {
          "chainId": 56,
          "address": "0xbc648cbd7b2b2c666f9f46ac5c5ce6ee77f9c407",
          "name": "Work Quest",
          "symbol": "WQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30040/thumb/WQT-1_%281%29.png?1682991564"
        },
        {
          "chainId": 56,
          "address": "0x31491c35c094a0336f4859dd94ab9466709dec45",
          "name": "COLLIE INU",
          "symbol": "COLLIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27365/thumb/Logo_New_200.png?1678340658"
        },
        {
          "chainId": 56,
          "address": "0x25d887ce7a35172c62febfd67a1856f20faebb00",
          "name": "Pepe",
          "symbol": "PEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29850/thumb/pepe-token.jpeg?1682922725"
        },
        {
          "chainId": 56,
          "address": "0x0b9bdcc696efa768cafe0e675525eaf42e32d108",
          "name": "GoSleep ZZZ",
          "symbol": "ZZZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29901/thumb/ZZZ200_200.png?1681984063"
        },
        {
          "chainId": 56,
          "address": "0x2f4b36442687e943e587b45cccc92d60c3c3502f",
          "name": "BedlingtonTerrierToken",
          "symbol": "BETT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29955/thumb/logo-e1681720498919.png?1682383849"
        },
        {
          "chainId": 56,
          "address": "0x9ed7e4b1bff939ad473da5e7a218c771d1569456",
          "name": "Reunit Wallet",
          "symbol": "REUNI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29492/thumb/reunit_200.png?1679280627"
        },
        {
          "chainId": 56,
          "address": "0xa7bd657c5838472ddf85ff0797a2e6fce8fd4833",
          "name": "ArbiPad",
          "symbol": "ARBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30275/thumb/ARBI_Logo.png?1684141736"
        },
        {
          "chainId": 56,
          "address": "0x027a9d301fb747cd972cfb29a63f3bda551dfc5c",
          "name": "Magpie WOM",
          "symbol": "MWOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29924/thumb/mWOM.png?1682135927"
        },
        {
          "chainId": 56,
          "address": "0x7881cd2b5724431372f57c50e91611352557a606",
          "name": "HyperCycle",
          "symbol": "HYPC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29917/thumb/HyperCycle-Logo-2022_icon_copy_%281%29.png?1682050109"
        },
        {
          "chainId": 56,
          "address": "0xa2e3356610840701bdf5611a53974510ae27e2e1",
          "name": "Wrapped Beacon ETH",
          "symbol": "WBETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30061/thumb/wbeth-icon.png?1683001548"
        },
        {
          "chainId": 56,
          "address": "0xe5c6155ed2924e50f998e28eff932d9b5a126974",
          "name": "LiquidDriver",
          "symbol": "LQDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15782/thumb/LQDR_Glowing_Icon.png?1631200053"
        },
        {
          "chainId": 56,
          "address": "0x69a87c8788d4a48c1362b3b357d0e6b59c11d93f",
          "name": "Orbofi AI",
          "symbol": "OBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30216/thumb/new_V_test2.png?1683626303"
        },
        {
          "chainId": 56,
          "address": "0xc03fbf20a586fa89c2a5f6f941458e1fbc40c661",
          "name": "COMBO",
          "symbol": "COMBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4932/thumb/COMBO.jpg?1685695824"
        },
        {
          "chainId": 56,
          "address": "0xffa188493c15dfaf2c206c97d8633377847b6a52",
          "name": "Wefi Finance",
          "symbol": "WEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30540/thumb/wefi.png?1684981490"
        },
        {
          "chainId": 56,
          "address": "0xe05a08226c49b636acf99c40da8dc6af83ce5bb3",
          "name": "Ankr Staked ETH",
          "symbol": "ANKRETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13403/thumb/aETHc.png?1625756490"
        },
        {
          "chainId": 56,
          "address": "0x9c08951397bc02cd66f69eadbb8d491f8bb08c5e",
          "name": "Planq",
          "symbol": "PLQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29100/thumb/Planq_256x256-circle.png?1676604362"
        },
        {
          "chainId": 56,
          "address": "0x111111111117dc0aa78b770fa6a738034120c302",
          "name": "1inch",
          "symbol": "1INCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028"
        },
        {
          "chainId": 56,
          "address": "0x5b6ebb33eea2d12eefd4a9b2aeaf733231169684",
          "name": "WELD",
          "symbol": "WELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18544/thumb/weld.png?1661497968"
        },
        {
          "chainId": 56,
          "address": "0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9",
          "name": "Mirror Protocol",
          "symbol": "MIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658"
        },
        {
          "chainId": 56,
          "address": "0xd632bd021a07af70592ce1e18717ab9aa126decb",
          "name": "Kangal",
          "symbol": "KANGAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1622341641"
        },
        {
          "chainId": 56,
          "address": "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
          "name": "KIRA Network",
          "symbol": "KEX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13232/thumb/avatar.png?1622601779"
        },
        {
          "chainId": 56,
          "address": "0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5",
          "name": "Spaceswap MILK2",
          "symbol": "MILK2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12771/thumb/milk.png?1633423719"
        },
        {
          "chainId": 56,
          "address": "0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0",
          "name": "Spaceswap SHAKE",
          "symbol": "SHAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12765/thumb/shake.png?1633423725"
        },
        {
          "chainId": 56,
          "address": "0x3d04edc843e74935c09f54cc4b2fe1870e347ac9",
          "name": "Curio Governance",
          "symbol": "CGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891"
        },
        {
          "chainId": 56,
          "address": "0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d",
          "name": "SuperVerse",
          "symbol": "SUPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14040/thumb/SuperVerse_Logo_200x200.png?1669963064"
        },
        {
          "chainId": 56,
          "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
          "name": "Binance USD",
          "symbol": "BUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766"
        },
        {
          "chainId": 56,
          "address": "0xeca41281c24451168a37211f0bc2b8645af45092",
          "name": "TokenPocket Token",
          "symbol": "TPT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/7603/thumb/pocket.jpg?1658907018"
        },
        {
          "chainId": 56,
          "address": "0x2ed9e96edd11a1ff5163599a66fb6f1c77fa9c66",
          "name": "Lead",
          "symbol": "LEAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg?1599491466"
        },
        {
          "chainId": 56,
          "address": "0x55d398326f99059ff775485246999027b3197955",
          "name": "Tether",
          "symbol": "USDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663"
        },
        {
          "chainId": 56,
          "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
          "name": "USD Coin",
          "symbol": "USDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389"
        },
        {
          "chainId": 56,
          "address": "0x8519ea49c997f50ceffa444d240fb655e89248aa",
          "name": "RAMP  OLD ",
          "symbol": "RAMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12837/thumb/RAMP-Logo-v2-1000pxsq.png?1617952606"
        },
        {
          "chainId": 56,
          "address": "0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506",
          "name": "UNCX Network",
          "symbol": "UNCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12871/thumb/uncx.png?1676952157"
        },
        {
          "chainId": 56,
          "address": "0x03ff0ff224f904be3118461335064bb48df47938",
          "name": "Wrapped One",
          "symbol": "WONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18183/thumb/wonelogo.png?1630919349"
        },
        {
          "chainId": 56,
          "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
          "name": "Dai",
          "symbol": "DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9956/thumb/Badge_Dai.png?1687143508"
        },
        {
          "chainId": 56,
          "address": "0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e",
          "name": "Reef",
          "symbol": "REEF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png?1610534130"
        },
        {
          "chainId": 56,
          "address": "0x92d7756c60dcfd4c689290e8a9f4d263b3b32241",
          "name": "BoringDAO  OLD ",
          "symbol": "BOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12917/thumb/bor_logo.png?1603607502"
        },
        {
          "chainId": 56,
          "address": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
          "name": "SXP",
          "symbol": "SXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311"
        },
        {
          "chainId": 56,
          "address": "0xac51066d7bec65dc4589368da368b212745d63e8",
          "name": "My Neighbor Alice",
          "symbol": "ALICE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968"
        },
        {
          "chainId": 56,
          "address": "0xe4a4ad6e0b773f47d28f548742a23efd73798332",
          "name": "GNY",
          "symbol": "GNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5300/thumb/GNY_LOGO_NEW_TRANS.png?1675592825"
        },
        {
          "chainId": 56,
          "address": "0xfdfd27ae39cebefdbaac8615f18aa68ddd0f15f5",
          "name": "Giftedhands",
          "symbol": "GHD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856"
        },
        {
          "chainId": 56,
          "address": "0xb2343143f814639c9b1f42961c698247171df34a",
          "name": "CORE MultiChain",
          "symbol": "CMCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png?1633590798"
        },
        {
          "chainId": 56,
          "address": "0x772722b55cdc2a086abd064267a17855eb15e8b3",
          "name": "Karus Starter",
          "symbol": "KST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png?1645962885"
        },
        {
          "chainId": 56,
          "address": "0x6fefd97f328342a8a840546a55fdcfee7542f9a8",
          "name": "BitBase Token",
          "symbol": "BTBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG?1627596633"
        },
        {
          "chainId": 56,
          "address": "0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5",
          "name": "Autobahn Network",
          "symbol": "TXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12432/thumb/txl.png?1646463631"
        },
        {
          "chainId": 56,
          "address": "0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75",
          "name": "Juggernaut",
          "symbol": "JGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png?1602428976"
        },
        {
          "chainId": 56,
          "address": "0x7af173f350d916358af3e218bdf2178494beb748",
          "name": "Unitrade",
          "symbol": "TRADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11982/thumb/unitrade.PNG?1597009487"
        },
        {
          "chainId": 56,
          "address": "0x0f9e4d49f25de22c2202af916b681fbb3790497b",
          "name": "PERL eco",
          "symbol": "PERL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4682/thumb/PERL.eco-Icon-green_6x.png?1624365340"
        },
        {
          "chainId": 56,
          "address": "0x393d87e44c7b1f5ba521b351532c24ece253b849",
          "name": "Blind Boxes",
          "symbol": "BLES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14537/thumb/BLES-Logo-BW.png?1637158078"
        },
        {
          "chainId": 56,
          "address": "0xa6f7645ed967faf708a614a2fca8d4790138586f",
          "name": "MoonieNFT",
          "symbol": "MNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png?1631026813"
        },
        {
          "chainId": 56,
          "address": "0xc2c23a86def9e9f5972a633b3d25f7ecbfa5e575",
          "name": "UniLayer",
          "symbol": "LAYER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12164/thumb/logo-layer.jpg?1674822983"
        },
        {
          "chainId": 56,
          "address": "0x6e9730ecffbed43fd876a264c982e254ef05a0de",
          "name": "Nord Finance",
          "symbol": "NORD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136"
        },
        {
          "chainId": 56,
          "address": "0x755f34709e369d37c6fa52808ae84a32007d1155",
          "name": "Nabox",
          "symbol": "NABOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16445/thumb/NyemjVRA_400x400.png?1624235013"
        },
        {
          "chainId": 56,
          "address": "0x965b0df5bda0e7a0649324d78f03d5f7f2de086a",
          "name": "Cook",
          "symbol": "COOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14603/thumb/logo-200x200.jpg?1622448330"
        },
        {
          "chainId": 56,
          "address": "0x12e34cdf6a031a10fe241864c32fb03a4fdad739",
          "name": "FREEdom coin",
          "symbol": "FREE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5585/thumb/free.png?1639547764"
        },
        {
          "chainId": 56,
          "address": "0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd",
          "name": "LTO Network",
          "symbol": "LTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6068/thumb/lto.png?1638855565"
        },
        {
          "chainId": 56,
          "address": "0x80dba9c32b7ab5445e482387a5522e24c0ba4c24",
          "name": "extraDNA",
          "symbol": "XDNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830"
        },
        {
          "chainId": 56,
          "address": "0x566cedd201f67e542a6851a2959c1a449a041945",
          "name": "Opium",
          "symbol": "OPIUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960"
        },
        {
          "chainId": 56,
          "address": "0x5304adfd82e5a24b70fe7ac1f45fe50b1ab4cb1d",
          "name": "EsportsPro",
          "symbol": "ESPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245"
        },
        {
          "chainId": 56,
          "address": "0xc53708664b99df348dd27c3ac0759d2da9c40462",
          "name": "Gourmet Galaxy",
          "symbol": "GUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13846/thumb/gum.png?1612320864"
        },
        {
          "chainId": 56,
          "address": "0x22a213852cee93eb6d41601133414d180c5684c2",
          "name": "0xMonero",
          "symbol": "0XMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680"
        },
        {
          "chainId": 56,
          "address": "0x33d08d8c7a168333a85285a68c0042b39fc3741d",
          "name": "AIOZ Network",
          "symbol": "AIOZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14631/thumb/aioz-logo-200.png?1649237507"
        },
        {
          "chainId": 56,
          "address": "0x518445f0db93863e5e93a7f70617c05afa8048f1",
          "name": "BITT",
          "symbol": "BITT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961"
        },
        {
          "chainId": 56,
          "address": "0x314593fa9a2fa16432913dbccc96104541d32d11",
          "name": "DexKit",
          "symbol": "KIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13187/thumb/7739.png?1643187354"
        },
        {
          "chainId": 56,
          "address": "0x4b5c23cac08a567ecf0c1ffca8372a45a5d33743",
          "name": "Harvest Finance",
          "symbol": "FARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180"
        },
        {
          "chainId": 56,
          "address": "0xf35262a9d427f96d2437379ef090db986eae5d42",
          "name": "OPEN Governance",
          "symbol": "OPEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13233/thumb/opendao_logo.png?1606575207"
        },
        {
          "chainId": 56,
          "address": "0x7e35d0e9180bf3a1fc47b0d110be7a21a10b41fe",
          "name": "Ovr",
          "symbol": "OVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911"
        },
        {
          "chainId": 56,
          "address": "0x872a34ebb2d54af86827810eebc7b9dc6b2144aa",
          "name": "RocketX exchange",
          "symbol": "RVF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png?1668391022"
        },
        {
          "chainId": 56,
          "address": "0x0231f91e02debd20345ae8ab7d71a41f8e140ce7",
          "name": "Jupiter",
          "symbol": "JUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932"
        },
        {
          "chainId": 56,
          "address": "0xe48a3d7d0bc88d552f730b62c006bc925eadb9ee",
          "name": "Frax Share",
          "symbol": "FXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13423/thumb/Frax_Shares_icon.png?1679886947"
        },
        {
          "chainId": 56,
          "address": "0x33a3d962955a3862c8093d1273344719f03ca17c",
          "name": "Spore",
          "symbol": "SPORE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14470/thumb/SPORE.png?1616567184"
        },
        {
          "chainId": 56,
          "address": "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
          "name": "Dypius",
          "symbol": "DYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13480/thumb/DYP-200x200px.png?1670915896"
        },
        {
          "chainId": 56,
          "address": "0x44754455564474a89358b2c2265883df993b12f0",
          "name": "ZeroSwap",
          "symbol": "ZEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12861/thumb/logo.?1666110579"
        },
        {
          "chainId": 56,
          "address": "0xcd40f2670cf58720b694968698a5514e924f742d",
          "name": "Oddz",
          "symbol": "ODDZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14421/thumb/NewLogo.png?1645171454"
        },
        {
          "chainId": 56,
          "address": "0x99c6e435ec259a7e8d65e1955c9423db624ba54c",
          "name": "Finminity",
          "symbol": "FMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14696/thumb/finminity.png?1617834914"
        },
        {
          "chainId": 56,
          "address": "0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2",
          "name": "DODO",
          "symbol": "DODO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png?1601433025"
        },
        {
          "chainId": 56,
          "address": "0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0",
          "name": "Equalizer",
          "symbol": "EQZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png?1618005664"
        },
        {
          "chainId": 56,
          "address": "0x1f534d2b1ee2933f1fdf8e4b63a44b2249d77eaf",
          "name": "0 exchange",
          "symbol": "ZERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530"
        },
        {
          "chainId": 56,
          "address": "0x935a544bf5816e3a7c13db2efe3009ffda0acda2",
          "name": "Bluzelle",
          "symbol": "BLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510"
        },
        {
          "chainId": 56,
          "address": "0x155040625d7ae3e9cada9a73e3e44f76d3ed1409",
          "name": "Revomon",
          "symbol": "REVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14759/thumb/revomon.jpeg?1618243538"
        },
        {
          "chainId": 56,
          "address": "0x686318000d982bc8dcc1cdcf8ffd22322f0960ed",
          "name": "Opulous",
          "symbol": "OPUL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG?1624418744"
        },
        {
          "chainId": 56,
          "address": "0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3",
          "name": "disBalancer",
          "symbol": "DDOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138"
        },
        {
          "chainId": 56,
          "address": "0x9ba4c78b048eeed69f4ed3cfddeda7b51baf7ca8",
          "name": "Genesis Shards",
          "symbol": "GS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14784/thumb/gs.png?1618408218"
        },
        {
          "chainId": 56,
          "address": "0x35de111558f691f77f791fb0c08b2d6b931a9d47",
          "name": "Chain Games",
          "symbol": "CHAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12257/thumb/chain-pfp-logo.png?1671678582"
        },
        {
          "chainId": 56,
          "address": "0x4a080377f83d669d7bb83b3184a8a5e61b500608",
          "name": "Xend Finance",
          "symbol": "XEND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png?1616661216"
        },
        {
          "chainId": 56,
          "address": "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd",
          "name": "Razor Network",
          "symbol": "RAZOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13797/thumb/icon.png?1611919354"
        },
        {
          "chainId": 56,
          "address": "0x976e33b07565b0c05b08b2e13affd3113e3d178d",
          "name": "AGA",
          "symbol": "AGA",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396"
        },
        {
          "chainId": 56,
          "address": "0x23ec58e45ac5313bcb6681f4f7827b8a8453ac45",
          "name": "Zenfuse",
          "symbol": "ZEFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg?1602640333"
        },
        {
          "chainId": 56,
          "address": "0xa325ad6d9c92b55a3fc5ad7e412b1518f96441c0",
          "name": "Oraichain",
          "symbol": "ORAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12931/thumb/orai.png?1647077378"
        },
        {
          "chainId": 56,
          "address": "0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f",
          "name": "Exeedme",
          "symbol": "XED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13518/thumb/exeedme.png?1610669597"
        },
        {
          "chainId": 56,
          "address": "0xb49b7e0742ecb4240ffe91661d2a580677460b6a",
          "name": "PERI Finance",
          "symbol": "PERI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905"
        },
        {
          "chainId": 56,
          "address": "0x57effde2759b68d86c544e88f7977e3314144859",
          "name": "TosDis",
          "symbol": "DIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png?1611379744"
        },
        {
          "chainId": 56,
          "address": "0xd4fbc57b6233f268e7fba3b66e62719d74deecbc",
          "name": "Modefi",
          "symbol": "MOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13980/thumb/modefi_logo.png?1613453111"
        },
        {
          "chainId": 56,
          "address": "0x65e66a61d0a8f1e686c2d6083ad611a10d84d97a",
          "name": "Raze Network",
          "symbol": "RAZE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png?1623867120"
        },
        {
          "chainId": 56,
          "address": "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256",
          "name": "Unmarshal",
          "symbol": "MARSH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14554/thumb/img_circle_256x256.png?1616997706"
        },
        {
          "chainId": 56,
          "address": "0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff",
          "name": "DefiDollar DAO",
          "symbol": "DFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12959/thumb/DFD.jpg?1604415975"
        },
        {
          "chainId": 56,
          "address": "0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2",
          "name": "MANTRA",
          "symbol": "OM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12151/thumb/OM_Token.png?1666056782"
        },
        {
          "chainId": 56,
          "address": "0xbf776e4fca664d791c4ee3a71e2722990e003283",
          "name": "Smoothy",
          "symbol": "SMTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030"
        },
        {
          "chainId": 56,
          "address": "0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c",
          "name": "renBTC",
          "symbol": "RENBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11370/thumb/Bitcoin.jpg?1628072791"
        },
        {
          "chainId": 56,
          "address": "0x5857c96dae9cf8511b08cb07f85753c472d36ea3",
          "name": "Fuse",
          "symbol": "FUSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10347/thumb/fuse.png?1685331339"
        },
        {
          "chainId": 56,
          "address": "0xbf05279f9bf1ce69bbfed670813b7e431142afa4",
          "name": "Million",
          "symbol": "MM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16825/thumb/logo200x200.png?1625834139"
        },
        {
          "chainId": 56,
          "address": "0x8bd778b12b15416359a227f0533ce2d91844e1ed",
          "name": "SakeSwap",
          "symbol": "SAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402"
        },
        {
          "chainId": 56,
          "address": "0xd38c1b7b95d359978996e01b8a85286f65b3c011",
          "name": "Rage Fan",
          "symbol": "RAGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png?1627476357"
        },
        {
          "chainId": 56,
          "address": "0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3",
          "name": "Lien",
          "symbol": "LIEN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12224/thumb/Lien.png?1598262819"
        },
        {
          "chainId": 56,
          "address": "0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3",
          "name": "StackOS",
          "symbol": "STACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14989/thumb/stackos.jpg?1661420187"
        },
        {
          "chainId": 56,
          "address": "0x270388e0ca29cfd7c7e73903d9d933a23d1bab39",
          "name": "TradeStars",
          "symbol": "TSX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15229/thumb/WsO9siKG_400x400.png?1620167669"
        },
        {
          "chainId": 56,
          "address": "0x5512014efa6cd57764fa743756f7a6ce3358cc83",
          "name": "EasyFi V2",
          "symbol": "EZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12742/thumb/Logo_Icon.png?1624471467"
        },
        {
          "chainId": 56,
          "address": "0x0f237db17aa4e6de062e6f052bd9c805789b01c3",
          "name": "Covesting",
          "symbol": "COV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237"
        },
        {
          "chainId": 56,
          "address": "0x3028b4395f98777123c7da327010c40f3c7cc4ef",
          "name": "Auctus",
          "symbol": "AUC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355"
        },
        {
          "chainId": 56,
          "address": "0xcfb24d3c3767364391340a2e6d99c64f1cbd7a3d",
          "name": "Launchpool",
          "symbol": "LPOOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg?1613976219"
        },
        {
          "chainId": 56,
          "address": "0x557f20ce25b41640ade4a3085d42d7e626d7965a",
          "name": "LandBox",
          "symbol": "LAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129"
        },
        {
          "chainId": 56,
          "address": "0x8aa688ab789d1848d131c65d98ceaa8875d97ef1",
          "name": "MultiVAC",
          "symbol": "MTV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585"
        },
        {
          "chainId": 56,
          "address": "0x0e37d70b51ffa2b98b4d34a5712c5291115464e3",
          "name": "IQ",
          "symbol": "IQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5010/thumb/YAIS3fUh.png?1626267646"
        },
        {
          "chainId": 56,
          "address": "0xdbf8265b1d5244a13424f13977723acf5395eab2",
          "name": "Wagerr",
          "symbol": "WGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241"
        },
        {
          "chainId": 56,
          "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
          "name": "Lever Network",
          "symbol": "LEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15323/thumb/lever.PNG?1620513101"
        },
        {
          "chainId": 56,
          "address": "0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8",
          "name": "HOKK Finance",
          "symbol": "HOKK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14985/thumb/hokk.png?1662706232"
        },
        {
          "chainId": 56,
          "address": "0xa997e5aaae60987eb0b59a336dce6b158b113100",
          "name": "SeChain",
          "symbol": "SNN",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536"
        },
        {
          "chainId": 56,
          "address": "0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a",
          "name": "Adshares",
          "symbol": "ADS",
          "decimals": 11,
          "logoURI": "https://assets.coingecko.com/coins/images/868/thumb/rnO9DyJ.png?1663921311"
        },
        {
          "chainId": 56,
          "address": "0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5",
          "name": "RFOX",
          "symbol": "RFOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12956/thumb/rfox.png?1642926902"
        },
        {
          "chainId": 56,
          "address": "0x8683e604cdf911cd72652a04bf9d571697a86a60",
          "name": "EvidenZ",
          "symbol": "BCDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2014/thumb/evidenz-512.png?1594871754"
        },
        {
          "chainId": 56,
          "address": "0x0e8d5504bf54d9e44260f8d153ecd5412130cabb",
          "name": "UNCL",
          "symbol": "UNCL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945"
        },
        {
          "chainId": 56,
          "address": "0x833f307ac507d47309fd8cdd1f835bef8d702a93",
          "name": "REVV",
          "symbol": "REVV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390"
        },
        {
          "chainId": 56,
          "address": "0x50ea9c9f88aeab9f554b8ffb4d7a1017957e866a",
          "name": "Fox Trading",
          "symbol": "FOXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619"
        },
        {
          "chainId": 56,
          "address": "0xf55a93b613d172b86c2ba3981a849dae2aecde2f",
          "name": "Your Futures Exchange",
          "symbol": "YFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455"
        },
        {
          "chainId": 56,
          "address": "0x4518231a8fdf6ac553b9bbd51bbb86825b583263",
          "name": "Media Licensing Token",
          "symbol": "MLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15659/thumb/milc_200x200.png?1621511031"
        },
        {
          "chainId": 56,
          "address": "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
          "name": "KeyFi",
          "symbol": "KEYFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054"
        },
        {
          "chainId": 56,
          "address": "0xf4b5470523ccd314c6b9da041076e7d79e0df267",
          "name": "blockbank",
          "symbol": "BBANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg.png?1675767121"
        },
        {
          "chainId": 56,
          "address": "0x0a232cb2005bda62d3de7ab5deb3ffe4c456165a",
          "name": "Finance Vote",
          "symbol": "FVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010"
        },
        {
          "chainId": 56,
          "address": "0xd882739fca9cbae00f3821c4c65189e2d7e26147",
          "name": "4thpillar technologies",
          "symbol": "FOUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287"
        },
        {
          "chainId": 56,
          "address": "0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed",
          "name": "Raini",
          "symbol": "RAINI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png?1633314246"
        },
        {
          "chainId": 56,
          "address": "0xe95fd76cf16008c12ff3b3a937cb16cd9cc20284",
          "name": "Sensitrust",
          "symbol": "SETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990"
        },
        {
          "chainId": 56,
          "address": "0x9b17baadf0f21f03e35249e0e59723f34994f806",
          "name": "inSure DeFi",
          "symbol": "SURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406"
        },
        {
          "chainId": 56,
          "address": "0x08037036451c768465369431da5c671ad9b37dbc",
          "name": "NFT Stars",
          "symbol": "NFTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png?1623116004"
        },
        {
          "chainId": 56,
          "address": "0x93ab30c08421750d5c7993fb621c6ff32fe3f89e",
          "name": "FrogeX",
          "symbol": "FROGEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907"
        },
        {
          "chainId": 56,
          "address": "0x9fdc3ae5c814b79dca2556564047c5e7e5449c19",
          "name": "Decentral Games  Old ",
          "symbol": "DG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13267/thumb/%28Old%29_DG.png?1639209538"
        },
        {
          "chainId": 56,
          "address": "0x25a528af62e56512a19ce8c3cab427807c28cc19",
          "name": "Formation FI",
          "symbol": "FORM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16145/thumb/FORM.png?1623119824"
        },
        {
          "chainId": 56,
          "address": "0x3a6e8b36645d6c252714daddd28ec0673535a109",
          "name": "2crazyNFT",
          "symbol": "2CRZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16797/thumb/2crz.png?1635525909"
        },
        {
          "chainId": 56,
          "address": "0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30",
          "name": "InsurAce",
          "symbol": "INSUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14226/thumb/insur.png?1615124622"
        },
        {
          "chainId": 56,
          "address": "0xc49dde62b4a0810074721faca54aab52369f486a",
          "name": "Polker",
          "symbol": "PKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png?1625589565"
        },
        {
          "chainId": 56,
          "address": "0xb86abcb37c3a4b64f74f59301aff131a1becc787",
          "name": "Zilliqa",
          "symbol": "ZIL",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/2687/thumb/Zilliqa-logo.png?1547036894"
        },
        {
          "chainId": 56,
          "address": "0x82d2f8e02afb160dd5a480a617692e62de9038c4",
          "name": "Aleph im",
          "symbol": "ALEPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11676/thumb/Aleph-Logo-BW.png?1683257123"
        },
        {
          "chainId": 56,
          "address": "0xb1ce906c610004e27e74415aa9bcc90e46366f90",
          "name": "Dynamite",
          "symbol": "DYNMT",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224"
        },
        {
          "chainId": 56,
          "address": "0x959229d94c9060552daea25ac17193bca65d7884",
          "name": "IOI",
          "symbol": "IOI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701"
        },
        {
          "chainId": 56,
          "address": "0xc70636a779118e57e1c6fdafdd1f919fae912d2f",
          "name": "Munch",
          "symbol": "MUNCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png?1618532828"
        },
        {
          "chainId": 56,
          "address": "0x141383cdb8158982fb3469c3e49cc82f8026d968",
          "name": "CorionX",
          "symbol": "CORX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405"
        },
        {
          "chainId": 56,
          "address": "0xc9457161320210d22f0d0d5fc1309acb383d4609",
          "name": "Dovu",
          "symbol": "DOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1072/thumb/Dovu_Earth_72x72_leaf_blue.png?1615259361"
        },
        {
          "chainId": 56,
          "address": "0x54c2c07b3af037567269ad6a168d5bd527867b58",
          "name": "ZUM",
          "symbol": "ZUM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005"
        },
        {
          "chainId": 56,
          "address": "0x064c8e55aa484adbd58ca2d43343ef50137473b7",
          "name": "MobieCoin",
          "symbol": "MBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697"
        },
        {
          "chainId": 56,
          "address": "0xd85ad783cc94bd04196a13dc042a3054a9b52210",
          "name": "TribeOne",
          "symbol": "HAKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16575/thumb/USqW1QX.png?1625577555"
        },
        {
          "chainId": 56,
          "address": "0xfd5af95c12446b60d23e16a4ea95690ce942e5dc",
          "name": "Freela",
          "symbol": "FREL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15856/thumb/freela.PNG?1622164474"
        },
        {
          "chainId": 56,
          "address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
          "name": "WOO Network",
          "symbol": "WOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367"
        },
        {
          "chainId": 56,
          "address": "0xf7686f43591302cd9b4b9c4fe1291473fae7d9c9",
          "name": "Lossless",
          "symbol": "LSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15917/thumb/Group_57.png?1623046307"
        },
        {
          "chainId": 56,
          "address": "0x1796ae0b0fa4862485106a0de9b654efe301d0b2",
          "name": "Polychain Monsters",
          "symbol": "PMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png?1617238350"
        },
        {
          "chainId": 56,
          "address": "0x19c91764a976ac6c1e2c2e4c5856f2939342a814",
          "name": "Parachute",
          "symbol": "PAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png?1560918207"
        },
        {
          "chainId": 56,
          "address": "0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3",
          "name": "Mask Network",
          "symbol": "MASK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316"
        },
        {
          "chainId": 56,
          "address": "0x09e889bb4d5b474f561db0491c38702f367a4e4d",
          "name": "Clover Finance",
          "symbol": "CLV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15278/thumb/photo_2022-03-24_10-22-33.jpg?1648531786"
        },
        {
          "chainId": 56,
          "address": "0x43a167b15a6f24913a8b4d35488b36ac15d39200",
          "name": "PumaPay",
          "symbol": "PMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png?1622176373"
        },
        {
          "chainId": 56,
          "address": "0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0",
          "name": "One",
          "symbol": "ONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png?1561103318"
        },
        {
          "chainId": 56,
          "address": "0x0a356f512f6fce740111ee04ab1699017a908680",
          "name": "UniFarm",
          "symbol": "UFARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765"
        },
        {
          "chainId": 56,
          "address": "0x9001fd53504f7bf253296cffadf5b6030cd61abb",
          "name": "CyberFM",
          "symbol": "CYFM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216"
        },
        {
          "chainId": 56,
          "address": "0xb32d4817908f001c2a53c15bff8c14d8813109be",
          "name": "smARTOFGIVING",
          "symbol": "AOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007"
        },
        {
          "chainId": 56,
          "address": "0x681fd3e49a6188fc526784ee70aa1c269ee2b887",
          "name": "Franklin",
          "symbol": "FLY",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14810/thumb/fly_logo_sq_bArtboard_4.png?1626420796"
        },
        {
          "chainId": 56,
          "address": "0x715d400f88c167884bbcc41c5fea407ed4d2f8a0",
          "name": "Axie Infinity",
          "symbol": "AXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082"
        },
        {
          "chainId": 56,
          "address": "0x1861c9058577c3b48e73d91d6f25c18b17fbffe0",
          "name": "DSLA Protocol",
          "symbol": "DSLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063"
        },
        {
          "chainId": 56,
          "address": "0xe60eaf5a997dfae83739e035b005a33afdcc6df5",
          "name": "Deri Protocol",
          "symbol": "DERI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg?1627649443"
        },
        {
          "chainId": 56,
          "address": "0xcaf5191fc480f43e4df80106c7695eca56e48b18",
          "name": "DEAPCOIN",
          "symbol": "DEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677"
        },
        {
          "chainId": 56,
          "address": "0xffba7529ac181c2ee1844548e6d7061c9a597df4",
          "name": "Ternoa",
          "symbol": "CAPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15921/thumb/e55393fa-7b4d-40f5-9f36-9a8a6bdcb570.png?1622430581"
        },
        {
          "chainId": 56,
          "address": "0x373e768f79c820aa441540d254dca6d045c6d25b",
          "name": "DeRace",
          "symbol": "DERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17438/thumb/DERC_logo_coingecko.png?1665714278"
        },
        {
          "chainId": 56,
          "address": "0x4399ae7538c33ca24edd4c28c5dd7ce9a80acf81",
          "name": "Phantom Protocol",
          "symbol": "PHM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG?1631137732"
        },
        {
          "chainId": 56,
          "address": "0xc1165227519ffd22fdc77ceb1037b9b284eef068",
          "name": "BNSD Finance",
          "symbol": "BNSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388"
        },
        {
          "chainId": 56,
          "address": "0xfb288d60d3b66f9c3e231a9a39ed3f158a4269aa",
          "name": "Plasma Finance",
          "symbol": "PPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849"
        },
        {
          "chainId": 56,
          "address": "0x180dae91d6d56235453a892d2e56a3e40ba81df8",
          "name": "DOJO",
          "symbol": "DOJO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16396/thumb/074606deafab3872.png?1623910120"
        },
        {
          "chainId": 56,
          "address": "0x3a06212763caf64bf101daa4b0cebb0cd393fa1a",
          "name": "delta theta",
          "symbol": "DLTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png?1621570627"
        },
        {
          "chainId": 56,
          "address": "0xad86d0e9764ba90ddd68747d64bffbd79879a238",
          "name": "PAID Network",
          "symbol": "PAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13761/thumb/512.png?1648630881"
        },
        {
          "chainId": 56,
          "address": "0xdaacb0ab6fb34d24e8a67bfa14bf4d95d4c7af92",
          "name": "pNetwork",
          "symbol": "PNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11659/thumb/pNetwork.png?1592411134"
        },
        {
          "chainId": 56,
          "address": "0x5c4bcc4dbaeabc7659f6435bce4e659314ebad87",
          "name": "NuNet",
          "symbol": "NTX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20950/thumb/8Zb2W2Wi_400x400.png?1638137477"
        },
        {
          "chainId": 56,
          "address": "0x9617857e191354dbea0b714d78bc59e57c411087",
          "name": "Lympo Market",
          "symbol": "LMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15155/thumb/coin_%282%29.png?1619995483"
        },
        {
          "chainId": 56,
          "address": "0x52d88a9a2a20a840d7a336d21e427e9ad093deea",
          "name": "Husky AVAX",
          "symbol": "HUSKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17812/thumb/husky.png?1629721413"
        },
        {
          "chainId": 56,
          "address": "0xa2120b9e674d3fc3875f415a7df52e382f141225",
          "name": "Automata",
          "symbol": "ATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745"
        },
        {
          "chainId": 56,
          "address": "0x7283dfa2d8d7e277b148cc263b5d8ae02f1076d3",
          "name": "Garlicoin",
          "symbol": "GRLC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2699/thumb/garlicoin.png?1548126827"
        },
        {
          "chainId": 56,
          "address": "0xd21d29b38374528675c34936bf7d5dd693d2a577",
          "name": "PARSIQ",
          "symbol": "PRQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280"
        },
        {
          "chainId": 56,
          "address": "0x3b198e26e473b8fab2085b37978e36c9de5d7f68",
          "name": "chrono tech",
          "symbol": "TIME",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666"
        },
        {
          "chainId": 56,
          "address": "0x198abb2d13faa2e52e577d59209b5c23c20cd6b3",
          "name": "Bamboo DeFi",
          "symbol": "BAMBOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13856/thumb/LOGO_BAMBOO_PNG.png?1686888830"
        },
        {
          "chainId": 56,
          "address": "0x846f52020749715f02aef25b5d1d65e48945649d",
          "name": "Umbrella Network",
          "symbol": "UMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png?1612836176"
        },
        {
          "chainId": 56,
          "address": "0x810ee35443639348adbbc467b33310d2ab43c168",
          "name": "Cyclone Protocol",
          "symbol": "CYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14065/thumb/b3_DFjFp_400x400.jpg?1614128740"
        },
        {
          "chainId": 56,
          "address": "0xb3d691125514db7a5be3326af86a72ecdc2cde16",
          "name": "Zoo",
          "symbol": "ZOOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316"
        },
        {
          "chainId": 56,
          "address": "0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d",
          "name": "PureFi",
          "symbol": "UFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG?1627362147"
        },
        {
          "chainId": 56,
          "address": "0xd3cceb42b544e91eee02eb585cc9a7b47247bfde",
          "name": "Dtube Coin",
          "symbol": "DTUBE",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467"
        },
        {
          "chainId": 56,
          "address": "0x3c1748d647e6a56b37b66fcd2b5626d0461d3aa0",
          "name": "DinoX",
          "symbol": "DNXC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png?1627292452"
        },
        {
          "chainId": 56,
          "address": "0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe",
          "name": "Chromia",
          "symbol": "CHR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018"
        },
        {
          "chainId": 56,
          "address": "0xa4838122c683f732289805fc3c207febd55babdd",
          "name": "TriasLab",
          "symbol": "TRIAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958"
        },
        {
          "chainId": 56,
          "address": "0x031b41e504677879370e9dbcf937283a8691fa7f",
          "name": "Fetch ai",
          "symbol": "FET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136"
        },
        {
          "chainId": 56,
          "address": "0x7bc75e291e656e8658d66be1cc8154a3769a35dd",
          "name": "iMe Lab",
          "symbol": "LIME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16243/thumb/lim_200.2.png?1623376205"
        },
        {
          "chainId": 56,
          "address": "0x0b33542240d6fa323c796749f6d6869fdb7f13ca",
          "name": "Ethereum Meta",
          "symbol": "ETHM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409"
        },
        {
          "chainId": 56,
          "address": "0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442",
          "name": "ETHPad",
          "symbol": "ETHPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg?1628075612"
        },
        {
          "chainId": 56,
          "address": "0x422e3af98bc1de5a1838be31a56f75db4ad43730",
          "name": "CoinWind",
          "symbol": "COW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15766/thumb/logo.c3d2c062.png?1621827743"
        },
        {
          "chainId": 56,
          "address": "0xedf3ce4dd6725650a8e9398e5c6398d061fa7955",
          "name": "VEMP",
          "symbol": "VEMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18074/thumb/VEMP_Token_200x200.png?1672996797"
        },
        {
          "chainId": 56,
          "address": "0x1b6609830c695f1c0692123bd2fd6d01f6794b98",
          "name": "Pocket Arena",
          "symbol": "POC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8731/thumb/poc.png?1638521756"
        },
        {
          "chainId": 56,
          "address": "0x6ff1bfa14a57594a5874b37ff6ac5efbd9f9599a",
          "name": "TotemFi",
          "symbol": "TOTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704"
        },
        {
          "chainId": 56,
          "address": "0xbd83010eb60f12112908774998f65761cf9f6f9a",
          "name": "Mogul Productions",
          "symbol": "STARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520"
        },
        {
          "chainId": 56,
          "address": "0xc0c6e4c6e70c6231b20979bda581a66f062a7967",
          "name": "Atari",
          "symbol": "ATRI",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png?1643189483"
        },
        {
          "chainId": 56,
          "address": "0x873801ae2ff12d816db9a7b082f5796bec64c82c",
          "name": "DAO Invest",
          "symbol": "VEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511"
        },
        {
          "chainId": 56,
          "address": "0x947950bcc74888a40ffa2593c5798f11fc9124c4",
          "name": "Sushi",
          "symbol": "SUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688"
        },
        {
          "chainId": 56,
          "address": "0x076ddce096c93dcf5d51fe346062bf0ba9523493",
          "name": "Paralink Network",
          "symbol": "PARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15789/thumb/para.PNG?1621893038"
        },
        {
          "chainId": 56,
          "address": "0x0ebd9537a25f56713e34c45b38f421a1e7191469",
          "name": "dotmoovs",
          "symbol": "MOOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626"
        },
        {
          "chainId": 56,
          "address": "0x9ba6a67a6f3b21705a46b380a1b97373a33da311",
          "name": "FEAR",
          "symbol": "FEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865"
        },
        {
          "chainId": 56,
          "address": "0xfa262f303aa244f9cc66f312f0755d89c3793192",
          "name": "Rigel Protocol",
          "symbol": "RGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15837/thumb/A_qRYvB2_400x400.png?1622080940"
        },
        {
          "chainId": 56,
          "address": "0x5e57f24415f37c7d304e85df9b4c36bc08789794",
          "name": "Barter",
          "symbol": "BRTR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022"
        },
        {
          "chainId": 56,
          "address": "0x4d2d32d8652058bf98c772953e1df5c5c85d9f45",
          "name": "DAO Maker",
          "symbol": "DAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13915/thumb/4.png?1612838831"
        },
        {
          "chainId": 56,
          "address": "0xbc1aff2c8fbcf65a96e4a634f2d69d2cd483036a",
          "name": "Viva",
          "symbol": "VIVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25300/thumb/Logo-ReverseWebsiteColor.png?1651151937"
        },
        {
          "chainId": 56,
          "address": "0x298eff8af1ecebbb2c034eaa3b9a5d0cc56c59cd",
          "name": "Phantasma",
          "symbol": "SOUL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035"
        },
        {
          "chainId": 56,
          "address": "0x2222227e22102fe3322098e4cbfe18cfebd57c95",
          "name": "Alien Worlds",
          "symbol": "TLM",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061"
        },
        {
          "chainId": 56,
          "address": "0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9",
          "name": "ORE Network",
          "symbol": "ORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18917/thumb/ORE_FullColor.png?1633921977"
        },
        {
          "chainId": 56,
          "address": "0x039cb485212f996a9dbb85a9a75d898f94d38da6",
          "name": "DeXe",
          "symbol": "DEXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12713/thumb/logo_%2814%29.png?1601952779"
        },
        {
          "chainId": 56,
          "address": "0xe9b9c1c38dab5eab3b7e2ad295425e89bd8db066",
          "name": "Playcent",
          "symbol": "PCNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831"
        },
        {
          "chainId": 56,
          "address": "0xb64fde8f199f073f41c132b9ec7ad5b61de0b1b7",
          "name": "Incognito",
          "symbol": "PRV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21971/thumb/50738351.png?1640560573"
        },
        {
          "chainId": 56,
          "address": "0xb72842d6f5fedf91d22d56202802bb9a79c6322e",
          "name": "Mochi Market",
          "symbol": "MOMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399"
        },
        {
          "chainId": 56,
          "address": "0x1ab6478b47270ff05af11a012ac17b098758e193",
          "name": "Flux Protocol",
          "symbol": "FLUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png?1619402947"
        },
        {
          "chainId": 56,
          "address": "0x1613957159e9b0ac6c80e824f7eea748a32a0ae2",
          "name": "Chain Guardians",
          "symbol": "CGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14326/thumb/cgg_logo.png?1615429976"
        },
        {
          "chainId": 56,
          "address": "0xebd49b26169e1b52c04cfd19fcf289405df55f80",
          "name": "Orbs",
          "symbol": "ORBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4630/thumb/Orbs.jpg?1547039896"
        },
        {
          "chainId": 56,
          "address": "0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa",
          "name": "Anyswap",
          "symbol": "ANY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg?1598443880"
        },
        {
          "chainId": 56,
          "address": "0x04f73a09e2eb410205be256054794fb452f0d245",
          "name": "DxSale Network",
          "symbol": "SALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12250/thumb/dx-light.png?1613965390"
        },
        {
          "chainId": 56,
          "address": "0x758d08864fb6cce3062667225ca10b8f00496cc2",
          "name": "NAOS Finance",
          "symbol": "NAOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15144/thumb/bafybeibkztkshitabrf7yqqkqtbjqestjknpgv7lsjfzdsa3ufspagqs2e.ipfs.infura-ipfs.io.png?1622176770"
        },
        {
          "chainId": 56,
          "address": "0xac86e5f9ba48d680516df50c72928c2ec50f3025",
          "name": "Phoenix Finance",
          "symbol": "PHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432"
        },
        {
          "chainId": 56,
          "address": "0x70802af0ba10dd5bb33276b5b37574b6451db3d9",
          "name": "Unido",
          "symbol": "UDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353"
        },
        {
          "chainId": 56,
          "address": "0xa4080f1778e69467e905b8d6f72f6e441f9e9484",
          "name": "Synapse",
          "symbol": "SYN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797"
        },
        {
          "chainId": 56,
          "address": "0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563",
          "name": "Forj",
          "symbol": "BONDLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13322/thumb/FORJ_twitter_twitter-linked_in_profile_%281%29.png?1653026729"
        },
        {
          "chainId": 56,
          "address": "0x52ce071bd9b1c4b00a0b92d298c512478cad67e8",
          "name": "Compound",
          "symbol": "COMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10775/thumb/COMP.png?1592625425"
        },
        {
          "chainId": 56,
          "address": "0x8443f091997f06a61670b735ed92734f5628692f",
          "name": "Bella Protocol",
          "symbol": "BEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12478/thumb/Bella.png?1602230054"
        },
        {
          "chainId": 56,
          "address": "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
          "name": "CoinsPaid",
          "symbol": "CPD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234"
        },
        {
          "chainId": 56,
          "address": "0xc0f42b31d154234a0a3ebe7ec52c662101c1d9bc",
          "name": "ShoeFy",
          "symbol": "SHOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg?1634371392"
        },
        {
          "chainId": 56,
          "address": "0x2794dad4077602ed25a88d03781528d1637898b4",
          "name": "Vite",
          "symbol": "VITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4513/thumb/Vite.png?1558014583"
        },
        {
          "chainId": 56,
          "address": "0xe7f72bc0252ca7b16dbb72eeee1afcdb2429f2dd",
          "name": "NFTLaunch",
          "symbol": "NFTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG?1630652662"
        },
        {
          "chainId": 56,
          "address": "0xe9c803f48dffe50180bd5b01dc04da939e3445fc",
          "name": "Velas",
          "symbol": "VLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9651/thumb/logo_blue.png?1663764088"
        },
        {
          "chainId": 56,
          "address": "0x00518f36d2e0e514e8eb94d34124fc18ee756f10",
          "name": "Planet Inverse",
          "symbol": "XIV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271"
        },
        {
          "chainId": 56,
          "address": "0x0615dbba33fe61a31c7ed131bda6655ed76748b1",
          "name": "BaconDAO",
          "symbol": "BACON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg?1630333329"
        },
        {
          "chainId": 56,
          "address": "0x47c9bcef4fe2dbcdf3abf508f147f1bbe8d4fef2",
          "name": "Flurry Finance",
          "symbol": "FLURRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png?1623365114"
        },
        {
          "chainId": 56,
          "address": "0x1b41a1ba7722e6431b1a782327dbe466fe1ee9f9",
          "name": "Knit Finance",
          "symbol": "KFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg?1621396114"
        },
        {
          "chainId": 56,
          "address": "0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b",
          "name": "Kyber Network Crystal",
          "symbol": "KNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851"
        },
        {
          "chainId": 56,
          "address": "0x872d068c25511be88c1f5990c53eeffcdf46c9b4",
          "name": "Vent Finance",
          "symbol": "VENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png?1629804982"
        },
        {
          "chainId": 56,
          "address": "0xb9b41da7fa895b093b95340a3379383bba36735e",
          "name": "Centaurify",
          "symbol": "CENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20512/thumb/Centaurify_Symbol_Black-01.png?1681802100"
        },
        {
          "chainId": 56,
          "address": "0xd6f28f15a5cafc8d29556393c08177124b88de0d",
          "name": "Spellfire",
          "symbol": "SPELLFIRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23107/thumb/17316.png?1643261163"
        },
        {
          "chainId": 56,
          "address": "0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034",
          "name": "Banano",
          "symbol": "BAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6226/thumb/banano-transparent.png?1619589798"
        },
        {
          "chainId": 56,
          "address": "0x8ae619d633cce175a2fbcfa1cea119ddc80f1342",
          "name": "PolyPad",
          "symbol": "POLYPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24905/thumb/BXApUK87_400x400.png?1649335541"
        },
        {
          "chainId": 56,
          "address": "0xac83271abb4ec95386f08ad2b904a46c61777cef",
          "name": "NFTrade",
          "symbol": "NFTD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png?1632486088"
        },
        {
          "chainId": 56,
          "address": "0xd98438889ae7364c7e2a3540547fad042fb24642",
          "name": "Cellframe",
          "symbol": "CELL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14465/thumb/cellframe-coingecko.png?1644483414"
        },
        {
          "chainId": 56,
          "address": "0xc822bb8f72c212f0f9477ab064f3bdf116c193e6",
          "name": "UpBots",
          "symbol": "UBXN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12476/thumb/UBXT.png?1600132967"
        },
        {
          "chainId": 56,
          "address": "0x464fdb8affc9bac185a7393fd4298137866dcfb8",
          "name": "Realm",
          "symbol": "REALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG?1631665838"
        },
        {
          "chainId": 56,
          "address": "0x889efce29fa0bb9b26be9fda17a8003f4e8da4de",
          "name": "Waves Ducks",
          "symbol": "EGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17298/thumb/200x200_pixel.png?1627288546"
        },
        {
          "chainId": 56,
          "address": "0x6fb1e018f107d3352506c23777e4cd62e063584a",
          "name": "Identity",
          "symbol": "IDTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG?1631745919"
        },
        {
          "chainId": 56,
          "address": "0x632b8c4e95b2f8a9309417f8d990ab9c04c77369",
          "name": "Weble Ecosystem",
          "symbol": "WET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17353/thumb/cropped-logo-wombat.png?1627368904"
        },
        {
          "chainId": 56,
          "address": "0x82443a77684a7da92fdcb639c8d2bd068a596245",
          "name": "TrustSwap",
          "symbol": "SWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11795/thumb/Untitled_design-removebg-preview.png?1626926355"
        },
        {
          "chainId": 56,
          "address": "0xed8c8aa8299c10f067496bb66f8cc7fb338a3405",
          "name": "Prosper",
          "symbol": "PROS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13668/thumb/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732"
        },
        {
          "chainId": 56,
          "address": "0xf915fdda4c882731c0456a4214548cd13a822886",
          "name": "Unvest",
          "symbol": "UNV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18119/thumb/UNV.jpg?1680852403"
        },
        {
          "chainId": 56,
          "address": "0x6a545f9c64d8f7b957d8d2e6410b52095a9e6c29",
          "name": "CyberFi",
          "symbol": "CFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13112/thumb/cyberfi_logo.jpeg?1605283367"
        },
        {
          "chainId": 56,
          "address": "0x4fa7163e153419e0e1064e418dd7a99314ed27b6",
          "name": "Hot Cross",
          "symbol": "HOTCROSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png?1632197570"
        },
        {
          "chainId": 56,
          "address": "0x5224f552f110ec78e6e0468138950ae5f3040942",
          "name": "Anomus Coin",
          "symbol": "ANOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png?1637807012"
        },
        {
          "chainId": 56,
          "address": "0xd15cee1deafbad6c0b3fd7489677cc102b141464",
          "name": "Circuits of Value",
          "symbol": "COVAL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950"
        },
        {
          "chainId": 56,
          "address": "0xb38b3c34e4bb6144c1e5283af720e046ee833a2a",
          "name": "DeSpace Protocol",
          "symbol": "DES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg?1632267572"
        },
        {
          "chainId": 56,
          "address": "0x1d6cbdc6b29c6afbae65444a1f65ba9252b8ca83",
          "name": "TOR",
          "symbol": "TOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23609/thumb/tor.png?1644747242"
        },
        {
          "chainId": 56,
          "address": "0x788d2780992222360f674cc12c36478870b8e6ed",
          "name": "S4FE",
          "symbol": "S4F",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640"
        },
        {
          "chainId": 56,
          "address": "0x8780fea4c6b242677d4a397fe1110ac09ce99ad2",
          "name": "Bird Money",
          "symbol": "BIRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13260/thumb/favicon-180x180.png?1611546646"
        },
        {
          "chainId": 56,
          "address": "0x71de20e0c4616e7fcbfdd3f875d568492cbe4739",
          "name": "Swingby",
          "symbol": "SWINGBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11861/thumb/swingby.png?1595822302"
        },
        {
          "chainId": 56,
          "address": "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
          "name": "Ferrum Network",
          "symbol": "FRM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8251/thumb/FRM.png?1658819429"
        },
        {
          "chainId": 56,
          "address": "0x4b85a666dec7c959e88b97814e46113601b07e57",
          "name": "GoCrypto",
          "symbol": "GOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3181/thumb/gocrypto.png?1597810330"
        },
        {
          "chainId": 56,
          "address": "0x5fe80d2cd054645b9419657d3d10d26391780a7b",
          "name": "MUX Protocol",
          "symbol": "MCB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11796/thumb/mux.jpg?1660125796"
        },
        {
          "chainId": 56,
          "address": "0x658a109c5900bc6d2357c87549b651670e5b0539",
          "name": "ForTube",
          "symbol": "FOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8242/thumb/for.png?1606195375"
        },
        {
          "chainId": 56,
          "address": "0x4a0a3902e091cdb3aec4279a6bfac50297f0a79e",
          "name": "Vera",
          "symbol": "VERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18519/thumb/JJXTVFOE_400x400.png?1632273174"
        },
        {
          "chainId": 56,
          "address": "0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54",
          "name": "HAPI",
          "symbol": "HAPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14298/thumb/R9i2HjAL_400x400.jpg?1615332438"
        },
        {
          "chainId": 56,
          "address": "0xe069af87450fb51fc0d0e044617f1c134163e591",
          "name": "Virtue Poker Points",
          "symbol": "VPP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png?1622068750"
        },
        {
          "chainId": 56,
          "address": "0x66109633715d2110dda791e64a7b2afadb517abb",
          "name": "Gamestarter",
          "symbol": "GAME",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/17604/thumb/gpMi14-r_400x400.jpg?1628647205"
        },
        {
          "chainId": 56,
          "address": "0xd6cce248263ea1e2b8cb765178c944fc16ed0727",
          "name": "Creator Platform",
          "symbol": "CTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png?1631137441"
        },
        {
          "chainId": 56,
          "address": "0x011734f6ed20e8d011d85cf7894814b897420acf",
          "name": "BallSwap",
          "symbol": "BSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14050/thumb/bsp.png?1637556507"
        },
        {
          "chainId": 56,
          "address": "0x1cdee2f21c066848a8a135e19f5f302ca29f1f69",
          "name": "Levolution",
          "symbol": "LEVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083"
        },
        {
          "chainId": 56,
          "address": "0x8f49733210700d38098d7375c221c7d02f700cc8",
          "name": "Pallapay",
          "symbol": "PALLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16200/thumb/palla.png?1647095947"
        },
        {
          "chainId": 56,
          "address": "0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d",
          "name": "Synapse Network",
          "symbol": "SNP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17962/thumb/Webp-net-resizeimage_%282%29.png?1629943450"
        },
        {
          "chainId": 56,
          "address": "0x5774b2fc3e91af89f89141eacf76545e74265982",
          "name": "NFTY",
          "symbol": "NFTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18741/thumb/coingecko.png?1683520850"
        },
        {
          "chainId": 56,
          "address": "0x33840024177a7daca3468912363bed8b425015c5",
          "name": "Ebox",
          "symbol": "EBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519"
        },
        {
          "chainId": 56,
          "address": "0x6a8fd46f88dbd7bdc2d536c604f811c63052ce0f",
          "name": "TRVL",
          "symbol": "TRVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg?1649390006"
        },
        {
          "chainId": 56,
          "address": "0x0af55d5ff28a3269d69b98680fd034f115dd53ac",
          "name": "BankSocial",
          "symbol": "BSL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png?1621685752"
        },
        {
          "chainId": 56,
          "address": "0x07ec61ae90860641972e9b41a706325a1e928bf8",
          "name": "Volt Inu  OLD ",
          "symbol": "VOLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21832/thumb/nl250.png?1643191234"
        },
        {
          "chainId": 56,
          "address": "0x6ae9701b9c423f40d54556c9a443409d79ce170a",
          "name": "Polkacity",
          "symbol": "POLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959"
        },
        {
          "chainId": 56,
          "address": "0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240",
          "name": "SingularityDAO",
          "symbol": "SDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15385/thumb/200x200_logo.png?1665743635"
        },
        {
          "chainId": 56,
          "address": "0xde9a73272bc2f28189ce3c243e36fafda2485212",
          "name": "Channels",
          "symbol": "CAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14093/thumb/KpSUqAH.png?1614229582"
        },
        {
          "chainId": 56,
          "address": "0x1bb76a939d6b7f5be6b95c4f9f822b02b4d62ced",
          "name": "POP Network",
          "symbol": "POP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7281/thumb/Logo_%28Light_Mode%29_%281%29.png?1644482888"
        },
        {
          "chainId": 56,
          "address": "0x968f6f898a6df937fc1859b323ac2f14643e3fed",
          "name": "Newscrypto Coin",
          "symbol": "NWC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png?1614745476"
        },
        {
          "chainId": 56,
          "address": "0x09f423ac3c9babbff6f94d372b16e4206e71439f",
          "name": "Enjinstarter",
          "symbol": "EJS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg?1633297242"
        },
        {
          "chainId": 56,
          "address": "0xc10358f062663448a3489fc258139944534592ac",
          "name": "Blockchain Monster Hunt",
          "symbol": "BCMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19045/thumb/bcmc-coin-200x200.png?1641398990"
        },
        {
          "chainId": 56,
          "address": "0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63",
          "name": "Highstreet",
          "symbol": "HIGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470"
        },
        {
          "chainId": 56,
          "address": "0xa01000c52b234a92563ba61e5649b7c76e1ba0f3",
          "name": "Rocki",
          "symbol": "ROCKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767"
        },
        {
          "chainId": 56,
          "address": "0x5a4fb10e7c4cbb9a2b9d9a942f9a875ebd3489ea",
          "name": "Carbon",
          "symbol": "CRBN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13262/thumb/carbon.png?1662693418"
        },
        {
          "chainId": 56,
          "address": "0xe552fb52a4f19e44ef5a967632dbc320b0820639",
          "name": "Metis",
          "symbol": "METIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312"
        },
        {
          "chainId": 56,
          "address": "0x3e3b357061103dc040759ac7dceeaba9901043ad",
          "name": "CloutContracts",
          "symbol": "CCS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/19072/thumb/200.png?1634829111"
        },
        {
          "chainId": 56,
          "address": "0xe795347731bc547f4e4643f7945738ce2bc18529",
          "name": "DefiCliq",
          "symbol": "CLIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146"
        },
        {
          "chainId": 56,
          "address": "0xd8cb4c2369db13c94c90c7fd3bebc9757900ee6b",
          "name": "Napoleon X",
          "symbol": "NPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg?1547035570"
        },
        {
          "chainId": 56,
          "address": "0xe9c64384deb0c2bf06d991a8d708c77eb545e3d5",
          "name": "Ridotto",
          "symbol": "RDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png?1632875527"
        },
        {
          "chainId": 56,
          "address": "0x3053ad3b31600074e9a90440770f78d5e8fc5a54",
          "name": "Font",
          "symbol": "FONT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14085/thumb/font.png?1614183855"
        },
        {
          "chainId": 56,
          "address": "0x99415856b37be9e75c0153615c7954f9ddb97a6e",
          "name": "Royale",
          "symbol": "ROYA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13602/thumb/roya.png?1636031771"
        },
        {
          "chainId": 56,
          "address": "0xace3574b8b054e074473a9bd002e5dc6dd3dff1b",
          "name": "RBX",
          "symbol": "RBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19253/thumb/output-onlinepngtools-9.png?1634801960"
        },
        {
          "chainId": 56,
          "address": "0xede2f059545e8cde832d8da3985caacf795b8765",
          "name": "Ormeus Ecosystem",
          "symbol": "ECO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8923/thumb/logo_eco_low.png?1562902804"
        },
        {
          "chainId": 56,
          "address": "0x7e2afe446a30fa67600a5174df7f4002b8e15b03",
          "name": "Ormeus Coin",
          "symbol": "ORME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243"
        },
        {
          "chainId": 56,
          "address": "0x5d2f9a9df1ba3c8c00303d0b4c431897ebc6626a",
          "name": "Ormeus Cash",
          "symbol": "OMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387"
        },
        {
          "chainId": 56,
          "address": "0x90c97f71e18723b0cf0dfa30ee176ab653e89f40",
          "name": "Frax",
          "symbol": "FRAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13422/thumb/FRAX_icon.png?1679886922"
        },
        {
          "chainId": 56,
          "address": "0x1e8150ea46e2a7fbb795459198fbb4b35715196c",
          "name": "Shih Tzu",
          "symbol": "SHIH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG?1620374274"
        },
        {
          "chainId": 56,
          "address": "0xc28ea768221f67b6a1fd33e6aa903d4e42f6b177",
          "name": "Dope Wars Paper",
          "symbol": "PAPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18166/thumb/EQHGcBO__400x400.jpeg?1663726283"
        },
        {
          "chainId": 56,
          "address": "0x77087ab5df23cfb52449a188e80e9096201c2097",
          "name": "hi Dollar",
          "symbol": "HI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png?1628665739"
        },
        {
          "chainId": 56,
          "address": "0x7837fd820ba38f95c54d6dac4ca3751b81511357",
          "name": "DOSE",
          "symbol": "DOSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG?1633590548"
        },
        {
          "chainId": 56,
          "address": "0xe2eb47954e821dc94e19013677004cd59be0b17f",
          "name": "Triall",
          "symbol": "TRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18679/thumb/-B7ftfN8_400x400.png?1632964301"
        },
        {
          "chainId": 56,
          "address": "0x86b3f23b6e90f5bbfac59b5b2661134ef8ffd255",
          "name": "Don key",
          "symbol": "DON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824"
        },
        {
          "chainId": 56,
          "address": "0x586fc153cf7e9c029d8c30842c4cb6a86f03b816",
          "name": "BYTZ",
          "symbol": "BYTZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4017/thumb/bytz.png?1584519997"
        },
        {
          "chainId": 56,
          "address": "0xd9ea58350bf120e2169a35fa1afc31975b07de01",
          "name": "Gains",
          "symbol": "GAINS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14681/thumb/200x200.png?1677061798"
        },
        {
          "chainId": 56,
          "address": "0xf720e38f678b29b243f7d53b56acbf5de98f2385",
          "name": "Upfire",
          "symbol": "UPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png?1637214757"
        },
        {
          "chainId": 56,
          "address": "0xa0cb0ce7c6d93a7ebd72952feb4407dddee8a194",
          "name": "Shibaken Finance",
          "symbol": "SHIBAKEN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/15413/thumb/shibak.png?1660902286"
        },
        {
          "chainId": 56,
          "address": "0xd3b71117e6c1558c1553305b44988cd944e97300",
          "name": "Yel Finance",
          "symbol": "YEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17429/thumb/Logo200.png?1627613989"
        },
        {
          "chainId": 56,
          "address": "0x22fda23aed456f2de139c6240f0776ef031c8b6b",
          "name": "Demeter USD",
          "symbol": "DUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18459/thumb/DUSD-200.png?1632108436"
        },
        {
          "chainId": 56,
          "address": "0xc1fdbed7dac39cae2ccc0748f7a80dc446f6a594",
          "name": "BiLira",
          "symbol": "TRYB",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg?1642668342"
        },
        {
          "chainId": 56,
          "address": "0x4d6b129db8a502b85fedc3443fa4f58b50327238",
          "name": "Envoy",
          "symbol": "ENV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19631/thumb/env.png?1638148461"
        },
        {
          "chainId": 56,
          "address": "0x21f9b5b2626603e3f40bfc13d01afb8c431d382f",
          "name": "Zombie Inu  OLD ",
          "symbol": "ZINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19516/thumb/zinu.jpeg?1635321575"
        },
        {
          "chainId": 56,
          "address": "0xc0ecb8499d8da2771abcbf4091db7f65158f1468",
          "name": "Carbon Protocol",
          "symbol": "SWTH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/3645/thumb/SWTH_Symbol_Origin.png?1645000262"
        },
        {
          "chainId": 56,
          "address": "0x22648c12acd87912ea1710357b1302c6a4154ebc",
          "name": "XAYA",
          "symbol": "WCHI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png?1656561479"
        },
        {
          "chainId": 56,
          "address": "0x92868a5255c628da08f550a858a802f5351c5223",
          "name": "Cross Chain Bridge",
          "symbol": "BRIDGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446"
        },
        {
          "chainId": 56,
          "address": "0xc5326b32e8baef125acd68f8bc646fd646104f1c",
          "name": "Zap",
          "symbol": "ZAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476"
        },
        {
          "chainId": 56,
          "address": "0x5f26fa0c2ee5d3c0323d861d0c503f31ac212662",
          "name": "Chronicle",
          "symbol": "XNL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png?1633517024"
        },
        {
          "chainId": 56,
          "address": "0x58759dd469ae5631c42cf8a473992335575b58d7",
          "name": "DeHive",
          "symbol": "DHV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1626181831"
        },
        {
          "chainId": 56,
          "address": "0x8e17ed70334c87ece574c9d537bc153d8609e2a3",
          "name": "WazirX",
          "symbol": "WRX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10547/thumb/WazirX.png?1580834330"
        },
        {
          "chainId": 56,
          "address": "0x377c6e37633e390aef9afb4f5e0b16689351eed4",
          "name": "ZYX",
          "symbol": "ZYX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904"
        },
        {
          "chainId": 56,
          "address": "0x0d3843f92d622468ba67df5a6a4149b484a75af3",
          "name": "Dinger",
          "symbol": "DINGER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19443/thumb/dinger.png?1661498131"
        },
        {
          "chainId": 56,
          "address": "0x794baab6b878467f93ef17e2f2851ce04e3e34c8",
          "name": "YIN Finance",
          "symbol": "YIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg?1631176921"
        },
        {
          "chainId": 56,
          "address": "0xfbcf80ed90856af0d6d9655f746331763efdb22c",
          "name": "NEXTYPE Finance",
          "symbol": "NT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17415/thumb/nextype.PNG?1627597099"
        },
        {
          "chainId": 56,
          "address": "0x4e1b16ef22935a575a6811d4616f98c4077e4408",
          "name": "KelVPN",
          "symbol": "KEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14851/thumb/logokelvpn200x200.png?1618797379"
        },
        {
          "chainId": 56,
          "address": "0x9df465460938f9ebdf51c38cc87d72184471f8f0",
          "name": "Genopets",
          "symbol": "GENE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20360/thumb/gene-token.png?1636945172"
        },
        {
          "chainId": 56,
          "address": "0x347e430b7cd1235e216be58ffa13394e5009e6e2",
          "name": "Gaia Everworld",
          "symbol": "GAIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19629/thumb/gaia.png?1646481791"
        },
        {
          "chainId": 56,
          "address": "0x7961ade0a767c0e5b67dd1a1f78ba44f727642ed",
          "name": "Quidd",
          "symbol": "QUIDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19725/thumb/quidd.png?1637303435"
        },
        {
          "chainId": 56,
          "address": "0xd9e8d20bde081600fac0d94b88eafaddce55aa43",
          "name": "Pussy Financial",
          "symbol": "PUSSY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339"
        },
        {
          "chainId": 56,
          "address": "0x6ba7a8f9063c712c1c8cabc776b1da7126805f3b",
          "name": "Polytrade",
          "symbol": "TRADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png?1623979654"
        },
        {
          "chainId": 56,
          "address": "0x0f5d8cd195a4539bcf2ec6118c6da50287c6d5f5",
          "name": "Gold Fever Native Gold",
          "symbol": "NGL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg?1637338673"
        },
        {
          "chainId": 56,
          "address": "0x513c3200f227ebb62e3b3d00b7a83779643a71cf",
          "name": "Uplift",
          "symbol": "LIFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png?1657675050"
        },
        {
          "chainId": 56,
          "address": "0xea136fc555e695ba96d22e10b7e2151c4c6b2a20",
          "name": "ReSource Protocol",
          "symbol": "SOURCE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20740/thumb/source.png?1637749215"
        },
        {
          "chainId": 56,
          "address": "0xf9752a6e8a5e5f5e6eb3ab4e7d8492460fb319f0",
          "name": "Ares Protocol",
          "symbol": "ARES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png?1620638611"
        },
        {
          "chainId": 56,
          "address": "0xa5342d72d04c133180f376753f90a4b2eee29bb3",
          "name": "Decentralized Mining Exchange",
          "symbol": "DMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14130/thumb/1_hXrVwWTpmRJkPB6Aty0npQ.png?1614580808"
        },
        {
          "chainId": 56,
          "address": "0x75d107de2217ffe2cd574a1b3297c70c8fafd159",
          "name": "TryHards",
          "symbol": "TRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18624/thumb/TryHards_Tokenticker.png?1650437198"
        },
        {
          "chainId": 56,
          "address": "0x3c6dad0475d3a1696b359dc04c99fd401be134da",
          "name": "Saito",
          "symbol": "SAITO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14750/thumb/SAITO.png?1626857406"
        },
        {
          "chainId": 56,
          "address": "0xa5ff48e326958e0ce6fdf9518de561f2b5f57da3",
          "name": "Lokr",
          "symbol": "LKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932"
        },
        {
          "chainId": 56,
          "address": "0xf03a2dc374d494fbe894563fe22ee544d826aa50",
          "name": "Radar",
          "symbol": "RADAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13909/thumb/RADAR.png?1634183461"
        },
        {
          "chainId": 56,
          "address": "0x23316e6b09e8f4f67b95d53b4f1e59d1fb518f29",
          "name": "MedicalVeda",
          "symbol": "MVEDA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690"
        },
        {
          "chainId": 56,
          "address": "0x84e9a6f9d240fdd33801f7135908bfa16866939a",
          "name": "GAMEE",
          "symbol": "GMEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14716/thumb/gmee-200x200.png?1621827468"
        },
        {
          "chainId": 56,
          "address": "0xb955b4cab9aa3b49e23aeb5204ebc5ff6678e86d",
          "name": "Asian Fintech",
          "symbol": "AFIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png?1630048356"
        },
        {
          "chainId": 56,
          "address": "0xf9d906a8dd25c4a4966bc075cdc946702219e62c",
          "name": "Yield Protocol",
          "symbol": "YIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14220/thumb/yield.png?1615030969"
        },
        {
          "chainId": 56,
          "address": "0x39ae8eefb05138f418bb27659c21632dc1ddab10",
          "name": "KardiaChain",
          "symbol": "KAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7942/thumb/kai.png?1647079540"
        },
        {
          "chainId": 56,
          "address": "0x284ac5af363bde6ef5296036af8fb0e9cc347b41",
          "name": "The HUSL",
          "symbol": "HUSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png?1650437671"
        },
        {
          "chainId": 56,
          "address": "0x678e840c640f619e17848045d23072844224dd37",
          "name": "Cratos",
          "symbol": "CRTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17322/thumb/cratos.png?1627301460"
        },
        {
          "chainId": 56,
          "address": "0xf83c0f6d3a5665bd7cfdd5831a856d85942bc060",
          "name": "KIRO",
          "symbol": "KIRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12688/thumb/logo_kirobo-04.png?1668683315"
        },
        {
          "chainId": 56,
          "address": "0x8530b66ca3ddf50e0447eae8ad7ea7d5e62762ed",
          "name": "Meta Doge",
          "symbol": "METADOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19656/thumb/metadoge.png?1636333901"
        },
        {
          "chainId": 56,
          "address": "0x8f081eb884fd47b79536d28e2dd9d4886773f783",
          "name": "bePAY Finance",
          "symbol": "BECOIN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png?1638861929"
        },
        {
          "chainId": 56,
          "address": "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
          "name": "Merit Circle",
          "symbol": "MC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154"
        },
        {
          "chainId": 56,
          "address": "0xe17fbdf671f3cce0f354cacbd27e03f4245a3ffe",
          "name": "Rikkei Finance",
          "symbol": "RIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg?1638888855"
        },
        {
          "chainId": 56,
          "address": "0x0d35a2b85c5a63188d566d104bebf7c694334ee4",
          "name": "Pawtocol",
          "symbol": "UPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008"
        },
        {
          "chainId": 56,
          "address": "0x9b208b117b2c4f76c1534b6f006b033220a681a4",
          "name": "Dingocoin",
          "symbol": "DINGO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15989/thumb/dingocoin.png?1634699256"
        },
        {
          "chainId": 56,
          "address": "0x73ff5dd853cb87c144f463a555dce0e43954220d",
          "name": "MetaFabric",
          "symbol": "FABRIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png?1638758217"
        },
        {
          "chainId": 56,
          "address": "0x51e7b598c9155b9dccb04eb42519f6eec9c841e9",
          "name": "Bitlocus",
          "symbol": "BTL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20913/thumb/btl.png?1637922710"
        },
        {
          "chainId": 56,
          "address": "0xcd7e445175ff67475f0079b13aa6bed8a4e01809",
          "name": "Add xyz  NEW ",
          "symbol": "ADD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png?1622444542"
        },
        {
          "chainId": 56,
          "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
          "name": "WETH",
          "symbol": "WETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        },
        {
          "chainId": 56,
          "address": "0xfb9c339b4bace4fe63ccc1dd9a3c3c531441d5fe",
          "name": "SHILL Token",
          "symbol": "SHILL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18176/thumb/SHILL_Logo.png?1630896205"
        },
        {
          "chainId": 56,
          "address": "0x612e1726435fe38dd49a0b35b4065b56f49c8f11",
          "name": "CryptoCart V2",
          "symbol": "CCV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png?1638928576"
        },
        {
          "chainId": 56,
          "address": "0xc146b7cdbaff065090077151d391f4c96aa09e0c",
          "name": "Multi Chain Capital",
          "symbol": "MCC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21621/thumb/mcc.png?1639622572"
        },
        {
          "chainId": 56,
          "address": "0x301af3eff0c904dc5ddd06faa808f653474f7fcc",
          "name": "Unbound Finance",
          "symbol": "UNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21412/thumb/Unbound_icon_png.png?1639091708"
        },
        {
          "chainId": 56,
          "address": "0x570a5d26f7765ecb712c0924e4de545b89fd43df",
          "name": "Wrapped Solana",
          "symbol": "SOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21629/thumb/solana.jpg?1639626543"
        },
        {
          "chainId": 56,
          "address": "0xc32bb619966b9a56cf2472528a36fd099ce979e0",
          "name": "Matrix Labs",
          "symbol": "MATRIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18297/thumb/matrixlabs.png?1643277367"
        },
        {
          "chainId": 56,
          "address": "0x77edfae59a7948d66e9911a30cc787d2172343d4",
          "name": "LABEL Foundation",
          "symbol": "LBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png?1634680838"
        },
        {
          "chainId": 56,
          "address": "0x07f9702ce093db82dfdc92c2c6e578d6ea8d5e22",
          "name": "Oobit",
          "symbol": "OBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19855/thumb/obt.png?1636062272"
        },
        {
          "chainId": 56,
          "address": "0x1785113910847770290f5f840b4c74fc46451201",
          "name": "Fabwelt",
          "symbol": "WELT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20505/thumb/welt.PNG?1637143324"
        },
        {
          "chainId": 56,
          "address": "0x6bcd897d4ba5675f860c7418ddc034f6c5610114",
          "name": "Rainmaker Games",
          "symbol": "RAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png?1639362827"
        },
        {
          "chainId": 56,
          "address": "0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4",
          "name": "Deeper Network",
          "symbol": "DPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14748/thumb/deeper.png?1618094356"
        },
        {
          "chainId": 56,
          "address": "0xfa4fa764f15d0f6e20aaec8e0d696870e5b77c6e",
          "name": "FUSION",
          "symbol": "FSN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907"
        },
        {
          "chainId": 56,
          "address": "0xcafe001067cdef266afb7eb5a286dcfd277f3de5",
          "name": "ParaSwap",
          "symbol": "PSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20403/thumb/ep7GqM19_400x400.jpg?1636979120"
        },
        {
          "chainId": 56,
          "address": "0x32d12029f62260e239b5b5c8f0bea9cb382cfdd6",
          "name": "Homeros",
          "symbol": "HMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938"
        },
        {
          "chainId": 56,
          "address": "0xdae4f1dca49408288b55250022f67195eff2445a",
          "name": "Hanu Yokia",
          "symbol": "HANU",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png?1627127472"
        },
        {
          "chainId": 56,
          "address": "0xf44fb887334fa17d2c5c0f970b5d320ab53ed557",
          "name": "Aluna",
          "symbol": "ALN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106"
        },
        {
          "chainId": 56,
          "address": "0xb346c52874c7023df183068c39478c3b7b2515bc",
          "name": "True PNL",
          "symbol": "PNL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png?1623230007"
        },
        {
          "chainId": 56,
          "address": "0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5",
          "name": "Prom",
          "symbol": "PROM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8825/thumb/Ticker.png?1657632943"
        },
        {
          "chainId": 56,
          "address": "0x5f39dd1bb6db20f3e792c4489f514794cac6392c",
          "name": "PlayNity",
          "symbol": "PLY",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21479/thumb/ply.png?1639651574"
        },
        {
          "chainId": 56,
          "address": "0x4ef06d1a55962f29d362c53c864a4f0c791dcb9e",
          "name": "My bu",
          "symbol": "MYOBU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16122/thumb/myobu.png?1623037167"
        },
        {
          "chainId": 56,
          "address": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
          "name": "Multichain",
          "symbol": "MULTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238"
        },
        {
          "chainId": 56,
          "address": "0x3f6b3595ecf70735d3f48d69b09c4e4506db3f47",
          "name": "GameStation",
          "symbol": "GAMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19584/thumb/game_station.PNG?1635471603"
        },
        {
          "chainId": 56,
          "address": "0x708cb02ad77e1b245b1640cee51b3cc844bcaef4",
          "name": "Etherland",
          "symbol": "ELAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14432/thumb/eland_logo.png?1678003898"
        },
        {
          "chainId": 56,
          "address": "0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe",
          "name": "Wrapped EGLD",
          "symbol": "WEGLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22255/thumb/wrapped_elrond.jpg?1673606817"
        },
        {
          "chainId": 56,
          "address": "0xeceb87cf00dcbf2d4e2880223743ff087a995ad9",
          "name": "NUM Token",
          "symbol": "NUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20495/thumb/NP_Social_media_profile_pic.png?1664026542"
        },
        {
          "chainId": 56,
          "address": "0xb160a5f19ebccd8e0549549327e43ddd1d023526",
          "name": "Winkies",
          "symbol": "WNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22260/thumb/wnk.png?1644053369"
        },
        {
          "chainId": 56,
          "address": "0x8f36cc333f55b09bb71091409a3d7ade399e3b1c",
          "name": "Cherry Network",
          "symbol": "CHER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127"
        },
        {
          "chainId": 56,
          "address": "0xf486ad071f3bee968384d2e39e2d8af0fcf6fd46",
          "name": "Velo",
          "symbol": "VELO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12538/thumb/Logo_200x_200.png?1625196103"
        },
        {
          "chainId": 56,
          "address": "0xeb986da994e4a118d5956b02d8b7c3c7ce373674",
          "name": "Gather",
          "symbol": "GTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686"
        },
        {
          "chainId": 56,
          "address": "0x659049786cb66e4486b8c0e0ccc90a5929a21162",
          "name": "TTcoin",
          "symbol": "TC",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/18887/thumb/200px.png?1642084842"
        },
        {
          "chainId": 56,
          "address": "0x0565805ca3a4105faee51983b0bd8ffb5ce1455c",
          "name": "BlockchainSpace",
          "symbol": "GUILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21271/thumb/BednjMw.png?1638857799"
        },
        {
          "chainId": 56,
          "address": "0xdbccd9131405dd1fe7320090af337952b9845dfa",
          "name": "Starbots",
          "symbol": "BOT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21823/thumb/coin_%286%29.png?1640076014"
        },
        {
          "chainId": 56,
          "address": "0x65d9033cff96782394dab5dbef17fa771bbe1732",
          "name": "Bit Store",
          "symbol": "STORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG?1637064215"
        },
        {
          "chainId": 56,
          "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
          "name": "Meter Governance",
          "symbol": "MTRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11848/thumb/meter.png?1660274270"
        },
        {
          "chainId": 56,
          "address": "0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a",
          "name": "TerraUSD  Wormhole ",
          "symbol": "UST",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png?1644223065"
        },
        {
          "chainId": 56,
          "address": "0x5f588efaf8eb57e3837486e834fc5a4e07768d98",
          "name": "MVL",
          "symbol": "MVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299"
        },
        {
          "chainId": 56,
          "address": "0xdefac16715671b7b6aeefe012125f1e19ee4b7d7",
          "name": "Defactor",
          "symbol": "FACTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050"
        },
        {
          "chainId": 56,
          "address": "0xfa54ff1a158b5189ebba6ae130ced6bbd3aea76e",
          "name": "SOL  Wormhole ",
          "symbol": "SOL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316"
        },
        {
          "chainId": 56,
          "address": "0x49d5cc521f75e13fa8eb4e89e9d381352c897c96",
          "name": "Tether USD  Wormhole ",
          "symbol": "USDTSO",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113"
        },
        {
          "chainId": 56,
          "address": "0xf4bea2c219eb95c6745983b68185c7340c319d9e",
          "name": "TFS",
          "symbol": "TFS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21963/thumb/dOF2IBa.png?1640524604"
        },
        {
          "chainId": 56,
          "address": "0x336f5a68fd46a25056a6c1d9c06072c691486acc",
          "name": "Mimir",
          "symbol": "MIMIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626"
        },
        {
          "chainId": 56,
          "address": "0xa39bf0446268d99c5c0a85ecf92970611912d386",
          "name": "Moon Rabbit",
          "symbol": "AAA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874"
        },
        {
          "chainId": 56,
          "address": "0xa91c7bc1e07996a188c1a5b1cfdff450389d8acf",
          "name": "SolChicks",
          "symbol": "CHICKS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20978/thumb/chicks.png?1638162821"
        },
        {
          "chainId": 56,
          "address": "0x71ab195498b6dc1656abb4d9233f83ae5f19495b",
          "name": "Marvin Inu",
          "symbol": "MARVIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22039/thumb/lVshyCp.png?1640669485"
        },
        {
          "chainId": 56,
          "address": "0x556b60c53fbc1518ad17e03d52e47368dd4d81b3",
          "name": "Axie Infinity Shard  Wormhole ",
          "symbol": "AXSET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22952/thumb/AXSet_wh_small.png?1644224450"
        },
        {
          "chainId": 56,
          "address": "0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea",
          "name": "Ethereum  Wormhole ",
          "symbol": "ETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png?1644225466"
        },
        {
          "chainId": 56,
          "address": "0xe239b561369aef79ed55dfdded84848a3bf60480",
          "name": "Paper",
          "symbol": "PAPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23510/thumb/PAPERLOGOCryptoLink1-1.png?1669717863"
        },
        {
          "chainId": 56,
          "address": "0x99956d38059cf7beda96ec91aa7bb2477e0901dd",
          "name": "DIA",
          "symbol": "DIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11955/thumb/Token_Logo.png?1669981526"
        },
        {
          "chainId": 56,
          "address": "0xd983ab71a284d6371908420d8ac6407ca943f810",
          "name": "ULTRON",
          "symbol": "ULX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26977/thumb/ULTRON-Profile-Pic.jpg?1690184603"
        },
        {
          "chainId": 56,
          "address": "0x986854779804799c1d68867f5e03e601e781e41b",
          "name": "Lido DAO  Wormhole ",
          "symbol": "LDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22995/thumb/LDO_wh_small.png?1644226233"
        },
        {
          "chainId": 56,
          "address": "0x49ba054b9664e99ac335667a917c63bb94332e84",
          "name": "FTX  Wormhole ",
          "symbol": "FTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22996/thumb/FTT_wh_small.png?1644225637"
        },
        {
          "chainId": 56,
          "address": "0xfb5b838b6cfeedc2873ab27866079ac55363d37e",
          "name": "FLOKI",
          "symbol": "FLOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16746/thumb/PNG_image.png?1643184642"
        },
        {
          "chainId": 56,
          "address": "0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2",
          "name": "Noku",
          "symbol": "NOKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png?1643171688"
        },
        {
          "chainId": 56,
          "address": "0xebaffc2d2ea7c66fb848c48124b753f93a0a90ec",
          "name": "Asia Coin",
          "symbol": "ASIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png?1632556422"
        },
        {
          "chainId": 56,
          "address": "0x0864c156b3c5f69824564dec60c629ae6401bf2a",
          "name": "Streamr",
          "symbol": "DATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17869/thumb/DATA_new_symbol_3x.png?1629692324"
        },
        {
          "chainId": 56,
          "address": "0x6125adcab2f171bc70cfe2caecfec5509273a86a",
          "name": "MetaGaming Guild",
          "symbol": "MGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010"
        },
        {
          "chainId": 56,
          "address": "0xbc7a566b85ef73f935e640a06b5a8b031cd975df",
          "name": "Blockasset",
          "symbol": "BLOCK",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21332/thumb/Blockasset-Logo-Symbol.png?1648442722"
        },
        {
          "chainId": 56,
          "address": "0xbb9f216bac27046c6b8bdaae660b761b851ab068",
          "name": "Foho Coin",
          "symbol": "FOHO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png?1629859400"
        },
        {
          "chainId": 56,
          "address": "0xbd7b8e4de08d9b01938f7ff2058f110ee1e0e8d4",
          "name": "GamerCoin",
          "symbol": "GHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440"
        },
        {
          "chainId": 56,
          "address": "0x68784ffaa6ff05e3e04575df77960dc1d9f42b4a",
          "name": "Allbridge",
          "symbol": "ABR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053"
        },
        {
          "chainId": 56,
          "address": "0x2a4dffa1fa0f86ce7f0982f88aecc199fb3476bc",
          "name": "OccamFi",
          "symbol": "OCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG?1618531140"
        },
        {
          "chainId": 56,
          "address": "0x6e2a5ea25b161befa6a8444c71ae3a89c39933c6",
          "name": "Bit2Me",
          "symbol": "B2M",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332"
        },
        {
          "chainId": 56,
          "address": "0xadec335a2e3881303a9b0203eb99de12202280df",
          "name": "Rentible",
          "symbol": "RNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850"
        },
        {
          "chainId": 56,
          "address": "0x180cfbe9843d79bcafcbcdf23590247793dfc95b",
          "name": "PolkaFantasy",
          "symbol": "XP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467"
        },
        {
          "chainId": 56,
          "address": "0x170dc35c5b58aededc81960691eb4b389eba9e95",
          "name": "Immortl  OLD ",
          "symbol": "IMRTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21983/thumb/ONE.png?1640575713"
        },
        {
          "chainId": 56,
          "address": "0x4374f26f0148a6331905edf4cd33b89d8eed78d1",
          "name": "Yoshi exchange",
          "symbol": "YOSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19355/thumb/YOSHI_200.png?1641569394"
        },
        {
          "chainId": 56,
          "address": "0x5921dee8556c4593eefcfad3ca5e2f618606483b",
          "name": "Mettalex",
          "symbol": "MTLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12730/thumb/nrEqFTEW_400x400.jpg?1602063980"
        },
        {
          "chainId": 56,
          "address": "0x666666661f9b6d8c581602aaa2f76cbead06c401",
          "name": "XY Finance",
          "symbol": "XY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21541/thumb/xy.png?1639913622"
        },
        {
          "chainId": 56,
          "address": "0x3dcb18569425930954feb191122e574b87f66abd",
          "name": "Orion Money",
          "symbol": "ORION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png?1632710781"
        },
        {
          "chainId": 56,
          "address": "0x668048e70284107a6afab1711f28d88df3e72948",
          "name": "Coldstack",
          "symbol": "CLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403"
        },
        {
          "chainId": 56,
          "address": "0x4e0fe270b856eebb91fb4b4364312be59f499a3f",
          "name": "Dafi Protocol",
          "symbol": "DAFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406"
        },
        {
          "chainId": 56,
          "address": "0x8ea93d00cc6252e2bd02a34782487eed65738152",
          "name": "Spherium",
          "symbol": "SPHRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159"
        },
        {
          "chainId": 56,
          "address": "0x37807d4fbeb84124347b8899dd99616090d3e304",
          "name": "Lunr",
          "symbol": "LUNR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807"
        },
        {
          "chainId": 56,
          "address": "0xda8929a6338f408cc78c1845fb4f71bffd2cfcfb",
          "name": "Hillstone Finance",
          "symbol": "HSF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png?1641519883"
        },
        {
          "chainId": 56,
          "address": "0x2235e79086dd23135119366da45851c741874e5b",
          "name": "Credefi",
          "symbol": "CREDI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg?1639042835"
        },
        {
          "chainId": 56,
          "address": "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b",
          "name": "Aurox",
          "symbol": "URUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png?1648524329"
        },
        {
          "chainId": 56,
          "address": "0x0ec04ece89609e545b8768e303986421ffc32eaf",
          "name": "Bintex Futures",
          "symbol": "BNTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672"
        },
        {
          "chainId": 56,
          "address": "0xb0c4080a8fa7afa11a09473f3be14d44af3f8743",
          "name": "Stobox",
          "symbol": "STBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906"
        },
        {
          "chainId": 56,
          "address": "0x2aaf50869739e317ab80a57bf87caa35f5b60598",
          "name": "Crosschain IOTX",
          "symbol": "CIOTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG?1631579018"
        },
        {
          "chainId": 56,
          "address": "0xb0ff8188f374902bb180bd186d17967b5b1188f2",
          "name": "ArchAngel",
          "symbol": "ARCHA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18814/thumb/Archa_200_x_200_PNG.png?1674811033"
        },
        {
          "chainId": 56,
          "address": "0x35156b404c3f9bdaf45ab65ba315419bcde3775c",
          "name": "Chihiro Inu",
          "symbol": "CHIRO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png?1642166355"
        },
        {
          "chainId": 56,
          "address": "0x9b4bdddaeb68d85b0848bab7774e6855439fd94e",
          "name": "Tiger King Coin",
          "symbol": "TKING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15605/thumb/tigerking.png?1649149229"
        },
        {
          "chainId": 56,
          "address": "0x3107c0a1126268ca303f8d99c712392fa596e6d7",
          "name": "Gem Exchange and Trading",
          "symbol": "GXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12226/thumb/a.png?1653378779"
        },
        {
          "chainId": 56,
          "address": "0xb6c53431608e626ac81a9776ac3e999c5556717c",
          "name": "Wrapped Telos",
          "symbol": "WTLOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23952/thumb/tL4cEmvt_400x400.png?1645772510"
        },
        {
          "chainId": 56,
          "address": "0xb44c63a09adf51f5e62cc7b63628b1b789941fa0",
          "name": "Reflex",
          "symbol": "RFX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456"
        },
        {
          "chainId": 56,
          "address": "0x3764bc0de9b6a68c67929130aaec16b6060cab8c",
          "name": "Xido Finance",
          "symbol": "XIDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png?1623141959"
        },
        {
          "chainId": 56,
          "address": "0xe91a8d2c584ca93c7405f15c22cdfe53c29896e3",
          "name": "DexTools",
          "symbol": "DEXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188"
        },
        {
          "chainId": 56,
          "address": "0xbe4cb2c354480042a39350a0c6c26bf54786539f",
          "name": "DeFinity",
          "symbol": "DEFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png?1622414896"
        },
        {
          "chainId": 56,
          "address": "0x6c619006043eab742355395690c7b42d3411e8c0",
          "name": "ELYFI",
          "symbol": "ELFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png?1645691480"
        },
        {
          "chainId": 56,
          "address": "0x45f7967926e95fd161e56ed66b663c9114c5226f",
          "name": "Tokoin",
          "symbol": "TOKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png?1646664093"
        },
        {
          "chainId": 56,
          "address": "0x222cf80a8514f8ce551c06d1b8d01db3678688ad",
          "name": "SpaceDawgs",
          "symbol": "DAWGS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png?1646792743"
        },
        {
          "chainId": 56,
          "address": "0xf64ed9ad397a1ae657f31131d4b189220a7f1cc7",
          "name": "DeFiato",
          "symbol": "DFIAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873"
        },
        {
          "chainId": 56,
          "address": "0xcf87d3d50a98a7832f5cfdf99ae1b88c7cfba4a7",
          "name": "0x nodes",
          "symbol": "BIOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736"
        },
        {
          "chainId": 56,
          "address": "0xc3afde95b6eb9ba8553cdaea6645d45fb3a7faf5",
          "name": "Kiba Inu",
          "symbol": "KIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19525/thumb/kiba.png?1648549322"
        },
        {
          "chainId": 56,
          "address": "0x23b8683ff98f9e4781552dfe6f12aa32814924e8",
          "name": "Jarvis Synthetic Euro",
          "symbol": "JEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png?1634046044"
        },
        {
          "chainId": 56,
          "address": "0x7c869b5a294b1314e985283d01c702b62224a05f",
          "name": "Jarvis Synthetic Swiss Franc",
          "symbol": "JCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png?1634046084"
        },
        {
          "chainId": 56,
          "address": "0x40225c6277b29bf9056b4acb7ee1512cbff11671",
          "name": "Buying com",
          "symbol": "BUY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5290/thumb/buying_200x200.png?1628587856"
        },
        {
          "chainId": 56,
          "address": "0x6b1c8765c7eff0b60706b0ae489eb9bb9667465a",
          "name": "Signata",
          "symbol": "SATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256"
        },
        {
          "chainId": 56,
          "address": "0x8038b1f3eb4f70436569618530ac96b439d67bae",
          "name": "MicroTuber",
          "symbol": "MCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638"
        },
        {
          "chainId": 56,
          "address": "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
          "name": "MAI",
          "symbol": "MIMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018"
        },
        {
          "chainId": 56,
          "address": "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
          "name": "STEPN",
          "symbol": "GMT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23597/thumb/gmt.png?1644658792"
        },
        {
          "chainId": 56,
          "address": "0x76a797a59ba2c17726896976b7b3747bfd1d220f",
          "name": "Toncoin",
          "symbol": "TON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17980/thumb/ton_symbol.png?1670498136"
        },
        {
          "chainId": 56,
          "address": "0x8ac0a467f878f3561d309cf9b0994b0530b0a9d2",
          "name": "Minter Hub",
          "symbol": "HUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14639/thumb/hub200.png?1651724396"
        },
        {
          "chainId": 56,
          "address": "0x69a1913d334b524ea1632461c78797c837ca9fa6",
          "name": "RioDeFi",
          "symbol": "RFUEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png?1602481093"
        },
        {
          "chainId": 56,
          "address": "0x854a63b35b70a7becbed508ff0b6ff5038d0c917",
          "name": "Minato",
          "symbol": "MNTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664"
        },
        {
          "chainId": 56,
          "address": "0xfb6115445bff7b52feb98650c87f44907e58f802",
          "name": "Aave",
          "symbol": "AAVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110"
        },
        {
          "chainId": 56,
          "address": "0x049dd7532148826cde956c7b45fec8c30b514052",
          "name": "IoTeXPad",
          "symbol": "TEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20964/thumb/7gyi5TV8_400x400.jpg?1638152165"
        },
        {
          "chainId": 56,
          "address": "0x630d98424efe0ea27fb1b3ab7741907dffeaad78",
          "name": "PEAKDEFI",
          "symbol": "PEAK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9626/thumb/PEAKDEFI_Logo_250x250.png?1603094772"
        },
        {
          "chainId": 56,
          "address": "0xe80772eaf6e2e18b651f160bc9158b2a5cafca65",
          "name": "Overnight fi USD ",
          "symbol": "USD+",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25757/thumb/USD__logo.png?1653519267"
        },
        {
          "chainId": 56,
          "address": "0x0a7e7d210c45c4abba183c1d0551b53ad1756eca",
          "name": "Everscale",
          "symbol": "EVER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/12783/thumb/everscale_badge_main_round_1x.png?1640050196"
        },
        {
          "chainId": 56,
          "address": "0x433fce7dfbec729a79999eaf056cb073b2153eba",
          "name": "CoinWealth",
          "symbol": "CNW",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522"
        },
        {
          "chainId": 56,
          "address": "0xff8bbc599ea030aa69d0298035dfe263740a95bc",
          "name": "Dohrnii",
          "symbol": "DHN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24966/thumb/dhn.png?1649586405"
        },
        {
          "chainId": 56,
          "address": "0x82190d28e710ea9c029d009fad951c6f1d803bb3",
          "name": "Life Crypto",
          "symbol": "LIFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18245/thumb/communityIcon_t3kzc5skazh81.png?1666058038"
        },
        {
          "chainId": 56,
          "address": "0x7269d98af4aa705e0b1a5d8512fadb4d45817d5a",
          "name": "Shirtum",
          "symbol": "SHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png?1625794164"
        },
        {
          "chainId": 56,
          "address": "0xa41f142b6eb2b164f8164cae0716892ce02f311f",
          "name": "Avocado DAO",
          "symbol": "AVG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png?1640567889"
        },
        {
          "chainId": 56,
          "address": "0x20ee7b720f4e4c4ffcb00c4065cdae55271aecca",
          "name": "APENFT",
          "symbol": "NFT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15687/thumb/apenft.jpg?1621562368"
        },
        {
          "chainId": 56,
          "address": "0x6451c6484d23889003c20be51819d6aa7dbd2b35",
          "name": "POLYSPORTS",
          "symbol": "PS1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg?1650024620"
        },
        {
          "chainId": 56,
          "address": "0x762539b45a1dcce3d36d080f74d1aed37844b878",
          "name": "Linear",
          "symbol": "LINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12509/thumb/1649227343-linalogo200px.png?1649229117"
        },
        {
          "chainId": 56,
          "address": "0x7faaf8d4c411218415d9d3f82d56214658349dbb",
          "name": "Betero",
          "symbol": "BTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529"
        },
        {
          "chainId": 56,
          "address": "0x3470c81026c8085b7b743695f851353043ff0d0d",
          "name": "Coliquidity",
          "symbol": "COLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png?1646641417"
        },
        {
          "chainId": 56,
          "address": "0x2c717059b366714d267039af8f59125cadce6d8c",
          "name": "MetaShooter",
          "symbol": "MHUNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24985/thumb/200x200.png?1649681575"
        },
        {
          "chainId": 56,
          "address": "0x8cd29d79f9376f353c493a7f2ff9d27df8d372de",
          "name": "WFDP",
          "symbol": "WFDP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23758/thumb/wfdp.png?1647695338"
        },
        {
          "chainId": 56,
          "address": "0x1fa4a73a3f0133f0025378af00236f3abdee5d63",
          "name": "Wrapped Near",
          "symbol": "WNEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18280/thumb/EX4mrWMW_400x400.jpg?1631244046"
        },
        {
          "chainId": 56,
          "address": "0x01e04c6e0b2c93bb4f8ee4b71072b861f9352660",
          "name": "American Shiba",
          "symbol": "USHIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610"
        },
        {
          "chainId": 56,
          "address": "0x4550003152f12014558e5ce025707e4dd841100f",
          "name": "Kaizen Finance",
          "symbol": "KZEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756"
        },
        {
          "chainId": 56,
          "address": "0xb001f1e7c8bda414ac7cf7ecba5469fe8d24b6de",
          "name": "Archethic",
          "symbol": "UCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png?1665916980"
        },
        {
          "chainId": 56,
          "address": "0xd17479997f34dd9156deef8f95a52d81d265be9c",
          "name": "USDD",
          "symbol": "USDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25380/thumb/UUSD.jpg?1651823371"
        },
        {
          "chainId": 56,
          "address": "0xe4cc45bb5dbda06db6183e8bf016569f40497aa5",
          "name": "Galxe",
          "symbol": "GAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24530/thumb/galaxy.jpg?1662517258"
        },
        {
          "chainId": 56,
          "address": "0x7f792db54b0e580cdc755178443f0430cf799aca",
          "name": "Volt Inu",
          "symbol": "VOLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25201/thumb/logo200.png?1653635992"
        },
        {
          "chainId": 56,
          "address": "0x398f7827dccbefe6990478876bbf3612d93baf05",
          "name": "MixMarvel",
          "symbol": "MIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8222/thumb/8878caf93b1e3b6cfb3b414bda3b5250.png?1613945432"
        },
        {
          "chainId": 56,
          "address": "0x482e6bd0a178f985818c5dfb9ac77918e8412fba",
          "name": "Colizeum",
          "symbol": "ZEUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24448/thumb/AB0cGpnx_400x400.jpg?1647681843"
        },
        {
          "chainId": 56,
          "address": "0xe336a772532650bc82828e9620dd0d5a3b78bfe8",
          "name": "DigiMetaverse",
          "symbol": "DGMV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png?1645081296"
        },
        {
          "chainId": 56,
          "address": "0x361c60b7c2828fcab80988d00d1d542c83387b50",
          "name": "DeFiChain",
          "symbol": "DFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11757/thumb/symbol-defi-blockchain_200.png?1597306538"
        },
        {
          "chainId": 56,
          "address": "0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba",
          "name": "Magic Internet Money",
          "symbol": "MIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612"
        },
        {
          "chainId": 56,
          "address": "0x388d819724dd6d71760a38f00dc01d310d879771",
          "name": "JustMoney",
          "symbol": "JM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25450/thumb/jm.png?1651803017"
        },
        {
          "chainId": 56,
          "address": "0x5dd1e31e1a0e2e077ac98d2a4b781f418ca50387",
          "name": "Zelwin",
          "symbol": "ZLW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128"
        },
        {
          "chainId": 56,
          "address": "0x9ce84f6a69986a83d92c324df10bc8e64771030f",
          "name": "CHEX Token",
          "symbol": "CHEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png?1578434355"
        },
        {
          "chainId": 56,
          "address": "0xb5be8d87fce6ce87a24b90abdb019458a8ec31f9",
          "name": "Obortech",
          "symbol": "OBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515"
        },
        {
          "chainId": 56,
          "address": "0x7c3cc93f39f0dbc9e00f96d1fa4ea52e36b3476b",
          "name": "Vitamin Coin",
          "symbol": "VITC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20661/thumb/vitc_200.png?1637545079"
        },
        {
          "chainId": 56,
          "address": "0x67db74b6d1ea807cb47248489c99d144323d348d",
          "name": "MMS Coin",
          "symbol": "MMSC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18695/thumb/MMSC_logo.png?1654677904"
        },
        {
          "chainId": 56,
          "address": "0x74d23db8fd35fd20e1964f7197147c8a22d92a8d",
          "name": "Naxar",
          "symbol": "NAXAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16946/thumb/logo.png?1655886562"
        },
        {
          "chainId": 56,
          "address": "0x410a56541bd912f9b60943fcb344f1e3d6f09567",
          "name": "Minto",
          "symbol": "BTCMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21819/thumb/MNVvqe2n_400x400.png?1640072390"
        },
        {
          "chainId": 56,
          "address": "0x40fed5691e547885cabd7a2990de719dcc8497fc",
          "name": "Safe Haven",
          "symbol": "SHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471"
        },
        {
          "chainId": 56,
          "address": "0x489580eb70a50515296ef31e8179ff3e77e24965",
          "name": "DappRadar",
          "symbol": "RADAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20894/thumb/radar.png?1640306268"
        },
        {
          "chainId": 56,
          "address": "0x7c3b67b30efbacc8f787f7ebd3bdc65234299f4c",
          "name": "ClinTex CTi",
          "symbol": "CTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542"
        },
        {
          "chainId": 56,
          "address": "0x5fb4968fc85868df3ad2d6e59883a10570f01d18",
          "name": "Share",
          "symbol": "SHR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410"
        },
        {
          "chainId": 56,
          "address": "0xd37ef7df703b6f8eec56cd4e082bb3e899e41f2b",
          "name": "Xiglute Coin",
          "symbol": "XGC",
          "decimals": 14,
          "logoURI": "https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016"
        },
        {
          "chainId": 56,
          "address": "0x3bfad48181c9e88e1dd9c1b48887e33e2653db4d",
          "name": "ViCat",
          "symbol": "VICAT",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/26125/thumb/ViCat_Logo_200x200.PNG?1655952116"
        },
        {
          "chainId": 56,
          "address": "0x30807d3b851a31d62415b8bb7af7dca59390434a",
          "name": "RadioShack",
          "symbol": "RADIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260"
        },
        {
          "chainId": 56,
          "address": "0x9929b92f4c743d014c68dfe022d04c8c8fcfa37a",
          "name": "OpenSwap One",
          "symbol": "OPENX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19274/thumb/X_color.png?1654833718"
        },
        {
          "chainId": 56,
          "address": "0xaf6162dc717cfc8818efc8d6f46a41cf7042fcba",
          "name": "Atlas USV",
          "symbol": "USV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png?1640744823"
        },
        {
          "chainId": 56,
          "address": "0xfa37e513e6cd506c4694b992825a8b614c035581",
          "name": "Nexum",
          "symbol": "NEXM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225"
        },
        {
          "chainId": 56,
          "address": "0xd2cdfd5d26dfa1d11116b9ed7dbd7c6b88c6e1d3",
          "name": "BlackCoin",
          "symbol": "BLK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/50/thumb/blackcoin.png?1547274445"
        },
        {
          "chainId": 56,
          "address": "0xe8647ea19496e87c061bbad79f457928b2f52b5a",
          "name": "Popcorn",
          "symbol": "POP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611"
        },
        {
          "chainId": 56,
          "address": "0xe05d1c28b3f8127b5b058f101198ede30fe3961d",
          "name": "Martin Shkreli Inu",
          "symbol": "MSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26365/thumb/jEYEUxUI_400x400.jpeg?1657600080"
        },
        {
          "chainId": 56,
          "address": "0x3da932456d082cba208feb0b096d49b202bf89c8",
          "name": "Dego Finance",
          "symbol": "DEGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png?1600298167"
        },
        {
          "chainId": 56,
          "address": "0xec583f25a049cc145da9a256cdbe9b6201a705ff",
          "name": "Drep",
          "symbol": "DREP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445"
        },
        {
          "chainId": 56,
          "address": "0xde7d1ce109236b12809c45b23d22f30dba0ef424",
          "name": "SpiceUSD",
          "symbol": "USDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25697/thumb/USDS.png?1653440948"
        },
        {
          "chainId": 56,
          "address": "0x7b610012bdc4d6deba2c2d91684e408f40863429",
          "name": "Omnisea",
          "symbol": "OSEA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766"
        },
        {
          "chainId": 56,
          "address": "0x766afcf83fd5eaf884b3d529b432ca27a6d84617",
          "name": "Bolide",
          "symbol": "BLID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25548/thumb/BLID_token_logo_200x200.png?1652334831"
        },
        {
          "chainId": 56,
          "address": "0xf0eb3c9088718a533c8fd64dbcaa5927faed6d18",
          "name": "Neonomad",
          "symbol": "NNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25068/thumb/SeSkZxx7_400x400.jpeg?1658118217"
        },
        {
          "chainId": 56,
          "address": "0x85dab10c3ba20148ca60c2eb955e1f8ffe9eaa79",
          "name": "ARTH",
          "symbol": "ARTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16876/thumb/Ik5dhOq.png?1674126397"
        },
        {
          "chainId": 56,
          "address": "0x4c882ec256823ee773b25b414d36f92ef58a7c0c",
          "name": "pSTAKE Finance",
          "symbol": "PSTAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930"
        },
        {
          "chainId": 56,
          "address": "0xffe2a166a3ea6dd7bb11b2c48f08f1e4202d4e78",
          "name": "Xave Coin",
          "symbol": "XVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg?1646292464"
        },
        {
          "chainId": 56,
          "address": "0xbfef6ccfc830d3baca4f6766a0d4aaa242ca9f3d",
          "name": "Navcoin",
          "symbol": "NAV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/233/thumb/Navcoin_Logo.png?1618823660"
        },
        {
          "chainId": 56,
          "address": "0x5ca42204cdaa70d5c773946e69de942b85ca6706",
          "name": "Position",
          "symbol": "POSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17459/thumb/posi.png?1627887743"
        },
        {
          "chainId": 56,
          "address": "0x8b1f4432f943c465a973fedc6d7aa50fc96f1f65",
          "name": "Axelar",
          "symbol": "AXL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730"
        },
        {
          "chainId": 56,
          "address": "0x94025780a1ab58868d9b2dbbb775f44b32e8e6e5",
          "name": "BetSwirl",
          "symbol": "BETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png?1659073275"
        },
        {
          "chainId": 56,
          "address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
          "name": "Stella",
          "symbol": "ALPHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12738/thumb/Stella200x200-06.png?1684988292"
        },
        {
          "chainId": 56,
          "address": "0x928e55dab735aa8260af3cedada18b5f70c72f1b",
          "name": "Frontier",
          "symbol": "FRONT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png?1600145472"
        },
        {
          "chainId": 56,
          "address": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
          "name": "Chainlink",
          "symbol": "LINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700"
        },
        {
          "chainId": 56,
          "address": "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
          "name": "Uniswap",
          "symbol": "UNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12504/thumb/uni.jpg?1687143398"
        },
        {
          "chainId": 56,
          "address": "0x7fe378c5e0b5c32af2ecc8829bedf02245a0e4ef",
          "name": "99Starz",
          "symbol": "STZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21467/thumb/stz.png?1639651623"
        },
        {
          "chainId": 56,
          "address": "0xf6b53b4c982b9b7e87af9dc5c66c85117a5df303",
          "name": "Denarius",
          "symbol": "D",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/760/thumb/denarius.png?1547034506"
        },
        {
          "chainId": 56,
          "address": "0xcd7c5025753a49f1881b31c48caa7c517bb46308",
          "name": "Raven Protocol",
          "symbol": "RAVEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8673/thumb/Raven_Protocol.jpg?1560248948"
        },
        {
          "chainId": 56,
          "address": "0xbea7086c99a85d4b5e6a0494c18b037fdd8ee932",
          "name": "Freedom Reserve",
          "symbol": "FR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046"
        },
        {
          "chainId": 56,
          "address": "0x82e7eb8f4c307f2dcf522fdca7b7038296584f29",
          "name": "SWTCoin",
          "symbol": "SWAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927"
        },
        {
          "chainId": 56,
          "address": "0xf6565a97dc832d93dc83b75ee9aa5c7e8ecb0f9d",
          "name": "Hyve",
          "symbol": "HYVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255"
        },
        {
          "chainId": 56,
          "address": "0xc5e6689c9c8b02be7c49912ef19e79cf24977f03",
          "name": "Alpaca City",
          "symbol": "ALPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13070/thumb/alpaca_logo.png?1604895862"
        },
        {
          "chainId": 56,
          "address": "0x77018282fd033daf370337a5367e62d8811bc885",
          "name": "Poolz Finance  OLD ",
          "symbol": "POOLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png?1610806091"
        },
        {
          "chainId": 56,
          "address": "0x4ee438be38f8682abb089f2bfea48851c5e71eaf",
          "name": "Cryptonovae",
          "symbol": "YAE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14693/thumb/yae.png?1640337904"
        },
        {
          "chainId": 56,
          "address": "0x2645d5f59d952ef2317c8e0aaa5a61c392ccd44d",
          "name": "UniLend Finance",
          "symbol": "UFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png?1602748658"
        },
        {
          "chainId": 56,
          "address": "0x7f692c05058f1c77c87413a0591c7a237090da00",
          "name": "Skillchain",
          "symbol": "SKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168"
        },
        {
          "chainId": 56,
          "address": "0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f",
          "name": "BoringDAO",
          "symbol": "BORING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16429/thumb/Tjq3pXEH_400x400.jpg?1623997009"
        },
        {
          "chainId": 56,
          "address": "0x13a637026df26f846d55acc52775377717345c06",
          "name": "SpaceY 2025",
          "symbol": "SPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG?1637132488"
        },
        {
          "chainId": 56,
          "address": "0x1ce440d1a64eea6aa1db2a5aa51c9b326930957c",
          "name": "A2DAO",
          "symbol": "ATD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14509/thumb/Logo.jpg?1680842346"
        },
        {
          "chainId": 56,
          "address": "0xd52669712f253cd6b2fe8a8638f66ed726cb770c",
          "name": "Curate",
          "symbol": "XCUR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208"
        },
        {
          "chainId": 56,
          "address": "0xaaa9214f675316182eaa21c85f0ca99160cc3aaa",
          "name": "QANplatform",
          "symbol": "QANX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15977/thumb/qanx.png?1637574290"
        },
        {
          "chainId": 56,
          "address": "0xe138c66982fd5c890c60b94fdba1747faf092c20",
          "name": "Offshift",
          "symbol": "XFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png?1614570441"
        },
        {
          "chainId": 56,
          "address": "0x8595f9da7b868b1822194faed312235e43007b49",
          "name": "BitTorrent  OLD ",
          "symbol": "BTTOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7595/thumb/BTT_Token_Graphic.png?1555066995"
        },
        {
          "chainId": 56,
          "address": "0xdae6c2a48bfaa66b43815c5548b10800919c993e",
          "name": "Kattana",
          "symbol": "KTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14739/thumb/256-256-1.png?1638363577"
        },
        {
          "chainId": 56,
          "address": "0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66",
          "name": "CEEK Smart VR",
          "symbol": "CEEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2581/thumb/ceek-smart-vr-token-logo.png?1547036714"
        },
        {
          "chainId": 56,
          "address": "0x8da443f84fea710266c8eb6bc34b71702d033ef2",
          "name": "Cartesi",
          "symbol": "CTSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11038/thumb/Cartesi_Logo.png?1689603517"
        },
        {
          "chainId": 56,
          "address": "0x1ba8d3c4c219b124d351f603060663bd1bcd9bbf",
          "name": "Tornado Cash",
          "symbol": "TORN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg?1609193407"
        },
        {
          "chainId": 56,
          "address": "0x6bff4fb161347ad7de4a625ae5aa3a1ca7077819",
          "name": "Ambire AdEx",
          "symbol": "ADX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540"
        },
        {
          "chainId": 56,
          "address": "0x6d1dc3928604b00180bb570bdae94b9698d33b79",
          "name": "UnitedCrowd",
          "symbol": "UCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511"
        },
        {
          "chainId": 56,
          "address": "0x658e64ffcf40d240a43d52ca9342140316ae44fa",
          "name": "OIN Finance",
          "symbol": "OIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12339/thumb/OIN_FInance_-_cLogo-01.png?1631796334"
        },
        {
          "chainId": 56,
          "address": "0x9cd9c5a44cb8fab39b2ee3556f5c439e65e4fddd",
          "name": "MARS4",
          "symbol": "MARS4",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18709/thumb/mars4_logo.jpg?1633070237"
        },
        {
          "chainId": 56,
          "address": "0x7e624fa0e1c4abfd309cc15719b7e2580887f570",
          "name": "Polkastarter",
          "symbol": "POLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702"
        },
        {
          "chainId": 56,
          "address": "0xe2e7329499e8ddb1f2b04ee4b35a8d7f6881e4ea",
          "name": "AnRKey X",
          "symbol": "ANRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg?1608311301"
        },
        {
          "chainId": 56,
          "address": "0xa2b726b1145a4773f68593cf171187d8ebe4d495",
          "name": "Injective",
          "symbol": "INJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237"
        },
        {
          "chainId": 56,
          "address": "0x7849ed1447250d0b896f89b58f3075b127ca29b3",
          "name": "TOKPIE",
          "symbol": "TKP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865"
        },
        {
          "chainId": 56,
          "address": "0x474021845c4643113458ea4414bdb7fb74a01a77",
          "name": "Uno Re",
          "symbol": "UNO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15073/thumb/c0vbqVE.png?1632814516"
        },
        {
          "chainId": 56,
          "address": "0xdfc3829b127761a3218bfcee7fc92e1232c9d116",
          "name": "PRivaCY Coin",
          "symbol": "PRCY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991"
        },
        {
          "chainId": 56,
          "address": "0xf307910a4c7bbc79691fd374889b36d8531b08e3",
          "name": "Ankr Network",
          "symbol": "ANKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978"
        },
        {
          "chainId": 56,
          "address": "0x6d8734002fbffe1c86495e32c95f732fc77f6f2a",
          "name": "Peanut",
          "symbol": "NUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg?1613353972"
        },
        {
          "chainId": 56,
          "address": "0x5de3939b2f811a61d830e6f52d13b066881412ab",
          "name": "Proton",
          "symbol": "XPR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/10941/thumb/Proton-Icon.png?1588283737"
        },
        {
          "chainId": 56,
          "address": "0x5ecc4b299e23f526980c33fe35eff531a54aedb1",
          "name": "TigerCash",
          "symbol": "TCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378"
        },
        {
          "chainId": 56,
          "address": "0x5989d72a559eb0192f2d20170a43a4bd28a1b174",
          "name": "NFTify",
          "symbol": "N1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png?1627130530"
        },
        {
          "chainId": 56,
          "address": "0xbe1a001fe942f96eea22ba08783140b9dcc09d28",
          "name": "Beta Finance",
          "symbol": "BETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18715/thumb/beta_finance.jpg?1633087053"
        },
        {
          "chainId": 56,
          "address": "0x728c5bac3c3e370e372fc4671f9ef6916b814d8b",
          "name": "Unifi Protocol DAO",
          "symbol": "UNFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967"
        },
        {
          "chainId": 56,
          "address": "0xed4bb33f20f32e989af975196e86019773a7cff0",
          "name": "UTU Coin",
          "symbol": "UTU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png?1602884636"
        },
        {
          "chainId": 56,
          "address": "0x304fc73e86601a61a6c6db5b0eafea587622acdc",
          "name": "CoTrader",
          "symbol": "COT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png?1547039508"
        },
        {
          "chainId": 56,
          "address": "0x790cfdc6ab2e0ee45a433aac5434f183be1f6a20",
          "name": "Pillar",
          "symbol": "PLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png?1624906282"
        },
        {
          "chainId": 56,
          "address": "0x3757232b55e60da4a8793183ac030cfce4c3865d",
          "name": "YDragon",
          "symbol": "YDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17807/thumb/icon.png?1629302175"
        },
        {
          "chainId": 56,
          "address": "0xe338d4250a4d959f88ff8789eaae8c32700bd175",
          "name": "Relay Chain",
          "symbol": "RELAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png?1629339288"
        },
        {
          "chainId": 56,
          "address": "0x3b79a28264fc52c7b4cea90558aa0b162f7faf57",
          "name": "Public Index Network",
          "symbol": "PIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/241/thumb/pin.jpeg?1623054253"
        },
        {
          "chainId": 56,
          "address": "0xf218184af829cf2b0019f8e6f0b2423498a36983",
          "name": "MATH",
          "symbol": "MATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590"
        },
        {
          "chainId": 56,
          "address": "0x0eb3a705fc54725037cc9e008bdede697f62f335",
          "name": "Cosmos Hub",
          "symbol": "ATOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1481/thumb/cosmos_hub.png?1555657960"
        },
        {
          "chainId": 56,
          "address": "0x808f1350dff684c099f4837a01d863fc61a86bc6",
          "name": "MetaFinance",
          "symbol": "MFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG?1627435447"
        },
        {
          "chainId": 56,
          "address": "0xc1e0510a0df7646817b6632d32caa681a425a5e6",
          "name": "CFL365 Finance",
          "symbol": "CFL365",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG?1628218865"
        },
        {
          "chainId": 56,
          "address": "0xc9132c76060f6b319764ea075973a650a1a53bc9",
          "name": "DuckDaoDime",
          "symbol": "DDIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12146/thumb/token_DDIM-01.png?1606982032"
        },
        {
          "chainId": 56,
          "address": "0x5d186e28934c6b0ff5fc2fece15d1f34f78cbd87",
          "name": "DLP Duck",
          "symbol": "DUCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13440/thumb/DLP_Duck_Token.png?1612840740"
        },
        {
          "chainId": 56,
          "address": "0x471ea49dd8e60e697f4cac262b5fafcc307506e4",
          "name": "Kommunitas",
          "symbol": "KOM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17483/thumb/1_f1S3h57YLT1e1cl8g7RJpw_2x.jpeg?1627956421"
        },
        {
          "chainId": 56,
          "address": "0x436c52a8cee41d5e9c5e6f4cb146e66d552fb700",
          "name": "EQIFi",
          "symbol": "EQX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png?1627968404"
        },
        {
          "chainId": 56,
          "address": "0x65ad6a2288b2dd23e466226397c8f5d1794e58fc",
          "name": "GamyFi",
          "symbol": "GFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124"
        },
        {
          "chainId": 56,
          "address": "0x289f347ac469bc1b7359ed95c87c75ac2c3eb16f",
          "name": "Feichang Niu",
          "symbol": "FCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17476/thumb/logo_-_2021-08-02T172142.650.png?1627896154"
        },
        {
          "chainId": 56,
          "address": "0x320d31183100280ccdf69366cd56180ea442a3e8",
          "name": "Lightcoin",
          "symbol": "LHC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG?1623835248"
        },
        {
          "chainId": 56,
          "address": "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
          "name": "Polygon",
          "symbol": "MATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912"
        },
        {
          "chainId": 56,
          "address": "0xed28a457a5a76596ac48d87c0f577020f6ea1c4c",
          "name": "pTokens BTC  OLD ",
          "symbol": "PBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10805/thumb/J51iIea.png?1583891599"
        },
        {
          "chainId": 56,
          "address": "0x368ce786ea190f32439074e8d22e12ecb718b44c",
          "name": "Epik Prime",
          "symbol": "EPIK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg?1630738458"
        },
        {
          "chainId": 56,
          "address": "0x66cafcf6c32315623c7ffd3f2ff690aa36ebed38",
          "name": "Brokoli",
          "symbol": "BRKL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18763/thumb/brkl.png?1633356263"
        },
        {
          "chainId": 56,
          "address": "0xbd525e51384905c6c0936a431bc7efb6c4903ea0",
          "name": "Bistroo",
          "symbol": "BIST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png?1659342030"
        },
        {
          "chainId": 56,
          "address": "0xe4e8e6878718bfe533702d4a6571eb74d79b0915",
          "name": "LunaChow",
          "symbol": "LUCHOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157"
        },
        {
          "chainId": 56,
          "address": "0x43f5b29d63cedc5a7c1724dbb1d698fde05ada21",
          "name": "Fodl Finance",
          "symbol": "FODL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19040/thumb/Fodl_Symbol_Gradient.png?1651644153"
        },
        {
          "chainId": 56,
          "address": "0xf16e81dce15b08f326220742020379b855b87df9",
          "name": "Popsicle Finance",
          "symbol": "ICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14586/thumb/ice.png?1617188825"
        },
        {
          "chainId": 56,
          "address": "0xcf909ef9a61dc5b05d46b5490a9f00d51c40bb28",
          "name": "Rice Wallet",
          "symbol": "RICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877"
        },
        {
          "chainId": 56,
          "address": "0x2ff0b946a6782190c4fe5d4971cfe79f0b6e4df2",
          "name": "Mysterium",
          "symbol": "MYST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/757/thumb/mysterium.png?1547034503"
        },
        {
          "chainId": 56,
          "address": "0xe7c9c6bc87b86f9e5b57072f907ee6460b593924",
          "name": "Tower",
          "symbol": "TOWER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14134/thumb/tower-circular-1000.png?1632195469"
        },
        {
          "chainId": 56,
          "address": "0xa4b6573c9ae09d81e4d1360e6402b81f52557098",
          "name": "COR Token",
          "symbol": "COR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12856/thumb/COR.png?1620210966"
        },
        {
          "chainId": 56,
          "address": "0xfb7400707df3d76084fbeae0109f41b178f71c02",
          "name": "Shadows",
          "symbol": "DOWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14160/thumb/C3E49eZx_400x400.jpg?1614689301"
        },
        {
          "chainId": 56,
          "address": "0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51",
          "name": "Enreach",
          "symbol": "NRCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg?1617813758"
        },
        {
          "chainId": 56,
          "address": "0xcea59dce6a6d73a24e6d6944cfabc330814c098a",
          "name": "TORG",
          "symbol": "TORG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056"
        },
        {
          "chainId": 56,
          "address": "0xaee433adebe0fbb88daa47ef0c1a513caa52ef02",
          "name": "Pontoon",
          "symbol": "TOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG?1635467899"
        },
        {
          "chainId": 56,
          "address": "0xaa88c603d142c371ea0eac8756123c5805edee03",
          "name": "The Doge NFT",
          "symbol": "DOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110"
        },
        {
          "chainId": 56,
          "address": "0x40e51e0ec04283e300f12f6bb98da157bb22036e",
          "name": "bloXmove",
          "symbol": "BLXM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png?1635238192"
        },
        {
          "chainId": 56,
          "address": "0xdb021b1b247fe2f1fa57e0a87c748cc1e321f07f",
          "name": "Ampleforth",
          "symbol": "AMPL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png?1561684250"
        },
        {
          "chainId": 56,
          "address": "0xf0902eb0049a4003793bab33f3566a22d2834442",
          "name": "Glitch Protocol",
          "symbol": "GLCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg?1611100011"
        },
        {
          "chainId": 56,
          "address": "0xaf00aac2431b04ef6afd904d19b08d5146e3a9a0",
          "name": "Portion",
          "symbol": "PRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13617/thumb/OKeO2FI.png?1610327038"
        },
        {
          "chainId": 56,
          "address": "0x61d5822dd7b3ed495108733e6550d4529480c8f6",
          "name": "Pancake Games",
          "symbol": "GCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png?1636671365"
        },
        {
          "chainId": 56,
          "address": "0xa0bed124a09ac2bd941b10349d8d224fe3c955eb",
          "name": "DePay",
          "symbol": "DEPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13183/thumb/DEPAY.png?1650615816"
        },
        {
          "chainId": 56,
          "address": "0x6d6ba21e4c4b29ca7bfa1c344ba1e35b8dae7205",
          "name": "Katana Inu",
          "symbol": "KATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png?1640236880"
        },
        {
          "chainId": 56,
          "address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
          "name": "Spores Network",
          "symbol": "SPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17431/thumb/SPO_token.png?1627619762"
        },
        {
          "chainId": 56,
          "address": "0x8ea2526c2373ba3fe1d0987f5db8ac770a42dd51",
          "name": "Everest",
          "symbol": "ID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5209/thumb/Everest.jpg?1628042930"
        },
        {
          "chainId": 56,
          "address": "0x6f620ec89b8479e97a6985792d0c64f237566746",
          "name": "WePiggy Coin",
          "symbol": "WPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945"
        },
        {
          "chainId": 56,
          "address": "0x409e215738e31d8ab252016369c2dd9c2008fee0",
          "name": "Pawthereum",
          "symbol": "PAWTH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19275/thumb/pawth.png?1635127429"
        },
        {
          "chainId": 56,
          "address": "0x8fc4532be3003fb5a3a2f9afc7e95b3bfbd5faab",
          "name": "Arcona",
          "symbol": "ARCONA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4312/thumb/icon_ARCONA_%281%29.png?1651823900"
        },
        {
          "chainId": 56,
          "address": "0x3623f2b63d8f50b477849d29e7c9a6625331e89d",
          "name": "Whole Earth Coin",
          "symbol": "WEC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16313/thumb/WEC_logo.png?1669459247"
        },
        {
          "chainId": 56,
          "address": "0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd",
          "name": "BBS Network",
          "symbol": "BBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23715/thumb/Ni13Pg1K_400x400.jpg?1645110585"
        },
        {
          "chainId": 56,
          "address": "0x14a9a94e555fdd54c21d7f7e328e61d7ebece54b",
          "name": "Lootex",
          "symbol": "LOOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22895/thumb/loot.png?1642850037"
        },
        {
          "chainId": 56,
          "address": "0xbc7d6b50616989655afd682fb42743507003056d",
          "name": "Alchemy Pay",
          "symbol": "ACH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266"
        },
        {
          "chainId": 56,
          "address": "0xb0d502e938ed5f4df2e681fe6e419ff29631d62b",
          "name": "Stargate Finance",
          "symbol": "STG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518"
        },
        {
          "chainId": 56,
          "address": "0x405ce8b2eaeea7d4ba5fc160848cb2a6569e03f0",
          "name": "Metria Network",
          "symbol": "METR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114"
        },
        {
          "chainId": 56,
          "address": "0x3a2927e68749dd6ad0a568d7c05b587863c0bc10",
          "name": "Nunu Spirits",
          "symbol": "NNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059"
        },
        {
          "chainId": 56,
          "address": "0x52fe7b439753092f584917e3efea86a1cfd210f9",
          "name": "Trazable",
          "symbol": "TRZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png?1649133506"
        },
        {
          "chainId": 56,
          "address": "0xa19863e302fd1b41276fce5a48d9c511dbeef34c",
          "name": "Primate",
          "symbol": "PRIMATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png?1651028701"
        },
        {
          "chainId": 56,
          "address": "0x738d96caf7096659db4c1afbf1e1bdfd281f388c",
          "name": "Ankr Staked MATIC",
          "symbol": "ANKRMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25742/thumb/a-matic-c-da4ec10dc9723e695700e25dbf8c8edf.png?1653462321"
        },
        {
          "chainId": 56,
          "address": "0xe79a1163a95734ccfbd006cbaaba954f3e846beb",
          "name": "Shack",
          "symbol": "SHACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433"
        },
        {
          "chainId": 56,
          "address": "0x0c9b3ab1bd0cf0745625381f5c3aa1cd9bbc7abb",
          "name": "Exeno",
          "symbol": "EXN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185"
        },
        {
          "chainId": 56,
          "address": "0x638eebe886b0e9e7c6929e69490064a6c94d204d",
          "name": "Hector Network",
          "symbol": "HEC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19832/thumb/logo-final.png?1635981937"
        },
        {
          "chainId": 56,
          "address": "0xcd6926193308d3b371fdd6a6219067e550000000",
          "name": "Nest Protocol",
          "symbol": "NEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539"
        },
        {
          "chainId": 56,
          "address": "0xf2ba89a6f9670459ed5aeefbd8db52be912228b8",
          "name": "Minter Network",
          "symbol": "BIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9982/thumb/Nvoj_6Mu_400x400.jpg?1587968303"
        },
        {
          "chainId": 56,
          "address": "0xf77351d8f4ee853135961a936bb8d2e4ffa75f9d",
          "name": "Roobee",
          "symbol": "ROOBEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629"
        },
        {
          "chainId": 56,
          "address": "0x1a28ed8472f644e8898a169a644503b779748d6e",
          "name": "RAI Finance",
          "symbol": "SOFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14686/thumb/sofi.png?1640249595"
        },
        {
          "chainId": 56,
          "address": "0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623",
          "name": "dForce",
          "symbol": "DF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794"
        },
        {
          "chainId": 56,
          "address": "0x48b19b7605429acaa8ea734117f39726a9aab1f9",
          "name": "Etho Protocol",
          "symbol": "ETHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png?1578298993"
        },
        {
          "chainId": 56,
          "address": "0xbe5166e8e8a5cb801f09a6a0a46c42b7c27be755",
          "name": "KEEPs Coin",
          "symbol": "KVERSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG?1635498049"
        },
        {
          "chainId": 56,
          "address": "0x582c12b30f85162fa393e5dbe2573f9f601f9d91",
          "name": "MetalSwap",
          "symbol": "XMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22075/thumb/Logo_COIN_-_Gradiente.png?1670579810"
        },
        {
          "chainId": 56,
          "address": "0x1d1cb8997570e73949930c01fe5796c88d7336c6",
          "name": "PolkaBridge",
          "symbol": "PBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png?1611377553"
        },
        {
          "chainId": 56,
          "address": "0x90a1e4bbade88366dc44436535f1571d95e666c7",
          "name": "Freeway",
          "symbol": "FWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png?1652862323"
        },
        {
          "chainId": 56,
          "address": "0xa865197a84e780957422237b5d152772654341f3",
          "name": "OpenLeverage",
          "symbol": "OLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26098/thumb/256x256_OLE_Token_Logo.png?1655859035"
        },
        {
          "chainId": 56,
          "address": "0xe64e30276c2f826febd3784958d6da7b55dfbad3",
          "name": "SWFTCOIN",
          "symbol": "SWFTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022"
        },
        {
          "chainId": 56,
          "address": "0xbc7370641ddcf16a27eea11230af4a9f247b61f9",
          "name": "XANA",
          "symbol": "XETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388"
        },
        {
          "chainId": 56,
          "address": "0xd0e98827d675a3231c2ea69d1f3ed12270df1435",
          "name": "Super Rare Ball Potion",
          "symbol": "SRBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25825/thumb/6295ae88b46cd60001d5ac25_SRBS_160.png?1654063925"
        },
        {
          "chainId": 56,
          "address": "0x65c8743a5a266c3512eabd34e65ade42d4355ef1",
          "name": "BlackPearl",
          "symbol": "BPLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141"
        },
        {
          "chainId": 56,
          "address": "0x0b842c7b18bef85daea11f23e1dabe0d6671c19a",
          "name": "Shido",
          "symbol": "SHIDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26674/thumb/Shido.png?1667361249"
        },
        {
          "chainId": 56,
          "address": "0xcbbb3e5099f769f6d4e2b8b92dc0e268f7e099d8",
          "name": "BitcoinZ",
          "symbol": "BTCZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1004/thumb/BTCZ_LOGO-1.png?1601429570"
        },
        {
          "chainId": 56,
          "address": "0x951df2682ff9a963c4243a38d3841c9ba471b8ae",
          "name": "United",
          "symbol": "UTED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12820/thumb/uted_new.png?1672373999"
        },
        {
          "chainId": 56,
          "address": "0xa07c39f8df11ca675f77efc19501e3dddacd03ed",
          "name": "Funex",
          "symbol": "FUNEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png?1660276769"
        },
        {
          "chainId": 56,
          "address": "0xce6bd1833bd077f62b2c1f9a777bb829801d6811",
          "name": "Bobcoin",
          "symbol": "BOBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24264/thumb/bobc.png?1647171532"
        },
        {
          "chainId": 56,
          "address": "0x41065e3428188ba6eb27fbdde8526ae3af8e3830",
          "name": "Swash",
          "symbol": "SWASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759"
        },
        {
          "chainId": 56,
          "address": "0xaf63d8032311fef82c111c060420020f35e78111",
          "name": "Marshall Inu",
          "symbol": "MRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23784/thumb/mri.png?1647693409"
        }
      ],

    1: [
        {
            "chainId": 1,
            "address": "0",
            "name": "Ethereum",
            "symbol": "ETH",
            "decimals": 18,
            "logoURI": "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880"
          },
        {
          "chainId": 1,
          "address": "0x910524678c0b1b23ffb9285a81f99c29c11cbaed",
          "name": "Azuki",
          "symbol": "AZUKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13091/thumb/bdUBSCo.png?1605169403"
        },
        {
          "chainId": 1,
          "address": "0xf7790914dc335b20aa19d7c9c9171e14e278a134",
          "name": "Euro Coinvertible",
          "symbol": "EUR-C",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29903/thumb/Screenshot_2023-04-20_at_5.57.42_PM.png?1681985097"
        },
        {
          "chainId": 1,
          "address": "0x95a4492f028aa1fd432ea71146b433e7b4446611",
          "name": "APY Finance",
          "symbol": "APY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13041/thumb/1*AvkD-OLocausbxqUzezZ0A.png?1604577922"
        },
        {
          "chainId": 1,
          "address": "0xa6a840e50bcaa50da017b91a0d86b8b2d41156ee",
          "name": "EchoLink",
          "symbol": "EKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2437/thumb/echolink.png?1547224598"
        },
        {
          "chainId": 1,
          "address": "0xbd0a4bf098261673d5e6e600fd87ddcd756e6764",
          "name": "Hina Inu",
          "symbol": "HINA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15993/thumb/download.png?1622549919"
        },
        {
          "chainId": 1,
          "address": "0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38",
          "name": "Morpher",
          "symbol": "MPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12619/thumb/morpher_200_200.png?1601524084"
        },
        {
          "chainId": 1,
          "address": "0xe28f0982eeed9ccf816d8496fb742d438056d275",
          "name": "Play Kingdom",
          "symbol": "PKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30023/thumb/logo_color_p.png?1682567316"
        },
        {
          "chainId": 1,
          "address": "0xbf776e4fca664d791c4ee3a71e2722990e003283",
          "name": "Smoothy",
          "symbol": "SMTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030"
        },
        {
          "chainId": 1,
          "address": "0xccf4429db6322d5c611ee964527d42e5d685dd6a",
          "name": "cWBTC",
          "symbol": "CWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10823/thumb/cwbtc.png?1584331700"
        },
        {
          "chainId": 1,
          "address": "0x2dd1b9410c73e16b60240e529e38e30425f00d4e",
          "name": "Kay Pacha",
          "symbol": "PACHA",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/27194/thumb/0_Lzf-8HxwOnPjYWi-.jpeg?1662539360"
        },
        {
          "chainId": 1,
          "address": "0x5dc60c4d5e75d22588fa17ffeb90a63e535efce0",
          "name": "dKargo",
          "symbol": "DKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11875/thumb/bVD0g0dlmrEOPIkt943KZIBZ086eCshyY0jIQFti4zxYdOlFltU8tKa6uJlcA14HvNjX4bc7dxdMvlpoW5NFMND85oG4aiiCbFRhI6eowDfKEBY3BoSVY0IrBbA9SFGIxh_IYrkNC5uNdG-roZ0_TlGO3098now6Tbzga0p4IDqVk6lnaX3TuRC7pgnAYWZM15RD-uEIHr3O_3OoIIWP-.jpg?1595563347"
        },
        {
          "chainId": 1,
          "address": "0x83984d6142934bb535793a82adb0a46ef0f66b6d",
          "name": "Remme",
          "symbol": "REM",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/2152/thumb/semme.png?1561622861"
        },
        {
          "chainId": 1,
          "address": "0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa",
          "name": "Orbs",
          "symbol": "ORBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4630/thumb/Orbs.jpg?1547039896"
        },
        {
          "chainId": 1,
          "address": "0x77777feddddffc19ff86db637967013e6c6a116c",
          "name": "Tornado Cash",
          "symbol": "TORN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg?1609193407"
        },
        {
          "chainId": 1,
          "address": "0x722f97a435278b7383a1e3c47f41773bebf3232c",
          "name": "UCROWDME",
          "symbol": "UCM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12079/thumb/UKm2qXh.png?1605346168"
        },
        {
          "chainId": 1,
          "address": "0x3a1bc4014c4c493db3dbfbdd8ee1417113b462bf",
          "name": "BlockPortal",
          "symbol": "BPTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28883/thumb/photo_2023-01-18_03-27-04.png?1675142999"
        },
        {
          "chainId": 1,
          "address": "0xf7e40af830a2ecbfe72c5210c1ca272e3aa7ca1b",
          "name": "Cultiplan",
          "symbol": "CTPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15798/thumb/Cultiplan_200x200_logo.png?1621927264"
        },
        {
          "chainId": 1,
          "address": "0xc55c2175e90a46602fd42e931f62b3acc1a013ca",
          "name": "Mogul Productions",
          "symbol": "STARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520"
        },
        {
          "chainId": 1,
          "address": "0x8765b1a0eb57ca49be7eacd35b24a574d0203656",
          "name": "MetaGameHub DAO",
          "symbol": "MGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20625/thumb/mgh.PNG?1637318966"
        },
        {
          "chainId": 1,
          "address": "0xaa99199d1e9644b588796f3215089878440d58e0",
          "name": "Alphr",
          "symbol": "ALPHR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15029/thumb/alphr.jpg?1619495712"
        },
        {
          "chainId": 1,
          "address": "0x2c056f9402a0627bc0e580365bb12979fc011e2c",
          "name": "Squid Game 2 0",
          "symbol": "SQUID2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30932/thumb/SQUID2_LOGO_%28200x200%29.png?1689055419"
        },
        {
          "chainId": 1,
          "address": "0x536381a8628dbcc8c70ac9a30a7258442eab4c92",
          "name": "Pantos",
          "symbol": "PAN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9639/thumb/Pantos-logo-compact.png?1619079883"
        },
        {
          "chainId": 1,
          "address": "0x1e8e29ca51363d923725ab9dac73bd7e9c440f71",
          "name": "MEME TAO",
          "symbol": "MTAO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28802/thumb/080A8E4D-5FBB-4567-89E4-1C34224BAC68.jpeg?1674296111"
        },
        {
          "chainId": 1,
          "address": "0xdd2e93924bdd4e20c3cf4a8736e5955224fa450e",
          "name": "Foho Coin",
          "symbol": "FOHO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png?1629859400"
        },
        {
          "chainId": 1,
          "address": "0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04",
          "name": "Aave ETH v1",
          "symbol": "AETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11619/thumb/aETH_2x.png?1591976597"
        },
        {
          "chainId": 1,
          "address": "0xf68415be72377611e95d59bc710ccbbbf94c4fa2",
          "name": "alphAI",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30684/thumb/alphAI.png?1686273356"
        },
        {
          "chainId": 1,
          "address": "0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81",
          "name": "Dopex",
          "symbol": "DPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16652/thumb/DPX_%281%29.png?1624598630"
        },
        {
          "chainId": 1,
          "address": "0xcd58d56906318ce6ad9ef4ae5fb21f61e05c417d",
          "name": "PepManCity",
          "symbol": "PEPMCITY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30761/thumb/pepmancity.png?1686889729"
        },
        {
          "chainId": 1,
          "address": "0x8ffe40a3d0f80c0ce6b203d5cdc1a6a86d9acaea",
          "name": "IG Gold",
          "symbol": "IGG",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/7697/thumb/N7aEdYrY_400x400.png?1561587437"
        },
        {
          "chainId": 1,
          "address": "0x9fa69536d1cda4a04cfb50688294de75b505a9ae",
          "name": "DeRace",
          "symbol": "DERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17438/thumb/DERC_logo_coingecko.png?1665714278"
        },
        {
          "chainId": 1,
          "address": "0x80a2ae356fc9ef4305676f7a3e2ed04e12c33946",
          "name": "cYFI",
          "symbol": "CYFI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17530/thumb/cyfi.PNG?1628123155"
        },
        {
          "chainId": 1,
          "address": "0x40fd72257597aa14c7231a7b1aaa29fce868f677",
          "name": "Sora",
          "symbol": "XOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11093/thumb/sora_logo_cg_white.png?1588284194"
        },
        {
          "chainId": 1,
          "address": "0xde4ce5447ce0c67920a1371605a39187cb6847c8",
          "name": "Deesse",
          "symbol": "LOVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22524/thumb/GaqpKHEP_400x400.jpg?1641975550"
        },
        {
          "chainId": 1,
          "address": "0x99a01a4d6a4d621094983050d9a2f10b2912e53d",
          "name": "VirtuSwap",
          "symbol": "VRSW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30629/thumb/VirtuSwap_Logo_Red_200x200.png?1685809373"
        },
        {
          "chainId": 1,
          "address": "0x7e9e431a0b8c4d532c745b1043c7fa29a48d4fba",
          "name": "eosDAC",
          "symbol": "EOSDAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3878/thumb/2644.png?1547038931"
        },
        {
          "chainId": 1,
          "address": "0x5d843fa9495d23de997c394296ac7b4d721e841c",
          "name": "Relay Chain",
          "symbol": "RELAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png?1629339288"
        },
        {
          "chainId": 1,
          "address": "0xb04bf60e468743418e87291d7c9301a5299d984d",
          "name": "FOREVER SHIBA",
          "symbol": "4SHIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29110/thumb/4shiba_logo.png?1676691582"
        },
        {
          "chainId": 1,
          "address": "0xf203ca1769ca8e9e8fe1da9d147db68b6c919817",
          "name": "Wrapped NCG",
          "symbol": "WNCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17747/thumb/WNCG_Color.png?1676455921"
        },
        {
          "chainId": 1,
          "address": "0x6ab4a7d75b0a42b6bc83e852dab9e121f9c610aa",
          "name": "Elitium",
          "symbol": "EUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7874/thumb/elitium-coin.png?1551864695"
        },
        {
          "chainId": 1,
          "address": "0xb31ef9e52d94d4120eb44fe1ddfde5b4654a6515",
          "name": "DOSE",
          "symbol": "DOSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG?1633590548"
        },
        {
          "chainId": 1,
          "address": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
          "name": "Civic",
          "symbol": "CVC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/788/thumb/civic-orange.png?1657246016"
        },
        {
          "chainId": 1,
          "address": "0x77d9046ee15faaceb89439ffdde4be071c2f07bd",
          "name": "Jiyuu",
          "symbol": "JIYUU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28907/thumb/logo_200.png?1675307283"
        },
        {
          "chainId": 1,
          "address": "0x4e4a47cac6a28a62dcc20990ed2cda9bc659469f",
          "name": "I will poop it NFT",
          "symbol": "SHIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25945/thumb/PCNoOl8.png?1654833800"
        },
        {
          "chainId": 1,
          "address": "0x068e3563b1c19590f822c0e13445c4fa1b9eefa5",
          "name": "Wrapped USD",
          "symbol": "WUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28854/thumb/wusd.png?1675567058"
        },
        {
          "chainId": 1,
          "address": "0x34f0915a5f15a66eba86f6a58be1a471fb7836a7",
          "name": "PulseDogecoin",
          "symbol": "PLSD",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/25327/thumb/Final_Doge.png?1674461869"
        },
        {
          "chainId": 1,
          "address": "0xf5238462e7235c7b62811567e63dd17d12c2eaa0",
          "name": "CACHE Gold",
          "symbol": "CGT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11601/thumb/cache-gold-icon-200x200.png?1591755874"
        },
        {
          "chainId": 1,
          "address": "0x07f9702ce093db82dfdc92c2c6e578d6ea8d5e22",
          "name": "Oobit",
          "symbol": "OBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19855/thumb/obt.png?1636062272"
        },
        {
          "chainId": 1,
          "address": "0x9f4909cc95fb870bf48c128c1fdbb5f482797632",
          "name": "Guzzler",
          "symbol": "GZLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20970/thumb/Screenshot-2021-11-27-at-22-57-22.png?1638153729"
        },
        {
          "chainId": 1,
          "address": "0x990e081a7b7d3ccba26a2f49746a68cc4ff73280",
          "name": "KStarCoin",
          "symbol": "KSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1630/thumb/ksc.png?1547035850"
        },
        {
          "chainId": 1,
          "address": "0x44f262622248027f8e2a8fb1090c4cf85072392c",
          "name": "Planet Inverse",
          "symbol": "XIV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271"
        },
        {
          "chainId": 1,
          "address": "0x4309e88d1d511f3764ee0f154cee98d783b61f09",
          "name": "Onchain AI",
          "symbol": "OCAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31191/thumb/onchain_200.jpeg?1691145559"
        },
        {
          "chainId": 1,
          "address": "0x506b8f75bdef0edac36b0a6f9cf313485e4341b0",
          "name": "WCAPES",
          "symbol": "WCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29361/thumb/wca.png?1678264484"
        },
        {
          "chainId": 1,
          "address": "0xaf8942831f3a096f708b8b31f191b8958cf176c5",
          "name": "Neural Radiance Field",
          "symbol": "NERF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29521/thumb/Neural_Radiance_Field.png?1679464106"
        },
        {
          "chainId": 1,
          "address": "0x9c5476af06590a9277c7706fe70c0a42a480f8a0",
          "name": "Chooky Inu",
          "symbol": "CHOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28525/thumb/New-Inu.jpeg?1671350928"
        },
        {
          "chainId": 1,
          "address": "0xfb559ce67ff522ec0b9ba7f5dc9dc7ef6c139803",
          "name": "Probit",
          "symbol": "PROB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6765/thumb/ProBit-Exchange-logo.png?1547043029"
        },
        {
          "chainId": 1,
          "address": "0xaf91e8afbe87642dc628786188a54b78580a4d76",
          "name": "Fund Of Yours",
          "symbol": "FOY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16648/thumb/foy200.png?1624591862"
        },
        {
          "chainId": 1,
          "address": "0x5b5065a95842ca8d51274ba9adf57861d60e8e19",
          "name": "Evermars",
          "symbol": "EMARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30856/thumb/RrS02WTo_400x400.jpg?1688365897"
        },
        {
          "chainId": 1,
          "address": "0x5d07f52ae5f779422447c2a32f58c93a15e7d6f2",
          "name": "Kylin Network",
          "symbol": "KYL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14164/thumb/kyl_logo.jpg?1647507720"
        },
        {
          "chainId": 1,
          "address": "0x77d0cb0ab54f9e74b9405a5b3f60da06a78f1aad",
          "name": "Wrapped Millix",
          "symbol": "WMLX",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/28703/thumb/wrapped_millix-200x200_300-dpi_GC.png?1673425888"
        },
        {
          "chainId": 1,
          "address": "0x9b83f827928abdf18cf1f7e67053572b9bceff3a",
          "name": "Artem",
          "symbol": "ARTEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21998/thumb/ARTM_Logo_MAIN_%281%29.png?1644469331"
        },
        {
          "chainId": 1,
          "address": "0x36f8d0d0573ae92326827c4a82fe4ce4c244cab6",
          "name": "Morpho Aave Dai Stablecoin",
          "symbol": "MADAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29836/thumb/maDAI.jpg?1681718528"
        },
        {
          "chainId": 1,
          "address": "0xf83301c5cd1ccbb86f466a6b3c53316ed2f8465a",
          "name": "COMSA",
          "symbol": "CMS",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/2500/thumb/comsa-_xem_.png?1547036614"
        },
        {
          "chainId": 1,
          "address": "0x3f5dd1a1538a4f9f82e543098f01f22480b0a3a8",
          "name": "KumaDex Token",
          "symbol": "DKUMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26693/thumb/dkumaOG02_CoinGecko.png?1660618296"
        },
        {
          "chainId": 1,
          "address": "0x2bd0fb740e403b505a3146f9ac02df883fd5c3fc",
          "name": "K9",
          "symbol": "K9",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29434/thumb/CoinGecko200pxFinal.png?1678784926"
        },
        {
          "chainId": 1,
          "address": "0x467719ad09025fcc6cf6f8311755809d45a5e5f3",
          "name": "Axelar",
          "symbol": "AXL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730"
        },
        {
          "chainId": 1,
          "address": "0x1c2641f9574d9288f4ca8d24c4894675bb17cb01",
          "name": "RichAI",
          "symbol": "RICHAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30272/thumb/Group_1000002759.png?1683780263"
        },
        {
          "chainId": 1,
          "address": "0x9303eabc860a743aabcc3a1629014cabcc3f8d36",
          "name": "Aave AMM UniDAIWETH",
          "symbol": "AAMMUNIDAIWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17230/thumb/aAmmUniDAIWETH.png?1626920804"
        },
        {
          "chainId": 1,
          "address": "0xe5ca70281149be03da30778fb5ec6183d339f7a5",
          "name": "Genius Playboy Billionaire Philanthropi",
          "symbol": "GPBP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30827/thumb/photo_2023-06-20_16.17.43_copy.jpeg?1687765486"
        },
        {
          "chainId": 1,
          "address": "0xedadeb5faa413e6c8623461849dfd0b7c3790c32",
          "name": "Obortech",
          "symbol": "OBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515"
        },
        {
          "chainId": 1,
          "address": "0xa91464abd4625a23ab719e3f0fce84dadd54e546",
          "name": "Inoovi",
          "symbol": "IVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10372/thumb/Bvp7U25U_400x400.jpg?1578674638"
        },
        {
          "chainId": 1,
          "address": "0x9a96e767bfcce8e80370be00821ed5ba283d4a17",
          "name": "GOGO Finance",
          "symbol": "GOGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13857/thumb/gogofinance.png?1612404853"
        },
        {
          "chainId": 1,
          "address": "0xe78bff28d3c5195c752e734e95421bb4bd742f1d",
          "name": "9 Lives Network",
          "symbol": "NINEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29247/thumb/photo_2023-02-28_22.28.05.jpeg?1677641414"
        },
        {
          "chainId": 1,
          "address": "0x0557e0d15aec0b9026dd17aa874fdf7d182a2ceb",
          "name": "CFX Quantum",
          "symbol": "CFXQ",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15031/thumb/CFXQ_TOKEN_LOGO_200x200.png?1619500058"
        },
        {
          "chainId": 1,
          "address": "0xa64efd5fe826f62e310a951332b519e1e3871489",
          "name": "BetaCarbon",
          "symbol": "BCAU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31040/thumb/Safeimagekit-resized-img_%281%29.png?1689856435"
        },
        {
          "chainId": 1,
          "address": "0x89d3c0249307ae570a316030764d12f53bb191fd",
          "name": "Xiglute Coin",
          "symbol": "XGC",
          "decimals": 14,
          "logoURI": "https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016"
        },
        {
          "chainId": 1,
          "address": "0xb4bd4628e6efb0cb521d9ec35050c75840320374",
          "name": "frETH",
          "symbol": "FRETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28734/thumb/freth.png?1673764725"
        },
        {
          "chainId": 1,
          "address": "0xd714d91a169127e11d8fab3665d72e8b7ef9dbe2",
          "name": "BlackHole Protocol",
          "symbol": "BLACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15124/thumb/blackhole.PNG?1620094580"
        },
        {
          "chainId": 1,
          "address": "0xb668473944d2e25b6af6d46917eb0233dbac53ae",
          "name": "Neton",
          "symbol": "NTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25418/thumb/lqT0CJNj_400x400.jpg?1651735850"
        },
        {
          "chainId": 1,
          "address": "0x1f17d72cbe65df609315df5c4f5f729efbd00ade",
          "name": "GYOSHI",
          "symbol": "GYOSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30002/thumb/99245ad73184347ee7c93bfc4f8ca2c2.png?1682475238"
        },
        {
          "chainId": 1,
          "address": "0xf190dbd849e372ff824e631a1fdf199f38358bcf",
          "name": "Capybara Memecoin",
          "symbol": "BARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30399/thumb/Capy_logo.png?1684317915"
        },
        {
          "chainId": 1,
          "address": "0x351caa9045d65107b9d311d922d15887cfd634e4",
          "name": "Arowana",
          "symbol": "ARW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17114/thumb/Arowana_LOGO_-_1_.png?1682405978"
        },
        {
          "chainId": 1,
          "address": "0x77c6e4a580c0dce4e5c7a17d0bc077188a83a059",
          "name": "Swerve fi USD",
          "symbol": "SWUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12918/thumb/swerve.png?1603631063"
        },
        {
          "chainId": 1,
          "address": "0xbbbbbbb5aa847a2003fbc6b5c16df0bd1e725f61",
          "name": "B Protocol",
          "symbol": "BPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15110/thumb/66428641.jpg?1619749844"
        },
        {
          "chainId": 1,
          "address": "0x378e1be15be6d6d1f23cfe7090b6a77660dbf14d",
          "name": "FOXE",
          "symbol": "FOXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30227/thumb/foxe.jpeg?1683631607"
        },
        {
          "chainId": 1,
          "address": "0xc2456d2118299a2efdfe6522ff79aa48fc5d2b00",
          "name": "MaruTaro",
          "symbol": "MARU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29304/thumb/marutaro_logo.jpg?1677918758"
        },
        {
          "chainId": 1,
          "address": "0x8a0c816a52e71a1e9b6719580ebe754709c55198",
          "name": "zkSync Labs",
          "symbol": "ZKLAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30723/thumb/our_logo.png?1686632551"
        },
        {
          "chainId": 1,
          "address": "0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9",
          "name": "Tempus",
          "symbol": "TEMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20547/thumb/Tempus_CoinGecko_200x200.png?1657674634"
        },
        {
          "chainId": 1,
          "address": "0x2f4eb100552ef93840d5adc30560e5513dfffacb",
          "name": "Balancer Boosted Aave USDT",
          "symbol": "BB-A-USDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25826/thumb/0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c.png?1654063979"
        },
        {
          "chainId": 1,
          "address": "0x28cb7e841ee97947a86b06fa4090c8451f64c0be",
          "name": "YF Link",
          "symbol": "YFL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12081/thumb/YFLink.png?1596987945"
        },
        {
          "chainId": 1,
          "address": "0x00dd5ad8f5d9c5118bceeb526c55ea6b2c07e922",
          "name": "Venify",
          "symbol": "VFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29194/thumb/Venify.jpg?1677301273"
        },
        {
          "chainId": 1,
          "address": "0x418d75f65a02b3d53b2418fb8e1fe493759c7605",
          "name": "Binance Coin  Wormhole ",
          "symbol": "BNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22884/thumb/BNB_wh_small.png?1644224553"
        },
        {
          "chainId": 1,
          "address": "0x362bc847a3a9637d3af6624eec853618a43ed7d2",
          "name": "PARSIQ",
          "symbol": "PRQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280"
        },
        {
          "chainId": 1,
          "address": "0xe59a1fe295f940e5226dcf6411ef2bdf3089c254",
          "name": "Yamanote Sen",
          "symbol": "YMNT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29699/thumb/LOGO_ymnt.jpg?1680743871"
        },
        {
          "chainId": 1,
          "address": "0xf94b5c5651c888d928439ab6514b93944eee6f48",
          "name": "Yield App",
          "symbol": "YLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13365/thumb/Google_Play_Store_Icon.png?1657012535"
        },
        {
          "chainId": 1,
          "address": "0x757da0e5c253082b0f2bd5105119f71817fe0911",
          "name": "Very Special Dragon",
          "symbol": "VITO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28527/thumb/Vito200.png?1671432930"
        },
        {
          "chainId": 1,
          "address": "0xb8e2e2101ed11e9138803cd3e06e16dd19910647",
          "name": "ArdCoin",
          "symbol": "ARDX",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/9432/thumb/ARDC.png?1627466542"
        },
        {
          "chainId": 1,
          "address": "0x1122b6a0e00dce0563082b6e2953f3a943855c1f",
          "name": "CENNZnet",
          "symbol": "CENNZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3408/thumb/CENNZ_ticker.png?1650943548"
        },
        {
          "chainId": 1,
          "address": "0x400b1d8a7dd8c471026b2c8cbe1062b27d120538",
          "name": "Limestone Network",
          "symbol": "LIMEX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11907/thumb/nw1FE_f4_400x400.png?1596074376"
        },
        {
          "chainId": 1,
          "address": "0xc30fba978743a43e736fc32fbeed364b8a2039cd",
          "name": "Money Market Index",
          "symbol": "ICSMMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29752/thumb/MMI-token-logoStandard.png?1681117910"
        },
        {
          "chainId": 1,
          "address": "0xf680429328caaacabee69b7a9fdb21a71419c063",
          "name": "Butterfly Protocol",
          "symbol": "BFLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13882/thumb/ButterflyProtocolNoText_sm.png?1612492535"
        },
        {
          "chainId": 1,
          "address": "0x7e794ed35788b698ae60cefc98ee48015c4876da",
          "name": "Shintama",
          "symbol": "SHINTAMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23405/thumb/shintama.png?1644145782"
        },
        {
          "chainId": 1,
          "address": "0x5cf04716ba20127f1e2297addcf4b5035000c9eb",
          "name": "NKN",
          "symbol": "NKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212"
        },
        {
          "chainId": 1,
          "address": "0xe616adb3f729f6f3df19c876eda66472a308d397",
          "name": "ThePepe AI",
          "symbol": "PPAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29759/thumb/20230411_161618.jpg?1681228251"
        },
        {
          "chainId": 1,
          "address": "0x5f4c148d17effd165c2e2d46b46d2bd6e3ebdc3e",
          "name": "5KM RUN",
          "symbol": "RUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26072/thumb/logo-200.png?1655621986"
        },
        {
          "chainId": 1,
          "address": "0xf62ac0fcae17f9195280ced4de978313effe2daa",
          "name": "Nchart",
          "symbol": "CHART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31169/thumb/n_200.png?1691116061"
        },
        {
          "chainId": 1,
          "address": "0xa885b6d3c87535de688194ec3645199ee76d6772",
          "name": "Tokerr",
          "symbol": "TOKR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28337/thumb/Tokerr_Logo.png?1669793676"
        },
        {
          "chainId": 1,
          "address": "0x9e46a38f5daabe8683e10793b06749eef7d733d1",
          "name": "PolySwarm",
          "symbol": "NCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767"
        },
        {
          "chainId": 1,
          "address": "0x2047ab3072b52561596ce5e0131bdbb7c848538d",
          "name": "Bored Token",
          "symbol": "BORED",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28515/thumb/Bored.png?1671196463"
        },
        {
          "chainId": 1,
          "address": "0xfcf8eda095e37a41e002e266daad7efc1579bc0a",
          "name": "FLEX Coin",
          "symbol": "FLEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9108/thumb/coinflex_logo.png?1628750583"
        },
        {
          "chainId": 1,
          "address": "0x3c48ca59bf2699e51d4974d4b6d284ae52076e5e",
          "name": "Capital DAO Starter",
          "symbol": "CDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20332/thumb/cds.png?1661762062"
        },
        {
          "chainId": 1,
          "address": "0xfd957f21bd95e723645c07c48a2d8acb8ffb3794",
          "name": "Ethereum Meta",
          "symbol": "ETHM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409"
        },
        {
          "chainId": 1,
          "address": "0xa249de6948022783765fee4850d7b85e43118fcc",
          "name": "Jarvis ",
          "symbol": "JAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8774/thumb/3jmUWB3e_400x400.jpg?1561085148"
        },
        {
          "chainId": 1,
          "address": "0x33909c9ce97ce509dab3a038b3ec7ac3d1be3231",
          "name": "Terareum",
          "symbol": "TERA2",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/29647/thumb/terareum.png?1680487202"
        },
        {
          "chainId": 1,
          "address": "0x22b48e1f20043d1db5f2a11cbf1d520a4f20b198",
          "name": "Okuru",
          "symbol": "XOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25473/thumb/xot.png?1652005616"
        },
        {
          "chainId": 1,
          "address": "0x98968f0747e0a261532cacc0be296375f5c08398",
          "name": "MOONCAT Vault  NFTX ",
          "symbol": "MOONCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17055/thumb/Mooncats.png?1626149563"
        },
        {
          "chainId": 1,
          "address": "0x1c48f86ae57291f7686349f12601910bd8d470bb",
          "name": "USDK",
          "symbol": "USDK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8824/thumb/usdk.png?1563418517"
        },
        {
          "chainId": 1,
          "address": "0x7458fd786b2fe8cd801c0381f88b61c5071a006f",
          "name": "LOA Protocol",
          "symbol": "LOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11164/thumb/1a67ac81c1803ee172b7ce8805b5da3d-full.jpg?1589411408"
        },
        {
          "chainId": 1,
          "address": "0xbe9ab37a414c517b2be2bfa5945665bb07379054",
          "name": "TomTomCoin",
          "symbol": "TOMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22864/thumb/toms_logo_200.png?1642753016"
        },
        {
          "chainId": 1,
          "address": "0xb3e2cb7cccfe139f8ff84013823bf22da6b6390a",
          "name": "Deutsche Digital Assets",
          "symbol": "ICNQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7830/thumb/dda_logo_small.jpg?1674721436"
        },
        {
          "chainId": 1,
          "address": "0xf951e335afb289353dc249e82926178eac7ded78",
          "name": "Swell Ethereum",
          "symbol": "SWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30326/thumb/_lB7zEtS_400x400.jpg?1684128260"
        },
        {
          "chainId": 1,
          "address": "0xda816459f1ab5631232fe5e97a05bbbb94970c95",
          "name": "yvDAI",
          "symbol": "YVDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28743/thumb/yvdai.png?1673842143"
        },
        {
          "chainId": 1,
          "address": "0xf04af3f4e4929f7cd25a751e6149a3318373d4fe",
          "name": "Spring Token",
          "symbol": "SPRING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25364/thumb/spring.png?1651317225"
        },
        {
          "chainId": 1,
          "address": "0x76fca1adb104770b38581b64d55e67fa5a0f3966",
          "name": "ZkTsunami",
          "symbol": "ZKT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29253/thumb/Sa72qYMC_400x400.jpg?1677724095"
        },
        {
          "chainId": 1,
          "address": "0x0fe0ed7f146cb12e4b9759aff4fa8d34571802ca",
          "name": "Pool Partyyy",
          "symbol": "PARTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29832/thumb/dioiHjS2_400x400.jpg?1681716087"
        },
        {
          "chainId": 1,
          "address": "0x54c890411e97755ca69321f28bed31cafbb3c5a0",
          "name": "BitStubs",
          "symbol": "STUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29483/thumb/BitStubs-200x200.png?1679131831"
        },
        {
          "chainId": 1,
          "address": "0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84",
          "name": "Interest Compounding ETH Index",
          "symbol": "ICETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24483/thumb/icETH-token-logo.png?1647826356"
        },
        {
          "chainId": 1,
          "address": "0x41bbd051e366d8437cb02374fbb0521c847f494e",
          "name": "Narfex",
          "symbol": "NRFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30443/thumb/NRFX.jpg?1684483820"
        },
        {
          "chainId": 1,
          "address": "0x670f9d9a26d3d42030794ff035d35a67aa092ead",
          "name": "XBullion",
          "symbol": "GOLD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15658/thumb/WhatsApp_Image_2021-05-17_at_2.24.16_PM.jpeg?1621484245"
        },
        {
          "chainId": 1,
          "address": "0x3235b13708f178af6f110de7177ed5de10c1093d",
          "name": "Mongol NFT",
          "symbol": "MNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23718/thumb/swI93LWg_400x400.jpg?1645160446"
        },
        {
          "chainId": 1,
          "address": "0x799ebfabe77a6e34311eeee9825190b9ece32824",
          "name": "Braintrust",
          "symbol": "BTRST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18100/thumb/braintrust.PNG?1630475394"
        },
        {
          "chainId": 1,
          "address": "0xd068c7c941fbbd2300cb2f1841858c2643722dc7",
          "name": "DGNAPP AI",
          "symbol": "DEGAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29745/thumb/logo_200x200.png?1681108069"
        },
        {
          "chainId": 1,
          "address": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
          "name": "Binance USD",
          "symbol": "BUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766"
        },
        {
          "chainId": 1,
          "address": "0x08711d3b02c8758f2fb3ab4e80228418a7f8e39c",
          "name": "Edgeless",
          "symbol": "EDG",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/635/thumb/Edgeless-Token.jpg?1631795809"
        },
        {
          "chainId": 1,
          "address": "0x54012cdf4119de84218f7eb90eeb87e25ae6ebd7",
          "name": "Luffy",
          "symbol": "LUFFY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17736/thumb/LUFFY_LOGO.png?1668675291"
        },
        {
          "chainId": 1,
          "address": "0x1fc5ef0337aea85c5f9198853a6e3a579a7a6987",
          "name": "ReapChain",
          "symbol": "REAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13109/thumb/REAP.jpg?1605259422"
        },
        {
          "chainId": 1,
          "address": "0xf406f7a9046793267bc276908778b29563323996",
          "name": "APY vision",
          "symbol": "VISION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13288/thumb/apyvisionlogo200circle.png?1607059042"
        },
        {
          "chainId": 1,
          "address": "0xc9fe6e1c76210be83dc1b5b20ec7fd010b0b1d15",
          "name": "Fringe Finance",
          "symbol": "FRIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13222/thumb/frin.png?1642932787"
        },
        {
          "chainId": 1,
          "address": "0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5",
          "name": "EvidenZ",
          "symbol": "BCDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2014/thumb/evidenz-512.png?1594871754"
        },
        {
          "chainId": 1,
          "address": "0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
          "name": "Akropolis",
          "symbol": "AKRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3328/thumb/Akropolis.png?1547037929"
        },
        {
          "chainId": 1,
          "address": "0x187d1018e8ef879be4194d6ed7590987463ead85",
          "name": "FUZE",
          "symbol": "FUZE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8941/thumb/logo-fuze-fix-big.png?1563117524"
        },
        {
          "chainId": 1,
          "address": "0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7",
          "name": "TempleDAO",
          "symbol": "TEMPLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20040/thumb/LPK15ZOW_400x400.jpg?1636425070"
        },
        {
          "chainId": 1,
          "address": "0x00d8318e44780edeefcf3020a5448f636788883c",
          "name": "dAppstore",
          "symbol": "DAPPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15780/thumb/large-logo.png?1621841062"
        },
        {
          "chainId": 1,
          "address": "0x705ee96c1c160842c92c1aecfcffccc9c412e3d9",
          "name": "ClearPoll",
          "symbol": "POLL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1050/thumb/clearpoll.png?1547034985"
        },
        {
          "chainId": 1,
          "address": "0xd9fcd98c322942075a5c3860693e9f4f03aae07b",
          "name": "Euler",
          "symbol": "EUL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26149/thumb/YCvKDfl8_400x400.jpeg?1656041509"
        },
        {
          "chainId": 1,
          "address": "0x12fd19dac0fab61bed5e0f09091b470c452d4d61",
          "name": "Echoin",
          "symbol": "EC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9354/thumb/L32_KzNQ_400x400.jpg?1566513096"
        },
        {
          "chainId": 1,
          "address": "0xad497ee6a70accc3cbb5eb874e60d87593b86f2f",
          "name": "SCOOBY",
          "symbol": "SCOOBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30253/thumb/I2de-EST_400x400.jpg?1683704177"
        },
        {
          "chainId": 1,
          "address": "0x3e98253d316856df99c17686af1a6a0107956f0b",
          "name": "0xShield",
          "symbol": "SHIELD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30351/thumb/favicon.png?1684218615"
        },
        {
          "chainId": 1,
          "address": "0x469084939d1c20fae3c73704fe963941c51be863",
          "name": "Envision",
          "symbol": "VIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23907/thumb/J3JCKVq2.png?1645681725"
        },
        {
          "chainId": 1,
          "address": "0x9ce115f0341ae5dabc8b477b74e83db2018a6f42",
          "name": "HairDAO",
          "symbol": "HAIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29620/thumb/LinkedIn_Logo.jpg?1680061629"
        },
        {
          "chainId": 1,
          "address": "0xe3818504c1b32bf1557b16c238b2e01fd3149c17",
          "name": "Pillar",
          "symbol": "PLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png?1624906282"
        },
        {
          "chainId": 1,
          "address": "0x939a7a577d93ad29b64c1595b1284ce660a479b9",
          "name": "Jejudoge",
          "symbol": "JEJUDOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15983/thumb/jejudoge.png?1634782252"
        },
        {
          "chainId": 1,
          "address": "0x9f549ebfd4974cd4ed4a1550d40394b44a7382aa",
          "name": "LinkCoin",
          "symbol": "LKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6098/thumb/vWhhedXQ.png?1547042094"
        },
        {
          "chainId": 1,
          "address": "0x62a8c2818bd7034dc24cd22368c3e53e8eb47c18",
          "name": "InnitForTheTECH",
          "symbol": "INNIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26724/thumb/INNITlogo.jpg?1659876626"
        },
        {
          "chainId": 1,
          "address": "0x6876eba317272fe221c67405c5e8eb3b24535547",
          "name": "MicroTuber",
          "symbol": "MCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638"
        },
        {
          "chainId": 1,
          "address": "0x6d52dfefb16bb9cdc78bfca09061e44574886626",
          "name": "CPUcoin",
          "symbol": "CPU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9544/thumb/uaz.ms.png?1624024119"
        },
        {
          "chainId": 1,
          "address": "0x62939f78a9a1cc4f9f1ea41cff95cd4f6b912d1b",
          "name": "Numisme2",
          "symbol": "NUME2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31009/thumb/NUME2.jpg?1689686572"
        },
        {
          "chainId": 1,
          "address": "0x2e95cea14dd384429eb3c4331b776c4cfbb6fcd9",
          "name": "Throne",
          "symbol": "THN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16965/thumb/aXhD3i_g_400x400.jpg?1625804145"
        },
        {
          "chainId": 1,
          "address": "0x6fce4a401b6b80ace52baaefe4421bd188e76f6f",
          "name": "Aave MANA v1",
          "symbol": "AMANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11730/thumb/aMANA.png?1593084474"
        },
        {
          "chainId": 1,
          "address": "0xbc8e35221904f61b4200ca44a08e4dac387ac83a",
          "name": "Fair BERC20",
          "symbol": "BERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30860/thumb/BERClogo3.png_alt_media_token_3c04fa07-9756-4037-8950-e30295030131.png?1688452579"
        },
        {
          "chainId": 1,
          "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
          "name": "Glo Dollar",
          "symbol": "USDGLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29319/thumb/glo_logo_coingecko.png?1678068662"
        },
        {
          "chainId": 1,
          "address": "0xc4c75f2a0cb1a9acc33929512dc9733ea1fd6fde",
          "name": "Martin Shkreli Inu",
          "symbol": "MSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26365/thumb/jEYEUxUI_400x400.jpeg?1657600080"
        },
        {
          "chainId": 1,
          "address": "0xd64809f5f7d772d9112a6bd379de00a77188199e",
          "name": "Lyfe Silver",
          "symbol": "LSILVER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14939/thumb/LSILVER-Logo-200.png?1619082811"
        },
        {
          "chainId": 1,
          "address": "0x97e3c21f27182498382f81e32fbe0ea3a0e3d79b",
          "name": "Flipped Pepe",
          "symbol": "EPEP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30991/thumb/photo_2023-07-13_14.25.20.jpeg?1689567873"
        },
        {
          "chainId": 1,
          "address": "0x34950ff2b487d9e5282c5ab342d08a2f712eb79f",
          "name": "Efforce",
          "symbol": "WOZX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13310/thumb/rZ6Oe3dm_400x400.jpg?1607331889"
        },
        {
          "chainId": 1,
          "address": "0x3ed3b47dd13ec9a98b44e6204a523e766b225811",
          "name": "Aave USDT",
          "symbol": "AUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400"
        },
        {
          "chainId": 1,
          "address": "0x822f1a51e534723ec1305e6eb5c96aecfeb4c629",
          "name": "Metaus",
          "symbol": "MTU",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x37f74e99794853777a10ea1dc08a64c86958f06a",
          "name": "D Community",
          "symbol": "DILI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9485/thumb/BYf_d6kekNWOY34VLGPvf7RcVj8SeRi7P65Nk0W7ji1jir_qSLfssffnNW0ZzQSWzhhsNRkMCL7qcX4CCaMTbgKb0D7b5yNeStKVMm2deZd9cONMuItgoKUXxIYkHElfk1PW2uiNZbg6a2K0OHtZTvkt1xo8YVKuthskXaryOkM6Ax-MXQlHOfbjwCcnQQjHxAucufrCZJfDgrY.jpg?1571685571"
        },
        {
          "chainId": 1,
          "address": "0x7616113782aadab041d7b10d474f8a0c04eff258",
          "name": "Zeeverse",
          "symbol": "VEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29164/thumb/vee.png?1677046824"
        },
        {
          "chainId": 1,
          "address": "0xe9966c1184f8552fcb16f65addba9dd08fe8f4ea",
          "name": "MY Ceremonial Event",
          "symbol": "MYCE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19182/thumb/BuoVrgpA_400x400.png?1634613857"
        },
        {
          "chainId": 1,
          "address": "0x20cd2e7ec8f5d8b337fe46a4f565ccef1561b9a9",
          "name": "ESG",
          "symbol": "ESG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24932/thumb/esg.png?1649397514"
        },
        {
          "chainId": 1,
          "address": "0x4e12eb8e506ccd1427f6b8f7faa3e88fb698eb28",
          "name": "Jack Token",
          "symbol": "JACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10991/thumb/Jacktoken200x200.png?1586938972"
        },
        {
          "chainId": 1,
          "address": "0x41dbecc1cdc5517c6f76f6a6e836adbee2754de3",
          "name": "Medicalchain",
          "symbol": "MTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2850/thumb/medicalchain.png?1547037019"
        },
        {
          "chainId": 1,
          "address": "0x67b66c99d3eb37fa76aa3ed1ff33e8e39f0b9c7a",
          "name": "Interest Bearing ETH",
          "symbol": "IBETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13131/thumb/7675.png?1605535879"
        },
        {
          "chainId": 1,
          "address": "0xd049206fb408a611e543791f2d8f102a8bc253dc",
          "name": "NFTDAO",
          "symbol": "NAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22576/thumb/NAO.png?1642083280"
        },
        {
          "chainId": 1,
          "address": "0x2ed2cc2c858a8a8219fd2f2d9e170285dbd02756",
          "name": "Sports Bet",
          "symbol": "SBET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25312/thumb/sbet_logo_256.png?1651214220"
        },
        {
          "chainId": 1,
          "address": "0x3dbb00c9be5a327e25caf4f650844c5dba81e34b",
          "name": "StaFi Staked MATIC",
          "symbol": "RMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29610/thumb/rMATIC.png?1680074998"
        },
        {
          "chainId": 1,
          "address": "0xad6250f0bd49f7a1eb11063af2ce9f25b9597b0f",
          "name": "OpenEden TBILL",
          "symbol": "TBILL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/30576/thumb/OE_Logo_200x200_Transparent.png?1685332431"
        },
        {
          "chainId": 1,
          "address": "0x70edf1c215d0ce69e7f16fd4e6276ba0d99d4de7",
          "name": "CHEQD Network",
          "symbol": "CHEQ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20855/thumb/79901197.png?1637791407"
        },
        {
          "chainId": 1,
          "address": "0xb893a8049f250b57efa8c62d51527a22404d7c9a",
          "name": "American Shiba",
          "symbol": "USHIBA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610"
        },
        {
          "chainId": 1,
          "address": "0xf411903cbc70a74d22900a5de66a2dda66507255",
          "name": "Verasity",
          "symbol": "VRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14025/thumb/VRA.jpg?1613797653"
        },
        {
          "chainId": 1,
          "address": "0xba21ef4c9f433ede00badefcc2754b8e74bd538a",
          "name": "Swapfolio",
          "symbol": "SWFL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12263/thumb/swapfolio-token-logo-icon-symbol-256-256.png?1598593097"
        },
        {
          "chainId": 1,
          "address": "0x06576eb3b212d605b797dc15523d9dc9f4f66db4",
          "name": "The Crypto Prophecies",
          "symbol": "TCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15054/thumb/tcp.PNG?1619579195"
        },
        {
          "chainId": 1,
          "address": "0x92868a5255c628da08f550a858a802f5351c5223",
          "name": "Cross Chain Bridge",
          "symbol": "BRIDGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446"
        },
        {
          "chainId": 1,
          "address": "0x967da4048cd07ab37855c090aaf366e4ce1b9f48",
          "name": "Ocean Protocol",
          "symbol": "OCEAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686"
        },
        {
          "chainId": 1,
          "address": "0xb04dfdb8271ed2d5e13858562c44a77d3ceb9e57",
          "name": "BuildUp",
          "symbol": "BUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13206/thumb/BUP_-_LOGO.png?1606199462"
        },
        {
          "chainId": 1,
          "address": "0xe5a3229ccb22b6484594973a03a3851dcd948756",
          "name": "Receive Access Ecosystem",
          "symbol": "RAE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9428/thumb/Copy_of_raetoken.png?1567290545"
        },
        {
          "chainId": 1,
          "address": "0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6",
          "name": "bAlpha",
          "symbol": "BALPHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14224/thumb/logo_bAlpha_200.png?1615089190"
        },
        {
          "chainId": 1,
          "address": "0xb4371da53140417cbb3362055374b10d97e420bb",
          "name": "Carbon Protocol",
          "symbol": "SWTH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/3645/thumb/SWTH_Symbol_Origin.png?1645000262"
        },
        {
          "chainId": 1,
          "address": "0xfe39c384d702914127a005523f9915addb9bd59b",
          "name": "Hippocrat",
          "symbol": "HPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4243/thumb/Token.png?1686826831"
        },
        {
          "chainId": 1,
          "address": "0x35f67c1d929e106fdff8d1a55226afe15c34dbe2",
          "name": "Beta",
          "symbol": "BETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25539/thumb/Beta-47.png?1652265414"
        },
        {
          "chainId": 1,
          "address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
          "name": "The Sandbox",
          "symbol": "SAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942"
        },
        {
          "chainId": 1,
          "address": "0x71a28feaee902966dc8d355e7b8aa427d421e7e0",
          "name": "LunchDAO",
          "symbol": "LUNCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25808/thumb/200x200.png?1653979480"
        },
        {
          "chainId": 1,
          "address": "0xe0c05ec44775e4ad62cdc2eecdf337aa7a143363",
          "name": "Mancium",
          "symbol": "MANC",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/25084/thumb/IKpuMYuGaMHEE0nrDUwpf6DqLQ5zTfZ1sak6ZqeIe2snV4GqQbh4Eic6hHcPl_OpBmlhcrRJ5kqoGzwB1CezMiDYnwR6rDT08_l8zb3-y3aPhTv7_0x5g4IO9JpQXDViEIqTZ7gYzTXqkcP6MH_uCFxQF-l-ohR0-FdT8O-ZTmNUKm--OuqEf9DVa-miAFp4t2pN-kcJ5Jv8woPj51yk-zna8c.jpg?1650265130"
        },
        {
          "chainId": 1,
          "address": "0xc5b3d3231001a776123194cf1290068e8b0c783b",
          "name": "LIT",
          "symbol": "LIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21344/thumb/LitLogo_CG.png?1639990605"
        },
        {
          "chainId": 1,
          "address": "0xa5b947687163fe88c3e6af5b17ae69896f4abccf",
          "name": "Poseidon",
          "symbol": "PSDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25316/thumb/psdn_32.png?1651216148"
        },
        {
          "chainId": 1,
          "address": "0x955d5c14c8d4944da1ea7836bd44d54a8ec35ba1",
          "name": "Refund",
          "symbol": "RFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30501/thumb/rfdlogo.jpg?1684735150"
        },
        {
          "chainId": 1,
          "address": "0xed279fdd11ca84beef15af5d39bb4d4bee23f0ca",
          "name": "LUSD3CRV f",
          "symbol": "LUSD3CRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28321/thumb/curve-dao-token-crv-logo.png?1669372858"
        },
        {
          "chainId": 1,
          "address": "0xf915110898d9a455ad2da51bf49520b41655ccea",
          "name": "TAI",
          "symbol": "TAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30574/thumb/tai.png?1685323205"
        },
        {
          "chainId": 1,
          "address": "0x4be10da47a07716af28ad199fbe020501bddd7af",
          "name": "XT com",
          "symbol": "XT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8391/thumb/XT_token.jpg?1557979399"
        },
        {
          "chainId": 1,
          "address": "0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd",
          "name": "Hakka Finance",
          "symbol": "HAKKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12163/thumb/Hakka-icon.png?1597746776"
        },
        {
          "chainId": 1,
          "address": "0x9ea3b5b4ec044b70375236a281986106457b20ef",
          "name": "Delta Financial",
          "symbol": "DELTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14564/thumb/Delta_logo.png?1619067341"
        },
        {
          "chainId": 1,
          "address": "0x9783b81438c24848f85848f8df31845097341771",
          "name": "Dog Collar",
          "symbol": "COLLAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18324/thumb/dcLogo.png?1631532599"
        },
        {
          "chainId": 1,
          "address": "0x71fc1f555a39e0b698653ab0b475488ec3c34d57",
          "name": "Rainmaker Games",
          "symbol": "RAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png?1639362827"
        },
        {
          "chainId": 1,
          "address": "0x6b9f031d718dded0d681c20cb754f97b3bb81b78",
          "name": "GEEQ",
          "symbol": "GEEQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11953/thumb/GeeqLogoPNGTransparent-1.png?1596421769"
        },
        {
          "chainId": 1,
          "address": "0x819c1a1568934ee59d9f3c8b9640908556c44140",
          "name": "Hobbes  OLD ",
          "symbol": "HOBBES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29541/thumb/Logo200x200.png?1680246455"
        },
        {
          "chainId": 1,
          "address": "0x46d473a0b3eeec9f55fade641bc576d5bc0b2246",
          "name": "SurfExUtilityToken",
          "symbol": "SURF",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10783/thumb/200x200-logo-blu-grey-bkg-4-e1583512409629.png?1583539501"
        },
        {
          "chainId": 1,
          "address": "0x52284158e02425290f6b627aeb5fff65edf058ad",
          "name": "FlappyMoonbird",
          "symbol": "FMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30659/thumb/200_%282%29.jpg?1686126910"
        },
        {
          "chainId": 1,
          "address": "0xb8c3b7a2a618c552c23b1e4701109a9e756bab67",
          "name": "1INCH yVault",
          "symbol": "YV1INCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28795/thumb/yv1INCH-128-0xB8C3B7A2A618C552C23B1E4701109a9E756Bab67.png?1674226063"
        },
        {
          "chainId": 1,
          "address": "0xd909c5862cdb164adb949d92622082f0092efc3d",
          "name": "Interest Protocol Token",
          "symbol": "IPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27000/thumb/logo.white_%281%29.png?1661328083"
        },
        {
          "chainId": 1,
          "address": "0x5ca381bbfb58f0092df149bd3d243b08b9a8386e",
          "name": "MXC",
          "symbol": "MXC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4604/thumb/mxc.png?1655534336"
        },
        {
          "chainId": 1,
          "address": "0xad5fe5b0b8ec8ff4565204990e4405b2da117d8e",
          "name": "TronClassic",
          "symbol": "TRXC",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/4626/thumb/trxc.png?1547039893"
        },
        {
          "chainId": 1,
          "address": "0x5b09a0371c1da44a8e24d36bf5deb1141a84d875",
          "name": "MADNetwork",
          "symbol": "MAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2031/thumb/Logo.png?1606984567"
        },
        {
          "chainId": 1,
          "address": "0x8eb5bd8c9ab0f8ad28e94693f3c889f490be2ab0",
          "name": "PoS 32",
          "symbol": "POS32",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27358/thumb/90532f66-9158-4bd4-8928-bcb8ec012579.png?1663663764"
        },
        {
          "chainId": 1,
          "address": "0x0aee8703d34dd9ae107386d3eff22ae75dd616d1",
          "name": "Tranche Finance",
          "symbol": "SLICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14119/thumb/slice.png?1635826973"
        },
        {
          "chainId": 1,
          "address": "0xe1bad922f84b198a08292fb600319300ae32471b",
          "name": "Firmachain",
          "symbol": "FCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9892/thumb/6mHcLurm_400x400.jpg?1573160342"
        },
        {
          "chainId": 1,
          "address": "0x2f4eb47a1b1f4488c71fc10e39a4aa56af33dd49",
          "name": "UNCL",
          "symbol": "UNCL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945"
        },
        {
          "chainId": 1,
          "address": "0x95b4ef2869ebd94beb4eee400a99824bf5dc325b",
          "name": "cMKR",
          "symbol": "CMKR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17528/thumb/cmkr.PNG?1628122490"
        },
        {
          "chainId": 1,
          "address": "0xb705268213d593b8fd88d3fdeff93aff5cbdcfae",
          "name": "IDEX",
          "symbol": "IDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2565/thumb/idexlogo.png?1684982937"
        },
        {
          "chainId": 1,
          "address": "0x5e6ffe7b174a50c81ff3f3c54c04fd3c11e20830",
          "name": "DragonVein",
          "symbol": "DVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8263/thumb/LrDC1OmYiqq05_-E3PFLO6oJou6HOvVMFpjEqbYdcvaIPgQp7mF52lpj_rCmQW1FhAoBg5E2Z9o16L3Z7n8SQrMIp1YZNEGHRzc3P0P3jOZnyKvHNuTXQx9q13_kxXG1oMzaN3HfWdUnTMAfKRwghCa7BX67W9pSV-AJkcQ4-5Qy_qlAN28UCUgVnM0k6ZXlNWZo7Vi00CD7657qw.jpg?1557125797"
        },
        {
          "chainId": 1,
          "address": "0xf1376bcef0f78459c0ed0ba5ddce976f1ddf51f4",
          "name": "Universal ETH",
          "symbol": "UNIETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28477/thumb/uniETH_200.png?1671004791"
        },
        {
          "chainId": 1,
          "address": "0xaa2d8c9a8bd0f7945143bfd509be3ff23dd78918",
          "name": "Artizen",
          "symbol": "ATNT",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/27263/thumb/ATNT-symbol_200.png?1663059371"
        },
        {
          "chainId": 1,
          "address": "0x98e0438d3ee1404fea48e38e92853bb08cfa68bd",
          "name": "TVT",
          "symbol": "TVT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8388/thumb/g8hXw4QX_400x400.jpg?1557973343"
        },
        {
          "chainId": 1,
          "address": "0x52a047ee205701895ee06a375492490ec9c597ce",
          "name": "PulseMarkets",
          "symbol": "PULSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19011/thumb/photo_2021-11-11_06-24-09.jpg?1636616734"
        },
        {
          "chainId": 1,
          "address": "0x91af0fbb28aba7e31403cb457106ce79397fd4e6",
          "name": "Aergo",
          "symbol": "AERGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4490/thumb/aergo.png?1647696770"
        },
        {
          "chainId": 1,
          "address": "0x82698aecc9e28e9bb27608bd52cf57f704bd1b83",
          "name": "Balancer Boosted Aave USDC",
          "symbol": "BB-A-USDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25780/thumb/bb-a-usdc.png?1653735108"
        },
        {
          "chainId": 1,
          "address": "0x55fb228730ed971269ebf284c7500d5ff572a141",
          "name": "Pepecola",
          "symbol": "PEPECOLA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30181/thumb/200.png?1683598862"
        },
        {
          "chainId": 1,
          "address": "0x4fee21439f2b95b72da2f9f901b3956f27fe91d5",
          "name": "FrogSwap",
          "symbol": "FROG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21449/thumb/logo.54d7099f.png?1639174332"
        },
        {
          "chainId": 1,
          "address": "0x92e187a03b6cd19cb6af293ba17f2745fd2357d5",
          "name": "Unit Protocol",
          "symbol": "DUCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13359/thumb/6f38719f-fe83-44ff-af30-7965fd23ac06.png?1660612730"
        },
        {
          "chainId": 1,
          "address": "0x6a6c2ada3ce053561c2fbc3ee211f23d9b8c520a",
          "name": "TON Community",
          "symbol": "TON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12334/thumb/ton.jpg?1599128436"
        },
        {
          "chainId": 1,
          "address": "0xdf35988d795d90711e785b488bb2127692e6f956",
          "name": "BabyFloki",
          "symbol": "BABYFLOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16712/thumb/floki.jpg?1624810144"
        },
        {
          "chainId": 1,
          "address": "0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098",
          "name": "Santiment Network",
          "symbol": "SAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/797/thumb/santiment-network-token.png?1547034571"
        },
        {
          "chainId": 1,
          "address": "0xc278041fdd8249fe4c1aad1193876857eea3d68c",
          "name": "IdleTUSD  Best Yield ",
          "symbol": "IDLETUSDYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11935/thumb/idletusdv3mMaxyield_32.png?1596263719"
        },
        {
          "chainId": 1,
          "address": "0x87869a9789291a6cec99f3c3ef2ff71fceb12a8e",
          "name": "CoinMerge OS",
          "symbol": "CMOS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29327/thumb/cmos.png?1678159718"
        },
        {
          "chainId": 1,
          "address": "0x5d5e244660ca05c42073c9a526616d99f2c99516",
          "name": "Game Tree",
          "symbol": "GTCOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24719/thumb/hUEmunGU_400x400.jpg?1648696738"
        },
        {
          "chainId": 1,
          "address": "0xa8262eb913fccea4c3f77fc95b8b4043b384cfbb",
          "name": "Krypton Galaxy Coin",
          "symbol": "KGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9152/thumb/krypton.PNG?1564733517"
        },
        {
          "chainId": 1,
          "address": "0x808507121b80c02388fad14726482e061b8da827",
          "name": "Pendle",
          "symbol": "PENDLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15069/thumb/Pendle_Logo_Normal-03.png?1634196276"
        },
        {
          "chainId": 1,
          "address": "0x5978708d6cce1cc9640eed47422d64c91bbd5171",
          "name": "LOLTOKEN",
          "symbol": "LOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8551/thumb/logo100.png?1596534732"
        },
        {
          "chainId": 1,
          "address": "0xfd30c9bea1a952feeed2ef2c6b2ff8a8fc4aad07",
          "name": "Kally",
          "symbol": "KALLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15812/thumb/polkally.PNG?1621979722"
        },
        {
          "chainId": 1,
          "address": "0x16756ec1deb89a2106c35e0b586a799d0a61837d",
          "name": "Chedda",
          "symbol": "CHEDDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22654/thumb/cMckil70_400x400.jpg?1642400459"
        },
        {
          "chainId": 1,
          "address": "0x1f31dd60b84ea2a273cfc56876437e069ae80afd",
          "name": "Shinji Inu",
          "symbol": "SHINJI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22846/thumb/Attachment_1645539736.png?1646300191"
        },
        {
          "chainId": 1,
          "address": "0xf8c76dbea329ec4fa987afc514f805b21b249d79",
          "name": "L",
          "symbol": "L",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30846/thumb/lltokenimagelogo.png?1687858253"
        },
        {
          "chainId": 1,
          "address": "0xba3e5f8b4200a5eb856ff2c3e001ab29444491aa",
          "name": "Minebase",
          "symbol": "MBASE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27313/thumb/mbase.png?1663312352"
        },
        {
          "chainId": 1,
          "address": "0x85f6eb2bd5a062f5f8560be93fb7147e16c81472",
          "name": "Franklin",
          "symbol": "FLY",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14810/thumb/fly_logo_sq_bArtboard_4.png?1626420796"
        },
        {
          "chainId": 1,
          "address": "0xb44377b74ef1773639b663d0754cb8410a847d02",
          "name": "Dream",
          "symbol": "DREAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24139/thumb/image0.jpeg?1659000537"
        },
        {
          "chainId": 1,
          "address": "0x350d3f0f41b5b21f0e252fe2645ae9d55562150a",
          "name": "BananaClubToken",
          "symbol": "BCT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26634/thumb/IMG_20220729_203534_044.jpg?1659327556"
        },
        {
          "chainId": 1,
          "address": "0x18a3563c21062897950bb09339c82b9686a35667",
          "name": "EtherNexus",
          "symbol": "ENXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29855/thumb/logo-circle.png?1681809971"
        },
        {
          "chainId": 1,
          "address": "0xb076bda1abc154ddb4ccd9be45542a823aee290e",
          "name": "FlexMeme",
          "symbol": "FLEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30018/thumb/flexmeme.png?1682517238"
        },
        {
          "chainId": 1,
          "address": "0x3c72fca8523686fd9e5740b0826fa4bb376e0241",
          "name": "300FIT",
          "symbol": "FIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9690/thumb/6mkyI4Ss_400x400.png?1570832640"
        },
        {
          "chainId": 1,
          "address": "0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83",
          "name": "Everest",
          "symbol": "ID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5209/thumb/Everest.jpg?1628042930"
        },
        {
          "chainId": 1,
          "address": "0xd559f20296ff4895da39b5bd9add54b442596a61",
          "name": "FintruX",
          "symbol": "FTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1928/thumb/token-500x500.png?1547036223"
        },
        {
          "chainId": 1,
          "address": "0x9aeb50f542050172359a0e1a25a9933bc8c01259",
          "name": "OIN Finance",
          "symbol": "OIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12339/thumb/OIN_FInance_-_cLogo-01.png?1631796334"
        },
        {
          "chainId": 1,
          "address": "0x3b5e2df103a037509dcc8430e5462b9cd8f75f49",
          "name": "GEMX",
          "symbol": "GEMX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28437/thumb/525BA403-4DD8-4AB4-A3C1-0EFF8C8F3450.jpeg?1670568823"
        },
        {
          "chainId": 1,
          "address": "0x628ab8b061fea2af1239b68efa5e46135d186666",
          "name": "XBullion Silver",
          "symbol": "SILV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24171/thumb/SILV_Logo_%28transparent_circle%29.png?1646816506"
        },
        {
          "chainId": 1,
          "address": "0x793786e2dd4cc492ed366a94b88a3ff9ba5e7546",
          "name": "Axia",
          "symbol": "AXIAV3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12906/thumb/axia_200x200.png?1613988058"
        },
        {
          "chainId": 1,
          "address": "0x3be65bd36c1ad28d33acc1c9dd8338a2706ca000",
          "name": "Kairos",
          "symbol": "KAIROS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30001/thumb/IMG_1074.png?1682473775"
        },
        {
          "chainId": 1,
          "address": "0x1abaea1f7c830bd89acc67ec4af516284b1bc33c",
          "name": "Euro Coin",
          "symbol": "EUROC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26045/thumb/euro-coin.png?1655394420"
        },
        {
          "chainId": 1,
          "address": "0x37f678ad6221a0fd71cda0eca19c8802f4cabf44",
          "name": "YUKKY",
          "symbol": "YUKKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30512/thumb/200GpSL05ul_400x400.jpg?1684832236"
        },
        {
          "chainId": 1,
          "address": "0x9d0b65a76274645b29e4cc41b8f23081fa09f4a3",
          "name": "iMe Lab",
          "symbol": "LIME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16243/thumb/lim_200.2.png?1623376205"
        },
        {
          "chainId": 1,
          "address": "0x2f4b36442687e943e587b45cccc92d60c3c3502f",
          "name": "BedlingtonTerrierToken",
          "symbol": "BETT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29955/thumb/logo-e1681720498919.png?1682383849"
        },
        {
          "chainId": 1,
          "address": "0x2b9a49417f9c9c8dd18ef5bb37c20637441ad67a",
          "name": "greg",
          "symbol": "GREG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30415/thumb/photo_2023-05-16_14-37-01.jpg?1684390913"
        },
        {
          "chainId": 1,
          "address": "0x4bb3205bf648b7f59ef90dee0f1b62f6116bc7ca",
          "name": "NBX",
          "symbol": "BYN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14746/thumb/NBX.png?1683023478"
        },
        {
          "chainId": 1,
          "address": "0x0107c4aaa31940ef88760acb1f32424ca8d8bd80",
          "name": "Authencity",
          "symbol": "AUTH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26960/thumb/1.png?1661062419"
        },
        {
          "chainId": 1,
          "address": "0x996229d0c6a485c7f4b52e092eaa907cb2def5c6",
          "name": "BuckHath Coin",
          "symbol": "BHIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7821/thumb/logstex.PNG?1585121237"
        },
        {
          "chainId": 1,
          "address": "0x60c24407d01782c2175d32fe7c8921ed732371d1",
          "name": "LemoChain",
          "symbol": "LEMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3108/thumb/lemochain.png?1547975597"
        },
        {
          "chainId": 1,
          "address": "0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d",
          "name": "Seigniorage Shares",
          "symbol": "SHARE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/12306/thumb/logo_%281%29.png?1607658707"
        },
        {
          "chainId": 1,
          "address": "0xbcbf09b4b83eb3893d58f0665d21460c04e39d8e",
          "name": "Sherlock Defi",
          "symbol": "SLOCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30020/thumb/20230425_212153_copy_200x200.jpg?1682564781"
        },
        {
          "chainId": 1,
          "address": "0x679badc551626e01b23ceecefbc9b877ea18fc46",
          "name": "Ccore",
          "symbol": "CCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1287/thumb/LOGO_CCO.png?1547035312"
        },
        {
          "chainId": 1,
          "address": "0xeb575c45004bd7b61c6a8d3446a62a05a6ce18d8",
          "name": "Ethlas",
          "symbol": "ELS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30331/thumb/ELS_Logo_200x200.png?1684137108"
        },
        {
          "chainId": 1,
          "address": "0xacf8d5e515ed005655dfefa09c22673a37a7cdee",
          "name": "FunFi",
          "symbol": "FNF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25872/thumb/FNF-200x200.png?1654241524"
        },
        {
          "chainId": 1,
          "address": "0x0e2ef8aecb3c01ad5d596f1b67134e178199984d",
          "name": "LandBox",
          "symbol": "LAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129"
        },
        {
          "chainId": 1,
          "address": "0x083d41d6dd21ee938f0c055ca4fb12268df0efac",
          "name": "GogolCoin",
          "symbol": "GOL",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14824/thumb/GOL.png?1618559300"
        },
        {
          "chainId": 1,
          "address": "0x76f187059039c13a93d43b6b6dc929f5f9806929",
          "name": "Woofie",
          "symbol": "WOOFIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30714/thumb/AD7CA813-D98D-4E1B-AE85-4895645BF1F5.png?1686555800"
        },
        {
          "chainId": 1,
          "address": "0xb83c27805aaca5c7082eb45c868d955cf04c337f",
          "name": "JungleKing TigerCoin",
          "symbol": "TIGER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26494/thumb/jungleparty.png?1658300842"
        },
        {
          "chainId": 1,
          "address": "0xb0280743b44bf7db4b6be482b2ba7b75e5da096c",
          "name": "Transcodium",
          "symbol": "TNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2071/thumb/transcodium.png?1548759093"
        },
        {
          "chainId": 1,
          "address": "0x69a95185ee2a045cdc4bcd1b1df10710395e4e23",
          "name": "Poolz Finance  OLD ",
          "symbol": "POOLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png?1610806091"
        },
        {
          "chainId": 1,
          "address": "0x2fda8c6783aa36bed645bad28a4cdc8769dcd252",
          "name": "Deuterium",
          "symbol": "D2O",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28732/thumb/LinkedIn_d2o_Icon_300x300px.png?1673690638"
        },
        {
          "chainId": 1,
          "address": "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
          "name": "Frax Share",
          "symbol": "FXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13423/thumb/Frax_Shares_icon.png?1679886947"
        },
        {
          "chainId": 1,
          "address": "0x0f8c45b896784a1e408526b9300519ef8660209c",
          "name": "XMax",
          "symbol": "XMX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4643/thumb/xmx.png?1574682262"
        },
        {
          "chainId": 1,
          "address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
          "name": "Convex Finance",
          "symbol": "CVX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328"
        },
        {
          "chainId": 1,
          "address": "0x40986a85b4cfcdb054a6cbfb1210194fee51af88",
          "name": "UniFarm",
          "symbol": "UFARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765"
        },
        {
          "chainId": 1,
          "address": "0xe1d7c7a4596b038ced2a84bf65b8647271c53208",
          "name": "NFTY",
          "symbol": "NFTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18741/thumb/coingecko.png?1683520850"
        },
        {
          "chainId": 1,
          "address": "0x03f813f7d066c55e3512985186df3b4e6a08e0d6",
          "name": "Shepherd Inu",
          "symbol": "SINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30366/thumb/SINU.jpg?1684273273"
        },
        {
          "chainId": 1,
          "address": "0xd528cf2e081f72908e086f8800977df826b5a483",
          "name": "Paribus",
          "symbol": "PBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18410/thumb/paribus.PNG?1631828570"
        },
        {
          "chainId": 1,
          "address": "0x36919a60a2b67b6d2329863093d180d23d5a0308",
          "name": "Kusunoki Samurai",
          "symbol": "KUSUNOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24688/thumb/68747470733a2f2f7777772e6b7573756e6f6b6973616d757261692e636f6d2f696d616765732f6b7573756e6f6b692d30312e706e67.png?1648604014"
        },
        {
          "chainId": 1,
          "address": "0x08f40811c7d6c013744166f3d4cb1a9a92d3d54e",
          "name": "NightVerse Game",
          "symbol": "NVG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29059/thumb/nvg.png?1676343175"
        },
        {
          "chainId": 1,
          "address": "0x6c5aa4244113e77414386630b7f963dbe1b4d73d",
          "name": "Dot Names",
          "symbol": "DNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29146/thumb/Dot_Names.png?1676965630"
        },
        {
          "chainId": 1,
          "address": "0x0ae055097c6d159879521c384f1d2123d1f195e6",
          "name": "STAKE",
          "symbol": "STAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11061/thumb/xdai.png?1587714165"
        },
        {
          "chainId": 1,
          "address": "0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6",
          "name": "sBTC",
          "symbol": "SBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8838/thumb/sBTC.png?1616149734"
        },
        {
          "chainId": 1,
          "address": "0xdfe691f37b6264a90ff507eb359c45d55037951c",
          "name": "Karma DAO",
          "symbol": "KARMA",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/11884/thumb/Karma.png?1597042574"
        },
        {
          "chainId": 1,
          "address": "0x213c53c96a01a89e6dcc5683cf16473203e17513",
          "name": "Defi Shopping Stake",
          "symbol": "DSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12602/thumb/DSS.png?1601002204"
        },
        {
          "chainId": 1,
          "address": "0x42b91f1d05afea671a2da3c780eda2abf0a2a366",
          "name": "Mineable",
          "symbol": "MNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29272/thumb/mnb.png?1677735665"
        },
        {
          "chainId": 1,
          "address": "0x9343e24716659a3551eb10aff9472a2dcad5db2d",
          "name": "STFX",
          "symbol": "STFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28631/thumb/stfx.png?1680832143"
        },
        {
          "chainId": 1,
          "address": "0x01792e1548dc317bde6123fe92da1fe6d7311c3c",
          "name": "Spiral",
          "symbol": "SPIRAL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26615/thumb/200logo.png?1664098207"
        },
        {
          "chainId": 1,
          "address": "0x809826cceab68c387726af962713b64cb5cb3cca",
          "name": "Nucleus Vision",
          "symbol": "NCASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3135/thumb/nv.jpg?1547037544"
        },
        {
          "chainId": 1,
          "address": "0xf6650117017ffd48b725b4ec5a00b414097108a7",
          "name": "Xido Finance",
          "symbol": "XIDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png?1623141959"
        },
        {
          "chainId": 1,
          "address": "0xffe510a92434a0df346c5e72a3494b043cf249eb",
          "name": "LUX BIO EXCHANGE COIN",
          "symbol": "LBXC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9482/thumb/sam7Fy3A_400x400.jpg?1568000741"
        },
        {
          "chainId": 1,
          "address": "0xbed4ab0019ff361d83ddeb74883dac8a70f5ea1e",
          "name": "MerchDAO",
          "symbol": "MRCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14540/thumb/logo_256x256.png?1616891356"
        },
        {
          "chainId": 1,
          "address": "0xf3b3cad094b89392fce5fafd40bc03b80f2bc624",
          "name": "Patron",
          "symbol": "PAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3169/thumb/patron.png?1548330790"
        },
        {
          "chainId": 1,
          "address": "0xf34842d05a1c888ca02769a633df37177415c2f8",
          "name": "IdleUSDT  Yield ",
          "symbol": "IDLEUSDTYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11933/thumb/idleusdtv3maxyield_32.png?1596263688"
        },
        {
          "chainId": 1,
          "address": "0x8770b7dd89e5f759ee3b226e0c45e890f87ddc48",
          "name": "Made In Real Life",
          "symbol": "MIRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28021/thumb/mirl.png?1667028324"
        },
        {
          "chainId": 1,
          "address": "0xea18dc833653172bbe819feb5320d9da9f99799a",
          "name": "Dogelon Mars 2 0",
          "symbol": "ELON20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30963/thumb/ABE04427-81C7-4AF2-9308-AE35C661E66E.jpeg?1689222339"
        },
        {
          "chainId": 1,
          "address": "0x4a621d9f1b19296d1c0f87637b3a8d4978e9bf82",
          "name": "CyberFM",
          "symbol": "CYFM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216"
        },
        {
          "chainId": 1,
          "address": "0x1c3d163219bb74f430411b95d66b72056f366ec1",
          "name": "ENO",
          "symbol": "ENO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26501/thumb/Eno_logo.png?1658364451"
        },
        {
          "chainId": 1,
          "address": "0xf81421fc15300c5a8cca9afe12f5cbad502fa756",
          "name": "Cardiocoin",
          "symbol": "CRDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25135/thumb/gQiyJrGcTRjEN-6bI22FOtHOmYF8-_MMYS_U1gIlUm1wf6QxiGnZ64tIeGNNFk9z5xPPch5FMie2grAN4fXQfcjKI9L0EQDVzDEdYDfqWl8ch4ree_q4pypGPNHeSCxnynfOOLAvd9XbKguJHEJNTLAdSKnWCKu92Kho49SplFvDBplt2ruvOz1v5QB795cnjZg4ZemfgfAB_ipJU1YE4TA3uL.jpg?1650432016"
        },
        {
          "chainId": 1,
          "address": "0xa891cf72aeda692570928efe1a832342e9783cdc",
          "name": "Interfinex Bills",
          "symbol": "IFEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13346/thumb/U6uYZry.png?1607662020"
        },
        {
          "chainId": 1,
          "address": "0xfeef77d3f69374f66429c91d732a244f074bdf74",
          "name": "Convex FXS",
          "symbol": "CVXFXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29001/thumb/cvxfxs.png?1675872793"
        },
        {
          "chainId": 1,
          "address": "0xee772cec929d8430b4fa7a01cd7fbd159a68aa83",
          "name": "Shanghai Inu",
          "symbol": "SHANG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29881/thumb/IMG_20230418_174523_393-removebg-preview.png?1681979222"
        },
        {
          "chainId": 1,
          "address": "0x33bc4e44054fdb21d5a7cea5c03613782d821e45",
          "name": "Xudo",
          "symbol": "XUDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30662/thumb/xudo.png?1686130764"
        },
        {
          "chainId": 1,
          "address": "0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
          "name": "Celsius Network",
          "symbol": "CEL",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/3263/thumb/CEL_logo.png?1609598753"
        },
        {
          "chainId": 1,
          "address": "0xa3a80559bbc1989654e6b8385f5001fa1333332f",
          "name": "RaiseR Token",
          "symbol": "RZR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30855/thumb/Token_Logo.png?1688360004"
        },
        {
          "chainId": 1,
          "address": "0x8387c2d1b0eea6b91b910aff66a7bd2710a5946a",
          "name": "Fomo Eth",
          "symbol": "FOMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30389/thumb/photo_2023-05-13_00-46-17.jpg?1684308001"
        },
        {
          "chainId": 1,
          "address": "0xc6bdb96e29c38dc43f014eed44de4106a6a8eb5f",
          "name": "Inu Inu",
          "symbol": "INUINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26391/thumb/logo_square_200.png?1657752596"
        },
        {
          "chainId": 1,
          "address": "0x68ffb43026cedc070bfc01b2203d90cab62ccf30",
          "name": "Turk Shiba",
          "symbol": "TUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29131/thumb/IMG_20230220_215553_755.jpg?1676957629"
        },
        {
          "chainId": 1,
          "address": "0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d",
          "name": "Voyager VGX",
          "symbol": "VGX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png?1575693595"
        },
        {
          "chainId": 1,
          "address": "0x35e78b3982e87ecfd5b3f3265b601c046cdbe232",
          "name": "SideShift",
          "symbol": "XAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15719/thumb/sideshift-icon_3x.png?1621595183"
        },
        {
          "chainId": 1,
          "address": "0xa0bed124a09ac2bd941b10349d8d224fe3c955eb",
          "name": "DePay",
          "symbol": "DEPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13183/thumb/DEPAY.png?1650615816"
        },
        {
          "chainId": 1,
          "address": "0xd52aae39a2b5cc7812f7b9450ebb61dfef702b15",
          "name": "MetaBrands",
          "symbol": "MAGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21244/thumb/MAGE-CG.png?1638766340"
        },
        {
          "chainId": 1,
          "address": "0x24bcec1afda63e622a97f17cff9a61ffcfd9b735",
          "name": "Crab Market",
          "symbol": "CRAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28356/thumb/200-icon.png?1669776793"
        },
        {
          "chainId": 1,
          "address": "0x7b7983967409fce461ea8bbdf9ed37631b1d59c9",
          "name": "KPOP Coin",
          "symbol": "KPOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15923/thumb/kpop.PNG?1622431964"
        },
        {
          "chainId": 1,
          "address": "0xbbeca80a4c99c3b1bd3c10e64595d0fc7dc26ee0",
          "name": "hiMEEBITS",
          "symbol": "HIMEEBITS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28126/thumb/himeebit.png?1667640906"
        },
        {
          "chainId": 1,
          "address": "0x562d8e607bed7fc183ca84c0362c853680b99638",
          "name": "Rewardz  Network",
          "symbol": "RAYN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30396/thumb/token_logo_etc-200px.png?1684317134"
        },
        {
          "chainId": 1,
          "address": "0xe22020f47b7378dfedcedd2c81d4137c22fe1152",
          "name": "My Liquidity Partner",
          "symbol": "MLP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26204/thumb/4xZ5Nb52_400x400.jpg?1656491493"
        },
        {
          "chainId": 1,
          "address": "0x8c6fa66c21ae3fc435790e451946a9ea82e6e523",
          "name": "MetaFabric",
          "symbol": "FABRIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png?1638758217"
        },
        {
          "chainId": 1,
          "address": "0xb48ee8f8fa3885fc08831ff366ec8df608159ee9",
          "name": "LiquidLock",
          "symbol": "LOCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29493/thumb/LOCK.png?1679285555"
        },
        {
          "chainId": 1,
          "address": "0x3472a5a71965499acd81997a54bba8d852c6e53d",
          "name": "Badger DAO",
          "symbol": "BADGER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976"
        },
        {
          "chainId": 1,
          "address": "0x196f4727526ea7fb1e17b2071b3d8eaa38486988",
          "name": "Reserve",
          "symbol": "RSV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10952/thumb/rsv.png?1637983297"
        },
        {
          "chainId": 1,
          "address": "0xceeb07dd26b36287b6d109f0b06d7e8202ce8c1d",
          "name": "Got Guaranteed",
          "symbol": "GOTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26107/thumb/%EA%B0%93%EC%A7%80%EB%A1%9C%EA%B3%A0.png?1655882278"
        },
        {
          "chainId": 1,
          "address": "0x58f7345b5295e43aa454911571f13be186655be9",
          "name": "Garlicoin",
          "symbol": "GRLC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2699/thumb/garlicoin.png?1548126827"
        },
        {
          "chainId": 1,
          "address": "0xb6ee9668771a79be7967ee29a63d4184f8097143",
          "name": "CargoX",
          "symbol": "CXO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2580/thumb/cargox.png?1547738832"
        },
        {
          "chainId": 1,
          "address": "0x5cb3ce6d081fb00d5f6677d196f2d70010ea3f4a",
          "name": "Busy",
          "symbol": "BUSY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14966/thumb/busy.PNG?1619165503"
        },
        {
          "chainId": 1,
          "address": "0xc3dca8f61b275d1e88f3ea31b3e311c49f56b24b",
          "name": "NPick Block",
          "symbol": "NPICK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25151/thumb/Logo200_%281%29.png?1650459923"
        },
        {
          "chainId": 1,
          "address": "0xc314b0e758d5ff74f63e307a86ebfe183c95767b",
          "name": "Adappter",
          "symbol": "ADP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14203/thumb/logo_on.png?1614909616"
        },
        {
          "chainId": 1,
          "address": "0xb2114e5420927932666a5c5bd1ac4e14d9ede32b",
          "name": "Pig 2 0",
          "symbol": "PIG20",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30942/thumb/200_%284%29.png?1689084770"
        },
        {
          "chainId": 1,
          "address": "0x72b886d09c117654ab7da13a14d603001de0b777",
          "name": "XDEFI",
          "symbol": "XDEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19524/thumb/XDEFI_logo_200x200.png?1689737785"
        },
        {
          "chainId": 1,
          "address": "0x6570ffe19da7e2b425329b157d9109b87f18304b",
          "name": "UNIUM",
          "symbol": "UNM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24567/thumb/Unium_logo.png?1648195706"
        },
        {
          "chainId": 1,
          "address": "0x0b498ff89709d3838a063f1dfa463091f9801c2b",
          "name": "BTC 2x Flexible Leverage Index",
          "symbol": "BTC2X-FLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15406/thumb/Copy_of_BTC2x-FLI_token_logo.png?1646749417"
        },
        {
          "chainId": 1,
          "address": "0xc3e486f614e297d8e016ac2805e81707c627b2d5",
          "name": "M",
          "symbol": "M",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28746/thumb/M.png?1673859520"
        },
        {
          "chainId": 1,
          "address": "0xf34b1db61aca1a371fe97bad2606c9f534fb9d7d",
          "name": "ArbiSmart",
          "symbol": "RBIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21906/thumb/imgonline-com-ua-Resize-cMjOYOwg12bLazt.png?1640235509"
        },
        {
          "chainId": 1,
          "address": "0xa848a1d33d8ef1633397a6acf617620fab8e5da8",
          "name": "MouseWorm",
          "symbol": "MOUSEWORM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28940/thumb/51E17AB5-6CB7-4975-AD03-6F7F86B72188.jpeg?1675504454"
        },
        {
          "chainId": 1,
          "address": "0x050d94685c6b0477e1fc555888af6e2bb8dfbda5",
          "name": "Inu ",
          "symbol": "INU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26259/thumb/inulogo_200x200.png?1656941363"
        },
        {
          "chainId": 1,
          "address": "0x9e976f211daea0d652912ab99b0dc21a7fd728e4",
          "name": "MAP Protocol",
          "symbol": "MAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10085/thumb/53819931.png?1649044945"
        },
        {
          "chainId": 1,
          "address": "0x7a41e0517a5eca4fdbc7fbeba4d4c47b9ff6dc63",
          "name": "Zeusshield",
          "symbol": "ZSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1009/thumb/zeusshield.png?1548761440"
        },
        {
          "chainId": 1,
          "address": "0xe857734840dc188b4283d5af14ab8685467ab87d",
          "name": "ESG Chain",
          "symbol": "ESGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22085/thumb/logo-01.png?1640761969"
        },
        {
          "chainId": 1,
          "address": "0xdbdb4d16eda451d0503b854cf79d55697f90c8df",
          "name": "Alchemix",
          "symbol": "ALCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png?1614409874"
        },
        {
          "chainId": 1,
          "address": "0x2ba8349123de45e931a8c8264c332e6e9cf593f9",
          "name": "Blockchain Monster Hunt",
          "symbol": "BCMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19045/thumb/bcmc-coin-200x200.png?1641398990"
        },
        {
          "chainId": 1,
          "address": "0x17837004ea685690b32dbead02a274ec4333a26a",
          "name": "Bear Inu",
          "symbol": "BEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29517/thumb/bear.png?1679394237"
        },
        {
          "chainId": 1,
          "address": "0xb5f1457d6fba1956fb8d31b0b7caca14bde0be4b",
          "name": "Stilton",
          "symbol": "STILT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26132/thumb/2vfQHbe9_400x400.png?1655969754"
        },
        {
          "chainId": 1,
          "address": "0x97b65710d03e12775189f0d113202cc1443b0aa2",
          "name": "AstroElon",
          "symbol": "ELONONE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16082/thumb/AstroElon.png?1622791921"
        },
        {
          "chainId": 1,
          "address": "0x524ebc93beef838f70b4ae54b675d3e971d5884e",
          "name": "Challenge Coin",
          "symbol": "HERO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26567/thumb/bug-CC-200.png?1658812509"
        },
        {
          "chainId": 1,
          "address": "0x52928c95c4c7e934e0efcfab08853a0e4558861d",
          "name": "Hara",
          "symbol": "HART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5138/thumb/hara.jpg?1547040533"
        },
        {
          "chainId": 1,
          "address": "0x58fcaa970339a9b1f8c0a5b4f3fcd7af2ba3075e",
          "name": "Polar Sync",
          "symbol": "POLAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18102/thumb/polar-sync-logo-200.jpeg?1668595860"
        },
        {
          "chainId": 1,
          "address": "0x00e679ba63b509182c349f5614f0a07cdd0ce0c5",
          "name": "Damex Token",
          "symbol": "DAMEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30053/thumb/damex.jpg?1682997644"
        },
        {
          "chainId": 1,
          "address": "0xab93df617f51e1e415b5b4f8111f122d6b48e55c",
          "name": "Delta Exchange",
          "symbol": "DETO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14610/thumb/deto-logo.png?1617247295"
        },
        {
          "chainId": 1,
          "address": "0xeef9f339514298c6a857efcfc1a762af84438dee",
          "name": "Hermez Network",
          "symbol": "HEZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12826/thumb/hermez_logo.png?1602826556"
        },
        {
          "chainId": 1,
          "address": "0x508626d9a29d13eba26f843a2bd7bf7b00a45be5",
          "name": "Bluelight",
          "symbol": "KALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28031/thumb/kale.png?1667109844"
        },
        {
          "chainId": 1,
          "address": "0xc07a150ecadf2cc352f5586396e344a6b17625eb",
          "name": "Bio Passport",
          "symbol": "BIOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14167/thumb/logo_%2895%29.png?1614753428"
        },
        {
          "chainId": 1,
          "address": "0x089729b0786c8803cff972c16e402f3344d079ea",
          "name": "BlockGPT",
          "symbol": "BGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30872/thumb/BLOCKGPT_ASSET_MEDIA_Plan_de_travail_1_copie_4.png?1691119594"
        },
        {
          "chainId": 1,
          "address": "0xa774ffb4af6b0a91331c084e1aebae6ad535e6f3",
          "name": "flexUSD",
          "symbol": "FLEXUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13323/thumb/flexUSD_2x.png?1607480702"
        },
        {
          "chainId": 1,
          "address": "0x9afb950948c2370975fb91a441f36fdc02737cd4",
          "name": "Huobi Fil",
          "symbol": "HFIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14109/thumb/HFIL.png?1614334127"
        },
        {
          "chainId": 1,
          "address": "0x32b86b99441480a7e5bd3a26c124ec2373e3f015",
          "name": "Bad Idea AI",
          "symbol": "BAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30871/thumb/Bad_idea.jpeg?1688373590"
        },
        {
          "chainId": 1,
          "address": "0xb46eda6219ba121ce9280388e7afb7dc84be3ff2",
          "name": "The Youth Pay",
          "symbol": "TYP",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/27412/thumb/8xJB5QnY_400x400.jpeg?1663855027"
        },
        {
          "chainId": 1,
          "address": "0x89509aa1d14a8e1e5364ec4c3b041213bcdbe08d",
          "name": "ZURRENCY",
          "symbol": "ZURR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22240/thumb/logo.png?1641274307"
        },
        {
          "chainId": 1,
          "address": "0x08f5a9235b08173b7569f83645d2c7fb55e8ccd8",
          "name": "Tierion",
          "symbol": "TNT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/923/thumb/tierion.png?1547034767"
        },
        {
          "chainId": 1,
          "address": "0xc229c69eb3bb51828d0caa3509a05a51083898dd",
          "name": "Pintu",
          "symbol": "PTU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20281/thumb/image_1_8dd79a68aa.png?1636733599"
        },
        {
          "chainId": 1,
          "address": "0xa0ed3c520dc0632657ad2eaaf19e26c4fd431a84",
          "name": "Hippo Wallet",
          "symbol": "HPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200"
        },
        {
          "chainId": 1,
          "address": "0x00000000bd56856065c00b1ad1b5cdefbf32ba22",
          "name": "Ham the Astrochimp",
          "symbol": "HAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30808/thumb/astromonkey_%28200%29.png?1687417399"
        },
        {
          "chainId": 1,
          "address": "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
          "name": "JPY Coin",
          "symbol": "JPYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25971/thumb/2023jpyc.png?1675935375"
        },
        {
          "chainId": 1,
          "address": "0xea5edef1287afdf9eb8a46f9773abfc10820c61c",
          "name": "EASE",
          "symbol": "EASE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27228/thumb/ease_symbol-200x200.png?1664098304"
        },
        {
          "chainId": 1,
          "address": "0xb435a47ecea7f5366b2520e45b9bed7e01d2ffae",
          "name": "The Nemesis",
          "symbol": "NEMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30590/thumb/nems.png?1685683989"
        },
        {
          "chainId": 1,
          "address": "0x34612903db071e888a4dadcaa416d3ee263a87b9",
          "name": "Items",
          "symbol": "ARTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11720/thumb/Arte.png?1607332372"
        },
        {
          "chainId": 1,
          "address": "0x0f5def84ced3e9e295dae28df96d0b846de92c1a",
          "name": "Crypto SDG",
          "symbol": "SDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29816/thumb/SDG_token_Logo_200.png?1681434978"
        },
        {
          "chainId": 1,
          "address": "0xae78736cd615f374d3085123a210448e74fc6393",
          "name": "Rocket Pool ETH",
          "symbol": "RETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20764/thumb/reth.png?1637652366"
        },
        {
          "chainId": 1,
          "address": "0xc0f1728d9513efc316d0e93a0758c992f88b0809",
          "name": "SWTCoin",
          "symbol": "SWAT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927"
        },
        {
          "chainId": 1,
          "address": "0x49d72e3973900a195a155a46441f0c08179fdb64",
          "name": "Cream ETH 2",
          "symbol": "CRETH2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13568/thumb/creth2.png?1609837099"
        },
        {
          "chainId": 1,
          "address": "0x5e3346444010135322268a4630d2ed5f8d09446c",
          "name": "LockTrip",
          "symbol": "LOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1357/thumb/LOC_Transparent_New.png?1579491094"
        },
        {
          "chainId": 1,
          "address": "0x5f32de250329b97aad84aae4cd3af548aff1ed37",
          "name": "BerylTrioChain",
          "symbol": "BRIBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30632/thumb/beryl.png?1685977850"
        },
        {
          "chainId": 1,
          "address": "0x89551b940e2a8ed8eccf509935bac9213fe30584",
          "name": "DoDreamChain",
          "symbol": "DRM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10469/thumb/57414069_286321078958466_8377709696580059136_n.jpg?1579733292"
        },
        {
          "chainId": 1,
          "address": "0xa350da05405cc204e551c4eed19c3039646528d5",
          "name": "Blocksport",
          "symbol": "BSPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23735/thumb/logo_icon.jpg?1690430556"
        },
        {
          "chainId": 1,
          "address": "0x69fa10cac03047a47ff32d7cddd6085ff1583d03",
          "name": "Hyper",
          "symbol": "HYPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30255/thumb/Hyper.png?1683705756"
        },
        {
          "chainId": 1,
          "address": "0x274e7eb07b485cfde53d02270555213447570ac6",
          "name": "SubDAO",
          "symbol": "GOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28379/thumb/gov.png?1669972199"
        },
        {
          "chainId": 1,
          "address": "0x3feb4fea5132695542f8ede5076ac43296d17c6d",
          "name": "Bitcoin 2 0",
          "symbol": "BTC20",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30984/thumb/btc2-200x200.png?1689435232"
        },
        {
          "chainId": 1,
          "address": "0xf8e386eda857484f5a12e4b5daa9984e06e73705",
          "name": "Indorse",
          "symbol": "IND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/848/thumb/indorse_token.png?1547034644"
        },
        {
          "chainId": 1,
          "address": "0xa5ca62d95d24a4a350983d5b8ac4eb8638887396",
          "name": "sUSD yVault",
          "symbol": "YVSUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28794/thumb/yvsUSD-128-0xa5cA62D95D24A4a350983D5B8ac4EB8638887396.png?1674709134"
        },
        {
          "chainId": 1,
          "address": "0xe8e06a5613dc86d459bc8fb989e173bb8b256072",
          "name": "Feyorra",
          "symbol": "FEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13689/thumb/1_XiKKk5_400x400.jpg?1610953208"
        },
        {
          "chainId": 1,
          "address": "0x69ed89ecd35082e031fe52b75123f801db083306",
          "name": "Kaeri",
          "symbol": "KAERI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27977/thumb/63370e2a1f2bea31fc7f6977_512x512_v2-p-500-2.png?1666846544"
        },
        {
          "chainId": 1,
          "address": "0xa0b93b9e90ab887e53f9fb8728c009746e989b53",
          "name": "The Standard Token",
          "symbol": "TST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20598/thumb/TheStandard-logo_variation-01.png?1668676004"
        },
        {
          "chainId": 1,
          "address": "0xb1f136a74e18e4e2921febbf25820d1bb65b5647",
          "name": "hiPunks",
          "symbol": "HIPUNKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26739/thumb/hipunks.png?1659946062"
        },
        {
          "chainId": 1,
          "address": "0xbd434a09191d401da3283a5545bb3515d033b8c4",
          "name": "GoldFinX",
          "symbol": "GIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13292/thumb/KAB_611E_400x400.jpg?1647558607"
        },
        {
          "chainId": 1,
          "address": "0x0202be363b8a4820f3f4de7faf5224ff05943ab1",
          "name": "UniLend Finance",
          "symbol": "UFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png?1602748658"
        },
        {
          "chainId": 1,
          "address": "0x1da4858ad385cc377165a298cc2ce3fce0c5fd31",
          "name": "CloutContracts",
          "symbol": "CCS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/19072/thumb/200.png?1634829111"
        },
        {
          "chainId": 1,
          "address": "0xa354f35829ae975e850e23e9615b11da1b3dc4de",
          "name": "USDC yVault",
          "symbol": "YVUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28779/thumb/yvUSDC-128-0xe2F6b9773BF3A015E2aA70741Bde1498bdB9425b.png?1674182395"
        },
        {
          "chainId": 1,
          "address": "0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6",
          "name": "Bankless BED Index",
          "symbol": "BED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17175/thumb/BED_Logo_-_No_border.png?1626833695"
        },
        {
          "chainId": 1,
          "address": "0x966e770030209c95f974f37edbde65d98e853354",
          "name": "Maxx",
          "symbol": "MAXX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30488/thumb/D9E6AF16-95F9-4550-87B5-E60C50DB1E02.png?1684714188"
        },
        {
          "chainId": 1,
          "address": "0xf6fd82dedbbe0ffadb5e1ecc2a283ab52b9ed2b0",
          "name": "Etheal",
          "symbol": "HEAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3267/thumb/heal.png?1547037807"
        },
        {
          "chainId": 1,
          "address": "0xa15865d9de09cb96aaa3a9081b3dfc8481f07d33",
          "name": "PopeCoin",
          "symbol": "POPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30239/thumb/photo_2023-05-01_11.25.54.jpeg?1683687811"
        },
        {
          "chainId": 1,
          "address": "0xa21af1050f7b26e0cff45ee51548254c41ed6b5c",
          "name": "Osaka Protocol",
          "symbol": "OSAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30911/thumb/osak_logo.png?1688693598"
        },
        {
          "chainId": 1,
          "address": "0xdf2c7238198ad8b389666574f2d8bc411a4b7428",
          "name": "Hifi Finance  OLD ",
          "symbol": "MFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3293/thumb/hft.png?1673534781"
        },
        {
          "chainId": 1,
          "address": "0x11ebe21e9d7bf541a18e1e3ac94939018ce88f0b",
          "name": "Pitch FXS",
          "symbol": "PITCHFXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28414/thumb/pitch.png?1670399849"
        },
        {
          "chainId": 1,
          "address": "0x97872eafd79940c7b24f7bcc1eadb1457347adc9",
          "name": "Strips Finance",
          "symbol": "STRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18327/thumb/Logo-Strips-200-x-200px---without-words.png?1631543556"
        },
        {
          "chainId": 1,
          "address": "0xbd7e92cf6f857be8541fca6abfb72aef8e16c307",
          "name": "Prodigy Bot",
          "symbol": "PRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31171/thumb/PRODIGY_LOGO_clean_%283%29.png?1691116873"
        },
        {
          "chainId": 1,
          "address": "0xc67b12049c2d0cf6e476bc64c7f82fc6c63cffc5",
          "name": "Globe Derivative Exchange",
          "symbol": "GDT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15335/thumb/gdt.PNG?1620597146"
        },
        {
          "chainId": 1,
          "address": "0x3f9bec82c776c47405bcb38070d2395fd18f89d3",
          "name": "Phantom Protocol",
          "symbol": "PHM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG?1631137732"
        },
        {
          "chainId": 1,
          "address": "0x321c2fe4446c7c963dc41dd58879af648838f98d",
          "name": "Cryptex Finance",
          "symbol": "CTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171"
        },
        {
          "chainId": 1,
          "address": "0xcbc1aa6961b28cccb11e1cddd84ad8b42ec920b1",
          "name": "ZEUS10000",
          "symbol": "ZEUS10000",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/22559/thumb/2022-01-11_22.10.11.jpg?1642056602"
        },
        {
          "chainId": 1,
          "address": "0xfa3118b34522580c35ae27f6cf52da1dbb756288",
          "name": "Linkeye",
          "symbol": "LET",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1814/thumb/linkeye.png?1547975964"
        },
        {
          "chainId": 1,
          "address": "0x85705ec9f2e11b8a70607d93ffaf9d73ec84cc34",
          "name": "Alien Chain",
          "symbol": "ALIEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31139/thumb/AF73688F-AA95-4623-B57C-94248BCB04B0.png?1690954348"
        },
        {
          "chainId": 1,
          "address": "0x36905fc93280f52362a1cbab151f25dc46742fb5",
          "name": "Bottos",
          "symbol": "BTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2048/thumb/bottos.png?1517478551"
        },
        {
          "chainId": 1,
          "address": "0x5fa54fddf1870c344dbfabb37dfab8700ec0def1",
          "name": "FrogeX",
          "symbol": "FROGEX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907"
        },
        {
          "chainId": 1,
          "address": "0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8",
          "name": "QuiverX",
          "symbol": "QRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12916/thumb/qrx_logo.png?1603550478"
        },
        {
          "chainId": 1,
          "address": "0x873fb544277fd7b977b196a826459a69e27ea4ea",
          "name": "RAI yVault",
          "symbol": "YVRAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28792/thumb/yvRAI-128-0x873fB544277FD7b977B196a826459a69E27eA4ea.png?1674225331"
        },
        {
          "chainId": 1,
          "address": "0x1bf7fd22709733ccd7c45ab27dd02c7ec8e50078",
          "name": "Quiztok",
          "symbol": "QTCON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8208/thumb/QTCON.png?1587543372"
        },
        {
          "chainId": 1,
          "address": "0x337dd23d05c27bff099d3604938bfc23a9b25820",
          "name": "Marshall Fighting Championship",
          "symbol": "MFC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29390/thumb/91C38363-77C4-4D85-A3B7-EFA1C5745DDF.png?1678426743"
        },
        {
          "chainId": 1,
          "address": "0x2d787d4f5005bd66ac910c2e821241625c406ed5",
          "name": "Berry",
          "symbol": "BERRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11016/thumb/Berry_logo.png?1593071384"
        },
        {
          "chainId": 1,
          "address": "0x66a64a8a9cdc54ef97e45a051ba1c8f3d4b41a1d",
          "name": "Baby Shiba Coin",
          "symbol": "BABYSHIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22707/thumb/9CmjzSIW_400x400.jpg?1642457763"
        },
        {
          "chainId": 1,
          "address": "0x7f3e4dbd84f5c8a5f15ef927557748cf2fbb77af",
          "name": "Broccoli",
          "symbol": "BRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30754/thumb/brologo.png?1686808792"
        },
        {
          "chainId": 1,
          "address": "0x5888641e3e6cbea6d84ba81edb217bd691d3be38",
          "name": "Bobo",
          "symbol": "BOBO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29875/thumb/BOBO_200.png?1681968700"
        },
        {
          "chainId": 1,
          "address": "0x944eee930933be5e23b690c8589021ec8619a301",
          "name": "Munch",
          "symbol": "MUNCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png?1618532828"
        },
        {
          "chainId": 1,
          "address": "0x4332f8a38f14bd3d8d1553af27d7c7ac6c27278d",
          "name": "Ape Finance",
          "symbol": "APEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25625/thumb/APEFI.png?1652857977"
        },
        {
          "chainId": 1,
          "address": "0xa9e8acf069c58aec8825542845fd754e41a9489a",
          "name": "PepeCoin",
          "symbol": "PEPECOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30219/thumb/pepecoin.jpeg?1683629013"
        },
        {
          "chainId": 1,
          "address": "0x95392f142af1c12f6e39897ff9b09c599666b50c",
          "name": "Impostors Blood",
          "symbol": "BLOOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25527/thumb/RV4nKK2RDO47XzfSLgGs_llgXivhdfpI3tso0.png?1652240523"
        },
        {
          "chainId": 1,
          "address": "0xd01409314acb3b245cea9500ece3f6fd4d70ea30",
          "name": "LTO Network",
          "symbol": "LTO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/6068/thumb/lto.png?1638855565"
        },
        {
          "chainId": 1,
          "address": "0x8baef8c9568c21b1a2b2fd048f8b4da835691fd0",
          "name": "USD ZEE",
          "symbol": "USDZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24876/thumb/003.png?1649217016"
        },
        {
          "chainId": 1,
          "address": "0x267eb2a9a13dc304a9deff4277abe850d0852c5f",
          "name": "Trace AI",
          "symbol": "TAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31058/thumb/200x200.png?1689926761"
        },
        {
          "chainId": 1,
          "address": "0x94804dc4948184ffd7355f62ccbb221c9765886f",
          "name": "Rage Fan",
          "symbol": "RAGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png?1627476357"
        },
        {
          "chainId": 1,
          "address": "0x0944d5848bd9f60a34ba92aea300d4286696eb76",
          "name": "Palette",
          "symbol": "PLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22153/thumb/262aoVTp_400x400.jpg?1640940192"
        },
        {
          "chainId": 1,
          "address": "0x738865301a9b7dd80dc3666dd48cf034ec42bdda",
          "name": "Agoras  Currency of Tau",
          "symbol": "AGRS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/399/thumb/agrs.png?1639622234"
        },
        {
          "chainId": 1,
          "address": "0xdfddf7a69716124bc346ba556d4b9f9e74c4a8bc",
          "name": "Succession",
          "symbol": "SCCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25377/thumb/sccn.png?1651410204"
        },
        {
          "chainId": 1,
          "address": "0xe910c2a090516fb7a7be07f96a464785f2d5dc18",
          "name": "CheckMate Token",
          "symbol": "CMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28382/thumb/cmt.png?1669974415"
        },
        {
          "chainId": 1,
          "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
          "name": "Axie Infinity",
          "symbol": "AXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082"
        },
        {
          "chainId": 1,
          "address": "0x8fc9b6354e839ab1c8b31f4afa53607092b8c2e5",
          "name": "ECOSC",
          "symbol": "ECU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10989/thumb/ecosc-logo.png?1586914826"
        },
        {
          "chainId": 1,
          "address": "0x73a9fb46e228628f8f9bb9004eca4f4f529d3998",
          "name": "Wrapped LEO",
          "symbol": "WLEO",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/12626/thumb/4XfO3w3.png?1601286769"
        },
        {
          "chainId": 1,
          "address": "0xf519381791c03dd7666c142d4e49fd94d3536011",
          "name": "Asia Coin",
          "symbol": "ASIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png?1632556422"
        },
        {
          "chainId": 1,
          "address": "0xe22910d04bdc9d55570f3bc52e786b49dddf37b4",
          "name": "Gatsby Inu  OLD ",
          "symbol": "GATSBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29585/thumb/gatsby_logo_large.png?1681375107"
        },
        {
          "chainId": 1,
          "address": "0x15874d65e649880c2614e7a480cb7c9a55787ff6",
          "name": "EthereumMax",
          "symbol": "EMAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15540/thumb/EMAX-Coin-Final2000x.png?1639402630"
        },
        {
          "chainId": 1,
          "address": "0xdcd85914b8ae28c1e62f1c488e1d968d5aaffe2b",
          "name": "TOP Network",
          "symbol": "TOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7058/thumb/topnetwork-logo.png?1547043514"
        },
        {
          "chainId": 1,
          "address": "0xb24cd494fae4c180a89975f1328eab2a7d5d8f11",
          "name": "Developer DAO",
          "symbol": "CODE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27011/thumb/CHWxD9GV_400x400.jpeg?1661421133"
        },
        {
          "chainId": 1,
          "address": "0x02814f435dd04e254be7ae69f61fca19881a780d",
          "name": "Meme Dollar",
          "symbol": "PINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28765/thumb/Frame_18457.png?1674003054"
        },
        {
          "chainId": 1,
          "address": "0x46d0dac0926fa16707042cadc23f1eb4141fe86b",
          "name": "SONM",
          "symbol": "SNM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/861/thumb/sonm.png?1548609871"
        },
        {
          "chainId": 1,
          "address": "0xb53ecf1345cabee6ea1a65100ebb153cebcac40f",
          "name": "Childhoods End",
          "symbol": "O",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25670/thumb/photo_2022-05-27_12-45-29.jpg?1653626809"
        },
        {
          "chainId": 1,
          "address": "0x5f190f9082878ca141f858c1c90b4c59fe2782c5",
          "name": "Kudoe",
          "symbol": "KDOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28709/thumb/CG.png?1674305204"
        },
        {
          "chainId": 1,
          "address": "0xd39a2cecba2657e125ba6a5c98ad2f6b6d7e83fd",
          "name": "Luxo",
          "symbol": "LUXO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25545/thumb/ik7UTHJR_400x400.jpg?1652327828"
        },
        {
          "chainId": 1,
          "address": "0x53c8395465a84955c95159814461466053dedede",
          "name": "DeGate",
          "symbol": "DG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14415/thumb/DG_token_brand_web_rgb_blue.png?1669460087"
        },
        {
          "chainId": 1,
          "address": "0x82fdedfb7635441aa5a92791d001fa7388da8025",
          "name": "Digital Ticks",
          "symbol": "DTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4898/thumb/digital-ticks-logo.jpg?1533010074"
        },
        {
          "chainId": 1,
          "address": "0x7420b4b9a0110cdc71fb720908340c03f9bc03ec",
          "name": "JasmyCoin",
          "symbol": "JASMY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg?1612473259"
        },
        {
          "chainId": 1,
          "address": "0x138c2f1123cf3f82e4596d097c118eac6684940b",
          "name": "Alpha Coin",
          "symbol": "ALPHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22199/thumb/logo2023.png?1683018649"
        },
        {
          "chainId": 1,
          "address": "0x8d75959f1e61ec2571aa72798237101f084de63a",
          "name": "Substratum",
          "symbol": "SUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/950/thumb/substratum.png?1548611133"
        },
        {
          "chainId": 1,
          "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          "name": "USD Coin",
          "symbol": "USDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389"
        },
        {
          "chainId": 1,
          "address": "0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593",
          "name": "StaFi Staked ETH",
          "symbol": "RETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14452/thumb/rETH.png?1680074713"
        },
        {
          "chainId": 1,
          "address": "0x226f7b842e0f0120b7e194d05432b3fd14773a9d",
          "name": "UNION Protocol Governance",
          "symbol": "UNN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13408/thumb/unn_finance.png?1608262290"
        },
        {
          "chainId": 1,
          "address": "0xea7aa1edd21735a5ab05ee3e90869016191e274e",
          "name": "Junca cash",
          "symbol": "JCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12889/thumb/junca.jpg?1603279160"
        },
        {
          "chainId": 1,
          "address": "0x75ecb52e403c617679fbd3e77a50f9d10a842387",
          "name": "CSR",
          "symbol": "CSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25585/thumb/CSR-LOGO-200x200-1.png?1652767956"
        },
        {
          "chainId": 1,
          "address": "0xcf1e086a145dd7b5b771b1e6acbbc1d2b58d7e80",
          "name": "Gen Wealth",
          "symbol": "GENW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30127/thumb/Gen_Wealth.png?1683260204"
        },
        {
          "chainId": 1,
          "address": "0x87931e7ad81914e7898d07c68f145fc0a553d8fb",
          "name": "WIZARD Vault  NFTX ",
          "symbol": "WIZARD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17068/thumb/Wizards.png?1626151233"
        },
        {
          "chainId": 1,
          "address": "0x0173661769325565d4f011b2e5cda688689cc87c",
          "name": "Quantland",
          "symbol": "QLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24536/thumb/8jYfKBTk_400x400.jpg?1648051247"
        },
        {
          "chainId": 1,
          "address": "0xee503d43ad447cdfc719f688207bfcb2fbb9471c",
          "name": "Daomatian",
          "symbol": "DAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30784/thumb/3Vwa_H8f_400x400.jpg?1687242380"
        },
        {
          "chainId": 1,
          "address": "0xb459f7204a8ac84f9e7758d6d839ebd01670e35c",
          "name": "Lotty",
          "symbol": "LOTTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31018/thumb/download.png?1689825980"
        },
        {
          "chainId": 1,
          "address": "0xad0891abb1687fb994a2c1c9699520422573996f",
          "name": "Ether Tech",
          "symbol": "ETHER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28748/thumb/200-x-200_ETHER_LOGO.jpg?1673868702"
        },
        {
          "chainId": 1,
          "address": "0x8ed2fc62d6850eaadcb717465752dab591286839",
          "name": "Elyssa",
          "symbol": "ELY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31187/thumb/IMG_20230720_174915_364.jpg?1691141809"
        },
        {
          "chainId": 1,
          "address": "0xf029fd6b5ed35c825dd02b7a02952c30002840e4",
          "name": "Sigil Finance",
          "symbol": "SIGIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29266/thumb/sigil.png?1677667120"
        },
        {
          "chainId": 1,
          "address": "0x558ec3152e2eb2174905cd19aea4e34a23de9ad6",
          "name": "Bread",
          "symbol": "BRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1440/thumb/bread.png?1547563238"
        },
        {
          "chainId": 1,
          "address": "0x238a1fcc2f65ed50c1e1797cf5857cba3e82a55b",
          "name": "Moeta",
          "symbol": "MOETA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29245/thumb/photo_2023-05-30_21-31-25.jpg?1688621267"
        },
        {
          "chainId": 1,
          "address": "0x6a445e9f40e0b97c92d0b8a3366cef1d67f700bf",
          "name": "Fidu",
          "symbol": "FIDU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25944/thumb/GFI-asset-icon.png?1654827482"
        },
        {
          "chainId": 1,
          "address": "0x010d14d36c3ea6570d240ae3ac9d660398f7c48e",
          "name": "XCF Token",
          "symbol": "XCF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10987/thumb/xcf-icon-01-1-flat.png?1622195426"
        },
        {
          "chainId": 1,
          "address": "0x5891599664ed15c6e88041b4f5bc08594f026f0e",
          "name": "JPGoldCoin",
          "symbol": "JPGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27321/thumb/JPGC_LOGO-_Coingecko.png?1663319056"
        },
        {
          "chainId": 1,
          "address": "0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407",
          "name": "c0x",
          "symbol": "CZRX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10644/thumb/czrx1.JPG?1581390510"
        },
        {
          "chainId": 1,
          "address": "0xe2ba8693ce7474900a045757fe0efca900f6530b",
          "name": "Flux DAI",
          "symbol": "FDAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29052/thumb/fDAI_200x200.png?1676335235"
        },
        {
          "chainId": 1,
          "address": "0x6bea7cfef803d1e3d5f7c0103f7ded065644e197",
          "name": "Gamma Strategies",
          "symbol": "GAMMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21975/thumb/gamma-token-200.png?1640566576"
        },
        {
          "chainId": 1,
          "address": "0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9",
          "name": "Donut",
          "symbol": "DONUT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7538/thumb/Donut.png?1548234345"
        },
        {
          "chainId": 1,
          "address": "0xfc1c93a2507975e98b9d0e9260ded61a00152bf1",
          "name": "Atlas Navi",
          "symbol": "NAVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28389/thumb/token_icon_-_logo_ATLAS_NAVI_200x200_transparent.png?1670070012"
        },
        {
          "chainId": 1,
          "address": "0xcd6adc6b8bd396e2d53ccd7d7257b4de55be4fbe",
          "name": "CFL365 Finance",
          "symbol": "CFL365",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG?1628218865"
        },
        {
          "chainId": 1,
          "address": "0x4cd0c43b0d53bc318cc5342b77eb6f124e47f526",
          "name": "FreeRossDAO",
          "symbol": "FREE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21648/thumb/free.jpg?1639639586"
        },
        {
          "chainId": 1,
          "address": "0x92cfbec26c206c90aee3b7c66a9ae673754fab7e",
          "name": "OpenLeverage",
          "symbol": "OLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26098/thumb/256x256_OLE_Token_Logo.png?1655859035"
        },
        {
          "chainId": 1,
          "address": "0xe632ea2ef2cfd8fc4a2731c76f99078aef6a4b31",
          "name": "THX Network",
          "symbol": "THX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21323/thumb/logo-thx-resized-200-200.png?1638945575"
        },
        {
          "chainId": 1,
          "address": "0xec681f28f4561c2a9534799aa38e0d36a83cf478",
          "name": "YVS Finance",
          "symbol": "YVS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13471/thumb/cu0LSzE.png?1608852718"
        },
        {
          "chainId": 1,
          "address": "0x24d86df61479982c90d2977f4ba839496895559d",
          "name": "HypeToken",
          "symbol": "HYPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30699/thumb/_HYPE_LOGO_Black.png?1686541750"
        },
        {
          "chainId": 1,
          "address": "0x39ea10e507720783c27edd5f96bf2d6e199579b8",
          "name": "DeltaFlare",
          "symbol": "HONR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28059/thumb/DF_Logo_200x200.png?1667298738"
        },
        {
          "chainId": 1,
          "address": "0xc581b735a1688071a1746c968e0798d642ede491",
          "name": "Euro Tether",
          "symbol": "EURT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17385/thumb/Tether_new.png?1683650223"
        },
        {
          "chainId": 1,
          "address": "0xc5bddf9843308380375a611c18b50fb9341f502a",
          "name": "veCRV DAO yVault",
          "symbol": "YVE-CRVDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13065/thumb/yearn_veCRV.png?1612862859"
        },
        {
          "chainId": 1,
          "address": "0x329c6e459ffa7475718838145e5e85802db2a303",
          "name": "MaidSafeCoin",
          "symbol": "EMAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/80/thumb/maidsafecoin.png?1547033750"
        },
        {
          "chainId": 1,
          "address": "0x3ea8ea4237344c9931214796d9417af1a1180770",
          "name": "SEDA Protocol",
          "symbol": "FLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21137/thumb/flx.png?1674306547"
        },
        {
          "chainId": 1,
          "address": "0xdd0020b1d5ba47a54e2eb16800d73beb6546f91a",
          "name": "Moola",
          "symbol": "AXPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2682/thumb/6QRknY7.png?1657767443"
        },
        {
          "chainId": 1,
          "address": "0xe4815ae53b124e7263f08dcdbbb757d41ed658c6",
          "name": "ZKSpace",
          "symbol": "ZKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13585/thumb/zkslogo640.png?1691386411"
        },
        {
          "chainId": 1,
          "address": "0x5395df198fcbc9c13bec506004c9a8b6460a7712",
          "name": "Bubu",
          "symbol": "BUBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30835/thumb/Bubu200x200.png?1687769540"
        },
        {
          "chainId": 1,
          "address": "0x251457b7c5d85251ca1ab384361c821330be2520",
          "name": "Hati",
          "symbol": "HATI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25736/thumb/hati-logo.png?1653461606"
        },
        {
          "chainId": 1,
          "address": "0x3bd8268791de798d4ed5d424d49412cf42b8ec3a",
          "name": "Opera Protocol",
          "symbol": "OPERA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31072/thumb/IMG_20230713_192302_797.png?1690338941"
        },
        {
          "chainId": 1,
          "address": "0xcb0d82f4dfa503c9e3b8abc7a3caa01175b2da39",
          "name": "AurusX",
          "symbol": "AX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27871/thumb/AurusX_token_2D.jpg?1666165444"
        },
        {
          "chainId": 1,
          "address": "0x3fab0bbaa03bceaf7c49e2b12877db0142be65fc",
          "name": "Castello Coin",
          "symbol": "CAST",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25216/thumb/19705.png?1650866493"
        },
        {
          "chainId": 1,
          "address": "0x1456688345527be1f37e9e627da0837d6f08c925",
          "name": "USDP Stablecoin",
          "symbol": "USDP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13234/thumb/USDP.png?1606579598"
        },
        {
          "chainId": 1,
          "address": "0x00aba6fe5557de1a1d565658cbddddf7c710a1eb",
          "name": "EasyFi V2",
          "symbol": "EZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12742/thumb/Logo_Icon.png?1624471467"
        },
        {
          "chainId": 1,
          "address": "0xdb05ea0877a2622883941b939f0bb11d1ac7c400",
          "name": "Opacity",
          "symbol": "OPCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7237/thumb/Opacity.jpg?1551843524"
        },
        {
          "chainId": 1,
          "address": "0x337af08bb6980ecb68389c5ed8876d08643abf8a",
          "name": "NOVAWCHI",
          "symbol": "VACHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29761/thumb/novawchi-infinity-of-nevoverse-coinmarketcap-logo.png?1681178882"
        },
        {
          "chainId": 1,
          "address": "0x5f4361971667c8198706dec8c59cf7607e405c6e",
          "name": "Fileshare Platform",
          "symbol": "FSC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28190/thumb/fsc.png?1668325601"
        },
        {
          "chainId": 1,
          "address": "0x09970aec766b6f3223aca9111555e99dc50ff13a",
          "name": "Levolution",
          "symbol": "LEVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083"
        },
        {
          "chainId": 1,
          "address": "0xd1e06952708771f71e6dd18f06ee418f6e8fc564",
          "name": "GazeTV",
          "symbol": "GAZE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14717/thumb/Gaze_Icon_Positive.png?1617937326"
        },
        {
          "chainId": 1,
          "address": "0x91bdf1eb4215bc1c6d9a6bd83ecfceeb17cd4343",
          "name": "Richard",
          "symbol": "RICHARD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30295/thumb/Richard.png?1683867922"
        },
        {
          "chainId": 1,
          "address": "0x4057db5bd9f67a566aa10e5587b1a964affc6a16",
          "name": "Truefeedback",
          "symbol": "TFBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8842/thumb/5rd7a55q_400x400.png?1562902557"
        },
        {
          "chainId": 1,
          "address": "0xcd7492db29e2ab436e819b249452ee1bbdf52214",
          "name": "SafeMoon Inu",
          "symbol": "SMI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/16091/thumb/SMI.png?1622801787"
        },
        {
          "chainId": 1,
          "address": "0xaca55d5a5f58e29bd1e00e4b1bdeda62d2ecf33f",
          "name": "MrsPepe",
          "symbol": "MRSPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30155/thumb/IMG_20230504_192148_558.jpg?1683518384"
        },
        {
          "chainId": 1,
          "address": "0xbb1ee07d6c7baeb702949904080eb61f5d5e7732",
          "name": "Dogey Inu",
          "symbol": "DINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16374/thumb/512x512_dinu_logo.jpg?1623919813"
        },
        {
          "chainId": 1,
          "address": "0x38b7bf4eecf3eb530b1529c9401fc37d2a71a912",
          "name": "ClayStack Staked MATIC",
          "symbol": "CSMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28352/thumb/csMatic.png?1669771887"
        },
        {
          "chainId": 1,
          "address": "0xea068fba19ce95f12d252ad8cb2939225c4ea02d",
          "name": "Fief",
          "symbol": "FIEF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23710/thumb/61f837d2703b649ab0213d45_Black_logo_-_no_background-p-500.png?1645083867"
        },
        {
          "chainId": 1,
          "address": "0x2c7f442aab99d5e18cfae2291c507c0b5f3c1eb5",
          "name": "Keko",
          "symbol": "KEKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29967/thumb/ytX0JKjO_400x400.jpg?1682591516"
        },
        {
          "chainId": 1,
          "address": "0x49849c98ae39fff122806c06791fa73784fb3675",
          "name": "LP renBTC Curve",
          "symbol": "RENBTCCURVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11957/thumb/Curvefi_renCrv_32.png?1596435739"
        },
        {
          "chainId": 1,
          "address": "0x7c32db0645a259fae61353c1f891151a2e7f8c1e",
          "name": "Potentiam",
          "symbol": "PTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2349/thumb/ptm.png?1547036546"
        },
        {
          "chainId": 1,
          "address": "0xc77b230f31b517f1ef362e59c173c2be6540b5e8",
          "name": "VIDY",
          "symbol": "VIDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5088/thumb/vidy-logo.jpg?1547040486"
        },
        {
          "chainId": 1,
          "address": "0xecbddf83687e9842837e08c5a650658f2260b376",
          "name": "Curve Network",
          "symbol": "CURVE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29934/thumb/IMG_20230416_170503_492_%281%29.png?1682142521"
        },
        {
          "chainId": 1,
          "address": "0x532997aced1d85d54bdd05696f45b7a1ba7df9ec",
          "name": "Libfi",
          "symbol": "LIBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30310/thumb/Logo_Unit_Vertical-01.png?1683875950"
        },
        {
          "chainId": 1,
          "address": "0xeeee2a622330e6d2036691e983dee87330588603",
          "name": "Asko",
          "symbol": "ASKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11773/thumb/ASKO.png?1662435680"
        },
        {
          "chainId": 1,
          "address": "0xbe9385116fbbff5fdbaea0b52c3003b92be7f81c",
          "name": "The Reaper Coin",
          "symbol": "REAPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30562/thumb/icon.jpg?1685069538"
        },
        {
          "chainId": 1,
          "address": "0xc014186cf1ba36032aaec7f96088f09eb3934347",
          "name": "WeCoOwn",
          "symbol": "WCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14497/thumb/WCX-Token-Logo.png?1616555141"
        },
        {
          "chainId": 1,
          "address": "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
          "name": "The Graph",
          "symbol": "GRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566"
        },
        {
          "chainId": 1,
          "address": "0x090fc4d5436d98e13473c4d6109de6a18bcfb0d4",
          "name": "SkyToken",
          "symbol": "SKY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28037/thumb/sky.png?1667129732"
        },
        {
          "chainId": 1,
          "address": "0x518b63da813d46556fea041a88b52e3caa8c16a8",
          "name": "Antfarm Token",
          "symbol": "ATF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28821/thumb/ATF.png?1681189807"
        },
        {
          "chainId": 1,
          "address": "0x6c3be406174349cfa4501654313d97e6a31072e1",
          "name": "CNNS",
          "symbol": "CNNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8362/thumb/cnns.png?1557992686"
        },
        {
          "chainId": 1,
          "address": "0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5",
          "name": "Lympo",
          "symbol": "LYM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2620/thumb/lympo_token.png?1547036775"
        },
        {
          "chainId": 1,
          "address": "0xb19dd661f076998e3b0456935092a233e12c2280",
          "name": "Continuum World",
          "symbol": "UM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18798/thumb/Moneda.png?1633474110"
        },
        {
          "chainId": 1,
          "address": "0x4f81c790581b240a5c948afd173620ecc8c71c8d",
          "name": "Decentral Games Governance",
          "symbol": "XDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21176/thumb/xDG_Logo.png?1638508706"
        },
        {
          "chainId": 1,
          "address": "0xe66b3aa360bb78468c00bebe163630269db3324f",
          "name": "Merchant",
          "symbol": "MTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17157/thumb/mto.PNG?1626672046"
        },
        {
          "chainId": 1,
          "address": "0xbb19da2482308ec02a242aced4fe0f09d06b12a7",
          "name": "Flash 3 0",
          "symbol": "FLASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31151/thumb/logo_200x200.png?1691037440"
        },
        {
          "chainId": 1,
          "address": "0xe4dae00bc1c46ea2f44ae71b1beb8b171c15d812",
          "name": "PREMA",
          "symbol": "PRMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27600/thumb/prmx.png?1664715769"
        },
        {
          "chainId": 1,
          "address": "0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042",
          "name": "MurAll",
          "symbol": "PAINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14103/thumb/paint_logo_200x200.png?1614325640"
        },
        {
          "chainId": 1,
          "address": "0x788b6d2b37aa51d916f2837ae25b05f0e61339d1",
          "name": "Metavault DAO",
          "symbol": "MVD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23899/thumb/MVD_Coin-simple.png?1681090496"
        },
        {
          "chainId": 1,
          "address": "0xe3a46b2bc1d83c731d58cab765d3b45bce789095",
          "name": "DAYSTARTER",
          "symbol": "DST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28006/thumb/daystarter_logo_200_200.png?1667148509"
        },
        {
          "chainId": 1,
          "address": "0x570936840fa942c96ca4c57a170dda494dd9b9c6",
          "name": "Decentral ART",
          "symbol": "ART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29398/thumb/E58AC31F-1B46-4BAD-B8D8-E01551303E09.jpeg?1678512681"
        },
        {
          "chainId": 1,
          "address": "0x106538cc16f938776c7c180186975bca23875287",
          "name": "Basis Share",
          "symbol": "BAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13251/thumb/BAS.png?1613231139"
        },
        {
          "chainId": 1,
          "address": "0x308516e6daac88d719af5a6d7ea2a82d8c8e7356",
          "name": "Meme Street Gang",
          "symbol": "MSG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29800/thumb/MSG.png?1681349420"
        },
        {
          "chainId": 1,
          "address": "0xfac0403a24229d7e2edd994d50f5940624cbeac2",
          "name": "Theopetra",
          "symbol": "THEO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29431/thumb/MicrosoftTeams-image_%2826%29.png?1678782828"
        },
        {
          "chainId": 1,
          "address": "0xe803178b48a0e560c2b19f3b3d4e504f79d229ce",
          "name": "Bobcoin",
          "symbol": "BOBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24264/thumb/bobc.png?1647171532"
        },
        {
          "chainId": 1,
          "address": "0x9a0aba393aac4dfbff4333b06c407458002c6183",
          "name": "ACoconut",
          "symbol": "AC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12779/thumb/ac_logo.png?1602500084"
        },
        {
          "chainId": 1,
          "address": "0x66a0f676479cee1d7373f3dc2e2952778bff5bd6",
          "name": "Wise",
          "symbol": "WISE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13552/thumb/Wise.jpg.jpg?1672204323"
        },
        {
          "chainId": 1,
          "address": "0x9ae380f0272e2162340a5bb646c354271c0f5cfc",
          "name": "Conic",
          "symbol": "CNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24747/thumb/cnc.png?1677911934"
        },
        {
          "chainId": 1,
          "address": "0xbe428c3867f05dea2a89fc76a102b544eac7f772",
          "name": "CyberVein",
          "symbol": "CVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3886/thumb/CyberVein_LOGO.png?1616062326"
        },
        {
          "chainId": 1,
          "address": "0x7e9c15c43f0d6c4a12e6bdff7c7d55d0f80e3e23",
          "name": "Astra DAO",
          "symbol": "ASTRADAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27255/thumb/astra200x200px_blue_%282%29.png?1663035884"
        },
        {
          "chainId": 1,
          "address": "0xa258c4606ca8206d8aa700ce2143d7db854d168c",
          "name": "WETH yVault",
          "symbol": "YVWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28793/thumb/yvWETH-128-0xa258C4606Ca8206D8aA700cE2143D7db854D168c.png?1674225513"
        },
        {
          "chainId": 1,
          "address": "0xfd0877d9095789caf24c98f7cce092fa8e120775",
          "name": "TUSD yVault",
          "symbol": "YVTUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28790/thumb/yvTUSD-128-0xFD0877d9095789cAF24c98F7CCe092fa8E120775.png?1674225035"
        },
        {
          "chainId": 1,
          "address": "0x0488401c3f535193fa8df029d9ffe615a06e74e6",
          "name": "SparkPoint",
          "symbol": "SRK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8371/thumb/SRK.png?1614675526"
        },
        {
          "chainId": 1,
          "address": "0x24b47299e756af0571f512232a3629e0dabb52ed",
          "name": "concertVR",
          "symbol": "CVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2914/thumb/cvt.png?1547037094"
        },
        {
          "chainId": 1,
          "address": "0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903",
          "name": "Xend Finance",
          "symbol": "XEND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png?1616661216"
        },
        {
          "chainId": 1,
          "address": "0xadc234a4e90e2045f353f5d4fcde66144d23b458",
          "name": "Fluid USDT",
          "symbol": "FUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28472/thumb/fUSDT-200x200.png?1671002181"
        },
        {
          "chainId": 1,
          "address": "0xa589d8868607b8d79ee4288ce192796051263b64",
          "name": "TATE",
          "symbol": "TATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30034/thumb/Tate_logo_Coingecko.png?1682645481"
        },
        {
          "chainId": 1,
          "address": "0xda23d301761e4e2bf474951f978f6dfb6f3c9f14",
          "name": "Tsuki Inu",
          "symbol": "TKINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15681/thumb/tkinu.png?1621508357"
        },
        {
          "chainId": 1,
          "address": "0x99fe3b1391503a1bc1788051347a1324bff41452",
          "name": "SX Network",
          "symbol": "SX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13779/thumb/sx.png?1636034699"
        },
        {
          "chainId": 1,
          "address": "0xb870679a7fa65b924026f496de7f27c1dd0e5c5f",
          "name": "Hello Pets",
          "symbol": "PET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14354/thumb/hello_pets.jpg?1675847422"
        },
        {
          "chainId": 1,
          "address": "0xb2fd1e0478dbf61772996bcce8a2f1151eeeda37",
          "name": "Wrapped Pepe",
          "symbol": "WPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30961/thumb/200.png?1689221056"
        },
        {
          "chainId": 1,
          "address": "0xc90906d45046059221e908de5cc8fcfaca859235",
          "name": "Shibarium Pad",
          "symbol": "SHIBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29268/thumb/shibp.jpg?1677727695"
        },
        {
          "chainId": 1,
          "address": "0xc86d054809623432210c107af2e3f619dcfbf652",
          "name": "Sentinel Protocol",
          "symbol": "UPP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3369/thumb/Sentinel_Protocol.jpg?1547700074"
        },
        {
          "chainId": 1,
          "address": "0xccdb064a41afcd432bcea6f6d0d1e7c371d0b002",
          "name": "Qrolli",
          "symbol": "QR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29681/thumb/logoQrolli.png?1680595833"
        },
        {
          "chainId": 1,
          "address": "0xb685145d7f127b9093d7f9278bae902ef59ff486",
          "name": "FREQAI",
          "symbol": "FREQAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29122/thumb/The_Razors_Edge.png?1676788109"
        },
        {
          "chainId": 1,
          "address": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
          "name": "Pax Dollar",
          "symbol": "USDP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6013/thumb/Pax_Dollar.png?1629877204"
        },
        {
          "chainId": 1,
          "address": "0xfb782396c9b20e564a64896181c7ac8d8979d5f4",
          "name": "Divergence Protocol",
          "symbol": "DIVER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18666/thumb/DIVER.jpg?1632837940"
        },
        {
          "chainId": 1,
          "address": "0xc8871267e07408b89aa5aecc58adca5e574557f8",
          "name": "Instadapp USDC",
          "symbol": "IUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25820/thumb/iUSDC_100x100.png?1654055475"
        },
        {
          "chainId": 1,
          "address": "0x3c4008eca800ec1283e4cf500e68d06bfabc00a8",
          "name": "HistoryDAO",
          "symbol": "HAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27318/thumb/5961657012978_.pic.jpg?1663316216"
        },
        {
          "chainId": 1,
          "address": "0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544",
          "name": "Doki Doki",
          "symbol": "DOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12759/thumb/doki_logo.png?1602338064"
        },
        {
          "chainId": 1,
          "address": "0x8442e0e292186854bb6875b2a0fc1308b9ded793",
          "name": "Print The Pepe",
          "symbol": "PP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29964/thumb/printthepepe.PNG?1682389733"
        },
        {
          "chainId": 1,
          "address": "0xb8fa12f8409da31a4fc43d15c4c78c33d8213b9b",
          "name": "CaliCoin",
          "symbol": "CALI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14998/thumb/cropped-Logo-transparent-background-1.png?1619392357"
        },
        {
          "chainId": 1,
          "address": "0xd478161c952357f05f0292b56012cd8457f1cfbf",
          "name": "Polkamarkets",
          "symbol": "POLK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14084/thumb/polkamarkets.jpg?1614179979"
        },
        {
          "chainId": 1,
          "address": "0x25722cd432d02895d9be45f5deb60fc479c8781e",
          "name": "Sponge",
          "symbol": "SPONGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30254/thumb/Sea_Sponge_1.png?1683704572"
        },
        {
          "chainId": 1,
          "address": "0x2fc246aa66f0da5bb1368f688548ecbbe9bdee5d",
          "name": "TEMCO",
          "symbol": "TEMCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6210/thumb/bSZ7HUuS_400x400.jpg?1549002381"
        },
        {
          "chainId": 1,
          "address": "0xea38eaa3c86c8f9b751533ba2e562deb9acded40",
          "name": "Etherparty",
          "symbol": "FUEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1082/thumb/etherparty.png?1547394573"
        },
        {
          "chainId": 1,
          "address": "0xa499648fd0e80fd911972bbeb069e4c20e68bf22",
          "name": "Jenny DAO V1",
          "symbol": "UJENNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15453/thumb/ujenny.png?1620870247"
        },
        {
          "chainId": 1,
          "address": "0xa0f0546eb5e3ee7e8cfc5da12e5949f3ae622675",
          "name": "Tokoin",
          "symbol": "TOKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png?1646664093"
        },
        {
          "chainId": 1,
          "address": "0xbe30f684d62c9f7883a75a29c162c332c0d98f23",
          "name": "Global Human Trust",
          "symbol": "GHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12040/thumb/WechatIMG6648.png?1596700737"
        },
        {
          "chainId": 1,
          "address": "0x7995ab36bb307afa6a683c24a25d90dc1ea83566",
          "name": "HitChain",
          "symbol": "HIT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/5555/thumb/hitchain.png?1547041361"
        },
        {
          "chainId": 1,
          "address": "0x56b4f8c39e07d4d5d91692acf9d0f6d4d3493763",
          "name": "Trism",
          "symbol": "TRISM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13549/thumb/76106366.png?1609715454"
        },
        {
          "chainId": 1,
          "address": "0x94025780a1ab58868d9b2dbbb775f44b32e8e6e5",
          "name": "BetSwirl",
          "symbol": "BETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png?1659073275"
        },
        {
          "chainId": 1,
          "address": "0x3a1311b8c404629e38f61d566cefefed083b9670",
          "name": "Piccolo Inu",
          "symbol": "PINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19335/thumb/Piccolo-Inu.png?1643191682"
        },
        {
          "chainId": 1,
          "address": "0xbe92b510007bd3ec0adb3d1fca338dd631e98de7",
          "name": "DegensTogether",
          "symbol": "DEGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30487/thumb/degen_logo_twit.png?1684713904"
        },
        {
          "chainId": 1,
          "address": "0x3b9be07d622accaed78f479bc0edabfd6397e320",
          "name": "Lossless",
          "symbol": "LSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15917/thumb/Group_57.png?1623046307"
        },
        {
          "chainId": 1,
          "address": "0x3267c5b73cc15e253b1a90c01366b17d560bc6fb",
          "name": "President Ron DeSantis",
          "symbol": "RON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30620/thumb/Desantis-200x200.jpg?1685772532"
        },
        {
          "chainId": 1,
          "address": "0xd43be54c1aedf7ee4099104f2dae4ea88b18a249",
          "name": "Traxx",
          "symbol": "TRAXX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24441/thumb/TT.png?1658383110"
        },
        {
          "chainId": 1,
          "address": "0x43a96962254855f16b925556f9e97be436a43448",
          "name": "Hord",
          "symbol": "HORD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png?1619513849"
        },
        {
          "chainId": 1,
          "address": "0xf7b098298f7c69fc14610bf71d5e02c60792894c",
          "name": "Guppy",
          "symbol": "GUP",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/683/thumb/matchpool.png?1547034437"
        },
        {
          "chainId": 1,
          "address": "0x2a039b1d9bbdccbb91be28691b730ca893e5e743",
          "name": "Rentible",
          "symbol": "RNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850"
        },
        {
          "chainId": 1,
          "address": "0xf134519cbe2042b06ee7ce20df51d09b55559896",
          "name": "Mochi",
          "symbol": "MOCHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28981/thumb/mochi-logo.png?1675758992"
        },
        {
          "chainId": 1,
          "address": "0xf009f5531de69067435e32c4b9d36077f4c4a673",
          "name": "Unvest",
          "symbol": "UNV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18119/thumb/UNV.jpg?1680852403"
        },
        {
          "chainId": 1,
          "address": "0xf939e0a03fb07f59a73314e73794be0e57ac1b4e",
          "name": "crvUSD",
          "symbol": "CRVUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30118/thumb/crvusd.jpeg?1683171990"
        },
        {
          "chainId": 1,
          "address": "0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51",
          "name": "Enreach",
          "symbol": "NRCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg?1617813758"
        },
        {
          "chainId": 1,
          "address": "0x6307b25a665efc992ec1c1bc403c38f3ddd7c661",
          "name": "Global Coin Research",
          "symbol": "GCR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14815/thumb/gcr.jpeg?1641461197"
        },
        {
          "chainId": 1,
          "address": "0x6fbc20483b53cea47839bb8e171abd6d67c3c696",
          "name": "EvolveAI",
          "symbol": "EVOAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28964/thumb/EvolveAI.png?1675744023"
        },
        {
          "chainId": 1,
          "address": "0xe8663a64a96169ff4d95b4299e7ae9a76b905b31",
          "name": "DPRating",
          "symbol": "RATING",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5383/thumb/dprating.png?1547224023"
        },
        {
          "chainId": 1,
          "address": "0xdecade1c6bf2cd9fb89afad73e4a519c867adcf5",
          "name": "Experty Wisdom",
          "symbol": "WIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13133/thumb/n0MTVBrm_400x400.jpg?1605543934"
        },
        {
          "chainId": 1,
          "address": "0xe47f1cd2a37c6fe69e3501ae45eca263c5a87b2b",
          "name": "Zunami Ether",
          "symbol": "ZETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31176/thumb/Brand_symbol_Color.png?1691117988"
        },
        {
          "chainId": 1,
          "address": "0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107",
          "name": "CVI",
          "symbol": "GOVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png?1612451531"
        },
        {
          "chainId": 1,
          "address": "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
          "name": "Magic Internet Money",
          "symbol": "MIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612"
        },
        {
          "chainId": 1,
          "address": "0x5c0590cde44569bf39ef79e859b367e39cb000f1",
          "name": "hiVALHALLA",
          "symbol": "HIVALHALLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28911/thumb/hiVALHALLA.png?1675311154"
        },
        {
          "chainId": 1,
          "address": "0x177ba0cac51bfc7ea24bad39d81dcefd59d74faa",
          "name": "KittenFinance",
          "symbol": "KIF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12340/thumb/SnQPkABT_400x400.png?1599173267"
        },
        {
          "chainId": 1,
          "address": "0x0cf75471b0fbf76a315a75ebda6600ab92fdc924",
          "name": "DeathWolf",
          "symbol": "DTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29405/thumb/DeathWolf.png?1678516176"
        },
        {
          "chainId": 1,
          "address": "0x3540abe4f288b280a0740ad5121aec337c404d15",
          "name": "TPRO",
          "symbol": "TPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26694/thumb/tpro-logo-200x200.png?1659607453"
        },
        {
          "chainId": 1,
          "address": "0xd69f306549e9d96f183b1aeca30b8f4353c2ecc3",
          "name": "MCH Coin",
          "symbol": "MCHC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15399/thumb/MCHC.jpg?1620721307"
        },
        {
          "chainId": 1,
          "address": "0xdfdd3459d4f87234751696840092ee20c970fb07",
          "name": "My bu",
          "symbol": "MYOBU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16122/thumb/myobu.png?1623037167"
        },
        {
          "chainId": 1,
          "address": "0x6431fa4b812a2dcc062a38cb55cc7d18135adead",
          "name": "RankerDao",
          "symbol": "RANKER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23902/thumb/ranker.png?1645676549"
        },
        {
          "chainId": 1,
          "address": "0x26a604dffe3ddab3bee816097f81d3c4a2a4cf97",
          "name": "CorionX",
          "symbol": "CORX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405"
        },
        {
          "chainId": 1,
          "address": "0x7797c85b46f548eacc07c229f6cd207d6370442f",
          "name": "Dog Tag",
          "symbol": "TAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26418/thumb/TAG_PNG.png?1657926565"
        },
        {
          "chainId": 1,
          "address": "0x286f851b049ccce1419e09b6468dc3297f86a703",
          "name": "hiSEALS",
          "symbol": "HISEALS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29191/thumb/63f46d084b94830001ea9a60_hiSEALS_-__Logo.png?1677225473"
        },
        {
          "chainId": 1,
          "address": "0x131157c6760f78f7ddf877c0019eba175ba4b6f6",
          "name": "BigShortBets",
          "symbol": "BIGSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18235/thumb/bigsb-token-logo.png?1631065645"
        },
        {
          "chainId": 1,
          "address": "0x7e80e4d7d5725499791cf8b17a4586f1f0672a0c",
          "name": "ZOGI",
          "symbol": "ZOGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28446/thumb/ZOGI_Token_Logo.png?1670659477"
        },
        {
          "chainId": 1,
          "address": "0x996dc5dfc819408dd98cd92c9a76f64b0738dc3d",
          "name": "Skillchain",
          "symbol": "SKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168"
        },
        {
          "chainId": 1,
          "address": "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
          "name": "Sentiment",
          "symbol": "SENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16388/thumb/94SwpUOP_400x400.jpg?1623894898"
        },
        {
          "chainId": 1,
          "address": "0x805c2077f3ab224d889f9c3992b41b2f4722c787",
          "name": "art Q NFT Investment Fund",
          "symbol": "ARTEQ",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/23407/thumb/arteq.png?1644146442"
        },
        {
          "chainId": 1,
          "address": "0x4fbb350052bca5417566f188eb2ebce5b19bc964",
          "name": "RigoBlock",
          "symbol": "GRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1532/thumb/Symbol-RigoblockRGB.png?1547035682"
        },
        {
          "chainId": 1,
          "address": "0xc719d010b63e5bbf2c0551872cd5316ed26acd83",
          "name": "Etherisc DIP",
          "symbol": "DIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4586/thumb/dip.png?1547039863"
        },
        {
          "chainId": 1,
          "address": "0x3541a5c1b04adaba0b83f161747815cd7b1516bc",
          "name": "CitaDAO",
          "symbol": "KNIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22848/thumb/KNIGHT_TOKEN_LOGO.png?1643190758"
        },
        {
          "chainId": 1,
          "address": "0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30",
          "name": "Recharge",
          "symbol": "RCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18120/thumb/thecharge.PNG?1630559768"
        },
        {
          "chainId": 1,
          "address": "0x78223d31298107f3e310b09797b07967832046a6",
          "name": "ROYAL SMART FUTURE TOKEN",
          "symbol": "RSFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26630/thumb/RSF200x200.png?1659325435"
        },
        {
          "chainId": 1,
          "address": "0xfde00bd1da57349b0df85f134b18124266f3bc5b",
          "name": "Decentralized Intelligence Agency",
          "symbol": "DIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29808/thumb/IMG_20230412_175949_318.jpg?1681431507"
        },
        {
          "chainId": 1,
          "address": "0x3e5d9d8a63cc8a88748f229999cf59487e90721e",
          "name": "MetalSwap",
          "symbol": "XMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22075/thumb/Logo_COIN_-_Gradiente.png?1670579810"
        },
        {
          "chainId": 1,
          "address": "0x9cf98eb8a8b28c83e8612046cf55701ce3eb0063",
          "name": "Unreal Finance",
          "symbol": "UGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17943/thumb/11292.png?1629883358"
        },
        {
          "chainId": 1,
          "address": "0xa5e412ba6fca1e07b15defcaa4236ff7b5a7f086",
          "name": "Crypto Bank",
          "symbol": "CBANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13473/thumb/HHeUVDBF5clKgE4vW_dyCtcTZqWDRw_vDe1MA3lIJWI4wEZW2BwF5dHFbm_ZpJNTWf85sZxCBVl2R7u29kGg37bE8XkGh3CneW6SkVwbnQEe0r2WR6yy6THxMvzIMiicWza82-NyjMitEo84_2E_C5LCiBcdTPFrjNQiMs1DTSJuaRTNB81KRsdcXNW0p2j7PH8O7Xk3QrWVjPe.jpg?1608853514"
        },
        {
          "chainId": 1,
          "address": "0xe8438c23157de97bde8bedd2eeabc8e7e44de18a",
          "name": "Lunatics  ETH ",
          "symbol": "LUNAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30526/thumb/whvem.png?1685345118"
        },
        {
          "chainId": 1,
          "address": "0xe5ef42d0e5e4aa6b36c613d00db8dad303d505f3",
          "name": "BTour Chain",
          "symbol": "MSOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13141/thumb/msot.png?1688452964"
        },
        {
          "chainId": 1,
          "address": "0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c",
          "name": "Aave REN v1",
          "symbol": "AREN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12483/thumb/aREN.png?1600188492"
        },
        {
          "chainId": 1,
          "address": "0xe9a95d175a5f4c9369f3b74222402eb1b837693b",
          "name": "ChangeNOW",
          "symbol": "NOW",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8224/thumb/now.png?1660465450"
        },
        {
          "chainId": 1,
          "address": "0x370a366f402e2e41cdbbe54ecec12aae0cce1955",
          "name": "Toad Killer",
          "symbol": "TOAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30234/thumb/toad_icon200.png?1683644900"
        },
        {
          "chainId": 1,
          "address": "0xaedf386b755465871ff874e3e37af5976e247064",
          "name": "Fasttoken",
          "symbol": "FTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28478/thumb/lightenicon_200x200.png?1671006114"
        },
        {
          "chainId": 1,
          "address": "0x80ce3027a70e0a928d9268994e9b85d03bd4cdcf",
          "name": "Lokr",
          "symbol": "LKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932"
        },
        {
          "chainId": 1,
          "address": "0x8a40c222996f9f3431f63bf80244c36822060f12",
          "name": "FINXFLO",
          "symbol": "FXF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13916/thumb/raVh1Iw.png?1618978607"
        },
        {
          "chainId": 1,
          "address": "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359",
          "name": "Sai",
          "symbol": "SAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1442/thumb/dai.png?1547035520"
        },
        {
          "chainId": 1,
          "address": "0x049399a6b048d52971f7d122ae21a1532722285f",
          "name": "Fire Lotto",
          "symbol": "FLOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2201/thumb/fire-lotto.png?1548126078"
        },
        {
          "chainId": 1,
          "address": "0x8acee0fcee91cedad1c5013f031762c814740587",
          "name": "SAUDI PEPE",
          "symbol": "SAUDIPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30318/thumb/undefined_-_Imgur.png?1684113535"
        },
        {
          "chainId": 1,
          "address": "0x37a15c92e67686aa268df03d4c881a76340907e8",
          "name": "Pixiu Finance",
          "symbol": "PIXIU",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/17297/thumb/pixiu_picture_.png?1627264684"
        },
        {
          "chainId": 1,
          "address": "0xfa1b65448e7ec5f37e512cdce52ab79e541f4fb2",
          "name": "Snail Race",
          "symbol": "SNAILS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31062/thumb/200.png?1690177755"
        },
        {
          "chainId": 1,
          "address": "0xb478c6245e3d85d6ec3486b62ea872128d562541",
          "name": "LootBot",
          "symbol": "LOOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30985/thumb/K7dMTTKi_400x400.png?1689472306"
        },
        {
          "chainId": 1,
          "address": "0x69e37422cb87d963367f73a119c8ce9a4d529b72",
          "name": "Advantis",
          "symbol": "ADVT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28549/thumb/advantis.jpg?1671640302"
        },
        {
          "chainId": 1,
          "address": "0x9af15d7b8776fa296019979e70a5be53c714a7ec",
          "name": "Evolution Finance",
          "symbol": "EVN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13932/thumb/Frame_63_2.png?1612936435"
        },
        {
          "chainId": 1,
          "address": "0xead482da0793b00bbae0e34c8cfae6daf29a44b2",
          "name": "Versailles Heroes",
          "symbol": "VRH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27860/thumb/VRH___200-200.png?1667467208"
        },
        {
          "chainId": 1,
          "address": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
          "name": "Polygon",
          "symbol": "MATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912"
        },
        {
          "chainId": 1,
          "address": "0xadf86e75d8f0f57e0288d0970e7407eaa49b3cab",
          "name": "Apollo Crypto",
          "symbol": "APOLLO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22606/thumb/apollo-icon-green-black-w3-200.png?1656312049"
        },
        {
          "chainId": 1,
          "address": "0xc382e04099a435439725bb40647e2b32dc136806",
          "name": "Cogecoin",
          "symbol": "COGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16791/thumb/Dog-Logo_1x.png?1625033809"
        },
        {
          "chainId": 1,
          "address": "0x6100dd79fcaa88420750dcee3f735d168abcb771",
          "name": "NONbeta",
          "symbol": "NON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19592/thumb/nonbeta.png?1685088920"
        },
        {
          "chainId": 1,
          "address": "0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6",
          "name": "Oddz",
          "symbol": "ODDZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14421/thumb/NewLogo.png?1645171454"
        },
        {
          "chainId": 1,
          "address": "0xc76d53f988820fe70e01eccb0248b312c2f1c7ca",
          "name": "Inu Token",
          "symbol": "INU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30755/thumb/0xc76d53f988820fe70e01eccb0248b312c2f1c7ca.png?1686810661"
        },
        {
          "chainId": 1,
          "address": "0x745407c86df8db893011912d3ab28e68b62e49b0",
          "name": "MahaDAO",
          "symbol": "MAHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13404/thumb/MAHA_Token.png?1625651604"
        },
        {
          "chainId": 1,
          "address": "0x8c81121b15197fa0eeaee1dc75533419dcfd3151",
          "name": "IdleWBTC  Best Yield ",
          "symbol": "IDLEWBTCYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11936/thumb/idlewbtcv3maxyield_32.png?1596263736"
        },
        {
          "chainId": 1,
          "address": "0x62359ed7505efc61ff1d56fef82158ccaffa23d7",
          "name": "cVault finance",
          "symbol": "CORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12635/thumb/cvault.finance_logo.png?1601353499"
        },
        {
          "chainId": 1,
          "address": "0x159cdaf78be31e730d9e1330adfcfbb79a5fdb95",
          "name": "MixToEarn",
          "symbol": "MTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30893/thumb/200x200x.png?1688542443"
        },
        {
          "chainId": 1,
          "address": "0xdd16ec0f66e54d453e6756713e533355989040e4",
          "name": "Tokenomy",
          "symbol": "TEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2286/thumb/logo_%281%29.png?1604543144"
        },
        {
          "chainId": 1,
          "address": "0x777fd20c983d6658c1d50b3958b3a1733d1cd1e1",
          "name": "PUBLISH",
          "symbol": "NEWS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/9572/thumb/PUBLISH_Logo_280X280.png?1617605563"
        },
        {
          "chainId": 1,
          "address": "0x7533d63a2558965472398ef473908e1320520ae2",
          "name": "INTEXCOIN",
          "symbol": "INTX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/11854/thumb/INTX.png?1595167044"
        },
        {
          "chainId": 1,
          "address": "0xec213f83defb583af3a000b1c0ada660b1902a0f",
          "name": "Presearch",
          "symbol": "PRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1299/thumb/presearch.png?1548331942"
        },
        {
          "chainId": 1,
          "address": "0xf819d9cb1c2a819fd991781a822de3ca8607c3c9",
          "name": "Unibot",
          "symbol": "UNIBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30462/thumb/logonoline_%281%29.png?1687510315"
        },
        {
          "chainId": 1,
          "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
          "name": "Shiba Inu",
          "symbol": "SHIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446"
        },
        {
          "chainId": 1,
          "address": "0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14",
          "name": "Mettalex",
          "symbol": "MTLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12730/thumb/nrEqFTEW_400x400.jpg?1602063980"
        },
        {
          "chainId": 1,
          "address": "0xc71d8baaa436aa7e42da1f40bec48f11ab3c9e4a",
          "name": "iEthereum",
          "symbol": "IETH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1065/thumb/ieth.png?1600765203"
        },
        {
          "chainId": 1,
          "address": "0x8b39b70e39aa811b69365398e0aace9bee238aeb",
          "name": "Red Kite",
          "symbol": "PKF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14422/thumb/red_kite.png?1679564335"
        },
        {
          "chainId": 1,
          "address": "0x07ac55797d4f43f57ca92a49e65ca582cc287c27",
          "name": "Rebasing TBT",
          "symbol": "TBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29792/thumb/TBT.png?1681346387"
        },
        {
          "chainId": 1,
          "address": "0xa7fbd9254f10f8e20a31a593c9e8bc0d041e15f6",
          "name": "Orbeon Protocol",
          "symbol": "ORBN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29566/thumb/logo_%281%29.png?1679736737"
        },
        {
          "chainId": 1,
          "address": "0x68037790a0229e9ce6eaa8a99ea92964106c4703",
          "name": "Parallel",
          "symbol": "PAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14153/thumb/par_round_200.png?1614670422"
        },
        {
          "chainId": 1,
          "address": "0xfa93660c3f6a848556bb8e265f994160a1f2b289",
          "name": "Community Business Token",
          "symbol": "CBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14701/thumb/CBT-Token-Small.png?1617838284"
        },
        {
          "chainId": 1,
          "address": "0xc66cdac744916afb6811c71c277d88de90ce8d5b",
          "name": "CDbio",
          "symbol": "MCD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26957/thumb/mcd.png?1660998546"
        },
        {
          "chainId": 1,
          "address": "0x7d647b1a0dcd5525e9c6b3d14be58f27674f8c95",
          "name": "Neo Tokyo",
          "symbol": "BYTES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28858/thumb/bytes.png?1674975330"
        },
        {
          "chainId": 1,
          "address": "0x6e9730ecffbed43fd876a264c982e254ef05a0de",
          "name": "Nord Finance",
          "symbol": "NORD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136"
        },
        {
          "chainId": 1,
          "address": "0xfe98c2b85caafe841ca7fa8b3b912a178a3f68ed",
          "name": "Red Rabbit",
          "symbol": "RR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28976/thumb/rr1.png?1675750430"
        },
        {
          "chainId": 1,
          "address": "0xb37ea37de1075b7d2f6b64e768917b174639a59c",
          "name": "AggrX",
          "symbol": "AGGRX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31205/thumb/0xb37ea37de1075b7d2f6b64e768917b174639a59c.png?1691381183"
        },
        {
          "chainId": 1,
          "address": "0x39d30828a163713d91c4eadbba2c497a9139ec5c",
          "name": "Happy Birthday Coin",
          "symbol": "HBDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12814/thumb/%EC%A7%84%ED%92%88.png?1630840854"
        },
        {
          "chainId": 1,
          "address": "0x76960dccd5a1fe799f7c29be9f19ceb4627aeb2f",
          "name": "Red",
          "symbol": "RED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4523/thumb/red.png?1548607994"
        },
        {
          "chainId": 1,
          "address": "0xf07ebf00aa847bbb0057b92cb469747e2c7c8a4f",
          "name": "Echoes",
          "symbol": "ECHOES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30994/thumb/logosmallcircle.png?1689568348"
        },
        {
          "chainId": 1,
          "address": "0x00a8b738e453ffd858a7edf03bccfe20412f0eb0",
          "name": "AllianceBlock",
          "symbol": "ALBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12392/thumb/alliance_block_logo.jpg?1599546617"
        },
        {
          "chainId": 1,
          "address": "0xaea46a60368a7bd060eec7df8cba43b7ef41ad85",
          "name": "Fetch ai",
          "symbol": "FET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136"
        },
        {
          "chainId": 1,
          "address": "0x5d3a536e4d6dbd6114cc1ead35777bab948e3643",
          "name": "cDAI",
          "symbol": "CDAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9281/thumb/cDAI.png?1576467585"
        },
        {
          "chainId": 1,
          "address": "0xc1322d8ae3b0e2e437e0ae36388d0cfd2c02f1c9",
          "name": "DAO PlayMarket 2 0",
          "symbol": "PMT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/1514/thumb/pmt.png?1547035648"
        },
        {
          "chainId": 1,
          "address": "0x205ed31c867bf715e4182137af95afe9177cd8e7",
          "name": "DEFY",
          "symbol": "DEFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26877/thumb/8ybr83fb0ca45cm1yvrcaclwbvcp.jpeg?1660622066"
        },
        {
          "chainId": 1,
          "address": "0x80f0c1c49891dcfdd40b6e0f960f84e6042bcb6f",
          "name": "DBXen",
          "symbol": "DXN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29838/thumb/DBXen.jpg?1681718763"
        },
        {
          "chainId": 1,
          "address": "0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec",
          "name": "Relevant",
          "symbol": "REL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11586/thumb/Relevant.png?1591390081"
        },
        {
          "chainId": 1,
          "address": "0x9a6aea7c2b0d563eab701e3255110d30f08b5a64",
          "name": "Metapolitans",
          "symbol": "MAPS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29933/thumb/Logo.png?1682142096"
        },
        {
          "chainId": 1,
          "address": "0x370adc71f67f581158dc56f539df5f399128ddf9",
          "name": "Aave AMM UniMKRWETH",
          "symbol": "AAMMUNIMKRWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17222/thumb/aAmmUniMKRWETH.png?1626880292"
        },
        {
          "chainId": 1,
          "address": "0xd2bdaaf2b9cc6981fd273dcb7c04023bfbe0a7fe",
          "name": "Aviator",
          "symbol": "AVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31024/thumb/Aviator-Icon_200x200.png?1689836643"
        },
        {
          "chainId": 1,
          "address": "0x2f573070e6090b3264fe707e2c9f201716f123c7",
          "name": "Mumu",
          "symbol": "MUMU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29921/thumb/Mumucoin-200x200.png?1682051838"
        },
        {
          "chainId": 1,
          "address": "0x0b5326da634f9270fb84481dd6f94d3dc2ca7096",
          "name": "Etho Protocol",
          "symbol": "ETHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png?1578298993"
        },
        {
          "chainId": 1,
          "address": "0x2dd7144b701bd66bc12ec17f1afaad409f0e32cf",
          "name": "Poope",
          "symbol": "POOPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30685/thumb/smilepoopecoinf.png?1686273494"
        },
        {
          "chainId": 1,
          "address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
          "name": "Maker",
          "symbol": "MKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1364/thumb/Mark_Maker.png?1585191826"
        },
        {
          "chainId": 1,
          "address": "0xefd113cea2f656fe7899a25a06243a6e40406e08",
          "name": "THE9",
          "symbol": "THE9",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29357/thumb/THE9_logo.png?1678263479"
        },
        {
          "chainId": 1,
          "address": "0xa3c31927a092bd54eb9a0b5dfe01d9db5028bd4f",
          "name": "Espresso Bot",
          "symbol": "ESPR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31091/thumb/Espresso.png?1690448832"
        },
        {
          "chainId": 1,
          "address": "0xc6e145421fd494b26dcf2bfeb1b02b7c5721978f",
          "name": "Crypto Perx",
          "symbol": "CPRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20768/thumb/8iQSd5YY_400x400.jpg?1637655996"
        },
        {
          "chainId": 1,
          "address": "0x77a9b858aa8417fd318ecee01c1d6d9fef31a71f",
          "name": "Definet",
          "symbol": "NET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29349/thumb/Definet.jpeg?1678261653"
        },
        {
          "chainId": 1,
          "address": "0xbf9e72eeb5adb8b558334c8672950b7a379d4266",
          "name": "CubToken",
          "symbol": "CUBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26197/thumb/photo-2022-04-20-13-35-28.jpg?1656471496"
        },
        {
          "chainId": 1,
          "address": "0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41",
          "name": "Props",
          "symbol": "PROPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6735/thumb/photo-2017-10-10-03-32-02.png?1595168186"
        },
        {
          "chainId": 1,
          "address": "0x671a912c10bba0cfa74cfc2d6fba9ba1ed9530b2",
          "name": "LINK yVault",
          "symbol": "YVLINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28791/thumb/yvLINK-128-0x671a912C10bba0CFA74Cfc2d6Fba9BA1ed9530B2.png?1674225194"
        },
        {
          "chainId": 1,
          "address": "0x569424c5ee13884a193773fdc5d1c5f79c443a51",
          "name": "Pine",
          "symbol": "PINE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25660/thumb/Logomark-Colour.png?1676282846"
        },
        {
          "chainId": 1,
          "address": "0x2b89bf8ba858cd2fcee1fada378d5cd6936968be",
          "name": "Secret  ERC20 ",
          "symbol": "WSCRT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13767/thumb/Secret_S_Black_Coingecko.png?1611667298"
        },
        {
          "chainId": 1,
          "address": "0x84c722e6f1363e8d5c6db3ea600bef9a006da824",
          "name": "Misbloc",
          "symbol": "MSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13021/thumb/xREV4lXV.png?1604396822"
        },
        {
          "chainId": 1,
          "address": "0x903ff0ba636e32de1767a4b5eeb55c155763d8b7",
          "name": "None Trading",
          "symbol": "NONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31137/thumb/None-200x200.png?1690952135"
        },
        {
          "chainId": 1,
          "address": "0x318ee488af8881f9945b6d3d69e8b395fb559bb1",
          "name": "Monte",
          "symbol": "MONTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29909/thumb/tPZ4SdnV_400x400.jpg?1681988471"
        },
        {
          "chainId": 1,
          "address": "0x4db57d585fa82ca32d25086ddc069d899f08d455",
          "name": "Enoch",
          "symbol": "ENOCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30810/thumb/20230619_001351.png?1687419457"
        },
        {
          "chainId": 1,
          "address": "0xfc82bb4ba86045af6f327323a46e80412b91b27d",
          "name": "Prom",
          "symbol": "PROM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8825/thumb/Ticker.png?1657632943"
        },
        {
          "chainId": 1,
          "address": "0x721a1b990699ee9d90b6327faad0a3e840ae8335",
          "name": "Lootex",
          "symbol": "LOOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22895/thumb/loot.png?1642850037"
        },
        {
          "chainId": 1,
          "address": "0x4e51a6b3cc6d5ae69a0d44db9de846aeb5a582dd",
          "name": "Gyoza",
          "symbol": "GYOZA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31083/thumb/logorecized_new.png?1690356726"
        },
        {
          "chainId": 1,
          "address": "0x1571ed0bed4d987fe2b498ddbae7dfa19519f651",
          "name": "iFARM",
          "symbol": "IFARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14472/thumb/ifarm.png?1616389133"
        },
        {
          "chainId": 1,
          "address": "0x23352036e911a22cfc692b5e2e196692658aded9",
          "name": "Friendz",
          "symbol": "FDZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3685/thumb/download_%2812%29.png?1547038682"
        },
        {
          "chainId": 1,
          "address": "0x60eb57d085c59932d5faa6c6026268a4386927d0",
          "name": "LOCG",
          "symbol": "LOCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15175/thumb/LOC_GAME.png?1620025060"
        },
        {
          "chainId": 1,
          "address": "0xb7bda6a89e724f63572ce68fddc1a6d1d5d24bcf",
          "name": "OGzClub",
          "symbol": "OGZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31121/thumb/25832.png?1690772601"
        },
        {
          "chainId": 1,
          "address": "0x4092678e4e78230f46a1534c0fbc8fa39780892b",
          "name": "Odyssey",
          "symbol": "OCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2559/thumb/ocn.png?1547036683"
        },
        {
          "chainId": 1,
          "address": "0xa92c49c403386111c1629aee00936eed2a9e74a6",
          "name": "Kollector",
          "symbol": "KLTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19926/thumb/kbase_logo.jpg?1636302934"
        },
        {
          "chainId": 1,
          "address": "0x25e1474170c4c0aa64fa98123bdc8db49d7802fa",
          "name": "Bidao",
          "symbol": "BID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12596/thumb/bidao.png?1600996485"
        },
        {
          "chainId": 1,
          "address": "0x9a2af0abb12bee5369b180976be01e8c80d0e7b6",
          "name": "Empire",
          "symbol": "EMPIRE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16390/thumb/pb_ranDy_400x400.png?1623897690"
        },
        {
          "chainId": 1,
          "address": "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
          "name": "MATH",
          "symbol": "MATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590"
        },
        {
          "chainId": 1,
          "address": "0xdf290b162a7d3e0a328cf198308d421954f08b94",
          "name": "Beyond Protocol",
          "symbol": "BP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4732/thumb/b-logo-200x200.png?1633423345"
        },
        {
          "chainId": 1,
          "address": "0xfb130d93e49dca13264344966a611dc79a456bc5",
          "name": "DogeGF",
          "symbol": "DOGEGF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18651/thumb/dogf.png?1632758659"
        },
        {
          "chainId": 1,
          "address": "0x916c5de09cf63f6602d1e1793fb41f6437814a62",
          "name": "JACY",
          "symbol": "JACY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19740/thumb/tBwbvjZK_400x400.jpg?1641803022"
        },
        {
          "chainId": 1,
          "address": "0x88d50b466be55222019d71f9e8fae17f5f45fca1",
          "name": "Cryptaur",
          "symbol": "CPT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2481/thumb/n3w6Z9xW_400x400.jpg?1548224365"
        },
        {
          "chainId": 1,
          "address": "0x21d5678a62dfe63a47062469ebb2fac2817d8832",
          "name": "YOLOCash",
          "symbol": "YLC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4368/thumb/received_2320303067998561.png?1547039717"
        },
        {
          "chainId": 1,
          "address": "0xa203eb78fee91c8459c6d4ef3a899d8724ee5b35",
          "name": "Liquid Mercury",
          "symbol": "MERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29297/thumb/cda56b6a-146c-4348-b4ff-5b87980c0f83.jpg?1677914858"
        },
        {
          "chainId": 1,
          "address": "0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559",
          "name": "EDEN",
          "symbol": "EDEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17470/thumb/Iyc0XM5-_400x400.jpg?1628254655"
        },
        {
          "chainId": 1,
          "address": "0xe23faefcdaae646527eb86b1c746a25ae4a36f75",
          "name": "WGMI",
          "symbol": "WGMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20330/thumb/new_WGMI_logo-01.png?1650534006"
        },
        {
          "chainId": 1,
          "address": "0x9681ee0d91e737c3b60aceba7fbdae61b5462f42",
          "name": "Crypto Carbon Energy",
          "symbol": "CYCE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29824/thumb/CYCE.jpg?1681461094"
        },
        {
          "chainId": 1,
          "address": "0xe9b7b5d5e8d2bcc78884f9f9099bfa42a9e5c1a5",
          "name": "Zenland",
          "symbol": "ZENF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29477/thumb/tokenicon200px.png?1679130231"
        },
        {
          "chainId": 1,
          "address": "0x7a3f7f6675514d4d611b442a4b76752f6ab77670",
          "name": "Tora Inu",
          "symbol": "TORA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28531/thumb/z9b6m1yS_400x400.png?1671438895"
        },
        {
          "chainId": 1,
          "address": "0xb6ed7644c69416d67b522e20bc294a9a9b405b31",
          "name": "0xBitcoin",
          "symbol": "0XBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4454/thumb/0xbtc.png?1561603765"
        },
        {
          "chainId": 1,
          "address": "0x2c33b28527a63cdf13c0b24ce4cf5bf9c9fb3bc6",
          "name": "Schrodinger",
          "symbol": "KITTYDINGER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19649/thumb/kitty_dinger.png?1636332557"
        },
        {
          "chainId": 1,
          "address": "0x0b63128c40737b13647552e0c926bcfeccc35f93",
          "name": "wLITI",
          "symbol": "WLITI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16810/thumb/liticapital_real.png?1625151260"
        },
        {
          "chainId": 1,
          "address": "0x03042482d64577a7bdb282260e2ea4c8a89c064b",
          "name": "Centaur",
          "symbol": "CNTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12743/thumb/logo_%2898%29.png?1602630445"
        },
        {
          "chainId": 1,
          "address": "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
          "name": "PAWZONE",
          "symbol": "PAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29598/thumb/PAW_Token_Logo.png?1679966715"
        },
        {
          "chainId": 1,
          "address": "0x374fb05c96c36348b92e38fb088b26b8511e3b3d",
          "name": "CROWD",
          "symbol": "CWD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24125/thumb/cwd.png?1646463219"
        },
        {
          "chainId": 1,
          "address": "0x8ae452d9f8f08f21ff81c94260cb85302a31ac30",
          "name": "XFL",
          "symbol": "XFL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30617/thumb/flowLogoSquare.png?1685771989"
        },
        {
          "chainId": 1,
          "address": "0x848578e351d25b6ec0d486e42677891521c3d743",
          "name": "moSOLID",
          "symbol": "MOSOLID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28677/thumb/black_mono_logopng.png?1673227338"
        },
        {
          "chainId": 1,
          "address": "0x71de2285ec83bb6517d2b3ec8deb2a6c44d8c5da",
          "name": "Unimoon",
          "symbol": "UMOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29489/thumb/unimoon-logo-final_%282%29_%281%29.png?1679214932"
        },
        {
          "chainId": 1,
          "address": "0x56c9d5f1e727de03643af220b5ce52de23d4d973",
          "name": "Decentra Box",
          "symbol": "DBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29513/thumb/decentrabox.png?1679385381"
        },
        {
          "chainId": 1,
          "address": "0x7cc97bf17c5adabe25f9d19d15a1ec8a1ad65f14",
          "name": "Wolverinu",
          "symbol": "WOLVERINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27872/thumb/LOGO3232.jpg?1666166703"
        },
        {
          "chainId": 1,
          "address": "0xc9bc48c72154ef3e5425641a3c747242112a46af",
          "name": "Aave RAI",
          "symbol": "ARAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17245/thumb/aRAI_2x.png?1626942652"
        },
        {
          "chainId": 1,
          "address": "0xb59490ab09a0f526cc7305822ac65f2ab12f9723",
          "name": "Litentry",
          "symbol": "LIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13825/thumb/logo_200x200.png?1612153317"
        },
        {
          "chainId": 1,
          "address": "0xd3e133a0a14bb8b595e5fbf9851c7c783685ba69",
          "name": "Locker Token",
          "symbol": "LKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28269/thumb/Locker.jpeg?1668995910"
        },
        {
          "chainId": 1,
          "address": "0x340d2bde5eb28c1eed91b2f790723e3b160613b7",
          "name": "BLOCKv",
          "symbol": "VEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1266/thumb/blockv.png?1547351380"
        },
        {
          "chainId": 1,
          "address": "0x44108f0223a3c3028f5fe7aec7f9bb2e66bef82f",
          "name": "Across Protocol",
          "symbol": "ACX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28161/thumb/across-200x200.png?1668168201"
        },
        {
          "chainId": 1,
          "address": "0x5d43b66da68706d39f6c97f7f1415615672b446b",
          "name": "ROGin AI",
          "symbol": "ROG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25922/thumb/20490.png?1654672163"
        },
        {
          "chainId": 1,
          "address": "0xa00425d3e2d3e9ff74f3e112b4d3a7978d7d88c2",
          "name": "GoPower",
          "symbol": "GPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2991/thumb/82EMDIqURn_Y_O021ulWgQ.png?1547037256"
        },
        {
          "chainId": 1,
          "address": "0x4ad0b81f92b16624bbcf46fc0030cfbbf8d02376",
          "name": "Unagii Dai",
          "symbol": "UDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13782/thumb/uDAI.png?1611730113"
        },
        {
          "chainId": 1,
          "address": "0x9ad37205d608b8b219e6a2573f922094cec5c200",
          "name": "iZUMi Finance",
          "symbol": "IZI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21791/thumb/izumi-logo-symbol.png?1640053924"
        },
        {
          "chainId": 1,
          "address": "0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe",
          "name": "Stably USDS",
          "symbol": "USDS",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/7596/thumb/Stably_USDS_-_Icon_%28Standard%29.png?1663824111"
        },
        {
          "chainId": 1,
          "address": "0xc5253c32bb72921b77ae18cf329dad5e6c3de5a4",
          "name": "NOBI",
          "symbol": "NOBI",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x9196e18bc349b1f64bc08784eae259525329a1ad",
          "name": "Pussy Financial",
          "symbol": "PUSSY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339"
        },
        {
          "chainId": 1,
          "address": "0xe7976c4efc60d9f4c200cc1bcef1a1e3b02c73e7",
          "name": "MAX",
          "symbol": "MAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6454/thumb/MAX_Token.jpg?1547042651"
        },
        {
          "chainId": 1,
          "address": "0x7707aada3ce7722ac63b91727daf1999849f6835",
          "name": "Bankera",
          "symbol": "BNK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1949/thumb/bankera.png?1650883375"
        },
        {
          "chainId": 1,
          "address": "0x39142c18b6db2a8a41b7018f49e1478837560cad",
          "name": "STATS",
          "symbol": "STATS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28853/thumb/stats.png?1674898914"
        },
        {
          "chainId": 1,
          "address": "0x348b7f3106b5da47405332534d06069ff9ce4d1b",
          "name": "Elongate Deluxe",
          "symbol": "ELONGD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15487/thumb/tT2g66pd.jpg?1623764695"
        },
        {
          "chainId": 1,
          "address": "0x0888fd2960ddf57cfb561b59d73dd2b9524f6a26",
          "name": "Mikawa Inu",
          "symbol": "MIKAWA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29168/thumb/IMG_20230215_172257_635.png?1677048330"
        },
        {
          "chainId": 1,
          "address": "0x99b600d0a4abdbc4a6796225a160bcf3d5ce2a89",
          "name": "Solareum",
          "symbol": "SRM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30072/thumb/green_lrg_transparent.png?1690864723"
        },
        {
          "chainId": 1,
          "address": "0xca5b0ae1d104030a9b8f879523508efd86c14483",
          "name": "Benji Bananas",
          "symbol": "BENJI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31022/thumb/BenjiTokens_FinalFlat.png?1689835610"
        },
        {
          "chainId": 1,
          "address": "0x53263d9ef74db583b15fbc6d5d4e8b83833fa134",
          "name": "LEAP Token",
          "symbol": "LEAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png?1663053803"
        },
        {
          "chainId": 1,
          "address": "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
          "name": "Dypius",
          "symbol": "DYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13480/thumb/DYP-200x200px.png?1670915896"
        },
        {
          "chainId": 1,
          "address": "0xf720e38f678b29b243f7d53b56acbf5de98f2385",
          "name": "Upfire",
          "symbol": "UPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png?1637214757"
        },
        {
          "chainId": 1,
          "address": "0xbd15ad921e1b480209af549874a2fcb80dc312bf",
          "name": "Harpoon",
          "symbol": "HRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29889/thumb/Harpoon.jpeg?1681981394"
        },
        {
          "chainId": 1,
          "address": "0x5150956e082c748ca837a5dfa0a7c10ca4697f9c",
          "name": "Zeedex",
          "symbol": "ZDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12412/thumb/Untitled-design-4.png?1599647173"
        },
        {
          "chainId": 1,
          "address": "0x14cc8dfaf2258e1b8b2869300dba1b734dc0fe43",
          "name": "K Tune",
          "symbol": "KTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13939/thumb/KakaoTalk_20220928_162525453.png?1664356062"
        },
        {
          "chainId": 1,
          "address": "0x875773784af8135ea0ef43b5a374aad105c5d39e",
          "name": "IDLE",
          "symbol": "IDLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13286/thumb/image.png?1655284075"
        },
        {
          "chainId": 1,
          "address": "0xfe9a29ab92522d14fc65880d817214261d8479ae",
          "name": "Snowswap",
          "symbol": "SNOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12751/thumb/uQBJL3A.png?1602237225"
        },
        {
          "chainId": 1,
          "address": "0x0913ddae242839f8995c0375493f9a1a3bddc977",
          "name": "Marshall Inu",
          "symbol": "MRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23784/thumb/mri.png?1647693409"
        },
        {
          "chainId": 1,
          "address": "0xeaf61fc150cd5c3bea75744e830d916e60ea5a9f",
          "name": "Typerium",
          "symbol": "TYPE",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/4019/thumb/type.png?1547039124"
        },
        {
          "chainId": 1,
          "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
          "name": "Uniswap",
          "symbol": "UNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12504/thumb/uni.jpg?1687143398"
        },
        {
          "chainId": 1,
          "address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
          "name": "WOO Network",
          "symbol": "WOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367"
        },
        {
          "chainId": 1,
          "address": "0x40803cea2b2a32bda1be61d3604af6a814e70976",
          "name": "Spool",
          "symbol": "SPOOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21532/thumb/spool.png?1639388809"
        },
        {
          "chainId": 1,
          "address": "0xae37d54ae477268b9997d4161b96b8200755935c",
          "name": "Balancer Boosted Aave DAI",
          "symbol": "BB-A-DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25883/thumb/bb-a-dai.png?1654347111"
        },
        {
          "chainId": 1,
          "address": "0x320623b8e4ff03373931769a31fc52a4e78b5d70",
          "name": "Reserve Rights",
          "symbol": "RSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8365/thumb/rsr.png?1637983320"
        },
        {
          "chainId": 1,
          "address": "0x651e44d0df89055c00513d0efb9e8d1a0c843378",
          "name": "HappyBear",
          "symbol": "HAPPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29029/thumb/HappyCG.png?1676106875"
        },
        {
          "chainId": 1,
          "address": "0x4d953cf077c0c95ba090226e59a18fcf97db44ec",
          "name": "Mini",
          "symbol": "MINI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12298/thumb/IrTAVc_GqZ7iQucAa3fNYlh_Cqt3tY9wM_pmzOl5SifscRMpuzrp_dizMzGTiMr_NxDJPCKigBgz8THrzvO_DqT3JLzqZIYeytDBRw3qKI73dljS0BnFaaI2aLadpdCZah4RkhydddLIDDbQlGit77farlQRaq7qEgxdjVe0aqEeh4phE-DWAKi_KS_Yz-fFdDfjWgifVCKkZRBeNSWWQEplxxl.jpg?1598961320"
        },
        {
          "chainId": 1,
          "address": "0x4674672bcddda2ea5300f5207e1158185c944bc0",
          "name": "Gem Exchange and Trading",
          "symbol": "GXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12226/thumb/a.png?1653378779"
        },
        {
          "chainId": 1,
          "address": "0x62406995cafd18f57e7375e8e0060725acebce58",
          "name": "Firulais Wallet",
          "symbol": "FIWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20716/thumb/FIWT-Logo.png?1637584712"
        },
        {
          "chainId": 1,
          "address": "0x40955d77f87123b71b145098358a60573ac7be96",
          "name": "Daisy Protocol",
          "symbol": "DAISY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17844/thumb/daisy.PNG?1629497116"
        },
        {
          "chainId": 1,
          "address": "0xf6c0aa7ebfe9992200c67e5388e4f42da49e1783",
          "name": "Psyche",
          "symbol": "USD1",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/13661/thumb/Psyche-logo-256.png?1610600682"
        },
        {
          "chainId": 1,
          "address": "0x13dbd5394c2c7e4bdb85b1838286faa66532a262",
          "name": "Sun Tzu",
          "symbol": "TZU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30689/thumb/TZU_CG.png?1686278367"
        },
        {
          "chainId": 1,
          "address": "0x8185bc4757572da2a610f887561c32298f1a5748",
          "name": "Aluna",
          "symbol": "ALN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106"
        },
        {
          "chainId": 1,
          "address": "0x5de597849cf72c72f073e9085bdd0dadd8e6c199",
          "name": "Finblox",
          "symbol": "FBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27087/thumb/fbx.png?1661774906"
        },
        {
          "chainId": 1,
          "address": "0x644192291cc835a93d6330b24ea5f5fedd0eef9e",
          "name": "AllianceBlock Nexera",
          "symbol": "NXRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29181/thumb/nxra.png?1677148772"
        },
        {
          "chainId": 1,
          "address": "0x01d33fd36ec67c6ada32cf36b31e88ee190b1839",
          "name": "Brazilian Digital",
          "symbol": "BRZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8472/thumb/MicrosoftTeams-image_%286%29.png?1674480131"
        },
        {
          "chainId": 1,
          "address": "0xd1b5651e55d4ceed36251c61c50c889b36f6abb5",
          "name": "Stake DAO CRV",
          "symbol": "SDCRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27756/thumb/scCRV-2.png?1665654580"
        },
        {
          "chainId": 1,
          "address": "0x69bbc3f8787d573f1bbdd0a5f40c7ba0aee9bcc9",
          "name": "Yup",
          "symbol": "YUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12322/thumb/photo_2021-10-26_00-47-35.jpg?1635227479"
        },
        {
          "chainId": 1,
          "address": "0x1e2f15302b90edde696593607b6bd444b64e8f02",
          "name": "Shiryo",
          "symbol": "SHIRYO-INU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19652/thumb/shiryo_inu.png?1648267828"
        },
        {
          "chainId": 1,
          "address": "0xed3d4e446a96dc3b181b64b75c3c70da41dc3cbe",
          "name": "Vodra",
          "symbol": "VDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19338/thumb/vdr.png?1635832496"
        },
        {
          "chainId": 1,
          "address": "0x7e85b369d170ebf127367400b1f399cb18027309",
          "name": "JUSTANEGG",
          "symbol": "EGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30461/thumb/logo.png?1684986356"
        },
        {
          "chainId": 1,
          "address": "0x006bea43baa3f7a6f765f14f10a1a1b08334ef45",
          "name": "Stox",
          "symbol": "STX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256"
        },
        {
          "chainId": 1,
          "address": "0xff030228a046f640143dab19be00009606c89b1d",
          "name": "Auxo",
          "symbol": "AUXO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30675/thumb/AUXO_Logo.png?1686212261"
        },
        {
          "chainId": 1,
          "address": "0x5919dea604631016c15c805e3d948a0384879892",
          "name": "The People s Coin",
          "symbol": "PEEP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16777/thumb/peeps.png?1679504939"
        },
        {
          "chainId": 1,
          "address": "0x06874f973dc3c96dc22a10ef0d0609f877f335ea",
          "name": "STOA Network",
          "symbol": "STA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16710/thumb/RSloE6X1_400x400.jpeg?1624684021"
        },
        {
          "chainId": 1,
          "address": "0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc",
          "name": "Kounotori",
          "symbol": "KTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21251/thumb/KTO.png?1650528876"
        },
        {
          "chainId": 1,
          "address": "0xca0e7269600d353f70b14ad118a49575455c0f2f",
          "name": "AMLT Network",
          "symbol": "AMLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2703/thumb/amlt.png?1563794756"
        },
        {
          "chainId": 1,
          "address": "0xb208b1a5664574f72d22e9e9981837310cacfed6",
          "name": "Blossom",
          "symbol": "SAKURA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28640/thumb/photo_2023-01-06_16.00.02.jpeg?1673072843"
        },
        {
          "chainId": 1,
          "address": "0x4156d3342d5c385a87d264f90653733592000581",
          "name": "SALT",
          "symbol": "SALT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/962/thumb/salt.png?1548608746"
        },
        {
          "chainId": 1,
          "address": "0x0ad896863ce4cd84f10a9d30d4f509ceffd53c84",
          "name": "Chocolate Like Butterfly",
          "symbol": "CLB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28859/thumb/clb.png?1674978770"
        },
        {
          "chainId": 1,
          "address": "0xd15a1a2a3211b58113e45809f05934252e34e2f8",
          "name": "Woozoo Music",
          "symbol": "WZM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21891/thumb/logo_200_200_%281%29.png?1640228330"
        },
        {
          "chainId": 1,
          "address": "0xe80c0cd204d654cebe8dd64a4857cab6be8345a3",
          "name": "JPEG d",
          "symbol": "JPEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24025/thumb/et_43CNi_400x400.jpg?1646100264"
        },
        {
          "chainId": 1,
          "address": "0x8954d907520532c1f0d89d42569232fd0f995fdf",
          "name": "Tradix",
          "symbol": "TX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28997/thumb/Tradix_Logo.png?1675836608"
        },
        {
          "chainId": 1,
          "address": "0xf45f6c8bb3d77ea762175b8f7ca4d251941649fa",
          "name": "Lemond",
          "symbol": "LEMD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14964/thumb/D-smP_i-_400x400.png?1619166250"
        },
        {
          "chainId": 1,
          "address": "0x807534b396919783b7e30383fe57d857bc084338",
          "name": "Test",
          "symbol": "TEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31188/thumb/IMG_6480.png?1691142715"
        },
        {
          "chainId": 1,
          "address": "0x0a44a7ccea34a7563ba1d45a5f757d0b02281124",
          "name": "BlockBlend",
          "symbol": "BBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30680/thumb/mdWl1HlW_400x400.jpg?1686227454"
        },
        {
          "chainId": 1,
          "address": "0x2a9bdcff37ab68b95a53435adfd8892e86084f93",
          "name": "Alpha Quark",
          "symbol": "AQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12872/thumb/alpha_quark_logo.png?1605169527"
        },
        {
          "chainId": 1,
          "address": "0xc96df921009b790dffca412375251ed1a2b75c60",
          "name": "Ormeus Coin",
          "symbol": "ORME",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243"
        },
        {
          "chainId": 1,
          "address": "0x2fd61567c29e7adb4ca17e60e1f4a3fcfe68acb8",
          "name": "SymVerse",
          "symbol": "SYM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9449/thumb/Picture1.png?1567551375"
        },
        {
          "chainId": 1,
          "address": "0x9813037ee2218799597d83d4a5b6f3b6778218d9",
          "name": "Bone ShibaSwap",
          "symbol": "BONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16916/thumb/bone_icon.png?1625625505"
        },
        {
          "chainId": 1,
          "address": "0x6aa40d02115090d40dc33c7c5f3cf05112fa4f83",
          "name": "PWRCASH",
          "symbol": "PWRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29571/thumb/our_logo.png?1679738240"
        },
        {
          "chainId": 1,
          "address": "0xf21661d0d1d76d3ecb8e1b9f1c923dbfffae4097",
          "name": "Realio",
          "symbol": "RIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12206/thumb/Rio.png?1687312076"
        },
        {
          "chainId": 1,
          "address": "0x4fd5b9b5dcc9a5d5931d007ba4ae573e760d9b64",
          "name": "MO",
          "symbol": "MO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12961/thumb/Group-9.png?1649143210"
        },
        {
          "chainId": 1,
          "address": "0xb90cb79b72eb10c39cbdf86e50b1c89f6a235f2e",
          "name": "Auditchain",
          "symbol": "AUDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6398/thumb/auditchain.png?1547042519"
        },
        {
          "chainId": 1,
          "address": "0xd5a98e77d1feb091344096301ea336a5c07a6a41",
          "name": "Alpha Capital",
          "symbol": "ACAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24044/thumb/gfSKeO3.png?1646289796"
        },
        {
          "chainId": 1,
          "address": "0x33d203fa03bb30b133de0fe2d6533c268ba286b6",
          "name": "MandoX",
          "symbol": "MANDOX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24968/thumb/NmqN8g7.png?1649917431"
        },
        {
          "chainId": 1,
          "address": "0x28dee01d53fed0edf5f6e310bf8ef9311513ae40",
          "name": "BlitzPick",
          "symbol": "XBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2458/thumb/BlitzPredict.jpg?1547701183"
        },
        {
          "chainId": 1,
          "address": "0x8207c1ffc5b6804f6024322ccf34f29c3541ae26",
          "name": "Origin Protocol",
          "symbol": "OGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878"
        },
        {
          "chainId": 1,
          "address": "0x5543862ba50b6bbc198222a34d30e93a62adce24",
          "name": "Build With Pepe",
          "symbol": "BWP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31057/thumb/bwp_logo.png?1689926323"
        },
        {
          "chainId": 1,
          "address": "0xae0585a259a3bcab258d6ee02fb583f7b33c2a12",
          "name": "TemDAO",
          "symbol": "TEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28617/thumb/77163809-BDA6-4C9C-8387-4DBB33F06265.jpeg?1672558764"
        },
        {
          "chainId": 1,
          "address": "0x71fc860f7d3a592a4a98740e39db31d25db65ae8",
          "name": "Aave USDT v1",
          "symbol": "AUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/11725/thumb/aUSDT.png?1593082796"
        },
        {
          "chainId": 1,
          "address": "0xdf49c9f599a0a9049d97cff34d0c30e468987389",
          "name": "SaTT",
          "symbol": "SATT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4070/thumb/r3s9XKk.png?1623046839"
        },
        {
          "chainId": 1,
          "address": "0xa4cb3ef5f41a4d89d6fced22ea8a1c57957629aa",
          "name": "MetaRuffy  MR ",
          "symbol": "MR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27831/thumb/mr_logo_2023_200_200.png?1678774149"
        },
        {
          "chainId": 1,
          "address": "0x6eff556748ee452cbdaf31bcb8c76a28651509bd",
          "name": "TiUSD",
          "symbol": "TIUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30697/thumb/TIUDS.png?1686540260"
        },
        {
          "chainId": 1,
          "address": "0x7ca5af5ba3472af6049f63c1abc324475d44efc1",
          "name": "KONDUX",
          "symbol": "KNDX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28244/thumb/kndx.png?1668587937"
        },
        {
          "chainId": 1,
          "address": "0xb8647e90c0645152fccf4d9abb6b59eb4aa99052",
          "name": "KeyFi",
          "symbol": "KEYFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054"
        },
        {
          "chainId": 1,
          "address": "0xe11ba472f74869176652c35d30db89854b5ae84d",
          "name": "HEGIC yVault",
          "symbol": "YVHEGIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28798/thumb/yvHEGIC-128-0xe11ba472F74869176652C35D30dB89854b5ae84D.png?1674226668"
        },
        {
          "chainId": 1,
          "address": "0x943ed852dadb5c3938ecdc6883718df8142de4c8",
          "name": "FansTime",
          "symbol": "FTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4952/thumb/fanstime.png?1547395397"
        },
        {
          "chainId": 1,
          "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
          "name": "Lever Network",
          "symbol": "LEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15323/thumb/lever.PNG?1620513101"
        },
        {
          "chainId": 1,
          "address": "0x0a13a5929e5f0ff0eaba4bd9e9512c91fce40280",
          "name": "XAI Corp",
          "symbol": "XAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30992/thumb/XAILOGO_%281%29.png?1689845669"
        },
        {
          "chainId": 1,
          "address": "0x32c4adb9cf57f972bc375129de91c897b4f364f1",
          "name": "Flowchain",
          "symbol": "FLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7446/thumb/logo_%2889%29.png?1597459811"
        },
        {
          "chainId": 1,
          "address": "0x40370aed88933021e20cf7c4d67e00417cda2202",
          "name": "Xave",
          "symbol": "XAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28299/thumb/Xave_Logomark_%28Color%29_%281%29.png?1669265691"
        },
        {
          "chainId": 1,
          "address": "0x9eec1a4814323a7396c938bc86aec46b97f1bd82",
          "name": "Toku",
          "symbol": "TOKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29590/thumb/Toku.jpeg?1679886201"
        },
        {
          "chainId": 1,
          "address": "0x9501bfc48897dceeadf73113ef635d2ff7ee4b97",
          "name": "easyMine",
          "symbol": "EMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1749/thumb/emt.png?1547036034"
        },
        {
          "chainId": 1,
          "address": "0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131",
          "name": "NAGA",
          "symbol": "NGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1439/thumb/naga.jpg?1547035515"
        },
        {
          "chainId": 1,
          "address": "0x965d79f1a1016b574a62986e13ca8ab04dfdd15c",
          "name": "M2",
          "symbol": "M2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18239/thumb/m2_%281%29.png?1631068956"
        },
        {
          "chainId": 1,
          "address": "0x801de502c493aa9d3f9f79a0348fbc00521b3001",
          "name": "Relaxable",
          "symbol": "RELAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28129/thumb/6B1F96A9-E306-4328-862A-FBE9209D1C0D.jpeg?1667649137"
        },
        {
          "chainId": 1,
          "address": "0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c",
          "name": "Utrust",
          "symbol": "UTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1824/thumb/300x300_logo.png?1570520533"
        },
        {
          "chainId": 1,
          "address": "0x51cb253744189f11241becb29bedd3f1b5384fdb",
          "name": "Dimitra",
          "symbol": "DMTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18530/thumb/HqEiru32_400x400.jpg?1632293527"
        },
        {
          "chainId": 1,
          "address": "0x5de8ab7e27f6e7a1fff3e5b337584aa43961beef",
          "name": "SmarDex",
          "symbol": "SDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29470/thumb/SDEX_logo_transparent.png?1690430205"
        },
        {
          "chainId": 1,
          "address": "0xb6c3dc857845a713d3531cea5ac546f6767992f4",
          "name": "Advertise Coin",
          "symbol": "ADCO",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14344/thumb/advertisecoin200x200.png?1615503478"
        },
        {
          "chainId": 1,
          "address": "0xa8b12cc90abf65191532a12bb5394a714a46d358",
          "name": "pBTC35A",
          "symbol": "PBTC35A",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13653/thumb/pBTC35A.png?1610574940"
        },
        {
          "chainId": 1,
          "address": "0x8666cb197af5103f7a3a0295b50efea47f3df78b",
          "name": "Ducks",
          "symbol": "DUCKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30147/thumb/ducks.png?1683510920"
        },
        {
          "chainId": 1,
          "address": "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec",
          "name": "VEMP",
          "symbol": "VEMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18074/thumb/VEMP_Token_200x200.png?1672996797"
        },
        {
          "chainId": 1,
          "address": "0x265f542c1e78068f13d87c6fe0df54f3e9562a48",
          "name": "Proof Of Pepe",
          "symbol": "POP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31000/thumb/ebEOnrjk_400x400.jpg?1689588477"
        },
        {
          "chainId": 1,
          "address": "0xd5281bb2d1ee94866b03a0fccdd4e900c8cb5091",
          "name": "Husky",
          "symbol": "HUSKY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14842/thumb/husky_logo.png?1618634383"
        },
        {
          "chainId": 1,
          "address": "0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2",
          "name": "Drep",
          "symbol": "DREP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445"
        },
        {
          "chainId": 1,
          "address": "0xf79f9020560963422ecc9c0c04d3a21190bbf045",
          "name": "Baby Saitama",
          "symbol": "BABYSAITAMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17584/thumb/babysaitama.png?1635907049"
        },
        {
          "chainId": 1,
          "address": "0x191557728e4d8caa4ac94f86af842148c0fa8f7e",
          "name": "Ormeus Ecosystem",
          "symbol": "ECO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8923/thumb/logo_eco_low.png?1562902804"
        },
        {
          "chainId": 1,
          "address": "0x1f19f83fc9a25f3c861260143e36c17706257986",
          "name": "DAO Invest",
          "symbol": "VEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511"
        },
        {
          "chainId": 1,
          "address": "0x77571a64342667f7818520ef004b2b91f47a266b",
          "name": "SnailMoon",
          "symbol": "SNM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30401/thumb/SnailMoonCoingecko.png?1684371089"
        },
        {
          "chainId": 1,
          "address": "0xb8baa0e4287890a5f79863ab62b7f175cecbd433",
          "name": "Swerve",
          "symbol": "SWRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12361/thumb/swerve.png?1599278316"
        },
        {
          "chainId": 1,
          "address": "0x635d081fd8f6670135d8a3640e2cf78220787d56",
          "name": "Add xyz  NEW ",
          "symbol": "ADD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png?1622444542"
        },
        {
          "chainId": 1,
          "address": "0x97a3bd8a445cc187c6a751f392e15c3b2134d695",
          "name": "Blockster",
          "symbol": "BXR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19857/thumb/bxr-left2.png?1636064209"
        },
        {
          "chainId": 1,
          "address": "0x534d1f5e617e0f72a6b06a04aa599839af776a5e",
          "name": "Blockswap Network",
          "symbol": "BSN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27023/thumb/BSN_brandmark_in_black_circle_preview.png?1661484890"
        },
        {
          "chainId": 1,
          "address": "0x70d2b7c19352bb76e4409858ff5746e500f2b67c",
          "name": "Pawtocol",
          "symbol": "UPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008"
        },
        {
          "chainId": 1,
          "address": "0xe1005bfbbc9a17d5d844c7a4371cbf6b2b387380",
          "name": "GRN Grid",
          "symbol": "G",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26126/thumb/MQrn5TGl_400x400.jpg?1655954068"
        },
        {
          "chainId": 1,
          "address": "0x159751323a9e0415dd3d6d42a1212fe9f4a0848c",
          "name": "Insured Finance",
          "symbol": "INFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13727/thumb/logo_%287%29.png?1611210296"
        },
        {
          "chainId": 1,
          "address": "0xaac41ec512808d64625576eddd580e7ea40ef8b2",
          "name": "Gameswap",
          "symbol": "GSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13026/thumb/gameswap.jpg?1604456704"
        },
        {
          "chainId": 1,
          "address": "0x4f14cdbd815b79e9624121f564f24685c6b1211b",
          "name": "Angry Doge",
          "symbol": "ANFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25921/thumb/angry-doge-anfd.png?1654653373"
        },
        {
          "chainId": 1,
          "address": "0x5dd0d493ea59d512efc13d5c1f9d325775192877",
          "name": "Pusuke Inu",
          "symbol": "PUSUKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28674/thumb/Pusuke_Inu.jpg?1673159823"
        },
        {
          "chainId": 1,
          "address": "0xc8de43bfe33ff496fa14c270d9cb29bda196b9b5",
          "name": "Big Eyes",
          "symbol": "BIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30824/thumb/200-200.jpg?1687759041"
        },
        {
          "chainId": 1,
          "address": "0x12b6893ce26ea6341919fe289212ef77e51688c8",
          "name": "Tamadoge",
          "symbol": "TAMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27501/thumb/CaltNDWu_400x400.jpeg?1664329157"
        },
        {
          "chainId": 1,
          "address": "0x34103e1190b824a44c6a638438d425cba21143ba",
          "name": "BITE",
          "symbol": "BITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29397/thumb/bite.png?1678511016"
        },
        {
          "chainId": 1,
          "address": "0x1045f5ccb01daea4f8eab055f5fcbb7c0e7c89f0",
          "name": "DeFiato",
          "symbol": "DFIAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873"
        },
        {
          "chainId": 1,
          "address": "0xda0c94c73d127ee191955fb46bacd7ff999b2bcd",
          "name": "Construct",
          "symbol": "STANDARD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20119/thumb/construct200px.png?1680074839"
        },
        {
          "chainId": 1,
          "address": "0x96c645d3d3706f793ef52c19bbace441900ed47d",
          "name": "Mt Pelerin Shares",
          "symbol": "MPS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/11471/thumb/MPS.png?1590319120"
        },
        {
          "chainId": 1,
          "address": "0x1f961bceaef8edf6fb2797c0293ffbde3e994614",
          "name": "Dingo",
          "symbol": "DINGO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16315/thumb/tzI4QPI7_400x400.png?1623733248"
        },
        {
          "chainId": 1,
          "address": "0xc4e30d504fe3b18423d82a34e7ebe529a56f89c2",
          "name": "Wojak 2 0 Coin",
          "symbol": "WOJAK20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30979/thumb/Wojak_2.0_Logo.png?1689311871"
        },
        {
          "chainId": 1,
          "address": "0xe8e8486228753e01dbc222da262aa706bd67e601",
          "name": "Arch Ethereum Web3",
          "symbol": "WEB3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26562/thumb/WEB3-TOKEN.png?1658798344"
        },
        {
          "chainId": 1,
          "address": "0xb98d4c97425d9908e66e53a6fdf673acca0be986",
          "name": "Arcblock",
          "symbol": "ABT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2341/thumb/arcblock.png?1547036543"
        },
        {
          "chainId": 1,
          "address": "0x009c43b42aefac590c719e971020575974122803",
          "name": "Bibox",
          "symbol": "BIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1441/thumb/bibox-token.png?1547035519"
        },
        {
          "chainId": 1,
          "address": "0x33bd66c334274989b673a8e8c8d1a3f1b8de5889",
          "name": "hiODBS",
          "symbol": "HIODBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27296/thumb/hiodbs.png?1668579342"
        },
        {
          "chainId": 1,
          "address": "0xe9f84d418b008888a992ff8c6d22389c2c3504e0",
          "name": "Maximus BASE",
          "symbol": "BASE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27683/thumb/IMG_1131.PNG?1665214262"
        },
        {
          "chainId": 1,
          "address": "0x4dd28568d05f09b02220b09c2cb307bfd837cb95",
          "name": "FingerprintsDAO",
          "symbol": "PRINTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18143/thumb/prints.png?1634698868"
        },
        {
          "chainId": 1,
          "address": "0x8c543aed163909142695f2d2acd0d55791a9edb9",
          "name": "Velas",
          "symbol": "VLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9651/thumb/logo_blue.png?1663764088"
        },
        {
          "chainId": 1,
          "address": "0x196c81385bc536467433014042788eb707703934",
          "name": "CryptoTask",
          "symbol": "CTASK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13965/thumb/cryptotask_logo.png?1613372242"
        },
        {
          "chainId": 1,
          "address": "0x8484e645a054586a6d6af60c0ee911d7b5180e64",
          "name": "DYOR",
          "symbol": "DYOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30149/thumb/dyor.jpeg?1683515818"
        },
        {
          "chainId": 1,
          "address": "0xc5068471fe18eda3b362231d01ae30ba6a91ff0d",
          "name": "Cut It Off",
          "symbol": "CUT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30402/thumb/Logo_one.png?1684371195"
        },
        {
          "chainId": 1,
          "address": "0xd98f75b1a3261dab9eed4956c93f33749027a964",
          "name": "Share",
          "symbol": "SHR",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410"
        },
        {
          "chainId": 1,
          "address": "0x028171bca77440897b824ca71d1c56cac55b68a3",
          "name": "Aave DAI",
          "symbol": "ADAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749"
        },
        {
          "chainId": 1,
          "address": "0x32e6c34cd57087abbd59b5a4aecc4cb495924356",
          "name": "BitBase Token",
          "symbol": "BTBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG?1627596633"
        },
        {
          "chainId": 1,
          "address": "0x3597bfd533a99c9aa083587b074434e61eb0a258",
          "name": "Dent",
          "symbol": "DENT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1152/thumb/gLCEA2G.png?1604543239"
        },
        {
          "chainId": 1,
          "address": "0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f",
          "name": "Aave ZRX v1",
          "symbol": "AZRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11735/thumb/aZRX.png?1593085371"
        },
        {
          "chainId": 1,
          "address": "0xedc1004886d010751f74ec0ad223819f9f3b1910",
          "name": "AgriNode",
          "symbol": "AGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28727/thumb/IMG_20230110_070430_486_%281%29.png?1673687293"
        },
        {
          "chainId": 1,
          "address": "0xf17a3fe536f8f7847f1385ec1bc967b2ca9cae8d",
          "name": "Alongside Crypto Market Index",
          "symbol": "AMKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28496/thumb/22999.png?1676455314"
        },
        {
          "chainId": 1,
          "address": "0x39b0e13a29c2a27ce88cebd21262a232b0633613",
          "name": "Grape Governance Token",
          "symbol": "GGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30807/thumb/grape_logo.png?1687416545"
        },
        {
          "chainId": 1,
          "address": "0xfeb6d5238ed8f1d59dcab2db381aa948e625966d",
          "name": "Doge TV",
          "symbol": "DGTV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28050/thumb/532a5b9e-8294-4be1-be18-f04a2cf5f0a9.png?1667280533"
        },
        {
          "chainId": 1,
          "address": "0x6a4c76874e686a7d080d173987a35a9c48905583",
          "name": "Luxurious Pro Network",
          "symbol": "LPNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14126/thumb/logo_%2894%29.png?1614570735"
        },
        {
          "chainId": 1,
          "address": "0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8",
          "name": "Jupiter",
          "symbol": "JUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932"
        },
        {
          "chainId": 1,
          "address": "0xe4d0941ad4ff744948704f7532dc00e01f33ed4d",
          "name": "Momo",
          "symbol": "MOMO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31039/thumb/QmeLzvzLVBt3xzP8maKYoP83zX5WVn5Xg9VtQYtHhcDUAa.png?1689849065"
        },
        {
          "chainId": 1,
          "address": "0x9d86b1b2554ec410eccffbf111a6994910111340",
          "name": "Open Platform",
          "symbol": "OPEN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2953/thumb/open-platform.png?1548330231"
        },
        {
          "chainId": 1,
          "address": "0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43",
          "name": "Fire Protocol",
          "symbol": "FIRE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13495/thumb/fire.jpg?1609165121"
        },
        {
          "chainId": 1,
          "address": "0x2c10c0de3362ff21f8ed6bc7f4ac5e391153fd2c",
          "name": "MARKETVIZ",
          "symbol": "VIZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30083/thumb/CG_Logo.png?1683016920"
        },
        {
          "chainId": 1,
          "address": "0x79be75ffc64dd58e66787e4eae470c8a1fd08ba4",
          "name": "Aave AMM DAI",
          "symbol": "AAMMDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17197/thumb/aAMMDAI_2x.png?1626940032"
        },
        {
          "chainId": 1,
          "address": "0xd2c869382c7ac9f87ff73548d029d67c0f9dee31",
          "name": "Wagie Bot",
          "symbol": "WAGIEBOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30954/thumb/Wagies3%281%29.png?1689216156"
        },
        {
          "chainId": 1,
          "address": "0x069f967be0ca21c7d793d8c343f71e597d9a49b3",
          "name": "HZM Coin",
          "symbol": "HZM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18032/thumb/HZM_new_Logo_PNG_CoinGecko.png?1683714219"
        },
        {
          "chainId": 1,
          "address": "0xe8ff5c9c75deb346acac493c463c8950be03dfba",
          "name": "VIBE",
          "symbol": "VIBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/948/thumb/vibe.png?1547034809"
        },
        {
          "chainId": 1,
          "address": "0x492798fb464e77cb3cda62b9a2c3c65162db198e",
          "name": "AmpliFi DAO",
          "symbol": "AGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29137/thumb/original-icon-transparent.png?1676962770"
        },
        {
          "chainId": 1,
          "address": "0x3f68e7b44e9bcb486c2feadb7a2289d9cdfc9088",
          "name": "SportsIcon",
          "symbol": "ICONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20856/thumb/icons-coin-gold.png?1638349473"
        },
        {
          "chainId": 1,
          "address": "0x65032604dab202aff9adf89300cdb4bd0d059f55",
          "name": "SOBA",
          "symbol": "SOBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14673/thumb/soba-logo-black.png?1617680379"
        },
        {
          "chainId": 1,
          "address": "0x77f9cf0bd8c500cffdf420e72343893aecc2ec0b",
          "name": "Laika",
          "symbol": "LAIKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26717/thumb/Laikacmcfinal.png?1659857807"
        },
        {
          "chainId": 1,
          "address": "0x846c66cf71c43f80403b51fe3906b3599d63336f",
          "name": "PumaPay",
          "symbol": "PMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png?1622176373"
        },
        {
          "chainId": 1,
          "address": "0x41a3dba3d677e573636ba691a70ff2d606c29666",
          "name": "BlockWallet",
          "symbol": "BLANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14209/thumb/Group_121417608.png?1688436285"
        },
        {
          "chainId": 1,
          "address": "0x6ba75d640bebfe5da1197bb5a2aff3327789b5d3",
          "name": "VNX EURO",
          "symbol": "VEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29351/thumb/VNXEUR_%281%29.png?1678262021"
        },
        {
          "chainId": 1,
          "address": "0xbede1f59fa4412556fef69f1b9969f2003e3f8c1",
          "name": "Meta MVRS",
          "symbol": "MVRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22140/thumb/mvrs-200x200.png?1640926136"
        },
        {
          "chainId": 1,
          "address": "0x668c50b1c7f46effbe3f242687071d7908aab00a",
          "name": "CoShi Inu",
          "symbol": "COSHI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15219/thumb/200.jpg?1620119215"
        },
        {
          "chainId": 1,
          "address": "0x2a7e415c169ce3a580c6f374dc26f6aaad1eccfe",
          "name": "Hachi",
          "symbol": "HACHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29262/thumb/HACHI_main_logo__.png?1677658543"
        },
        {
          "chainId": 1,
          "address": "0xb26631c6dda06ad89b93c71400d25692de89c068",
          "name": "Minds",
          "symbol": "MINDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11517/thumb/Minds.png?1590580465"
        },
        {
          "chainId": 1,
          "address": "0x865ec58b06bf6305b886793aa20a2da31d034e68",
          "name": "Mossland",
          "symbol": "MOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2374/thumb/moc.png?1547036557"
        },
        {
          "chainId": 1,
          "address": "0x9b99cca871be05119b2012fd4474731dd653febe",
          "name": "AntiMatter",
          "symbol": "MATTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14112/thumb/antimatter_icon.png?1614395992"
        },
        {
          "chainId": 1,
          "address": "0x102203517ce35ac5cab9a2cda80df03f26c7419b",
          "name": "SUMOTEX",
          "symbol": "SMTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27478/thumb/Sumotex_telegram_image_PNG.jpg?1664198249"
        },
        {
          "chainId": 1,
          "address": "0x36948a6809be82d3ba8de9f2be626101e2c9e473",
          "name": "BoltBot",
          "symbol": "BOLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30877/thumb/boltbot.jpg?1688381252"
        },
        {
          "chainId": 1,
          "address": "0x171b1daefac13a0a3524fcb6beddc7b31e58e079",
          "name": "Freechat",
          "symbol": "FCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29413/thumb/FCC_logo.png?1678598952"
        },
        {
          "chainId": 1,
          "address": "0x1117ac6ad6cdf1a3bc543bad3b133724620522d5",
          "name": "MODA DAO",
          "symbol": "MODA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20870/thumb/ModaDAO__logomark-primary_3x.png?1637809145"
        },
        {
          "chainId": 1,
          "address": "0xa88842ae47dbe87116cf7001dddd1b246fcd8262",
          "name": "hiENS3",
          "symbol": "HIENS3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27262/thumb/hiens3.png?1663059024"
        },
        {
          "chainId": 1,
          "address": "0x6bf765c43030387a983f429c1438e9d2025b7e12",
          "name": "McPepe s",
          "symbol": "PEPES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29265/thumb/pepes-logo_%282%29.png?1677666477"
        },
        {
          "chainId": 1,
          "address": "0xb6387e8e928c9face4d44a6aca72e5a6c939a829",
          "name": "Salty Coin",
          "symbol": "SALTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30186/thumb/salty_%281%29.png?1683601287"
        },
        {
          "chainId": 1,
          "address": "0x2af72850c504ddd3c1876c66a914caee7ff8a46a",
          "name": "WhaleRoom",
          "symbol": "WHL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14328/thumb/ArEX8tkV_400x400.png?1615432175"
        },
        {
          "chainId": 1,
          "address": "0x884ddbb5dc6c2cef77d3e74c6ccca315797d655b",
          "name": "Carnomaly",
          "symbol": "CARR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14403/thumb/tcjOTKE3_400x400.png?1615895296"
        },
        {
          "chainId": 1,
          "address": "0xf7e945fce8f19302aacc7e1418b0a0bdef89327b",
          "name": "Galvan",
          "symbol": "IZE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29442/thumb/Galvan_%28IZE%29.png?1678865632"
        },
        {
          "chainId": 1,
          "address": "0x1d2d542e6d9d85a712deb4d1a7d96a16ce00b8ce",
          "name": "Proof Of Apes",
          "symbol": "POA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27896/thumb/POA.png?1666318268"
        },
        {
          "chainId": 1,
          "address": "0xcbd55d4ffc43467142761a764763652b48b969ff",
          "name": "AstroTools",
          "symbol": "ASTRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12345/thumb/AT-flat-purple_logo.png?1599190828"
        },
        {
          "chainId": 1,
          "address": "0x5d858bcd53e085920620549214a8b27ce2f04670",
          "name": "POP Network",
          "symbol": "POP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7281/thumb/Logo_%28Light_Mode%29_%281%29.png?1644482888"
        },
        {
          "chainId": 1,
          "address": "0x7169ba45cf03e0377b464462aacbccdf1f721125",
          "name": "1peco",
          "symbol": "1PECO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22312/thumb/1PECO_200x200.png?1641467088"
        },
        {
          "chainId": 1,
          "address": "0xe5a733681bbe6cd8c764bb8078ef8e13a576dd78",
          "name": "Devour",
          "symbol": "DPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27191/thumb/DPAY_-_Icon_%28200_%C3%97_200_px%29.png?1684229296"
        },
        {
          "chainId": 1,
          "address": "0x4159862bcf6b4393a80550b1ed03dffa6f90533c",
          "name": "One Hundred Million Inu",
          "symbol": "OHMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28625/thumb/ohmi-shield-200.png?1676794632"
        },
        {
          "chainId": 1,
          "address": "0xb8db81b84d30e2387de0ff330420a4aaa6688134",
          "name": "Aave AMM UniLINKWETH",
          "symbol": "AAMMUNILINKWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17221/thumb/aAmmUniLINKWETH.png?1626879669"
        },
        {
          "chainId": 1,
          "address": "0x29127fe04ffa4c32acac0ffe17280abd74eac313",
          "name": "SIFU",
          "symbol": "SIFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24692/thumb/token_%283%29.png?1648620836"
        },
        {
          "chainId": 1,
          "address": "0xf4172630a656a47ece8616e75791290446fa41a0",
          "name": "PEPPA",
          "symbol": "PEPPA",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/30492/thumb/200_x_200_JPG.jpg?1684719830"
        },
        {
          "chainId": 1,
          "address": "0x0d15009896efe9972f8e086bdd3bcba5c1f74bf3",
          "name": "SonoCoin",
          "symbol": "SONO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7230/thumb/MeWTs09-_400x400.jpg?1582700314"
        },
        {
          "chainId": 1,
          "address": "0xeccf15a4b5976a1365baed5297058b4ca42777c0",
          "name": "Nosturis",
          "symbol": "NTRS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9990/thumb/47010860_203643113858754_2966343352238211072_n.jpg?1574373395"
        },
        {
          "chainId": 1,
          "address": "0x6732efaf6f39926346bef8b821a04b6361c4f3e5",
          "name": "Simple Asymmetry ETH",
          "symbol": "SAFETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30426/thumb/safETH_BACKGROUND.png?1685682391"
        },
        {
          "chainId": 1,
          "address": "0x3d26dcd840fcc8e4b2193ace8a092e4a65832f9f",
          "name": "Aave AMM UniUNIWETH",
          "symbol": "AAMMUNIUNIWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17225/thumb/aAmmUniUNIWETH.png?1626881095"
        },
        {
          "chainId": 1,
          "address": "0x333a4823466879eef910a04d473505da62142069",
          "name": "Nation3",
          "symbol": "NATION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25106/thumb/Orb_gradient.png?1650321118"
        },
        {
          "chainId": 1,
          "address": "0x34be5b8c30ee4fde069dc878989686abe9884470",
          "name": "SENATE",
          "symbol": "SENATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21272/thumb/siIBOmcs.png?1638857898"
        },
        {
          "chainId": 1,
          "address": "0xc57f1d079c862b70aa12faab19293f827187aaf6",
          "name": "Gilgeous",
          "symbol": "GLG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30241/thumb/GLG_200x200.jpg?1683690884"
        },
        {
          "chainId": 1,
          "address": "0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06",
          "name": "Parachute",
          "symbol": "PAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png?1560918207"
        },
        {
          "chainId": 1,
          "address": "0x2610f0bfc21ef389fe4d03cfb7de9ac1e6c99d6e",
          "name": "Skyrim Finance",
          "symbol": "SKYRIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18003/thumb/Xo3oCb53_400x400.png?1630096304"
        },
        {
          "chainId": 1,
          "address": "0x9b9647431632af44be02ddd22477ed94d14aacaa",
          "name": "KOK",
          "symbol": "KOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10502/thumb/logo_navy.png?1580250344"
        },
        {
          "chainId": 1,
          "address": "0x973e52691176d36453868d9d86572788d27041a9",
          "name": "DxChain",
          "symbol": "DX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4700/thumb/VdZwy0Pv_400x400.png?1603089728"
        },
        {
          "chainId": 1,
          "address": "0x1c4853ec0d55e420002c5efabc7ed8e0ba7a4121",
          "name": "Kanagawa Nami",
          "symbol": "OKINAMI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26629/thumb/KANAGAWA_%284%29.png?1659324703"
        },
        {
          "chainId": 1,
          "address": "0x9839675308f4a83e45f4b0c89b8fa264d68b7812",
          "name": "Ciphercore",
          "symbol": "CIPHER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30907/thumb/cipher_200x200.png?1688623648"
        },
        {
          "chainId": 1,
          "address": "0x47b9f01b16e9c9cb99191dca68c9cc5bf6403957",
          "name": "Onston",
          "symbol": "ONSTON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20669/thumb/onston.PNG?1637547859"
        },
        {
          "chainId": 1,
          "address": "0x701c244b988a513c945973defa05de933b23fe1d",
          "name": "OAX",
          "symbol": "OAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/853/thumb/OAXlogo.png?1598686792"
        },
        {
          "chainId": 1,
          "address": "0xaf9f549774ecedbd0966c52f250acc548d3f36e5",
          "name": "RioDeFi",
          "symbol": "RFUEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png?1602481093"
        },
        {
          "chainId": 1,
          "address": "0x865377367054516e17014ccded1e7d814edc9ce4",
          "name": "DOLA",
          "symbol": "DOLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14287/thumb/dola.png?1667738374"
        },
        {
          "chainId": 1,
          "address": "0x48783486ddd7fa85eca6b0c4ae8920bc25dfbcd7",
          "name": "GoMoney2",
          "symbol": "GOM2",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/10374/thumb/lvAhDIqmH0fh6U3NIiYLmKETR3uUBcySAv-K28eW6CCFm-ODhCdId71Ug5c4TCoEtxsre30Efe08muctK0MlK-JPdAbxilzZ7dHyiBNOCvcc_9AmJIo09TRLaiAafgqcFKsxpNOON2D28oTLnVTaqwxWL8zKSzjbI6ChKTCJKOiM2mq7VhQRZYe93StR30mf2O7DnkqmGEbZ5_i.jpg?1578675305"
        },
        {
          "chainId": 1,
          "address": "0x22b6c31c2beb8f2d0d5373146eed41ab9ede3caf",
          "name": "cocktailbar finance",
          "symbol": "COC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13121/thumb/Cocktail_Bar_logo_%281%29_%281%29.png?1690798858"
        },
        {
          "chainId": 1,
          "address": "0xcc4304a31d09258b0029ea7fe63d032f52e44efe",
          "name": "TrustSwap",
          "symbol": "SWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11795/thumb/Untitled_design-removebg-preview.png?1626926355"
        },
        {
          "chainId": 1,
          "address": "0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f",
          "name": "OriginTrail",
          "symbol": "TRAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367"
        },
        {
          "chainId": 1,
          "address": "0xffc97d72e13e01096502cb8eb52dee56f74dad7b",
          "name": "Aave AAVE",
          "symbol": "AAAVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17248/thumb/amAAVE_2x.png?1626941140"
        },
        {
          "chainId": 1,
          "address": "0x244517dc59943e8cdfbd424bdb3262c5f04a1387",
          "name": "Fluid DAI",
          "symbol": "FDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28473/thumb/fDAI-200x200.png?1671002526"
        },
        {
          "chainId": 1,
          "address": "0xdf347911910b6c9a4286ba8e2ee5ea4a39eb2134",
          "name": "Bob s Repair",
          "symbol": "BOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3550/thumb/PNG_BOB_Token_Large.png?1547038375"
        },
        {
          "chainId": 1,
          "address": "0x58083b54013631bacc0bbb6d4efa543fee1d9ce0",
          "name": "Force",
          "symbol": "FRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30917/thumb/force_token_1000.png?1688956279"
        },
        {
          "chainId": 1,
          "address": "0x2c9715d6d95443c3bdf29e473ad168ff6fa6627d",
          "name": "Crab Rave Token",
          "symbol": "CRABS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30849/thumb/cute-little-crab-cartoon-posing-vector_%281%29.jpg?1687939933"
        },
        {
          "chainId": 1,
          "address": "0xaa19961b6b858d9f18a115f25aa1d98abc1fdba8",
          "name": "LocalCoinSwap",
          "symbol": "LCS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3998/thumb/LocalCoinSwap.png?1547039086"
        },
        {
          "chainId": 1,
          "address": "0xb2e96a63479c2edd2fd62b382c89d5ca79f572d3",
          "name": "Zenon",
          "symbol": "ZNN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30753/thumb/zenon.jpg?1686747490"
        },
        {
          "chainId": 1,
          "address": "0xdc524e3c6910257744c1f93cf15e9f472b5bd236",
          "name": "Witch Token",
          "symbol": "WITCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17205/thumb/download_%2829%29.png?1626848072"
        },
        {
          "chainId": 1,
          "address": "0x6cd079f296c10eb84a64239b893d4edfa2e1d779",
          "name": "PulseCrypt",
          "symbol": "PLSCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29776/thumb/PulseCrypt.jpg?1681267250"
        },
        {
          "chainId": 1,
          "address": "0xfa4baa6951b6ee382e9ff9af2d523278b99ca6d0",
          "name": "House",
          "symbol": "HOUSE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/31202/thumb/hm_icon_200x200.png?1691379190"
        },
        {
          "chainId": 1,
          "address": "0xf6043bea98b07f0bea7813aeb22d0cb70c91c0c4",
          "name": "MemeDao Ai",
          "symbol": "MDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29021/thumb/MemedaoLogo.png?1675995407"
        },
        {
          "chainId": 1,
          "address": "0xa14ea0e11121e6e951e87c66afe460a00bcd6a16",
          "name": "IdleDAI  Risk Adjusted ",
          "symbol": "IDLEDAISAFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11929/thumb/idledai-safe.png?1596263241"
        },
        {
          "chainId": 1,
          "address": "0xaaca86b876ca011844b5798eca7a67591a9743c8",
          "name": "0x nodes",
          "symbol": "BIOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736"
        },
        {
          "chainId": 1,
          "address": "0xc775c0c30840cb9f51e21061b054ebf1a00acc29",
          "name": "Pastel",
          "symbol": "PSL",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/14293/thumb/A4qGwEij_400x400.jpg?1615290394"
        },
        {
          "chainId": 1,
          "address": "0x877dd180f71369d9bd70e658baba110a55ee3f1f",
          "name": "Fat Pickle",
          "symbol": "FATP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30726/thumb/_FATP_Logo.png?1686634044"
        },
        {
          "chainId": 1,
          "address": "0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0",
          "name": "Equalizer",
          "symbol": "EQZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png?1618005664"
        },
        {
          "chainId": 1,
          "address": "0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd",
          "name": "Index Coop   ETH 2x Flexible Leverage I",
          "symbol": "ETH2X-FLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14392/thumb/ETH2x-FLI_%281%29.png?1615875910"
        },
        {
          "chainId": 1,
          "address": "0xef7c2ab5fafea06f8aee63c866d0be1846c35f68",
          "name": "Apiens",
          "symbol": "APN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30421/thumb/apienslg.png?1684392301"
        },
        {
          "chainId": 1,
          "address": "0x878fcc2bdcccff8c56812607b9a58f29b274c4f0",
          "name": "Derp Coin",
          "symbol": "DERP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30262/thumb/logo-200x200-transparent.png?1683773074"
        },
        {
          "chainId": 1,
          "address": "0xc7bba5b765581efb2cdd2679db5bea9ee79b201f",
          "name": "Gems",
          "symbol": "GEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3945/thumb/Webp.net-resizeimage_%2817%29.jpg?1547038970"
        },
        {
          "chainId": 1,
          "address": "0x571e21a545842c6ce596663cda5caa8196ac1c7a",
          "name": "Champignons of Arborethia",
          "symbol": "CHAMPZ",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/31124/thumb/ChampzSquare200x200.png?1690775177"
        },
        {
          "chainId": 1,
          "address": "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
          "name": "Lido DAO",
          "symbol": "LDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644"
        },
        {
          "chainId": 1,
          "address": "0x3625b55f653f8c7c884ac668696881df43631d44",
          "name": "Arti Project",
          "symbol": "ARTI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/18878/thumb/arti.PNG?1633688264"
        },
        {
          "chainId": 1,
          "address": "0xfcc63e6459936216b7b887f6da5fcd90c0c62b7c",
          "name": "Korea Entertainment Education   Shoppin",
          "symbol": "KEES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27513/thumb/lo2XCLcf_400x400.jpeg?1664351911"
        },
        {
          "chainId": 1,
          "address": "0xe155f64b9ad8c81318c313196a60c72e72fd2cd1",
          "name": "Chaintools",
          "symbol": "CTLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30896/thumb/IMG_0501.png?1688544799"
        },
        {
          "chainId": 1,
          "address": "0x70c5f366db60a2a0c59c4c24754803ee47ed7284",
          "name": "Glove",
          "symbol": "GLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28831/thumb/glo.png?1675567261"
        },
        {
          "chainId": 1,
          "address": "0x418afee14a1fd9c05c4df05e033f7c3d46aeb905",
          "name": "Magic Bag",
          "symbol": "FELIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30436/thumb/Magic_Bag.png?1684470772"
        },
        {
          "chainId": 1,
          "address": "0xaa602de53347579f86b996d2add74bb6f79462b2",
          "name": "Zipmex",
          "symbol": "ZMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13866/thumb/ZMT_Token.png?1637241562"
        },
        {
          "chainId": 1,
          "address": "0xd89310f4baedb33afb36d7cc45bb8847f4463060",
          "name": "MEMEX",
          "symbol": "MEMEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15448/thumb/V46Sq63d_400x400.jpg?1682566798"
        },
        {
          "chainId": 1,
          "address": "0x2730d6fdc86c95a74253beffaa8306b40fedecbb",
          "name": "UNICORN",
          "symbol": "UNI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9383/thumb/54231438.png?1566777314"
        },
        {
          "chainId": 1,
          "address": "0x35156b404c3f9bdaf45ab65ba315419bcde3775c",
          "name": "Chihiro Inu",
          "symbol": "CHIRO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png?1642166355"
        },
        {
          "chainId": 1,
          "address": "0x583cc692aefe93c57cd55b6cd11d39cf1aa98c8f",
          "name": "NCAT",
          "symbol": "NCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31021/thumb/200x200.png?1689829139"
        },
        {
          "chainId": 1,
          "address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
          "name": "OpenDAO",
          "symbol": "SOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21956/thumb/fo42wXI6_400x400.jpg?1640373810"
        },
        {
          "chainId": 1,
          "address": "0xc39a048353842caf1b94ce5967a04149bb435b98",
          "name": "Reset News",
          "symbol": "NEWS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29829/thumb/NEWS_Logo.png?1681714509"
        },
        {
          "chainId": 1,
          "address": "0x130914e1b240a7f4c5d460b7d3a2fd3846b576fa",
          "name": "Aureus Nummus Gold",
          "symbol": "ANG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12134/thumb/AureusNummusGold-logo.png?1597462266"
        },
        {
          "chainId": 1,
          "address": "0x4aa41bc1649c9c3177ed16caaa11482295fc7441",
          "name": "XFai",
          "symbol": "XFIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14904/thumb/XFAI_Logo200x200.jpg?1678328437"
        },
        {
          "chainId": 1,
          "address": "0x543ff227f64aa17ea132bf9886cab5db55dcaddf",
          "name": "DAOstack",
          "symbol": "GEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3479/thumb/gen.png?1547038215"
        },
        {
          "chainId": 1,
          "address": "0x95efd1fe6099f65a7ed524def487483221094947",
          "name": "CryptoBonusMiles",
          "symbol": "CBM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9302/thumb/CryptoBonusMiles.png?1565865043"
        },
        {
          "chainId": 1,
          "address": "0x8c6bf16c273636523c29db7db04396143770f6a0",
          "name": "Moon Rabbit",
          "symbol": "AAA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874"
        },
        {
          "chainId": 1,
          "address": "0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db",
          "name": "AppCoins",
          "symbol": "APPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1876/thumb/appcoins.png?1547036186"
        },
        {
          "chainId": 1,
          "address": "0x9be89d2a4cd102d8fecc6bf9da793be995c22541",
          "name": "Binance Wrapped BTC",
          "symbol": "BBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14867/thumb/binance-btc_32.png?1618814666"
        },
        {
          "chainId": 1,
          "address": "0x954b890704693af242613edef1b603825afcd708",
          "name": "Cardstack",
          "symbol": "CARD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3247/thumb/cardstack.png?1547037769"
        },
        {
          "chainId": 1,
          "address": "0x6a969d379700b2e5ea4e684d273d63c1c050ba49",
          "name": "Pacific",
          "symbol": "PAF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20915/thumb/paf.png?1637922989"
        },
        {
          "chainId": 1,
          "address": "0x0abdace70d3790235af448c88547603b945604ea",
          "name": "district0x",
          "symbol": "DNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
        },
        {
          "chainId": 1,
          "address": "0x6fb3e0a217407efff7ca062d46c26e5d60a14d69",
          "name": "IoTeX",
          "symbol": "IOTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3334/thumb/iotex-logo.png?1547037941"
        },
        {
          "chainId": 1,
          "address": "0x1f05dee43df9fd0cd7f4b011b20b04870a30c29a",
          "name": "ShibWallet",
          "symbol": "SWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29485/thumb/Disseny_sense_t%C3%ADtol_%284%29.png?1679136825"
        },
        {
          "chainId": 1,
          "address": "0x3a810ff7211b40c4fa76205a14efe161615d0385",
          "name": "AI Network",
          "symbol": "AIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13211/thumb/AI_Network_Logo_200x200.png?1606205615"
        },
        {
          "chainId": 1,
          "address": "0xf1290473e210b2108a85237fbcd7b6eb42cc654f",
          "name": "HedgeTrade",
          "symbol": "HEDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7196/thumb/to3Vj4EZ_400x400.jpg?1547043758"
        },
        {
          "chainId": 1,
          "address": "0xe831f96a7a1dce1aa2eb760b1e296c6a74caa9d5",
          "name": "Nexum",
          "symbol": "NEXM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225"
        },
        {
          "chainId": 1,
          "address": "0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d",
          "name": "Kleros",
          "symbol": "PNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3833/thumb/kleros.png?1547975322"
        },
        {
          "chainId": 1,
          "address": "0xf0f9d895aca5c8678f706fb8216fa22957685a13",
          "name": "Cult DAO",
          "symbol": "CULT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23331/thumb/quxZPrbC_400x400.jpg?1643880172"
        },
        {
          "chainId": 1,
          "address": "0x8811e4dd5ec5eb8764b97cc814b1339089717ada",
          "name": "Bithachi",
          "symbol": "BITH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11765/thumb/bith3.png?1593656342"
        },
        {
          "chainId": 1,
          "address": "0xa67e9f021b9d208f7e3365b2a155e3c55b27de71",
          "name": "KleeKai",
          "symbol": "KLEE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15548/thumb/Klee-Kai-Logo.png?1628258448"
        },
        {
          "chainId": 1,
          "address": "0xcae636167db2369bd746f2bcba79a6e8b0d4e000",
          "name": "Education Assessment Cult",
          "symbol": "EAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25466/thumb/eac.png?1651915165"
        },
        {
          "chainId": 1,
          "address": "0xbededdf2ef49e87037c4fb2ca34d1ff3d3992a11",
          "name": "FEG ETH",
          "symbol": "FEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29643/thumb/feg.png?1680234765"
        },
        {
          "chainId": 1,
          "address": "0xae377ed00df9b9663b253be93035ef1cfecbc1fb",
          "name": "ALERT",
          "symbol": "ALERT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28931/thumb/Safeimagekit-resized-img.png?1675496016"
        },
        {
          "chainId": 1,
          "address": "0xd1b624f07a4d9b3e3746e33cb58f42df079b5444",
          "name": "NKCL Classic",
          "symbol": "NKCLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17893/thumb/w02Nksm-.png?1629718144"
        },
        {
          "chainId": 1,
          "address": "0x301c755ba0fca00b1923768fffb3df7f4e63af31",
          "name": "Global Digital Content",
          "symbol": "GDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10122/thumb/Vp4L1x4Hu4HEuEPUl0gSocDaVifkXlCqSd8A72ml_Q0uwEc9uBWBTwjS43_NP8eiiZVveJDIQXguWUXYk3MLZiFOER-8CGO120I0NGU_L8aTJ8c50a-O_AbkY-4nUkPYhUTNgf3rkcqkXnKecixQwLfkJG2XTCLwNvo_ox05x0_hU1Z__FOT4O7AeU-PC6skc85NCVS68cnZjxcUBOY9K.jpg?1576124937"
        },
        {
          "chainId": 1,
          "address": "0x66a4868f1829ab78ffd5aac5fe68fe508eb96e66",
          "name": "Yieldara",
          "symbol": "YARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30502/thumb/mOKeZup6_400x400.jpg?1684743729"
        },
        {
          "chainId": 1,
          "address": "0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e",
          "name": "Unistake",
          "symbol": "UNISTAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12813/thumb/unistake.png?1612346684"
        },
        {
          "chainId": 1,
          "address": "0xfcc5c47be19d06bf83eb04298b026f81069ff65b",
          "name": "Yearn CRV",
          "symbol": "YCRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27622/thumb/yearncrvnew_32.png?1664881722"
        },
        {
          "chainId": 1,
          "address": "0x836a808d4828586a69364065a1e064609f5078c7",
          "name": "pETH",
          "symbol": "PETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28376/thumb/peth.png?1669968949"
        },
        {
          "chainId": 1,
          "address": "0xdd2a36ae937bc134ea694d77fc7e2e36f5d86de0",
          "name": "WELD",
          "symbol": "WELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18544/thumb/weld.png?1661497968"
        },
        {
          "chainId": 1,
          "address": "0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f",
          "name": "Stake DAO",
          "symbol": "SDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13724/thumb/stakedao_logo.jpg?1611195011"
        },
        {
          "chainId": 1,
          "address": "0xfc4913214444af5c715cc9f7b52655e788a569ed",
          "name": "Icosa",
          "symbol": "ICSA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27708/thumb/icsa.e2b79cbc.png?1665314034"
        },
        {
          "chainId": 1,
          "address": "0x84342e932797fc62814189f01f0fb05f52519708",
          "name": "Neighbourhoods",
          "symbol": "NHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22055/thumb/social_media_logo_black_bg.png?1640682530"
        },
        {
          "chainId": 1,
          "address": "0x626e8036deb333b408be468f951bdb42433cbf18",
          "name": "AIOZ Network",
          "symbol": "AIOZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14631/thumb/aioz-logo-200.png?1649237507"
        },
        {
          "chainId": 1,
          "address": "0x5c1d9aa868a30795f92fae903edc9eff269044bf",
          "name": "Changer",
          "symbol": "CNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21786/thumb/cng.png?1640327628"
        },
        {
          "chainId": 1,
          "address": "0x71e368ed06814bf35d4e663eff946400a5bc8115",
          "name": "Degrain",
          "symbol": "DGRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27570/thumb/degrain.jpeg?1664778517"
        },
        {
          "chainId": 1,
          "address": "0xd6a5ab46ead26f49b03bbb1f9eb1ad5c1767974a",
          "name": "Ethermon",
          "symbol": "EMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15889/thumb/LtET0reH_400x400.jpg?1622213962"
        },
        {
          "chainId": 1,
          "address": "0x0335a7610d817aeca1bebbefbd392ecc2ed587b8",
          "name": "Nitro League",
          "symbol": "NITRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21668/thumb/_X6vYBDM_400x400.jpg?1639705848"
        },
        {
          "chainId": 1,
          "address": "0x4295c8556afee00264c0789dde2ddd2dba71acfe",
          "name": "Bidao Smart Chain",
          "symbol": "BISC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29825/thumb/bisc200.png?1681705605"
        },
        {
          "chainId": 1,
          "address": "0x4da08a1bff50be96bded5c7019227164b49c2bfc",
          "name": "Mononoke Inu",
          "symbol": "MONONOKE-INU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18769/thumb/z9YDK0f.png?1633357322"
        },
        {
          "chainId": 1,
          "address": "0xdc63269ea166b70d4780b3a11f5c825c2b761b01",
          "name": "PAWSWAP",
          "symbol": "PAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28946/thumb/pawnewlogobig.png?1682498998"
        },
        {
          "chainId": 1,
          "address": "0xec363faa5c4dd0e51f3d9b5d0101263760e7cdeb",
          "name": "Instadapp WBTC",
          "symbol": "IWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25784/thumb/iWBTC-100x100.png?1654055142"
        },
        {
          "chainId": 1,
          "address": "0xf16e81dce15b08f326220742020379b855b87df9",
          "name": "Popsicle Finance",
          "symbol": "ICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14586/thumb/ice.png?1617188825"
        },
        {
          "chainId": 1,
          "address": "0x3486b751a36f731a1bebff779374bad635864919",
          "name": "Inedible Coin",
          "symbol": "INEDIBLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30828/thumb/ZIGj-TlV_400x400.jpg?1688351063"
        },
        {
          "chainId": 1,
          "address": "0x7f4c5447af6a96d8eeaee1d932338cfc57890dbd",
          "name": "Dave Coin",
          "symbol": "DAVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30703/thumb/davelogo_transparent200x200.png?1686545135"
        },
        {
          "chainId": 1,
          "address": "0xf6afc05fccea5a53f22a3e39ffee861e016bd9a0",
          "name": "LandWolf",
          "symbol": "WOLF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30982/thumb/landwolf.jpg?1689326821"
        },
        {
          "chainId": 1,
          "address": "0x159a1dfae19057de57dfffcbb3da1ae784678965",
          "name": "Reflex",
          "symbol": "RFX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456"
        },
        {
          "chainId": 1,
          "address": "0x7104d1f179cc9cc7fb5c79be6da846e3fbc4c105",
          "name": "X7104",
          "symbol": "X7104",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28422/thumb/X7104_LOGO_black_back_200_x_200_px.png?1670460882"
        },
        {
          "chainId": 1,
          "address": "0x63f584fa56e60e4d0fe8802b27c7e6e3b33e007f",
          "name": "ContentBox",
          "symbol": "BOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4929/thumb/contentbox.png?1547740046"
        },
        {
          "chainId": 1,
          "address": "0x8189afbe7b0e81dae735ef027cd31371b3974feb",
          "name": "Bean",
          "symbol": "BEAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30884/thumb/coin_logo.png?1688520279"
        },
        {
          "chainId": 1,
          "address": "0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9",
          "name": "Symbiosis",
          "symbol": "SIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20805/thumb/SymbiosisFinance_logo-150x150.jpeg?1637707064"
        },
        {
          "chainId": 1,
          "address": "0x426fc8be95573230f6e6bc4af91873f0c67b21b4",
          "name": "BlackPearl",
          "symbol": "BPLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141"
        },
        {
          "chainId": 1,
          "address": "0x7102dc82ef61bfb0410b1b1bf8ea74575bf0a105",
          "name": "X7102",
          "symbol": "X7102",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28420/thumb/X7102_LOGO_black_back_200_x_200_px.png?1670460752"
        },
        {
          "chainId": 1,
          "address": "0xa95c5ebb86e0de73b4fb8c47a45b792cfea28c23",
          "name": "stake link",
          "symbol": "SDL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28406/thumb/stake.png?1670334146"
        },
        {
          "chainId": 1,
          "address": "0x1426cc6d52d1b14e2b3b1cb04d57ea42b39c4c7c",
          "name": "Jomon Shiba",
          "symbol": "JSHIBA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15395/thumb/Jomon.png?1622960557"
        },
        {
          "chainId": 1,
          "address": "0xb3999f658c0391d94a37f7ff328f3fec942bcadc",
          "name": "Hashflow",
          "symbol": "HFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26136/thumb/hashflow-icon-cmc.png?1668345672"
        },
        {
          "chainId": 1,
          "address": "0x9008064e6cf73e27a3aba4b10e69f855a4f8efcc",
          "name": "Gemie",
          "symbol": "GEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27745/thumb/icon-transparent.png?1665632270"
        },
        {
          "chainId": 1,
          "address": "0xcd74cf66c43e45abd5703f3642f73d0675d6aff7",
          "name": "Erugo World Coin",
          "symbol": "EWC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21652/thumb/Swhwfr3G_400x400.jpg?1639650872"
        },
        {
          "chainId": 1,
          "address": "0x96184d9c811ea0624fc30c80233b1d749b9e485b",
          "name": "Dapp com",
          "symbol": "DAPPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8282/thumb/dappt_logo.png?1593074788"
        },
        {
          "chainId": 1,
          "address": "0xed35af169af46a02ee13b9d79eb57d6d68c1749e",
          "name": "ECOMI",
          "symbol": "OMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4428/thumb/ECOMI.png?1557928886"
        },
        {
          "chainId": 1,
          "address": "0x9f195617fa8fbad9540c5d113a99a0a0172aaedc",
          "name": "Niobium Coin",
          "symbol": "NBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4095/thumb/owgLqenv_400x400.jpg?1678176489"
        },
        {
          "chainId": 1,
          "address": "0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7",
          "name": "TokenClub",
          "symbol": "TCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2455/thumb/tokenclub.png?1558012103"
        },
        {
          "chainId": 1,
          "address": "0x456125cd98107ae0480ba566f1b716d48ba31453",
          "name": "Ultimate Champions",
          "symbol": "CHAMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28091/thumb/champ.png?1667472074"
        },
        {
          "chainId": 1,
          "address": "0x53011e93f21ec7a74cdfbb7e6548f1abce306833",
          "name": "LendeXe Finance",
          "symbol": "LEXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29066/thumb/669fbb3b-8f61-4f3d-8ced-c9d47babcae4.png?1676366651"
        },
        {
          "chainId": 1,
          "address": "0x5516ac1aaca7bb2fd5b7bdde1549ef1ea242953d",
          "name": "Decentralized ETF",
          "symbol": "DETF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24232/thumb/image.jpg?1648448076"
        },
        {
          "chainId": 1,
          "address": "0xc2a2e9b87b37938d934fd51cfec0c5fd5cb14c31",
          "name": "Fluffy Token",
          "symbol": "FLUFFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29655/thumb/image_6-2.png?1680496182"
        },
        {
          "chainId": 1,
          "address": "0xe5caef4af8780e59df925470b050fb23c43ca68c",
          "name": "Ferrum Network",
          "symbol": "FRM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/8251/thumb/FRM.png?1658819429"
        },
        {
          "chainId": 1,
          "address": "0x86f0cdb38a853420be4dbf6a3a9a3fe0756bc701",
          "name": "Leverage Protocol",
          "symbol": "LEVX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29736/thumb/LEVX_Logo.png?1681095711"
        },
        {
          "chainId": 1,
          "address": "0x43ab765ee05075d78ad8aa79dcb1978ca3079258",
          "name": "POW",
          "symbol": "POW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22333/thumb/16963.png?1641515902"
        },
        {
          "chainId": 1,
          "address": "0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7",
          "name": "Tidal Finance",
          "symbol": "TIDAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14460/thumb/Tidal-mono.png?1616233894"
        },
        {
          "chainId": 1,
          "address": "0xe63684bcf2987892cefb4caa79bd21b34e98a291",
          "name": "Chicken",
          "symbol": "KFC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12569/thumb/download.png?1600840301"
        },
        {
          "chainId": 1,
          "address": "0x4740735aa98dc8aa232bd049f8f0210458e7fca3",
          "name": "Ridotto",
          "symbol": "RDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png?1632875527"
        },
        {
          "chainId": 1,
          "address": "0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68",
          "name": "Dinger",
          "symbol": "DINGER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19443/thumb/dinger.png?1661498131"
        },
        {
          "chainId": 1,
          "address": "0x97d4f49eeb0e2c96d5ebaa71ab8418e563ecd9fd",
          "name": "Liquid Staking Derivative",
          "symbol": "LSD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29188/thumb/LSD.jpg?1677219444"
        },
        {
          "chainId": 1,
          "address": "0xf9c12b27ce5058ab98ce11bd53900f84e18c0650",
          "name": "Y2B",
          "symbol": "Y2B",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28638/thumb/logo.jpg?1672825625"
        },
        {
          "chainId": 1,
          "address": "0xed354cae598148846aa9694254c854fc7051163c",
          "name": "Shido",
          "symbol": "SHIDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26674/thumb/Shido.png?1667361249"
        },
        {
          "chainId": 1,
          "address": "0x883a158c9b28f8d626acfcfbe1028f49e70c9d75",
          "name": "CNG Casino",
          "symbol": "CNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11573/thumb/cng_casino.png?1591230675"
        },
        {
          "chainId": 1,
          "address": "0x64551fa4093c2d6c298cf1792740f884084a8f69",
          "name": "SPGBB",
          "symbol": "SPGBB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30089/thumb/spgbb.jpg?1683019322"
        },
        {
          "chainId": 1,
          "address": "0x142a774e8b52550e88e196cedd7a5835acb646d0",
          "name": "SaitaRealty",
          "symbol": "SRLTY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27289/thumb/s_mksCdB_400x400.jpeg?1663145048"
        },
        {
          "chainId": 1,
          "address": "0x6fb1e018f107d3352506c23777e4cd62e063584a",
          "name": "Identity",
          "symbol": "IDTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG?1631745919"
        },
        {
          "chainId": 1,
          "address": "0x419e35e3515c2fdb652c898bf7a0b21fb20497dc",
          "name": "Ordinals Finance",
          "symbol": "OFI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29226/thumb/symbol-bbg.png?1677485044"
        },
        {
          "chainId": 1,
          "address": "0x2f57430a6ceda85a67121757785877b4a71b8e6d",
          "name": "DefiPlaza",
          "symbol": "DFP2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19552/thumb/profile-round.png?1654153498"
        },
        {
          "chainId": 1,
          "address": "0x7101a9392eac53b01e7c07ca3baca945a56ee105",
          "name": "X7101",
          "symbol": "X7101",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28419/thumb/X7101_LOGO_black_back_200_x_200_px.png?1670460693"
        },
        {
          "chainId": 1,
          "address": "0x6d1dc3928604b00180bb570bdae94b9698d33b79",
          "name": "UnitedCrowd",
          "symbol": "UCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511"
        },
        {
          "chainId": 1,
          "address": "0x9471d30d78a3c9f076ce206d14867a8d8be1efde",
          "name": "ZENEX",
          "symbol": "ZNX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29638/thumb/znx-logo.png?1680160898"
        },
        {
          "chainId": 1,
          "address": "0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c",
          "name": "Digital Rand",
          "symbol": "DZAR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/9841/thumb/logo200_%281%29.png?1572577311"
        },
        {
          "chainId": 1,
          "address": "0xe45dfc26215312edc131e34ea9299fbca53275ca",
          "name": "Relation Native Token",
          "symbol": "REL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30656/thumb/2.png?1686124916"
        },
        {
          "chainId": 1,
          "address": "0x678e840c640f619e17848045d23072844224dd37",
          "name": "Cratos",
          "symbol": "CRTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17322/thumb/cratos.png?1627301460"
        },
        {
          "chainId": 1,
          "address": "0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656",
          "name": "Aave WBTC",
          "symbol": "AWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721"
        },
        {
          "chainId": 1,
          "address": "0x8b6cda5cc518c904e8844f445e1a7c7d2db0ff16",
          "name": "SF Capital",
          "symbol": "SFCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6239/thumb/36952079_1621155091328297_7456989147790573568_n.jpg?1547042274"
        },
        {
          "chainId": 1,
          "address": "0x0383e1c4af6fed9f127e901ca982fa61333e932c",
          "name": "Mr  Hankey",
          "symbol": "HANKEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30999/thumb/IMG_6836.jpeg?1689583717"
        },
        {
          "chainId": 1,
          "address": "0x9cc83d580180f0d37d00e5d86ce868f73b6e3d0a",
          "name": "Bitcointry Token",
          "symbol": "BTTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28641/thumb/bitcointry_token_logo_100x100.png?1672839869"
        },
        {
          "chainId": 1,
          "address": "0x441c3c2f4a92f1b90f916811514ebddbbd3bff4f",
          "name": "LFG coin",
          "symbol": "LFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30278/thumb/T207klVP_400x400.jpg?1683793651"
        },
        {
          "chainId": 1,
          "address": "0x0a6e18fb2842855c3af925310b0f50a4bfa17909",
          "name": "CoinPoker",
          "symbol": "CHP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1808/thumb/coinpoker.jpg?1547036113"
        },
        {
          "chainId": 1,
          "address": "0x3a0b022f32b3191d44e5847da12dc0b63fb07c91",
          "name": "Spellfire",
          "symbol": "SPELLFIRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23107/thumb/17316.png?1643261163"
        },
        {
          "chainId": 1,
          "address": "0xc53342fd7575f572b0ff4569e31941a5b821ac76",
          "name": "Ethereum Volatility Index Token",
          "symbol": "ETHV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16716/thumb/logo_-_2021-06-28T092549.772.png?1624843558"
        },
        {
          "chainId": 1,
          "address": "0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84",
          "name": "Aave LINK v1",
          "symbol": "ALINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11729/thumb/aLINK.png?1593084323"
        },
        {
          "chainId": 1,
          "address": "0x433fce7dfbec729a79999eaf056cb073b2153eba",
          "name": "CoinWealth",
          "symbol": "CNW",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522"
        },
        {
          "chainId": 1,
          "address": "0x9b39a0b97319a9bd5fed217c1db7b030453bac91",
          "name": "TigerCash",
          "symbol": "TCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378"
        },
        {
          "chainId": 1,
          "address": "0xba3335588d9403515223f109edc4eb7269a9ab5d",
          "name": "Gearbox",
          "symbol": "GEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21630/thumb/gear.png?1640330291"
        },
        {
          "chainId": 1,
          "address": "0x34c8b3fe84410f9b3870549e037fd1ea7b168977",
          "name": "BRUCE pepe",
          "symbol": "BRUCE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30544/thumb/token-200x200.png?1684984967"
        },
        {
          "chainId": 1,
          "address": "0x8ee325ae3e54e83956ef2d5952d3c8bc1fa6ec27",
          "name": "Fable Of The Dragon",
          "symbol": "TYRANT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27911/thumb/image_%283%29.png?1674184985"
        },
        {
          "chainId": 1,
          "address": "0x0b8057c3cc676c329c25c1d0cd27776efa73762d",
          "name": "Megaweapon",
          "symbol": "WEAPON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20833/thumb/weapon.png?1659792713"
        },
        {
          "chainId": 1,
          "address": "0xaac679720204aaa68b6c5000aa87d789a3ca0aa5",
          "name": "Huobi Bitcoin Cash",
          "symbol": "HBCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14105/thumb/HBCh.png?1614330789"
        },
        {
          "chainId": 1,
          "address": "0xcba78d126f0b1feda0c538bcaf4c852a7a171099",
          "name": "MOE",
          "symbol": "MOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31146/thumb/symbol-200.png?1691029653"
        },
        {
          "chainId": 1,
          "address": "0x785c34312dfa6b74f6f1829f79ade39042222168",
          "name": "Bumper",
          "symbol": "BUMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17822/thumb/Bumper-Icon-Inverse-200.png?1629344025"
        },
        {
          "chainId": 1,
          "address": "0xbf2179859fc6d5bee9bf9158632dc51678a4100e",
          "name": "aelf",
          "symbol": "ELF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1371/thumb/aelf-logo.png?1547035397"
        },
        {
          "chainId": 1,
          "address": "0x50bc2ecc0bfdf5666640048038c1aba7b7525683",
          "name": "carVertical",
          "symbol": "CV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2098/thumb/carvertical.png?1547738903"
        },
        {
          "chainId": 1,
          "address": "0x0ab87046fbb341d058f17cbc4c1133f25a20a52f",
          "name": "Governance OHM",
          "symbol": "GOHM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21129/thumb/token_wsOHM_logo.png?1638764900"
        },
        {
          "chainId": 1,
          "address": "0x198065e69a86cb8a9154b333aad8efe7a3c256f8",
          "name": "Koyo",
          "symbol": "KOY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29484/thumb/koyo200x200.png?1679135709"
        },
        {
          "chainId": 1,
          "address": "0xade6fdaba1643e4d1eef68da7170f234470938c6",
          "name": "Harambe",
          "symbol": "HARAMBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23489/thumb/unnamed.jpg?1644220775"
        },
        {
          "chainId": 1,
          "address": "0xc05d14442a510de4d3d71a3d316585aa0ce32b50",
          "name": "LINA",
          "symbol": "LINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5412/thumb/3083.png?1642981471"
        },
        {
          "chainId": 1,
          "address": "0xeadc218ac4cb7895a5a56e6484646b48f841c45a",
          "name": "MetaFinance",
          "symbol": "MFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG?1627435447"
        },
        {
          "chainId": 1,
          "address": "0x21381e026ad6d8266244f2a583b35f9e4413fa2a",
          "name": "Formation FI",
          "symbol": "FORM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16145/thumb/FORM.png?1623119824"
        },
        {
          "chainId": 1,
          "address": "0xebf4ca5319f406602eeff68da16261f1216011b5",
          "name": "Yobit",
          "symbol": "YO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9231/thumb/ybx_logo.gif?1565306320"
        },
        {
          "chainId": 1,
          "address": "0x65ccd72c0813ce6f2703593b633202a0f3ca6a0c",
          "name": "Nestree",
          "symbol": "EGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9362/thumb/nestree.png?1566529061"
        },
        {
          "chainId": 1,
          "address": "0xf6af18f94d55b8888b557cb3da0ec623ba245f80",
          "name": "SocialBlox",
          "symbol": "SBLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24769/thumb/x86VE7oQ.png?1648817788"
        },
        {
          "chainId": 1,
          "address": "0x4c25bdf026ea05f32713f00f73ca55857fbf6342",
          "name": "Font",
          "symbol": "FONT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14085/thumb/font.png?1614183855"
        },
        {
          "chainId": 1,
          "address": "0xffd822149fa6749176c7a1424e71a417f26189c8",
          "name": "Nothing Token",
          "symbol": "THING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30327/thumb/nothing.jpeg?1684135077"
        },
        {
          "chainId": 1,
          "address": "0x272f97b7a56a387ae942350bbc7df5700f8a4576",
          "name": "Aave BAL",
          "symbol": "ABAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17242/thumb/aBAL_2x.png?1626940749"
        },
        {
          "chainId": 1,
          "address": "0x66186008c1050627f979d464eabb258860563dbe",
          "name": "MediShares",
          "symbol": "MDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1343/thumb/medishares.png?1547978625"
        },
        {
          "chainId": 1,
          "address": "0x6400b5522f8d448c0803e6245436dd1c81df09ce",
          "name": "Conscious Value Network",
          "symbol": "CVNT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5158/thumb/cvnt.png?1634711407"
        },
        {
          "chainId": 1,
          "address": "0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361",
          "name": "Mysterium",
          "symbol": "MYST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/757/thumb/mysterium.png?1547034503"
        },
        {
          "chainId": 1,
          "address": "0x734c90044a0ba31b3f2e640c10dc5d3540499bfd",
          "name": "TradeStars",
          "symbol": "TSX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15229/thumb/WsO9siKG_400x400.png?1620167669"
        },
        {
          "chainId": 1,
          "address": "0xee42beb10dcd03775107f5ec56c3ae022f1072a4",
          "name": "Pepeplay",
          "symbol": "PEPLAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30341/thumb/Pepe.jpg?1684162349"
        },
        {
          "chainId": 1,
          "address": "0x7448c7456a97769f6cd04f1e83a4a23ccdc46abd",
          "name": "Maverick Protocol",
          "symbol": "MAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30850/thumb/MAV_Logo.png?1687945366"
        },
        {
          "chainId": 1,
          "address": "0x6f222e04f6c53cc688ffb0abe7206aac66a8ff98",
          "name": "Roko Network",
          "symbol": "ROKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29427/thumb/1.618_200.jpg?1678763790"
        },
        {
          "chainId": 1,
          "address": "0x75430d0782a443bd4f1c92c69009599dea53a206",
          "name": "Pick Or Rick",
          "symbol": "RICK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30653/thumb/Untitled_Artwork_8_%283%29_%281%29.png?1686123651"
        },
        {
          "chainId": 1,
          "address": "0x6bb61215298f296c55b19ad842d3df69021da2ef",
          "name": "Drops Ownership Power",
          "symbol": "DOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15696/thumb/dop.png?1640325078"
        },
        {
          "chainId": 1,
          "address": "0xcaa9ed6d7502595b555113d4517668ae24038c8a",
          "name": "Marvin Inu",
          "symbol": "MARVIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22039/thumb/lVshyCp.png?1640669485"
        },
        {
          "chainId": 1,
          "address": "0x8326bf664704966c984a3a46fa37d7a80a52dcf4",
          "name": "Dogu Inu",
          "symbol": "DOGU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28492/thumb/Coingecko_Dogu_Inu_logo.jpg?1671093591"
        },
        {
          "chainId": 1,
          "address": "0x3802c218221390025bceabbad5d8c59f40eb74b8",
          "name": "Guarded Ether",
          "symbol": "GETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14001/thumb/guarda-shield-logo.png?1613603915"
        },
        {
          "chainId": 1,
          "address": "0x3d2b66bc4f9d6388bd2d97b95b565be1686aefb3",
          "name": " LAMBO",
          "symbol": "LAMBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30126/thumb/IMG_20230502_225617_622.png?1683260153"
        },
        {
          "chainId": 1,
          "address": "0x4b13006980acb09645131b91d259eaa111eaf5ba",
          "name": "Mycelium",
          "symbol": "MYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26874/thumb/MYC_Token.png?1665971552"
        },
        {
          "chainId": 1,
          "address": "0x77777777772cf0455fb38ee0e75f38034dfa50de",
          "name": "XY Finance",
          "symbol": "XY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21541/thumb/xy.png?1639913622"
        },
        {
          "chainId": 1,
          "address": "0xe49214e4c92dc9bcb3b56c1309afe0d626dd730e",
          "name": "SynchroCoin",
          "symbol": "SYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1469/thumb/ud643DVg.png?1621828021"
        },
        {
          "chainId": 1,
          "address": "0x5b1d655c93185b06b00f7925791106132cb3ad75",
          "name": "DarkMatter",
          "symbol": "DMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23989/thumb/dmt.png?1645948367"
        },
        {
          "chainId": 1,
          "address": "0x0ab39ac604f992aaec3c36de337c3cd3917a7d26",
          "name": "KEEPs Coin",
          "symbol": "KVERSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG?1635498049"
        },
        {
          "chainId": 1,
          "address": "0x4ee438be38f8682abb089f2bfea48851c5e71eaf",
          "name": "Cryptonovae",
          "symbol": "YAE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14693/thumb/yae.png?1640337904"
        },
        {
          "chainId": 1,
          "address": "0x4d4f3715050571a447fffa2cd4cf091c7014ca5c",
          "name": "Summer",
          "symbol": "SUMMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25365/thumb/summer.png?1651317403"
        },
        {
          "chainId": 1,
          "address": "0x3a707d56d538e85b783e8ce12b346e7fb6511f90",
          "name": "Inverse Ethereum Volatility Index Token",
          "symbol": "IETHV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16715/thumb/logo_-_2021-06-28T085526.083.png?1624841740"
        },
        {
          "chainId": 1,
          "address": "0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4",
          "name": "CyberFi",
          "symbol": "CFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13112/thumb/cyberfi_logo.jpeg?1605283367"
        },
        {
          "chainId": 1,
          "address": "0x0c6bf55f60143fa1da202ac8c9b8ce484b5a5d90",
          "name": "Odasea",
          "symbol": "ODA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28914/thumb/photo_2023-03-10_22.40.00.jpeg?1678505487"
        },
        {
          "chainId": 1,
          "address": "0x383b0b2ce2e1757b5e1d087d8f36013ea595541a",
          "name": "Heal The World",
          "symbol": "HEAL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24123/thumb/GJe1qAQS_400x400.jpg?1646461929"
        },
        {
          "chainId": 1,
          "address": "0xa86a0da9d05d0771955df05b44ca120661af16de",
          "name": "OTCBTC",
          "symbol": "OTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5246/thumb/otcbtc-token.jpg?1547040734"
        },
        {
          "chainId": 1,
          "address": "0x3b544e6fcf6c8dce9d8b45a4fdf21c9b02f9fda9",
          "name": "Giftedhands",
          "symbol": "GHD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856"
        },
        {
          "chainId": 1,
          "address": "0x70bef3bb2f001da2fddb207dae696cd9faff3f5d",
          "name": "Ninja Squad",
          "symbol": "NST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22248/thumb/unnamed.jpg?1641277417"
        },
        {
          "chainId": 1,
          "address": "0x9e10f61749c4952c320412a6b26901605ff6da1d",
          "name": "Theos",
          "symbol": "THEOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18150/thumb/theos_logo.png?1630714224"
        },
        {
          "chainId": 1,
          "address": "0x89303500a7abfb178b274fd89f2469c264951e1f",
          "name": "Ref",
          "symbol": "REF",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2575/thumb/reftoken.png?1548608011"
        },
        {
          "chainId": 1,
          "address": "0x41c21693e60fc1a5dbb7c50e54e7a6016aa44c99",
          "name": "SO COL",
          "symbol": "SIMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28760/thumb/Frame_427318605.png?1673998547"
        },
        {
          "chainId": 1,
          "address": "0x09a3ecafa817268f77be1283176b946c4ff2e608",
          "name": "Mirror Protocol",
          "symbol": "MIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658"
        },
        {
          "chainId": 1,
          "address": "0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44",
          "name": "Whiteheart",
          "symbol": "WHITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13484/thumb/whiteheart.png?1609076548"
        },
        {
          "chainId": 1,
          "address": "0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4",
          "name": "DexKit",
          "symbol": "KIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13187/thumb/7739.png?1643187354"
        },
        {
          "chainId": 1,
          "address": "0x8dae6cb04688c62d939ed9b68d32bc62e49970b1",
          "name": "Aave CRV",
          "symbol": "ACRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17250/thumb/aCRV_2x.png?1626941642"
        },
        {
          "chainId": 1,
          "address": "0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198",
          "name": "Bankless DAO",
          "symbol": "BANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15227/thumb/j4WEJrwU.png?1622615796"
        },
        {
          "chainId": 1,
          "address": "0xa87ed75c257f1ec38393bea0a83d55ac2279d79c",
          "name": "EverEarn ETH",
          "symbol": "EARN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28925/thumb/200x200EverEarnETHc.png?1675482560"
        },
        {
          "chainId": 1,
          "address": "0xd9788f3931ede4d5018184e198699dc6d66c1915",
          "name": "Aave yVault",
          "symbol": "YVAAVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28784/thumb/yvAAVE-128-0xd9788f3931Ede4D5018184E198699dC6d66C1915.png?1674199536"
        },
        {
          "chainId": 1,
          "address": "0x07ef9e82721ac16809d24dafbe1792ce01654db4",
          "name": "Chimpion",
          "symbol": "BNANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7522/thumb/banana-token-logo.jpg?1548167777"
        },
        {
          "chainId": 1,
          "address": "0xc502002aeb1b9309fccb016adf50507987fc6c2b",
          "name": "GNFT",
          "symbol": "GNFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23532/thumb/gnft_200x200.png?1644386728"
        },
        {
          "chainId": 1,
          "address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
          "name": "Basic Attention",
          "symbol": "BAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427"
        },
        {
          "chainId": 1,
          "address": "0x05030203674173fa6df6f9f7e34d6e70e9a761d7",
          "name": "Muverse",
          "symbol": "MU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28857/thumb/20230130_100626.jpg?1675183941"
        },
        {
          "chainId": 1,
          "address": "0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050",
          "name": "Tracer DAO",
          "symbol": "TCR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18271/thumb/tracer_logo.png?1631176676"
        },
        {
          "chainId": 1,
          "address": "0xb2a63a5dd36c91ec2da59b188ff047f66fac122a",
          "name": "Alpha Impact",
          "symbol": "FOLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19988/thumb/folo.PNG?1636357322"
        },
        {
          "chainId": 1,
          "address": "0x0d02755a5700414b26ff040e1de35d337df56218",
          "name": "BendDAO",
          "symbol": "BEND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22829/thumb/benddao.PNG?1642664553"
        },
        {
          "chainId": 1,
          "address": "0x35bd01fc9d6d5d81ca9e055db88dc49aa2c699a8",
          "name": "Friends With Benefits Pro",
          "symbol": "FWB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14391/thumb/xRGEXmQN_400x400.png?1615868085"
        },
        {
          "chainId": 1,
          "address": "0xb97048628db6b661d4c2aa833e95dbe1a905b280",
          "name": "TenX",
          "symbol": "PAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/775/thumb/TenX-Icon-CircleBlack.png?1553766360"
        },
        {
          "chainId": 1,
          "address": "0x10be9a8dae441d276a5027936c3aaded2d82bc15",
          "name": "UniMex Network",
          "symbol": "UMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13615/thumb/sloYxHx.jpeg?1633154340"
        },
        {
          "chainId": 1,
          "address": "0x9d71ce49ab8a0e6d2a1e7bfb89374c9392fd6804",
          "name": "NvirWorld",
          "symbol": "NVIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21359/thumb/Nvir_symbol.png?1639013234"
        },
        {
          "chainId": 1,
          "address": "0x246908bff0b1ba6ecadcf57fb94f6ae2fcd43a77",
          "name": "Divi",
          "symbol": "DIVI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1273/thumb/red_logomark.png?1590383789"
        },
        {
          "chainId": 1,
          "address": "0x927159670c50042109d7c0f4aed0cee89452433e",
          "name": "DGPayment",
          "symbol": "DGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13291/thumb/dgpay-icon-logo.png?1607062736"
        },
        {
          "chainId": 1,
          "address": "0x833d94797cbea8279a689572b01f310ff5ffa31b",
          "name": "PixiaAI",
          "symbol": "PIXIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28941/thumb/PixiaAi.png?1675568793"
        },
        {
          "chainId": 1,
          "address": "0x25d4e7fe624fcc6e7f0b52d142f40c9acecf039a",
          "name": "Kennel",
          "symbol": "KENNEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29292/thumb/kennel.png?1678183228"
        },
        {
          "chainId": 1,
          "address": "0x0ec72cd6690db40b16be166858299f19d4f8e5b0",
          "name": "Teh Golden One",
          "symbol": "GOLD1",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27290/thumb/teh_golden_one_200x200.png?1663147215"
        },
        {
          "chainId": 1,
          "address": "0x4e114d405b9ba2f59524941733e505ae03fb1fb5",
          "name": "Joystick",
          "symbol": "JOY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27666/thumb/awKbFbPi_400x400.jpg?1665140248"
        },
        {
          "chainId": 1,
          "address": "0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4",
          "name": "Router Protocol",
          "symbol": "ROUTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13709/thumb/route_token_200x200-19.png?1611057698"
        },
        {
          "chainId": 1,
          "address": "0x8d96b4ab6c741a4c8679ae323a100d74f085ba8f",
          "name": "Bazaars",
          "symbol": "BZR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26392/thumb/bazaars_logo-color-black-icon.png?1657753265"
        },
        {
          "chainId": 1,
          "address": "0x93ef1ea305d11a9b2a3ebb9bb4fcc34695292e7d",
          "name": "queenETH",
          "symbol": "QETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30027/thumb/queeneth.png?1686038310"
        },
        {
          "chainId": 1,
          "address": "0x86efc496dca70bcfd92d19194290e8457a375773",
          "name": "Silent Notary",
          "symbol": "UBSN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/1599/thumb/silent-notary.png?1548609544"
        },
        {
          "chainId": 1,
          "address": "0x65a8fba02f641a13bb7b01d5e1129b0521004f52",
          "name": "Amasa",
          "symbol": "AMAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18799/thumb/agmqWjv8_400x400.png?1633473272"
        },
        {
          "chainId": 1,
          "address": "0xd5df655087d99b7b720a5bc8711f296180a4f44b",
          "name": "Catgirl Optimus",
          "symbol": "OPTIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29669/thumb/Optigpng.png?1680574307"
        },
        {
          "chainId": 1,
          "address": "0xa888d9616c2222788fa19f05f77221a290eef704",
          "name": "Daruma",
          "symbol": "DARUMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27357/thumb/CGlogo.png?1663661942"
        },
        {
          "chainId": 1,
          "address": "0x198d7387fa97a73f05b8578cdeff8f2a1f34cd1f",
          "name": "Wrapped Jones AURA",
          "symbol": "WJAURA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30103/thumb/geckoAura.png?1683081004"
        },
        {
          "chainId": 1,
          "address": "0xe65cdb6479bac1e22340e4e755fae7e509ecd06c",
          "name": "cAAVE",
          "symbol": "CAAVE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17527/thumb/caave.PNG?1628122171"
        },
        {
          "chainId": 1,
          "address": "0xf1e345ea7c33fd6c05f5512a780eb5839ee31674",
          "name": "Telefy",
          "symbol": "TELE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26485/thumb/WhatsApp_Image_2022-07-20_at_2.51.42_AM.jpeg?1658281111"
        },
        {
          "chainId": 1,
          "address": "0x516e2758b044433371076a48127b8cfa7b0bdb43",
          "name": "Smudge Lord",
          "symbol": "SMUDGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30268/thumb/logo-A.png?1683776274"
        },
        {
          "chainId": 1,
          "address": "0x310c8f00b9de3c31ab95ea68feb6c877538f7947",
          "name": "Undead Blocks",
          "symbol": "UNDEAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25382/thumb/AB0Uemhlw6jR.png?1651488079"
        },
        {
          "chainId": 1,
          "address": "0x04abeda201850ac0124161f037efd70c74ddc74c",
          "name": "Nest Protocol",
          "symbol": "NEST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539"
        },
        {
          "chainId": 1,
          "address": "0x96eafff5bedf18566b18fce71c2323b69c795623",
          "name": "Astropup Coin",
          "symbol": "ASPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30693/thumb/ASTROPUP_COIN.png?1686281877"
        },
        {
          "chainId": 1,
          "address": "0x746dda2ea243400d5a63e0700f190ab79f06489e",
          "name": "BOSagora",
          "symbol": "BOA",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/9202/thumb/Picture1.png?1673623413"
        },
        {
          "chainId": 1,
          "address": "0x37e83a94c6b1bdb816b59ac71dd02cf154d8111f",
          "name": "PhotoChromic",
          "symbol": "PHCR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23640/thumb/L7vabJibqXHHmGtsIfIEQRaR3SjaE94QSaKWGlqYU7fpuz--nKsWG4BlB6Ddhn5-tGPWo-MDhfZPVAYG7ZM2vpWrVZlSx1Euw78tZcNey6FvpKX6a8w26qBWYeV7wxFzvW7VKieijrMXQ0BkISpt8d6jrCMBvx2ZFKVxF1x31_2X7WhRyqAgIG3epFPNz7v0j0ECaS7HmE0_sTHM4RS3CW1d0a.jpg?1644908390"
        },
        {
          "chainId": 1,
          "address": "0xc7f950271d118a5bdf250dffc39128dcced8472c",
          "name": "Chainback",
          "symbol": "ARCHIVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30580/thumb/Chainback-glyph-logo-200.png?1685416082"
        },
        {
          "chainId": 1,
          "address": "0xc0ec8caec55f37d47fbfa595727418868a21fd48",
          "name": "EcoG9coin",
          "symbol": "EGC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8038/thumb/Screenshot_2019-12-05_at_7.14.34_AM.png?1575501284"
        },
        {
          "chainId": 1,
          "address": "0x320d31183100280ccdf69366cd56180ea442a3e8",
          "name": "Lightcoin",
          "symbol": "LHC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG?1623835248"
        },
        {
          "chainId": 1,
          "address": "0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932",
          "name": "ProStarter",
          "symbol": "PROT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15259/thumb/rpot.PNG?1620269970"
        },
        {
          "chainId": 1,
          "address": "0x01022591fce0609b57670beba60102dfd2b95d77",
          "name": "Hitop",
          "symbol": "HITOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25959/thumb/20540.png?1654860802"
        },
        {
          "chainId": 1,
          "address": "0x0bf43350076f95e0d16120b4d6bdfa1c9d50bdbd",
          "name": "Antfarm Governance Token",
          "symbol": "AGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28822/thumb/AGT.png?1681189764"
        },
        {
          "chainId": 1,
          "address": "0x0e9cc0f7e550bd43bd2af2214563c47699f96479",
          "name": "UnleashClub",
          "symbol": "UNLEASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30804/thumb/UnleashClub.png?1687413910"
        },
        {
          "chainId": 1,
          "address": "0x8013266cb5c9dd48be3ad7d1ce832874d64b3ce1",
          "name": "Boop",
          "symbol": "BOOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27918/thumb/boop.png?1666351418"
        },
        {
          "chainId": 1,
          "address": "0x7ae0d42f23c33338de15bfa89c7405c068d9dc0a",
          "name": "Shibaverse",
          "symbol": "VERSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18407/thumb/logo_200.png?1631795328"
        },
        {
          "chainId": 1,
          "address": "0x8810c63470d38639954c6b41aac545848c46484a",
          "name": "Aditus",
          "symbol": "ADI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1867/thumb/adi.png?1514446309"
        },
        {
          "chainId": 1,
          "address": "0xbc5991ccd8caceba01edc44c2bb9832712c29cab",
          "name": "Unagii USD Coin",
          "symbol": "UUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13781/thumb/uUSDC.png?1611729843"
        },
        {
          "chainId": 1,
          "address": "0x6c22910c6f75f828b305e57c6a54855d8adeabf8",
          "name": "Satoshis Vision",
          "symbol": "SATS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29303/thumb/SATS_123-removebg-preview_%281%29.png?1677918446"
        },
        {
          "chainId": 1,
          "address": "0x355a824bea1adc22733978a3748271e1bbb34130",
          "name": "Metanept",
          "symbol": "NEPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26798/thumb/OH1UiJ16_400x400.jpeg?1660202251"
        },
        {
          "chainId": 1,
          "address": "0xcc779bbbd17b2e68ecdccaea3d8c791b1573c66b",
          "name": "Fetch",
          "symbol": "FETCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29402/thumb/Fetch.png?1678514964"
        },
        {
          "chainId": 1,
          "address": "0xfa5b75a9e13df9775cf5b996a049d9cc07c15731",
          "name": "28VCK",
          "symbol": "VCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20063/thumb/9435.png?1636446186"
        },
        {
          "chainId": 1,
          "address": "0x7240ac91f01233baaf8b064248e80feaa5912ba3",
          "name": "OctoFi",
          "symbol": "OCTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12594/thumb/octofi-256x256-radius-22percent.png?1610679969"
        },
        {
          "chainId": 1,
          "address": "0xa80f2c8f61c56546001f5fc2eb8d6e4e72c45d4c",
          "name": "Unique Utility",
          "symbol": "UNQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23382/thumb/D31DBA6B-FB7E-4ACB-82B4-98E255C1C483.jpg?1643962784"
        },
        {
          "chainId": 1,
          "address": "0xbb3a8fd6ec4bf0fdc6cd2739b1e41192d12b1873",
          "name": "Orbofi AI",
          "symbol": "OBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30216/thumb/new_V_test2.png?1683626303"
        },
        {
          "chainId": 1,
          "address": "0xda8263d8ce3f726233f8b5585bcb86a3120a58b6",
          "name": "DogeClub",
          "symbol": "DOGC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30733/thumb/200*200.png?1686650076"
        },
        {
          "chainId": 1,
          "address": "0x374cb8c27130e2c9e04f44303f3c8351b9de61c1",
          "name": "Bao Finance",
          "symbol": "BAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13547/thumb/BaoLogo.png?1615342483"
        },
        {
          "chainId": 1,
          "address": "0x4d224452801aced8b2f0aebe155379bb5d594381",
          "name": "ApeCoin",
          "symbol": "APE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24383/thumb/apecoin.jpg?1647476455"
        },
        {
          "chainId": 1,
          "address": "0x24a6a37576377f63f194caa5f518a60f45b42921",
          "name": "Float Protocol",
          "symbol": "BANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14116/thumb/FLOAT-Bank_LOGO-reduced_01.png?1616573606"
        },
        {
          "chainId": 1,
          "address": "0x6fc73113fc1afab4c28d3dd4c537a1da6045d47d",
          "name": "TrendAI",
          "symbol": "TRENDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29369/thumb/coingecko.png?1678349231"
        },
        {
          "chainId": 1,
          "address": "0xaaa9214f675316182eaa21c85f0ca99160cc3aaa",
          "name": "QANplatform",
          "symbol": "QANX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15977/thumb/qanx.png?1637574290"
        },
        {
          "chainId": 1,
          "address": "0x9565c2036963697786705120fc59310f747bcfd0",
          "name": "PoorPleb",
          "symbol": "PP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28063/thumb/PP_TOKEN_LOGO.png?1669366801"
        },
        {
          "chainId": 1,
          "address": "0xb1e4a5f70090947e6ad3fa2dc648cba7b6f913e9",
          "name": "Philosoraptor",
          "symbol": "RAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30892/thumb/photo_2023-07-02_10-26-13.jpg?1688541794"
        },
        {
          "chainId": 1,
          "address": "0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f",
          "name": "Must",
          "symbol": "MUST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13688/thumb/must_logo.png?1610949645"
        },
        {
          "chainId": 1,
          "address": "0x8eecaad83a1ea77bd88a818d4628fafc4cad7969",
          "name": "Not Financial Advice",
          "symbol": "NFAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28758/thumb/logo-200x200-white.png?1684830246"
        },
        {
          "chainId": 1,
          "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
          "name": "Chainlink",
          "symbol": "LINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700"
        },
        {
          "chainId": 1,
          "address": "0x5c0217e4e126d501896594bec409898a9afc5970",
          "name": "Frens Coin",
          "symbol": "FRENS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30470/thumb/Frens_200x200.png?1684828434"
        },
        {
          "chainId": 1,
          "address": "0x358aa737e033f34df7c54306960a38d09aabd523",
          "name": "Ares Protocol",
          "symbol": "ARES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png?1620638611"
        },
        {
          "chainId": 1,
          "address": "0x8c15ef5b4b21951d50e53e4fbda8298ffad25057",
          "name": "Function X",
          "symbol": "FX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683"
        },
        {
          "chainId": 1,
          "address": "0xd578779dbc9252218e12d18d628e3cb27e4a56f2",
          "name": "Britto",
          "symbol": "BRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22830/thumb/britto-coin-logo.jpg?1642664707"
        },
        {
          "chainId": 1,
          "address": "0xe7ae6d0c56cacaf007b7e4d312f9af686a9e9a04",
          "name": "Vabble",
          "symbol": "VAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17508/thumb/vabble_tplogo_200_x_200.png?1628508875"
        },
        {
          "chainId": 1,
          "address": "0xc91a71a1ffa3d8b22ba615ba1b9c01b2bbbf55ad",
          "name": "ZigZag",
          "symbol": "ZZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26141/thumb/zig_zag.?1656248564"
        },
        {
          "chainId": 1,
          "address": "0x490bbbc2485e99989ba39b34802fafa58e26aba4",
          "name": "Morpho Aave Wrapped Ether",
          "symbol": "MAWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29873/thumb/maWETH.jpg?1681894501"
        },
        {
          "chainId": 1,
          "address": "0x5a035e3f1551a15230d0cde3357fb1bf89369261",
          "name": "Wooonen",
          "symbol": "WOOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30909/thumb/SGpzsmu.jpg?1688628357"
        },
        {
          "chainId": 1,
          "address": "0x32ea3dc70e2962334864a9665254d2433e4ddbfd",
          "name": "Sportzchain",
          "symbol": "SPN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24915/thumb/Sportzchain_logo_195_195.png?1650240200"
        },
        {
          "chainId": 1,
          "address": "0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20",
          "name": "DuckDaoDime",
          "symbol": "DDIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12146/thumb/token_DDIM-01.png?1606982032"
        },
        {
          "chainId": 1,
          "address": "0x3d9a4d8ab4f5bd1d5d08ae3a95e8ed8bb4d7e3b9",
          "name": "STONKSDAO",
          "symbol": "STONKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29976/thumb/Final_Logo_%281%29_%281%29.png?1682402894"
        },
        {
          "chainId": 1,
          "address": "0x6982508145454ce325ddbe47a25d4ec3d2311933",
          "name": "Pepe",
          "symbol": "PEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29850/thumb/pepe-token.jpeg?1682922725"
        },
        {
          "chainId": 1,
          "address": "0x8a6d4c8735371ebaf8874fbd518b56edd66024eb",
          "name": "BLOCKS",
          "symbol": "BLOCKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19666/thumb/blocks_logo_200200.png?1681972436"
        },
        {
          "chainId": 1,
          "address": "0x5b71bee9d961b1b848f8485eec8d8787f80217f5",
          "name": "Bitforex",
          "symbol": "BF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5330/thumb/BitForex-Logo.png?1573808168"
        },
        {
          "chainId": 1,
          "address": "0xf56408077487cb879c992909c5b5c66d68c02eb4",
          "name": "Riot Racers",
          "symbol": "RIOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19238/thumb/jyxvIbmJ_400x400.png?1634774849"
        },
        {
          "chainId": 1,
          "address": "0x37e1160184f7dd29f00b78c050bf13224780b0b0",
          "name": "Yuan Chain Coin",
          "symbol": "YCC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5328/thumb/G5gCEo-J_400x400.jpg?1547040897"
        },
        {
          "chainId": 1,
          "address": "0x4efe8665e564bf454ccf5c90ee16817f7485d5cf",
          "name": "BlackDragon",
          "symbol": "BDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13426/thumb/Black-Dragon-Black.png?1608515220"
        },
        {
          "chainId": 1,
          "address": "0xa0d69e286b938e21cbf7e51d71f6a4c8918f482f",
          "name": "Electronic USD",
          "symbol": "EUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28445/thumb/0xa0d69e286b938e21cbf7e51d71f6a4c8918f482f.png?1677723145"
        },
        {
          "chainId": 1,
          "address": "0x3231cb76718cdef2155fc47b5286d82e6eda273f",
          "name": "Monerium EUR emoney",
          "symbol": "EURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23354/thumb/eur.png?1643926562"
        },
        {
          "chainId": 1,
          "address": "0x2001f2a0cf801ecfda622f6c28fb6e10d803d969",
          "name": "CoinLoan",
          "symbol": "CLT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1713/thumb/blue-logo-transparent-200x200.png?1595912819"
        },
        {
          "chainId": 1,
          "address": "0x508df5aa4746be37b5b6a69684dfd8bdc322219d",
          "name": "Crafting Finance",
          "symbol": "CRF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21559/thumb/tokenlogo.png?1639611564"
        },
        {
          "chainId": 1,
          "address": "0x8af5fedc0f263841c18f31d9dbcc97a47e1ab462",
          "name": "MESSIER",
          "symbol": "M87",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25957/thumb/MESSIERlogonew_%281%29.png?1666773848"
        },
        {
          "chainId": 1,
          "address": "0x48c276e8d03813224bb1e55f953adb6d02fd3e02",
          "name": "Kuma Inu",
          "symbol": "KUMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15526/thumb/kuma_inu.PNG?1621128824"
        },
        {
          "chainId": 1,
          "address": "0x849c479d7a90eb378dbd00e8f166371176244eb1",
          "name": "MUU",
          "symbol": "MUU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25620/thumb/muu.jpg?1662436796"
        },
        {
          "chainId": 1,
          "address": "0xf5717f5df41ea67ef67dfd3c1d02f9940bcf5d08",
          "name": "SeChain",
          "symbol": "SNN",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536"
        },
        {
          "chainId": 1,
          "address": "0xd945d2031b4c63c0e363304fb771f709b502dc0a",
          "name": "BountyMarketCap",
          "symbol": "BMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13472/thumb/bmc-logo-200.png?1608852915"
        },
        {
          "chainId": 1,
          "address": "0xe2f2a5c287993345a840db3b0845fbc70f5935a5",
          "name": "mStable USD",
          "symbol": "MUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11576/thumb/mStable_USD.png?1595591803"
        },
        {
          "chainId": 1,
          "address": "0x77607588222e01bf892a29abab45796a2047fc7b",
          "name": "Unagii ETH",
          "symbol": "UETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14624/thumb/uETH.png?1617294107"
        },
        {
          "chainId": 1,
          "address": "0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6",
          "name": "Raiden Network",
          "symbol": "RDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1132/thumb/raiden-logo.jpg?1547035131"
        },
        {
          "chainId": 1,
          "address": "0xd2adc1c84443ad06f0017adca346bd9b6fc52cab",
          "name": "dFund",
          "symbol": "DFND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15286/thumb/1zbdX36.png?1620355973"
        },
        {
          "chainId": 1,
          "address": "0x3adfc4999f77d04c8341bac5f3a76f58dff5b37a",
          "name": "Privatix",
          "symbol": "PRIX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1183/thumb/dZZrRl8.png?1547035211"
        },
        {
          "chainId": 1,
          "address": "0x2c022e58c5e3ee213f06f975fd8a0d3a6fe9ca1c",
          "name": "FINU",
          "symbol": "FINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29309/thumb/FINU_200x200.png?1685610757"
        },
        {
          "chainId": 1,
          "address": "0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9",
          "name": "Deri Protocol",
          "symbol": "DERI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg?1627649443"
        },
        {
          "chainId": 1,
          "address": "0xaa68fd12a3b0f7aea66fe8f7111ae3f8d9ac5058",
          "name": "Tweety",
          "symbol": "TWEETY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30861/thumb/tweety_logo.png?1688367593"
        },
        {
          "chainId": 1,
          "address": "0x298d492e8c1d909d3f63bc4a36c66c64acb3d695",
          "name": "PolkaBridge",
          "symbol": "PBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png?1611377553"
        },
        {
          "chainId": 1,
          "address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
          "name": "Synthetix Network",
          "symbol": "SNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3406/thumb/SNX.png?1598631139"
        },
        {
          "chainId": 1,
          "address": "0x8cc0f052fff7ead7f2edcccac895502e884a8a71",
          "name": "ARTH",
          "symbol": "ARTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16876/thumb/Ik5dhOq.png?1674126397"
        },
        {
          "chainId": 1,
          "address": "0x7237c0b30b1355f1b76355582f182f6f04b08740",
          "name": "MetaGaming Guild",
          "symbol": "MGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010"
        },
        {
          "chainId": 1,
          "address": "0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0",
          "name": "Loom Network  OLD ",
          "symbol": "LOOMOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3387/thumb/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png?1547038040"
        },
        {
          "chainId": 1,
          "address": "0x6bc08509b36a98e829dffad49fde5e412645d0a3",
          "name": "WoofWork io",
          "symbol": "WOOF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28903/thumb/WWlogoTransparent_200x200.png?1675241564"
        },
        {
          "chainId": 1,
          "address": "0xb1e93236ab6073fdac58ada5564897177d4bcc43",
          "name": "Seele",
          "symbol": "SEELE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3294/thumb/Seele.png?1649325804"
        },
        {
          "chainId": 1,
          "address": "0xf4d2888d29d722226fafa5d9b24f9164c092421e",
          "name": "LooksRare",
          "symbol": "LOOKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22173/thumb/circle-black-256.png?1641173160"
        },
        {
          "chainId": 1,
          "address": "0xeed3ae7b0f8b5b9bb8c035a9941382b1822671cd",
          "name": "EveryCoin",
          "symbol": "EVY",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/5629/thumb/TutkUC1x_400x400_%281%29.jpg?1547447105"
        },
        {
          "chainId": 1,
          "address": "0x0db8d8b76bc361bacbb72e2c491e06085a97ab31",
          "name": "IQeon",
          "symbol": "IQN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1736/thumb/IQN_logo_64x64.png?1547036010"
        },
        {
          "chainId": 1,
          "address": "0x55296f69f40ea6d20e478533c15a6b08b654e758",
          "name": "XYO Network",
          "symbol": "XYO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819"
        },
        {
          "chainId": 1,
          "address": "0x7e9d8f07a64e363e97a648904a89fb4cd5fb94cd",
          "name": "Forefront",
          "symbol": "FF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14659/thumb/N2kir6jx_400x400.jpg?1617608020"
        },
        {
          "chainId": 1,
          "address": "0xbb556b0ee2cbd89ed95ddea881477723a3aa8f8b",
          "name": "AliceNet",
          "symbol": "ALCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29417/thumb/LinkedIn_-_Profile_300_x_300.png?1678673329"
        },
        {
          "chainId": 1,
          "address": "0x01b23286ff60a543ec29366ae8d6b6274ca20541",
          "name": "Brother Music Platform",
          "symbol": "BMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13207/thumb/BMP_logo.png?1606199707"
        },
        {
          "chainId": 1,
          "address": "0xe67f943af5eb6051ef56f05979cc30b732717fa6",
          "name": "WATTTON",
          "symbol": "WATT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/27215/thumb/CG_WATT_LOGO.png?1662611245"
        },
        {
          "chainId": 1,
          "address": "0x17d2628d30f8e9e966c9ba831c9b9b01ea8ea75c",
          "name": "ISKRA Token",
          "symbol": "ISK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27428/thumb/ISKRA_logo.png?1663919121"
        },
        {
          "chainId": 1,
          "address": "0x4af5ff1a60a6ef6c7c8f9c4e304cd9051fca3ec0",
          "name": "Rigel Protocol",
          "symbol": "RGP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15837/thumb/A_qRYvB2_400x400.png?1622080940"
        },
        {
          "chainId": 1,
          "address": "0xc1f33e0cf7e40a67375007104b929e49a581bafe",
          "name": "Spot",
          "symbol": "SPOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28426/thumb/SPOT_Logo_200x200_sq_small_centered.png?1675591592"
        },
        {
          "chainId": 1,
          "address": "0x915ea4a94b61b138b568169122903ed707a8e704",
          "name": "Wrapped Ordichains",
          "symbol": "WOICH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30787/thumb/2000_2000.png?1687249422"
        },
        {
          "chainId": 1,
          "address": "0x1c95b093d6c236d3ef7c796fe33f9cc6b8606714",
          "name": "BOMB",
          "symbol": "BOMB",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/7713/thumb/Bomb-token.png?1549944422"
        },
        {
          "chainId": 1,
          "address": "0x0a7b89e66a1dc16633abdfd132bae05827d3bfa5",
          "name": "hiMOONBIRDS",
          "symbol": "HIMOONBIRDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28663/thumb/himoonbirds.png?1672985604"
        },
        {
          "chainId": 1,
          "address": "0xe03b2642a5111ad0efc0cbce766498c2dd562ae9",
          "name": "Old Bitcoin",
          "symbol": "BC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28832/thumb/bitcoinlogo.png?1674790389"
        },
        {
          "chainId": 1,
          "address": "0xc0eb85285d83217cd7c891702bcbc0fc401e2d9d",
          "name": "Hiveterminal",
          "symbol": "HVN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/899/thumb/Hiveterminal_token.jpg?1547034726"
        },
        {
          "chainId": 1,
          "address": "0x3819f64f282bf135d62168c1e513280daf905e06",
          "name": "Hedron",
          "symbol": "HDRN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24208/thumb/hdrn.png?1647079428"
        },
        {
          "chainId": 1,
          "address": "0x47da5456bc2e1ce391b645ce80f2e97192e4976a",
          "name": "PL Gnet",
          "symbol": "PLUG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15824/thumb/PLUG_token.png?1622007572"
        },
        {
          "chainId": 1,
          "address": "0x39aa39c021dfbae8fac545936693ac917d5e7563",
          "name": "cUSDC",
          "symbol": "CUSDC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9442/thumb/Compound_USDC.png?1567581577"
        },
        {
          "chainId": 1,
          "address": "0xb4272071ecadd69d933adcd19ca99fe80664fc08",
          "name": "CryptoFranc",
          "symbol": "XCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8465/thumb/WhuiuJBc_400x400.jpg?1558699947"
        },
        {
          "chainId": 1,
          "address": "0x6d0f5149c502faf215c89ab306ec3e50b15e2892",
          "name": "Portion",
          "symbol": "PRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13617/thumb/OKeO2FI.png?1610327038"
        },
        {
          "chainId": 1,
          "address": "0x001a8ffcb0f03e99141652ebcdecdb0384e3bd6c",
          "name": "Polker",
          "symbol": "PKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png?1625589565"
        },
        {
          "chainId": 1,
          "address": "0x3bdffa70f4b4e6985eed50453c7c0d4a15dcec52",
          "name": "TiTi Governance Token",
          "symbol": "TITI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30561/thumb/TiTi.png?1685019688"
        },
        {
          "chainId": 1,
          "address": "0xe973e453977195422b48e1852a207b7ee9c913c7",
          "name": "ADreward",
          "symbol": "AD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29639/thumb/ADreward_AD_Logo_CoinGecko_200X200_%EB%B6%88%ED%88%AC%EB%AA%85.png?1680161036"
        },
        {
          "chainId": 1,
          "address": "0x6069c9223e8a5da1ec49ac5525d4bb757af72cd8",
          "name": "MUSK Gold",
          "symbol": "MUSK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21696/thumb/musk-icon-200x200.png?1649329802"
        },
        {
          "chainId": 1,
          "address": "0xe50365f5d679cb98a1dd62d6f6e58e59321bcddf",
          "name": "LA",
          "symbol": "LA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1051/thumb/LA_token.png?1605772040"
        },
        {
          "chainId": 1,
          "address": "0xb10cc888cb2cce7036f4c7ecad8a57da16161338",
          "name": "Switch Token",
          "symbol": "SWITCH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30140/thumb/SWITCH-200x200-Circle_Token.jpg?1683277550"
        },
        {
          "chainId": 1,
          "address": "0xd7c9f0e536dc865ae858b0c0453fe76d13c3beac",
          "name": "XAI Stablecoin",
          "symbol": "XAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28254/thumb/Xai_logo.png?1668674085"
        },
        {
          "chainId": 1,
          "address": "0x0af55d5ff28a3269d69b98680fd034f115dd53ac",
          "name": "BankSocial",
          "symbol": "BSL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png?1621685752"
        },
        {
          "chainId": 1,
          "address": "0xf0edac27aa3e85e2d176f689b0025f90c154393a",
          "name": "I LOVE SNOOPY",
          "symbol": "LOVESNOOPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30340/thumb/dAlRqD6r_400x400.jpg?1684160560"
        },
        {
          "chainId": 1,
          "address": "0xea1ea0972fa092dd463f2968f9bb51cc4c981d71",
          "name": "Modefi",
          "symbol": "MOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13980/thumb/modefi_logo.png?1613453111"
        },
        {
          "chainId": 1,
          "address": "0x1b8568fbb47708e9e9d31ff303254f748805bf21",
          "name": "Scarcity",
          "symbol": "SCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19064/thumb/scx_gecko.png?1634266881"
        },
        {
          "chainId": 1,
          "address": "0x93eeb426782bd88fcd4b48d7b0368cf061044928",
          "name": "The Rug Game",
          "symbol": "TRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28750/thumb/TRG_Logo_Circular_200px.png?1673925129"
        },
        {
          "chainId": 1,
          "address": "0xf80d589b3dbe130c270a69f1a69d050f268786df",
          "name": "Datamine",
          "symbol": "DAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11695/thumb/qxsFH8W.png?1592880463"
        },
        {
          "chainId": 1,
          "address": "0xacb47686b92fdde6d233ec6445c1f8d4d0d59c38",
          "name": "Swipe Token",
          "symbol": "SWIPE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30601/thumb/SWIPE_%28200_%C3%97_200_px%29.png?1685515784"
        },
        {
          "chainId": 1,
          "address": "0x0fd3822072ad001aac1c90a09d9506f097f24458",
          "name": "NOA PLAY",
          "symbol": "NOA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15723/thumb/XHFjmBTx_400x400.png?1621635644"
        },
        {
          "chainId": 1,
          "address": "0xdbdd6f355a37b94e6c7d32fef548e98a280b8df5",
          "name": "UniWhales",
          "symbol": "UWL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13166/thumb/uniwhale.png?1611967645"
        },
        {
          "chainId": 1,
          "address": "0x15dd37a83564559927bbdcdbfcf85f60a2c6a419",
          "name": "StonkLeague",
          "symbol": "AEGIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26294/thumb/iShot_2022-07-07_10.44.16.png?1657161870"
        },
        {
          "chainId": 1,
          "address": "0xa9d2927d3a04309e008b6af6e2e282ae2952e7fd",
          "name": "Zipper Network",
          "symbol": "ZIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4524/thumb/zip_token_logo.png?1547039822"
        },
        {
          "chainId": 1,
          "address": "0xcafe001067cdef266afb7eb5a286dcfd277f3de5",
          "name": "ParaSwap",
          "symbol": "PSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20403/thumb/ep7GqM19_400x400.jpg?1636979120"
        },
        {
          "chainId": 1,
          "address": "0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f",
          "name": "GHO",
          "symbol": "GHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30663/thumb/ghoaave.jpeg?1686151372"
        },
        {
          "chainId": 1,
          "address": "0xb1191f691a355b43542bea9b8847bc73e7abb137",
          "name": "KIRO",
          "symbol": "KIRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12688/thumb/logo_kirobo-04.png?1668683315"
        },
        {
          "chainId": 1,
          "address": "0x92d529163c5e880b9de86f01de0cb8924d790357",
          "name": "Eyeverse",
          "symbol": "EYE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28623/thumb/EYE.png?1672709171"
        },
        {
          "chainId": 1,
          "address": "0xc62def1701309bb76e6b39b6ab8b5fac910a3c87",
          "name": "MetaRare",
          "symbol": "MTRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24276/thumb/Logo_200x200_PNG.png?1647180519"
        },
        {
          "chainId": 1,
          "address": "0x1dea979ae76f26071870f824088da78979eb91c8",
          "name": "SPINDLE",
          "symbol": "SPD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3714/thumb/spindle-core-logo.png?1547038741"
        },
        {
          "chainId": 1,
          "address": "0x96610186f3ab8d73ebee1cf950c750f3b1fb79c2",
          "name": "Enjinstarter",
          "symbol": "EJS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg?1633297242"
        },
        {
          "chainId": 1,
          "address": "0x578dc16c47327429ff1594fa3eb77a96f38438e0",
          "name": "Paradigm Zero",
          "symbol": "PZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28920/thumb/PZ.jpg?1675407490"
        },
        {
          "chainId": 1,
          "address": "0xd0cd466b34a24fcb2f87676278af2005ca8a78c4",
          "name": "Popcorn",
          "symbol": "POP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611"
        },
        {
          "chainId": 1,
          "address": "0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af",
          "name": "K21",
          "symbol": "K21",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14466/thumb/k21-token.png?1616775801"
        },
        {
          "chainId": 1,
          "address": "0x9f009d03e1b7f02065017c90e8e0d5cb378eb015",
          "name": "Fortune",
          "symbol": "FORTUNE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20374/thumb/TZcfq5b_%281%29.png?1636949601"
        },
        {
          "chainId": 1,
          "address": "0x4c601dc69affb0d4fc8de1ac303705e432a4a27e",
          "name": "Konnect",
          "symbol": "KCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25756/thumb/EAO_5JhW_400x400.jpg?1653516279"
        },
        {
          "chainId": 1,
          "address": "0x900db999074d9277c5da2a43f252d74366230da0",
          "name": "Giveth",
          "symbol": "GIV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21792/thumb/GIVToken_200x200.png?1640055088"
        },
        {
          "chainId": 1,
          "address": "0x53340a1ef3a0ddeba1d94bbd1e2ff55936f0ea60",
          "name": "Bark",
          "symbol": "BARK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29341/thumb/BarkLogo200x200.png?1678186397"
        },
        {
          "chainId": 1,
          "address": "0x4d4d883f920f7c0c36a1be71a02aa0cde2aa22d1",
          "name": "Opticash",
          "symbol": "OPCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29362/thumb/opticash_logo_256*256.jpg?1678265000"
        },
        {
          "chainId": 1,
          "address": "0x3832d2f059e55934220881f831be501d180671a7",
          "name": "renDOGE",
          "symbol": "RENDOGE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13796/thumb/Dogecoin.jpg?1628072827"
        },
        {
          "chainId": 1,
          "address": "0x58f9102bf53cf186682bd9a281d3cd3c616eec41",
          "name": "Triall",
          "symbol": "TRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18679/thumb/-B7ftfN8_400x400.png?1632964301"
        },
        {
          "chainId": 1,
          "address": "0xb2d2e1309db33b38a19ee2a7cd9cb5de39d76663",
          "name": "colR Coin",
          "symbol": "COLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26701/thumb/colverse.jpeg?1659662193"
        },
        {
          "chainId": 1,
          "address": "0xb5fe099475d3030dde498c3bb6f3854f762a48ad",
          "name": "Fnk com",
          "symbol": "FNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13483/thumb/fnk.png?1609039834"
        },
        {
          "chainId": 1,
          "address": "0x090185f2135308bad17527004364ebcc2d37e5f6",
          "name": "Spell",
          "symbol": "SPELL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862"
        },
        {
          "chainId": 1,
          "address": "0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec",
          "name": "Polymath",
          "symbol": "POLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034"
        },
        {
          "chainId": 1,
          "address": "0x09f098b155d561fc9f7bccc97038b7e3d20baf74",
          "name": "ZooDAO",
          "symbol": "ZOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24305/thumb/Zt2BM_8D_400x400.jpg?1647305115"
        },
        {
          "chainId": 1,
          "address": "0x259ab9b9eab62b0fd98729b97be121073d5b3479",
          "name": "Erica Social Token",
          "symbol": "EST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24076/thumb/EST.png?1646632592"
        },
        {
          "chainId": 1,
          "address": "0x238cefec182679c27a3035713416fa0a8198b302",
          "name": "GoodMeme",
          "symbol": "GMEME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30325/thumb/gmeme200smol.png?1684121367"
        },
        {
          "chainId": 1,
          "address": "0x329cf160f30d21006bcd24b67eade561e54cde4c",
          "name": "CareCoin",
          "symbol": "CARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28378/thumb/46FD299C-3DA4-43E6-A6A2-27F492FC7E4D.jpeg?1669971342"
        },
        {
          "chainId": 1,
          "address": "0x28fda76721a8077a5de802ab0212849b8c38429e",
          "name": "Artemis Vision",
          "symbol": "ARV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23716/thumb/uUMU6_Cr_400x400.jpg?1645147096"
        },
        {
          "chainId": 1,
          "address": "0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a",
          "name": "Adshares",
          "symbol": "ADS",
          "decimals": 11,
          "logoURI": "https://assets.coingecko.com/coins/images/868/thumb/rnO9DyJ.png?1663921311"
        },
        {
          "chainId": 1,
          "address": "0x29fa1fee0f4f0ab0e36ef7ab8d7a35439ec6be75",
          "name": "SafeStake",
          "symbol": "DVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30016/thumb/safestake.png?1682500536"
        },
        {
          "chainId": 1,
          "address": "0xa92e7c82b11d10716ab534051b271d2f6aef7df5",
          "name": "Ara",
          "symbol": "ARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13877/thumb/ara-icon-200.png?1628171321"
        },
        {
          "chainId": 1,
          "address": "0xa41f142b6eb2b164f8164cae0716892ce02f311f",
          "name": "Avocado DAO",
          "symbol": "AVG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png?1640567889"
        },
        {
          "chainId": 1,
          "address": "0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442",
          "name": "ETHPad",
          "symbol": "ETHPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg?1628075612"
        },
        {
          "chainId": 1,
          "address": "0x19de6b897ed14a376dda0fe53a5420d2ac828a28",
          "name": "Bitget Token",
          "symbol": "BGB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11610/thumb/icon_colour.png?1690515455"
        },
        {
          "chainId": 1,
          "address": "0x4123a133ae3c521fd134d7b13a2dec35b56c2463",
          "name": "Qredo",
          "symbol": "QRDO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17541/thumb/qrdo.png?1630637735"
        },
        {
          "chainId": 1,
          "address": "0x5fce9fc9b5d62af082a59d0823a062f7529efa5a",
          "name": "Shaman",
          "symbol": "SHAMAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25292/thumb/0v-V3FPz_400x400.jpg?1651139525"
        },
        {
          "chainId": 1,
          "address": "0x0ea20e7ffb006d4cfe84df2f72d8c7bd89247db0",
          "name": "Aave AMM UniCRVWETH",
          "symbol": "AAMMUNICRVWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17220/thumb/aAmmUniCRVWETH.png?1626879022"
        },
        {
          "chainId": 1,
          "address": "0x20910e5b5f087f6439dfcb0dda4e27d1014ac2b8",
          "name": "BananaTok",
          "symbol": "BNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10289/thumb/sMCxdYBa_400x400.jpg?1577013480"
        },
        {
          "chainId": 1,
          "address": "0x12f649a9e821f90bb143089a6e56846945892ffb",
          "name": "Hyprr",
          "symbol": "UDOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3078/thumb/HYPRR-WIDGET-CIRCLE-ONBLACK-1.jpg?1646752306"
        },
        {
          "chainId": 1,
          "address": "0x08f7be99ed83369541501d60f4e66f8e34c3f736",
          "name": "Cryptoku",
          "symbol": "CKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25813/thumb/Cryptoku.png?1653988443"
        },
        {
          "chainId": 1,
          "address": "0x948c70dc6169bfb10028fdbe96cbc72e9562b2ac",
          "name": "PolkaFantasy",
          "symbol": "XP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467"
        },
        {
          "chainId": 1,
          "address": "0x2565ae0385659badcada1031db704442e1b69982",
          "name": "Assemble Protocol",
          "symbol": "ASM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg?1591775789"
        },
        {
          "chainId": 1,
          "address": "0xbe56ab825fd35678a32dc35bc4eb17e238e1404f",
          "name": "Digits DAO",
          "symbol": "DIGITS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23551/thumb/Logo-Digits-DAO-Icon-01.jpg?1644462126"
        },
        {
          "chainId": 1,
          "address": "0x72e4f9f808c49a2a61de9c5896298920dc4eeea9",
          "name": "HarryPotterObamaSonic10Inu  ETH ",
          "symbol": "BITCOIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30323/thumb/hpos10i_logo_casino_night-dexview.png?1684117567"
        },
        {
          "chainId": 1,
          "address": "0x6d3d490964205c8bc8ded39e48e88e8fde45b41f",
          "name": "Gooch",
          "symbol": "GOOCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30290/thumb/gooch.jpg?1683866049"
        },
        {
          "chainId": 1,
          "address": "0xdc9a328a55a9241fe14abc03498d57124063665b",
          "name": "Garden",
          "symbol": "GRDN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30708/thumb/GARDEN.png?1686548363"
        },
        {
          "chainId": 1,
          "address": "0x8971f9fd7196e5cee2c1032b50f656855af7dd26",
          "name": "Lambda",
          "symbol": "LAMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4703/thumb/lambda_200.png?1666431649"
        },
        {
          "chainId": 1,
          "address": "0x57ab1ec28d129707052df4df418d58a2d46d5f51",
          "name": "sUSD",
          "symbol": "SUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765"
        },
        {
          "chainId": 1,
          "address": "0xa6586e19ef681b1ac0ed3d46413d199a555dbb95",
          "name": "Lets Go Brandon",
          "symbol": "LETSGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24034/thumb/3zKU0T83_400x400.jpeg?1646117523"
        },
        {
          "chainId": 1,
          "address": "0xcb86c6a22cb56b6cf40cafedb06ba0df188a416e",
          "name": "inSure DeFi",
          "symbol": "SURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406"
        },
        {
          "chainId": 1,
          "address": "0x2c5bc2ba3614fd27fcc7022ea71d9172e2632c16",
          "name": "Shib Original Vision",
          "symbol": "SOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29601/thumb/photo_2023-03-19_19-07-40_2.png?1679985063"
        },
        {
          "chainId": 1,
          "address": "0x52e7b23e1faba6d83376725e2ccad75efbd8736e",
          "name": "BURN",
          "symbol": "BURN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30506/thumb/burn.jpg?1684824809"
        },
        {
          "chainId": 1,
          "address": "0xceb53519a2b891d33b36e3826962d2a48f878e45",
          "name": "YellowHeart Protocol",
          "symbol": "HRTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25304/thumb/HRTS.png?1652087596"
        },
        {
          "chainId": 1,
          "address": "0x3540dfcad7cf102a2e44aa0e2132fab1c89d7eae",
          "name": "SchwiftAI",
          "symbol": "SWAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29301/thumb/SchwiftAI.png?1677917099"
        },
        {
          "chainId": 1,
          "address": "0xe8272210954ea85de6d2ae739806ab593b5d9c51",
          "name": "Alpha5",
          "symbol": "A5T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13848/thumb/a5t.jpg?1614652988"
        },
        {
          "chainId": 1,
          "address": "0x20bc832ca081b91433ff6c17f85701b6e92486c5",
          "name": "rETH2",
          "symbol": "RETH2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16570/thumb/red256.png?1624495231"
        },
        {
          "chainId": 1,
          "address": "0x02ad335dd3ca11c18cebbbb583b9613b6289d75f",
          "name": "BPEGd",
          "symbol": "BPEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29310/thumb/BPEGd_Governance_Token.png?1677995219"
        },
        {
          "chainId": 1,
          "address": "0xd16fd95d949f996e3808eeea0e3881c59e76ef1e",
          "name": "Para",
          "symbol": "PARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24652/thumb/para.jpg?1662464045"
        },
        {
          "chainId": 1,
          "address": "0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202",
          "name": "Kyber Network Crystal",
          "symbol": "KNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851"
        },
        {
          "chainId": 1,
          "address": "0xeabb8996ea1662cad2f7fb715127852cd3262ae9",
          "name": "Connect Financial",
          "symbol": "CNFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13592/thumb/cf-logo-iconic-black.png?1644479524"
        },
        {
          "chainId": 1,
          "address": "0xea3983fc6d0fbbc41fb6f6091f68f3e08894dc06",
          "name": "Unido",
          "symbol": "UDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353"
        },
        {
          "chainId": 1,
          "address": "0x42726d074bba68ccc15200442b72afa2d495a783",
          "name": "Isiklar Coin",
          "symbol": "ISIKC",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/10992/thumb/logo_%2866%29.png?1586940186"
        },
        {
          "chainId": 1,
          "address": "0x9d5963ba32e877871dff3e2e697283dc64066271",
          "name": "Edcoin",
          "symbol": "EDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13872/thumb/EDCOIN-1.png?1612447671"
        },
        {
          "chainId": 1,
          "address": "0x574d22e2555cac0ce71e44778f6de2e7487ae229",
          "name": "SoonVerse",
          "symbol": "SOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29448/thumb/1.png?1678868610"
        },
        {
          "chainId": 1,
          "address": "0x3aada3e213abf8529606924d8d1c55cbdc70bf74",
          "name": "XMON",
          "symbol": "XMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14008/thumb/xmon_logo.png?1613615094"
        },
        {
          "chainId": 1,
          "address": "0x6595b8fd9c920c81500dca94e53cdc712513fb1f",
          "name": "Olyverse",
          "symbol": "OLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13983/thumb/OLY-LOGO.png?1684391203"
        },
        {
          "chainId": 1,
          "address": "0x430ef9263e76dae63c84292c3409d61c598e9682",
          "name": "Vulcan Forged",
          "symbol": "PYR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14770/thumb/1617088937196.png?1619414736"
        },
        {
          "chainId": 1,
          "address": "0xb30f5d11b94efbbfdeaa4de38edffceec0be6513",
          "name": "Play It Forward DAO",
          "symbol": "PIF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22904/thumb/pif.png?1642938913"
        },
        {
          "chainId": 1,
          "address": "0x823556202e86763853b40e9cde725f412e294689",
          "name": "Altered State Machine",
          "symbol": "ASTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24893/thumb/ASTO.png?1649303806"
        },
        {
          "chainId": 1,
          "address": "0xfc05987bd2be489accf0f509e44b0145d68240f7",
          "name": "Essentia",
          "symbol": "ESS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2483/thumb/Essentia-token.jpg?1547036604"
        },
        {
          "chainId": 1,
          "address": "0x4db2c02831c9ac305ff9311eb661f80f1df61e07",
          "name": "oneICHI",
          "symbol": "ONEICHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25908/thumb/74116843.png?1654585863"
        },
        {
          "chainId": 1,
          "address": "0x1cdd2eab61112697626f7b4bb0e23da4febf7b7c",
          "name": "Tether USD  Wormhole ",
          "symbol": "USDTSO",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113"
        },
        {
          "chainId": 1,
          "address": "0x1c7e83f8c581a967940dbfa7984744646ae46b29",
          "name": "The RandomDAO",
          "symbol": "RND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24256/thumb/rnd.png?1647160021"
        },
        {
          "chainId": 1,
          "address": "0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016",
          "name": "1MillionNFTs",
          "symbol": "1MIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14990/thumb/4kQ8hRnU_400x400.jpg?1619334818"
        },
        {
          "chainId": 1,
          "address": "0x05d3606d5c81eb9b7b18530995ec9b29da05faba",
          "name": "TomoChain ERC 20",
          "symbol": "TOMOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12646/thumb/tomoe_logo.png?1601377449"
        },
        {
          "chainId": 1,
          "address": "0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8",
          "name": "Sentivate",
          "symbol": "SNTVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7383/thumb/2x9veCp.png?1598409975"
        },
        {
          "chainId": 1,
          "address": "0xaecc217a749c2405b5ebc9857a16d58bdc1c367f",
          "name": "Pawthereum",
          "symbol": "PAWTH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19275/thumb/pawth.png?1635127429"
        },
        {
          "chainId": 1,
          "address": "0xf1f508c7c9f0d1b15a76fba564eef2d956220cf7",
          "name": "Pepedex",
          "symbol": "PPDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13022/thumb/output-onlinepngtools-1.png?1604720841"
        },
        {
          "chainId": 1,
          "address": "0x8a9c67fee641579deba04928c4bc45f66e26343a",
          "name": "Jarvis Reward",
          "symbol": "JRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10390/thumb/cfeii0y.png?1578868949"
        },
        {
          "chainId": 1,
          "address": "0x8a0cdfab62ed35b836dc0633482798421c81b3ec",
          "name": "Spherium",
          "symbol": "SPHRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159"
        },
        {
          "chainId": 1,
          "address": "0x733b5056a0697e7a4357305fe452999a0c409feb",
          "name": "VCORE",
          "symbol": "VCORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30682/thumb/VCORE_Logo_200x200.png?1686272908"
        },
        {
          "chainId": 1,
          "address": "0xfbeb78a723b8087fd2ea7ef1afec93d35e8bed42",
          "name": "UNI yVault",
          "symbol": "YVUNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28781/thumb/yvUNI-128-0xFBEB78a723b8087fD2ea7Ef1afEc93d35E8Bed42.png?1674188955"
        },
        {
          "chainId": 1,
          "address": "0x38e491a71291cd43e8de63b7253e482622184894",
          "name": "Aave AMM UniSNXWETH",
          "symbol": "AAMMUNISNXWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17224/thumb/aAmmUniSNXWETH.png?1626880874"
        },
        {
          "chainId": 1,
          "address": "0x38cf11283de05cf1823b7804bc75068bd6296957",
          "name": "MoonBot",
          "symbol": "MBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31198/thumb/IMAGE_2023-07-27_20_46_47.jpg?1691374702"
        },
        {
          "chainId": 1,
          "address": "0xf0a163a26601d9dc8aef26b388eadb7b1c620d24",
          "name": "Tickr",
          "symbol": "TICKR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28835/thumb/logo-t.png?1677061954"
        },
        {
          "chainId": 1,
          "address": "0xe7ab45162f5979f09b0bda1cc7dfc97c270ea3d5",
          "name": "Dobermann",
          "symbol": "DOBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16002/thumb/doberman.PNG?1622593349"
        },
        {
          "chainId": 1,
          "address": "0xc4c346edc55504574cceb00aa1091d22404a4bc3",
          "name": "MEZZ",
          "symbol": "MEZZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29344/thumb/20230307_234212.png?1678239961"
        },
        {
          "chainId": 1,
          "address": "0x9ba4cbcbb920f67f54a8f8f0001d721e0b8944b9",
          "name": "RedPanda Earth V2",
          "symbol": "REDPANDA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28522/thumb/RedPanda_Earth_Main_Logo_200x200.png?1677406050"
        },
        {
          "chainId": 1,
          "address": "0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6",
          "name": "CoverCompared",
          "symbol": "CVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13720/thumb/cvr.png?1635416411"
        },
        {
          "chainId": 1,
          "address": "0xd17c18979a45c0f3604331831c409f7bff64d3c1",
          "name": "Farmer Friends",
          "symbol": "FRENS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31048/thumb/FRENS_logo.png?1689862033"
        },
        {
          "chainId": 1,
          "address": "0xd37ee7e4f452c6638c96536e68090de8cbcdb583",
          "name": "Aave GUSD",
          "symbol": "AGUSD",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/17246/thumb/aGUSD_2x.png?1626942603"
        },
        {
          "chainId": 1,
          "address": "0xc8ef1460277ea47d179dec66d1c5f8b7f7ae5a28",
          "name": "Rikkei Finance",
          "symbol": "RIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg?1638888855"
        },
        {
          "chainId": 1,
          "address": "0x697ef32b4a3f5a4c39de1cb7563f24ca7bfc5947",
          "name": "Insula",
          "symbol": "ISLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10884/thumb/isla.PNG?1585522028"
        },
        {
          "chainId": 1,
          "address": "0x0000000000095413afc295d19edeb1ad7b71c952",
          "name": "Tokenlon",
          "symbol": "LON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13454/thumb/lon_logo.png?1608701720"
        },
        {
          "chainId": 1,
          "address": "0x74be64b45d394fa57816c1950e94dbb8d7a7b306",
          "name": "Givewell Inu",
          "symbol": "GINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28323/thumb/5FADC518-3EE8-4914-A627-602B9C3FAFB4.jpeg?1669445878"
        },
        {
          "chainId": 1,
          "address": "0xa8eda9d4aee0eb882f8752c6ba7e16d9233c9ad2",
          "name": "LevelApp",
          "symbol": "LVL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5588/thumb/n5DaGBqY_400x400.jpg?1559789019"
        },
        {
          "chainId": 1,
          "address": "0x38c87aa89b2b8cd9b95b736e1fa7b612ea972169",
          "name": "AMO Coin",
          "symbol": "AMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4009/thumb/amo-logomark_black.png?1547039106"
        },
        {
          "chainId": 1,
          "address": "0xa62894d5196bc44e4c3978400ad07e7b30352372",
          "name": "X",
          "symbol": "X",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31144/thumb/IMG_5477.JPG?1691028622"
        },
        {
          "chainId": 1,
          "address": "0xa5269a8e31b93ff27b887b56720a25f844db0529",
          "name": "Morpho Aave USD Coin",
          "symbol": "MAUSDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29835/thumb/maUSDC.jpg?1681718450"
        },
        {
          "chainId": 1,
          "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
          "name": "Bancor Network",
          "symbol": "BNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/736/thumb/bancor-bnt.png?1628822309"
        },
        {
          "chainId": 1,
          "address": "0x6d4ca1177087924edfe0908ef655169ea766fdc3",
          "name": "Hedgehog",
          "symbol": "HEDGEHOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28552/thumb/hedgie.png?1671696815"
        },
        {
          "chainId": 1,
          "address": "0x58b6a8a3302369daec383334672404ee733ab239",
          "name": "Livepeer",
          "symbol": "LPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365"
        },
        {
          "chainId": 1,
          "address": "0xd49ff13661451313ca1553fd6954bd1d9b6e02b9",
          "name": "Electrify Asia",
          "symbol": "ELEC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3415/thumb/d45b1d82743c749d05697da200179874.jpg?1547038096"
        },
        {
          "chainId": 1,
          "address": "0xf0acf8949e705e0ebb6cb42c2164b0b986454223",
          "name": "Barter",
          "symbol": "BRTR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022"
        },
        {
          "chainId": 1,
          "address": "0x64b78325d7495d6d4be92f234fa3f3b8d8964b8b",
          "name": "Shopping io",
          "symbol": "SHOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27242/thumb/shop.png?1663123573"
        },
        {
          "chainId": 1,
          "address": "0xa58a4f5c4bb043d2cc1e170613b74e767c94189b",
          "name": "UTU Coin",
          "symbol": "UTU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png?1602884636"
        },
        {
          "chainId": 1,
          "address": "0xd031edafac6a6ae5425e77f936022e506444c242",
          "name": "HERUKA TSANGNYON",
          "symbol": "TSANGNYON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27611/thumb/81b3e527-f8a9-47f7-8d4d-10f754f501c2.png?1664853112"
        },
        {
          "chainId": 1,
          "address": "0xca3ea3061d638e02113aa960340c98343b5acd62",
          "name": "Bittwatt",
          "symbol": "BWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4777/thumb/bittwatt.png?1547274101"
        },
        {
          "chainId": 1,
          "address": "0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509",
          "name": "UniPower",
          "symbol": "POWER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11618/thumb/unipower.png?1591943398"
        },
        {
          "chainId": 1,
          "address": "0x0f2d719407fdbeff09d87557abb7232601fd9f29",
          "name": "Synapse",
          "symbol": "SYN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797"
        },
        {
          "chainId": 1,
          "address": "0xb41380174d0b06181513a5677b60200b93b5efb4",
          "name": "Chainsquare",
          "symbol": "CHS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14858/thumb/chainsquare.png?1618804636"
        },
        {
          "chainId": 1,
          "address": "0x97a9bac06f90940bce9caec2b880ff17707519e4",
          "name": "Minato",
          "symbol": "MNTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664"
        },
        {
          "chainId": 1,
          "address": "0x6ee9742d17b527e682248dca85952e4fe190061d",
          "name": "Neon Coin",
          "symbol": "NEON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30686/thumb/neon.png?1686274150"
        },
        {
          "chainId": 1,
          "address": "0x766a824314bd1d9fa27c8461754c276772bc666a",
          "name": "Scam Coin",
          "symbol": "SCAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30792/thumb/logo_%281%29_%281%29.png?1687335453"
        },
        {
          "chainId": 1,
          "address": "0x234d51ee02be808a0160b19b689660fb7bfa871b",
          "name": "CoinScan",
          "symbol": "SCAN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24254/thumb/scan.png?1647155535"
        },
        {
          "chainId": 1,
          "address": "0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5",
          "name": "Covenants",
          "symbol": "UNIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12258/thumb/Unifi.png?1598548933"
        },
        {
          "chainId": 1,
          "address": "0xa1e770be76bde604f8ebb66f640250a787b9422b",
          "name": "Anduschain",
          "symbol": "DEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20842/thumb/DEB.png?1637743593"
        },
        {
          "chainId": 1,
          "address": "0xaee433adebe0fbb88daa47ef0c1a513caa52ef02",
          "name": "Pontoon",
          "symbol": "TOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG?1635467899"
        },
        {
          "chainId": 1,
          "address": "0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e",
          "name": "Aave ZRX",
          "symbol": "AZRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14264/thumb/aZRX.9be555a9.png?1615528286"
        },
        {
          "chainId": 1,
          "address": "0x1712aad2c773ee04bdc9114b32163c058321cd85",
          "name": "LimitSwap",
          "symbol": "LIMIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12760/thumb/limit_swap_logo.png?1602347106"
        },
        {
          "chainId": 1,
          "address": "0x41b25ff6431074959532db7435dadaca65a21d1c",
          "name": "Claw",
          "symbol": "CLAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29677/thumb/PBixw6K.png?1680659685"
        },
        {
          "chainId": 1,
          "address": "0x0d58df0929b6baf8ed231f3fa672f0e5dcd665f7",
          "name": "BEER",
          "symbol": "BEER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30355/thumb/BEER_tnsprnt_Logo_no_words.png?1684226424"
        },
        {
          "chainId": 1,
          "address": "0x0d505c03d30e65f6e9b4ef88855a47a89e4b7676",
          "name": "Zoomer",
          "symbol": "ZOOMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30894/thumb/zoooooooooomer.jpg?1688542666"
        },
        {
          "chainId": 1,
          "address": "0x77a90b04d64189d4d09508612c09219bc6816bdc",
          "name": "Toshi Tools",
          "symbol": "TOSHI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28959/thumb/toshi_logo_200_by_200.png?1675738584"
        },
        {
          "chainId": 1,
          "address": "0x0000000005c6b7c1fd10915a05f034f90d524d6e",
          "name": "TRYC",
          "symbol": "TRYC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21985/thumb/16357.png?1640577395"
        },
        {
          "chainId": 1,
          "address": "0xf4134146af2d511dd5ea8cdb1c4ac88c57d60404",
          "name": "SunContract",
          "symbol": "SNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1166/thumb/suncontract.png?1548611174"
        },
        {
          "chainId": 1,
          "address": "0xd71ecff9342a5ced620049e616c5035f1db98620",
          "name": "sEUR",
          "symbol": "SEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13637/thumb/sEUR.png?1610609277"
        },
        {
          "chainId": 1,
          "address": "0x9b31bb425d8263fa1b8b9d090b83cf0c31665355",
          "name": "CoinsPaid",
          "symbol": "CPD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234"
        },
        {
          "chainId": 1,
          "address": "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
          "name": "Illuvium",
          "symbol": "ILV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14468/thumb/logo-200x200.png?1682415398"
        },
        {
          "chainId": 1,
          "address": "0x18c1074845c389907762a71242bfe271c9da2d9c",
          "name": "BULL Token",
          "symbol": "BULL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30785/thumb/BULL200x200.jpg?1687248867"
        },
        {
          "chainId": 1,
          "address": "0xc949fc82a15964fb5b97e5cf8f9ffed139086821",
          "name": "PGPay",
          "symbol": "PGPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6351/thumb/pgpay-logo.jpg?1582515723"
        },
        {
          "chainId": 1,
          "address": "0xa20f77b7ad5a88badc48800c56507b7274c06fdc",
          "name": "Cherry Network",
          "symbol": "CHER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127"
        },
        {
          "chainId": 1,
          "address": "0x4b0f027d0b694aae2761ed2d426295d4f949f5d0",
          "name": "Pollchain",
          "symbol": "POLL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19034/thumb/pollchain.PNG?1634197337"
        },
        {
          "chainId": 1,
          "address": "0x7d8daff6d70cead12c6f077048552cf89130a2b1",
          "name": "ARCS",
          "symbol": "ARX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10068/thumb/arcs.png?1575426741"
        },
        {
          "chainId": 1,
          "address": "0xef6344de1fcfc5f48c30234c16c1389e8cdc572c",
          "name": "EncrypGen",
          "symbol": "DNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1215/thumb/encrypgen.png?1547393601"
        },
        {
          "chainId": 1,
          "address": "0x31ea0de8119307aa264bb4b38727aab4e36b074f",
          "name": "Bit Store",
          "symbol": "STORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG?1637064215"
        },
        {
          "chainId": 1,
          "address": "0xa35b1b31ce002fbf2058d22f30f95d405200a15b",
          "name": "Stader ETHx",
          "symbol": "ETHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30870/thumb/staderx.png?1688979399"
        },
        {
          "chainId": 1,
          "address": "0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40",
          "name": "Aave ENJ v1",
          "symbol": "AENJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12484/thumb/aENJ.png?1600189443"
        },
        {
          "chainId": 1,
          "address": "0x9695e0114e12c0d3a3636fab5a18e6b737529023",
          "name": "Dfyn Network",
          "symbol": "DFYN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15368/thumb/SgqhfWz4_400x400_%281%29.jpg?1620666919"
        },
        {
          "chainId": 1,
          "address": "0xb4fbed161bebcb37afb1cb4a6f7ca18b977ccb25",
          "name": "Dogeswap",
          "symbol": "DOGES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12763/thumb/20200926-220107.png?1622520018"
        },
        {
          "chainId": 1,
          "address": "0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af",
          "name": "Blox",
          "symbol": "CDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1231/thumb/Blox_Staking_Logo_2.png?1609117544"
        },
        {
          "chainId": 1,
          "address": "0x9daef41e08bc88183572b03796c96a7ace1ec9d4",
          "name": "Yofune Nushi",
          "symbol": "KOYO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27697/thumb/Capture-removebg-preview_%283%29.png?1665303166"
        },
        {
          "chainId": 1,
          "address": "0x4db26817c88a57d2ef84cfd90168f535ee83bda9",
          "name": "hiAZUKI",
          "symbol": "HIAZUKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28565/thumb/hiazuki.png?1671787184"
        },
        {
          "chainId": 1,
          "address": "0x00a7ec2f2b451cb0233e8adbf4c9a951027c2b02",
          "name": "hiENS4",
          "symbol": "HIENS4",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26921/thumb/62f9ec6fd1c44e000102051d_MicrosoftTeams-image.png?1660817481"
        },
        {
          "chainId": 1,
          "address": "0xcf0c122c6b73ff809c693db761e7baebe62b6a2e",
          "name": "FLOKI",
          "symbol": "FLOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16746/thumb/PNG_image.png?1643184642"
        },
        {
          "chainId": 1,
          "address": "0x661ab0ed68000491d98c796146bcf28c20d7c559",
          "name": "Shadows",
          "symbol": "DOWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14160/thumb/C3E49eZx_400x400.jpg?1614689301"
        },
        {
          "chainId": 1,
          "address": "0x857d4d47a707cb4e409e14ac63b2e79114acd102",
          "name": "Titter",
          "symbol": "TITR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30344/thumb/200x200.png?1684829453"
        },
        {
          "chainId": 1,
          "address": "0xf03a7eb46d01d9ecaa104558c732cf82f6b6b645",
          "name": "Stader MaticX",
          "symbol": "MATICX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25383/thumb/maticx.png?1674714297"
        },
        {
          "chainId": 1,
          "address": "0x4743a7a193cdf202035e9bc6830a07f1607630c4",
          "name": "Family Guy",
          "symbol": "GUY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30435/thumb/photo_2023-05-16_18-24-10_%281%29.png?1684470217"
        },
        {
          "chainId": 1,
          "address": "0x4c11249814f11b9346808179cf06e71ac328c1b5",
          "name": "Oraichain",
          "symbol": "ORAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12931/thumb/orai.png?1647077378"
        },
        {
          "chainId": 1,
          "address": "0x5adebafbf2fd0d6808a7a1e823759de2df1df39e",
          "name": "FANTA",
          "symbol": "FANTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30564/thumb/200Fwx_AI7WIAMoy7c.png?1685070049"
        },
        {
          "chainId": 1,
          "address": "0xce9de5365739b1bed5c8546867aee4209fbb8538",
          "name": "Thug Life",
          "symbol": "THUG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31036/thumb/IMG_20230717_172433_167.jpg?1689845663"
        },
        {
          "chainId": 1,
          "address": "0x4104b135dbc9609fc1a9490e61369036497660c8",
          "name": "Spectra",
          "symbol": "APW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15597/thumb/spectra.png?1690881005"
        },
        {
          "chainId": 1,
          "address": "0x66861d5f0fbfb7b2711712fef2172c560d08d0ab",
          "name": "A Fund Baby",
          "symbol": "FUND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30955/thumb/fund_baby.jpg?1689216430"
        },
        {
          "chainId": 1,
          "address": "0x33349b282065b0284d756f0577fb39c158f935e6",
          "name": "Maple",
          "symbol": "MPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14097/thumb/Maple_Logo_Mark_Maple_Orange.png?1653381382"
        },
        {
          "chainId": 1,
          "address": "0x082716b6734b31791407d7dd0e2a2c41260029b2",
          "name": "hiCOOLCATS",
          "symbol": "HICOOLCATS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28603/thumb/hicoolcats.png?1672381993"
        },
        {
          "chainId": 1,
          "address": "0x68a47fe1cf42eba4a030a10cd4d6a1031ca3ca0a",
          "name": "Tectum",
          "symbol": "TET",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29575/thumb/Icon_TET.png?1679740721"
        },
        {
          "chainId": 1,
          "address": "0x97e19e2a5458294842036404e6a05571d8bc2fa3",
          "name": "G ",
          "symbol": "G",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x8793fb615eb92822f482f88b3137b00aad4c00d2",
          "name": "revoAI",
          "symbol": "REVOAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28952/thumb/roveAI1_%283%29.png?1675582353"
        },
        {
          "chainId": 1,
          "address": "0xd502f487e1841fdc805130e13eae80c61186bc98",
          "name": "Integral",
          "symbol": "ITGR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18381/thumb/icon.png?1650331643"
        },
        {
          "chainId": 1,
          "address": "0x32a7c02e79c4ea1008dd6564b35f131428673c41",
          "name": "Crust Network",
          "symbol": "CRU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12549/thumb/sAB3KVzD_400x400.jpg?1600680411"
        },
        {
          "chainId": 1,
          "address": "0xdcee70654261af21c44c093c300ed3bb97b78192",
          "name": "Wrapped OETH",
          "symbol": "WOETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29734/thumb/wOETH.png?1681094836"
        },
        {
          "chainId": 1,
          "address": "0x4086e77c5e993fdb90a406285d00111a974f877a",
          "name": "Blockchain Brawlers",
          "symbol": "BRWL",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/24701/thumb/fuDdvI8Y_400x400.png?1648642627"
        },
        {
          "chainId": 1,
          "address": "0xea01906843ea8d910658a2c485ffce7c104ab2b6",
          "name": "Qtoken",
          "symbol": "QTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26024/thumb/8QyOrTCm_400x400.jpg?1655332588"
        },
        {
          "chainId": 1,
          "address": "0x4a220e6096b25eadb88358cb44068a3248254675",
          "name": "Quant",
          "symbol": "QNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252"
        },
        {
          "chainId": 1,
          "address": "0x8b937af714ac7e2129bd33d93641f52b665ca352",
          "name": "JizzRocket",
          "symbol": "JIZZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28985/thumb/bLQvXuh_%281%29.png?1675818176"
        },
        {
          "chainId": 1,
          "address": "0x5b7533812759b45c2b44c19e320ba2cd2681b542",
          "name": "SingularityNET",
          "symbol": "AGIX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2138/thumb/singularitynet.png?1548609559"
        },
        {
          "chainId": 1,
          "address": "0x7163436b8efffb469f6bb81cc908b1661d4795e6",
          "name": "Esco Coin",
          "symbol": "ESCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30405/thumb/escocoin-06.png?1684374521"
        },
        {
          "chainId": 1,
          "address": "0x00000000a82b4758df44fcb124e26a9b441e59a0",
          "name": "Zenith Token",
          "symbol": "ZTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30221/thumb/Zenith_logo_%281%29.png?1683629045"
        },
        {
          "chainId": 1,
          "address": "0x9a0587eae7ef64b2b38a10442a44cfa43edd7d2a",
          "name": "Welltrado",
          "symbol": "WTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3612/thumb/welltrado.png?1548760605"
        },
        {
          "chainId": 1,
          "address": "0x0edf9bc41bbc1354c70e2107f80c42cae7fbbca8",
          "name": "Instrumental Finance",
          "symbol": "STRM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21407/thumb/HRZ0RUOh_400x400.jpg?1639088027"
        },
        {
          "chainId": 1,
          "address": "0x9355372396e3f6daf13359b7b607a3374cc638e0",
          "name": "WHALE",
          "symbol": "WHALE",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/11797/thumb/WHALE.png?1595004706"
        },
        {
          "chainId": 1,
          "address": "0x35b08722aa26be119c1608029ccbc976ac5c1082",
          "name": "Eminer",
          "symbol": "EM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9155/thumb/KDbQPKIu_400x400.png?1564735775"
        },
        {
          "chainId": 1,
          "address": "0x2e516ba5bf3b7ee47fb99b09eadb60bde80a82e0",
          "name": "Eggs",
          "symbol": "EGGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29024/thumb/egg200x200.png?1676016342"
        },
        {
          "chainId": 1,
          "address": "0x7f792db54b0e580cdc755178443f0430cf799aca",
          "name": "Volt Inu",
          "symbol": "VOLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25201/thumb/logo200.png?1653635992"
        },
        {
          "chainId": 1,
          "address": "0xf0dc76c22139ab22618ddfb498be1283254612b1",
          "name": "Wrapped Star",
          "symbol": "WSTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23972/thumb/wstr.png?1645870711"
        },
        {
          "chainId": 1,
          "address": "0x048fe49be32adfc9ed68c37d32b5ec9df17b3603",
          "name": "Skrumble Network",
          "symbol": "SKM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3826/thumb/skrumble-network.png?1548609513"
        },
        {
          "chainId": 1,
          "address": "0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713",
          "name": "Cover Protocol",
          "symbol": "COVER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13563/thumb/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg?1609822578"
        },
        {
          "chainId": 1,
          "address": "0xde67d97b8770dc98c746a3fc0093c538666eb493",
          "name": "Bitrock",
          "symbol": "BROCK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30987/thumb/20230713_155626.png?1689563465"
        },
        {
          "chainId": 1,
          "address": "0xcdb9d30a3ba48cdfcb0ecbe19317e6cf783672f1",
          "name": "Mondo Community Coin",
          "symbol": "MNDCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18680/thumb/mndcc_bildmarke_256px.png?1632965673"
        },
        {
          "chainId": 1,
          "address": "0x0cba60ca5ef4d42f92a5070a8fedd13be93e2861",
          "name": "The Protocol",
          "symbol": "THE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27849/thumb/cSar5sDM_400x400.jpg?1666085191"
        },
        {
          "chainId": 1,
          "address": "0xe9f84de264e91529af07fa2c746e934397810334",
          "name": "SAKE",
          "symbol": "SAK3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15890/thumb/Sak3.jpg?1622219322"
        },
        {
          "chainId": 1,
          "address": "0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe",
          "name": "DSLA Protocol",
          "symbol": "DSLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063"
        },
        {
          "chainId": 1,
          "address": "0xa7a5c1058194af8f00c187adb7fcc0c95f1c6c2d",
          "name": "SPACE iZ",
          "symbol": "SPIZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12213/thumb/aEXTI0vf_400x400.jpg?1598236662"
        },
        {
          "chainId": 1,
          "address": "0x923b83c26b3809d960ff80332ed00aa46d7ed375",
          "name": "Creator Platform",
          "symbol": "CTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png?1631137441"
        },
        {
          "chainId": 1,
          "address": "0x758b4684be769e92eefea93f60dda0181ea303ec",
          "name": "Phonon DAO",
          "symbol": "PHONON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22308/thumb/ezgif-2-e7fb84364d.png?1641449852"
        },
        {
          "chainId": 1,
          "address": "0xbd3de9a069648c84d27d74d701c9fa3253098b15",
          "name": "EQIFi",
          "symbol": "EQX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png?1627968404"
        },
        {
          "chainId": 1,
          "address": "0x634239cfa331df0291653139d1a6083b9cf705e3",
          "name": "DeSpace Protocol",
          "symbol": "DES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg?1632267572"
        },
        {
          "chainId": 1,
          "address": "0x6d614686550b9e1c1df4b2cd8f91c9d4df66c810",
          "name": "Skeb",
          "symbol": "SKEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27155/thumb/skebcoin-cmc.png?1662206072"
        },
        {
          "chainId": 1,
          "address": "0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374",
          "name": "Veritaseum",
          "symbol": "VERI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/695/thumb/veritaseum.png?1547034460"
        },
        {
          "chainId": 1,
          "address": "0x12e951934246186f50146235d541d3bd1d463e4d",
          "name": "Crypto International",
          "symbol": "CRI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24700/thumb/logo200x200.png?1648641961"
        },
        {
          "chainId": 1,
          "address": "0x4521c9ad6a3d4230803ab752ed238be11f8b342f",
          "name": "Sanin Inu",
          "symbol": "SANI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25222/thumb/B7r0ocfQ_400x400.jpg?1650892157"
        },
        {
          "chainId": 1,
          "address": "0x56694577564fdd577a0abb20fe95c1e2756c2a11",
          "name": "AdaSwap",
          "symbol": "ASW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24453/thumb/rA5cmPtX_400x400.jpg?1647677878"
        },
        {
          "chainId": 1,
          "address": "0x833850be8858722cfc5e5e75f2fe6275e055d888",
          "name": "DecentraBNB",
          "symbol": "DBNB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26544/thumb/Screenshot_2022-07-22_at_18.16.11.png?1658735503"
        },
        {
          "chainId": 1,
          "address": "0xb475332d25d34b59176f5c1d94cb9bc9b5e3954a",
          "name": "Hobbes",
          "symbol": "HOBBES",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30928/thumb/hobbes.jpeg?1688967508"
        },
        {
          "chainId": 1,
          "address": "0xf5d126077096e5b01bc30ffa5d9324d7202d7cb3",
          "name": "CHEW",
          "symbol": "CHEW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29377/thumb/IMG_4701.PNG?1678351869"
        },
        {
          "chainId": 1,
          "address": "0xf66cd2f8755a21d3c8683a10269f795c0532dd58",
          "name": "coreDAO",
          "symbol": "COREDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23745/thumb/coredao.png?1645273627"
        },
        {
          "chainId": 1,
          "address": "0x3cbc780d2934d55a06069e837fabd3e6fc23dab0",
          "name": "DBX",
          "symbol": "DBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15682/thumb/logo.png?1646903349"
        },
        {
          "chainId": 1,
          "address": "0x0de05f6447ab4d22c8827449ee4ba2d5c288379b",
          "name": "Ooki",
          "symbol": "OOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21719/thumb/Ooki_Token.png?1669772543"
        },
        {
          "chainId": 1,
          "address": "0xe6f47303032a09c8c0f8ebb713c00e6ed345e8c3",
          "name": "Risitas",
          "symbol": "RISITA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30333/thumb/200x200.png?1684145324"
        },
        {
          "chainId": 1,
          "address": "0x163f8c2467924be0ae7b5347228cabf260318753",
          "name": "Worldcoin",
          "symbol": "WLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31069/thumb/worldcoin.jpeg?1690182386"
        },
        {
          "chainId": 1,
          "address": "0x0adcfdfe9e88d28cc4c1daac9cc021067aff9b0c",
          "name": "AnimalFam",
          "symbol": "TOTOFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29959/thumb/logo2_%283%29.png?1682387179"
        },
        {
          "chainId": 1,
          "address": "0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a",
          "name": "Smart Valor",
          "symbol": "VALOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7231/thumb/smart_valor.png?1555925772"
        },
        {
          "chainId": 1,
          "address": "0xfc98e825a2264d890f9a1e68ed50e1526abccacd",
          "name": "Moss Carbon Credit",
          "symbol": "MCO2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522"
        },
        {
          "chainId": 1,
          "address": "0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112",
          "name": "Genesis Shards",
          "symbol": "GS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14784/thumb/gs.png?1618408218"
        },
        {
          "chainId": 1,
          "address": "0x6c862f803ff42a97d4a483ab761256ad8c90f4f8",
          "name": "ELIS",
          "symbol": "XLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13613/thumb/Elis-Symbol-Green.png?1610285892"
        },
        {
          "chainId": 1,
          "address": "0x1fcdce58959f536621d76f5b7ffb955baa5a672f",
          "name": "ForTube",
          "symbol": "FOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8242/thumb/for.png?1606195375"
        },
        {
          "chainId": 1,
          "address": "0x23ddbd36547d43627afa9b42d4e9fb0515f48b09",
          "name": "hiBEANZ",
          "symbol": "HIBEANZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29281/thumb/hibeanz.png?1677744566"
        },
        {
          "chainId": 1,
          "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
          "name": "Curve DAO",
          "symbol": "CRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12124/thumb/Curve.png?1597369484"
        },
        {
          "chainId": 1,
          "address": "0x5c2975269e74cb3a8514e5b800a1e66c694d4df8",
          "name": "Caroline",
          "symbol": "HER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30146/thumb/caroline.jpeg?1683509796"
        },
        {
          "chainId": 1,
          "address": "0xadb2437e6f65682b85f814fbc12fec0508a7b1d0",
          "name": "UNCX Network",
          "symbol": "UNCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12871/thumb/uncx.png?1676952157"
        },
        {
          "chainId": 1,
          "address": "0xa49d7499271ae71cd8ab9ac515e6694c755d400c",
          "name": "Mute",
          "symbol": "MUTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14331/thumb/MUTE.png?1617618967"
        },
        {
          "chainId": 1,
          "address": "0x73a83269b9bbafc427e76be0a2c1a1db2a26f4c2",
          "name": "Civfund Stone",
          "symbol": "0NE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25156/thumb/stone.png?1650462014"
        },
        {
          "chainId": 1,
          "address": "0x53dfea0a8cc2a2a2e425e1c174bc162999723ea0",
          "name": "Jarvis Synthetic Swiss Franc",
          "symbol": "JCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png?1634046084"
        },
        {
          "chainId": 1,
          "address": "0xbddf903f43dc7d9801f3f0034ba306169074ef8e",
          "name": "Apes Go Bananas",
          "symbol": "AGB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30465/thumb/agb_logo.jpeg?1686557321"
        },
        {
          "chainId": 1,
          "address": "0x4dd942baa75810a3c1e876e79d5cd35e09c97a76",
          "name": "Dash 2 Trade",
          "symbol": "D2T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28707/thumb/logo.png?1675762068"
        },
        {
          "chainId": 1,
          "address": "0xd109b2a304587569c84308c55465cd9ff0317bfb",
          "name": "Aave AMM BptBALWETH",
          "symbol": "AAMMBPTBALWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17261/thumb/aAmmBptBALWETH.png?1626959531"
        },
        {
          "chainId": 1,
          "address": "0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2",
          "name": "Uquid Coin",
          "symbol": "UQC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1341/thumb/uquid-coin.png?1548759712"
        },
        {
          "chainId": 1,
          "address": "0x9a3bbc0ee2d6300bca88c4ff820e2987d62b4f63",
          "name": "HedgBet",
          "symbol": "HDG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31170/thumb/logo-200x200.png?1691116405"
        },
        {
          "chainId": 1,
          "address": "0x00c2999c8b2adf4abc835cc63209533973718eb1",
          "name": "New World Order",
          "symbol": "STATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27387/thumb/icon.png?1663812357"
        },
        {
          "chainId": 1,
          "address": "0x4da9b813057d04baef4e5800e36083717b4a0341",
          "name": "Aave TUSD v1",
          "symbol": "ATUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11724/thumb/aTUSD.png?1593082700"
        },
        {
          "chainId": 1,
          "address": "0x5d285f735998f36631f678ff41fb56a10a4d0429",
          "name": "MixMarvel",
          "symbol": "MIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8222/thumb/8878caf93b1e3b6cfb3b414bda3b5250.png?1613945432"
        },
        {
          "chainId": 1,
          "address": "0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e",
          "name": "cBAT",
          "symbol": "CBAT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10645/thumb/cBAT1.JPG?1581390910"
        },
        {
          "chainId": 1,
          "address": "0x8e0fe2947752be0d5acf73aae77362daf79cb379",
          "name": "NFTrade",
          "symbol": "NFTD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png?1632486088"
        },
        {
          "chainId": 1,
          "address": "0x3d38f87bef0123f5764635c7e11048027e0721a8",
          "name": "EndlessWebWorlds",
          "symbol": "EWW",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29197/thumb/EWW-_1_.png?1677305037"
        },
        {
          "chainId": 1,
          "address": "0x3be7bf1a5f23bd8336787d0289b70602f1940875",
          "name": "VIDT DAO",
          "symbol": "VIDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27552/thumb/VIDTDAO_logo.png?1670333277"
        },
        {
          "chainId": 1,
          "address": "0xd13cfd3133239a3c73a9e535a5c4dadee36b395c",
          "name": "Vaiot",
          "symbol": "VAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13981/thumb/VAIOT_logo.png?1613456546"
        },
        {
          "chainId": 1,
          "address": "0xccc8cb5229b0ac8069c51fd58367fd1e622afd97",
          "name": "Gods Unchained",
          "symbol": "GODS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17139/thumb/10631.png?1635718182"
        },
        {
          "chainId": 1,
          "address": "0x6e0615a03ed9527a6013fcd5b556e36ef4dab1ff",
          "name": "HNB Protocol",
          "symbol": "HNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26182/thumb/newhnblogo.png?1681199790"
        },
        {
          "chainId": 1,
          "address": "0xc6cd44f9630886a7492cea3d9860e0510933a600",
          "name": "Fake Market Cap",
          "symbol": "CAP",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x38029c62dfa30d9fd3cadf4c64e9b2ab21dbda17",
          "name": "Dubbz",
          "symbol": "DUBBZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28665/thumb/D8EACA06-18ED-4999-8B3A-6339F9E021CE.jpeg?1672987234"
        },
        {
          "chainId": 1,
          "address": "0xe0a189c975e4928222978a74517442239a0b86ff",
          "name": "Keys",
          "symbol": "KEYS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20604/thumb/200x200_%2843%29.png?1637289427"
        },
        {
          "chainId": 1,
          "address": "0xa9e201a4e269d6cd5e9f0fcbcb78520cf815878b",
          "name": "Aave AMM UniRENWETH",
          "symbol": "AAMMUNIRENWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17223/thumb/aAmmUniRENWETH.png?1626880629"
        },
        {
          "chainId": 1,
          "address": "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
          "name": "Dogelon Mars",
          "symbol": "ELON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413"
        },
        {
          "chainId": 1,
          "address": "0xcc6f15be8573cb8243c42d300565566d328213dd",
          "name": "OWN Token",
          "symbol": "OWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10950/thumb/cropped-WhatsApp-Image-2020-04-04-at-9.04.01-PM.jpeg?1586305520"
        },
        {
          "chainId": 1,
          "address": "0xbfc66d8cce39e668fd5d3c10fd1b1eabb82c27b7",
          "name": "OVO",
          "symbol": "OVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29514/thumb/ovotoken200.png?1679725585"
        },
        {
          "chainId": 1,
          "address": "0x20d4db1946859e2adb0e5acc2eac58047ad41395",
          "name": "Moon DAO",
          "symbol": "MOONEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22905/thumb/cVWTHdA.png?1645690722"
        },
        {
          "chainId": 1,
          "address": "0xa381e7073ea828fb963157dcc4b414da4344e3fd",
          "name": "Cartel Coin",
          "symbol": "CARTEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30292/thumb/IMG_0763.PNG?1684142433"
        },
        {
          "chainId": 1,
          "address": "0xb1a30851e3f7d841b231b086479608e17198363a",
          "name": "Homeros",
          "symbol": "HMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938"
        },
        {
          "chainId": 1,
          "address": "0x3fe7940616e5bc47b0775a0dccf6237893353bb4",
          "name": "IdleDAI  Best Yield ",
          "symbol": "IDLEDAIYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11901/thumb/idledai_32.png?1595990312"
        },
        {
          "chainId": 1,
          "address": "0x4946fcea7c692606e8908002e55a582af44ac121",
          "name": "FOAM",
          "symbol": "FOAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3345/thumb/K51lJQc.png?1547037959"
        },
        {
          "chainId": 1,
          "address": "0x1e5193ccc53f25638aa22a940af899b692e10b09",
          "name": "Alluo",
          "symbol": "ALLUO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21484/thumb/logo-sm-wb.png?1649993895"
        },
        {
          "chainId": 1,
          "address": "0x1a3496c18d558bd9c6c8f609e1b129f67ab08163",
          "name": "DEAPCOIN",
          "symbol": "DEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677"
        },
        {
          "chainId": 1,
          "address": "0x8c088775e4139af116ac1fa6f281bbf71e8c1c73",
          "name": "PUMLx",
          "symbol": "PUMLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27253/thumb/pumlx.png?1665154140"
        },
        {
          "chainId": 1,
          "address": "0x7ca4408137eb639570f8e647d9bd7b7e8717514a",
          "name": "Alpaca City",
          "symbol": "ALPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13070/thumb/alpaca_logo.png?1604895862"
        },
        {
          "chainId": 1,
          "address": "0xdc8af07a7861bedd104b8093ae3e9376fc8596d2",
          "name": "RocketX exchange",
          "symbol": "RVF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png?1668391022"
        },
        {
          "chainId": 1,
          "address": "0xba41ddf06b7ffd89d1267b5a93bfef2424eb2003",
          "name": "Mythos",
          "symbol": "MYTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28045/thumb/Mythos_Logos_200x200.png?1667269609"
        },
        {
          "chainId": 1,
          "address": "0x76dbcba41e11784bfa95ce30e529fa533c3fa4ad",
          "name": "Smart Marketing",
          "symbol": "SMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21905/thumb/smt-logo.png?1640234993"
        },
        {
          "chainId": 1,
          "address": "0x0d438f3b5175bebc262bf23753c1e53d03432bde",
          "name": "Wrapped NXM",
          "symbol": "WNXM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11890/thumb/wNXM.png?1674799594"
        },
        {
          "chainId": 1,
          "address": "0x14fee680690900ba0cccfc76ad70fd1b95d10e16",
          "name": "PAAL AI",
          "symbol": "PAAL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30815/thumb/PaalX.jpg?1687510326"
        },
        {
          "chainId": 1,
          "address": "0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb",
          "name": "xToken",
          "symbol": "XTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14089/thumb/xtk.png?1659278233"
        },
        {
          "chainId": 1,
          "address": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
          "name": "Ethereum Name Service",
          "symbol": "ENS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140"
        },
        {
          "chainId": 1,
          "address": "0x0c90c57aaf95a3a87eadda6ec3974c99d786511f",
          "name": "HanChain",
          "symbol": "HAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27374/thumb/logo_200.png?1663732819"
        },
        {
          "chainId": 1,
          "address": "0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd",
          "name": "Wombat",
          "symbol": "WOMBAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26430/thumb/Project_Page_Icon.png?1657930951"
        },
        {
          "chainId": 1,
          "address": "0x7d4b8cce0591c9044a22ee543533b72e976e36c3",
          "name": "Change",
          "symbol": "CAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1021/thumb/200x200.png?1610766224"
        },
        {
          "chainId": 1,
          "address": "0x29a5c1db7321c5c9eae57f9366ee8eef00ca11fb",
          "name": "SHKOOBY INU",
          "symbol": "SHKOOBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19790/thumb/shkooby.png?1645255468"
        },
        {
          "chainId": 1,
          "address": "0x3ee4b152824b657644c7a9b50694787e80eb8f4a",
          "name": "Bazed Games",
          "symbol": "BAZED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31195/thumb/IMG_1849.jpeg?1691147156"
        },
        {
          "chainId": 1,
          "address": "0x1c9a2d6b33b4826757273d47ebee0e2dddcd978b",
          "name": "Flux FRAX",
          "symbol": "FFRAX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29580/thumb/fFRAX-320.png?1679813767"
        },
        {
          "chainId": 1,
          "address": "0x5bb29c33c4a3c29f56f8aca40b4db91d8a5fe2c5",
          "name": "One Share",
          "symbol": "ONS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13531/thumb/bss.a1671c75.png?1609452258"
        },
        {
          "chainId": 1,
          "address": "0x51fe2e572e97bfeb1d719809d743ec2675924edc",
          "name": "VLaunch",
          "symbol": "VPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20838/thumb/vlaunch_22.png?1637738535"
        },
        {
          "chainId": 1,
          "address": "0x8d137e3337eb1b58a222fef2b2cc7c423903d9cf",
          "name": "SQGL Vault  NFTX ",
          "symbol": "SQGL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17041/thumb/SQGL.png?1626147070"
        },
        {
          "chainId": 1,
          "address": "0xb3ad645db386d7f6d753b2b9c3f4b853da6890b8",
          "name": "Concentrator",
          "symbol": "CTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26364/thumb/%E6%9C%AA%E5%91%BD%E5%90%8D%E7%9A%84%E8%AE%BE%E8%AE%A1.jpg?1657596066"
        },
        {
          "chainId": 1,
          "address": "0x7815bda662050d84718b988735218cffd32f75ea",
          "name": "Yel Finance",
          "symbol": "YEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17429/thumb/Logo200.png?1627613989"
        },
        {
          "chainId": 1,
          "address": "0x7ac168c81f4f3820fa3f22603ce5864d6ab3c547",
          "name": "Staked ACME",
          "symbol": "STACME",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29756/thumb/stacme-avatar.jpg?1681176054"
        },
        {
          "chainId": 1,
          "address": "0x52662717e448be36cb54588499d5a8328bd95292",
          "name": "Tenshi",
          "symbol": "TENSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17170/thumb/TENSHI.png?1628739569"
        },
        {
          "chainId": 1,
          "address": "0x625ae63000f46200499120b906716420bd059240",
          "name": "Aave SUSD v1",
          "symbol": "ASUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11723/thumb/aSUSD.png?1593082612"
        },
        {
          "chainId": 1,
          "address": "0x15f20f9dfdf96ccf6ac96653b7c0abfe4a9c9f0f",
          "name": "Wall Street Baby",
          "symbol": "WSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15042/thumb/GMih0pSW_400x400.png?1682581631"
        },
        {
          "chainId": 1,
          "address": "0x9ccf191169a613eca433a249d3f40131178caebc",
          "name": "MicroChains Gov Token",
          "symbol": "MCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26663/thumb/B2QwV0BL_400x400.jpeg?1659449087"
        },
        {
          "chainId": 1,
          "address": "0x2be1e42bf263aab47d27ba92e72c14823e101d7c",
          "name": "Fluid FRAX",
          "symbol": "FFRAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28475/thumb/fFRAX-200x200.png?1671002588"
        },
        {
          "chainId": 1,
          "address": "0xe6602b34d8510b033e000975b3322537c7172441",
          "name": "Frontrow",
          "symbol": "FRR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21658/thumb/uZsO4yI7_400x400.jpg?1639697777"
        },
        {
          "chainId": 1,
          "address": "0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce",
          "name": "Lead",
          "symbol": "LEAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg?1599491466"
        },
        {
          "chainId": 1,
          "address": "0x4236f8aaf2b1f3a28420eb15b8e0ddf63201a95e",
          "name": "Bermuda",
          "symbol": "BMDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28878/thumb/LOGO.png?1675134294"
        },
        {
          "chainId": 1,
          "address": "0x41a08648c3766f9f9d85598ff102a08f4ef84f84",
          "name": "Aave Balancer Pool Token",
          "symbol": "ABPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16788/thumb/ABPT_2x.png?1625046056"
        },
        {
          "chainId": 1,
          "address": "0x8530b66ca3ddf50e0447eae8ad7ea7d5e62762ed",
          "name": "Meta Doge",
          "symbol": "METADOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19656/thumb/metadoge.png?1636333901"
        },
        {
          "chainId": 1,
          "address": "0xf59257e961883636290411c11ec5ae622d19455e",
          "name": "FloorDAO",
          "symbol": "FLOOR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24026/thumb/icon-floor_2x.png?1646102857"
        },
        {
          "chainId": 1,
          "address": "0xb755d5bc7de83232b9df1886bd5cdb38895933b0",
          "name": "hiMFERS",
          "symbol": "HIMFERS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28719/thumb/hiMFERs.png?1673590471"
        },
        {
          "chainId": 1,
          "address": "0xd6caf5bd23cf057f5fccce295dcc50c01c198707",
          "name": "Evanesco Network",
          "symbol": "EVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16722/thumb/evan.PNG?1624849154"
        },
        {
          "chainId": 1,
          "address": "0x1af2eaeaf2b1d9dda800861268e6bbb3995a6c3b",
          "name": "1eco",
          "symbol": "1ECO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22118/thumb/mgrOT_dx_400x400.png?1640847402"
        },
        {
          "chainId": 1,
          "address": "0x08389495d7456e1951ddf7c3a1314a4bfb646d8b",
          "name": "Crypterium",
          "symbol": "CRPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205"
        },
        {
          "chainId": 1,
          "address": "0xba58444c8050ed9385b7417533a73644036d21eb",
          "name": "Lord of Dragons",
          "symbol": "LOGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29471/thumb/sym_1000.png?1679128938"
        },
        {
          "chainId": 1,
          "address": "0xa8b0f154a688c22142e361707df64277e0a0be66",
          "name": "Rake Finance",
          "symbol": "RAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13078/thumb/rake-logo-200x200.png?1604931839"
        },
        {
          "chainId": 1,
          "address": "0x066798d9ef0833ccc719076dab77199ecbd178b0",
          "name": "SakeSwap",
          "symbol": "SAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402"
        },
        {
          "chainId": 1,
          "address": "0x259ce0cb3581995d40cbb03fd4badeaaba1edaff",
          "name": "SphereSXS",
          "symbol": "SXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27924/thumb/Sphere_logo.jpg?1687102327"
        },
        {
          "chainId": 1,
          "address": "0x0b4c2708f052dca413600e237675e4d6778a9375",
          "name": "CoinClaim",
          "symbol": "CLM",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/6687/thumb/coinclaim.jpg?1547042891"
        },
        {
          "chainId": 1,
          "address": "0x37d299ef5548bf19503cb72c085799e331f9097d",
          "name": "VeganNation GreenCoin",
          "symbol": "GRNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21581/thumb/logo-green_%281%29.png?1639532854"
        },
        {
          "chainId": 1,
          "address": "0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9",
          "name": "Revain",
          "symbol": "REV",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1107/thumb/256x256.png?1587018647"
        },
        {
          "chainId": 1,
          "address": "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
          "name": "Yield Guild Games",
          "symbol": "YGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17358/thumb/Shield_Mark_-_Colored_-_Iridescent.png?1686132424"
        },
        {
          "chainId": 1,
          "address": "0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7",
          "name": "Mars",
          "symbol": "MARS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13654/thumb/MARS.png?1610575403"
        },
        {
          "chainId": 1,
          "address": "0x6a68de599e8e0b1856e322ce5bd11c5c3c79712b",
          "name": "iBetYou",
          "symbol": "IBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16423/thumb/qvrS5aNM_400x400.jpg?1623988397"
        },
        {
          "chainId": 1,
          "address": "0x2008e3057bd734e10ad13c9eae45ff132abc1722",
          "name": "Zebi",
          "symbol": "ZCO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2756/thumb/zebi.png?1548761131"
        },
        {
          "chainId": 1,
          "address": "0x5c147e74d63b1d31aa3fd78eb229b65161983b2b",
          "name": "Wrapped Flow",
          "symbol": "WFLOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24045/thumb/wrapped-flow.png?1646187143"
        },
        {
          "chainId": 1,
          "address": "0x429aca1ccd47296483d1281c85b24e842de0b758",
          "name": "YEET DAO",
          "symbol": "YEET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30106/thumb/200x200.png?1683083443"
        },
        {
          "chainId": 1,
          "address": "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
          "name": "Amp",
          "symbol": "AMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397"
        },
        {
          "chainId": 1,
          "address": "0x1f4cb968b76931c494ff92ed80ccb169ad641cb1",
          "name": "Structure Finance",
          "symbol": "STF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18409/thumb/v4RAXKdq_400x400_%281%29.jpg?1631797904"
        },
        {
          "chainId": 1,
          "address": "0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f",
          "name": "DLP Duck",
          "symbol": "DUCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13440/thumb/DLP_Duck_Token.png?1612840740"
        },
        {
          "chainId": 1,
          "address": "0x29d578cec46b50fa5c88a99c6a4b70184c062953",
          "name": "Everscale",
          "symbol": "EVER",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/12783/thumb/everscale_badge_main_round_1x.png?1640050196"
        },
        {
          "chainId": 1,
          "address": "0x9506d37f70eb4c3d79c398d326c871abbf10521d",
          "name": "Media Licensing Token",
          "symbol": "MLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15659/thumb/milc_200x200.png?1621511031"
        },
        {
          "chainId": 1,
          "address": "0xff8c479134a18918059493243943150776cf8cf2",
          "name": "Renq Finance",
          "symbol": "RENQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29603/thumb/Renq.png?1679994056"
        },
        {
          "chainId": 1,
          "address": "0x72108a8cc3254813c6be2f1b77be53e185abfdd9",
          "name": "Era Swap",
          "symbol": "ES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6031/thumb/era_swap.PNG?1570920926"
        },
        {
          "chainId": 1,
          "address": "0x24e3794605c84e580eea4972738d633e8a7127c8",
          "name": "Katalyo",
          "symbol": "KTLYO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13347/thumb/katalyo_logo_aqua_256.png?1607762430"
        },
        {
          "chainId": 1,
          "address": "0xb49fa25978abf9a248b8212ab4b87277682301c0",
          "name": "RAI Finance",
          "symbol": "SOFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14686/thumb/sofi.png?1640249595"
        },
        {
          "chainId": 1,
          "address": "0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62",
          "name": "Guild of Guardians",
          "symbol": "GOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17362/thumb/V2QDNoLg_400x400.jpg?1640054187"
        },
        {
          "chainId": 1,
          "address": "0xcfa0885131f602d11d4da248d2c65a62063567a9",
          "name": "TORG",
          "symbol": "TORG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056"
        },
        {
          "chainId": 1,
          "address": "0xc8d07671afba9492da95819de4ad10859e00ab7f",
          "name": "CryptoCoinPay",
          "symbol": "CCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7127/thumb/PNGLOGO.png?1674306197"
        },
        {
          "chainId": 1,
          "address": "0x70881d5c8a5950ceedf1f1b4b5d4105718642548",
          "name": "Bagholder",
          "symbol": "BAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30503/thumb/rsz_bagholdrpepecut-sq%281%29.png?1684765881"
        },
        {
          "chainId": 1,
          "address": "0xe1c7e30c42c24582888c758984f6e382096786bd",
          "name": "Curate",
          "symbol": "XCUR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208"
        },
        {
          "chainId": 1,
          "address": "0x1ea48b9965bb5086f3b468e50ed93888a661fc17",
          "name": "Moneta DAO",
          "symbol": "MON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27493/thumb/logo-moneta.png?1664268389"
        },
        {
          "chainId": 1,
          "address": "0x6810e776880c02933d47db1b9fc05908e5386b96",
          "name": "Gnosis",
          "symbol": "GNO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/662/thumb/logo_square_simple_300px.png?1609402668"
        },
        {
          "chainId": 1,
          "address": "0xbffaa2bf647e5ae6280a8138d884c3a9a95ece7e",
          "name": "DYOR Analyzer",
          "symbol": "DYOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30782/thumb/favicon_%281%29.png?1687238562"
        },
        {
          "chainId": 1,
          "address": "0x693c216aa181ebf776730d16c7ba06842548415e",
          "name": "PAPI",
          "symbol": "PAPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30584/thumb/FC39248F-E051-42B1-93BD-EE3839DEA999.jpeg?1685451040"
        },
        {
          "chainId": 1,
          "address": "0x830a8512db4f6fca51968593e2667156c2c483a8",
          "name": "WEN Token",
          "symbol": "WEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30400/thumb/kxtrOzUd_400x400.jpg?1684370899"
        },
        {
          "chainId": 1,
          "address": "0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab",
          "name": "Geyser",
          "symbol": "GYSR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12995/thumb/logo_padded_shifted.png?1636633987"
        },
        {
          "chainId": 1,
          "address": "0xe89751b31cd4e2eb7006ce168857f72c8e37bceb",
          "name": "Math Coin",
          "symbol": "MATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30734/thumb/A0BC5523-B2CF-4BC2-B55E-576BF45A0AAC.jpeg?1686709997"
        },
        {
          "chainId": 1,
          "address": "0x7495e5cc8f27e0bd5bd4cb86d17f0d841ca58ee4",
          "name": "Arnoya classic",
          "symbol": "ARNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28194/thumb/ARNC_200.png?1668332937"
        },
        {
          "chainId": 1,
          "address": "0xe77f82f288a6a7eb4ac295a86fccb1f6d240499b",
          "name": "Plan B DAO",
          "symbol": "PLANB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29663/thumb/planb200x200.png?1680508525"
        },
        {
          "chainId": 1,
          "address": "0x3a880652f47bfaa771908c07dd8673a787daed3a",
          "name": "DerivaDAO",
          "symbol": "DDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641"
        },
        {
          "chainId": 1,
          "address": "0xe3fedaecd47aa8eab6b23227b0ee56f092c967a9",
          "name": "Primas",
          "symbol": "PST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/917/thumb/primas.png?1548331951"
        },
        {
          "chainId": 1,
          "address": "0x24c19f7101c1731b85f1127eaa0407732e36ecdd",
          "name": "SharedStake Governance v2",
          "symbol": "SGTV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13948/thumb/sgt-png.png?1615189100"
        },
        {
          "chainId": 1,
          "address": "0xa1d65e8fb6e87b60feccbc582f7f97804b725521",
          "name": "DXdao",
          "symbol": "DXD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11148/thumb/dxdao.png?1607999331"
        },
        {
          "chainId": 1,
          "address": "0x294559fa758c88d639fd085751e463fee7806eab",
          "name": "Metal Blockchain",
          "symbol": "METAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27222/thumb/metal-chain.png?1662685566"
        },
        {
          "chainId": 1,
          "address": "0x4561de8e0c2bba725d38d266ef62426e62678d82",
          "name": "Coniun",
          "symbol": "CONI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29771/thumb/16801884918921679374349437Only-Amblem-23.png?1681203516"
        },
        {
          "chainId": 1,
          "address": "0xc4de189abf94c57f396bd4c52ab13b954febefd8",
          "name": "B20",
          "symbol": "B20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13803/thumb/b20.png?1611996305"
        },
        {
          "chainId": 1,
          "address": "0x114f1388fab456c4ba31b1850b244eedcd024136",
          "name": "COOL Vault  NFTX ",
          "symbol": "COOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17032/thumb/COOL.png?1626142075"
        },
        {
          "chainId": 1,
          "address": "0x8ef47555856f6ce2e0cd7c36aef4fab317d2e2e2",
          "name": "PayAccept",
          "symbol": "PAYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12513/thumb/logo.png?1603801944"
        },
        {
          "chainId": 1,
          "address": "0xc590c348d431d544172f5ec4e4c8ea44537b8f4d",
          "name": "AntNetworX",
          "symbol": "ANTX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31054/thumb/0ZE35O-t_400x400.jpg?1689925804"
        },
        {
          "chainId": 1,
          "address": "0x94e9eb8b5ab9fd6b9ea3169d55ffade62a01702e",
          "name": "BreederDAO",
          "symbol": "BREED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25203/thumb/BreederDAO-Breed_Token-FINAL.png?1650638916"
        },
        {
          "chainId": 1,
          "address": "0x64a60493d888728cf42616e034a0dfeae38efcf0",
          "name": "OneLedger",
          "symbol": "OLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3422/thumb/One_Ledger.jpeg?1547038108"
        },
        {
          "chainId": 1,
          "address": "0x0f00f1696218eaefa2d2330df3d6d1f94813b38f",
          "name": "SEDO POW",
          "symbol": "SEDO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/6866/thumb/sedocoin-logo.jpg?1547043199"
        },
        {
          "chainId": 1,
          "address": "0x5bb441fb23263b59e97e20b37a0534e33b9b88e8",
          "name": "Pokmon",
          "symbol": "POKMON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30329/thumb/pokmon.jpg?1684136030"
        },
        {
          "chainId": 1,
          "address": "0x3b27f92c0e212c671ea351827edf93db27cc0c65",
          "name": "USDT yVault",
          "symbol": "YVUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28780/thumb/yvUSDT-128-0x7Da96a3891Add058AdA2E826306D812C638D87a7.png?1674182545"
        },
        {
          "chainId": 1,
          "address": "0x6230f552a1c825d02e1140ccc0d3f5eeec81ca84",
          "name": "FusionBot",
          "symbol": "FUSION",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31125/thumb/logo.png?1690775825"
        },
        {
          "chainId": 1,
          "address": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
          "name": "HEX",
          "symbol": "HEX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10103/thumb/HEX-logo.png?1575942673"
        },
        {
          "chainId": 1,
          "address": "0x012e0e6342308b247f36ee500ecb14dc77a7a8c1",
          "name": "Sukhavati Network",
          "symbol": "SKT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17674/thumb/logo2.png?1628830203"
        },
        {
          "chainId": 1,
          "address": "0xf3bb9f16677f2b86efd1dfca1c141a99783fde58",
          "name": "Crown Token",
          "symbol": "CROWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30161/thumb/Crown_Token_200_200.png?1683527391"
        },
        {
          "chainId": 1,
          "address": "0xe9fa21e671bcfb04e6868784b89c19d5aa2424ea",
          "name": "EurocoinToken",
          "symbol": "ECTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7634/thumb/logotoken200x200.png?1550742362"
        },
        {
          "chainId": 1,
          "address": "0x34ba042827996821cffeb06477d48a2ff9474483",
          "name": "Shib2 0",
          "symbol": "SHIB20",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30940/thumb/unnamed.jpg?1689083941"
        },
        {
          "chainId": 1,
          "address": "0x1936ae42b59876192a2e263b3807343c448e3c85",
          "name": "Ember",
          "symbol": "EMBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31186/thumb/gitbookpfp.png?1691140955"
        },
        {
          "chainId": 1,
          "address": "0x3d3af44cf092a49280e316f09c8f20ecf97bc933",
          "name": "UCX",
          "symbol": "UCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10131/thumb/UCX_200200.png?1576186720"
        },
        {
          "chainId": 1,
          "address": "0xc03fbf20a586fa89c2a5f6f941458e1fbc40c661",
          "name": "COMBO",
          "symbol": "COMBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4932/thumb/COMBO.jpg?1685695824"
        },
        {
          "chainId": 1,
          "address": "0x108a850856db3f85d0269a2693d896b394c80325",
          "name": "THORWallet DEX",
          "symbol": "TGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21843/thumb/tgt_logo.png?1641568708"
        },
        {
          "chainId": 1,
          "address": "0x8f081eb884fd47b79536d28e2dd9d4886773f783",
          "name": "bePAY Finance",
          "symbol": "BECOIN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png?1638861929"
        },
        {
          "chainId": 1,
          "address": "0xba8a621b4a54e61c442f5ec623687e2a942225ef",
          "name": "Sandclock",
          "symbol": "QUARTZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19368/thumb/sandclock.jpg?1635132501"
        },
        {
          "chainId": 1,
          "address": "0x604026696fdb3c6720ae3049c46d59ac604dea0a",
          "name": "eXciting Japan Coin",
          "symbol": "XJP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11963/thumb/XJP_LOGO.png?1596453963"
        },
        {
          "chainId": 1,
          "address": "0xb892249939adbf6d7851864ca9a5c7d2d537af97",
          "name": "Gambler Shiba",
          "symbol": "GSHIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17130/thumb/RHzOHCyb_400x400.jpg?1626410775"
        },
        {
          "chainId": 1,
          "address": "0x8ae4bf2c33a8e667de34b54938b0ccd03eb8cc06",
          "name": "Patientory",
          "symbol": "PTOY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/746/thumb/patientory.png?1548330777"
        },
        {
          "chainId": 1,
          "address": "0x6f87d756daf0503d08eb8993686c7fc01dc44fb1",
          "name": "Unitrade",
          "symbol": "TRADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11982/thumb/unitrade.PNG?1597009487"
        },
        {
          "chainId": 1,
          "address": "0x471d113059324321749e097705197a2b44a070fc",
          "name": "Kanga Exchange",
          "symbol": "KNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21188/thumb/KNG_logo-200.png?1681374829"
        },
        {
          "chainId": 1,
          "address": "0x823e1b82ce1dc147bbdb25a203f046afab1ce918",
          "name": "SpiralDAO Coil",
          "symbol": "COIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29773/thumb/200x200.png?1681265925"
        },
        {
          "chainId": 1,
          "address": "0xb57420fad6731b004309d5a0ec7c6c906adb8df7",
          "name": "DotOracle",
          "symbol": "DTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19527/thumb/84438129.png?1635371187"
        },
        {
          "chainId": 1,
          "address": "0x5283d291dbcf85356a21ba090e6db59121208b44",
          "name": "Blur",
          "symbol": "BLUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28453/thumb/blur.png?1670745921"
        },
        {
          "chainId": 1,
          "address": "0x08ba718f288c3b12b01146816bef9fa03cc635bc",
          "name": "Centaurify",
          "symbol": "CENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20512/thumb/Centaurify_Symbol_Black-01.png?1681802100"
        },
        {
          "chainId": 1,
          "address": "0xe1a3864dbf62fb94834b108ff6bf439ce70183ac",
          "name": "vXDEFI",
          "symbol": "VXDEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30479/thumb/vXDEFI.jpg?1684708659"
        },
        {
          "chainId": 1,
          "address": "0x7bd44cf5c0566aab26150a0cd5c3d20c5535686f",
          "name": "Evil Pepe",
          "symbol": "EVILPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31201/thumb/IMG_20230803_120336_441.png?1691378621"
        },
        {
          "chainId": 1,
          "address": "0x86b4dbe5d203e634a12364c0e428fa242a3fba98",
          "name": "poundtoken",
          "symbol": "GBPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26591/thumb/gbpt.jpeg?1658958061"
        },
        {
          "chainId": 1,
          "address": "0x9acbd8315d81253f1ba7c0d24cdbd4e01be44a9b",
          "name": "Psycho",
          "symbol": "PSYCHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30692/thumb/Psycho_200x200_PNG.png?1686281196"
        },
        {
          "chainId": 1,
          "address": "0x74b1af114274335598da72f5c6ed7b954a016eed",
          "name": "HitBTC",
          "symbol": "HIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16395/thumb/JgMkz95.png?1623909204"
        },
        {
          "chainId": 1,
          "address": "0xe15a66b7b8e385caa6f69fd0d55984b96d7263cf",
          "name": "Arch USD Div  Yield",
          "symbol": "ADDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30484/thumb/ADDY.png?1684710966"
        },
        {
          "chainId": 1,
          "address": "0x42d6622dece394b54999fbd73d108123806f6a18",
          "name": "SpankChain",
          "symbol": "SPANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1226/thumb/spankchain.png?1548610811"
        },
        {
          "chainId": 1,
          "address": "0x009668a9691e456972c8ec4cc84e99486308b84d",
          "name": "Terareum  OLD ",
          "symbol": "TERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23880/thumb/Tera-200x200-1.png?1645600935"
        },
        {
          "chainId": 1,
          "address": "0xa59e341e8047498700ed244814b01b34547fb21b",
          "name": "Medal of Honour",
          "symbol": "MOH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27861/thumb/MOH___200-200.png?1667467198"
        },
        {
          "chainId": 1,
          "address": "0x75387e1287dd85482ab66102da9f6577e027f609",
          "name": "Mindsync",
          "symbol": "MAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7581/thumb/mindsync-logo.jpg?1548636745"
        },
        {
          "chainId": 1,
          "address": "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
          "name": "Request",
          "symbol": "REQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951"
        },
        {
          "chainId": 1,
          "address": "0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0",
          "name": "KRYLL",
          "symbol": "KRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979"
        },
        {
          "chainId": 1,
          "address": "0xb17548c7b510427baac4e267bea62e800b247173",
          "name": "Swarm Markets",
          "symbol": "SMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17488/thumb/swarm-SMT-token-symbol_200x200.png?1655373659"
        },
        {
          "chainId": 1,
          "address": "0x429876c4a6f89fb470e92456b8313879df98b63c",
          "name": "Cryption Network",
          "symbol": "CNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16171/thumb/Cryption_Logo_Gradient_White_Text.png?1623191284"
        },
        {
          "chainId": 1,
          "address": "0x1cc30e2eac975416060ec6fe682041408420d414",
          "name": "Kollect",
          "symbol": "KOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18664/thumb/kol.png?1632827164"
        },
        {
          "chainId": 1,
          "address": "0xf028adee51533b1b47beaa890feb54a457f51e89",
          "name": "BMCHAIN",
          "symbol": "BMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7419/thumb/bchain_logo.jpg?1547199925"
        },
        {
          "chainId": 1,
          "address": "0x4527a3b4a8a150403090a99b87effc96f2195047",
          "name": "P2P solutions foundation",
          "symbol": "P2PS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2798/thumb/p2ps.png?1547036973"
        },
        {
          "chainId": 1,
          "address": "0x16484d73ac08d2355f466d448d2b79d2039f6ebb",
          "name": "FortKnoxster",
          "symbol": "FKX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5050/thumb/fkx.png?1644048298"
        },
        {
          "chainId": 1,
          "address": "0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4",
          "name": "Sylo",
          "symbol": "SYLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6430/thumb/SYLO.svg?1589527756"
        },
        {
          "chainId": 1,
          "address": "0x6c3f90f043a72fa612cbac8115ee7e52bde6e490",
          "name": "LP 3pool Curve",
          "symbol": "3CRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12972/thumb/3pool_128.png?1603948039"
        },
        {
          "chainId": 1,
          "address": "0xaf4dce16da2877f8c9e00544c93b62ac40631f16",
          "name": "Monetha",
          "symbol": "MTH",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/902/thumb/LOGO-MTH-200-200.png?1656643069"
        },
        {
          "chainId": 1,
          "address": "0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c",
          "name": "Peanut",
          "symbol": "NUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg?1613353972"
        },
        {
          "chainId": 1,
          "address": "0xe5867608b51a2c9c78b9587355cc093140a49b0a",
          "name": "Speed Mining Service",
          "symbol": "SMS",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/1807/thumb/speed-mining-service.png?1548610827"
        },
        {
          "chainId": 1,
          "address": "0x79c9e0410b6615e7ba9dd69614b0519325a2b047",
          "name": "hiFLUF",
          "symbol": "HIFLUF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28602/thumb/hifluf.png?1672381139"
        },
        {
          "chainId": 1,
          "address": "0x2791bfd60d232150bff86b39b7146c0eaaa2ba81",
          "name": "BiFi",
          "symbol": "BIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13671/thumb/ysYIu7Q.png?1610679337"
        },
        {
          "chainId": 1,
          "address": "0xeb2f5a4e1441df330670dc7b0cf4eac0f7ab5fa5",
          "name": "hiFRIENDS",
          "symbol": "HIFRIENDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29086/thumb/hifriends.png?1676517421"
        },
        {
          "chainId": 1,
          "address": "0x3505f494c3f0fed0b594e01fa41dd3967645ca39",
          "name": "Swarm Network",
          "symbol": "SWM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/197/thumb/swarm.jpg?1547033949"
        },
        {
          "chainId": 1,
          "address": "0x5aa158404fed6b4730c13f49d3a7f820e14a636f",
          "name": "ULTRON",
          "symbol": "ULX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26977/thumb/ULTRON-Profile-Pic.jpg?1690184603"
        },
        {
          "chainId": 1,
          "address": "0x8b79656fc38a04044e495e22fad747126ca305c4",
          "name": "AgaveCoin",
          "symbol": "AGVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7056/thumb/G4TML4cb_400x400.jpg?1547043511"
        },
        {
          "chainId": 1,
          "address": "0xbd9908b0cdd50386f92efcc8e1d71766c2782df0",
          "name": "DAOSquare",
          "symbol": "RICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19084/thumb/tw5gu1dW_400x400.jpeg?1634389807"
        },
        {
          "chainId": 1,
          "address": "0x1a87077c4f834884691b8ba4fc808d2ec93a9f30",
          "name": "Winry Inu",
          "symbol": "WINRY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19420/thumb/winry.png?1640575013"
        },
        {
          "chainId": 1,
          "address": "0xf7920b0768ecb20a123fac32311d07d193381d6f",
          "name": "Time New Bank",
          "symbol": "TNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1265/thumb/time-new-bank.png?1547035290"
        },
        {
          "chainId": 1,
          "address": "0xb06b8186cc008a79fd6722b1eefad07c14e97da0",
          "name": "Sign Token",
          "symbol": "SIGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26495/thumb/PWRjbqG9_400x400.png?1658303265"
        },
        {
          "chainId": 1,
          "address": "0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14",
          "name": "Bella Protocol",
          "symbol": "BEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12478/thumb/Bella.png?1602230054"
        },
        {
          "chainId": 1,
          "address": "0x7a786dac1f315c8a0e9962172ad8ae0c04d9c9b6",
          "name": "MemeDAO",
          "symbol": "MEMD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30468/thumb/memeDAO_200x200.png?1684665589"
        },
        {
          "chainId": 1,
          "address": "0x8e9a29e7ed21db7c5b2e1cd75e676da0236dfb45",
          "name": "Minter Hub",
          "symbol": "HUB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14639/thumb/hub200.png?1651724396"
        },
        {
          "chainId": 1,
          "address": "0x4161725d019690a3e0de50f6be67b07a86a9fae1",
          "name": "TokenPocket Token",
          "symbol": "TPT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/7603/thumb/pocket.jpg?1658907018"
        },
        {
          "chainId": 1,
          "address": "0xeca82185adce47f39c684352b0439f030f860318",
          "name": "PERL eco",
          "symbol": "PERL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4682/thumb/PERL.eco-Icon-green_6x.png?1624365340"
        },
        {
          "chainId": 1,
          "address": "0x8640353cdc9778deab0df45d12fb3013deac079c",
          "name": "DEVITA",
          "symbol": "LIFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22268/thumb/divita.PNG?1641339910"
        },
        {
          "chainId": 1,
          "address": "0xe35f19e4457a114a951781aaf421ec5266ef25fe",
          "name": "Vidiachange",
          "symbol": "VIDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14346/thumb/vidia.png?1615504632"
        },
        {
          "chainId": 1,
          "address": "0x4fb721ef3bf99e0f2c193847afa296b9257d3c30",
          "name": "Tokenplace",
          "symbol": "TOK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15779/thumb/output-onlinepngtools_%283%29.png?1621837855"
        },
        {
          "chainId": 1,
          "address": "0xf2ec4a773ef90c58d98ea734c0ebdb538519b988",
          "name": "Doge 2 0",
          "symbol": "DOGE20",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30902/thumb/2DOGE_200.jpg?1688620052"
        },
        {
          "chainId": 1,
          "address": "0xafe7131a57e44f832cb2de78ade38cad644aac2f",
          "name": "Morpho Aave Tether USD",
          "symbol": "MAUSDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29834/thumb/maUSDT_%284%29.png?1681718428"
        },
        {
          "chainId": 1,
          "address": "0xb3cb8d5aeff0f4d1f432f353309f47b885e404e3",
          "name": "MEVerse",
          "symbol": "MEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24566/thumb/0sp1DWVw_400x400.png?1648191682"
        },
        {
          "chainId": 1,
          "address": "0xb683d83a532e2cb7dfa5275eed3698436371cc9f",
          "name": "BTU Protocol",
          "symbol": "BTU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3697/thumb/btuprotocol.jpeg?1549597021"
        },
        {
          "chainId": 1,
          "address": "0x666d875c600aa06ac1cf15641361dec3b00432ef",
          "name": "BTSE Token",
          "symbol": "BTSE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10807/thumb/BTSE_logo_Square.jpeg?1583965964"
        },
        {
          "chainId": 1,
          "address": "0x0ec9f76202a7061eb9b3a7d6b59d36215a7e37da",
          "name": "BlackPool",
          "symbol": "BPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15887/thumb/uyO7dQzR_400x400.jpg?1622199432"
        },
        {
          "chainId": 1,
          "address": "0x8052327f1baf94a9dc8b26b9100f211ee3774f54",
          "name": "A2DAO",
          "symbol": "ATD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14509/thumb/Logo.jpg?1680842346"
        },
        {
          "chainId": 1,
          "address": "0xb25ea095997f5bbaa6cea962c4fbf3bfc3c09776",
          "name": "Promethios",
          "symbol": "FIRE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30750/thumb/Fire_Logo.png?1686728534"
        },
        {
          "chainId": 1,
          "address": "0xecc0f1f860a82ab3b442382d93853c02d6384389",
          "name": "Axis DeFi",
          "symbol": "AXIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12199/thumb/YeLWZ3V.jpg?1597998424"
        },
        {
          "chainId": 1,
          "address": "0xc666081073e8dff8d3d1c2292a29ae1a2153ec09",
          "name": "Digitex",
          "symbol": "DGTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2188/thumb/DGTX.png?1616385887"
        },
        {
          "chainId": 1,
          "address": "0x869dbe51dc214fcb663604b0f7b548592f8c71f5",
          "name": "Okidoki Social",
          "symbol": "DOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27018/thumb/Okidoki_Logo.png?1661480220"
        },
        {
          "chainId": 1,
          "address": "0x820802fa8a99901f52e39acd21177b0be6ee2974",
          "name": "EUROe Stablecoin",
          "symbol": "EUROE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28913/thumb/euroe-200x200-round.png?1675338103"
        },
        {
          "chainId": 1,
          "address": "0xbfb2b6870501a6ff17121d676a0a45a38c9eed1e",
          "name": "LuckyToad",
          "symbol": "TOAD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28867/thumb/Coingecko_listing.png?1683179547"
        },
        {
          "chainId": 1,
          "address": "0x32d5b155e82c87c491ea14b59477ddd0957e242c",
          "name": "X Bridge Bot",
          "symbol": "XFER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31178/thumb/Xbot.jpeg?1691119209"
        },
        {
          "chainId": 1,
          "address": "0x1c9922314ed1415c95b9fd453c3818fd41867d0b",
          "name": "Tower",
          "symbol": "TOWER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14134/thumb/tower-circular-1000.png?1632195469"
        },
        {
          "chainId": 1,
          "address": "0x02d7a93829b365b7ad4c582dace1493aac50a290",
          "name": "Scat",
          "symbol": "CAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30429/thumb/output-onlinepngtools.png?1684462192"
        },
        {
          "chainId": 1,
          "address": "0xf4f618eff5ef36cde2fca4fbd86554c62fb1382b",
          "name": "Astra Guild Ventures",
          "symbol": "AGV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21603/thumb/astra_guild.PNG?1639551997"
        },
        {
          "chainId": 1,
          "address": "0x3c3a81e81dc49a522a592e7622a7e711c06bf354",
          "name": "Mantle",
          "symbol": "MNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30980/thumb/token-logo.png?1689320029"
        },
        {
          "chainId": 1,
          "address": "0x78d448a1d1fedaa3e916f467568e86081e0f4df5",
          "name": "MetaBET",
          "symbol": "MBET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22778/thumb/y5TphxL.jpeg?1642577860"
        },
        {
          "chainId": 1,
          "address": "0xe70e1d6a2e971ff6debc21dd2540064c25c9bc0d",
          "name": "Spider Spirit",
          "symbol": "SPIDER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30741/thumb/200x2001.png?1686724700"
        },
        {
          "chainId": 1,
          "address": "0x93c9175e26f57d2888c7df8b470c9eea5c0b0a93",
          "name": "B cube ai",
          "symbol": "BCUBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15948/thumb/bcube.PNG?1622511353"
        },
        {
          "chainId": 1,
          "address": "0x9ab165d795019b6d8b3e971dda91071421305e5a",
          "name": "Aurora Chain",
          "symbol": "AOA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4755/thumb/aurora-logo.png?1547040076"
        },
        {
          "chainId": 1,
          "address": "0x8e0e57dcb1ce8d9091df38ec1bfc3b224529754a",
          "name": "Moon Tropica",
          "symbol": "CAH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28470/thumb/MTLOGO.png?1671001371"
        },
        {
          "chainId": 1,
          "address": "0xc73c167e7a4ba109e4052f70d5466d0c312a344d",
          "name": "Sanshu Inu",
          "symbol": "SANSHU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15370/thumb/m9DgRlXY.jpg?1623177779"
        },
        {
          "chainId": 1,
          "address": "0xd1d2eb1b1e90b638588728b4130137d262c87cae",
          "name": "GALA",
          "symbol": "GALA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435"
        },
        {
          "chainId": 1,
          "address": "0x888888888889c00c67689029d7856aac1065ec11",
          "name": "Opium",
          "symbol": "OPIUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960"
        },
        {
          "chainId": 1,
          "address": "0xd7d05bda4bf5876ba1254b3eaaf8b47d2f5676eb",
          "name": "STABLE ASSET",
          "symbol": "STA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12313/thumb/stable_200x200.png?1599083938"
        },
        {
          "chainId": 1,
          "address": "0x3d382228c54736d831fac2748f4734d9177c7332",
          "name": "Aniverse",
          "symbol": "ANV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16348/thumb/anv.PNG?1623794994"
        },
        {
          "chainId": 1,
          "address": "0xa2847348b58ced0ca58d23c7e9106a49f1427df6",
          "name": "Convex FPIS",
          "symbol": "CVXFPIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29678/thumb/photo_2023-03-18_21-37-17.jpg?1680587888"
        },
        {
          "chainId": 1,
          "address": "0x2ccbff3a042c68716ed2a2cb0c544a9f1d1935e1",
          "name": "DMarket",
          "symbol": "DMT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1602/thumb/dmarket.png?1547223830"
        },
        {
          "chainId": 1,
          "address": "0x2be5e8c109e2197d077d13a82daead6a9b3433c5",
          "name": "Tokamak Network",
          "symbol": "TON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12260/thumb/D919x5-s_400x400.png?1598568068"
        },
        {
          "chainId": 1,
          "address": "0x46cca329970b33e1a007dd4ef0594a1cedb3e72a",
          "name": "Yesports",
          "symbol": "YESP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29767/thumb/YESP_TOKEN.png?1681194310"
        },
        {
          "chainId": 1,
          "address": "0xed91879919b71bb6905f23af0a68d231ecf87b14",
          "name": "DMM  Governance",
          "symbol": "DMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11677/thumb/dmm.png?1592674690"
        },
        {
          "chainId": 1,
          "address": "0x9e04f519b094f5f8210441e285f603f4d2b50084",
          "name": "EarthFund",
          "symbol": "1EARTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20822/thumb/earthfund.PNG?1637722232"
        },
        {
          "chainId": 1,
          "address": "0x60d604890feaa0b5460b28a424407c24fe89374a",
          "name": "Balancer Aave v3 Boosted Pool  WETH ",
          "symbol": "BB-A-WETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30386/thumb/bb-a-weth.png?1684305824"
        },
        {
          "chainId": 1,
          "address": "0x2620638eda99f9e7e902ea24a285456ee9438861",
          "name": "Crust Shadow",
          "symbol": "CSM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16037/thumb/2_mnCYZfHmLg2bFrjM3vKtPw.png?1622680217"
        },
        {
          "chainId": 1,
          "address": "0x5bbe36152d3cd3eb7183a82470b39b29eedf068b",
          "name": "Hord hETH",
          "symbol": "HETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29624/thumb/eth-staking-pool-icon.92471e99_%282%29_1.png?1683599515"
        },
        {
          "chainId": 1,
          "address": "0xd61143652af94f570c7d9429356662dd859ca6ec",
          "name": "Fintropy",
          "symbol": "FINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21222/thumb/quExJXdW_400x400.png?1638740691"
        },
        {
          "chainId": 1,
          "address": "0xc1f976b91217e240885536af8b63bc8b5269a9be",
          "name": "Public Index Network",
          "symbol": "PIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/241/thumb/pin.jpeg?1623054253"
        },
        {
          "chainId": 1,
          "address": "0xa34ee6108fe427f91edce0d6520d9fec0e64f67b",
          "name": "Pepe Le Pew Coin",
          "symbol": "PLPC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30876/thumb/plpc.png?1689705860"
        },
        {
          "chainId": 1,
          "address": "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
          "name": "Storj",
          "symbol": "STORJ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/949/thumb/storj.png?1547034811"
        },
        {
          "chainId": 1,
          "address": "0xc2544a32872a91f4a553b404c6950e89de901fdb",
          "name": "Frax Price Index Share",
          "symbol": "FPIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24944/thumb/FPIS_icon.png?1679886817"
        },
        {
          "chainId": 1,
          "address": "0xcb56b52316041a62b6b5d0583dce4a8ae7a3c629",
          "name": "Cigarette",
          "symbol": "CIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22145/thumb/logo200.png?1676535394"
        },
        {
          "chainId": 1,
          "address": "0x36520f6336490088c2455279c59cefac66984b36",
          "name": "Copium",
          "symbol": "COPIUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30466/thumb/copium.png?1684664211"
        },
        {
          "chainId": 1,
          "address": "0x67cc621ab2d086a101cff3340df0a065ac75827c",
          "name": "Floki Musk",
          "symbol": "FLOKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18850/thumb/1632145919586.png?1633592006"
        },
        {
          "chainId": 1,
          "address": "0xa4eb9c64ec359d093eac7b65f51ef933d6e5f7cd",
          "name": "Stablz",
          "symbol": "STABLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28983/thumb/Frame_2869.png?1675760347"
        },
        {
          "chainId": 1,
          "address": "0x21ead867c8c5181854f6f8ce71f75b173d2bc16a",
          "name": "LSDx Pool",
          "symbol": "ETHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29780/thumb/ethx.png?1681284433"
        },
        {
          "chainId": 1,
          "address": "0x375abb85c329753b1ba849a601438ae77eec9893",
          "name": "ParagonsDAO",
          "symbol": "PDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22642/thumb/PDT_LOGO_200.jpg?1674482648"
        },
        {
          "chainId": 1,
          "address": "0xd2be3722b17b616c51ed9b8944a227d1ce579c24",
          "name": "Dtube Coin",
          "symbol": "DTUBE",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467"
        },
        {
          "chainId": 1,
          "address": "0x3392d8a60b77f8d3eaa4fb58f09d835bd31add29",
          "name": "IndiGG",
          "symbol": "INDI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23857/thumb/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_0cb39c1f-db6d-4dd9-9143-1f30ee38d74d_743x726.jpeg?1645594083"
        },
        {
          "chainId": 1,
          "address": "0xa361718326c15715591c299427c62086f69923d9",
          "name": "Aave BUSD",
          "symbol": "ABUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14245/thumb/aBUSD.346a65d7_-_Copy.png?1615528703"
        },
        {
          "chainId": 1,
          "address": "0xda70fbac9e1c9ac3475c24a3a847f85f1388c429",
          "name": "YourWallet ETH",
          "symbol": "YOURWALLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28937/thumb/logo_200.png?1675503402"
        },
        {
          "chainId": 1,
          "address": "0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6",
          "name": "Scaleswap",
          "symbol": "SCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16360/thumb/thumbnail_1170823958_vertical_logo_lateral_radiance.png?1623810516"
        },
        {
          "chainId": 1,
          "address": "0x603778e5e07e180e300bd10df7fa626313a206b2",
          "name": "WIZ Protocol",
          "symbol": "WIZ",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/28069/thumb/CG_WIZ.png?1671588506"
        },
        {
          "chainId": 1,
          "address": "0x7c8155909cd385f120a56ef90728dd50f9ccbe52",
          "name": "Nahmii",
          "symbol": "NII",
          "decimals": 15,
          "logoURI": "https://assets.coingecko.com/coins/images/9786/thumb/nahmii-sm_icon-full-color.png?1608513773"
        },
        {
          "chainId": 1,
          "address": "0x790bfacae71576107c068f494c8a6302aea640cb",
          "name": "CryptoBossCoin",
          "symbol": "CBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7114/thumb/eqIkj-ZZ_400x400.jpg?1549521587"
        },
        {
          "chainId": 1,
          "address": "0x69d9905b2e5f6f5433212b7f3c954433f23c1572",
          "name": "Onooks",
          "symbol": "OOKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16281/thumb/onooks-logo.png?1623637635"
        },
        {
          "chainId": 1,
          "address": "0x6d57b2e05f26c26b549231c866bdd39779e4a488",
          "name": "VNX Gold",
          "symbol": "VNXAU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28019/thumb/VNX_Token_logo_%283%29.png?1687227572"
        },
        {
          "chainId": 1,
          "address": "0x30cf203b48edaa42c3b4918e955fed26cd012a3f",
          "name": "MetaGame",
          "symbol": "SEED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13099/thumb/V8phEz8V.png?1612854078"
        },
        {
          "chainId": 1,
          "address": "0xcae3faa4b6cf660aef18474074949ba0948bc025",
          "name": "Generational Wealth",
          "symbol": "GEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30419/thumb/ethericon200x200.png?1684391637"
        },
        {
          "chainId": 1,
          "address": "0x95aa5d2dbd3c16ee3fdea82d5c6ec3e38ce3314f",
          "name": "PointPay",
          "symbol": "PXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8950/thumb/6f9823ed-464a-4413-8940-72aa61d30b43.png?1645271610"
        },
        {
          "chainId": 1,
          "address": "0x764104dc24dadff01150253a58c82337984b4319",
          "name": "hiCLONEX",
          "symbol": "HICLONEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28436/thumb/hiclonex.png?1670568067"
        },
        {
          "chainId": 1,
          "address": "0x62a116b9ffe7338bee13c87e69552de9cc67627d",
          "name": "FUBT Token",
          "symbol": "FUC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8132/thumb/FUC_fubt_token.png?1555325050"
        },
        {
          "chainId": 1,
          "address": "0x25644ea5be09d1266b5df3f96559ecdc2d0ad69b",
          "name": "Shori",
          "symbol": "YSHORI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29710/thumb/Shori_logo.jpeg?1680767199"
        },
        {
          "chainId": 1,
          "address": "0x4ec1b60b96193a64acae44778e51f7bff2007831",
          "name": "Edge",
          "symbol": "EDGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1848/thumb/EDGE.png?1632300420"
        },
        {
          "chainId": 1,
          "address": "0xbdcfbf5c4d91abc0bc9709c7286d00063c0e6f22",
          "name": "PeerGuess",
          "symbol": "GUESS",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/1743/thumb/guess.png?1547036022"
        },
        {
          "chainId": 1,
          "address": "0x1936c91190e901b7dd55229a574ae22b58ff498a",
          "name": "MEVFree",
          "symbol": "MEVFREE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29510/thumb/mevfree200.png?1679383352"
        },
        {
          "chainId": 1,
          "address": "0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54",
          "name": "SSV Network",
          "symbol": "SSV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19155/thumb/ssv.png?1638181902"
        },
        {
          "chainId": 1,
          "address": "0xce391315b414d4c7555956120461d21808a69f3a",
          "name": "Bao Finance V2",
          "symbol": "BAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28287/thumb/bao-logo-mark-full-color-rgb-900px-w-72ppi.png?1689931526"
        },
        {
          "chainId": 1,
          "address": "0x0e8d2eb7d6bdf28393c25a1966385ad32ff0259a",
          "name": "Streamer Inu",
          "symbol": "STREAMERINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27994/thumb/logo.png?1666922914"
        },
        {
          "chainId": 1,
          "address": "0x0fbe9cc858d7ad6e246fe9d01aed22abd2a66f0b",
          "name": "Danat Coin",
          "symbol": "DNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13170/thumb/1528717728file.png?1605855085"
        },
        {
          "chainId": 1,
          "address": "0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430",
          "name": "PlayDapp",
          "symbol": "PLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14316/thumb/54023228.png?1615366911"
        },
        {
          "chainId": 1,
          "address": "0xb26c4b3ca601136daf98593feaeff9e0ca702a8d",
          "name": "Aladdin DAO",
          "symbol": "ALD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18277/thumb/78200839.png?1631234134"
        },
        {
          "chainId": 1,
          "address": "0xc064f4f215b6a1e4e7f39bd8530c4de0fc43ee9d",
          "name": "LeisureMeta",
          "symbol": "LM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25761/thumb/SVG_16533804486374586M.jpg?1653548604"
        },
        {
          "chainId": 1,
          "address": "0xc1abb8c93be6811affc70675b0432926c4bfbb5d",
          "name": "UERII",
          "symbol": "UERII",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29388/thumb/Avatar512.png?1678424774"
        },
        {
          "chainId": 1,
          "address": "0xacdf0dba4b9839b96221a8487e9ca660a48212be",
          "name": "High Yield USD",
          "symbol": "HYUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30899/thumb/resized_logo.png?1688695744"
        },
        {
          "chainId": 1,
          "address": "0xb78b3320493a4efaa1028130c5ba26f0b6085ef8",
          "name": "Dracula",
          "symbol": "DRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12758/thumb/DRC-LOGO.png?1648050189"
        },
        {
          "chainId": 1,
          "address": "0x8e235f491ae66b82296d58332adc2a021c449c10",
          "name": "Tipja",
          "symbol": "TIPJA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30182/thumb/logo.jpg?1683599233"
        },
        {
          "chainId": 1,
          "address": "0xf7168c8abb0ff80116413a8d95396bbdc318a3ff",
          "name": "KEK",
          "symbol": "KEKE",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/30172/thumb/KEKE.png?1683541095"
        },
        {
          "chainId": 1,
          "address": "0xbeef3bb9da340ebdf0f5bae2e85368140d7d85d0",
          "name": "BuyMORE",
          "symbol": "MORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26769/thumb/0BD4687E-6C18-43D2-87C7-C5BC144B49D0_200x200.jpeg?1660040979"
        },
        {
          "chainId": 1,
          "address": "0xc5f0f7b66764f6ec8c8dff7ba683102295e16409",
          "name": "First Digital USD",
          "symbol": "FDUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31079/thumb/firstfigital.jpeg?1690347713"
        },
        {
          "chainId": 1,
          "address": "0xaaaaaa20d9e0e2461697782ef11675f668207961",
          "name": "Aurora",
          "symbol": "AURORA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20582/thumb/aurora.jpeg?1637250883"
        },
        {
          "chainId": 1,
          "address": "0xe63d6b308bce0f6193aec6b7e6eba005f41e36ab",
          "name": "Stone",
          "symbol": "STN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14593/thumb/stone-logo.png?1617164796"
        },
        {
          "chainId": 1,
          "address": "0xc221b7e65ffc80de234bbb6667abdd46593d34f0",
          "name": "Wrapped Centrifuge",
          "symbol": "WCFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17106/thumb/WCFG.jpg?1626266462"
        },
        {
          "chainId": 1,
          "address": "0x02e7f808990638e9e67e1f00313037ede2362361",
          "name": "KiboShib",
          "symbol": "KIBSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29335/thumb/foto_no_exif_%2811%29%282%29_%281%29.png?1678173395"
        },
        {
          "chainId": 1,
          "address": "0x22987407fd1fc5a971e3fda3b3e74c88666cda91",
          "name": "Smart Reward Token",
          "symbol": "SRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25035/thumb/me-44EyS_400x400.jpg?1649852504"
        },
        {
          "chainId": 1,
          "address": "0x84777600c51996b2ce92a514ff2583b7d7c2239b",
          "name": "Recovery Value USD",
          "symbol": "RVUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29839/thumb/RVusd_logo.png?1681718797"
        },
        {
          "chainId": 1,
          "address": "0xa130e3a33a4d84b04c3918c4e5762223ae252f80",
          "name": "Swash",
          "symbol": "SWASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759"
        },
        {
          "chainId": 1,
          "address": "0x0c37bcf456bc661c14d596683325623076d7e283",
          "name": "Aeron",
          "symbol": "ARNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1124/thumb/ARNX-token-logo-256x256.png?1602652111"
        },
        {
          "chainId": 1,
          "address": "0x3b7f247f21bf3a07088c2d3423f64233d4b069f7",
          "name": "Dynamite",
          "symbol": "DYNMT",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224"
        },
        {
          "chainId": 1,
          "address": "0x7ecbb21346c501fd07eb165e406120fa32381c16",
          "name": "Ecoreal Estate",
          "symbol": "ECOREAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6431/thumb/golden_leave_in_200_x_200.png?1655886676"
        },
        {
          "chainId": 1,
          "address": "0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3",
          "name": "disBalancer",
          "symbol": "DDOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138"
        },
        {
          "chainId": 1,
          "address": "0xda30f261a962d5aae94c9ecd170544600d193766",
          "name": "Orbler",
          "symbol": "ORBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24056/thumb/fA2YATWT_400x400.jpg?1646203182"
        },
        {
          "chainId": 1,
          "address": "0x67954768e721fad0f0f21e33e874497c73ed6a82",
          "name": "KeKChain",
          "symbol": "KEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27061/thumb/20220816_155427.png?1661668348"
        },
        {
          "chainId": 1,
          "address": "0xe4f726adc8e89c6a6017f01eada77865db22da14",
          "name": "PieDAO Balanced Crypto Pie",
          "symbol": "BCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13560/thumb/BCP.png?1609813753"
        },
        {
          "chainId": 1,
          "address": "0x1d37986f252d0e349522ea6c3b98cb935495e63e",
          "name": "ChartEx",
          "symbol": "CHART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12523/thumb/chartex.png?1600499406"
        },
        {
          "chainId": 1,
          "address": "0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d",
          "name": "Aave DAI v1",
          "symbol": "ADAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10843/thumb/aDAI.png?1584698791"
        },
        {
          "chainId": 1,
          "address": "0xc32db1d3282e872d98f6437d3bcfa57801ca6d5c",
          "name": "VoldemortTrumpRobotnik 10Neko",
          "symbol": "ETHEREUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31061/thumb/icon_medium.jpg?1690176312"
        },
        {
          "chainId": 1,
          "address": "0xbcc66ed2ab491e9ae7bf8386541fb17421fa9d35",
          "name": "Skull",
          "symbol": "SKULL",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/10641/thumb/skull.png?1581339740"
        },
        {
          "chainId": 1,
          "address": "0xf941d3aabf2ee0f5589e68ba6047b8329592b366",
          "name": "Good Dog",
          "symbol": "HEEL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27138/thumb/HEEL_New_Logo.png?1687836368"
        },
        {
          "chainId": 1,
          "address": "0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0",
          "name": "AnRKey X",
          "symbol": "ANRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg?1608311301"
        },
        {
          "chainId": 1,
          "address": "0xd4c435f5b09f855c3317c8524cb1f586e42795fa",
          "name": "Cindicator",
          "symbol": "CND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1006/thumb/cindicator.png?1547034913"
        },
        {
          "chainId": 1,
          "address": "0x3db045814d0a29d831fe38055cb97a956ef7cafb",
          "name": "BlockRemit",
          "symbol": "REMIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30360/thumb/20230512_204249_copy_200x200.jpg?1684233708"
        },
        {
          "chainId": 1,
          "address": "0x903bef1736cddf2a537176cf3c64579c3867a881",
          "name": "Legacy ICHI",
          "symbol": "ICHI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26004/thumb/legacy.png?1655278844"
        },
        {
          "chainId": 1,
          "address": "0x045da4bfe02b320f4403674b3b7d121737727a36",
          "name": "DeFi Franc",
          "symbol": "DCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28108/thumb/22249.png?1667544891"
        },
        {
          "chainId": 1,
          "address": "0x0168703872fa06741ecaa9dff7803168e83f7ae0",
          "name": "Mirocana",
          "symbol": "MIRO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1651/thumb/mirocana.jpg?1547035896"
        },
        {
          "chainId": 1,
          "address": "0xad32a8e6220741182940c5abf610bde99e737b2d",
          "name": "PieDAO DOUGH v2",
          "symbol": "DOUGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12693/thumb/DOUGH2v.png?1602655308"
        },
        {
          "chainId": 1,
          "address": "0xa1116930326d21fb917d5a27f1e9943a9595fb47",
          "name": "Staked Aave Balancer Pool Token",
          "symbol": "STKABPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16789/thumb/StkABPT_2x.png?1625046066"
        },
        {
          "chainId": 1,
          "address": "0x2781246fe707bb15cee3e5ea354e2154a2877b16",
          "name": "ELYSIA",
          "symbol": "EL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10887/thumb/CeyRVXPY_400x400.jpg?1585559128"
        },
        {
          "chainId": 1,
          "address": "0xed0d5747a9ab03a75fbfec3228cd55848245b75d",
          "name": "e Money",
          "symbol": "NGM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13722/thumb/logo-200x200.jpg?1626095888"
        },
        {
          "chainId": 1,
          "address": "0xc40f23a3e9613e012944f7957edce97899fa920d",
          "name": "dHealth",
          "symbol": "DHP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20167/thumb/dHealth_Network_Icon_200w.png?1636601369"
        },
        {
          "chainId": 1,
          "address": "0x8f828a0644f12fa352888e645a90333d30f6fd7d",
          "name": "Rinia Inu",
          "symbol": "RINIA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29466/thumb/rinia.png?1679110331"
        },
        {
          "chainId": 1,
          "address": "0xd567b5f02b9073ad3a982a099a23bf019ff11d1c",
          "name": "Gamestarter",
          "symbol": "GAME",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/17604/thumb/gpMi14-r_400x400.jpg?1628647205"
        },
        {
          "chainId": 1,
          "address": "0x4485561db76614ff727f8e0a3ea95690b8b16022",
          "name": "Invox Finance",
          "symbol": "INVOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3200/thumb/invox.png?1547037651"
        },
        {
          "chainId": 1,
          "address": "0x6b0956258ff7bd7645aa35369b55b61b8e6d6140",
          "name": "Maximus LUCKY",
          "symbol": "LUCKY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27685/thumb/IMG_1129.PNG?1665214648"
        },
        {
          "chainId": 1,
          "address": "0x3c2a309d9005433c1bc2c92ef1be06489e5bf258",
          "name": "Wrapped Paycoin",
          "symbol": "WPCI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24390/thumb/new_logo_square_%281%29.png?1648792416"
        },
        {
          "chainId": 1,
          "address": "0x382a1667c9062f0621362f49076ef6e4fe4c9ec7",
          "name": "RushCoin",
          "symbol": "RUSH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25053/thumb/Kax1yBSz_400x400.png?1649979044"
        },
        {
          "chainId": 1,
          "address": "0xc0ae17eb994fa828540ffa53776b3830233a1b02",
          "name": "Element Black",
          "symbol": "ELT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25160/thumb/4TCtUKHs_400x400.jpg?1650464346"
        },
        {
          "chainId": 1,
          "address": "0xf974b5f9ac9c6632fee8b76c61b0242ce69c839d",
          "name": "ZYX",
          "symbol": "ZYX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904"
        },
        {
          "chainId": 1,
          "address": "0x305de070488c8469dfac957226c9c900c4bfba22",
          "name": "More",
          "symbol": "MORE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/742/thumb/1722.png?1620080666"
        },
        {
          "chainId": 1,
          "address": "0x9dcd367e2afa8d6e5d6cf0306094e3eb7bbaaf4d",
          "name": "Crypto Bros",
          "symbol": "BROS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30338/thumb/Crypto_Bros_%284%29.png?1684158463"
        },
        {
          "chainId": 1,
          "address": "0x3f7d1c62a8456893c0f55c13e3b5993d2f68287a",
          "name": "Froki",
          "symbol": "FROKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30158/thumb/FROKI_200X200s.png?1683526275"
        },
        {
          "chainId": 1,
          "address": "0x4727a02269943b225a7de9ef28496f36d454b983",
          "name": "Flash Bot",
          "symbol": "FBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31138/thumb/ct0ID9qI_400x400.jpg?1690953697"
        },
        {
          "chainId": 1,
          "address": "0x04c17b9d3b29a78f7bd062a57cf44fc633e71f85",
          "name": "IMPT",
          "symbol": "IMPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28486/thumb/IMPT_logo.png?1677543087"
        },
        {
          "chainId": 1,
          "address": "0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0",
          "name": "4thpillar technologies",
          "symbol": "FOUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287"
        },
        {
          "chainId": 1,
          "address": "0x9469d013805bffb7d3debe5e7839237e535ec483",
          "name": "Darwinia Network",
          "symbol": "RING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9443/thumb/RING.png?1615271827"
        },
        {
          "chainId": 1,
          "address": "0x02de007d412266a2e0fa9287c103474170f06560",
          "name": "Exorde",
          "symbol": "EXD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28684/thumb/logo-exorde.png?1673625181"
        },
        {
          "chainId": 1,
          "address": "0x04a020325024f130988782bd5276e53595e8d16e",
          "name": "Herbalist",
          "symbol": "HERB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/6892/thumb/herbalist-token-twitter.png?1547043247"
        },
        {
          "chainId": 1,
          "address": "0xfeeeef4d7b4bf3cc8bd012d02d32ba5fd3d51e31",
          "name": "Tail",
          "symbol": "TAIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28260/thumb/IMG_20221210_122215_723.jpg?1670744910"
        },
        {
          "chainId": 1,
          "address": "0xf56b164efd3cfc02ba739b719b6526a6fa1ca32a",
          "name": "Curio Governance",
          "symbol": "CGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891"
        },
        {
          "chainId": 1,
          "address": "0xb097dd6a23175c10eaa45a1f410d7eb4cfdafc8a",
          "name": "KAINET",
          "symbol": "KAINET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19071/thumb/kaiinu.1d3b6110.png?1675593102"
        },
        {
          "chainId": 1,
          "address": "0xef27252b567f6b3fe35b34a85be322917abe524a",
          "name": "Flona",
          "symbol": "FLONA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28838/thumb/E3784046-3121-49D5-A090-1409BD81942D.jpeg?1674796729"
        },
        {
          "chainId": 1,
          "address": "0x9a0df129e798438a8ad995368bd82baa7eee8913",
          "name": "BEEP Coin",
          "symbol": "BEEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30566/thumb/green.png?1685074488"
        },
        {
          "chainId": 1,
          "address": "0xe5097d9baeafb89f9bcb78c9290d545db5f9e9cb",
          "name": "Hummingbot",
          "symbol": "HBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21717/thumb/PDPuf0tJ_400x400.jpg?1639863782"
        },
        {
          "chainId": 1,
          "address": "0xe7ac8545e34771de3706598abb3db9a19af2a07f",
          "name": "MONKED",
          "symbol": "MONKED",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30011/thumb/monked200.png?1682497322"
        },
        {
          "chainId": 1,
          "address": "0x0af5855a348c58a8bbf65bcc7b9bbacd0e7e2238",
          "name": "Ulanco",
          "symbol": "UAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20662/thumb/udacity_logo_icon_169367.png?1637545628"
        },
        {
          "chainId": 1,
          "address": "0xb009bfaaf85e53f55d8657781eb69feaaed83672",
          "name": "EdgeSwap",
          "symbol": "EGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24878/thumb/egs.png?1649218020"
        },
        {
          "chainId": 1,
          "address": "0x72e5390edb7727e3d4e3436451dadaff675dbcc0",
          "name": "Hanu Yokia",
          "symbol": "HANU",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png?1627127472"
        },
        {
          "chainId": 1,
          "address": "0x3576eff2be8b1a31782b3e8dfd962a6ddb087890",
          "name": "Rabbit Race",
          "symbol": "RABBITS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31063/thumb/uCAj-QeZ_400x400.jpg?1690184787"
        },
        {
          "chainId": 1,
          "address": "0xbe393aa534f82c0ffac31bf06a23e283acb3352b",
          "name": "TokenAsset",
          "symbol": "NTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13710/thumb/8284.png?1611097251"
        },
        {
          "chainId": 1,
          "address": "0x68c85b9f78f30a0df5ac5723e4e700037f185415",
          "name": "AiWork",
          "symbol": "AWO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15373/thumb/aiwork.PNG?1620691299"
        },
        {
          "chainId": 1,
          "address": "0x308564dc5217c39386f5eae96545159e1d396661",
          "name": "HLP",
          "symbol": "HLP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12668/thumb/simbolo-hlp-token.png?1601467816"
        },
        {
          "chainId": 1,
          "address": "0x07d9e49ea402194bf48a8276dafb16e4ed633317",
          "name": "Dalecoin",
          "symbol": "DALC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/932/thumb/dalecoin.png?1547791491"
        },
        {
          "chainId": 1,
          "address": "0x52dec19feef469d7a683963b7380ecd0b1aff9c7",
          "name": "Octo",
          "symbol": "OCTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30160/thumb/octo.jpeg?1683527017"
        },
        {
          "chainId": 1,
          "address": "0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7",
          "name": "Duckereum",
          "symbol": "DUCKER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26325/thumb/coin.png?1669981187"
        },
        {
          "chainId": 1,
          "address": "0x9c2dc0c3cc2badde84b0025cf4df1c5af288d835",
          "name": "COR Token",
          "symbol": "COR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12856/thumb/COR.png?1620210966"
        },
        {
          "chainId": 1,
          "address": "0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4",
          "name": "Enigma",
          "symbol": "ENG",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1007/thumb/enigma-logo.png?1547034914"
        },
        {
          "chainId": 1,
          "address": "0x2bba3cf6de6058cc1b4457ce00deb359e2703d7f",
          "name": "HashCoin",
          "symbol": "HSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4989/thumb/hashfuture-logo.png?1531741876"
        },
        {
          "chainId": 1,
          "address": "0x23894dc9da6c94ecb439911caf7d337746575a72",
          "name": "Geojam",
          "symbol": "JAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24648/thumb/200px_Transparent_Bubble_Token_Icon.png?1668597490"
        },
        {
          "chainId": 1,
          "address": "0x0954906da0bf32d5479e25f46056d22f08464cab",
          "name": "Index Cooperative",
          "symbol": "INDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12729/thumb/index.png?1634894321"
        },
        {
          "chainId": 1,
          "address": "0x5fbc3cb8b428cc00a04808870295d39962cc7fee",
          "name": "Bitazza",
          "symbol": "BTZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24597/thumb/BTZ_Logo_%28200x200%29.png?1649777047"
        },
        {
          "chainId": 1,
          "address": "0xf1ca9cb74685755965c7458528a36934df52a3ef",
          "name": "AVINOC",
          "symbol": "AVINOC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4659/thumb/qX7fh69Q_400x400-1.jpg?1636680685"
        },
        {
          "chainId": 1,
          "address": "0x42dbbd5ae373fea2fc320f62d44c058522bb3758",
          "name": "Memecoin",
          "symbol": "MEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16370/thumb/mem_gold_200x200_copy.png?1623821100"
        },
        {
          "chainId": 1,
          "address": "0x8e57c27761ebbd381b0f9d09bb92ceb51a358abb",
          "name": "extraDNA",
          "symbol": "XDNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830"
        },
        {
          "chainId": 1,
          "address": "0xc834fa996fa3bec7aad3693af486ae53d8aa8b50",
          "name": "Convergence",
          "symbol": "CONV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14519/thumb/Convergence_Logo_%28Final%29.png?1616667041"
        },
        {
          "chainId": 1,
          "address": "0x674c6ad92fd080e4004b2312b45f796a192d27a0",
          "name": "Neutrino Index Token",
          "symbol": "XTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10117/thumb/USDN_Logo.jpg?1675223697"
        },
        {
          "chainId": 1,
          "address": "0xb4a677b0e363c3815d46326954a4e4d2b1ace357",
          "name": "THENODE",
          "symbol": "THE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10292/thumb/thenode_logo.png?1577334103"
        },
        {
          "chainId": 1,
          "address": "0x2a03a891add2dc6d0f7b94419086630ba5cb65b6",
          "name": "Dreamverse",
          "symbol": "DV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21554/thumb/dv.png?1639464955"
        },
        {
          "chainId": 1,
          "address": "0xdfc3829b127761a3218bfcee7fc92e1232c9d116",
          "name": "PRivaCY Coin",
          "symbol": "PRCY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991"
        },
        {
          "chainId": 1,
          "address": "0xf2ddae89449b7d26309a5d54614b1fc99c608af5",
          "name": "ASTA",
          "symbol": "ASTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11123/thumb/asta_logo.png?1588734656"
        },
        {
          "chainId": 1,
          "address": "0xe304283c3e60cefaf7ea514007cf4e8fdc3d869d",
          "name": "Gecoin",
          "symbol": "GEC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25646/thumb/62162f0d583e5a047dc02e7e_gecoin_200x200.png?1653019356"
        },
        {
          "chainId": 1,
          "address": "0x5b649c07e7ba0a1c529deaabed0b47699919b4a2",
          "name": "Suzuverse",
          "symbol": "SGT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30549/thumb/Suzuverse.png?1684998480"
        },
        {
          "chainId": 1,
          "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
          "name": "Compound",
          "symbol": "COMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10775/thumb/COMP.png?1592625425"
        },
        {
          "chainId": 1,
          "address": "0x72a66e54b66892ae3bbe54df7bb7dd5ae927a6f9",
          "name": "Crypto Village Accelerator CVAG",
          "symbol": "CVAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17124/thumb/cvag.PNG?1626401266"
        },
        {
          "chainId": 1,
          "address": "0x7c3ed93b03e67c1fa240395b28e38c4386321b58",
          "name": "Wally Bot",
          "symbol": "WALLY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31046/thumb/IMG_4215.jpeg?1689917951"
        },
        {
          "chainId": 1,
          "address": "0xdd1ad9a21ce722c151a836373babe42c868ce9a4",
          "name": "Universal Basic Income",
          "symbol": "UBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15269/thumb/ubi.png?1620287853"
        },
        {
          "chainId": 1,
          "address": "0x636d585f40a7a445da7403fcf92e03f89dc3ebd0",
          "name": "pepeGPT",
          "symbol": "PEPEGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30010/thumb/2002002006khlFpye_400x400.jpg?1682496121"
        },
        {
          "chainId": 1,
          "address": "0x1500205f50bf3fd976466d0662905c9ff254fc9c",
          "name": "BitBoost",
          "symbol": "BBT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/1194/thumb/bitboost.jpg?1547035224"
        },
        {
          "chainId": 1,
          "address": "0xe9287543684f440b2f29983a3472987bf7b0ed12",
          "name": "Shiba Nodes",
          "symbol": "SHINO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28890/thumb/output-onlinepngtools_%281%29.png?1675156319"
        },
        {
          "chainId": 1,
          "address": "0xd7c302fc3ac829c7e896a32c4bd126f3e8bd0a1f",
          "name": "Bit2Me",
          "symbol": "B2M",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332"
        },
        {
          "chainId": 1,
          "address": "0xc2a81eb482cb4677136d8812cc6db6e0cb580883",
          "name": "TFS",
          "symbol": "TFS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21963/thumb/dOF2IBa.png?1640524604"
        },
        {
          "chainId": 1,
          "address": "0xcedefe438860d2789da6419b3a19cece2a41038d",
          "name": "Love Hate Inu",
          "symbol": "LHINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30455/thumb/lhinu.png?1684492412"
        },
        {
          "chainId": 1,
          "address": "0xc89d9aa9d9e54bb196319c6195aea1038d2bc936",
          "name": "Trend X",
          "symbol": "TRENDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29522/thumb/200.png?1679464304"
        },
        {
          "chainId": 1,
          "address": "0x5d39957fc88566f14ae7e8ab8971d7c603f0ce5e",
          "name": "Eye Labs",
          "symbol": "EYE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31071/thumb/_EYE_logo.png?1690277867"
        },
        {
          "chainId": 1,
          "address": "0x8578530205cecbe5db83f7f29ecfeec860c297c2",
          "name": "smARTOFGIVING",
          "symbol": "AOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007"
        },
        {
          "chainId": 1,
          "address": "0x06a00715e6f92210af9d7680b584931faf71a833",
          "name": "Chronicle",
          "symbol": "XNL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png?1633517024"
        },
        {
          "chainId": 1,
          "address": "0x6e109e9dd7fa1a58bc3eff667e8e41fc3cc07aef",
          "name": "CNH Tether",
          "symbol": "CNHT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/5251/thumb/cny-tether-logo.png?1547040741"
        },
        {
          "chainId": 1,
          "address": "0xc08512927d12348f6620a698105e1baac6ecd911",
          "name": "GYEN",
          "symbol": "GYEN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343"
        },
        {
          "chainId": 1,
          "address": "0x58002a6b6e659a16de9f02f529b10536e307b0d9",
          "name": "Crypto Holding Frank",
          "symbol": "CHFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11184/thumb/chft.png?1589523711"
        },
        {
          "chainId": 1,
          "address": "0xb9e7f8568e08d5659f5d29c4997173d84cdf2607",
          "name": "Swarm City",
          "symbol": "SWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/621/thumb/swarm-city.png?1548611426"
        },
        {
          "chainId": 1,
          "address": "0x095797fd4297fb79883cc912a5ba6313b15c445d",
          "name": "GOLCOIN",
          "symbol": "GOLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png?1663551501"
        },
        {
          "chainId": 1,
          "address": "0x6ec8a24cabdc339a06a172f8223ea557055adaa5",
          "name": "Genaro Network",
          "symbol": "GNX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/1361/thumb/genaro-network.png?1547035387"
        },
        {
          "chainId": 1,
          "address": "0x967b0c95295ead8faef70d26a7846aecd349aaff",
          "name": "Akita DAO",
          "symbol": "HACHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28862/thumb/HACHI_now.png?1674983125"
        },
        {
          "chainId": 1,
          "address": "0xb4615aad766f6de3c55330099e907ff7f13f1582",
          "name": "Onigiri Neko",
          "symbol": "ONIGI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28673/thumb/ONIGI.png?1673157596"
        },
        {
          "chainId": 1,
          "address": "0x16587cf43f044aba0165ffa00acf412631194e4b",
          "name": "Simracer Coin",
          "symbol": "SRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13027/thumb/SimRacer-500px.png?1604456794"
        },
        {
          "chainId": 1,
          "address": "0x111111111117dc0aa78b770fa6a738034120c302",
          "name": "1inch",
          "symbol": "1INCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028"
        },
        {
          "chainId": 1,
          "address": "0xb9d7dddca9a4ac480991865efef82e01273f79c3",
          "name": "Boosted LUSD",
          "symbol": "BLUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27681/thumb/bLUSD_logo.png?1665213755"
        },
        {
          "chainId": 1,
          "address": "0xcc802c45b55581713cecd1eb17be9ab7fccb0844",
          "name": "SBU Honey",
          "symbol": "BHNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26079/thumb/honey.png?1655694712"
        },
        {
          "chainId": 1,
          "address": "0xd346e8ada104093adcf5f4186087e1ad309bb3b3",
          "name": "Shibnaut",
          "symbol": "SHIBN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28872/thumb/66EDE430-1CD7-4524-B610-FC58A7DCDA3E.jpeg?1675131598"
        },
        {
          "chainId": 1,
          "address": "0xe0bceef36f3a6efdd5eebfacd591423f8549b9d5",
          "name": "Defactor",
          "symbol": "FACTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050"
        },
        {
          "chainId": 1,
          "address": "0x3bb86d867a9f3addf994cdadb210fa82f0d4157a",
          "name": "Ghoul",
          "symbol": "GHOUL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16455/thumb/rsz-ghoul2.png?1624249816"
        },
        {
          "chainId": 1,
          "address": "0x9c354503c38481a7a7a51629142963f98ecc12d0",
          "name": "Origin DeFi Governance",
          "symbol": "OGV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26353/thumb/ogv-200x200.png?1657606918"
        },
        {
          "chainId": 1,
          "address": "0x04e0af0af1b7f0023c6b12af5a94df59b0e8cf59",
          "name": "Sensitrust",
          "symbol": "SETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990"
        },
        {
          "chainId": 1,
          "address": "0x8eb94a06b4716093dbfe335cbdb098deb2dcde1b",
          "name": "Half Shiba Inu",
          "symbol": "SHIB05",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28957/thumb/halfshib.jpg?1675737731"
        },
        {
          "chainId": 1,
          "address": "0x149d8290f653deb8e34c037d239d3d8eee9de5ad",
          "name": "Kingdomverse",
          "symbol": "KING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28607/thumb/_King_logo_%28Color%29.png?1673597477"
        },
        {
          "chainId": 1,
          "address": "0x36feca97a4e81f4e1260119fae0c572a98832285",
          "name": "Chainswitch",
          "symbol": "SWITCH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30778/thumb/chainswitch_loho.png?1687167252"
        },
        {
          "chainId": 1,
          "address": "0x87a92428bbc876d463c21c8e51b903f127d9a9f4",
          "name": "Advanced United Continent",
          "symbol": "AUC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24369/thumb/200x200.png?1647441606"
        },
        {
          "chainId": 1,
          "address": "0xb794ad95317f75c44090f64955954c3849315ffe",
          "name": "Ribbit Meme",
          "symbol": "RIBBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30145/thumb/ribbit-logo.png?1683433609"
        },
        {
          "chainId": 1,
          "address": "0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6",
          "name": "ICHI",
          "symbol": "ICHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13119/thumb/ICHI_%28Round%29.jpg?1614308761"
        },
        {
          "chainId": 1,
          "address": "0x2f9411088cef82fd9fb904eb8092f28eb485c8f6",
          "name": "Athens",
          "symbol": "ATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24514/thumb/ATH-logo-round.png?1647951491"
        },
        {
          "chainId": 1,
          "address": "0xc57d533c50bc22247d49a368880fb49a1caa39f7",
          "name": "PowerTrade Fuel",
          "symbol": "PTF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12590/thumb/powertrade_logo.jpg?1600944549"
        },
        {
          "chainId": 1,
          "address": "0x5ee84583f67d5ecea5420dbb42b462896e7f8d06",
          "name": "PulseBitcoin",
          "symbol": "PLSB",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/28690/thumb/%E2%82%BF.png?1673404685"
        },
        {
          "chainId": 1,
          "address": "0xf552b656022c218c26dad43ad88881fc04116f76",
          "name": "MORK",
          "symbol": "MORK",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/12183/thumb/mork-logo.png?1597941710"
        },
        {
          "chainId": 1,
          "address": "0x5d64d850c8368008afb39224e92ad0dceff3cf38",
          "name": "MINDOL",
          "symbol": "MIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6366/thumb/mindol-logo.png?1547042468"
        },
        {
          "chainId": 1,
          "address": "0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd",
          "name": "DODO",
          "symbol": "DODO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png?1601433025"
        },
        {
          "chainId": 1,
          "address": "0xb17c88bda07d28b3838e0c1de6a30eafbcf52d85",
          "name": "Shyft Network",
          "symbol": "SHFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14534/thumb/TOKEN-Gradient.png?1616768352"
        },
        {
          "chainId": 1,
          "address": "0x27702a26126e0b3702af63ee09ac4d1a084ef628",
          "name": "Aleph im",
          "symbol": "ALEPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11676/thumb/Aleph-Logo-BW.png?1683257123"
        },
        {
          "chainId": 1,
          "address": "0xf65b5c5104c4fafd4b709d9d60a185eae063276c",
          "name": "Truebit Protocol",
          "symbol": "TRU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15053/thumb/Truebit.png?1623296246"
        },
        {
          "chainId": 1,
          "address": "0x0352557b007a4aae1511c114409b932f06f9e2f4",
          "name": "sRUNE",
          "symbol": "SRUNE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14953/thumb/sRUNE.png?1619129376"
        },
        {
          "chainId": 1,
          "address": "0xe5597f0723eeaba1b26948e06f008bf0fc1e37e6",
          "name": "GM",
          "symbol": "GM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20066/thumb/PQ29R4D.png?1636768949"
        },
        {
          "chainId": 1,
          "address": "0xbee709aa42375ace642338f7f657ff859a19c9bc",
          "name": "Muverse Token",
          "symbol": "MCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29747/thumb/20230407_171144.jpg?1681109593"
        },
        {
          "chainId": 1,
          "address": "0x946551dd05c5abd7cc808927480225ce36d8c475",
          "name": "One",
          "symbol": "ONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png?1561103318"
        },
        {
          "chainId": 1,
          "address": "0xba745513acebcbb977497c569d4f7d340f2a936b",
          "name": "Mainstream For The Underground",
          "symbol": "MFTU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5519/thumb/Mainstream_for_the_underground.png?1534426154"
        },
        {
          "chainId": 1,
          "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
          "name": "Aave",
          "symbol": "AAVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110"
        },
        {
          "chainId": 1,
          "address": "0xedf6568618a00c6f0908bf7758a16f76b6e04af9",
          "name": "Arianee",
          "symbol": "ARIA20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5054/thumb/Aria_Logo_256.png?1610097866"
        },
        {
          "chainId": 1,
          "address": "0x56de8bc61346321d4f2211e3ac3c0a7f00db9b76",
          "name": "RENA Finance",
          "symbol": "RENA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15667/thumb/photo_2021-05-21_17-31-35.png?1621825861"
        },
        {
          "chainId": 1,
          "address": "0xb19189fb36c816f3e0f16065057b07b790998fdc",
          "name": "Serum SER",
          "symbol": "SER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27757/thumb/Untitled_design_%2810%29.png?1665824038"
        },
        {
          "chainId": 1,
          "address": "0x547b2f82cecfab9c2b1d36fdda96ef9f58c63b8c",
          "name": "Taxa Network",
          "symbol": "TXT",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41",
          "name": "Autobahn Network",
          "symbol": "TXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12432/thumb/txl.png?1646463631"
        },
        {
          "chainId": 1,
          "address": "0x831091da075665168e01898c6dac004a867f1e1b",
          "name": "Gains Farm",
          "symbol": "GFARM2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13703/thumb/gfarm_v2.png?1611035398"
        },
        {
          "chainId": 1,
          "address": "0x92e52a1a235d9a103d970901066ce910aacefd37",
          "name": "U CASH",
          "symbol": "UCASH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2901/thumb/ucash.png?1547037074"
        },
        {
          "chainId": 1,
          "address": "0xc8d3dcb63c38607cb0c9d3f55e8ecce628a01c36",
          "name": "Matrix Labs",
          "symbol": "MATRIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18297/thumb/matrixlabs.png?1643277367"
        },
        {
          "chainId": 1,
          "address": "0xcd17fa52528f37facb3028688e62ec82d9417581",
          "name": "Materium",
          "symbol": "MTRM",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/24613/thumb/mtrm.png?1648373908"
        },
        {
          "chainId": 1,
          "address": "0x8d6482b757f29e7b06440ddeebcceed3af49b4ce",
          "name": "Rocket Raccoon Token",
          "symbol": "ROCKET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30482/thumb/Rocket_Raccoon.png?1684709886"
        },
        {
          "chainId": 1,
          "address": "0xb63b606ac810a52cca15e44bb630fd42d8d1d83d",
          "name": "MCO",
          "symbol": "MCO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/739/thumb/1776.png?1648537078"
        },
        {
          "chainId": 1,
          "address": "0xf9c53268e9de692ae1b2ea5216e24e1c3ad7cb1e",
          "name": "Idexo",
          "symbol": "IDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18523/thumb/qOiqm7T8_400x400.jpg?1632275628"
        },
        {
          "chainId": 1,
          "address": "0x69fa0fee221ad11012bab0fdb45d444d3d2ce71c",
          "name": "Thorstarter",
          "symbol": "XRUNE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16835/thumb/thorstarter.jpg?1625279004"
        },
        {
          "chainId": 1,
          "address": "0xbdab72602e9ad40fc6a6852caf43258113b8f7a5",
          "name": "Sovryn",
          "symbol": "SOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16248/thumb/sov.PNG?1623380748"
        },
        {
          "chainId": 1,
          "address": "0x4bcea5e4d0f6ed53cf45e7a28febb2d3621d7438",
          "name": "Modex",
          "symbol": "MODEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2851/thumb/LhWIeAg.png?1602566568"
        },
        {
          "chainId": 1,
          "address": "0x5d30ad9c6374bf925d0a75454fa327aacf778492",
          "name": "PERI Finance",
          "symbol": "PERI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905"
        },
        {
          "chainId": 1,
          "address": "0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81",
          "name": "Muse DAO",
          "symbol": "MUSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13230/thumb/muse_logo.png?1606460453"
        },
        {
          "chainId": 1,
          "address": "0x3d371413dd5489f3a04c07c0c2ce369c20986ceb",
          "name": "YOUcash",
          "symbol": "YOUC",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/11152/thumb/round-400x400.png?1589162715"
        },
        {
          "chainId": 1,
          "address": "0x5b985b4f827072febe33091b42729522b557bba1",
          "name": "T",
          "symbol": "T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28691/thumb/T.png?1673405024"
        },
        {
          "chainId": 1,
          "address": "0x443b29fc978058abe3fc2f4c3c6b76c57fdecc02",
          "name": "IDEAS",
          "symbol": "IDEAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21535/thumb/IDEAS_logo_200.png?1675166407"
        },
        {
          "chainId": 1,
          "address": "0x00fc270c9cc13e878ab5363d00354bebf6f05c15",
          "name": "VNX Exchange",
          "symbol": "VNXLU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9945/thumb/vnx.PNG?1573639467"
        },
        {
          "chainId": 1,
          "address": "0x269616d549d7e8eaa82dfb17028d0b212d11232a",
          "name": "Punk Vault  NFTX ",
          "symbol": "PUNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17018/thumb/Punk.png?1626086346"
        },
        {
          "chainId": 1,
          "address": "0x7d8d7c26179b7a6aebbf66a91c38ed92d5b4996b",
          "name": "Teh Fund",
          "symbol": "FUND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28969/thumb/IMG_20230203_091543_056.png?1675747757"
        },
        {
          "chainId": 1,
          "address": "0xae6e307c3fe9e922e5674dbd7f830ed49c014c6b",
          "name": "Credefi",
          "symbol": "CREDI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg?1639042835"
        },
        {
          "chainId": 1,
          "address": "0x44709a920fccf795fbc57baa433cc3dd53c44dbe",
          "name": "DappRadar",
          "symbol": "RADAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20894/thumb/radar.png?1640306268"
        },
        {
          "chainId": 1,
          "address": "0x06b884e60794ce02aafab13791b59a2e6a07442f",
          "name": "Unbanked",
          "symbol": "UNBNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20171/thumb/logo_-_2021-11-11T115427.135.png?1636602880"
        },
        {
          "chainId": 1,
          "address": "0x4a1d542b52a95ad01ddc70c2e7df0c7bbaadc56f",
          "name": "Niftify",
          "symbol": "NIFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20558/thumb/AWPDLxLK_400x400.jpg?1637209747"
        },
        {
          "chainId": 1,
          "address": "0x0ff5a8451a839f5f0bb3562689d9a44089738d11",
          "name": "Dopex Rebate",
          "symbol": "RDPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16659/thumb/rDPX_200x200_Coingecko.png?1624614475"
        },
        {
          "chainId": 1,
          "address": "0xf2cdf38e24738ba379ffa38d47bc88a941df5627",
          "name": "Ally",
          "symbol": "ALY",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/9619/thumb/BTf_sTXi_400x400.jpg?1569854715"
        },
        {
          "chainId": 1,
          "address": "0x7884f51dc1410387371ce61747cb6264e1daee0b",
          "name": "Binance Wrapped DOT",
          "symbol": "BDOT",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/23268/thumb/binance-dot_32.png?1643414783"
        },
        {
          "chainId": 1,
          "address": "0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9",
          "name": "KOMPETE Token",
          "symbol": "KOMPETE",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/24298/thumb/KOMPETE_LOGO.?1669463130"
        },
        {
          "chainId": 1,
          "address": "0xc3ffbe26e9446ac52008ffc8c1191d60a12bdc48",
          "name": "Medifakt",
          "symbol": "FAKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25248/thumb/fxOi9ZYI_400x400.png?1651031310"
        },
        {
          "chainId": 1,
          "address": "0x33e07f5055173cf8febede8b21b12d1e2b523205",
          "name": "Etherland",
          "symbol": "ELAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14432/thumb/eland_logo.png?1678003898"
        },
        {
          "chainId": 1,
          "address": "0x5833dbb0749887174b254ba4a5df747ff523a905",
          "name": "XRun",
          "symbol": "XRUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25272/thumb/19787.png?1651118626"
        },
        {
          "chainId": 1,
          "address": "0x58fad9e3c3ae54c9ba98c3f0e4bf88ab3e8cf3c5",
          "name": "SpaceY 2025",
          "symbol": "SPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG?1637132488"
        },
        {
          "chainId": 1,
          "address": "0xef8e456967122db4c3c160314bde8d2602ad6199",
          "name": "Wagmi Coin",
          "symbol": "WAGMI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30477/thumb/W.png?1684707717"
        },
        {
          "chainId": 1,
          "address": "0x29c56e7cb9c840d2b2371b17e28bab44ad3c3ead",
          "name": "EsportsPro",
          "symbol": "ESPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245"
        },
        {
          "chainId": 1,
          "address": "0x1f1f26c966f483997728bed0f9814938b2b5e294",
          "name": "Meowl",
          "symbol": "MEOWL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31142/thumb/logo_200_200.png?1690967943"
        },
        {
          "chainId": 1,
          "address": "0x2da719db753dfa10a62e140f436e1d67f2ddb0d6",
          "name": "Cere Network",
          "symbol": "CERE",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/20008/thumb/cere.png?1636366576"
        },
        {
          "chainId": 1,
          "address": "0x47140a767a861f7a1f3b0dd22a2f463421c28814",
          "name": "All me",
          "symbol": "ME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5497/thumb/all.me-logo.jpg?1547041239"
        },
        {
          "chainId": 1,
          "address": "0x5d0ebc4ec5ac18d30512fb6287886245061b3dbd",
          "name": "Gatsby Inu",
          "symbol": "GATSBY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30578/thumb/gatsby.jpeg?1685355153"
        },
        {
          "chainId": 1,
          "address": "0x24022539963319b0d6ca92797cfdc7cdf59c6930",
          "name": "Gold AI Network Token",
          "symbol": "GAIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30456/thumb/circle_200_%281%29.png?1685956356"
        },
        {
          "chainId": 1,
          "address": "0x9dc7094530cb1bcf5442c3b9389ee386738a190c",
          "name": "Morpho Aave Curve DAO Token",
          "symbol": "MACRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29874/thumb/maCRV.jpg?1681894570"
        },
        {
          "chainId": 1,
          "address": "0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037",
          "name": "Sentinel  OLD ",
          "symbol": "DVPN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/3625/thumb/Sentinel_512X512.png?1622174555"
        },
        {
          "chainId": 1,
          "address": "0x685fb6960ea7f7b24f02e17c8d893b8c33239d48",
          "name": "Saiko   The Revival",
          "symbol": "SAIKO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28932/thumb/200x200.png?1675499987"
        },
        {
          "chainId": 1,
          "address": "0x27f103f86070cc639fef262787a16887d22d8415",
          "name": "FOFO Token",
          "symbol": "FOFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30438/thumb/01_%281%29_copy.png?1684471585"
        },
        {
          "chainId": 1,
          "address": "0xad038eb671c44b853887a7e32528fab35dc5d710",
          "name": "DOLA Borrowing Right",
          "symbol": "DBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28530/thumb/DBR.png?1671437631"
        },
        {
          "chainId": 1,
          "address": "0xe0e05c43c097b0982db6c9d626c4eb9e95c3b9ce",
          "name": "Unslashed Finance",
          "symbol": "USF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14177/thumb/Unslashed.jpeg?1614793365"
        },
        {
          "chainId": 1,
          "address": "0x9c405acf8688afb61b3197421cdeec1a266c6839",
          "name": "DogeYield",
          "symbol": "DOGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13734/thumb/dogy200px.png?1611266985"
        },
        {
          "chainId": 1,
          "address": "0x53250b5dfa8c911547afeaf18db025024c8e919a",
          "name": "Kermit",
          "symbol": "KERMIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29858/thumb/Kermit.png?1681867984"
        },
        {
          "chainId": 1,
          "address": "0xd87de4ccef2c2fe651bc4d130cb1a365248f21fa",
          "name": "Lyfe",
          "symbol": "LYFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28608/thumb/lyfe-32.png?1672388850"
        },
        {
          "chainId": 1,
          "address": "0x341c05c0e9b33c0e38d64de76516b2ce970bb3be",
          "name": "Diversified Staked ETH",
          "symbol": "DSETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28751/thumb/dsETH-logo.png?1673929867"
        },
        {
          "chainId": 1,
          "address": "0xd85a6ae55a7f33b0ee113c234d2ee308edeaf7fd",
          "name": "Cobak",
          "symbol": "CBK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13459/thumb/cbk-128-128.png?1608760936"
        },
        {
          "chainId": 1,
          "address": "0x8f9b4525681f3ea6e43b8e0a57bfff86c0a1dd2e",
          "name": "Zeebu",
          "symbol": "ZBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31145/thumb/200x200_Front_token.png?1691028767"
        },
        {
          "chainId": 1,
          "address": "0xae36155a55f04a696b8362777620027882b31db5",
          "name": "Kishimoto",
          "symbol": "KISHIMOTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28011/thumb/kishimoto.png?1666944015"
        },
        {
          "chainId": 1,
          "address": "0xf6c2a37acc8ba45874808837486110dc0afc63c8",
          "name": "MetaX",
          "symbol": "X1",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/27411/thumb/pNi9ej83_400x400.png?1663853065"
        },
        {
          "chainId": 1,
          "address": "0x40d1f63b5d2048e67e9bedb1b4c2f1a9fb4b6817",
          "name": "Golden Goose",
          "symbol": "GOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7892/thumb/tsRcntSR_400x400.png?1611024068"
        },
        {
          "chainId": 1,
          "address": "0x850aab69f0e0171a9a49db8be3e71351c8247df4",
          "name": "Konomi Network",
          "symbol": "KONO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14406/thumb/konomi.jpg?1615907763"
        },
        {
          "chainId": 1,
          "address": "0x024b6e7dc26f4d5579bdd936f8d7bc31f2339999",
          "name": "Mithril Share",
          "symbol": "MIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13516/thumb/MIS.png?1609306996"
        },
        {
          "chainId": 1,
          "address": "0xf8c3527cc04340b208c854e985240c02f7b7793f",
          "name": "Frontier",
          "symbol": "FRONT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png?1600145472"
        },
        {
          "chainId": 1,
          "address": "0x9994e35db50125e0df82e4c2dde62496ce330999",
          "name": "Morpho",
          "symbol": "MORPHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29837/thumb/2022-official-morpho-token.png?1681718566"
        },
        {
          "chainId": 1,
          "address": "0x075b1bb99792c9e1041ba13afef80c91a1e70fb3",
          "name": "Curve fi renBTC wBTC sBTC",
          "symbol": "CRVRENWSBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11958/thumb/Curvefi_sbtcCrv_32.png?1596436054"
        },
        {
          "chainId": 1,
          "address": "0x5cac718a3ae330d361e39244bf9e67ab17514ce8",
          "name": "Cosplay Token",
          "symbol": "COT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21294/thumb/brave_ZxsjzUc8xn.png?1638874098"
        },
        {
          "chainId": 1,
          "address": "0x7f0c8b125040f707441cad9e5ed8a8408673b455",
          "name": "CSP DAO Network",
          "symbol": "NEBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13436/thumb/csp_dao.png?1608535699"
        },
        {
          "chainId": 1,
          "address": "0x6ef460eb3563cfcc73f8147b0a77daffee71f867",
          "name": "Zeus AI",
          "symbol": "ZEUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29593/thumb/ZEUS.png?1679887650"
        },
        {
          "chainId": 1,
          "address": "0x2dca19e944453e46d9130950ca135461b3bc0c30",
          "name": "EYES Protocol",
          "symbol": "EYES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9734/thumb/pTaAZUI6_400x400.jpg?1571308667"
        },
        {
          "chainId": 1,
          "address": "0xa13f0743951b4f6e3e3aa039f682e17279f52bc3",
          "name": "Sentinel Chain",
          "symbol": "SENC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2960/thumb/sentinel-chain.jpg?1547037201"
        },
        {
          "chainId": 1,
          "address": "0x1788430620960f9a70e3dc14202a3a35dde1a316",
          "name": "OpenAlexa Protocol",
          "symbol": "OAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12400/thumb/256x256-OAP.png?1599556701"
        },
        {
          "chainId": 1,
          "address": "0xd24946147829deaa935be2ad85a3291dbf109c80",
          "name": "Aave AMM USDC",
          "symbol": "AAMMUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17226/thumb/aAMMUSDC_2x.png?1626939994"
        },
        {
          "chainId": 1,
          "address": "0x116c4b65e14449947bc6fa1bbe844cb16a162d53",
          "name": "BMAX",
          "symbol": "BMAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26427/thumb/BMAX_logo.png?1657929623"
        },
        {
          "chainId": 1,
          "address": "0xa49811140e1d6f653dec28037be0924c811c4538",
          "name": "PR F Protocol",
          "symbol": "PRUF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27482/thumb/prufu-200x200_1.png?1664246561"
        },
        {
          "chainId": 1,
          "address": "0x6769d86f9c430f5ac6d9c861a0173613f1c5544c",
          "name": "KoaCombat",
          "symbol": "KOACOMBAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23827/thumb/jGYAi2LB_400x400.jpg?1645515278"
        },
        {
          "chainId": 1,
          "address": "0x8790f2fc7ca2e7db841307fb3f4e72a03baf7b47",
          "name": "Spillways",
          "symbol": "SPILLWAYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28358/thumb/1669791775144_%281%29.png?1669794825"
        },
        {
          "chainId": 1,
          "address": "0x9d91be44c06d373a8a226e1f3b146956083803eb",
          "name": "Aave KNC v1",
          "symbol": "AKNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11727/thumb/aKNC.png?1593083924"
        },
        {
          "chainId": 1,
          "address": "0x2a69655c22eda32ff48d315bb26ed45f150700b4",
          "name": "StakerDAO Wrapped Tezos",
          "symbol": "WXTZ",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14763/thumb/wXTZ-token-FullColor.png?1618281765"
        },
        {
          "chainId": 1,
          "address": "0xdfbc9050f5b01df53512dcc39b4f2b2bbacd517a",
          "name": "Jobchain",
          "symbol": "JOB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9352/thumb/Logo_%283%29.png?1566510871"
        },
        {
          "chainId": 1,
          "address": "0x6b785a0322126826d8226d77e173d75dafb84d11",
          "name": "Bankroll Vault",
          "symbol": "VLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11638/thumb/vlt-200.png?1592272725"
        },
        {
          "chainId": 1,
          "address": "0xba6b0dbb2ba8daa8f5d6817946393aef8d3a4487",
          "name": "Hillstone Finance",
          "symbol": "HSF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png?1641519883"
        },
        {
          "chainId": 1,
          "address": "0x221657776846890989a759ba2973e427dff5c9bb",
          "name": "Augur",
          "symbol": "REP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/309/thumb/REP.png?1596339859"
        },
        {
          "chainId": 1,
          "address": "0x1f16d41f9b3db03b462bdd6c92245ee708d1c103",
          "name": "Rangers Protocol",
          "symbol": "RPG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18791/thumb/tO8MlqiM_400x400.png?1633421196"
        },
        {
          "chainId": 1,
          "address": "0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd",
          "name": "WAXE",
          "symbol": "WAXE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13508/thumb/waxe_logo.png?1609232755"
        },
        {
          "chainId": 1,
          "address": "0x6afcff9189e8ed3fcc1cffa184feb1276f6a82a5",
          "name": "PolkaPet World",
          "symbol": "PETS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19409/thumb/pets_polka.PNG?1635211453"
        },
        {
          "chainId": 1,
          "address": "0xb56a1f3310578f23120182fb2e58c087efe6e147",
          "name": "All Coins Yield Capital",
          "symbol": "ACYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21109/thumb/acyc.png?1639990309"
        },
        {
          "chainId": 1,
          "address": "0x260e59a6e96c26cfefd9dec566a4d4e50031744d",
          "name": "OHearn",
          "symbol": "MIKE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/30737/thumb/mikkekekekeke.png?1686722424"
        },
        {
          "chainId": 1,
          "address": "0x3c79eb9d7a76f4eb7b2a5286aacfbbd4feabe79a",
          "name": "DogTick",
          "symbol": "DOGTIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30029/thumb/logodogtick2_%281%29.png?1682585019"
        },
        {
          "chainId": 1,
          "address": "0xb087c2180e3134db396977065817aed91fea6ead",
          "name": "Hellsing Inu",
          "symbol": "HELLSING",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19795/thumb/hellsing.png?1638182960"
        },
        {
          "chainId": 1,
          "address": "0x34bdf48a8f753de4822a6cfb1fee275f9b4d662e",
          "name": "FACTS",
          "symbol": "BKC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8461/thumb/sFNOkmVx_400x400.jpg?1558690624"
        },
        {
          "chainId": 1,
          "address": "0xf91ac30e9b517f6d57e99446ee44894e6c22c032",
          "name": "LOL",
          "symbol": "LOL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20354/thumb/lollogo.png?1648442855"
        },
        {
          "chainId": 1,
          "address": "0x53fd2342b43ecd24aef1535bc3797f509616ce8c",
          "name": "Anarchy",
          "symbol": "ANARCHY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26583/thumb/90EWu8yp_400x400.jpg?1658891996"
        },
        {
          "chainId": 1,
          "address": "0x3b9e094d56103611f0acefdab43182347ba60df4",
          "name": "PANTHEON X",
          "symbol": "XPN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8297/thumb/Hr9wHItp_400x400.jpg?1557307478"
        },
        {
          "chainId": 1,
          "address": "0xb045f7f363fe4949954811b113bd56d208c67b23",
          "name": "Spider Tanks",
          "symbol": "SILK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28057/thumb/SILK_Logo.png?1667295996"
        },
        {
          "chainId": 1,
          "address": "0x94501b6a153c8973fd1f321fcc8188d40dc5d72d",
          "name": "DigiDinar Token",
          "symbol": "DDRT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10415/thumb/8WbpGRw.png?1579128643"
        },
        {
          "chainId": 1,
          "address": "0x5aa7c403c7de4b3bb0cc07079a03e389671a4771",
          "name": "Ibiza Token",
          "symbol": "IBZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19349/thumb/AZHnAHiY_400x400.jpg?1635127261"
        },
        {
          "chainId": 1,
          "address": "0x46be62d1fc217063c1bf1f35d788690cec7a60ca",
          "name": "Retsuko",
          "symbol": "SUKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28551/thumb/logo_200.png?1671690892"
        },
        {
          "chainId": 1,
          "address": "0x5dfe42eea70a3e6f93ee54ed9c321af07a85535c",
          "name": "Union Finance",
          "symbol": "UNION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30556/thumb/Mark.png?1685013645"
        },
        {
          "chainId": 1,
          "address": "0x74b988156925937bd4e082f0ed7429da8eaea8db",
          "name": "Meme Inu",
          "symbol": "MEME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20755/thumb/photo_2021-11-20_13-39-45.jpg?1637638928"
        },
        {
          "chainId": 1,
          "address": "0xe115401b985306837b9f1495c8e9d7f8f8328cc3",
          "name": "Claw  OLD ",
          "symbol": "CLAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29475/thumb/CLAW.png?1679129955"
        },
        {
          "chainId": 1,
          "address": "0x05fe069626543842439ef90d9fa1633640c50cf1",
          "name": "Eve AI",
          "symbol": "EVEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29830/thumb/200-X-200.png?1681714843"
        },
        {
          "chainId": 1,
          "address": "0x77252494c25444f8598a0c74ffc90adc535291a9",
          "name": "Nemesis",
          "symbol": "NMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18904/thumb/NMS-150x150.jpg?1633849465"
        },
        {
          "chainId": 1,
          "address": "0xe0ad1806fd3e7edf6ff52fdb822432e847411033",
          "name": "OnX Finance",
          "symbol": "ONX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13445/thumb/onxlogo-1.png?1608629659"
        },
        {
          "chainId": 1,
          "address": "0x88303fed02b31db9c7a9eafb711da9ef4a03e5d3",
          "name": "Ziktalk",
          "symbol": "ZIK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8991/thumb/ziktalk.logo.jpeg?1650437845"
        },
        {
          "chainId": 1,
          "address": "0x4a8f5f96d5436e43112c2fbc6a9f70da9e4e16d4",
          "name": "Internxt",
          "symbol": "INXT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/986/thumb/inxt.png?1639651885"
        },
        {
          "chainId": 1,
          "address": "0x86772b1409b61c639eaac9ba0acfbb6e238e5f83",
          "name": "Indexed Finance",
          "symbol": "NDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13546/thumb/indexed-light.74bb5471.png?1609712728"
        },
        {
          "chainId": 1,
          "address": "0x43d4a3cd90ddd2f8f4f693170c9c8098163502ad",
          "name": "Prime",
          "symbol": "D2D",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21609/thumb/RJD82RrV_400x400.jpg?1639559164"
        },
        {
          "chainId": 1,
          "address": "0xffffffff2ba8f66d4e51811c5190992176930278",
          "name": "Furucombo",
          "symbol": "COMBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13629/thumb/COMBO_token_ol.png?1610701537"
        },
        {
          "chainId": 1,
          "address": "0xd076c4ba62c57b3fa10800bcfd8da66742110e0e",
          "name": "HAVAH",
          "symbol": "HVH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29156/thumb/hvh.png?1677029638"
        },
        {
          "chainId": 1,
          "address": "0x4d2ee5dae46c86da2ff521f7657dad98834f97b8",
          "name": "Pepemon Pepeballs",
          "symbol": "PPBLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12823/thumb/200pepebball-BIG.png?1603330304"
        },
        {
          "chainId": 1,
          "address": "0xc961da88bb5e8ee2ba7dfd4c62a875ef80f7202f",
          "name": "Haram",
          "symbol": "HARAM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30374/thumb/200x200trans.png?1684296931"
        },
        {
          "chainId": 1,
          "address": "0x26cb3641aaa43911f1d4cb2ce544eb652aac7c47",
          "name": "Crystal CYL",
          "symbol": "CYL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6419/thumb/crystal-token.png?1547042585"
        },
        {
          "chainId": 1,
          "address": "0xd1cd47746b8e72359b28c1c84a4f6a19dc1a0ee5",
          "name": "Sonic Suite",
          "symbol": "SONIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28489/thumb/155EE8AF-26AA-4CEC-9EC5-18532E779474.jpeg?1671091323"
        },
        {
          "chainId": 1,
          "address": "0x0f7b3f5a8fed821c5eb60049538a548db2d479ce",
          "name": "AirTor Protocol",
          "symbol": "ATOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29690/thumb/AirTor.png?1680669794"
        },
        {
          "chainId": 1,
          "address": "0xb8b295df2cd735b15be5eb419517aa626fc43cd5",
          "name": "Staked LINK",
          "symbol": "STLINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29271/thumb/ST.LINK_tokenl_blue.png?1677733935"
        },
        {
          "chainId": 1,
          "address": "0x65def5029a0e7591e46b38742bfedd1fb7b24436",
          "name": "Kanpeki",
          "symbol": "KAE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18833/thumb/kanpeki-logo-square_2.png?1633628384"
        },
        {
          "chainId": 1,
          "address": "0x171d76d931529384639bc9aad5b77b77041ed604",
          "name": "MetaOctagon",
          "symbol": "MOTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26496/thumb/iJoylNuO_400x400.jpeg?1658304109"
        },
        {
          "chainId": 1,
          "address": "0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a",
          "name": "Basis Cash",
          "symbol": "BAC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13246/thumb/BAC.png?1613231642"
        },
        {
          "chainId": 1,
          "address": "0x63f88a2298a5c4aee3c216aa6d926b184a4b2437",
          "name": "GameCredits",
          "symbol": "GAME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/193/thumb/XlQmXoU.png?1595304945"
        },
        {
          "chainId": 1,
          "address": "0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8",
          "name": "UniX",
          "symbol": "UNIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20674/thumb/unix.png?1663499268"
        },
        {
          "chainId": 1,
          "address": "0xeb7355c2f217b3485a591332fe13c8c5a76a581d",
          "name": "Jubi Token",
          "symbol": "JT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10994/thumb/Af5MFcVY_400x400.jpg?1586998222"
        },
        {
          "chainId": 1,
          "address": "0xac0c8da4a4748d8d821a0973d00b157aa78c473d",
          "name": "YFIONE",
          "symbol": "YFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13670/thumb/256.png?1610676054"
        },
        {
          "chainId": 1,
          "address": "0x9a7703338730b82a803ba050df55f9b3959f3fb2",
          "name": "Arise Chikun",
          "symbol": "CHIKUN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31043/thumb/WJyGMMxM_400x400.jpg?1689857335"
        },
        {
          "chainId": 1,
          "address": "0xbea0000029ad1c77d3d5d23ba2d8893db9d1efab",
          "name": "Bean",
          "symbol": "BEAN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/18447/thumb/bean-logo-coingecko.png?1632090495"
        },
        {
          "chainId": 1,
          "address": "0x103c3a209da59d3e7c4a89307e66521e081cfdf0",
          "name": "Genesis Vision",
          "symbol": "GVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1173/thumb/Genesis-vision.png?1558045005"
        },
        {
          "chainId": 1,
          "address": "0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1",
          "name": "Aave BAT",
          "symbol": "ABAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14246/thumb/aBAT.1fe436f7_-_Copy.png?1615528663"
        },
        {
          "chainId": 1,
          "address": "0xc7ff1e126cc81e816915ff48c940ed9d4e6d05d6",
          "name": "IjasCoin",
          "symbol": "IJC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14099/thumb/Ijascode-Flat-Logo-2-24-2021.png?1614304358"
        },
        {
          "chainId": 1,
          "address": "0xb1c064c3f2908f741c9dea4afc5773238b53e6cc",
          "name": "WarioXRPDumbledoreYugioh69Inu",
          "symbol": "XRP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31167/thumb/ETYYcV7I_400x400.jpg?1691058740"
        },
        {
          "chainId": 1,
          "address": "0x189fc141854bfc6146777406d53fbc89f4e70407",
          "name": "Ceji",
          "symbol": "CEJI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26458/thumb/CEJI.png?1658129614"
        },
        {
          "chainId": 1,
          "address": "0xa9f9acb92e4e2f16410511d56839a5bd1d630a60",
          "name": "BLOCK E",
          "symbol": "BLOCK-E",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26905/thumb/photo_2022-09-07_10.17.08.jpeg?1664065816"
        },
        {
          "chainId": 1,
          "address": "0x6c936d4ae98e6d2172db18c16c4b601c99918ee6",
          "name": "Life Crypto",
          "symbol": "LIFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18245/thumb/communityIcon_t3kzc5skazh81.png?1666058038"
        },
        {
          "chainId": 1,
          "address": "0x73d7c860998ca3c01ce8c808f5577d94d545d1b4",
          "name": "IX Swap",
          "symbol": "IXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18069/thumb/ixswap.PNG?1630375152"
        },
        {
          "chainId": 1,
          "address": "0x3d0293f06daf4311b482564330d57c8db6c10893",
          "name": "Y Coin",
          "symbol": "YCO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14128/thumb/BIG-POTAT-200x200-removebg-preview.png?1614576159"
        },
        {
          "chainId": 1,
          "address": "0xbcca60bb61934080951369a648fb03df4f96263c",
          "name": "Aave USDC",
          "symbol": "AUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797"
        },
        {
          "chainId": 1,
          "address": "0xd38e031f4529a07996aab977d2b79f0e00656c56",
          "name": "wTBT",
          "symbol": "WTBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29772/thumb/WTBT.png?1681265480"
        },
        {
          "chainId": 1,
          "address": "0x3abf2a4f8452ccc2cf7b4c1e4663147600646f66",
          "name": "Juicebox",
          "symbol": "JBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21120/thumb/CCeIEmvE_400x400.jpg?1638341224"
        },
        {
          "chainId": 1,
          "address": "0x0198f46f520f33cd4329bd4be380a25a90536cd5",
          "name": "PlayChip",
          "symbol": "PLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4567/thumb/pla.png?1547039851"
        },
        {
          "chainId": 1,
          "address": "0x049e9f5369358786a1ce6483d668d062cfe547ec",
          "name": "Checks Token",
          "symbol": "CHECKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30367/thumb/checkstoken-200x200.png?1684289834"
        },
        {
          "chainId": 1,
          "address": "0x2822f6d1b2f41f93f33d937bc7d84a8dfa4f4c21",
          "name": "Poseidon Network",
          "symbol": "QQQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8442/thumb/LNBLzKy2_400x400.jpg?1558562627"
        },
        {
          "chainId": 1,
          "address": "0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6",
          "name": "QASH",
          "symbol": "QASH",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1220/thumb/qash.jpeg?1547035247"
        },
        {
          "chainId": 1,
          "address": "0x39c6b3e42d6a679d7d776778fe880bc9487c2eda",
          "name": "Aave KNC",
          "symbol": "AKNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14250/thumb/aKNC.42bcd2e3.png?1615528613"
        },
        {
          "chainId": 1,
          "address": "0x054f76beed60ab6dbeb23502178c52d6c5debe40",
          "name": "DeFiner",
          "symbol": "FIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12780/thumb/PdaW8Lb.png?1602500407"
        },
        {
          "chainId": 1,
          "address": "0x8ed97a637a790be1feff5e888d43629dc05408f6",
          "name": "Non Playable Coin",
          "symbol": "NPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31193/thumb/NPC_200x200.png?1691146346"
        },
        {
          "chainId": 1,
          "address": "0xc28e27870558cf22add83540d2126da2e4b464c2",
          "name": "Sashimi",
          "symbol": "SASHIMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12427/thumb/SashimiSwap-200x200.png?1601347413"
        },
        {
          "chainId": 1,
          "address": "0x5efcea234f7547de4569aad1215fa5d2adaced38",
          "name": "Clown Pepe",
          "symbol": "HONK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29890/thumb/Honk.png?1681981583"
        },
        {
          "chainId": 1,
          "address": "0x31429d1856ad1377a8a0079410b297e1a9e214c2",
          "name": "ANGLE",
          "symbol": "ANGLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19060/thumb/ANGLE_Token-light.png?1666774221"
        },
        {
          "chainId": 1,
          "address": "0x5a7e6c8204a1359db9aacab7ba5fc309b7981efd",
          "name": "Offshift anonUSD",
          "symbol": "ANONUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29254/thumb/anonUSD_200x200.png?1677646616"
        },
        {
          "chainId": 1,
          "address": "0xc28e931814725bbeb9e670676fabbcb694fe7df2",
          "name": "Quadrant Protocol",
          "symbol": "EQUAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4462/thumb/equad.png?1547039783"
        },
        {
          "chainId": 1,
          "address": "0xa393473d64d2f9f026b60b6df7859a689715d092",
          "name": "Lattice",
          "symbol": "LTX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13050/thumb/Lattice.jpg?1613976295"
        },
        {
          "chainId": 1,
          "address": "0x93b1e78a3e652cd2e71c4a767595b77282344932",
          "name": "BITO Coin",
          "symbol": "BITO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7796/thumb/bitopro_28.png?1550628495"
        },
        {
          "chainId": 1,
          "address": "0x0b452278223d3954f4ac050949d7998e373e7e43",
          "name": "Shita kiri Suzume",
          "symbol": "SUZUME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26813/thumb/shitakiri_cmc.png?1660275049"
        },
        {
          "chainId": 1,
          "address": "0xff770e4c68e35db85c6e0e89a43750ec02bdb2ac",
          "name": "Ordinal BTC",
          "symbol": "OBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29244/thumb/Ordinal_BTC.png?1678010144"
        },
        {
          "chainId": 1,
          "address": "0xc82e3db60a52cf7529253b4ec688f631aad9e7c2",
          "name": "Arc",
          "symbol": "ARC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24235/thumb/arc.png?1647694270"
        },
        {
          "chainId": 1,
          "address": "0x2596825a84888e8f24b747df29e11b5dd03c81d7",
          "name": "Faith Tribe",
          "symbol": "FTRB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23939/thumb/ym1Hf4x2_400x400.jpg?1645766940"
        },
        {
          "chainId": 1,
          "address": "0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1",
          "name": "PowerPool Concentrated Voting Power",
          "symbol": "CVP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12266/thumb/Powerpool.jpg?1598621373"
        },
        {
          "chainId": 1,
          "address": "0xf0c5831ec3da15f3696b4dad8b21c7ce2f007f28",
          "name": "AXIS",
          "symbol": "AXIS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/16972/thumb/lUeFYUeu_400x400.png?1625815058"
        },
        {
          "chainId": 1,
          "address": "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
          "name": "Coinbase Wrapped Staked ETH",
          "symbol": "CBETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27008/thumb/cbeth.png?1661390066"
        },
        {
          "chainId": 1,
          "address": "0xfc4b8ed459e00e5400be803a9bb3954234fd50e3",
          "name": "Aave WBTC v1",
          "symbol": "AWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11734/thumb/aWBTC.png?1593087741"
        },
        {
          "chainId": 1,
          "address": "0xceec4ae5cf5300ac9a49a816961af9393b214674",
          "name": "Insight AI",
          "symbol": "INSAI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29135/thumb/My_project-1.jpg?1676958886"
        },
        {
          "chainId": 1,
          "address": "0x5f474906637bdcda05f29c74653f6962bb0f8eda",
          "name": "DeFinity",
          "symbol": "DEFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png?1622414896"
        },
        {
          "chainId": 1,
          "address": "0xfd09911130e6930bf87f2b0554c44f400bd80d3e",
          "name": "Ethix",
          "symbol": "ETHIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3031/thumb/ETHIX_icon_256x256-256.png?1622195164"
        },
        {
          "chainId": 1,
          "address": "0x60be1e1fe41c1370adaf5d8e66f07cf1c2df2268",
          "name": "Perion",
          "symbol": "PERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23302/thumb/PERC.png?1674724325"
        },
        {
          "chainId": 1,
          "address": "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
          "name": "Lido Staked Ether",
          "symbol": "STETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13442/thumb/steth_logo.png?1608607546"
        },
        {
          "chainId": 1,
          "address": "0x1341a2257fa7b770420ef70616f888056f90926c",
          "name": "Zoo",
          "symbol": "ZOOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316"
        },
        {
          "chainId": 1,
          "address": "0xbe82bbd9c47e05f1fac183b106a768717588be73",
          "name": "Crypto Threads",
          "symbol": "CT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31042/thumb/CT.jpeg?1689857134"
        },
        {
          "chainId": 1,
          "address": "0x3f17f64f682019599ba51638f74e4b6c127fe725",
          "name": "ReviveEth",
          "symbol": "REVIVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30770/thumb/IMG_1662.png?1686905344"
        },
        {
          "chainId": 1,
          "address": "0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611",
          "name": "Million",
          "symbol": "MM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16825/thumb/logo200x200.png?1625834139"
        },
        {
          "chainId": 1,
          "address": "0xed40834a13129509a89be39a9be9c0e96a0ddd71",
          "name": "Warp Finance",
          "symbol": "WARP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13910/thumb/601ed0ac35c687c6e07d17c2_warp_token.png?1612834360"
        },
        {
          "chainId": 1,
          "address": "0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5",
          "name": "Hunt",
          "symbol": "HUNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7989/thumb/HUNT.png?1571024256"
        },
        {
          "chainId": 1,
          "address": "0x57c75eccc8557136d32619a191fbcdc88560d711",
          "name": "VeriDocGlobal",
          "symbol": "VDG",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/5604/thumb/VDG-ICON.png?1547041457"
        },
        {
          "chainId": 1,
          "address": "0xa66daa57432024023db65477ba87d4e7f5f95213",
          "name": "Huobi Pool",
          "symbol": "HPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6628/thumb/Huobi_pool_token.png?1547042829"
        },
        {
          "chainId": 1,
          "address": "0x9b110fda4e20db18ad7052f8468a455de7449eb6",
          "name": "Calvaria  DoE",
          "symbol": "RIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28817/thumb/icon200x200.png?1674460652"
        },
        {
          "chainId": 1,
          "address": "0x095cf7f3e82a1dcadbf0fbc59023f419883ea296",
          "name": "Pocket Arena",
          "symbol": "POC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8731/thumb/poc.png?1638521756"
        },
        {
          "chainId": 1,
          "address": "0xaec7d1069e3a914a3eb50f0bfb1796751f2ce48a",
          "name": "S4FE",
          "symbol": "S4F",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640"
        },
        {
          "chainId": 1,
          "address": "0x081f67afa0ccf8c7b17540767bbe95df2ba8d97f",
          "name": "CoinEx",
          "symbol": "CET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4817/thumb/coinex-token.png?1547040183"
        },
        {
          "chainId": 1,
          "address": "0xc5170dd7386247cdb8c48545c803f5d0e3347022",
          "name": "Titanium22",
          "symbol": "TI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31197/thumb/TI-AVATAR-1.jpg?1691147697"
        },
        {
          "chainId": 1,
          "address": "0xd77401a76d6cdb7ac3bb031bf25dec07615509e7",
          "name": "Metropoly",
          "symbol": "METRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30138/thumb/Metropoly_Icon.png?1683276879"
        },
        {
          "chainId": 1,
          "address": "0x36e43065e977bc72cb86dbd8405fae7057cdc7fd",
          "name": "ArchAngel",
          "symbol": "ARCHA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18814/thumb/Archa_200_x_200_PNG.png?1674811033"
        },
        {
          "chainId": 1,
          "address": "0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25",
          "name": "Sirin Labs",
          "symbol": "SRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1872/thumb/sirin-labs-token.png?1548609584"
        },
        {
          "chainId": 1,
          "address": "0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f",
          "name": "Open Exchange Token",
          "symbol": "OX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30604/thumb/Logo2.png?1685522119"
        },
        {
          "chainId": 1,
          "address": "0x0ec78ed49c2d27b315d462d43b5bab94d2c79bf8",
          "name": "Zero Tech",
          "symbol": "ZERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21530/thumb/UlyJW87D_400x400.jpg?1639384115"
        },
        {
          "chainId": 1,
          "address": "0xebe807bfdf8b2092e36e964c75463a8aaa642139",
          "name": "New Paradigm Assets Solution",
          "symbol": "NPAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24660/thumb/S3i9bI6n_400x400.jpg?1648529725"
        },
        {
          "chainId": 1,
          "address": "0xc6d1f1d5a46de07e73091f1c8793293b203f01a1",
          "name": "Mission Helios",
          "symbol": "HELIOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18295/thumb/helios.png?1644049696"
        },
        {
          "chainId": 1,
          "address": "0xddf7fd345d54ff4b40079579d4c4670415dbfd0a",
          "name": "SocialGood",
          "symbol": "SG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3948/thumb/logo_200.png?1596589686"
        },
        {
          "chainId": 1,
          "address": "0x8c661806f716652b637728355cc4e2620d428f99",
          "name": "Delio DSP",
          "symbol": "DSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26236/thumb/4Wbz4ZFt_400x400.png?1656901850"
        },
        {
          "chainId": 1,
          "address": "0xc626e0619ac79afea9281c8eb9b1a9f9d3fab532",
          "name": "Freedom Reserve",
          "symbol": "FR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046"
        },
        {
          "chainId": 1,
          "address": "0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c",
          "name": "Ultra",
          "symbol": "UOS",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/4480/thumb/Ultra.png?1563356418"
        },
        {
          "chainId": 1,
          "address": "0xefab7248d36585e2340e5d25f8a8d243e6e3193f",
          "name": "Dacxi",
          "symbol": "DACXI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4466/thumb/dacxi.png?1639042471"
        },
        {
          "chainId": 1,
          "address": "0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d",
          "name": "iZUMi Bond USD",
          "symbol": "IUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png?1651660620"
        },
        {
          "chainId": 1,
          "address": "0xd83ae04c9ed29d6d3e6bf720c71bc7beb424393e",
          "name": "InsureDAO",
          "symbol": "INSURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23913/thumb/InsureDAO_-_Final_Logo_%28solo%29.png?1645682885"
        },
        {
          "chainId": 1,
          "address": "0xd6bd97a26232ba02172ff86b055d5d7be789335b",
          "name": "Ormeus Cash",
          "symbol": "OMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387"
        },
        {
          "chainId": 1,
          "address": "0x5cb888182fbffdb62c08fb4b5a343914f00fdfee",
          "name": "Moneybrain BiPS",
          "symbol": "BIPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15556/thumb/bips_logo_200x200_Trans.png?1621204965"
        },
        {
          "chainId": 1,
          "address": "0xf8b358b3397a8ea5464f8cc753645d42e14b79ea",
          "name": "Airbloc",
          "symbol": "ABL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4393/thumb/airbloc-protocol-logo.png?1547039734"
        },
        {
          "chainId": 1,
          "address": "0xa62cc35625b0c8dc1faea39d33625bb4c15bd71c",
          "name": "StormX",
          "symbol": "STMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1369/thumb/StormX.png?1603113002"
        },
        {
          "chainId": 1,
          "address": "0x0cf0ee63788a0849fe5297f3407f701e122cc023",
          "name": "Streamr XDATA",
          "symbol": "XDATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1115/thumb/streamr.png?1547035101"
        },
        {
          "chainId": 1,
          "address": "0x1864ce27e9f7517047933caae530674e8c70b8a7",
          "name": "Pibble",
          "symbol": "PIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2972/thumb/pibble.jpg?1551683103"
        },
        {
          "chainId": 1,
          "address": "0x96eb50804d0ef2790f2e1a33670feff6040cf89d",
          "name": "Space Xmitter",
          "symbol": "SX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28335/thumb/X200.png?1669680704"
        },
        {
          "chainId": 1,
          "address": "0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0",
          "name": "Aave LINK",
          "symbol": "ALINK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827"
        },
        {
          "chainId": 1,
          "address": "0xf278c1ca969095ffddded020290cf8b5c424ace2",
          "name": "Ruff",
          "symbol": "RUFF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2726/thumb/ruff.png?1548608556"
        },
        {
          "chainId": 1,
          "address": "0x6c5024cd4f8a59110119c56f8933403a539555eb",
          "name": "Aave SUSD",
          "symbol": "ASUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14266/thumb/aSUSD.9b00ffc6.png?1615528241"
        },
        {
          "chainId": 1,
          "address": "0x2c93e25182ac52bcbccc95a3bd61ce4b3a7e38d1",
          "name": "Pengy",
          "symbol": "PENGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31196/thumb/Pengy.jpg?1691147291"
        },
        {
          "chainId": 1,
          "address": "0x816fce1cc93473b2d54bd8c0dae4cae911f6dd41",
          "name": "Mech X",
          "symbol": "MECHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31047/thumb/26F58FD9-0CFF-4F6C-8067-3747A3A06DF9.png?1689861561"
        },
        {
          "chainId": 1,
          "address": "0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5",
          "name": "cETH",
          "symbol": "CETH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10643/thumb/ceth.png?1687143191"
        },
        {
          "chainId": 1,
          "address": "0x582d872a1b094fc48f5de31d3b73f2d9be47def1",
          "name": "Toncoin",
          "symbol": "TON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17980/thumb/ton_symbol.png?1670498136"
        },
        {
          "chainId": 1,
          "address": "0x7b3296198f8a548edf89bdb16864da8f37b7d9cb",
          "name": "GoldeNugget",
          "symbol": "GNTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9974/thumb/gn.png?1574319320"
        },
        {
          "chainId": 1,
          "address": "0x4b1d0b9f081468d780ca1d5d79132b64301085d1",
          "name": "Lumerin",
          "symbol": "LMR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24593/thumb/Lumerin-icon.png?1650688090"
        },
        {
          "chainId": 1,
          "address": "0xdffa3a7f5b40789c7a437dbe7b31b47f9b08fe75",
          "name": "CryptoPunk  7171",
          "symbol": "HOODIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17842/thumb/cryptopunk-hoodie.png?1629468579"
        },
        {
          "chainId": 1,
          "address": "0xea47b64e1bfccb773a0420247c0aa0a3c1d2e5c5",
          "name": "BAYC Vault  NFTX ",
          "symbol": "BAYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17035/thumb/BAYC.png?1626142944"
        },
        {
          "chainId": 1,
          "address": "0x10633216e7e8281e33c86f02bf8e565a635d9770",
          "name": "Dvision Network",
          "symbol": "DVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13020/thumb/WGAhHOLv_400x400.png?1604396086"
        },
        {
          "chainId": 1,
          "address": "0xcfcffe432a48db53f59c301422d2edd77b2a88d7",
          "name": "Texan",
          "symbol": "TEXAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28731/thumb/texan_logo_200x200.png?1673690316"
        },
        {
          "chainId": 1,
          "address": "0x79d4f0232a66c4c91b89c76362016a1707cfbf4f",
          "name": "VNX Swiss Franc",
          "symbol": "VCHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29547/thumb/VNXCHF_%282%29.png?1679561328"
        },
        {
          "chainId": 1,
          "address": "0xee9e5eff401ee921b138490d00ca8d1f13f67a72",
          "name": "Asian Fintech",
          "symbol": "AFIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png?1630048356"
        },
        {
          "chainId": 1,
          "address": "0x40a9d39aa50871df092538c5999b107f34409061",
          "name": "Instadapp DAI",
          "symbol": "IDAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25821/thumb/iDAI_100x100.png?1654055549"
        },
        {
          "chainId": 1,
          "address": "0x0cec1a9154ff802e7934fc916ed7ca50bde6844e",
          "name": "PoolTogether",
          "symbol": "POOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14003/thumb/PoolTogether.png?1613585632"
        },
        {
          "chainId": 1,
          "address": "0xd85ad783cc94bd04196a13dc042a3054a9b52210",
          "name": "TribeOne",
          "symbol": "HAKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16575/thumb/USqW1QX.png?1625577555"
        },
        {
          "chainId": 1,
          "address": "0x5c59a5b139b0538cb106d775a022cad98dd14b5a",
          "name": "XREATORS",
          "symbol": "ORT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25678/thumb/XREATORS_ORT_Logo.png?1653548310"
        },
        {
          "chainId": 1,
          "address": "0xfa14fa6958401314851a17d6c5360ca29f74b57b",
          "name": "Saito",
          "symbol": "SAITO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14750/thumb/SAITO.png?1626857406"
        },
        {
          "chainId": 1,
          "address": "0xb0e99627bc29adef1178f16117bf495351e81997",
          "name": "Dex Trade Coin",
          "symbol": "DXC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15025/thumb/R1A63oDx_400x400.jpg?1619489389"
        },
        {
          "chainId": 1,
          "address": "0x6b1a8f210ec6b7b6643cea3583fb0c079f367898",
          "name": "Baanx",
          "symbol": "BXX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17108/thumb/BXX_Token_logo.png?1626311836"
        },
        {
          "chainId": 1,
          "address": "0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb",
          "name": "Mithril",
          "symbol": "MITH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3484/thumb/mithril.png?1548085086"
        },
        {
          "chainId": 1,
          "address": "0xc31cebf8f9e825d1d1244d73d0a65e44bd5210db",
          "name": "CRYN",
          "symbol": "CRYN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/24601/thumb/cryn.png?1648365782"
        },
        {
          "chainId": 1,
          "address": "0x17ef75aa22dd5f6c2763b8304ab24f40ee54d48a",
          "name": "Revolution Populi",
          "symbol": "RVP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14772/thumb/vsd0Wnc2_400x400.png?1618369912"
        },
        {
          "chainId": 1,
          "address": "0x9b5161a41b58498eb9c5febf89d60714089d2253",
          "name": "Meta Finance",
          "symbol": "MF1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21954/thumb/eC1-dKKt_400x400.jpg?1640326512"
        },
        {
          "chainId": 1,
          "address": "0x155040625d7ae3e9cada9a73e3e44f76d3ed1409",
          "name": "Revomon",
          "symbol": "REVO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14759/thumb/revomon.jpeg?1618243538"
        },
        {
          "chainId": 1,
          "address": "0x000000000000d0151e748d25b766e77efe2a6c83",
          "name": "XDEFI Governance",
          "symbol": "XDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14485/thumb/logo.png?1616472896"
        },
        {
          "chainId": 1,
          "address": "0x66eff5221ca926636224650fd3b9c497ff828f7d",
          "name": "MultiBTC",
          "symbol": "MULTIBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29495/thumb/MultiBTC.png?1679364709"
        },
        {
          "chainId": 1,
          "address": "0xd7d8f3b8bc8bc48d3acc37879eaba7b85889fa52",
          "name": "ClearDAO",
          "symbol": "CLH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22389/thumb/clh_small_logo_png.png?1643194446"
        },
        {
          "chainId": 1,
          "address": "0x5549394cfeef53b08fa41317019f474bfc588528",
          "name": "Rapid Stakes",
          "symbol": "RAPID",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31166/thumb/D9npeRtv_400x400.jpg?1691058001"
        },
        {
          "chainId": 1,
          "address": "0x9c6666d5ff4b53b5eb3bd866664c15d0bfcecaa7",
          "name": "Edoverse Zeni",
          "symbol": "ZENI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29238/thumb/Edoverse_Logo.png?1677569478"
        },
        {
          "chainId": 1,
          "address": "0xaada04204e9e1099daf67cf3d5d137e84e41cf41",
          "name": "Peepo",
          "symbol": "PEEPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30006/thumb/20230425_175833.png?1682482396"
        },
        {
          "chainId": 1,
          "address": "0x6692de64716a177c15360d8d010bc522bbc530a0",
          "name": "Talent",
          "symbol": "TNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25563/thumb/tnt.png?1652521166"
        },
        {
          "chainId": 1,
          "address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
          "name": "Enjin Coin",
          "symbol": "ENJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078"
        },
        {
          "chainId": 1,
          "address": "0xf32cfbaf4000e6820a95b3a3fcdbf27fb4efc9af",
          "name": "Cash Token",
          "symbol": "CASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30676/thumb/200x.png?1686214447"
        },
        {
          "chainId": 1,
          "address": "0x0c48250eb1f29491f1efbeec0261eb556f0973c7",
          "name": "AimBot",
          "symbol": "AIMBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31200/thumb/200x200.jpg?1691377993"
        },
        {
          "chainId": 1,
          "address": "0x491e136ff7ff03e6ab097e54734697bb5802fc1c",
          "name": "Kattana",
          "symbol": "KTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14739/thumb/256-256-1.png?1638363577"
        },
        {
          "chainId": 1,
          "address": "0x8b1f49491477e0fb46a29fef53f1ea320d13c349",
          "name": "MicroMoney",
          "symbol": "AMM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1356/thumb/micromoney.png?1548085045"
        },
        {
          "chainId": 1,
          "address": "0x27206f5a9afd0c51da95f20972885545d3b33647",
          "name": "KuKu",
          "symbol": "KUKU",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/31177/thumb/IMG_20230801_131536_144.jpg?1691118691"
        },
        {
          "chainId": 1,
          "address": "0x0f7f961648ae6db43c75663ac7e5414eb79b5704",
          "name": "Blockzero Labs",
          "symbol": "XIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10029/thumb/blockzero.jpg?1611110205"
        },
        {
          "chainId": 1,
          "address": "0x65ef703f5594d2573eb71aaf55bc0cb548492df4",
          "name": "Multichain",
          "symbol": "MULTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238"
        },
        {
          "chainId": 1,
          "address": "0x00282fd551d03dc033256c4bf119532e8c735d8a",
          "name": "Biaocoin",
          "symbol": "BIAO",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/30602/thumb/biao_cmc.png?1685516220"
        },
        {
          "chainId": 1,
          "address": "0x0d248ce39e26fb00f911fb1e7a45a00d8c94341c",
          "name": "Butter",
          "symbol": "BUTTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30252/thumb/butterlogotrans.png?1683703944"
        },
        {
          "chainId": 1,
          "address": "0xc3eb2622190c57429aac3901808994443b64b466",
          "name": "ORO",
          "symbol": "ORO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13114/thumb/oro_logo.png?1605338447"
        },
        {
          "chainId": 1,
          "address": "0x5befbb272290dd5b8521d4a938f6c4757742c430",
          "name": "Xfinance",
          "symbol": "XFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12449/thumb/go.png?1599904281"
        },
        {
          "chainId": 1,
          "address": "0xe1a4c5bbb704a92599fedb191f451e0d3a1ed842",
          "name": "PolkaDomain",
          "symbol": "NAME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14832/thumb/name.png?1618580733"
        },
        {
          "chainId": 1,
          "address": "0xb64fde8f199f073f41c132b9ec7ad5b61de0b1b7",
          "name": "Incognito",
          "symbol": "PRV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21971/thumb/50738351.png?1640560573"
        },
        {
          "chainId": 1,
          "address": "0x64b5a241b829bca14eb42d00097ba8fd8135841e",
          "name": "MEFLEX",
          "symbol": "MEF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28752/thumb/_200x200_%28png%29white_logo_meflex.png?1673941234"
        },
        {
          "chainId": 1,
          "address": "0x6c6ee5e31d828de241282b9606c8e98ea48526e2",
          "name": "Holo",
          "symbol": "HOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3348/thumb/Holologo_Profile.png?1547037966"
        },
        {
          "chainId": 1,
          "address": "0xb269ab3b20e09e6fd17c3ed5b4cecf3dea1a5141",
          "name": "Sakura Planet",
          "symbol": "SAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27225/thumb/SAK.?1662698993"
        },
        {
          "chainId": 1,
          "address": "0x02e7ac540409d32c90bfb51114003a9e1ff0249c",
          "name": "JPG NFT Index",
          "symbol": "JPG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25071/thumb/JPG-token-logo-01.png?1650242041"
        },
        {
          "chainId": 1,
          "address": "0xa962fc9d092c1e2de00bf600e261cf058b5685b1",
          "name": "STYLE Protocol",
          "symbol": "STYLE",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0xfad45e47083e4607302aa43c65fb3106f1cd7607",
          "name": "Hoge Finance",
          "symbol": "HOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14360/thumb/hoge.jpg?1615641604"
        },
        {
          "chainId": 1,
          "address": "0x3da434f76226b9489ed4277eb6fdccdf03ff190e",
          "name": "Zion Token",
          "symbol": "ZION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30504/thumb/zion.jpg?1684811345"
        },
        {
          "chainId": 1,
          "address": "0xbadff0ef41d2a68f22de21eabca8a59aaf495cf0",
          "name": "Kabosu Inu",
          "symbol": "KABOSU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28619/thumb/Kabosu_Inu.png?1672582138"
        },
        {
          "chainId": 1,
          "address": "0xa2e3356610840701bdf5611a53974510ae27e2e1",
          "name": "Wrapped Beacon ETH",
          "symbol": "WBETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30061/thumb/wbeth-icon.png?1683001548"
        },
        {
          "chainId": 1,
          "address": "0xd4df22556e07148e591b4c7b4f555a17188cf5cf",
          "name": "Twitfi",
          "symbol": "TWT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28755/thumb/23251.png?1673949057"
        },
        {
          "chainId": 1,
          "address": "0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d",
          "name": "Synapse Network",
          "symbol": "SNP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17962/thumb/Webp-net-resizeimage_%282%29.png?1629943450"
        },
        {
          "chainId": 1,
          "address": "0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532",
          "name": "ZEON Network",
          "symbol": "ZEON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4247/thumb/XZqXYc2j_400x400.jpg?1547039580"
        },
        {
          "chainId": 1,
          "address": "0xfe5f141bf94fe84bc28ded0ab966c16b17490657",
          "name": "Libra Credit",
          "symbol": "LBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3673/thumb/libra-credit.png?1547975828"
        },
        {
          "chainId": 1,
          "address": "0x0f51bb10119727a7e5ea3538074fb341f56b09ad",
          "name": "DAO Maker",
          "symbol": "DAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13915/thumb/4.png?1612838831"
        },
        {
          "chainId": 1,
          "address": "0x51acb1ea45c1ec2512ae4202b9076c13016dc8aa",
          "name": "Fractal Protocol Vault",
          "symbol": "USDF",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/30946/thumb/fractal.jpeg?1689089141"
        },
        {
          "chainId": 1,
          "address": "0x2223bf1d7c19ef7c06dab88938ec7b85952ccd89",
          "name": "Kryxivia Game",
          "symbol": "KXA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21225/thumb/kxa.png?1638742914"
        },
        {
          "chainId": 1,
          "address": "0x968cbe62c830a0ccf4381614662398505657a2a9",
          "name": "Thrupenny",
          "symbol": "TPY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26799/thumb/rsz_1mpmojfho_400x400.png?1660189540"
        },
        {
          "chainId": 1,
          "address": "0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9",
          "name": "Cardstarter",
          "symbol": "CARDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14612/thumb/CARDSx200.png?1617252006"
        },
        {
          "chainId": 1,
          "address": "0x3ebb4a4e91ad83be51f8d596533818b246f4bee1",
          "name": "Signata",
          "symbol": "SATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256"
        },
        {
          "chainId": 1,
          "address": "0xeee0fe52299f2de8e2ed5111cd521ab67dcf0faf",
          "name": "The QWAN",
          "symbol": "QWAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30613/thumb/qwan.jpg?1685699484"
        },
        {
          "chainId": 1,
          "address": "0xaa3573cc3761133375b0c504146b93f6827f945a",
          "name": "All Time High",
          "symbol": "ATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28304/thumb/ath200.png?1669276766"
        },
        {
          "chainId": 1,
          "address": "0xd8b90d2e680ea535eacce1b025c998b347892f68",
          "name": "Chatter Shield",
          "symbol": "SHIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31140/thumb/CABDF194-1-FCF-4418-8-BA1-F537-D227288-F.png?1690956290"
        },
        {
          "chainId": 1,
          "address": "0xc528c28fec0a90c083328bc45f587ee215760a0f",
          "name": "Endor Protocol",
          "symbol": "EDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3683/thumb/0b805574-ef0d-41dc-b518-8d6148bf4716.png?1547038680"
        },
        {
          "chainId": 1,
          "address": "0xb29663aa4e2e81e425294193616c1b102b70a158",
          "name": "Ludena Protocol",
          "symbol": "LDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13372/thumb/LudenaProtocol_symbol_200x200.png?1607999831"
        },
        {
          "chainId": 1,
          "address": "0x4d67edef87a5ff910954899f4e5a0aaf107afd42",
          "name": "BlueSparrow  OLD ",
          "symbol": "BLUESPARROW",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19995/thumb/bluesparrow.png?1636361716"
        },
        {
          "chainId": 1,
          "address": "0xb197a6fdd8ab825332edeba1ab5c4d1bf97fb9a8",
          "name": "Shibarium DAO",
          "symbol": "SHIBDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28881/thumb/200x200.png?1675140206"
        },
        {
          "chainId": 1,
          "address": "0xd31695a1d35e489252ce57b129fd4b1b05e6acac",
          "name": "TOKPIE",
          "symbol": "TKP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865"
        },
        {
          "chainId": 1,
          "address": "0x0000000de40dfa9b17854cbc7869d80f9f98d823",
          "name": "delta theta",
          "symbol": "DLTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png?1621570627"
        },
        {
          "chainId": 1,
          "address": "0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c",
          "name": "Golff",
          "symbol": "GOF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12445/thumb/_x-AmLBv_400x400.jpg?1599902833"
        },
        {
          "chainId": 1,
          "address": "0x06a01a4d579479dd5d884ebf61a31727a3d8d442",
          "name": "Skey Network",
          "symbol": "SKEY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13425/thumb/SKEY_Network_logo_black.png?1633421778"
        },
        {
          "chainId": 1,
          "address": "0x9d9e399e5385e2b9a58d4f775a1e16441b571afb",
          "name": "Metano",
          "symbol": "METANO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28183/thumb/logo200x.jpeg?1668256131"
        },
        {
          "chainId": 1,
          "address": "0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
          "name": "LunaChow",
          "symbol": "LUCHOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157"
        },
        {
          "chainId": 1,
          "address": "0x798d1be841a82a273720ce31c822c61a67a601c3",
          "name": "DIGG",
          "symbol": "DIGG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/13737/thumb/digg.PNG?1611292196"
        },
        {
          "chainId": 1,
          "address": "0xdcb5645eda1ed34c5641d81b927d33ebae9cf2a4",
          "name": "PayB",
          "symbol": "PAYB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25805/thumb/logo_payb_200_x_200.png?1653978259"
        },
        {
          "chainId": 1,
          "address": "0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6",
          "name": "Stargate Finance",
          "symbol": "STG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518"
        },
        {
          "chainId": 1,
          "address": "0xbea269038eb75bdab47a9c04d0f5c572d94b93d5",
          "name": "Wrapped FIO",
          "symbol": "WFIO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29183/thumb/fio_light_favicon_transparent_200x200.png?1677200040"
        },
        {
          "chainId": 1,
          "address": "0x8b3870df408ff4d7c3a26df852d41034eda11d81",
          "name": "IOI",
          "symbol": "IOI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701"
        },
        {
          "chainId": 1,
          "address": "0x5136c98a80811c3f46bdda8b5c4555cfd9f812f0",
          "name": "indaHash",
          "symbol": "IDH",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1909/thumb/indahash.png?1547973902"
        },
        {
          "chainId": 1,
          "address": "0x1afb69dbc9f54d08dab1bd3436f8da1af819e647",
          "name": "Melos Studio",
          "symbol": "MELOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24411/thumb/18551.png?1647558428"
        },
        {
          "chainId": 1,
          "address": "0xc983c1bd8393dd5c630a3d6c4fe9e5c92e582328",
          "name": "Kekya",
          "symbol": "KEKYA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30510/thumb/kekyalogo_%282%29.png?1684829969"
        },
        {
          "chainId": 1,
          "address": "0x2c9023bbc572ff8dc1228c7858a280046ea8c9e5",
          "name": "Vivid Labs",
          "symbol": "VID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3376/thumb/1638280236344.jpg?1645083911"
        },
        {
          "chainId": 1,
          "address": "0xe33ae4e795114279721047484e5ad5cc7df24fcb",
          "name": "MCFinance",
          "symbol": "MCF",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/17608/thumb/arFRQ0b.png?1628650181"
        },
        {
          "chainId": 1,
          "address": "0x6f8b23296394d20ec048fbdec8ebc0ca90f5c8f1",
          "name": "TUF Token",
          "symbol": "TUF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28410/thumb/2022-12-06_10.10.16.jpg?1670385344"
        },
        {
          "chainId": 1,
          "address": "0x7105faa4a26ed1c67b8b2b41bec98f06ee21d105",
          "name": "X7105",
          "symbol": "X7105",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28423/thumb/X7105_LOGO_black_back_200_x_200_px.png?1670460916"
        },
        {
          "chainId": 1,
          "address": "0x8a2ead49d2123edee893181fb69527a022235c36",
          "name": "Max Revive",
          "symbol": "MAXR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22402/thumb/17030.png?1641794243"
        },
        {
          "chainId": 1,
          "address": "0x979aca85ba37c675e78322ed5d97fa980b9bdf00",
          "name": "FUSION",
          "symbol": "FSN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907"
        },
        {
          "chainId": 1,
          "address": "0x336f646f87d9f6bc6ed42dd46e8b3fd9dbd15c22",
          "name": "Crystal Clear",
          "symbol": "CCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/995/thumb/crystal-clear.png?1547034897"
        },
        {
          "chainId": 1,
          "address": "0x217ddead61a42369a266f1fb754eb5d3ebadc88a",
          "name": "Don key",
          "symbol": "DON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824"
        },
        {
          "chainId": 1,
          "address": "0xd0d6d6c5fe4a677d343cc433536bb717bae167dd",
          "name": "adChain",
          "symbol": "ADT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/778/thumb/adtoken.png?1547034537"
        },
        {
          "chainId": 1,
          "address": "0xfbe878ced08132bd8396988671b450793c44bc12",
          "name": "Fox Trading",
          "symbol": "FOXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619"
        },
        {
          "chainId": 1,
          "address": "0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8",
          "name": "Everex",
          "symbol": "EVX",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/997/thumb/everex.png?1548125695"
        },
        {
          "chainId": 1,
          "address": "0x79b87d52a03ed26a19c1914be4ce37f812e2056c",
          "name": "USP Token",
          "symbol": "USP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28855/thumb/USP_Token.png?1683090295"
        },
        {
          "chainId": 1,
          "address": "0x1de852cd18323bc5ebda842b8eef46c6e551aa47",
          "name": "hiRENGA",
          "symbol": "HIRENGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28999/thumb/hirenga.png?1675841906"
        },
        {
          "chainId": 1,
          "address": "0xc3f65b1c45b0d42a472cd0fbd37dd6678aef667a",
          "name": "Cinnamoon",
          "symbol": "CIMO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28465/thumb/D7287693-BFD2-4E5B-9A52-BBA8749F2B7D.png?1670911437"
        },
        {
          "chainId": 1,
          "address": "0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30",
          "name": "Vidya",
          "symbol": "VIDYA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12219/thumb/VIDYA_TOKEN.png?1598240425"
        },
        {
          "chainId": 1,
          "address": "0xd4f6f9ae14399fd5eb8dfc7725f0094a1a7f5d80",
          "name": "Bitsten  OLD ",
          "symbol": "BST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7335/thumb/bitsten.png?1589629209"
        },
        {
          "chainId": 1,
          "address": "0xea097a2b1db00627b2fa17460ad260c016016977",
          "name": "Upfiring",
          "symbol": "UFR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1170/thumb/upfiring.png?1548759693"
        },
        {
          "chainId": 1,
          "address": "0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7",
          "name": "Gro DAO",
          "symbol": "GRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18673/thumb/613f171979749061aaa1edf9_Icon-GRO-256x256-Square.png?1632876204"
        },
        {
          "chainId": 1,
          "address": "0xc58f53a8adff2fb4eb16ed56635772075e2ee123",
          "name": "Aave AMM UniWBTCWETH",
          "symbol": "AAMMUNIWBTCWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17231/thumb/aAmmUniWBTCWETH.png?1626921113"
        },
        {
          "chainId": 1,
          "address": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
          "name": "Chiliz",
          "symbol": "CHZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8834/thumb/CHZ_Token_updated.png?1675848257"
        },
        {
          "chainId": 1,
          "address": "0x669db4c47f89f21554ebd825a744888725fd9491",
          "name": "hiPENGUINS",
          "symbol": "HIPENGUINS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28381/thumb/63856bebcd8c700001b23c42_hiPENGUINS-_Logo.png?1669974038"
        },
        {
          "chainId": 1,
          "address": "0xd79f43113b22d1ea9f29cfcc7bb287489f8ee5e0",
          "name": "Protocol Zero",
          "symbol": "ZRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28557/thumb/photo_2022-08-16_02-50-00.jpg?1671705583"
        },
        {
          "chainId": 1,
          "address": "0x6251e725cd45fb1af99354035a414a2c0890b929",
          "name": "MixTrust",
          "symbol": "MXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12148/thumb/MXT_LOGO_200_200.png?1597578983"
        },
        {
          "chainId": 1,
          "address": "0x3a82d3111ab5faf39d847d46023d9090261a658f",
          "name": "Tycoon",
          "symbol": "TYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15165/thumb/coingecko.png?1622177525"
        },
        {
          "chainId": 1,
          "address": "0x7103ebdbf1f89be2d53eff9b3cf996c9e775c105",
          "name": "X7103",
          "symbol": "X7103",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28421/thumb/X7103_LOGO_black_back_200_x_200_px.png?1670460779"
        },
        {
          "chainId": 1,
          "address": "0x0fcbc31c503b4a9ed90e87f8ff46c318a4a14260",
          "name": "Quantfury",
          "symbol": "QTF",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14033/thumb/FtSOX9Vy_400x400.jpg?1613946524"
        },
        {
          "chainId": 1,
          "address": "0x2ebd53d035150f328bd754d6dc66b99b0edb89aa",
          "name": "Metronome",
          "symbol": "MET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3249/thumb/met.png?1673081184"
        },
        {
          "chainId": 1,
          "address": "0xc74b43cc61b627667a608c3f650c2558f88028a1",
          "name": "Astral AI",
          "symbol": "ASTRAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29505/thumb/Astral_AI_Project.png?1679381776"
        },
        {
          "chainId": 1,
          "address": "0xcc0d1b36d88fd8f5f26fd00e4e769e2dab4e3e07",
          "name": "Lightning Bot",
          "symbol": "LIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31123/thumb/output-onlinepngtools_%285%29.png?1690774196"
        },
        {
          "chainId": 1,
          "address": "0x755be920943ea95e39ee2dc437b268917b580d6e",
          "name": "VersoView",
          "symbol": "VVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13380/thumb/HkfxEtWh_400x400.jpg?1608031723"
        },
        {
          "chainId": 1,
          "address": "0x956f47f50a910163d8bf957cf5846d573e7f87ca",
          "name": "Fei USD",
          "symbol": "FEI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14570/thumb/ZqsF51Re_400x400.png?1617082206"
        },
        {
          "chainId": 1,
          "address": "0x0aa7efe4945db24d95ca6e117bba65ed326e291a",
          "name": "Ojamu",
          "symbol": "OJA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18947/thumb/ojamu-icon-PNK.png?1634006741"
        },
        {
          "chainId": 1,
          "address": "0xcc8e21f599995d1c8367054841b8af5024ddf01b",
          "name": "Alpha Gardeners",
          "symbol": "AG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31153/thumb/AG.png?1691049159"
        },
        {
          "chainId": 1,
          "address": "0x760ea31711ac14cb9ba81ac25453c6f378fa5794",
          "name": "BOWL SHIBARIUM",
          "symbol": "BOWL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29370/thumb/redbowl_200x200_logo_copy.png?1678349476"
        },
        {
          "chainId": 1,
          "address": "0x1e053d89e08c24aa2ce5c5b4206744dc2d7bd8f5",
          "name": "ThunderCore",
          "symbol": "TT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4375/thumb/ThunderCore_Logo_Mark_Gradient_%283%29.png?1650455349"
        },
        {
          "chainId": 1,
          "address": "0x95e05e2abbd26943874ac000d87c3d9e115b543c",
          "name": "Raptor",
          "symbol": "BIBLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30866/thumb/bible_logo.png?1688369335"
        },
        {
          "chainId": 1,
          "address": "0xd6929179d752d5d25c5efe2d9729eb77d7138a80",
          "name": "Goons of Balatroon",
          "symbol": "GOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27104/thumb/q1_hZykF_400x400.jpeg?1662006909"
        },
        {
          "chainId": 1,
          "address": "0x46e98ffe40e408ba6412beb670507e083c8b95ff",
          "name": "Primate",
          "symbol": "PRIMATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png?1651028701"
        },
        {
          "chainId": 1,
          "address": "0x8503a7b00b4b52692cc6c14e5b96f142e30547b7",
          "name": "Meeds DAO",
          "symbol": "MEED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24281/thumb/s-YfFWYu_400x400.png?1647218173"
        },
        {
          "chainId": 1,
          "address": "0xa3c56427683a19f7574b9fc219cfd27d5d6e87fa",
          "name": "Saitoki Inu",
          "symbol": "SAITOKI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23586/thumb/saitoki_200.png?1667467076"
        },
        {
          "chainId": 1,
          "address": "0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54",
          "name": "HAPI",
          "symbol": "HAPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14298/thumb/R9i2HjAL_400x400.jpg?1615332438"
        },
        {
          "chainId": 1,
          "address": "0x3fd8f39a962efda04956981c31ab89fab5fb8bc8",
          "name": "Rotharium",
          "symbol": "RTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2030/thumb/L7kpRMda_400x400.jpg?1547036314"
        },
        {
          "chainId": 1,
          "address": "0x11613b1f840bb5a40f8866d857e24da126b79d73",
          "name": "Cappasity",
          "symbol": "CAPP",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/1302/thumb/cappasity.png?1547564648"
        },
        {
          "chainId": 1,
          "address": "0x89b69f2d1adffa9a253d40840b6baa7fc903d697",
          "name": "Dione",
          "symbol": "DIONE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26931/thumb/dione_200x200.png?1660886063"
        },
        {
          "chainId": 1,
          "address": "0xfdedd42a446bf6c5a883727cda6ff557511e3e1e",
          "name": "Waygate",
          "symbol": "WAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30903/thumb/CMC_tile.png?1688620509"
        },
        {
          "chainId": 1,
          "address": "0xf5b5efc906513b4344ebabcf47a04901f99f09f3",
          "name": "UBIX Network",
          "symbol": "UBX",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/13000/thumb/1.png?1674822617"
        },
        {
          "chainId": 1,
          "address": "0x556d4f40982cb95e0714989e0c229c42be8b1499",
          "name": "Golteum",
          "symbol": "GLTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28597/thumb/_GLTM-Token-Border200.png?1672275111"
        },
        {
          "chainId": 1,
          "address": "0x3593d125a4f7849a1b059e64f4517a86dd60c95d",
          "name": "MANTRA",
          "symbol": "OM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12151/thumb/OM_Token.png?1666056782"
        },
        {
          "chainId": 1,
          "address": "0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b",
          "name": "Robo Inu Finance",
          "symbol": "RBIF",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20821/thumb/O68Gs5SE_400x400.jpg?1654929220"
        },
        {
          "chainId": 1,
          "address": "0x72f020f8f3e8fd9382705723cd26380f8d0c66bb",
          "name": "PlotX",
          "symbol": "PLOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12795/thumb/PlotX.png?1611109969"
        },
        {
          "chainId": 1,
          "address": "0x4b0181102a0112a2ef11abee5563bb4a3176c9d7",
          "name": "cSUSHI",
          "symbol": "CSUSHI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17529/thumb/csushi.PNG?1628122792"
        },
        {
          "chainId": 1,
          "address": "0x995a6c0944322b7285d0712824acf0cf9bf3993a",
          "name": "First Ever NFT",
          "symbol": "FEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24336/thumb/FEN_TOKEN_COIN_edited.png?1647361805"
        },
        {
          "chainId": 1,
          "address": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
          "name": "Render",
          "symbol": "RNDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934"
        },
        {
          "chainId": 1,
          "address": "0xc19b6a4ac7c7cc24459f08984bbd09664af17bd1",
          "name": "SENSO",
          "symbol": "SENSO",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/11064/thumb/senso.png?1587776458"
        },
        {
          "chainId": 1,
          "address": "0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c",
          "name": "X8X",
          "symbol": "X8X",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1912/thumb/x8x.png?1547036213"
        },
        {
          "chainId": 1,
          "address": "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
          "name": "Ankr Network",
          "symbol": "ANKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978"
        },
        {
          "chainId": 1,
          "address": "0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea",
          "name": "WPT Investing Corp",
          "symbol": "WPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26431/thumb/200200.png?1657934772"
        },
        {
          "chainId": 1,
          "address": "0x0000000000085d4780b73119b644ae5ecd22b376",
          "name": "TrueUSD",
          "symbol": "TUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3449/thumb/tusd.png?1618395665"
        },
        {
          "chainId": 1,
          "address": "0xb939da54f9748440a1b279d42be1296942732288",
          "name": "Fonzy",
          "symbol": "FONZY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30245/thumb/FONZY_CROWN_2_HANDS_%282%29.png?1683694006"
        },
        {
          "chainId": 1,
          "address": "0x34d6a0f5c2f5d0082141fe73d93b9dd00ca7ce11",
          "name": "Golden",
          "symbol": "GOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8939/thumb/4uf1Sim9_400x400.png?1563116366"
        },
        {
          "chainId": 1,
          "address": "0xa31b1767e09f842ecfd4bc471fe44f830e3891aa",
          "name": "Roobee",
          "symbol": "ROOBEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629"
        },
        {
          "chainId": 1,
          "address": "0x557b933a7c2c45672b610f8954a3deb39a51a8ca",
          "name": "REVV",
          "symbol": "REVV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390"
        },
        {
          "chainId": 1,
          "address": "0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35",
          "name": "Sweatcoin  Sweat Economy ",
          "symbol": "SWEAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25057/thumb/fhD9Xs16_400x400.jpg?1649947000"
        },
        {
          "chainId": 1,
          "address": "0x26524e35e44e265eb181c7ba6ec084983181aae3",
          "name": "Cydotori",
          "symbol": "DOTR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28109/thumb/dotr.png?1667545446"
        },
        {
          "chainId": 1,
          "address": "0xad22f63404f7305e4713ccbd4f296f34770513f4",
          "name": "Atomic Wallet Coin",
          "symbol": "AWC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7430/thumb/Atomic_Wallet_Aeron_%281%29.png?1547274057"
        },
        {
          "chainId": 1,
          "address": "0xc6956b78e036b87ba2ab9810bf081eb76eedd17a",
          "name": "H",
          "symbol": "H",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28759/thumb/H.png?1673998140"
        },
        {
          "chainId": 1,
          "address": "0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8",
          "name": "LP yCurve",
          "symbol": "YCURVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11858/thumb/yCrv.png?1595203628"
        },
        {
          "chainId": 1,
          "address": "0x15eabb7500e44b7fdb6e4051ca8deca430cf9fb8",
          "name": "Dexfin",
          "symbol": "DXF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13660/thumb/dxf-symbol.png?1610599440"
        },
        {
          "chainId": 1,
          "address": "0x986ee2b944c42d017f52af21c4c69b84dbea35d8",
          "name": "BitMart",
          "symbol": "BMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5236/thumb/bitmart-token.png?1548835017"
        },
        {
          "chainId": 1,
          "address": "0x445bd590a01fe6709d4f13a8f579c1e4846921db",
          "name": "DUMMY",
          "symbol": "DUMMY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30650/thumb/a3miYrC7_400x400-removebg-preview%281%29.png?1686122117"
        },
        {
          "chainId": 1,
          "address": "0x66fd97a78d8854fec445cd1c80a07896b0b4851f",
          "name": "Lunch Money",
          "symbol": "LMY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4149/thumb/IMG_2073.png?1612754752"
        },
        {
          "chainId": 1,
          "address": "0x4c6e2c495b974b8d4220e370f23c7e0e1da9b644",
          "name": "Smiley Coin",
          "symbol": "SMILEY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30809/thumb/photo_2023-06-21_03-25-14.jpg?1687419153"
        },
        {
          "chainId": 1,
          "address": "0x6d4695f7e8d7f9615314a743335702d7eaba4575",
          "name": "LiquiShield",
          "symbol": "LIQS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30919/thumb/IMG_0508.jpeg?1688958109"
        },
        {
          "chainId": 1,
          "address": "0x39207d2e2feef178fbda8083914554c59d9f8c00",
          "name": "MultiPlanetary Inus",
          "symbol": "INUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22648/thumb/logo.png?1644479310"
        },
        {
          "chainId": 1,
          "address": "0x5d259e3fbcadef1abfea5582e456bc7f0aebc2a1",
          "name": "Alpha Intelligence",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29127/thumb/alpha.png?1677663278"
        },
        {
          "chainId": 1,
          "address": "0x3c20ac688410be8f391be1fb00afc5c212972f86",
          "name": "CLever USD",
          "symbol": "CLEVUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30624/thumb/fx_logo.png?1685777143"
        },
        {
          "chainId": 1,
          "address": "0x9ce84f6a69986a83d92c324df10bc8e64771030f",
          "name": "CHEX Token",
          "symbol": "CHEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png?1578434355"
        },
        {
          "chainId": 1,
          "address": "0x9488936e4a36e3585afeb63377e2393aeac253ea",
          "name": "GAMB",
          "symbol": "GMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4497/thumb/gmb.png?1547039806"
        },
        {
          "chainId": 1,
          "address": "0x383518188c0c6d7730d91b2c03a03c837814a899",
          "name": "Olympus v1",
          "symbol": "OHM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21496/thumb/OHM.jpg?1639620224"
        },
        {
          "chainId": 1,
          "address": "0xf7970499814654cd13cb7b6e7634a12a7a8a9abc",
          "name": "TOM Finance",
          "symbol": "TOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13173/thumb/TOM_logo.png?1605857896"
        },
        {
          "chainId": 1,
          "address": "0xdd78b3e430361560a70b9cbb7d98d961f0428b61",
          "name": "Patrick",
          "symbol": "PAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30266/thumb/500x500_%282%29.png?1683775789"
        },
        {
          "chainId": 1,
          "address": "0x442bc47357919446eabc18c7211e57a13d983469",
          "name": "BeeChat",
          "symbol": "CHAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14386/thumb/ezgif-6-b78b8423d870.png?1615803294"
        },
        {
          "chainId": 1,
          "address": "0x358bd0d980e031e23eba9aa793926857703783bd",
          "name": "Aave AMM BptWBTCWETH",
          "symbol": "AAMMBPTWBTCWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17260/thumb/aAmmBptWBTCWETH.png?1626959325"
        },
        {
          "chainId": 1,
          "address": "0x4dd672e77c795844fe3a464ef8ef0faae617c8fb",
          "name": "CONUN",
          "symbol": "CON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7480/thumb/conun.png?1547716938"
        },
        {
          "chainId": 1,
          "address": "0x9c32185b81766a051e08de671207b34466dd1021",
          "name": "BTC Proxy",
          "symbol": "BTCPX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/22630/thumb/MB1aYO7T_400x400.jpg?1642342656"
        },
        {
          "chainId": 1,
          "address": "0x9e32b13ce7f2e80a01932b42553652e053d6ed8e",
          "name": "Metis",
          "symbol": "METIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312"
        },
        {
          "chainId": 1,
          "address": "0x328c4c80bc7aca0834db37e6600a6c49e12da4de",
          "name": "Aave SNX v1",
          "symbol": "ASNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11733/thumb/aSNX.png?1593085047"
        },
        {
          "chainId": 1,
          "address": "0xc56c2b7e71b54d38aab6d52e94a04cbfa8f604fa",
          "name": "ZUSD",
          "symbol": "ZUSD",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14192/thumb/icon_zusd_200_200.png?1614843297"
        },
        {
          "chainId": 1,
          "address": "0xb7c2fcd6d7922eddd2a7a9b0524074a60d5b472c",
          "name": "VentiSwap",
          "symbol": "VST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25591/thumb/output-onlinepngtools-2_2.png?1652862384"
        },
        {
          "chainId": 1,
          "address": "0x55c08ca52497e2f1534b59e2917bf524d4765257",
          "name": "UwU Lend",
          "symbol": "UWU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27408/thumb/uwu_logo_no-bg_1024.png?1663842922"
        },
        {
          "chainId": 1,
          "address": "0xe28b3b32b6c345a34ff64674606124dd5aceca30",
          "name": "Injective",
          "symbol": "INJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237"
        },
        {
          "chainId": 1,
          "address": "0x6dde4ffd6db302bc9a46850f61399e082f6c2122",
          "name": "inheritance Art",
          "symbol": "IAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24221/thumb/iAI.jpg?1646919582"
        },
        {
          "chainId": 1,
          "address": "0xa43a8fc632d809fb2c16f590c0ff6423e311c67d",
          "name": "Modulus Domain Service",
          "symbol": "MODS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29844/thumb/_MODS.png?1681726696"
        },
        {
          "chainId": 1,
          "address": "0x419d0d8bdd9af5e606ae2232ed285aff190e711b",
          "name": "FUNToken",
          "symbol": "FUN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/761/thumb/FUN.png?1678773913"
        },
        {
          "chainId": 1,
          "address": "0xcb8d1260f9c92a3a545d409466280ffdd7af7042",
          "name": "NFT Protocol",
          "symbol": "NFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12174/thumb/nftprotocol_32.png?1597818115"
        },
        {
          "chainId": 1,
          "address": "0x99c6e435ec259a7e8d65e1955c9423db624ba54c",
          "name": "Finminity",
          "symbol": "FMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14696/thumb/finminity.png?1617834914"
        },
        {
          "chainId": 1,
          "address": "0x3252d4221f92b7af3774da8312f01600ec84b252",
          "name": "xoloitzcuintli",
          "symbol": "XOLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28567/thumb/photo_2022-12-15_22-39-03.jpg?1671789858"
        },
        {
          "chainId": 1,
          "address": "0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b",
          "name": "Rally",
          "symbol": "RLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077"
        },
        {
          "chainId": 1,
          "address": "0x4fabf135bcf8111671870d4399af739683198f96",
          "name": "Xave Coin",
          "symbol": "XVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg?1646292464"
        },
        {
          "chainId": 1,
          "address": "0x33840024177a7daca3468912363bed8b425015c5",
          "name": "Ebox",
          "symbol": "EBOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519"
        },
        {
          "chainId": 1,
          "address": "0xf9d4daae1300cff251979722c4a3c45857973079",
          "name": "bitcastle",
          "symbol": "CASTLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26551/thumb/blue_logo03.png?1658753049"
        },
        {
          "chainId": 1,
          "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
          "name": "Tether",
          "symbol": "USDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663"
        },
        {
          "chainId": 1,
          "address": "0x112b08621e27e10773ec95d250604a041f36c582",
          "name": "Wrapped Kaspa",
          "symbol": "KAS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31037/thumb/WKAS-256-black-ETH-3.png?1689846828"
        },
        {
          "chainId": 1,
          "address": "0x56a86d648c435dc707c8405b78e2ae8eb4e60ba4",
          "name": "StackOS",
          "symbol": "STACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14989/thumb/stackos.jpg?1661420187"
        },
        {
          "chainId": 1,
          "address": "0xf75c7a59bcd9bd207c4ab1beb0b32eed3b6392f3",
          "name": "Kei Finance",
          "symbol": "KEI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29840/thumb/200x200.png?1681718876"
        },
        {
          "chainId": 1,
          "address": "0x3a8cccb969a61532d1e6005e2ce12c200caece87",
          "name": "TitanSwap",
          "symbol": "TITAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12606/thumb/nqGlQzdz_400x400.png?1601019805"
        },
        {
          "chainId": 1,
          "address": "0x4bdcb66b968060d9390c1d12bd29734496205581",
          "name": "Acquire Fi",
          "symbol": "ACQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27199/thumb/ACQisotypeSept2022_1000x1000px.png?1662566695"
        },
        {
          "chainId": 1,
          "address": "0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5",
          "name": "Olympus",
          "symbol": "OHM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14483/thumb/token_OHM_%281%29.png?1628311611"
        },
        {
          "chainId": 1,
          "address": "0x95e40e065afb3059dcabe4aaf404c1f92756603a",
          "name": "King DAG",
          "symbol": "KDAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10809/thumb/3xcLUorv_400x400.jpg?1591000563"
        },
        {
          "chainId": 1,
          "address": "0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419",
          "name": "DIA",
          "symbol": "DIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11955/thumb/Token_Logo.png?1669981526"
        },
        {
          "chainId": 1,
          "address": "0xf433089366899d83a9f26a773d59ec7ecf30355e",
          "name": "Metal DAO",
          "symbol": "MTL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/763/thumb/Metal.png?1592195010"
        },
        {
          "chainId": 1,
          "address": "0xeb986da994e4a118d5956b02d8b7c3c7ce373674",
          "name": "Gather",
          "symbol": "GTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686"
        },
        {
          "chainId": 1,
          "address": "0x42476f744292107e34519f9c357927074ea3f75d",
          "name": "Loom Network  NEW ",
          "symbol": "LOOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14735/thumb/LOOM.png?1617967111"
        },
        {
          "chainId": 1,
          "address": "0xab2a7b5876d707e0126b3a75ef7781c77c8877ee",
          "name": "Quadency",
          "symbol": "QUAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21364/thumb/GkMXE_7A_400x400.png?1639014399"
        },
        {
          "chainId": 1,
          "address": "0x94e496474f1725f1c1824cb5bdb92d7691a4f03a",
          "name": "Banana",
          "symbol": "BANANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17521/thumb/banana-token-cg.png?1646285527"
        },
        {
          "chainId": 1,
          "address": "0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9",
          "name": "NFT Worlds",
          "symbol": "WRLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22112/thumb/ZyBrRgfO.jpg?1640842284"
        },
        {
          "chainId": 1,
          "address": "0x485d17a6f1b8780392d53d64751824253011a260",
          "name": "chrono tech",
          "symbol": "TIME",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666"
        },
        {
          "chainId": 1,
          "address": "0xa1b0edf4460cc4d8bfaa18ed871bff15e5b57eb4",
          "name": "Aave AMM UniBATWETH",
          "symbol": "AAMMUNIBATWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17217/thumb/aAmmUniBATWETH.png?1626878218"
        },
        {
          "chainId": 1,
          "address": "0xe7f58a92476056627f9fdb92286778abd83b285f",
          "name": "DecentraWeb",
          "symbol": "DWEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18971/thumb/dweb-logo-transparent.png?1634082358"
        },
        {
          "chainId": 1,
          "address": "0x030ba81f1c18d280636f32af80b9aad02cf0854e",
          "name": "Aave WETH",
          "symbol": "AWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782"
        },
        {
          "chainId": 1,
          "address": "0x37fc4b48ce93469dbea9918468993c735049642a",
          "name": "CropBytes",
          "symbol": "CBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19907/thumb/cbx.png?1647698560"
        },
        {
          "chainId": 1,
          "address": "0x9669890e48f330acd88b78d63e1a6b3482652cd9",
          "name": "Bincentive",
          "symbol": "BCNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9661/thumb/bcnt.png?1640328324"
        },
        {
          "chainId": 1,
          "address": "0x1977be49c33dfacf6590c16ca9a9cfa0463f663c",
          "name": "DoRen",
          "symbol": "DRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16901/thumb/DoRen_project_whitepaper_v1_1_EN_pdf.png?1625572581"
        },
        {
          "chainId": 1,
          "address": "0x103bb3ebc6f61b3db2d6e01e54ef7d9899a2e16b",
          "name": "Arch Ethereum Div  Yield",
          "symbol": "AEDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30483/thumb/AEDY-512x512.png?1684710666"
        },
        {
          "chainId": 1,
          "address": "0xaa0c5b3567fd1bac8a2a11eb16c3f81a49eea90f",
          "name": "MetamonkeyAi",
          "symbol": "MMAI",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/27208/thumb/mmai_new_logo.png?1675187587"
        },
        {
          "chainId": 1,
          "address": "0x905e337c6c8645263d3521205aa37bf4d034e745",
          "name": "Doc com",
          "symbol": "MTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4177/thumb/2711.png?1650864171"
        },
        {
          "chainId": 1,
          "address": "0xdadb4ae5b5d3099dd1f586f990b845f2404a1c4c",
          "name": "Lenny Face",
          "symbol": "",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29138/thumb/Lenny_Face.png?1676962950"
        },
        {
          "chainId": 1,
          "address": "0x892a6f9df0147e5f079b0993f486f9aca3c87881",
          "name": "xFUND",
          "symbol": "XFUND",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/13770/thumb/xfund.png?1611697744"
        },
        {
          "chainId": 1,
          "address": "0xe08ef9206a8a7c9337cc6611b4f5226fdafc4772",
          "name": "MESSI COIN",
          "symbol": "MESSI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30762/thumb/x4aUydEr_400x400.jpg?1686891475"
        },
        {
          "chainId": 1,
          "address": "0xd31a59c85ae9d8edefec411d448f90841571b89c",
          "name": "SOL  Wormhole ",
          "symbol": "SOL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316"
        },
        {
          "chainId": 1,
          "address": "0xd49efa7bc0d339d74f487959c573d518ba3f8437",
          "name": "Coliquidity",
          "symbol": "COLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png?1646641417"
        },
        {
          "chainId": 1,
          "address": "0x5c872500c00565505f3624ab435c222e558e9ff8",
          "name": "CoTrader",
          "symbol": "COT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png?1547039508"
        },
        {
          "chainId": 1,
          "address": "0xfa05a73ffe78ef8f1a739473e462c54bae6567d9",
          "name": "Lunyr",
          "symbol": "LUN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/735/thumb/lunyr.png?1547976492"
        },
        {
          "chainId": 1,
          "address": "0xb14ebf566511b9e6002bb286016ab2497b9b9c9d",
          "name": "Hypersign Identity",
          "symbol": "HID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16158/thumb/hypersign_ONLYlogo_Yellow.png?1623140987"
        },
        {
          "chainId": 1,
          "address": "0xddd5592cf4759313c649eb4e624a79541ed222ed",
          "name": "PepeXL",
          "symbol": "PEPEXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30343/thumb/plogo200.png?1684195424"
        },
        {
          "chainId": 1,
          "address": "0x6ff1bfa14a57594a5874b37ff6ac5efbd9f9599a",
          "name": "TotemFi",
          "symbol": "TOTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704"
        },
        {
          "chainId": 1,
          "address": "0x4cf488387f035ff08c371515562cba712f9015d4",
          "name": "WePower",
          "symbol": "WPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1951/thumb/wpr.png?1547036237"
        },
        {
          "chainId": 1,
          "address": "0xd7f5cabdf696d7d1bf384d7688926a4bdb092c67",
          "name": "DRC Mobility",
          "symbol": "DRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13457/thumb/drc_symbol.png?1608759323"
        },
        {
          "chainId": 1,
          "address": "0xed0e2041bfb5a426e5ed426a73765624e08bbb75",
          "name": "FANZY",
          "symbol": "FX1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11117/thumb/FX.png?1604900016"
        },
        {
          "chainId": 1,
          "address": "0x69e8b9528cabda89fe846c67675b5d73d463a916",
          "name": "OPEN Governance",
          "symbol": "OPEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13233/thumb/opendao_logo.png?1606575207"
        },
        {
          "chainId": 1,
          "address": "0x62d3c05b9c3d916fbc111819bbd3cee52906c1ae",
          "name": "Every Game",
          "symbol": "EGAME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20758/thumb/egame.png?1637648306"
        },
        {
          "chainId": 1,
          "address": "0x2ecba91da63c29ea80fbe7b52632ca2d1f8e5be0",
          "name": "FairERC20",
          "symbol": "FERC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30698/thumb/fair.png?1686541302"
        },
        {
          "chainId": 1,
          "address": "0xc443c15033fcb6cf72cc24f1bda0db070ddd9786",
          "name": "Balancer Boosted Aave USD",
          "symbol": "BB-A-USD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25769/thumb/0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb2.png?1653604699"
        },
        {
          "chainId": 1,
          "address": "0x2370f9d504c7a6e775bf6e14b3f12846b594cd53",
          "name": "JPY Coin v1",
          "symbol": "JPYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17277/thumb/WoZ8rruL_400x400.png?1627016492"
        },
        {
          "chainId": 1,
          "address": "0x441761326490cacf7af299725b6292597ee822c2",
          "name": "Unifi Protocol DAO",
          "symbol": "UNFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967"
        },
        {
          "chainId": 1,
          "address": "0xe59d2ff6995a926a574390824a657eed36801e55",
          "name": "Aave AMM UniAAVEWETH",
          "symbol": "AAMMUNIAAVEWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17232/thumb/aAmmUniAAVEWETH.png?1626921301"
        },
        {
          "chainId": 1,
          "address": "0x4216663ddc7bd10eaf44609df4dd0f91cd2be7f2",
          "name": "MicroPepe",
          "symbol": "MPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30153/thumb/logo.jpeg?1683517269"
        },
        {
          "chainId": 1,
          "address": "0xfdb15e5e6799be72798b1ccfaecbf186bf73a0c4",
          "name": "NitroEX",
          "symbol": "NTX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13976/thumb/ntx-icon.png?1613435774"
        },
        {
          "chainId": 1,
          "address": "0xbcd4b7de6fde81025f74426d43165a5b0d790fdd",
          "name": "SpiderDAO",
          "symbol": "SPDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13378/thumb/spiderdao_logo.png?1608029180"
        },
        {
          "chainId": 1,
          "address": "0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed",
          "name": "Raini",
          "symbol": "RAINI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png?1633314246"
        },
        {
          "chainId": 1,
          "address": "0xf29ae508698bdef169b89834f76704c3b205aedf",
          "name": "SNX yVault",
          "symbol": "YVSNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28797/thumb/yvSNX-128-0xF29AE508698bDeF169B89834F76704C3B205aedf.png?1674226457"
        },
        {
          "chainId": 1,
          "address": "0xabea7663c472648d674bd3403d94c858dfeef728",
          "name": "PUDGY Vault  NFTX ",
          "symbol": "PUDGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18330/thumb/pudgy.png?1631605038"
        },
        {
          "chainId": 1,
          "address": "0xcc7ab8d78dba187dc95bf3bb86e65e0c26d0041f",
          "name": "Spacelens",
          "symbol": "SPACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16979/thumb/SPACE___Spacelens.png?1625900475"
        },
        {
          "chainId": 1,
          "address": "0x525794473f7ab5715c81d06d10f52d11cc052804",
          "name": "12Ships",
          "symbol": "TSHP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9351/thumb/12ships.png?1566485390"
        },
        {
          "chainId": 1,
          "address": "0x5c6ee304399dbdb9c8ef030ab642b10820db8f56",
          "name": "Balancer 80 BAL 20 WETH",
          "symbol": "B-80BAL-20WETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26452/thumb/0x5c6ee304399dbdb9c8ef030ab642b10820db8f56.png?1658113613"
        },
        {
          "chainId": 1,
          "address": "0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8",
          "name": "Radar",
          "symbol": "RADAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13909/thumb/RADAR.png?1634183461"
        },
        {
          "chainId": 1,
          "address": "0xed0889f7e1c7c7267407222be277e1f1ef4d4892",
          "name": "MELX",
          "symbol": "MEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15028/thumb/melx.jpeg?1637156878"
        },
        {
          "chainId": 1,
          "address": "0x76306f029f8f99effe509534037ba7030999e3cf",
          "name": "Acreage Coin",
          "symbol": "ACR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5100/thumb/acreage-coin.jpg?1547040494"
        },
        {
          "chainId": 1,
          "address": "0xdd782657af86492771bdb369caeb8c246be362f5",
          "name": "RUGAME",
          "symbol": "RUG",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/29925/thumb/rugame.png?1682136914"
        },
        {
          "chainId": 1,
          "address": "0xfeeb4d0f5463b1b04351823c246bdb84c4320cc2",
          "name": "Gold Retriever",
          "symbol": "GLDN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27529/thumb/33406C30-2F50-45D8-9F75-5B7EAEB23038.jpeg?1664371749"
        },
        {
          "chainId": 1,
          "address": "0xac0968a3e2020ac8ca83e60ccf69081ebc6d3bc3",
          "name": "Cindrum",
          "symbol": "CIND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20449/thumb/1_sbv9l6ArIn7oYIhi0oQWoQ.png?1637043983"
        },
        {
          "chainId": 1,
          "address": "0x8526be2379e853d5cf02f9823bb9690e1a6ff9e2",
          "name": "Habibi",
          "symbol": "HABIBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30683/thumb/HABIBI.png?1686273022"
        },
        {
          "chainId": 1,
          "address": "0x0b319db00d07c8fadfaaef13c910141a5da0aa8f",
          "name": "Fluid TUSD",
          "symbol": "FTUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28474/thumb/fTUSD-200x200.png?1671002558"
        },
        {
          "chainId": 1,
          "address": "0xf61bf4d1a948487d61b8fa63808aac06bda55f98",
          "name": "Tr3zor",
          "symbol": "TR3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21980/thumb/9T9OoYXE_400x400.jpg?1640573690"
        },
        {
          "chainId": 1,
          "address": "0xfac77a24e52b463ba9857d6b758ba41ae20e31ff",
          "name": "LSDx Finance",
          "symbol": "LSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29519/thumb/logo.png?1679448801"
        },
        {
          "chainId": 1,
          "address": "0x1032abe2902a23ddcbab085c20e0e69c33ceb8fa",
          "name": "Pepe Predator",
          "symbol": "SNAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30205/thumb/Logo_FOR_CG.png?1683616617"
        },
        {
          "chainId": 1,
          "address": "0xba386a4ca26b85fd057ab1ef86e3dc7bdeb5ce70",
          "name": "Jesus Coin",
          "symbol": "JESUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30036/thumb/JESUS_COIN_LOGO.png?1682646791"
        },
        {
          "chainId": 1,
          "address": "0x4e4bffaa8df6f0dc3e5600bbacf7da55f37134fc",
          "name": "BlockStar",
          "symbol": "BST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29524/thumb/BlockStar_Transparent_200_by_200.png?1679472988"
        },
        {
          "chainId": 1,
          "address": "0x8f8ed076e61a27db72a5724ea0907a66fb0ddb32",
          "name": "gold pieces",
          "symbol": "GP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30281/thumb/buying_old_school_runescape_gold_osrs_gold_1484153770_0f3f5f5e.jpg?1683796647"
        },
        {
          "chainId": 1,
          "address": "0xe4d75e9b493458d032a5c3cc1ee9b0712c1ece06",
          "name": "Bitcci Cash",
          "symbol": "BITCCA",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/25998/thumb/20574.png?1655276250"
        },
        {
          "chainId": 1,
          "address": "0xb840d10d840ef47c233fec1fd040f5b145a6dfa5",
          "name": "STREETH",
          "symbol": "STREETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24954/thumb/streeth.png?1649507723"
        },
        {
          "chainId": 1,
          "address": "0xbbc7f7a6aadac103769c66cbc69ab720f7f9eae3",
          "name": "INX Token",
          "symbol": "INX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29241/thumb/Tc8Z6xAG_400x400.jpg?1677573022"
        },
        {
          "chainId": 1,
          "address": "0xcae7f3aa6d456463bef0e5b508542b69d96f2904",
          "name": "InvectAI",
          "symbol": "INVECTAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29333/thumb/Invect.jpeg?1678173073"
        },
        {
          "chainId": 1,
          "address": "0xe516d78d784c77d479977be58905b3f2b1111126",
          "name": "Bitspawn",
          "symbol": "SPWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16513/thumb/token_logo.png?1631603192"
        },
        {
          "chainId": 1,
          "address": "0x31fdd1c6607f47c14a2821f599211c67ac20fa96",
          "name": "Burency",
          "symbol": "BUY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12216/thumb/Ddphl9BD.png?1598238121"
        },
        {
          "chainId": 1,
          "address": "0xc4cb5793bd58bad06bf51fb37717b86b02cbe8a4",
          "name": "PROXI DeFi",
          "symbol": "CREDIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12212/thumb/_credit.png?1598235420"
        },
        {
          "chainId": 1,
          "address": "0x8c1bed5b9a0928467c9b1341da1d7bd5e10b6549",
          "name": "Liquid Staked ETH",
          "symbol": "LSETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28848/thumb/LsETH-receipt-token-circle.png?1674866187"
        },
        {
          "chainId": 1,
          "address": "0x9d7630adf7ab0b0cb00af747db76864df0ec82e4",
          "name": "GATENet",
          "symbol": "GATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16009/thumb/82168105.png?1622599261"
        },
        {
          "chainId": 1,
          "address": "0x2c9aceb63181cd08a093d052ec041e191f229692",
          "name": "Angryb",
          "symbol": "ANB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17270/thumb/AngryBlockchain.png?1627005396"
        },
        {
          "chainId": 1,
          "address": "0x09617f6fd6cf8a71278ec86e23bbab29c04353a7",
          "name": "Shardus",
          "symbol": "ULT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8383/thumb/final_logo_photoshop.png?1557890272"
        },
        {
          "chainId": 1,
          "address": "0xa23c1194d421f252b4e6d5edcc3205f7650a4ebe",
          "name": "LaunchBlock",
          "symbol": "LBP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24181/thumb/S6z_-9GB_400x400.jpg?1646786017"
        },
        {
          "chainId": 1,
          "address": "0x3810a4ddf41e586fa0dba1463a7951b748cecfca",
          "name": "Menapay",
          "symbol": "MPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6360/thumb/Menapay_ico.png?1547042454"
        },
        {
          "chainId": 1,
          "address": "0x505a84a03e382331a1be487b632cf357748b65d6",
          "name": "SHIBGF",
          "symbol": "SHIBGF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19699/thumb/shibgf_logo.png?1637293051"
        },
        {
          "chainId": 1,
          "address": "0xad83b92cdd542db07445597f7f06963b31cf9444",
          "name": "Bumble C",
          "symbol": "BUMBLEC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29813/thumb/Bumblec_logo.jpg?1681433322"
        },
        {
          "chainId": 1,
          "address": "0x7cda79830faf07ed696fe220566116951ced36a7",
          "name": "Maya Preferred",
          "symbol": "MAYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8081/thumb/2u0OXhT9_400x400.png?1554426529"
        },
        {
          "chainId": 1,
          "address": "0x07e3c70653548b04f0a75970c1f81b4cbbfb606f",
          "name": "Agrello",
          "symbol": "DLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/900/thumb/delta_200x200.png?1616645398"
        },
        {
          "chainId": 1,
          "address": "0x79650799e7899a802cb96c0bc33a6a8d4ce4936c",
          "name": "AICHAIN",
          "symbol": "AIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2525/thumb/aichain.png?1547036638"
        },
        {
          "chainId": 1,
          "address": "0x12a7530d6f9e1a9b0351d78ab711f7c2c033873a",
          "name": "Hoot",
          "symbol": "HOOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29497/thumb/hoot.png?1679370641"
        },
        {
          "chainId": 1,
          "address": "0xdb2f2bcce3efa95eda95a233af45f3e0d4f00e2a",
          "name": "Aegis",
          "symbol": "AGS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12391/thumb/logo-3.png?1599540071"
        },
        {
          "chainId": 1,
          "address": "0xdbbe358fead311b4089b8bf30f455c041e67f5f4",
          "name": "DED",
          "symbol": "DED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29938/thumb/dedlogo.png?1682225318"
        },
        {
          "chainId": 1,
          "address": "0xd4126f195a8de772eeffa61a4ab6dd43462f4e39",
          "name": "Hikari Protocol",
          "symbol": "HIKARI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28953/thumb/HIKARI.png?1675585665"
        },
        {
          "chainId": 1,
          "address": "0x9d95486e1b0e0ea8a5361e853901f731b7f8e403",
          "name": "Bored Pepe VIP Club",
          "symbol": "BPVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30738/thumb/biredcmc.png?1686723017"
        },
        {
          "chainId": 1,
          "address": "0xc91b523a59acc63a64f61fc7bbfb4bfc82dd25f2",
          "name": "Multiverse",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16329/thumb/multiverse_logo_full_color_rgb_200px_72ppi.png?1652011384"
        },
        {
          "chainId": 1,
          "address": "0x2c000c0093de75a8fa2fccd3d97b314e20b431c3",
          "name": "Huobi Litecoin",
          "symbol": "HLTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14110/thumb/HLTC.png?1614335967"
        },
        {
          "chainId": 1,
          "address": "0x7f3141c4d6b047fb930991b450f1ed996a51cb26",
          "name": "X",
          "symbol": "X",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22334/thumb/x.PNG?1641519663"
        },
        {
          "chainId": 1,
          "address": "0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9",
          "name": "EDDASwap",
          "symbol": "EDDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14368/thumb/edda.png?1615732928"
        },
        {
          "chainId": 1,
          "address": "0xb1f871ae9462f1b2c6826e88a7827e76f86751d4",
          "name": "GNY",
          "symbol": "GNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5300/thumb/GNY_LOGO_NEW_TRANS.png?1675592825"
        },
        {
          "chainId": 1,
          "address": "0xb3e6ee8d2c586fa03ab70aef96b8ae6d12d64ec7",
          "name": "hiFIDENZA",
          "symbol": "HIFIDENZA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28237/thumb/hifidenza.png?1668579144"
        },
        {
          "chainId": 1,
          "address": "0x8c130499d33097d4d000d3332e1672f75b431543",
          "name": "Hoppy Token",
          "symbol": "HOPPY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31027/thumb/IMG_1349.jpeg?1689838365"
        },
        {
          "chainId": 1,
          "address": "0x686f2404e77ab0d9070a46cdfb0b7fecdd2318b0",
          "name": "LORDS",
          "symbol": "LORDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22171/thumb/Frame_1.png?1642334107"
        },
        {
          "chainId": 1,
          "address": "0x4abb9cc67bd3da9eb966d1159a71a0e68bd15432",
          "name": "KelVPN",
          "symbol": "KEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14851/thumb/logokelvpn200x200.png?1618797379"
        },
        {
          "chainId": 1,
          "address": "0x6e98e5401adcb0d76f4debfc3d794b3031f48790",
          "name": "Aurix",
          "symbol": "AUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14372/thumb/gold-brown-2.png?1615769158"
        },
        {
          "chainId": 1,
          "address": "0x035df12e0f3ac6671126525f1015e47d79dfeddf",
          "name": "0xMonero",
          "symbol": "0XMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680"
        },
        {
          "chainId": 1,
          "address": "0x1a403e1c96792dfedb8232cf56400eb72ab95acb",
          "name": "Saltmarble",
          "symbol": "SML",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27504/thumb/SML_200px.png?1664331670"
        },
        {
          "chainId": 1,
          "address": "0xc36b4311b21fc0c2ead46f1ea6ce97c9c4d98d3d",
          "name": "Creaticles",
          "symbol": "CRE8",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21115/thumb/logo-v3-small.png?1638339957"
        },
        {
          "chainId": 1,
          "address": "0x6c2adc2073994fb2ccc5032cc2906fa221e9b391",
          "name": "Delphy",
          "symbol": "DPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1133/thumb/delphy.png?1547035133"
        },
        {
          "chainId": 1,
          "address": "0x5c559f3ee9a81da83e069c0093471cb05d84052a",
          "name": "BabyPepe",
          "symbol": "BABYPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29869/thumb/BabyPepe200.png?1681892461"
        },
        {
          "chainId": 1,
          "address": "0x1e4e46b7bf03ece908c88ff7cc4975560010893a",
          "name": "Internet of Energy Network",
          "symbol": "IOEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19095/thumb/12799.png?1634422996"
        },
        {
          "chainId": 1,
          "address": "0x3a4cab3dcfab144fe7eb2b5a3e288cc03dc07659",
          "name": "OnGo",
          "symbol": "FTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20154/thumb/IMG-20220728-WA0004.jpg?1669792759"
        },
        {
          "chainId": 1,
          "address": "0x852e5427c86a3b46dd25e5fe027bb15f53c4bcb8",
          "name": "NiiFi",
          "symbol": "NIIFI",
          "decimals": 15,
          "logoURI": "https://assets.coingecko.com/coins/images/16033/thumb/niifi.PNG?1622674467"
        },
        {
          "chainId": 1,
          "address": "0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e",
          "name": "Big Data Protocol",
          "symbol": "BDP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14222/thumb/logo_BDP_200.png?1615088501"
        },
        {
          "chainId": 1,
          "address": "0xd08623fb2a1f044025eec65886011cf5d0f06b01",
          "name": "Larry",
          "symbol": "LARRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30460/thumb/Safeimagekit-resized-img.png?1684659070"
        },
        {
          "chainId": 1,
          "address": "0x72630b1e3b42874bf335020ba0249e3e9e47bafc",
          "name": "Paypolitan",
          "symbol": "EPAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13192/thumb/ava3.png?1606102032"
        },
        {
          "chainId": 1,
          "address": "0x6bd361e10c1afed0d95259e7c0115f3a60e4ea99",
          "name": "BollyCoin",
          "symbol": "BOLLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21610/thumb/IMG-20211208-124337-701.jpg?1639579540"
        },
        {
          "chainId": 1,
          "address": "0x69beab403438253f13b6e92db91f7fb849258263",
          "name": "Neuro NTK",
          "symbol": "NTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3028/thumb/neurotoken.png?1547037334"
        },
        {
          "chainId": 1,
          "address": "0x5228a22e72ccc52d415ecfd199f99d0665e7733b",
          "name": "pTokens BTC  OLD ",
          "symbol": "PBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10805/thumb/J51iIea.png?1583891599"
        },
        {
          "chainId": 1,
          "address": "0x635f15eb7aa2e62d122f6b1f9f519fdccf4abdda",
          "name": "hiMAYC",
          "symbol": "HIMAYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27602/thumb/himayc.png?1664716538"
        },
        {
          "chainId": 1,
          "address": "0xd115a61a25c059c78cd34b97fab7ab25dfc84bc3",
          "name": "Jesus",
          "symbol": "RAPTOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30745/thumb/Favicon_%281%29.png?1686727570"
        },
        {
          "chainId": 1,
          "address": "0x0000000000004946c0e9f43f4dee607b0ef1fa1c",
          "name": "Chi Gas",
          "symbol": "CHI",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/11583/thumb/chi.png?1591331659"
        },
        {
          "chainId": 1,
          "address": "0x1e18821e69b9faa8e6e75dffe54e7e25754beda0",
          "name": "KIMCHI finance",
          "symbol": "KIMCHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12302/thumb/VBa2Z60o_400x400.png?1598982471"
        },
        {
          "chainId": 1,
          "address": "0x5552e5a89a70cb2ef5adbbc45a6be442fe7160ec",
          "name": "Kawakami",
          "symbol": "KAWA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16369/thumb/kawakami-2022-red-logo.png?1665374338"
        },
        {
          "chainId": 1,
          "address": "0xd3c89cac4a4283edba6927e2910fd1ebc14fe006",
          "name": "Moona ",
          "symbol": "MOOI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14125/thumb/mooi.png?1617866132"
        },
        {
          "chainId": 1,
          "address": "0xaf1250fa68d7decd34fd75de8742bc03b29bd58e",
          "name": "Invictus Hyperion Fund",
          "symbol": "IHF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3747/thumb/ihp.png?1547038807"
        },
        {
          "chainId": 1,
          "address": "0xf45091f25d374bbe956c0bb64bb85e02d07aa741",
          "name": "MNMCoin",
          "symbol": "MNMC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11163/thumb/MNMC.png?1589280919"
        },
        {
          "chainId": 1,
          "address": "0x36880f14af2e85cae8e467827fa077d6bf12ea56",
          "name": "Jared From Subway",
          "symbol": "JARED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30890/thumb/Jared_Logo_200_X_200.png?1688524791"
        },
        {
          "chainId": 1,
          "address": "0x9cbf044bc535db4c93a9f11205a69631d9dcef26",
          "name": "Billy Token",
          "symbol": "BILLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30231/thumb/output-onlinepngtools.png?1683643413"
        },
        {
          "chainId": 1,
          "address": "0x0414d8c87b271266a5864329fb4932bbe19c0c49",
          "name": "WSB Coin",
          "symbol": "WSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30060/thumb/wsb_pfp.png?1683001544"
        },
        {
          "chainId": 1,
          "address": "0x8a6aca71a218301c7081d4e96d64292d3b275ce0",
          "name": "S Finance",
          "symbol": "SFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12605/thumb/Z7D8B4b.png?1605346184"
        },
        {
          "chainId": 1,
          "address": "0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2",
          "name": "Plasma Finance",
          "symbol": "PPAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849"
        },
        {
          "chainId": 1,
          "address": "0xacbd826394189cf2623c6df98a18b41fc8ffc16d",
          "name": "NFTify",
          "symbol": "N1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png?1627130530"
        },
        {
          "chainId": 1,
          "address": "0x887168120cb89fb06f3e74dc4af20d67df0977f6",
          "name": "Sekuritance",
          "symbol": "SKRT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15495/thumb/skrt.png?1621053881"
        },
        {
          "chainId": 1,
          "address": "0x37fe0f067fa808ffbdd12891c0858532cfe7361d",
          "name": "Civilization",
          "symbol": "CIV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17626/thumb/civ-200x200.png?1628674350"
        },
        {
          "chainId": 1,
          "address": "0x741b0428efdf4372a8df6fb54b018db5e5ab7710",
          "name": "ARTX",
          "symbol": "ARTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14652/thumb/logo_black_cmc.png?1617556500"
        },
        {
          "chainId": 1,
          "address": "0x4448d67ea26a2eaf286bc1045005f4cf15aaba11",
          "name": "Shinjarium",
          "symbol": "SJM",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29862/thumb/Shinjarium_logo.png?1681877969"
        },
        {
          "chainId": 1,
          "address": "0x228ba514309ffdf03a81a205a6d040e429d6e80c",
          "name": "Global Social Chain",
          "symbol": "GSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4304/thumb/global-social-chain.png?1547742843"
        },
        {
          "chainId": 1,
          "address": "0x18cc2ba8995c6307e355726244adb023cf00522f",
          "name": "Monke",
          "symbol": "MONKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30192/thumb/A2305F39-4EC1-45B6-A70C-BD1B1EC02AF3.jpg?1683605880"
        },
        {
          "chainId": 1,
          "address": "0x6cacdb97e3fc8136805a9e7c342d866ab77d0957",
          "name": "Swapr",
          "symbol": "SWPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18740/thumb/swapr.jpg?1633516501"
        },
        {
          "chainId": 1,
          "address": "0xdacd69347de42babfaecd09dc88958378780fb62",
          "name": "Atari",
          "symbol": "ATRI",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png?1643189483"
        },
        {
          "chainId": 1,
          "address": "0x3af5ba94c29a8407785f5f6d90ef5d69a8eb2436",
          "name": "Unagii Wrapped Bitcoin",
          "symbol": "UWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14625/thumb/uBTC.png?1617294190"
        },
        {
          "chainId": 1,
          "address": "0x03042ae6fcfd53e3a0baa1fab5ce70e0cb74e6fb",
          "name": "Ten Best Coins",
          "symbol": "TBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27312/thumb/tbc.png?1663312003"
        },
        {
          "chainId": 1,
          "address": "0x3d5fa1cf7d356474f72c8cb24f7a6117b40f8c40",
          "name": "Saintbot",
          "symbol": "SAINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31165/thumb/1SDHoUxG_400x400.jpg?1691057545"
        },
        {
          "chainId": 1,
          "address": "0xe64b47931f28f89cc7a0c6965ecf89eadb4975f5",
          "name": "Ludos Protocol",
          "symbol": "LUD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5118/thumb/Ludos_Protocol.jpg?1557216263"
        },
        {
          "chainId": 1,
          "address": "0x13a0599c493cc502ed8c4dd26f22f5cedc248fc4",
          "name": "Export Motors Platform",
          "symbol": "EMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24422/thumb/emp_logo_200.png?1647654574"
        },
        {
          "chainId": 1,
          "address": "0xda4dd9586d27202a338843dd6b9824d267006783",
          "name": "Echain Network",
          "symbol": "ECT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28133/thumb/1666808362379-8226c3c3451e049842cfe21d65cb91ad.png?1667719253"
        },
        {
          "chainId": 1,
          "address": "0xb444cb2bcb19180602328fa774738cb8dbd6677b",
          "name": "Mumon Ginsen",
          "symbol": "MG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29785/thumb/Mumon.jpeg?1681287004"
        },
        {
          "chainId": 1,
          "address": "0x78132543d8e20d2417d8a07d9ae199d458a0d581",
          "name": "Luna Inu",
          "symbol": "LINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25623/thumb/output-onlinepngtools.png?1653983337"
        },
        {
          "chainId": 1,
          "address": "0x1bbf25e71ec48b84d773809b4ba55b6f4be946fb",
          "name": "Vow",
          "symbol": "VOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18202/thumb/72Nd63R0_400x400.png?1630974351"
        },
        {
          "chainId": 1,
          "address": "0x1d96fd43ee07aa79f8fd003cbdf404fb5ce41ad2",
          "name": "Qawalla",
          "symbol": "QWLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15242/thumb/qwla.png?1648268288"
        },
        {
          "chainId": 1,
          "address": "0x296233e84c1d7bff11121bf6d60f0ffa39c3f0cf",
          "name": "No One",
          "symbol": "NOONE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19393/thumb/logo-200x200_%289%29.png?1635150101"
        },
        {
          "chainId": 1,
          "address": "0x4f08705fb8f33affc231ed66e626b40e84a71870",
          "name": "Flute",
          "symbol": "FLUT",
          "decimals": 11,
          "logoURI": "https://assets.coingecko.com/coins/images/29148/thumb/TheFlute200x200.png?1676966950"
        },
        {
          "chainId": 1,
          "address": "0x0f1e49d6dcfc9eefcce9d5ae3c660f8ead75061a",
          "name": "Vinlink",
          "symbol": "VNLNK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30139/thumb/VNLNK_Logo.png?1683277242"
        },
        {
          "chainId": 1,
          "address": "0x178bf8fd04b47d2de3ef3f6b3d112106375ad584",
          "name": "Unagii Tether USD",
          "symbol": "UUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13778/thumb/uUSDT.png?1611729448"
        },
        {
          "chainId": 1,
          "address": "0xf7413489c474ca4399eee604716c72879eea3615",
          "name": "APYSwap",
          "symbol": "APYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14163/thumb/apys.png?1635831990"
        },
        {
          "chainId": 1,
          "address": "0x5ea82c27efc7634f1c5ad20a3561c453433a2f3a",
          "name": "BTRIPS",
          "symbol": "BTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19273/thumb/btr.png?1638182009"
        },
        {
          "chainId": 1,
          "address": "0x8eedefe828a0f16c8fc80e46a87bc0f1de2d960c",
          "name": "DigiMetaverse",
          "symbol": "DGMV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png?1645081296"
        },
        {
          "chainId": 1,
          "address": "0xb44b653f147569d88a684cbf6549e1968e8b2a1d",
          "name": "2DAI io",
          "symbol": "2DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30542/thumb/2dai.jpg?1684983815"
        },
        {
          "chainId": 1,
          "address": "0x7eaf9c89037e4814dc0d9952ac7f888c784548db",
          "name": "Royale",
          "symbol": "ROYA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13602/thumb/roya.png?1636031771"
        },
        {
          "chainId": 1,
          "address": "0xe9f721e7419423f11863e83dbd710b5d6127b5b0",
          "name": "ePhiat",
          "symbol": "EPHIAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29737/thumb/ePHIAT_Trust.png?1681095826"
        },
        {
          "chainId": 1,
          "address": "0x9625ce7753ace1fa1865a47aae2c5c2ce4418569",
          "name": "KAP Games",
          "symbol": "KAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27682/thumb/KAP_Logo.png?1689662354"
        },
        {
          "chainId": 1,
          "address": "0x72955ecff76e48f2c8abcce11d54e5734d6f3657",
          "name": "TrustVerse",
          "symbol": "TRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8793/thumb/trv_256px.png?1623928469"
        },
        {
          "chainId": 1,
          "address": "0x6c4c193bff0a117f0c2b516802abba961a1eeb12",
          "name": "Papa",
          "symbol": "PAPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29971/thumb/logo-dex.png?1682399020"
        },
        {
          "chainId": 1,
          "address": "0xd8684adc4664bc2a0c78ddc8657dc005e804af15",
          "name": "Copycat DAO",
          "symbol": "CCD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30728/thumb/twitter-logo.jpeg?1686635660"
        },
        {
          "chainId": 1,
          "address": "0x664c6e221c77313307467b121528ad563107bd01",
          "name": "Tax Haven Inu",
          "symbol": "TAXHAVENINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27362/thumb/tax_haven_inu.png?1663665897"
        },
        {
          "chainId": 1,
          "address": "0x8e964e35a76103af4c7d7318e1b1a82c682ae296",
          "name": "Fellaz",
          "symbol": "FLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26169/thumb/NUERy1Wy_400x400.png?1656336115"
        },
        {
          "chainId": 1,
          "address": "0xe477292f1b3268687a29376116b0ed27a9c76170",
          "name": "HEROcoin",
          "symbol": "PLAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2221/thumb/herocoin.png?1547744781"
        },
        {
          "chainId": 1,
          "address": "0x5510d26c504b21ef22ca85b7125390bc23ca50e7",
          "name": "Homer",
          "symbol": "SIMPSON20",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30882/thumb/1111.png?1688461602"
        },
        {
          "chainId": 1,
          "address": "0x59b44e9ac4ab8e29db6faf7bacd726c6b7bc94b9",
          "name": "Black Eyed Dragon",
          "symbol": "BLEYD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29432/thumb/C4B58A54-53A3-4644-8F61-2B86BF006122.jpeg?1678784104"
        },
        {
          "chainId": 1,
          "address": "0x2d64750ec85f6ec442e3093d74c7b1b0a133be6a",
          "name": "Point Coin",
          "symbol": "POINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27295/thumb/point.png?1663150205"
        },
        {
          "chainId": 1,
          "address": "0xf55a93b613d172b86c2ba3981a849dae2aecde2f",
          "name": "Your Futures Exchange",
          "symbol": "YFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455"
        },
        {
          "chainId": 1,
          "address": "0x1735db6ab5baa19ea55d0adceed7bcdc008b3136",
          "name": "UREEQA",
          "symbol": "URQA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14605/thumb/R_O2enOX_400x400.png?1617243310"
        },
        {
          "chainId": 1,
          "address": "0x93dfaf57d986b9ca77df9376c50878e013d9c7c8",
          "name": "Unique One",
          "symbol": "RARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13458/thumb/Logo_Unique.png?1612757355"
        },
        {
          "chainId": 1,
          "address": "0xd0b3a986fff305854a7238a8e099cce1ced01a3d",
          "name": "Nova",
          "symbol": "NOVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30943/thumb/IMG_20230709_210803_010.jpg?1689084967"
        },
        {
          "chainId": 1,
          "address": "0x42a501903afaa1086b5975773375c80e363f4063",
          "name": "Cryptyk",
          "symbol": "CTK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2729/thumb/th.jpg?1667122361"
        },
        {
          "chainId": 1,
          "address": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
          "name": "ConstitutionDAO",
          "symbol": "PEOPLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20612/thumb/GN_UVm3d_400x400.jpg?1637294355"
        },
        {
          "chainId": 1,
          "address": "0x19e2a43fbbc643c3b2d9667d858d49cad17bc2b5",
          "name": "BNS",
          "symbol": "BNS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25150/thumb/bitbns_logo.png?1650450366"
        },
        {
          "chainId": 1,
          "address": "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
          "name": "Wrapped stETH",
          "symbol": "WSTETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18834/thumb/wstETH.png?1633565443"
        },
        {
          "chainId": 1,
          "address": "0xc5bcc8ba3f33ab0d64f3473e861bdc0685b19ef5",
          "name": "Mechanium",
          "symbol": "MECHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24374/thumb/w4K4OOMo_400x400.jpg?1647940092"
        },
        {
          "chainId": 1,
          "address": "0x7a83ff237e7870d8d6c3485920ebe654d2841315",
          "name": "hiOD",
          "symbol": "HIOD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28238/thumb/hiod.png?1668579474"
        },
        {
          "chainId": 1,
          "address": "0xc146b7cdbaff065090077151d391f4c96aa09e0c",
          "name": "Multi Chain Capital",
          "symbol": "MCC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21621/thumb/mcc.png?1639622572"
        },
        {
          "chainId": 1,
          "address": "0xca1207647ff814039530d7d35df0e1dd2e91fa84",
          "name": "dHEDGE DAO",
          "symbol": "DHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12508/thumb/dht.png?1600752201"
        },
        {
          "chainId": 1,
          "address": "0xf70ce9ee486106882d3dc43ddbd84e0fa71ac2a5",
          "name": "Ducker",
          "symbol": "DUCKER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30816/thumb/Ducker_Logo_200x200.png?1687749545"
        },
        {
          "chainId": 1,
          "address": "0x61fd1c62551850d0c04c76fce614cbced0094498",
          "name": "IDK",
          "symbol": "IDK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9301/thumb/idk-logo.png?1565861759"
        },
        {
          "chainId": 1,
          "address": "0xba93ef534094f8b7001ece2691168140965341ab",
          "name": "Beauty Bakery Linked Operation Transact",
          "symbol": "LOTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27688/thumb/LOTT.jpg?1665217304"
        },
        {
          "chainId": 1,
          "address": "0x74faab6986560fd1140508e4266d8a7b87274ffd",
          "name": "HyperDAO",
          "symbol": "HDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10780/thumb/B7-ppPfE_400x400.png?1583467291"
        },
        {
          "chainId": 1,
          "address": "0x53884b61963351c283118a8e1fc05ba464a11959",
          "name": "Monnos",
          "symbol": "MNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11640/thumb/logo_%2880%29.png?1592274153"
        },
        {
          "chainId": 1,
          "address": "0x2fe39f22eac6d3c1c86dd9d143640ebb94609fce",
          "name": "JD Coin",
          "symbol": "JDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9958/thumb/Cv-2ca6p_400x400.jpg?1575926305"
        },
        {
          "chainId": 1,
          "address": "0x9c5673002d854de18e9f6feb7f9825f88c5bf1e4",
          "name": "Terraform DAO",
          "symbol": "TERRAFORM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26742/thumb/tf-logo-200x200.png?1660005166"
        },
        {
          "chainId": 1,
          "address": "0xc6cc3d07c705e39d11c7f60d8836c7c78d4ac5f1",
          "name": "Olea Token",
          "symbol": "OLEA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29056/thumb/symbol.png?1681374382"
        },
        {
          "chainId": 1,
          "address": "0xfe459828c90c0ba4bc8b42f5c5d44f316700b430",
          "name": "BBS Network",
          "symbol": "BBS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23715/thumb/Ni13Pg1K_400x400.jpg?1645110585"
        },
        {
          "chainId": 1,
          "address": "0x83e9f223e1edb3486f876ee888d76bfba26c475a",
          "name": "BlockchainSpace",
          "symbol": "GUILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21271/thumb/BednjMw.png?1638857799"
        },
        {
          "chainId": 1,
          "address": "0x24e89bdf2f65326b94e36978a7edeac63623dafa",
          "name": "Tiger King Coin",
          "symbol": "TKING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15605/thumb/tigerking.png?1649149229"
        },
        {
          "chainId": 1,
          "address": "0x8bbe1a2961b41340468d0548c2cd5b7dfa9b684c",
          "name": "Handy",
          "symbol": "HANDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21944/thumb/Handy_Token_symbol-01.png?1640313628"
        },
        {
          "chainId": 1,
          "address": "0xcdeee767bed58c5325f68500115d4b722b3724ee",
          "name": "Carbon",
          "symbol": "CRBN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13262/thumb/carbon.png?1662693418"
        },
        {
          "chainId": 1,
          "address": "0xd75c5aa683485780940cf0f78c08aac051e5573d",
          "name": "Awoke",
          "symbol": "AWOKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31029/thumb/200.png?1689838678"
        },
        {
          "chainId": 1,
          "address": "0xcbee6459728019cb1f2bb971dde2ee3271bc7617",
          "name": "WemergeToken",
          "symbol": "MRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6833/thumb/wemerge200.png?1547043144"
        },
        {
          "chainId": 1,
          "address": "0x428dca9537116148616a5a3e44035af17238fe9d",
          "name": "OxAI com",
          "symbol": "OXAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28992/thumb/oxai-logo-200.png?1675821031"
        },
        {
          "chainId": 1,
          "address": "0xe76c6c83af64e4c60245d8c7de953df673a7a33d",
          "name": "Railgun",
          "symbol": "RAIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775"
        },
        {
          "chainId": 1,
          "address": "0x8af78f0c818302164f73b2365fe152c2d1fe80e1",
          "name": "Financie Token",
          "symbol": "FNCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29099/thumb/VcJQzCWT_400x400.jpg?1676596960"
        },
        {
          "chainId": 1,
          "address": "0xb39185e33e8c28e0bb3dbbce24da5dea6379ae91",
          "name": "PHUNK Vault  NFTX ",
          "symbol": "PHUNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17074/thumb/Phunks.png?1626152056"
        },
        {
          "chainId": 1,
          "address": "0x2c2f7e7c5604d162d75641256b80f1bf6f4dc796",
          "name": "Polkarare",
          "symbol": "PRARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15388/thumb/Image_from_iOS.png?1621145396"
        },
        {
          "chainId": 1,
          "address": "0x6f2dec5da475333b0af4a3ffc9a33b0211a9a452",
          "name": "CryptoTwitter",
          "symbol": "CT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30237/thumb/200x200px.png?1683686670"
        },
        {
          "chainId": 1,
          "address": "0x227c7df69d3ed1ae7574a1a7685fded90292eb48",
          "name": "Milady Vault  NFTX ",
          "symbol": "MILADY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26540/thumb/256x256.png?1658730893"
        },
        {
          "chainId": 1,
          "address": "0x1e9d0bb190ac34492aa11b80d28c1c86487a341f",
          "name": "The Neko",
          "symbol": "NEKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22692/thumb/FL6SU9b5_400x400.jpg?1642409749"
        },
        {
          "chainId": 1,
          "address": "0x048aa6e69d5117bd665af4128383a6982b8367b3",
          "name": "VoxNET",
          "symbol": "VXON",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/28369/thumb/VoxNETlogo.png?1669947735"
        },
        {
          "chainId": 1,
          "address": "0x3c8d2fce49906e11e71cb16fa0ffeb2b16c29638",
          "name": "Nifty League",
          "symbol": "NFTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19224/thumb/NFTL-256x256.png?1634713638"
        },
        {
          "chainId": 1,
          "address": "0x51395ade06eae126f590e7b06dc8f6baf511f13f",
          "name": "hiSQUIGGLE",
          "symbol": "HISQUIGGLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28127/thumb/hisquiggle.png?1667641238"
        },
        {
          "chainId": 1,
          "address": "0x220b71671b649c03714da9c621285943f3cbcdc6",
          "name": "TosDis",
          "symbol": "DIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png?1611379744"
        },
        {
          "chainId": 1,
          "address": "0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8",
          "name": "KIRA Network",
          "symbol": "KEX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13232/thumb/avatar.png?1622601779"
        },
        {
          "chainId": 1,
          "address": "0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28",
          "name": "Badger Sett Badger",
          "symbol": "BBADGER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14502/thumb/BADGER_DAO.png?1616559959"
        },
        {
          "chainId": 1,
          "address": "0x0cdf9acd87e940837ff21bb40c9fd55f68bba059",
          "name": "Public Mint",
          "symbol": "MINT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14106/thumb/Public_Mint_Icon_200x200.png?1614333027"
        },
        {
          "chainId": 1,
          "address": "0xe530441f4f73bdb6dc2fa5af7c3fc5fd551ec838",
          "name": "GSENetwork",
          "symbol": "GSE",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/4776/thumb/gsenetwork.png?1547743285"
        },
        {
          "chainId": 1,
          "address": "0x15f0eedf9ce24fc4b6826e590a8292ce5524a1da",
          "name": "Decentralized Nations",
          "symbol": "DENA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22167/thumb/denations.PNG?1641166526"
        },
        {
          "chainId": 1,
          "address": "0x4da0c48376c277cdbd7fc6fdc6936dee3e4adf75",
          "name": "Epik Prime",
          "symbol": "EPIK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg?1630738458"
        },
        {
          "chainId": 1,
          "address": "0x59276455177429ae2af1cc62b77ae31b34ec3890",
          "name": "Sturdy",
          "symbol": "STRDY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30581/thumb/SF-Dark-Logomark.png?1685417067"
        },
        {
          "chainId": 1,
          "address": "0xc4ee0aa2d993ca7c9263ecfa26c6f7e13009d2b6",
          "name": "Hoichi",
          "symbol": "HOICHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27227/thumb/3dBs4VvW_400x400.jpeg?1662711951"
        },
        {
          "chainId": 1,
          "address": "0xc237868a9c5729bdf3173dddacaa336a0a5bb6e0",
          "name": "Wagerr",
          "symbol": "WGR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241"
        },
        {
          "chainId": 1,
          "address": "0x009178997aff09a67d4caccfeb897fb79d036214",
          "name": "1Sol",
          "symbol": "1SOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21615/thumb/YyGDie9f_400x400.jpg?1639610633"
        },
        {
          "chainId": 1,
          "address": "0xbd1848e1491d4308ad18287a745dd4db2a4bd55b",
          "name": "Mochi Market",
          "symbol": "MOMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399"
        },
        {
          "chainId": 1,
          "address": "0xbbff34e47e559ef680067a6b1c980639eeb64d24",
          "name": "Leverj Gluon",
          "symbol": "L2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12950/thumb/Gluon256x256.png?1604048379"
        },
        {
          "chainId": 1,
          "address": "0xf063fe1ab7a291c5d06a86e14730b00bf24cb589",
          "name": "DxSale Network",
          "symbol": "SALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12250/thumb/dx-light.png?1613965390"
        },
        {
          "chainId": 1,
          "address": "0x207e14389183a94343942de7afbc607f57460618",
          "name": "AngelBlock",
          "symbol": "THOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25058/thumb/Tholos_circle_gradient_200x200.png?1681725391"
        },
        {
          "chainId": 1,
          "address": "0xbb97e381f1d1e94ffa2a5844f6875e6146981009",
          "name": "WiBX",
          "symbol": "WBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11009/thumb/wibx_new_logo.png?1632122685"
        },
        {
          "chainId": 1,
          "address": "0x120a3879da835a5af037bb2d1456bebd6b54d4ba",
          "name": "Revest Finance",
          "symbol": "RVST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18622/thumb/Qma94n6waADECpde1ukBBS8iNiECcdVcxjfgubnWPE9ZT7.png?1632701737"
        },
        {
          "chainId": 1,
          "address": "0xf9ca9523e5b5a42c3018c62b084db8543478c400",
          "name": "Data Lake",
          "symbol": "LAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29279/thumb/VWlCfLlX_400x400.jpeg?1677738702"
        },
        {
          "chainId": 1,
          "address": "0x11f9e9b3c539368bea16dde2108b748a9672d714",
          "name": "Lint",
          "symbol": "LINT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30408/thumb/200_lint.jpg?1684385454"
        },
        {
          "chainId": 1,
          "address": "0x39fbbabf11738317a448031930706cd3e612e1b9",
          "name": "Wrapped XRP",
          "symbol": "WXRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19952/thumb/WXRP_revision-256px.png?1648530664"
        },
        {
          "chainId": 1,
          "address": "0xf0939011a9bb95c3b791f0cb546377ed2693a574",
          "name": "0 exchange",
          "symbol": "ZERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530"
        },
        {
          "chainId": 1,
          "address": "0xaf691508ba57d416f895e32a1616da1024e882d2",
          "name": "Pinknode",
          "symbol": "PNODE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15469/thumb/icon-Logo-pink.png?1644476523"
        },
        {
          "chainId": 1,
          "address": "0x64a77277e37d44957fe5815d6ff442ab8b16cc29",
          "name": "SpaceDawgs",
          "symbol": "DAWGS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png?1646792743"
        },
        {
          "chainId": 1,
          "address": "0x20945ca1df56d237fd40036d47e866c7dccd2114",
          "name": "Nsure Network",
          "symbol": "NSURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12948/thumb/Nsure_token.png?1603778876"
        },
        {
          "chainId": 1,
          "address": "0xf1d1a5306daae314af6c5d027a492b313e07e1a0",
          "name": "Envoy",
          "symbol": "ENV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19631/thumb/env.png?1638148461"
        },
        {
          "chainId": 1,
          "address": "0x188e817b02e635d482ae4d81e25dda98a97c4a42",
          "name": "Lithium Finance",
          "symbol": "LITH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17278/thumb/Lithium_Logo-03200x200.png?1627018557"
        },
        {
          "chainId": 1,
          "address": "0x5ca135cb8527d76e932f34b5145575f9d8cbe08e",
          "name": "Frax Price Index",
          "symbol": "FPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24945/thumb/FPI_icon.png?1679886853"
        },
        {
          "chainId": 1,
          "address": "0x725c263e32c72ddc3a19bea12c5a0479a81ee688",
          "name": "Bridge Mutual",
          "symbol": "BMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13808/thumb/bmi_logo.png?1612009598"
        },
        {
          "chainId": 1,
          "address": "0x9b01637302b6adfc2c82678e2a8d680cff6337b7",
          "name": "Fit",
          "symbol": "FIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30918/thumb/FIT_Token_1000.png?1688956436"
        },
        {
          "chainId": 1,
          "address": "0xb8366948b4a3f07bcbf14eb1739daa42a26b07c4",
          "name": "VALOBIT",
          "symbol": "VBIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12394/thumb/Untitled-design-3.png?1599550715"
        },
        {
          "chainId": 1,
          "address": "0x06e0feb0d74106c7ada8497754074d222ec6bcdf",
          "name": "Bitball",
          "symbol": "BTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7039/thumb/4JaJQEx.png?1677635732"
        },
        {
          "chainId": 1,
          "address": "0x9ba00d6856a4edf4665bca2c2309936572473b7e",
          "name": "Aave USDC v1",
          "symbol": "AUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/11674/thumb/aUSDC.png?1592546449"
        },
        {
          "chainId": 1,
          "address": "0xae1eaae3f627aaca434127644371b67b18444051",
          "name": "Yield Optimization Platform   Protocol",
          "symbol": "YOP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13678/thumb/J7zykPx.jpg?1610802162"
        },
        {
          "chainId": 1,
          "address": "0x3c6a7ab47b5f058be0e7c7fe1a4b7925b8aca40e",
          "name": "Cajutel",
          "symbol": "CAJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7354/thumb/RuHhMsFd_400x400.png?1547044031"
        },
        {
          "chainId": 1,
          "address": "0x3175df0976dfa876431c2e9ee6bc45b65d3473cc",
          "name": "Curve fi FRAX USDC",
          "symbol": "CRVFRAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26829/thumb/W1sQNVWo_400x400.jpeg?1660294792"
        },
        {
          "chainId": 1,
          "address": "0x0001a500a6b18995b03f44bb040a5ffc28e45cb0",
          "name": "Autonolas",
          "symbol": "OLAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31099/thumb/OLAS-token.png?1690454175"
        },
        {
          "chainId": 1,
          "address": "0x89fb927240750c1b15d4743cd58440fc5f14a11c",
          "name": "Attila",
          "symbol": "ATT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11337/thumb/LOGO_%2874%29.png?1589942265"
        },
        {
          "chainId": 1,
          "address": "0x6e605c269e0c92e70beeb85486f1fc550f9380bd",
          "name": "Catex",
          "symbol": "CATT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6353/thumb/catex.png?1548733307"
        },
        {
          "chainId": 1,
          "address": "0x8db253a1943dddf1af9bcf8706ac9a0ce939d922",
          "name": "Unbound Finance",
          "symbol": "UNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21412/thumb/Unbound_icon_png.png?1639091708"
        },
        {
          "chainId": 1,
          "address": "0x8f0921f30555624143d427b340b1156914882c10",
          "name": "FlypMe",
          "symbol": "FYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1098/thumb/flypme.png?1548126453"
        },
        {
          "chainId": 1,
          "address": "0xb2ee0adbe0ef1281025d0676511bb1df14600f4d",
          "name": "FORE Protocol",
          "symbol": "FORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31001/thumb/EXCHANGE_LOGO_FORE.png?1689591906"
        },
        {
          "chainId": 1,
          "address": "0x3e7804c51a70ba26e904c2e0ab440c5623a8a83f",
          "name": "GPEX",
          "symbol": "GPX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/22584/thumb/GPEX-E_200.png?1642142068"
        },
        {
          "chainId": 1,
          "address": "0x5380442d3c4ec4f5777f551f5edd2fa0f691a27c",
          "name": "UkraineDAO Flag NFT",
          "symbol": "LOVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24092/thumb/download_%282%29.png?1646344453"
        },
        {
          "chainId": 1,
          "address": "0x5caf454ba92e6f2c929df14667ee360ed9fd5b26",
          "name": "Dev Protocol",
          "symbol": "DEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11560/thumb/Dev_Protocol__CoinGecko_Logo___Jan.18.2021_.png?1611021474"
        },
        {
          "chainId": 1,
          "address": "0x9c0bd34bebc33a0e898554cfc91e8a84c728bf9f",
          "name": "Pisscoin",
          "symbol": "PISS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30459/thumb/A7661040-68D2-4753-B20E-B7416A7F90C2.png?1684656998"
        },
        {
          "chainId": 1,
          "address": "0x3757232b55e60da4a8793183ac030cfce4c3865d",
          "name": "YDragon",
          "symbol": "YDR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17807/thumb/icon.png?1629302175"
        },
        {
          "chainId": 1,
          "address": "0x881145e5a28b6411cb80d0350497217f549b050c",
          "name": "Farmer Moe",
          "symbol": "MOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30718/thumb/2scpsxE8_400x400.jpg?1686560096"
        },
        {
          "chainId": 1,
          "address": "0x40d2025ed2e89632d3a41d8541df9ed2ac0e2b1c",
          "name": "Connectico",
          "symbol": "CON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22853/thumb/9005.png?1642750464"
        },
        {
          "chainId": 1,
          "address": "0x49bf0220c9ce17e52dcca3d217231746d676085b",
          "name": "Vixco",
          "symbol": "VIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15405/thumb/vixco.png?1620811916"
        },
        {
          "chainId": 1,
          "address": "0x14d4c7a788908fbbbd3c1a4bac4aff86fe1573eb",
          "name": "Tairyo Inu",
          "symbol": "TAIRYO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30090/thumb/IMG_20230429_121425_856.jpg?1683019767"
        },
        {
          "chainId": 1,
          "address": "0xa9598333b99d14d90bc81cad8af82c4c70625e75",
          "name": "Metis MTS",
          "symbol": "MTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13218/thumb/1Vd_NuQU_400x400.jpg?1606292031"
        },
        {
          "chainId": 1,
          "address": "0x9b68bfae21df5a510931a262cecf63f41338f264",
          "name": "DecentBet",
          "symbol": "DBET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1168/thumb/decent-bet.jpg?1547035195"
        },
        {
          "chainId": 1,
          "address": "0x9b62ec1453cea5dde760aaf662048ca6eeb66e7f",
          "name": "Consensus Cell Network",
          "symbol": "ECELL",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/12624/thumb/ecell_logo_128.png?1643188699"
        },
        {
          "chainId": 1,
          "address": "0x76c81e322fe678f9391029d571453fad9bc9e73e",
          "name": "EdFi",
          "symbol": "EDFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30363/thumb/EDFI_LOGO_200X200_Black_BG_%281%29.png?1684252086"
        },
        {
          "chainId": 1,
          "address": "0x436da116249044e8b4464f0cf21dd93311d88190",
          "name": "Colizeum",
          "symbol": "ZEUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24448/thumb/AB0cGpnx_400x400.jpg?1647681843"
        },
        {
          "chainId": 1,
          "address": "0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4",
          "name": "TE FOOD",
          "symbol": "TONE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2325/thumb/tec.png?1547036538"
        },
        {
          "chainId": 1,
          "address": "0x624d520bab2e4ad83935fa503fb130614374e850",
          "name": "Smartshare",
          "symbol": "SSP",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/4642/thumb/smartshare.png?1548609894"
        },
        {
          "chainId": 1,
          "address": "0xa1a36d3537bbe375cc9694795f663ddc8d516db9",
          "name": "Polinate",
          "symbol": "POLI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18096/thumb/Polinate_Games___Guilds_Elements-05.png?1648806645"
        },
        {
          "chainId": 1,
          "address": "0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d",
          "name": "Kine Protocol",
          "symbol": "KINE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14339/thumb/kine.jpg?1615474456"
        },
        {
          "chainId": 1,
          "address": "0x434cb4fc4b952872967914d430878eee53ebd502",
          "name": "ALLPAYCOIN",
          "symbol": "APCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26836/thumb/apcg.png?1660299504"
        },
        {
          "chainId": 1,
          "address": "0x33f391f4c4fe802b70b77ae37670037a92114a7c",
          "name": "Burp",
          "symbol": "BURP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17317/thumb/Big_Town_Chef_-__BURP_-_Avatar_3x.png?1646202255"
        },
        {
          "chainId": 1,
          "address": "0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
          "name": "Tokemak",
          "symbol": "TOKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17495/thumb/tokemak-avatar-200px-black.png?1628131614"
        },
        {
          "chainId": 1,
          "address": "0xe6d2c3cb986db66818c14c7032db05d1d2a6ee74",
          "name": "Finexbox",
          "symbol": "FNB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8419/thumb/p1WP-viw_400x400.jpg?1558395663"
        },
        {
          "chainId": 1,
          "address": "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4",
          "name": "cCOMP",
          "symbol": "CCOMP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12840/thumb/1_z8UrVtod3bme4-J_pXAQQA_2x.png?1602936322"
        },
        {
          "chainId": 1,
          "address": "0x3f5294df68f871241c4b18fcf78ebd8ac18ab654",
          "name": "99Starz",
          "symbol": "STZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21467/thumb/stz.png?1639651623"
        },
        {
          "chainId": 1,
          "address": "0xd81b71cbb89b2800cdb000aa277dc1491dc923c3",
          "name": "NFTMart",
          "symbol": "NMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15811/thumb/NFTMART.png?1624962351"
        },
        {
          "chainId": 1,
          "address": "0x5d3a4f62124498092ce665f865e0b38ff6f5fbea",
          "name": "Ideaology",
          "symbol": "IDEA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13938/thumb/idea_logo.png?1613022658"
        },
        {
          "chainId": 1,
          "address": "0x3fa729b4548becbad4eab6ef18413470e6d5324c",
          "name": "Mover",
          "symbol": "MOVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13719/thumb/o0KIvs7T_400x400.jpg?1617672818"
        },
        {
          "chainId": 1,
          "address": "0xb69d946d8cdc5238b0658188e1babb41b8652a29",
          "name": "Bet2Bank",
          "symbol": "BXB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29093/thumb/Bet2Bank.png?1676528256"
        },
        {
          "chainId": 1,
          "address": "0x3136ef851592acf49ca4c825131e364170fa32b3",
          "name": "CoinFi",
          "symbol": "COFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1709/thumb/coinfi.png?1547035969"
        },
        {
          "chainId": 1,
          "address": "0x1a6658f40e51b372e593b7d2144c1402d5cf33e8",
          "name": "PUBLC",
          "symbol": "PUBLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29074/thumb/PUBLX_Logo_%28200x200%29.png?1676432730"
        },
        {
          "chainId": 1,
          "address": "0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31",
          "name": "Voxel X Network",
          "symbol": "VXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21419/thumb/Voxel-Logo-200x200-Transparent.png?1639093664"
        },
        {
          "chainId": 1,
          "address": "0x1892f6ff5fbe11c31158f8c6f6f6e33106c5b10e",
          "name": "MegaWorld",
          "symbol": "MEGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28456/thumb/Twitter_AVA1.jpg?1670810440"
        },
        {
          "chainId": 1,
          "address": "0x1a57367c6194199e5d9aea1ce027431682dfb411",
          "name": "MatrixETF",
          "symbol": "MDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18538/thumb/MDF.png?1632304949"
        },
        {
          "chainId": 1,
          "address": "0x5b685863494c33f344081f75e5430c260c224a32",
          "name": "CORE MultiChain",
          "symbol": "CMCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png?1633590798"
        },
        {
          "chainId": 1,
          "address": "0xe796d6ca1ceb1b022ece5296226bf784110031cd",
          "name": "Blind Boxes",
          "symbol": "BLES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14537/thumb/BLES-Logo-BW.png?1637158078"
        },
        {
          "chainId": 1,
          "address": "0x00281dfce4cfd72c0b6fda2aaaf077258743f9e8",
          "name": "NuriFootBall",
          "symbol": "NRFB",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/23360/thumb/pcMlP25e_400x400.jpg?1643945452"
        },
        {
          "chainId": 1,
          "address": "0x045109cf1be9edec048aa0b3d7a323154a1aea65",
          "name": "Elevate Token",
          "symbol": "ELEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30085/thumb/elevate.jpeg?1683017635"
        },
        {
          "chainId": 1,
          "address": "0x513c3200f227ebb62e3b3d00b7a83779643a71cf",
          "name": "Uplift",
          "symbol": "LIFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png?1657675050"
        },
        {
          "chainId": 1,
          "address": "0x3656bd0f3f07623bb7f429b390d208f894e44ece",
          "name": "INFLIV",
          "symbol": "IFV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5279/thumb/INFLIV_LOGO_PNG.png?1550657927"
        },
        {
          "chainId": 1,
          "address": "0xef952363c1d990a2fa58f8b379a9fa33bad1dfd1",
          "name": "LynKey",
          "symbol": "LYNK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25069/thumb/oOcTsBsg_400x400_%281%29.jpg?1650038695"
        },
        {
          "chainId": 1,
          "address": "0x674c964ac0e89d847d6b0abd144b797bf78ba56b",
          "name": "GreenWorld",
          "symbol": "GWD",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/27771/thumb/200x200logo.png?1665737292"
        },
        {
          "chainId": 1,
          "address": "0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b",
          "name": "Jarvis Synthetic Euro",
          "symbol": "JEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png?1634046044"
        },
        {
          "chainId": 1,
          "address": "0xf725f73caee250ae384ec38bb2c77c38ef2cccea",
          "name": "Ape In Records",
          "symbol": "AIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24194/thumb/gCZZdeR.jpeg?1646830675"
        },
        {
          "chainId": 1,
          "address": "0x5aaefe84e0fb3dd1f0fcff6fa7468124986b91bd",
          "name": "Evedo",
          "symbol": "EVED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7721/thumb/Variations-09.png?1549979992"
        },
        {
          "chainId": 1,
          "address": "0x515d7e9d75e2b76db60f8a051cd890eba23286bc",
          "name": "Governor DAO",
          "symbol": "GDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13140/thumb/GDAOlogo2-bird.png?1605591842"
        },
        {
          "chainId": 1,
          "address": "0xff75ced57419bcaebe5f05254983b013b0646ef5",
          "name": "Cook",
          "symbol": "COOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14603/thumb/logo-200x200.jpg?1622448330"
        },
        {
          "chainId": 1,
          "address": "0xe83d5fb2c60b3a2597452e248cf7b2f52a7e731e",
          "name": "ARTIC Foundation",
          "symbol": "ARTIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24279/thumb/Artic_logo_02.png?1647181452"
        },
        {
          "chainId": 1,
          "address": "0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1",
          "name": "CoFiX",
          "symbol": "COFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12781/thumb/dnPnSkfa_400x400.png?1602885321"
        },
        {
          "chainId": 1,
          "address": "0x963035453633f6f7433032c958f82eb3043d8cd2",
          "name": "USD Freedom",
          "symbol": "USDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26920/thumb/USDF_200x200.png?1660815930"
        },
        {
          "chainId": 1,
          "address": "0x987441856ba4f463544fc68ccbf6a80f434a7956",
          "name": "NOWAI",
          "symbol": "NOWAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29177/thumb/NOWAI_%282%29.png?1677141368"
        },
        {
          "chainId": 1,
          "address": "0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6",
          "name": "Abyss",
          "symbol": "ABYSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2105/thumb/NrFmpxs.png?1600318377"
        },
        {
          "chainId": 1,
          "address": "0xdac657ffd44a3b9d8aba8749830bf14beb66ff2d",
          "name": "humanDAO",
          "symbol": "HDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21138/thumb/Untitled-2.jpg?1645163749"
        },
        {
          "chainId": 1,
          "address": "0x425d105913a04f4f95311788195ba13c3e82c3b9",
          "name": "Apollo Token",
          "symbol": "APOLLO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29861/thumb/Apollo.jpeg?1681876695"
        },
        {
          "chainId": 1,
          "address": "0x465a5a630482f3abd6d3b84b39b29b07214d19e5",
          "name": "Flux USDC",
          "symbol": "FUSDC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29051/thumb/fUSDC_200x200.png?1676335208"
        },
        {
          "chainId": 1,
          "address": "0x9f52c8ecbee10e00d9faaac5ee9ba0ff6550f511",
          "name": "Sipher",
          "symbol": "SIPHER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21070/thumb/SipherToken.png?1638312272"
        },
        {
          "chainId": 1,
          "address": "0x9dfad1b7102d46b1b197b90095b5c4e9f5845bba",
          "name": "Botto",
          "symbol": "BOTTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18892/thumb/bottos_logo.jpg?1633791571"
        },
        {
          "chainId": 1,
          "address": "0xf59ae934f6fe444afc309586cc60a84a0f89aaea",
          "name": "Polkadex",
          "symbol": "PDEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14833/thumb/dIze5Ztl_400x400.jpg?1618610724"
        },
        {
          "chainId": 1,
          "address": "0xbfd815347d024f449886c171f78fa5b8e6790811",
          "name": "AMPnet",
          "symbol": "AAPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14303/thumb/AAPX.png?1615356946"
        },
        {
          "chainId": 1,
          "address": "0x0018d5e01e53878f90feab02f1b2019a21adf8b1",
          "name": "Shadowcats",
          "symbol": "SHADOWCATS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29140/thumb/Shadowcats.png?1676963378"
        },
        {
          "chainId": 1,
          "address": "0x40284109c3309a7c3439111bfd93bf5e0fbb706c",
          "name": "MOTIV Protocol",
          "symbol": "MOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11869/thumb/jvftnBL-_400x400.jpg?1595498693"
        },
        {
          "chainId": 1,
          "address": "0x368c5290b13caa10284db58b4ad4f3e9ee8bf4c9",
          "name": "KKO Protocol",
          "symbol": "KKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15366/thumb/kko-coingecko.png?1658982821"
        },
        {
          "chainId": 1,
          "address": "0x4ebe70cb942d5af0a18b9126762637e7098ff5fd",
          "name": "G Revolution",
          "symbol": "G",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30869/thumb/G_logo.png?1688373006"
        },
        {
          "chainId": 1,
          "address": "0xd075e95423c5c4ba1e122cae0f4cdfa19b82881b",
          "name": "OPES  Wrapped PE ",
          "symbol": "WPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16647/thumb/opes.png?1625834316"
        },
        {
          "chainId": 1,
          "address": "0xc813ea5e3b48bebeedb796ab42a30c5599b01740",
          "name": "Autonio",
          "symbol": "NIOX",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/1122/thumb/NewLogo.png?1597298450"
        },
        {
          "chainId": 1,
          "address": "0x6524b87960c2d573ae514fd4181777e7842435d4",
          "name": "Benzene",
          "symbol": "BZN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17368/thumb/bzn-logo-200.png?1627437320"
        },
        {
          "chainId": 1,
          "address": "0xc12d099be31567add4e4e4d0d45691c3f58f5663",
          "name": "Auctus",
          "symbol": "AUC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355"
        },
        {
          "chainId": 1,
          "address": "0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160",
          "name": "WASSIE",
          "symbol": "WASSIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30144/thumb/wassie-coin.png?1683355291"
        },
        {
          "chainId": 1,
          "address": "0x0a58153a0cd1cfaea94ce1f7fdc5d7e679eca936",
          "name": "Internet Money  ETH ",
          "symbol": "IM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28930/thumb/IM_Circle_200.png?1682405962"
        },
        {
          "chainId": 1,
          "address": "0x9fc8f0ca1668e87294941b7f627e9c15ea06b459",
          "name": "True PNL",
          "symbol": "PNL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png?1623230007"
        },
        {
          "chainId": 1,
          "address": "0xa4cb0dce4849bdcad2d553e9e68644cf40e26cce",
          "name": "Baked",
          "symbol": "BAKED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19178/thumb/rebaked-logo-full.png?1634613132"
        },
        {
          "chainId": 1,
          "address": "0x3bf954e809620bf2f1fcb667f1c7d2d2e94350d1",
          "name": "Vision City",
          "symbol": "VIZ",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30614/thumb/logo.png?1686297504"
        },
        {
          "chainId": 1,
          "address": "0x765f0c16d1ddc279295c1a7c24b0883f62d33f75",
          "name": "DaTa eXchange DTX",
          "symbol": "DTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2920/thumb/dtx.png?1578973961"
        },
        {
          "chainId": 1,
          "address": "0xef53462838000184f35f7d991452e5f25110b207",
          "name": "Knit Finance",
          "symbol": "KFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg?1621396114"
        },
        {
          "chainId": 1,
          "address": "0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a",
          "name": "Yearn Compounding veCRV yVault",
          "symbol": "YVBOOST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15152/thumb/yvBOOST.png?1619978216"
        },
        {
          "chainId": 1,
          "address": "0x675ababd3a210566a5e213547523b740be80041a",
          "name": "Entropy",
          "symbol": "ENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30102/thumb/Entropy_bi_symbol_200x200.png?1684827717"
        },
        {
          "chainId": 1,
          "address": "0xf058501585023d040ea9493134ed72c083553eed",
          "name": "Dymmax",
          "symbol": "DMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13476/thumb/dmmx.png?1608934713"
        },
        {
          "chainId": 1,
          "address": "0xb5c578947de0fd71303f71f2c3d41767438bd0de",
          "name": "DeHorizon",
          "symbol": "DEVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21373/thumb/A3RjDqet_400x400.jpg?1639031847"
        },
        {
          "chainId": 1,
          "address": "0x7cca2e1c9b0519f52029467914a15e782bf66971",
          "name": "Captain Inu",
          "symbol": "CPTINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21600/thumb/200x200-transparent-logo.png?1639548692"
        },
        {
          "chainId": 1,
          "address": "0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6",
          "name": "Ripio Credit Network",
          "symbol": "RCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1057/thumb/ripio-credit-network.png?1548608361"
        },
        {
          "chainId": 1,
          "address": "0x703293d32aea894cca91d6c282db9efffe47c308",
          "name": "Aerie",
          "symbol": "AER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30977/thumb/icon.png?1689310646"
        },
        {
          "chainId": 1,
          "address": "0x4e352cf164e64adcbad318c3a1e222e9eba4ce42",
          "name": "MUX Protocol",
          "symbol": "MCB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11796/thumb/mux.jpg?1660125796"
        },
        {
          "chainId": 1,
          "address": "0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af",
          "name": "Blockchain Cuties Universe Governance",
          "symbol": "BCUG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14425/thumb/bcug_logo.png?1616022820"
        },
        {
          "chainId": 1,
          "address": "0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2",
          "name": "mStable Governance  Meta",
          "symbol": "MTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11846/thumb/mStable.png?1594950533"
        },
        {
          "chainId": 1,
          "address": "0x19062190b1925b5b6689d7073fdfc8c2976ef8cb",
          "name": "Swarm",
          "symbol": "BZZ",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/16509/thumb/Circle_Orange_onWhite.png?1675184920"
        },
        {
          "chainId": 1,
          "address": "0x45448e05020576929fcdeabc228e35b420098840",
          "name": "Idavoll DAO",
          "symbol": "IDV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14229/thumb/71922199.png?1615169236"
        },
        {
          "chainId": 1,
          "address": "0x580c8520deda0a441522aeae0f9f7a5f29629afa",
          "name": "Dawn Protocol",
          "symbol": "DAWN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11555/thumb/dawn_protocol.png?1591060256"
        },
        {
          "chainId": 1,
          "address": "0x5afff9876c1f98b7d2b53bcb69eb57e92408319f",
          "name": "metavisa",
          "symbol": "MESA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23246/thumb/twitteravatar_circle.png?1653551813"
        },
        {
          "chainId": 1,
          "address": "0x0eb3032bcac2be1fa95e296442f225edb80fc3cd",
          "name": "Aster",
          "symbol": "ATC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17325/thumb/atc.png?1627311439"
        },
        {
          "chainId": 1,
          "address": "0xe29f1241e6015a4949cb7e3f07234ba794006181",
          "name": "BIGCAP",
          "symbol": "BIGCAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29708/thumb/BIGCAP_Logo.png?1680766494"
        },
        {
          "chainId": 1,
          "address": "0xf98ab0874b13a7fdc39d7295dedd49850a5d426b",
          "name": "KIRA",
          "symbol": "KIRA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28196/thumb/KIRA_CMC.png?1668334302"
        },
        {
          "chainId": 1,
          "address": "0x1a3cbda3853494acab67648ee59afeb7ec3e9334",
          "name": "Collateral Network",
          "symbol": "COLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29770/thumb/IMG_20230407_101929_020.png?1681202760"
        },
        {
          "chainId": 1,
          "address": "0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5",
          "name": "Notional Finance",
          "symbol": "NOTE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20282/thumb/NOTE-340x340.png?1636821924"
        },
        {
          "chainId": 1,
          "address": "0xde5ea375ffbdc8b25a80fe13d631e8ba0ab4bb02",
          "name": "Gera Coin",
          "symbol": "GERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13686/thumb/GeraCoin_Logo-icon-1000px.png?1610919942"
        },
        {
          "chainId": 1,
          "address": "0x1b073382e63411e3bcffe90ac1b9a43fefa1ec6f",
          "name": "Bitpanda Ecosystem",
          "symbol": "BEST",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8738/thumb/BEST-Coin-Logo.png?1619079782"
        },
        {
          "chainId": 1,
          "address": "0x5b322514ff727253292637d9054301600c2c81e8",
          "name": "DAD",
          "symbol": "DAD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/9833/thumb/q_Vt0ajV_400x400.jpg?1572475136"
        },
        {
          "chainId": 1,
          "address": "0x29ceddcf0da3c1d8068a7dfbd0fb06c2e438ff70",
          "name": "Freela",
          "symbol": "FREL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15856/thumb/freela.PNG?1622164474"
        },
        {
          "chainId": 1,
          "address": "0x1d6405138a335ce5fd7364086334efb3e4f28b59",
          "name": "ClearCryptos",
          "symbol": "CCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27900/thumb/3e88b61451995da1a5aa572cd4f48e78023e010c0bf984cd5456b2a7bd85f7a5.png?1666322609"
        },
        {
          "chainId": 1,
          "address": "0x5f944b0c4315cb7c3a846b025ab4045da44abf6c",
          "name": "Pancake Games",
          "symbol": "GCAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png?1636671365"
        },
        {
          "chainId": 1,
          "address": "0xdc98c5543f3004debfaad8966ec403093d0aa4a8",
          "name": "Etherrock  72",
          "symbol": "PEBBLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17963/thumb/Screen-Shot-2021-08-25-at-8-42-22-PM.png?1629943788"
        },
        {
          "chainId": 1,
          "address": "0x8610632b514761597d91ecf20f485cd8163a3033",
          "name": "Betted",
          "symbol": "BET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29287/thumb/ficha_icon_CG.png?1677807812"
        },
        {
          "chainId": 1,
          "address": "0xfd6c31bb6f05fc8db64f4b740ab758605c271fd8",
          "name": "Contracoin",
          "symbol": "CTCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10801/thumb/Contracoin-symbol.png?1583881685"
        },
        {
          "chainId": 1,
          "address": "0xfa99a87b14b02e2240c79240c5a20f945ca5ef76",
          "name": "GG",
          "symbol": "GGTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13666/thumb/ggtk.png?1629348879"
        },
        {
          "chainId": 1,
          "address": "0xa1a4e303e9c56962f201c5e834abc1e677a3c4f3",
          "name": "CVNX",
          "symbol": "CVNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/862/thumb/CVNX-logo.png?1649528798"
        },
        {
          "chainId": 1,
          "address": "0x6361f338ab8def2af3f2a1be7bd8a7db3156f7e7",
          "name": "Probably Nothing",
          "symbol": "PRBLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20193/thumb/pn.png?1645338665"
        },
        {
          "chainId": 1,
          "address": "0xbe1dbe6741fb988fb571ab1e28cffb36e3c62629",
          "name": "Massive Protocol",
          "symbol": "MAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25134/thumb/logo.png?1650431353"
        },
        {
          "chainId": 1,
          "address": "0x04b77a1be2981c1ca353aaf251b0f11398413bfa",
          "name": "Doomer AI",
          "symbol": "DOOMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29918/thumb/doomer200x200.png?1682050698"
        },
        {
          "chainId": 1,
          "address": "0x7dbbcae15d4db168e01673400d7844870cc1e36f",
          "name": "WOLFY",
          "symbol": "WOLFY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15742/thumb/Wofly.png?1621761884"
        },
        {
          "chainId": 1,
          "address": "0xed0439eacf4c4965ae4613d77a5c2efe10e5f183",
          "name": "Niftyx Protocol",
          "symbol": "SHROOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12341/thumb/niftyx_logo.jpg?1617784430"
        },
        {
          "chainId": 1,
          "address": "0x5e031a5bc1c6b6e86a49e0b0f2e757800f1d0fff",
          "name": "DaFIN",
          "symbol": "DAF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17378/thumb/dafin.PNG?1627454158"
        },
        {
          "chainId": 1,
          "address": "0x1a16b2b93b37a7ef5cb0a4f978ced45fe14a5486",
          "name": "Collar",
          "symbol": "COLLAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29459/thumb/20230316_150014.png?1679017478"
        },
        {
          "chainId": 1,
          "address": "0x8dfc8cc3201425669fae803e1eb125cddd4189ec",
          "name": "Okage Inu",
          "symbol": "OKAGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28829/thumb/Okage_Inu.png?1674784589"
        },
        {
          "chainId": 1,
          "address": "0x777e2ae845272a2f540ebf6a3d03734a5a8f618e",
          "name": "Ryoshis Vision",
          "symbol": "RYOSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17614/thumb/OFFICIAL_RYOSHI_LOGO-removebg-preview_2_200x200.png?1629794604"
        },
        {
          "chainId": 1,
          "address": "0xcbe771323587ea16dacb6016e269d7f08a7acc4e",
          "name": "Spores Network",
          "symbol": "SPO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17431/thumb/SPO_token.png?1627619762"
        },
        {
          "chainId": 1,
          "address": "0x0a913bead80f321e7ac35285ee10d9d922659cb7",
          "name": "DOS Network",
          "symbol": "DOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7991/thumb/DOS.png?1552900889"
        },
        {
          "chainId": 1,
          "address": "0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9",
          "name": "Gains",
          "symbol": "GAINS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14681/thumb/200x200.png?1677061798"
        },
        {
          "chainId": 1,
          "address": "0x72de803b67b6ab05b61efab2efdcd414d16ebf6d",
          "name": "LedgerScore",
          "symbol": "LED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16144/thumb/photo-2021-06-05-07-45-06.jpg?1623119314"
        },
        {
          "chainId": 1,
          "address": "0x7449c93abe66457e83b3799bcf1a99e92d58a93b",
          "name": "CryptMi",
          "symbol": "CYMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29032/thumb/Screen_Shot_2023-02-10_at_1.22.01_PM.png?1676094438"
        },
        {
          "chainId": 1,
          "address": "0xc1ad0aa69454603a5dee55cf9bd9341e01328544",
          "name": "hiGAZERS",
          "symbol": "HIGAZERS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28316/thumb/higazers.png?1669358592"
        },
        {
          "chainId": 1,
          "address": "0xe51b8ab09008285a0380dd2680cd9dd5e13924d3",
          "name": "BallSwap",
          "symbol": "BSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14050/thumb/bsp.png?1637556507"
        },
        {
          "chainId": 1,
          "address": "0x2942e3b38e33123965bfbc21e802be943a76bbc6",
          "name": "EHash",
          "symbol": "EHASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14180/thumb/ehash.png?1614820416"
        },
        {
          "chainId": 1,
          "address": "0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d",
          "name": "Quantstamp",
          "symbol": "QSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917"
        },
        {
          "chainId": 1,
          "address": "0x8716fc5da009d3a208f0178b637a50f4ef42400f",
          "name": "Ultrain",
          "symbol": "UGAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4684/thumb/ultrain.png?1547039956"
        },
        {
          "chainId": 1,
          "address": "0x20e7125677311fca903a8897042b9983f22ea295",
          "name": "Freeway",
          "symbol": "FWT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png?1652862323"
        },
        {
          "chainId": 1,
          "address": "0xff44b5719f0b77a9951636fc5e69d3a1fc9e7d73",
          "name": "4ART Coin",
          "symbol": "4ART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4690/thumb/0_%284%29.png?1595544690"
        },
        {
          "chainId": 1,
          "address": "0x5ac83bfbfcebb3397a40fd259dbe7a4be04237d3",
          "name": "Lyfebloc",
          "symbol": "LBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30021/thumb/LBT_Token.png?1682565005"
        },
        {
          "chainId": 1,
          "address": "0xd75f1f81b69bdd4df8efbb70e9c6f4609009d753",
          "name": "YASHA",
          "symbol": "YASHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24040/thumb/oX9E7Q7.png?1646119331"
        },
        {
          "chainId": 1,
          "address": "0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229",
          "name": "Zenfuse",
          "symbol": "ZEFU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg?1602640333"
        },
        {
          "chainId": 1,
          "address": "0x64d91f12ece7362f91a6f8e7940cd55f05060b92",
          "name": "ASH",
          "symbol": "ASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15714/thumb/omnPqaTY.png?1622820503"
        },
        {
          "chainId": 1,
          "address": "0xad996a45fd2373ed0b10efa4a8ecb9de445a4302",
          "name": "Shirtum",
          "symbol": "SHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png?1625794164"
        },
        {
          "chainId": 1,
          "address": "0x3ecab35b64345bfc472477a653e4a3abe70532d9",
          "name": "EnterButton",
          "symbol": "ENTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19677/thumb/ENTC_symbol.png?1635740712"
        },
        {
          "chainId": 1,
          "address": "0xb620be8a1949aa9532e6a3510132864ef9bc3f82",
          "name": "Lend Flare Dao",
          "symbol": "LFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24846/thumb/e5x55-kU_400x400.jpg?1649116244"
        },
        {
          "chainId": 1,
          "address": "0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577",
          "name": "YfDAI finance",
          "symbol": "YF-DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12385/thumb/1619048513068.png?1622193581"
        },
        {
          "chainId": 1,
          "address": "0x23b608675a2b2fb1890d3abbd85c5775c51691d5",
          "name": "Unisocks",
          "symbol": "SOCKS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png?1582525244"
        },
        {
          "chainId": 1,
          "address": "0x7ae1d57b58fa6411f32948314badd83583ee0e8c",
          "name": "Dope Wars Paper",
          "symbol": "PAPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18166/thumb/EQHGcBO__400x400.jpeg?1663726283"
        },
        {
          "chainId": 1,
          "address": "0xdf121180af07cb906d970799627a430d2440c36d",
          "name": "Piggy Bank",
          "symbol": "PIGGY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30594/thumb/piggy_bank_logo_png.png?1685499632"
        },
        {
          "chainId": 1,
          "address": "0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c",
          "name": "Neos Credits",
          "symbol": "NCR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17935/thumb/s4C4tVi.png?1629860208"
        },
        {
          "chainId": 1,
          "address": "0x149cac67f1cd5d80651e7c9bb359ec285d821a05",
          "name": "Minterest",
          "symbol": "MNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29455/thumb/Minterest.png?1678963801"
        },
        {
          "chainId": 1,
          "address": "0x71ab77b7dbb4fa7e017bc15090b2163221420282",
          "name": "Highstreet",
          "symbol": "HIGH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470"
        },
        {
          "chainId": 1,
          "address": "0x5d929aa919e489505ccaad8a199619c6dca0c2de",
          "name": "BaaSid",
          "symbol": "BAAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5463/thumb/baasid.png?1547041199"
        },
        {
          "chainId": 1,
          "address": "0x3a1bda28adb5b0a812a7cf10a1950c920f79bcd3",
          "name": "Gameflip",
          "symbol": "FLP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2173/thumb/flp.png?1547036470"
        },
        {
          "chainId": 1,
          "address": "0xd7dcd9b99787c619b4d57979521258d1a7267ad7",
          "name": "Evrynet",
          "symbol": "EVRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19194/thumb/evry.png?1634637970"
        },
        {
          "chainId": 1,
          "address": "0xb16d3ed603d62b125c6bd45519eda40829549489",
          "name": "Insureum",
          "symbol": "ISR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4888/thumb/insureum.png?1547974141"
        },
        {
          "chainId": 1,
          "address": "0x146d8d942048ad517479c9bab1788712af180fde",
          "name": "MIB Coin",
          "symbol": "MIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5691/thumb/mibcoin.png?1547041559"
        },
        {
          "chainId": 1,
          "address": "0x179cd91631d96e8fafee6a744eac6ffdbb923520",
          "name": "IxiCash",
          "symbol": "IXI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/8368/thumb/ixi.png?1565866972"
        },
        {
          "chainId": 1,
          "address": "0x0e5f00da8aaef196a719d045db89b5da8f371b32",
          "name": "Connectome",
          "symbol": "CNTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8528/thumb/200_200_CNTM-LOGO-07.png?1600751947"
        },
        {
          "chainId": 1,
          "address": "0x252b9f56359901a0bde52d0675b1f1130d86f471",
          "name": "Pando",
          "symbol": "PANDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14150/thumb/pando_logo.png?1614667045"
        },
        {
          "chainId": 1,
          "address": "0xda7c0810ce6f8329786160bb3d1734cf6661ca6e",
          "name": "Jaypeggers",
          "symbol": "JAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30074/thumb/jay.jpg?1683011694"
        },
        {
          "chainId": 1,
          "address": "0x60f63b76e2fc1649e57a3489162732a90acf59fe",
          "name": "Flurry Finance",
          "symbol": "FLURRY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png?1623365114"
        },
        {
          "chainId": 1,
          "address": "0xa150db9b1fa65b44799d4dd949d922c0a33ee606",
          "name": "Digital Reserve Currency",
          "symbol": "DRC",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/12802/thumb/DRC_Logo.jpg?1654229818"
        },
        {
          "chainId": 1,
          "address": "0x794baab6b878467f93ef17e2f2851ce04e3e34c8",
          "name": "YIN Finance",
          "symbol": "YIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg?1631176921"
        },
        {
          "chainId": 1,
          "address": "0x99295f1141d58a99e939f7be6bbe734916a875b8",
          "name": "LinkPool",
          "symbol": "LPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14548/thumb/linkpool-logo-256x256.png?1616988567"
        },
        {
          "chainId": 1,
          "address": "0x4384b85fe228ae727b129230211194e4a50877c4",
          "name": "Tail Finance",
          "symbol": "TAIL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29439/thumb/ShibTail_logo_fullArtboard_1_%281%29.png?1678864457"
        },
        {
          "chainId": 1,
          "address": "0xca1308e38340c69e848061aa2c3880daeb958187",
          "name": "Hackerlabs DAO",
          "symbol": "HLD",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24670/thumb/YFYHhGlJ_400x400.jpg?1648540176"
        },
        {
          "chainId": 1,
          "address": "0x405ce8b2eaeea7d4ba5fc160848cb2a6569e03f0",
          "name": "Metria Network",
          "symbol": "METR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114"
        },
        {
          "chainId": 1,
          "address": "0x0275e1001e293c46cfe158b3702aade0b99f88a5",
          "name": "Oiler",
          "symbol": "OIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15249/thumb/oiler.png?1620237607"
        },
        {
          "chainId": 1,
          "address": "0x43a8f41644a3f07818a43455b975d940ce16a3fa",
          "name": "Fren Nation",
          "symbol": "FREN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30551/thumb/frennation.png?1684999637"
        },
        {
          "chainId": 1,
          "address": "0x9f284e1337a815fe77d2ff4ae46544645b20c5ff",
          "name": "Darwinia Commitment",
          "symbol": "KTON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11895/thumb/KTON.png?1615271813"
        },
        {
          "chainId": 1,
          "address": "0x71dc40668682a124231301414167e4cf7f55383c",
          "name": "Mimir",
          "symbol": "MIMIR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626"
        },
        {
          "chainId": 1,
          "address": "0x772c44b5166647b135bb4836abc4e06c28e94978",
          "name": "Normie",
          "symbol": "NORMIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30395/thumb/normie.png?1684317044"
        },
        {
          "chainId": 1,
          "address": "0x244b797d622d4dee8b188b03546acaabd0cf91a0",
          "name": "FourCoin",
          "symbol": "FOUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30167/thumb/fourcoin.jpeg?1683532354"
        },
        {
          "chainId": 1,
          "address": "0x8dc89f4716e027394bba225b82328c1ea2ea58bf",
          "name": "Galaxy Villains",
          "symbol": "GVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28741/thumb/Villains.jpeg?1673840651"
        },
        {
          "chainId": 1,
          "address": "0xf6ec87dfe1ed3a7256cc0c38e3c8139103e9af3b",
          "name": "Gene",
          "symbol": "GENE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14145/thumb/logo.a60a0c80_%281%29.png?1614653629"
        },
        {
          "chainId": 1,
          "address": "0x076641af1b8f06b7f8c92587156143c109002cbe",
          "name": "SoPay",
          "symbol": "SOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6736/thumb/sopay.png?1548609877"
        },
        {
          "chainId": 1,
          "address": "0x8c4e7f814d40f8929f9112c5d09016f923d34472",
          "name": "XCELTOKEN PLUS",
          "symbol": "XLAB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8508/thumb/CXQJPNyO_400x400.jpg?1559030200"
        },
        {
          "chainId": 1,
          "address": "0x0d86eb9f43c57f6ff3bc9e23d8f9d82503f0e84b",
          "name": "Maximus DAO",
          "symbol": "MAXI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25126/thumb/maxilogo200.png?1650372648"
        },
        {
          "chainId": 1,
          "address": "0x9040e237c3bf18347bb00957dc22167d0f2b999d",
          "name": "Standard Protocol",
          "symbol": "STND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15100/thumb/STND_logo_black_bg.png?1677657688"
        },
        {
          "chainId": 1,
          "address": "0xa41161af8d4ee421ba5fed4328f2b12034796a21",
          "name": "Artify",
          "symbol": "AFY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29219/thumb/200x200_trans.png?1680505514"
        },
        {
          "chainId": 1,
          "address": "0x777172d858dc1599914a1c4c6c9fc48c99a60990",
          "name": "Solidly",
          "symbol": "SOLID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28676/thumb/solid.png?1673226406"
        },
        {
          "chainId": 1,
          "address": "0xd101dcc414f310268c37eeb4cd376ccfa507f571",
          "name": "ResearchCoin",
          "symbol": "RSC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28146/thumb/RH_Logo_200x200.png?1668678529"
        },
        {
          "chainId": 1,
          "address": "0x406b9dca8b52f08385014ec1ed1cf6a0d5c01289",
          "name": "meishu",
          "symbol": "MEISHU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24112/thumb/png_20230210_102745_0000.png?1676793622"
        },
        {
          "chainId": 1,
          "address": "0x4bc3263eb5bb2ef7ad9ab6fb68be80e43b43801f",
          "name": "Voucher Ethereum 2 0",
          "symbol": "VETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29683/thumb/vETH_200.png?1680596347"
        },
        {
          "chainId": 1,
          "address": "0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3",
          "name": "Pundi X  OLD ",
          "symbol": "NPXS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2170/thumb/pundi-x.png?1548386366"
        },
        {
          "chainId": 1,
          "address": "0x16c52ceece2ed57dad87319d91b5e3637d50afa4",
          "name": "Total Crypto Market Cap",
          "symbol": "TCAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14703/thumb/tcap.png?1617860242"
        },
        {
          "chainId": 1,
          "address": "0x397b102deccace4aa8e5ba63eedb8e65ad83e20c",
          "name": "Encryption AI",
          "symbol": "0XENCRYPT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30015/thumb/photo_2023-04-15_03-15-32_%281%29.jpg?1682498374"
        },
        {
          "chainId": 1,
          "address": "0x62d04c79c1f3a2d7230ffcd3ab01794e1d153239",
          "name": "CryptoPirates",
          "symbol": "OGMF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30442/thumb/cryptopirates.png?1684478445"
        },
        {
          "chainId": 1,
          "address": "0x8bcbef61acd66537362f38167f11875134ffcd63",
          "name": "Pepe Girl",
          "symbol": "PEPEG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29896/thumb/pepe_sad_reverse_200X200.png?1681982341"
        },
        {
          "chainId": 1,
          "address": "0x4daeb4a06f70f4b1a5c329115731fe4b89c0b227",
          "name": "Quasacoin",
          "symbol": "QUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21965/thumb/coin_200.png?1643454438"
        },
        {
          "chainId": 1,
          "address": "0xd337382da15d12bb6e56498e91df64f86c8f1ea8",
          "name": "Aelysir",
          "symbol": "AEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12955/thumb/ael_logo.png?1603794538"
        },
        {
          "chainId": 1,
          "address": "0x54b8d105aa09342fad6b352d41a0bad3e1a9aa9d",
          "name": "Pantomime",
          "symbol": "PANTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29670/thumb/Pantomime_logo.jpeg?1680574418"
        },
        {
          "chainId": 1,
          "address": "0xd7394087e1dbbe477fe4f1cf373b9ac9459565ff",
          "name": "RealTract",
          "symbol": "RET",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5955/thumb/200-01.png?1547041895"
        },
        {
          "chainId": 1,
          "address": "0x247dc9cbbaadabce6e30e2a84ec6c53a419913ad",
          "name": "Ethereum Message Service",
          "symbol": "EMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29812/thumb/LogoEMS200x200.png?1681432251"
        },
        {
          "chainId": 1,
          "address": "0x5f9123d661459af6f398b6f1566f53222612541e",
          "name": "MaranBet",
          "symbol": "MARAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29787/thumb/token_maran-01_1_%281%29.png?1681288671"
        },
        {
          "chainId": 1,
          "address": "0x6628606c321faf52b7230a57b26c01b19aa68e82",
          "name": "BitHash",
          "symbol": "BT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13224/thumb/bt.png?1606358296"
        },
        {
          "chainId": 1,
          "address": "0xfb5453340c03db5ade474b27e68b6a9c6b2823eb",
          "name": "Robot",
          "symbol": "ROBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13517/thumb/MF_Robot_200px.png?1609312481"
        },
        {
          "chainId": 1,
          "address": "0x9b4e2b4b13d125238aa0480dd42b4f6fc71b37cc",
          "name": "MyToken",
          "symbol": "MT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4109/thumb/2712.png?1547039296"
        },
        {
          "chainId": 1,
          "address": "0x3f3cd642e81d030d7b514a2ab5e3a5536beb90ec",
          "name": "Rho",
          "symbol": "RHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25540/thumb/Rho-icon-03.png?1652265700"
        },
        {
          "chainId": 1,
          "address": "0xecbee2fae67709f718426ddc3bf770b26b95ed20",
          "name": "Clips",
          "symbol": "CLIPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30151/thumb/clips-logo.png?1683516039"
        },
        {
          "chainId": 1,
          "address": "0x79126d32a86e6663f3aaac4527732d0701c1ae6c",
          "name": "Dark Matter",
          "symbol": "DMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14223/thumb/dmt.jpg?1615089067"
        },
        {
          "chainId": 1,
          "address": "0x4297394c20800e8a38a619a243e9bbe7681ff24e",
          "name": "Hot Cross",
          "symbol": "HOTCROSS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png?1632197570"
        },
        {
          "chainId": 1,
          "address": "0xc88f47067db2e25851317a2fdae73a22c0777c37",
          "name": "Legacy oneBTC",
          "symbol": "ONEBTC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15082/thumb/G9oJy1-R.png?1619660359"
        },
        {
          "chainId": 1,
          "address": "0x577fee283e776eec29c9e4d258431982780a38a8",
          "name": "Pepa ERC",
          "symbol": "PEPA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29866/thumb/3iGdoyL.png?1681890350"
        },
        {
          "chainId": 1,
          "address": "0xb2279b6769cfba691416f00609b16244c0cf4b20",
          "name": "Waifu Genesis Card Collection",
          "symbol": "WAIF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12100/thumb/Small-Waifu_token.png?1597120029"
        },
        {
          "chainId": 1,
          "address": "0x4a3fe75762017db0ed73a71c9a06db7768db5e66",
          "name": "COMP yVault",
          "symbol": "YVCOMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28786/thumb/yvCOMP-128-0x4A3FE75762017DB0eD73a71C9A06db7768DB5e66.png?1674199912"
        },
        {
          "chainId": 1,
          "address": "0xaec65404ddc3af3c897ad89571d5772c1a695f22",
          "name": "Phoenix Finance",
          "symbol": "PHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432"
        },
        {
          "chainId": 1,
          "address": "0xda9fdab21bc4a5811134a6e0ba6ca06624e67c07",
          "name": "Quidd",
          "symbol": "QUIDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19725/thumb/quidd.png?1637303435"
        },
        {
          "chainId": 1,
          "address": "0x03dde9e5bb31ee40a471476e2fccf75c67921062",
          "name": "EML Protocol",
          "symbol": "EML",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30950/thumb/EML_LOGO.png?1689214632"
        },
        {
          "chainId": 1,
          "address": "0x52f4d5ee6c91e01be67ca1f64b11ed0ee370817d",
          "name": "CIA",
          "symbol": "CIA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21569/thumb/Untitled-design-24.png?1641975750"
        },
        {
          "chainId": 1,
          "address": "0x03d1e72765545729a035e909edd9371a405f77fb",
          "name": "Nabox",
          "symbol": "NABOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16445/thumb/NyemjVRA_400x400.png?1624235013"
        },
        {
          "chainId": 1,
          "address": "0x0ebb614204e47c09b6c3feb9aaecad8ee060e23e",
          "name": "Cryptopay",
          "symbol": "CPAY",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/2216/thumb/cryptopay.png?1547036499"
        },
        {
          "chainId": 1,
          "address": "0x30df7d7ee52c1b03cd009e656f00ab875adceed2",
          "name": "MetaReset",
          "symbol": "RESET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26631/thumb/logo.png?1659325867"
        },
        {
          "chainId": 1,
          "address": "0x94d863173ee77439e4292284ff13fad54b3ba182",
          "name": "Delphi",
          "symbol": "ADEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12300/thumb/adel_on_white_10x.png?1598967061"
        },
        {
          "chainId": 1,
          "address": "0x08037036451c768465369431da5c671ad9b37dbc",
          "name": "NFT Stars",
          "symbol": "NFTS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png?1623116004"
        },
        {
          "chainId": 1,
          "address": "0x8b0fde007458ee153bd0f66cd448af5fb3d99b43",
          "name": "Mastermind",
          "symbol": "MASTERMIND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29205/thumb/Mastermind.png?1677312893"
        },
        {
          "chainId": 1,
          "address": "0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9",
          "name": "X2Y2",
          "symbol": "X2Y2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23633/thumb/logo-60b81ff87b40b11739105acf5ad1e075.png?1644903256"
        },
        {
          "chainId": 1,
          "address": "0x056354f3ff20743aa4c0da365603871c7000b081",
          "name": "TriumphX",
          "symbol": "TRIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12270/thumb/TRIX_Logo_transparent.png?1598619314"
        },
        {
          "chainId": 1,
          "address": "0xde075d9adbd0240b4462f124af926452ad0bac91",
          "name": "Bubblefong",
          "symbol": "BBF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24634/thumb/BBF-Token-200x200.png?1648460352"
        },
        {
          "chainId": 1,
          "address": "0x657b83a0336561c8f64389a6f5ade675c04b0c3b",
          "name": "Playcent",
          "symbol": "PCNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831"
        },
        {
          "chainId": 1,
          "address": "0x8a7664e782860e856031d6c31eb3bde721bc362b",
          "name": "RektSkulls",
          "symbol": "REKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28910/thumb/REKT_icon_200.png?1675310887"
        },
        {
          "chainId": 1,
          "address": "0x666cbfaa3baa2faccfac8854fea1e5db140fb104",
          "name": "PLUMS",
          "symbol": "PLUMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30097/thumb/logoPlums.png?1683180942"
        },
        {
          "chainId": 1,
          "address": "0x992d339532a9c42f1b0e59a57e95f38da38c66f6",
          "name": "Soulsaver",
          "symbol": "SOUL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27494/thumb/SOUL_LOGO.png?1664268950"
        },
        {
          "chainId": 1,
          "address": "0xc25a3a3b969415c80451098fa907ec722572917f",
          "name": "LP sCurve",
          "symbol": "SCURVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11899/thumb/Curvefi_sCrv_32.png?1595931870"
        },
        {
          "chainId": 1,
          "address": "0x4e432a62733a7ee38ad2e16b3cc0731457ea5b55",
          "name": "QChain QDT",
          "symbol": "QDT",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/26502/thumb/qdt_logo.png?1658366015"
        },
        {
          "chainId": 1,
          "address": "0x033e223870f766644f7f7a4b7dc2e91573707d06",
          "name": "Zin",
          "symbol": "ZIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13250/thumb/coingecko_logo.png?1606716375"
        },
        {
          "chainId": 1,
          "address": "0xd26a9c3437f7d121098c8c05c7413f5cc70bb070",
          "name": "Azuma Coin",
          "symbol": "AZUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10483/thumb/WDncHJ5.png?1580161760"
        },
        {
          "chainId": 1,
          "address": "0x9fb41be7688a1ae9759185d7cacb10e9a9d22ece",
          "name": "HOLD",
          "symbol": "HOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30595/thumb/HOLD.png?1685499984"
        },
        {
          "chainId": 1,
          "address": "0x391e86e2c002c70dee155eaceb88f7a3c38f5976",
          "name": "Aave AMM UniUSDCWETH",
          "symbol": "AAMMUNIUSDCWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17219/thumb/aAmmUniUSDCWETH.png?1626879118"
        },
        {
          "chainId": 1,
          "address": "0xf52cdcd458bf455aed77751743180ec4a595fd3f",
          "name": "IdleSUSD  Yield ",
          "symbol": "IDLESUSDYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11934/thumb/idlesusdv3mmaxyield_32.png?1596263703"
        },
        {
          "chainId": 1,
          "address": "0x556713e6a6a928af55bf3967f847c62402acbadc",
          "name": "Stick Man",
          "symbol": "STICK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30173/thumb/logo.png?1683542683"
        },
        {
          "chainId": 1,
          "address": "0xbb4f3ad7a2cf75d8effc4f6d7bd21d95f06165ca",
          "name": "Sheesh",
          "symbol": "SHS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30858/thumb/1111.png?1688366719"
        },
        {
          "chainId": 1,
          "address": "0x6d765cbe5bc922694afe112c140b8878b9fb0390",
          "name": "SUSHI yVault",
          "symbol": "YVSUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28789/thumb/yvSUSHI-128-0x6d765CbE5bC922694afE112C140b8878b9FB0390.png?1674224861"
        },
        {
          "chainId": 1,
          "address": "0x5f48d1fd6814cd1cd38aeb895755e57d519196d1",
          "name": "Wins",
          "symbol": "WINS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31007/thumb/icon_wins.png?1689673393"
        },
        {
          "chainId": 1,
          "address": "0xd2d6158683aee4cc838067727209a0aaf4359de3",
          "name": "Bounty0x",
          "symbol": "BNTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1444/thumb/bounty0x.png?1547562674"
        },
        {
          "chainId": 1,
          "address": "0x1ccf27211e8bf052f6255329ed641b4e94e80603",
          "name": "Metababy",
          "symbol": "BABY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27175/thumb/7psZaqh0_400x400.jpeg?1662369176"
        },
        {
          "chainId": 1,
          "address": "0x16b0a1a87ae8af5c792fabc429c4fe248834842b",
          "name": "Algory",
          "symbol": "ALG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12231/thumb/logo-2.png?1605256312"
        },
        {
          "chainId": 1,
          "address": "0xe0a458bf4acf353cb45e211281a334bb1d837885",
          "name": "4Chan",
          "symbol": "4CHAN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30124/thumb/4CHAN.png?1683259065"
        },
        {
          "chainId": 1,
          "address": "0x446c9033e7516d820cc9a2ce2d0b7328b579406f",
          "name": "SOLVE",
          "symbol": "SOLVE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1768/thumb/Solve.Token_logo_200_200_wiyhout_BG.png?1575869846"
        },
        {
          "chainId": 1,
          "address": "0xdf96bde075d59e9143b325c75af38e208c986e6f",
          "name": "YetiCoin",
          "symbol": "YETIC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20493/thumb/yetic.png?1647695460"
        },
        {
          "chainId": 1,
          "address": "0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20",
          "name": "AGA",
          "symbol": "AGA",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396"
        },
        {
          "chainId": 1,
          "address": "0x94d40b49f020bfebba1a80a0191eb3737b90e8d3",
          "name": "Mintera",
          "symbol": "MNTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28774/thumb/Mintera_Logo_CoinGecko.png?1674480509"
        },
        {
          "chainId": 1,
          "address": "0x67d85a291fcdc862a78812a3c26d55e28ffb2701",
          "name": "Asymetrix",
          "symbol": "ASX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29984/thumb/Asymetrix_Logo_Only_Square_w200.png?1682410718"
        },
        {
          "chainId": 1,
          "address": "0xf8ad7dfe656188a23e89da09506adf7ad9290d5d",
          "name": "Blocery",
          "symbol": "BLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12095/thumb/Blocery_logo_200x200_.png?1597046107"
        },
        {
          "chainId": 1,
          "address": "0x2904b9b16652d7d0408eccfa23a19d4a3358230f",
          "name": "Puriever",
          "symbol": "PURE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12600/thumb/200x200_pure_logo.png?1600999358"
        },
        {
          "chainId": 1,
          "address": "0xd90e69f67203ebe02c917b5128629e77b4cd92dc",
          "name": "One Cash",
          "symbol": "ONC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13529/thumb/onc_logo.png?1609406029"
        },
        {
          "chainId": 1,
          "address": "0xf32aa187d5bc16a2c02a6afb7df1459d0d107574",
          "name": "HachikoInu",
          "symbol": "INU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16392/thumb/hachikoinu-200px.png?1626355242"
        },
        {
          "chainId": 1,
          "address": "0x68e9c0d9aa450254aed2cd102503d4dff6b3c37c",
          "name": "CrownSterling",
          "symbol": "WCSOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25179/thumb/9kL0liy4_400x400.jpg?1650541820"
        },
        {
          "chainId": 1,
          "address": "0x91a5de30e57831529a3c1af636a78a7e4e83f3aa",
          "name": "Hulk Inu",
          "symbol": "HULK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26358/thumb/HULKINU_LOGO.png?1657593698"
        },
        {
          "chainId": 1,
          "address": "0x9b00e6e8d787b13756eb919786c9745054db64f9",
          "name": "Sienna  ERC 20 ",
          "symbol": "WSIENNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15420/thumb/sienna.jpeg?1620782072"
        },
        {
          "chainId": 1,
          "address": "0x910c4da718caf4ee38ce5c2490cddaeca689204e",
          "name": "Waste Digital Coin",
          "symbol": "WACO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19035/thumb/waco.png?1634197749"
        },
        {
          "chainId": 1,
          "address": "0x14a32f050facf226ec60882398a9bf36d91dbac2",
          "name": "Kyoko",
          "symbol": "KYOKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24454/thumb/kyoko.png?1649576935"
        },
        {
          "chainId": 1,
          "address": "0x0642026e7f0b6ccac5925b4e7fa61384250e1701",
          "name": "H2O",
          "symbol": "H2O",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25315/thumb/h2o_32.png?1651215712"
        },
        {
          "chainId": 1,
          "address": "0x1e4746dc744503b53b4a082cb3607b169a289090",
          "name": "IPOR",
          "symbol": "IPOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28373/thumb/IPOR-token-200x200.png?1670480793"
        },
        {
          "chainId": 1,
          "address": "0x3209d14ff61766359e64aceff91877cec2ad968e",
          "name": "CouponBay",
          "symbol": "CUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24696/thumb/logo_200.png?1648625906"
        },
        {
          "chainId": 1,
          "address": "0x04969cd041c0cafb6ac462bd65b536a5bdb3a670",
          "name": "Wrapped ECOMI",
          "symbol": "WOMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14675/thumb/ecomi.jpg?1617689100"
        },
        {
          "chainId": 1,
          "address": "0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3",
          "name": "Dynamic Set Dollar",
          "symbol": "DSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13249/thumb/DSD.jpg?1606713628"
        },
        {
          "chainId": 1,
          "address": "0xb3207935ff56120f3499e8ad08461dd403bf16b8",
          "name": "dAMM",
          "symbol": "DAMM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28082/thumb/dAMM_logo_round_-_dark.png?1667443628"
        },
        {
          "chainId": 1,
          "address": "0xaa4e3edb11afa93c41db59842b29de64b72e355b",
          "name": "Marginswap",
          "symbol": "MFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13899/thumb/marginswap_logo.png?1612756590"
        },
        {
          "chainId": 1,
          "address": "0xe7ef051c6ea1026a70967e8f04da143c67fa4e1f",
          "name": "VetMe",
          "symbol": "VETME",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28787/thumb/2.png?1674201162"
        },
        {
          "chainId": 1,
          "address": "0xd212046f89680ac9f106b9c63f314cc9808e18d5",
          "name": "Sukiyaki",
          "symbol": "SUKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29027/thumb/Sukiyaki.png?1676020957"
        },
        {
          "chainId": 1,
          "address": "0xfca47962d45adfdfd1ab2d972315db4ce7ccf094",
          "name": "iXledger",
          "symbol": "IXT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/814/thumb/ixledger.png?1547034602"
        },
        {
          "chainId": 1,
          "address": "0x16cda4028e9e872a38acb903176719299beaed87",
          "name": "MARS4",
          "symbol": "MARS4",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18709/thumb/mars4_logo.jpg?1633070237"
        },
        {
          "chainId": 1,
          "address": "0x51db5ad35c671a87207d88fc11d593ac0c8415bd",
          "name": "Moeda Loyalty Points",
          "symbol": "MDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/895/thumb/moeda-loyalty-points.png?1547034720"
        },
        {
          "chainId": 1,
          "address": "0x622dffcc4e83c64ba959530a5a5580687a57581b",
          "name": "Cube Intelligence",
          "symbol": "AUTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3503/thumb/2559.png?1547038267"
        },
        {
          "chainId": 1,
          "address": "0xe2f668f6166eda65620a01c4c116a5f6e5528614",
          "name": "Me Gusta",
          "symbol": "GUSTA",
          "decimals": 16,
          "logoURI": "https://assets.coingecko.com/coins/images/30348/thumb/NoMeGusta.jpg?1684197695"
        },
        {
          "chainId": 1,
          "address": "0x5eaa69b29f99c84fe5de8200340b4e9b4ab38eac",
          "name": "Raze Network",
          "symbol": "RAZE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png?1623867120"
        },
        {
          "chainId": 1,
          "address": "0xf56842af3b56fd72d17cb103f92d027bba912e89",
          "name": "Bamboo DeFi",
          "symbol": "BAMBOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13856/thumb/LOGO_BAMBOO_PNG.png?1686888830"
        },
        {
          "chainId": 1,
          "address": "0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55",
          "name": "Ushi",
          "symbol": "USHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27865/thumb/photo_2022-10-17_22-21-01.jpg?1666151494"
        },
        {
          "chainId": 1,
          "address": "0x3b58c52c03ca5eb619eba171091c86c34d603e5f",
          "name": "Cyclub",
          "symbol": "CYCLUB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/12524/thumb/mcicoin-logo.png?1600471686"
        },
        {
          "chainId": 1,
          "address": "0xd4342a57ecf2fe7ffa37c33cb8f63b1500e575e6",
          "name": "Apron",
          "symbol": "APN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14916/thumb/1_GOjoDhGzzpqnMPGpHGeWhg.png?1618998577"
        },
        {
          "chainId": 1,
          "address": "0x249ca82617ec3dfb2589c4c17ab7ec9765350a18",
          "name": "Verse",
          "symbol": "VERSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28424/thumb/verselogo.png?1670461811"
        },
        {
          "chainId": 1,
          "address": "0x21f15966e07a10554c364b988e91dab01d32794a",
          "name": "SmartMesh",
          "symbol": "SMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1346/thumb/smartmesh.png?1548609531"
        },
        {
          "chainId": 1,
          "address": "0xaa2ce7ae64066175e0b90497ce7d9c190c315db4",
          "name": "Suterusu",
          "symbol": "SUTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9830/thumb/p-NFlBlw_400x400.jpg?1572472860"
        },
        {
          "chainId": 1,
          "address": "0xed03ed872159e199065401b6d0d487d78d9464aa",
          "name": "Mexican Peso Tether",
          "symbol": "MXNT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26087/thumb/MXNT_green.jpg?1655772424"
        },
        {
          "chainId": 1,
          "address": "0xeb4c2781e4eba804ce9a9803c67d0893436bb27d",
          "name": "renBTC",
          "symbol": "RENBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11370/thumb/Bitcoin.jpg?1628072791"
        },
        {
          "chainId": 1,
          "address": "0x066e941e168de3815cd6c8f63c46e8e3a2309ad7",
          "name": "The Box",
          "symbol": "BOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29750/thumb/791ED19E-2DED-4EF7-BEC5-248C08CF0F7F.jpeg?1681114736"
        },
        {
          "chainId": 1,
          "address": "0xa6a1cc527d48585538b137e6abc14b2a55489d11",
          "name": "Linework Coin",
          "symbol": "LWC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27739/thumb/Linework-logo_coin_200px-01.png?1665578755"
        },
        {
          "chainId": 1,
          "address": "0x5abf88cf3444611d13f6d1b39f3f3ee8575c91a2",
          "name": "Super Athletes Token",
          "symbol": "SAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27761/thumb/SAT_200.png?1665658758"
        },
        {
          "chainId": 1,
          "address": "0x5ba19d656b65f1684cfea4af428c23b9f3628f97",
          "name": "AAG",
          "symbol": "AAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21382/thumb/aag-token-icon.png?1661849633"
        },
        {
          "chainId": 1,
          "address": "0x6710c63432a2de02954fc0f851db07146a6c0312",
          "name": "Smart MFG",
          "symbol": "MFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1842/thumb/Smart_MFG_Cropped_Logo.png?1621422155"
        },
        {
          "chainId": 1,
          "address": "0x645c52cf6c5c58aa4064494f5b5ffe9c7ec0d7d4",
          "name": "Huckleberry Inu",
          "symbol": "HKBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28495/thumb/IMG_20221214_073842_547_%283%29_%281%29.png?1671108300"
        },
        {
          "chainId": 1,
          "address": "0x83869de76b9ad8125e22b857f519f001588c0f62",
          "name": "EXMO Coin",
          "symbol": "EXM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9154/thumb/200x200.png?1668998803"
        },
        {
          "chainId": 1,
          "address": "0x4ce4c025692b3142dbde1cd432ef55b9a8d18701",
          "name": "Decanect",
          "symbol": "DCNT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27309/thumb/200-x-200-jpeg.jpg?1663239875"
        },
        {
          "chainId": 1,
          "address": "0xab37e1358b639fd877f015027bb62d3ddaa7557e",
          "name": "Lien",
          "symbol": "LIEN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12224/thumb/Lien.png?1598262819"
        },
        {
          "chainId": 1,
          "address": "0x86d1d12523b65203851c571fcc029bf90903fb6d",
          "name": "Bintex Futures",
          "symbol": "BNTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672"
        },
        {
          "chainId": 1,
          "address": "0x0def8d8adde14c9ef7c2a986df3ea4bd65826767",
          "name": "DefiCliq",
          "symbol": "CLIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146"
        },
        {
          "chainId": 1,
          "address": "0x466912baa9430a4a460b141ee8c580d817441449",
          "name": "BLOCKMAX",
          "symbol": "OCB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12315/thumb/200x200-01.png?1599086761"
        },
        {
          "chainId": 1,
          "address": "0xd7f0cc50ad69408ae58be033f4f85d2367c2e468",
          "name": "Vera",
          "symbol": "VERA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18519/thumb/JJXTVFOE_400x400.png?1632273174"
        },
        {
          "chainId": 1,
          "address": "0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc",
          "name": "Goldmint",
          "symbol": "MNTP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1944/thumb/goldmint.png?1547743002"
        },
        {
          "chainId": 1,
          "address": "0x723cbfc05e2cfcc71d3d89e770d32801a5eef5ab",
          "name": "Bitcoin Pro",
          "symbol": "BTCP",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/3545/thumb/DSiD9ZhWsAE_8cS.png?1547038353"
        },
        {
          "chainId": 1,
          "address": "0x6ef6610d24593805144d73b13d4405e00a4e4ac7",
          "name": "Die Protocol",
          "symbol": "DIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27903/thumb/newlogo.jpg?1666334549"
        },
        {
          "chainId": 1,
          "address": "0x295b42684f90c77da7ea46336001010f2791ec8c",
          "name": "Xi",
          "symbol": "XI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18640/thumb/v5NMxPo.png?1632751046"
        },
        {
          "chainId": 1,
          "address": "0xa4bbe66f151b22b167127c770016b15ff97dd35c",
          "name": "Umbria Network",
          "symbol": "UMBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14712/thumb/RX7VJg6.png?1617891954"
        },
        {
          "chainId": 1,
          "address": "0xfca89d55a768375ab7ca04485a35a964bea828dd",
          "name": "Delrey Inu",
          "symbol": "DELREY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29661/thumb/Delrey_Inu.png?1680508134"
        },
        {
          "chainId": 1,
          "address": "0x1410434b0346f5be678d0fb554e5c7ab620f8f4a",
          "name": "BitKan",
          "symbol": "KAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4651/thumb/kan-token.png?1547039917"
        },
        {
          "chainId": 1,
          "address": "0xa0cf46eb152656c7090e769916eb44a138aaa406",
          "name": "Spheroid Universe",
          "symbol": "SPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11968/thumb/Frame_36.png?1685956889"
        },
        {
          "chainId": 1,
          "address": "0x232fb065d9d24c34708eedbf03724f2e95abe768",
          "name": "Sheesha Finance  ERC20 ",
          "symbol": "SHEESHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23053/thumb/MLBmh4z0.png?1643113057"
        },
        {
          "chainId": 1,
          "address": "0xa3ad8c7ab6b731045b5b16e3fdf77975c71abe79",
          "name": "Dinerobet",
          "symbol": "DINERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28248/thumb/CABA1DE1-2CB1-46D3-97BC-20305EA67FBB.png?1668656923"
        },
        {
          "chainId": 1,
          "address": "0xbd04ccc050058a6a422851fa6c0f92bb65eb06ca",
          "name": "Pre Retogeum",
          "symbol": "PRTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27397/thumb/IMG_20220921_123729_913.jpg?1663827688"
        },
        {
          "chainId": 1,
          "address": "0x0c943300d604c732117db2755873a0a0683ee7a9",
          "name": "Paradise Defi",
          "symbol": "PDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28827/thumb/photo_2023-01-16_15.08.09.jpeg?1674724911"
        },
        {
          "chainId": 1,
          "address": "0x6a8fee0e33cb65a7e8d21badca62e87639ef74b3",
          "name": "PDX Coin",
          "symbol": "PDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25095/thumb/vBi23nWw_400x400.jpg?1650271510"
        },
        {
          "chainId": 1,
          "address": "0xbc17729fdf562723f0267f79ff25ade441056d87",
          "name": "Karus Starter",
          "symbol": "KST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png?1645962885"
        },
        {
          "chainId": 1,
          "address": "0xde4ee8057785a7e8e800db58f9784845a5c2cbd6",
          "name": "DeXe",
          "symbol": "DEXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12713/thumb/logo_%2814%29.png?1601952779"
        },
        {
          "chainId": 1,
          "address": "0xb62d46a21fef0fadb5b92fc5ef7077e71e65631c",
          "name": "AlchemyAi",
          "symbol": "ACOIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30055/thumb/alchemy.jpeg?1682999120"
        },
        {
          "chainId": 1,
          "address": "0x471a202f69d6e975da55e363dab1bdb2e86e0c0f",
          "name": "Geke",
          "symbol": "GEKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30332/thumb/geke.jpg?1684142638"
        },
        {
          "chainId": 1,
          "address": "0x4af698b479d0098229dc715655c667ceb6cd8433",
          "name": "MaidCoin",
          "symbol": "MAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17571/thumb/610e48937e73535a83f92ae7_icon200.png?1628517343"
        },
        {
          "chainId": 1,
          "address": "0xe0b9bcd54bf8a730ea5d3f1ffce0885e911a502c",
          "name": "ZUM",
          "symbol": "ZUM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005"
        },
        {
          "chainId": 1,
          "address": "0x0789dbae94fb18e5789b8e4489bcb7a1adb58622",
          "name": "FISCO Coin",
          "symbol": "FSCC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/6507/thumb/AnyConv.com__fscc_icon_fin.png?1608779052"
        },
        {
          "chainId": 1,
          "address": "0x20c3fa331a385b63ee39137e99d0cf2db142fce1",
          "name": "Shila Inu",
          "symbol": "SHIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28847/thumb/rsz_logo1.png?1674820641"
        },
        {
          "chainId": 1,
          "address": "0x8a001303158670e284950565164933372807cd48",
          "name": "WaifuAI",
          "symbol": "WFAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30606/thumb/waifuai.jpg?1685599837"
        },
        {
          "chainId": 1,
          "address": "0x71dc640b99faaf03544bdbce87018c2ae8ebe849",
          "name": "OmniaVerse",
          "symbol": "OMNIA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25543/thumb/OMNIA.png?1652266335"
        },
        {
          "chainId": 1,
          "address": "0x62199b909fb8b8cf870f97bef2ce6783493c4908",
          "name": "pTokens BTC",
          "symbol": "PBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25861/thumb/wMTpRljt_400x400.png?1654228097"
        },
        {
          "chainId": 1,
          "address": "0x25c7b64a93eb1261e130ec21a3e9918caa38b611",
          "name": "Wrapped Virgin Gen 0 CryptoKittties",
          "symbol": "WVG0",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12875/thumb/wvg0.png?1603211534"
        },
        {
          "chainId": 1,
          "address": "0x70e8de73ce538da2beed35d14187f6959a8eca96",
          "name": "XSGD",
          "symbol": "XSGD",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/12832/thumb/StraitsX_Singapore_Dollar_%28XSGD%29_Token_Logo.png?1633936813"
        },
        {
          "chainId": 1,
          "address": "0xa823e6722006afe99e91c30ff5295052fe6b8e32",
          "name": "Neumark",
          "symbol": "NEU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2218/thumb/neumark.png?1547036501"
        },
        {
          "chainId": 1,
          "address": "0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a",
          "name": "Sarcophagus",
          "symbol": "SARCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15091/thumb/E2S2-CcUcAAyNxD.jpeg?1622519884"
        },
        {
          "chainId": 1,
          "address": "0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3",
          "name": "Fractal",
          "symbol": "FCL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14096/thumb/fractal_logo.png?1614264502"
        },
        {
          "chainId": 1,
          "address": "0x5c3a228510d246b78a3765c20221cbf3082b44a4",
          "name": "Storiqa",
          "symbol": "STQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1929/thumb/storiqa.png?1548611108"
        },
        {
          "chainId": 1,
          "address": "0xe1fc4455f62a6e89476f1072530c20cf1a0622da",
          "name": "Phuture",
          "symbol": "PHTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16071/thumb/phtr.png?1655535400"
        },
        {
          "chainId": 1,
          "address": "0x0ba74fb26ca523f2dc22fa4318581cc2452eaba1",
          "name": "Bogdanoff",
          "symbol": "BOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30026/thumb/telegram-cloud-document-1-1809833204887060537_copia_2.png?1682582878"
        },
        {
          "chainId": 1,
          "address": "0x2c9c19ce3b15ae77c6d80aec3c1194cfd6f7f3fa",
          "name": "2crazyNFT",
          "symbol": "2CRZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16797/thumb/2crz.png?1635525909"
        },
        {
          "chainId": 1,
          "address": "0xe4b91faf8810f8895772e7ca065d4cb889120f94",
          "name": "Cat in a Box Fee Token",
          "symbol": "BOXFEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29928/thumb/boxFEE.png?1682139923"
        },
        {
          "chainId": 1,
          "address": "0xa849eaae994fb86afa73382e9bd88c2b6b18dc71",
          "name": "MVL",
          "symbol": "MVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299"
        },
        {
          "chainId": 1,
          "address": "0x5224f552f110ec78e6e0468138950ae5f3040942",
          "name": "Anomus Coin",
          "symbol": "ANOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png?1637807012"
        },
        {
          "chainId": 1,
          "address": "0x0b4bdc478791897274652dc15ef5c135cae61e60",
          "name": "DAEX",
          "symbol": "DAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4063/thumb/daex.png?1547791412"
        },
        {
          "chainId": 1,
          "address": "0xc691bc298a304d591ad9b352c7a8d216de9f2ced",
          "name": "Polaris Share",
          "symbol": "POLA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13817/thumb/tHQrxdYQ_400x400.png?1612051098"
        },
        {
          "chainId": 1,
          "address": "0xb9098d3669a78e9afe8b94a97290407400d9da31",
          "name": "Protectorate Protocol",
          "symbol": "PRTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30901/thumb/IMG_1778.png?1688619759"
        },
        {
          "chainId": 1,
          "address": "0x61f7fdaae910f3ed65fb1a3ab59354e38b06cf8e",
          "name": "BitcoinPepe",
          "symbol": "BTCPEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30193/thumb/Logo_BTCPepe-200x200.png?1683606358"
        },
        {
          "chainId": 1,
          "address": "0x5eeaa2dcb23056f4e8654a349e57ebe5e76b5e6e",
          "name": "Virtue Poker Points",
          "symbol": "VPP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png?1622068750"
        },
        {
          "chainId": 1,
          "address": "0x05bbe7240de66f6480c9aeda77c1376b13393f83",
          "name": "Xeno",
          "symbol": "XNO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13830/thumb/XNO_logo_200x200.png?1612168285"
        },
        {
          "chainId": 1,
          "address": "0x10bc518c32fbae5e38ecb50a612160571bd81e44",
          "name": "VeraOne",
          "symbol": "VRO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11112/thumb/wsBaVF.png?1588639964"
        },
        {
          "chainId": 1,
          "address": "0xee9e7bb7e55bbc86414047b61d65c9c0d91ffbd0",
          "name": "Fracton Protocol",
          "symbol": "FT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26809/thumb/CtH9UlRl_400x400.jpeg?1660227771"
        },
        {
          "chainId": 1,
          "address": "0x5319e86f0e41a06e49eb37046b8c11d78bcad68c",
          "name": "Zelwin",
          "symbol": "ZLW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128"
        },
        {
          "chainId": 1,
          "address": "0x641927e970222b10b2e8cdbc96b1b4f427316f16",
          "name": "MEEB Vault  NFTX ",
          "symbol": "MEEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17044/thumb/Meebs.png?1626147616"
        },
        {
          "chainId": 1,
          "address": "0xff44b937788215eca197baaf9af69dbdc214aa04",
          "name": "Rocki",
          "symbol": "ROCKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767"
        },
        {
          "chainId": 1,
          "address": "0x4ece5c5cfb9b960a49aae739e15cdb6cfdcc5782",
          "name": "Doont Buy",
          "symbol": "DBUY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17895/thumb/VaxEiZ3.png?1629727429"
        },
        {
          "chainId": 1,
          "address": "0x7105e64bf67eca3ae9b123f0e5ca2b83b2ef2da0",
          "name": "X7DAO",
          "symbol": "X7DAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27709/thumb/X7DAO.png?1665314516"
        },
        {
          "chainId": 1,
          "address": "0xbf0741e995f469d39e4f96c0780f9a8e43f4b978",
          "name": "i Money Crypto",
          "symbol": "IMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20178/thumb/imc_Logo.png?1636604215"
        },
        {
          "chainId": 1,
          "address": "0x09637e374ec5bab8c9e9654a97c9593e3038c5b3",
          "name": "zkProof",
          "symbol": "ZKP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29592/thumb/200.png?1679886934"
        },
        {
          "chainId": 1,
          "address": "0x19ac2659599fd01c853de846919544276ad26f50",
          "name": "Covenant",
          "symbol": "COVN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22280/thumb/16837.png?1641364060"
        },
        {
          "chainId": 1,
          "address": "0xd2e5decc08a80be6538f89f9ab8ff296e2f724df",
          "name": "STIMA",
          "symbol": "STIMA",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/26101/thumb/STIMA_200x200.png?1655867272"
        },
        {
          "chainId": 1,
          "address": "0xd2287a52287dd64ae2c690d74322e2e345dc8f69",
          "name": "BabyWhale",
          "symbol": "BBW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24168/thumb/babywhale_32.png?1646716918"
        },
        {
          "chainId": 1,
          "address": "0x7c5d5100b339fe7d995a893af6cb496b9474373c",
          "name": "Loon Network",
          "symbol": "LOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11673/thumb/ErUvUA2D4Q8hPWXxZBIuiI8MD0nrU2rDEY56d6EtVzJDTqlflAFXa2bgJ_kFwb8OtotfAvESvm-dik7d_SeJJh23f6RosUJ0AzU64QcKk5H3fu300KOJjFDFsntpRWARFKI5S4Cc8F3pcRPBkFIw6oPF58mKi83sa2DJPl-E5JMqmj6rJh5acb7Fvi80kfziWv3DHhhpg1YJYMP.jpg?1592536236"
        },
        {
          "chainId": 1,
          "address": "0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32",
          "name": "Sync Network",
          "symbol": "SYNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13231/thumb/sync_network.png?1629777103"
        },
        {
          "chainId": 1,
          "address": "0x35073ca7bac0c2edf58fb34a2cbc39ea2fbb7056",
          "name": "Tor Wallet",
          "symbol": "TOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29371/thumb/ce34lut.png?1678349859"
        },
        {
          "chainId": 1,
          "address": "0x1b19c19393e2d034d8ff31ff34c81252fcbbee92",
          "name": "OUSG",
          "symbol": "OUSG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29023/thumb/OUSG.png?1676015920"
        },
        {
          "chainId": 1,
          "address": "0xd23ac27148af6a2f339bd82d0e3cff380b5093de",
          "name": "Siren",
          "symbol": "SI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13955/thumb/siren_token_200x200.png?1672132332"
        },
        {
          "chainId": 1,
          "address": "0x62959c699a52ec647622c91e79ce73344e4099f5",
          "name": "DeFine",
          "symbol": "DFA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17579/thumb/define.PNG?1628558827"
        },
        {
          "chainId": 1,
          "address": "0x56123908aca89bf60ce9659ae9f4589e46c8d2cf",
          "name": "Mushe",
          "symbol": "XMU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27965/thumb/2.png?1666762043"
        },
        {
          "chainId": 1,
          "address": "0xb186035490c8602ead853ec98be05e3461521ab2",
          "name": "Pack",
          "symbol": "PACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29556/thumb/Pack.png?1679642286"
        },
        {
          "chainId": 1,
          "address": "0x65f9a292f1aeed5d755aa2fd2fb17ab2e9431447",
          "name": "SoMee Social",
          "symbol": "SOMEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16406/thumb/SoMeeBallLogo200x200.png?1623920285"
        },
        {
          "chainId": 1,
          "address": "0xa8b61cff52564758a204f841e636265bebc8db9b",
          "name": "Yield Protocol",
          "symbol": "YIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14220/thumb/yield.png?1615030969"
        },
        {
          "chainId": 1,
          "address": "0x18b52f500e6d9c8b0455ec3483846d9f8edd7e1b",
          "name": "Monopoly Meta",
          "symbol": "MPM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26965/thumb/TkA1Iou8_400x400.jpeg?1661081008"
        },
        {
          "chainId": 1,
          "address": "0x66f73d0fd4161cfad4302dc145ff994375c13475",
          "name": "DexGame",
          "symbol": "DXGM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21084/thumb/dxgm-token.png?1638310949"
        },
        {
          "chainId": 1,
          "address": "0x6988a804c74fd04f37da1ea4781cea68c9c00f86",
          "name": "Tribal Token",
          "symbol": "TRIBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27774/thumb/TRIBL_Logo_200x200.png?1665724961"
        },
        {
          "chainId": 1,
          "address": "0x83031984b45553070a088273f341bff7fb4f2f46",
          "name": "hiBAYC",
          "symbol": "HIBAYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26623/thumb/hibayc.png?1659150840"
        },
        {
          "chainId": 1,
          "address": "0x300211def2a644b036a9bdd3e58159bb2074d388",
          "name": "Crosschain IOTX",
          "symbol": "CIOTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG?1631579018"
        },
        {
          "chainId": 1,
          "address": "0xe340b25fe32b1011616bb8ec495a4d503e322177",
          "name": "Aave AMM UniDAIUSDC",
          "symbol": "AAMMUNIDAIUSDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17218/thumb/aAmmUniDAIUSDC.png?1626878648"
        },
        {
          "chainId": 1,
          "address": "0xfd4168e642ebd04c3684a6cdb3a5e86de85d3908",
          "name": "The APIS",
          "symbol": "API",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14055/thumb/Screenshot_2022-03-10_at_11.09.50_AM.png?1646882043"
        },
        {
          "chainId": 1,
          "address": "0x02d3a27ac3f55d5d91fb0f52759842696a864217",
          "name": "Charged Particles",
          "symbol": "IONX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15836/thumb/DrKjSQMH_400x400.png?1622080222"
        },
        {
          "chainId": 1,
          "address": "0x0aacfbec6a24756c20d41914f2caba817c0d8521",
          "name": "YAM",
          "symbol": "YAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12530/thumb/YAM-icon.png?1600495536"
        },
        {
          "chainId": 1,
          "address": "0x9f5f463a7666e04cdabd22bd83569a5c72cb4f4d",
          "name": "Smol Su",
          "symbol": "SU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30179/thumb/SmolSu_%281%29.jpg?1683598316"
        },
        {
          "chainId": 1,
          "address": "0x8355dbe8b0e275abad27eb843f3eaf3fc855e525",
          "name": "Wolf Game Wool",
          "symbol": "WOOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20760/thumb/KM3RwIVx_400x400.jpg?1637650093"
        },
        {
          "chainId": 1,
          "address": "0x0a41e6d4d4897e8cec819ce7d10b2d7cda61dc94",
          "name": "YCLUB",
          "symbol": "SYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28267/thumb/9DC88DDF-CAB3-410A-87BA-6AB98DCD1B86.jpeg?1668771177"
        },
        {
          "chainId": 1,
          "address": "0xf55cd1e399e1cc3d95303048897a680be3313308",
          "name": "Maximus TRIO",
          "symbol": "TRIO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27684/thumb/IMG_1127.PNG?1665214637"
        },
        {
          "chainId": 1,
          "address": "0x581911b360b6eb3a14ef295a83a91dc2bce2d6f7",
          "name": "MileVerse",
          "symbol": "MVC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13146/thumb/kXSdwuxD_400x400.jpg?1605668418"
        },
        {
          "chainId": 1,
          "address": "0x4b48068864f77261838c7849a12853fb94c77a91",
          "name": "Lith Token",
          "symbol": "LITX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26327/thumb/200x200px_LOGO.jpeg?1657768654"
        },
        {
          "chainId": 1,
          "address": "0x1e797ce986c3cff4472f7d38d5c4aba55dfefe40",
          "name": "BlockCDN",
          "symbol": "BCDN",
          "decimals": 15,
          "logoURI": "https://assets.coingecko.com/coins/images/1301/thumb/blockcdn.png?1547350830"
        },
        {
          "chainId": 1,
          "address": "0x3ef389f264e07fff3106a3926f2a166d1393086f",
          "name": "Sator",
          "symbol": "SAO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19410/thumb/sator-logo-CMC.png?1635211626"
        },
        {
          "chainId": 1,
          "address": "0xedb171c18ce90b633db442f2a6f72874093b49ef",
          "name": "Wrapped Ampleforth",
          "symbol": "WAMPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951"
        },
        {
          "chainId": 1,
          "address": "0x343c6de13833bc7d9890eb6b1cd3fbebc730ebec",
          "name": "Decentralized Activism",
          "symbol": "DACT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27700/thumb/logodact200x200.png?1665305210"
        },
        {
          "chainId": 1,
          "address": "0x9f9913853f749b3fe6d6d4e16a1cc3c1656b6d51",
          "name": "BITT",
          "symbol": "BITT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961"
        },
        {
          "chainId": 1,
          "address": "0xaae3cf9968d26925bdb73ce3864e0084a20f4687",
          "name": "Farmland Protocol",
          "symbol": "FAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13497/thumb/70059912.jpg?1609193763"
        },
        {
          "chainId": 1,
          "address": "0x3391bc034f2935ef0e1e41619445f998b2680d35",
          "name": "IdleUSDC  Risk Adjusted ",
          "symbol": "IDLEUSDCSAFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11930/thumb/idleusdc-safe.png?1596263257"
        },
        {
          "chainId": 1,
          "address": "0xd07d9fe2d2cc067015e2b4917d24933804f42cfa",
          "name": "Tolar",
          "symbol": "TOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4487/thumb/tolar.png?1548759060"
        },
        {
          "chainId": 1,
          "address": "0xb2617246d0c6c0087f18703d576831899ca94f01",
          "name": "Zignaly",
          "symbol": "ZIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14796/thumb/zignaly.jpg?1618496339"
        },
        {
          "chainId": 1,
          "address": "0xf8483e2d6560585c02d46bf7b3186bf154a96166",
          "name": "IdeaChain",
          "symbol": "ICH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11053/thumb/4fJlFzca_400x400.jpg?1587609361"
        },
        {
          "chainId": 1,
          "address": "0x4df812f6064def1e5e029f1ca858777cc98d2d81",
          "name": "Xaurum",
          "symbol": "XAUR",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/461/thumb/xaurum.png?1548761197"
        },
        {
          "chainId": 1,
          "address": "0xb0e977e0214bcfeec0d685064244ad2b2487380b",
          "name": "CollarSwap",
          "symbol": "COLLAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29330/thumb/IMG_20230307_015235_378.jpg?1678172351"
        },
        {
          "chainId": 1,
          "address": "0xbb126042235e6bd38b17744cb31a8bf4a206c045",
          "name": "fanC",
          "symbol": "FANC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26500/thumb/hJ6MD49P_400x400.jpeg?1658356676"
        },
        {
          "chainId": 1,
          "address": "0x347a96a5bd06d2e15199b032f46fb724d6c73047",
          "name": "ASIC Token",
          "symbol": "ASIC",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/27952/thumb/ASIC_Token_Logo.png?1666668286"
        },
        {
          "chainId": 1,
          "address": "0x81994b9607e06ab3d5cf3afff9a67374f05f27d7",
          "name": "Flux USDT",
          "symbol": "FUSDT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29581/thumb/fUSDT-320.png?1679813769"
        },
        {
          "chainId": 1,
          "address": "0x044d078f1c86508e13328842cc75ac021b272958",
          "name": "Peercoin",
          "symbol": "PPC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/4/thumb/peercoin-icon-green-transparent_6x.png?1547033591"
        },
        {
          "chainId": 1,
          "address": "0xe1ec350ea16d1ddaff57f31387b2d9708eb7ce28",
          "name": "Pepechain",
          "symbol": "PC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30059/thumb/Pepechain.png?1683001252"
        },
        {
          "chainId": 1,
          "address": "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
          "name": "Status",
          "symbol": "SNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778"
        },
        {
          "chainId": 1,
          "address": "0x3a529a8d4f2ea64d206339fa12a3af4d431f53c3",
          "name": "Vendetta Finance",
          "symbol": "VEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26601/thumb/VF-Logo.png?1663768906"
        },
        {
          "chainId": 1,
          "address": "0x31c2415c946928e9fd1af83cdfa38d3edbd4326f",
          "name": "MADworld",
          "symbol": "UMAD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20939/thumb/UMAD_.png?1638238294"
        },
        {
          "chainId": 1,
          "address": "0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38",
          "name": "Pundi X",
          "symbol": "PUNDIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14571/thumb/vDyefsXq_400x400.jpg?1617085003"
        },
        {
          "chainId": 1,
          "address": "0x87de305311d5788e8da38d19bb427645b09cb4e5",
          "name": "Verox",
          "symbol": "VRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13669/thumb/IMG-20210115-000024.png?1610675319"
        },
        {
          "chainId": 1,
          "address": "0x10086399dd8c1e3de736724af52587a2044c9fa2",
          "name": "The Midas Touch Gold",
          "symbol": "TMTG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6255/thumb/the_midas_touch_gold.png?1547042292"
        },
        {
          "chainId": 1,
          "address": "0x56d3d0b333d29b48dd7c01d516a737b8866fbee2",
          "name": "Naetion",
          "symbol": "NTN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30130/thumb/IMG_8982.png?1683264366"
        },
        {
          "chainId": 1,
          "address": "0x409c4d8cd5d2924b9bc5509230d16a61289c8153",
          "name": "TONStarter",
          "symbol": "TOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17878/thumb/tos.PNG?1629703380"
        },
        {
          "chainId": 1,
          "address": "0x2f6f397332b0f8b4536430bcc97980aa838f62f9",
          "name": "Yuna",
          "symbol": "YUNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29384/thumb/logo_gl_%281%29.png?1678420944"
        },
        {
          "chainId": 1,
          "address": "0x107c4504cd79c5d2696ea0030a8dd4e92601b82e",
          "name": "Bloom",
          "symbol": "BLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2662/thumb/bloom.png?1547036854"
        },
        {
          "chainId": 1,
          "address": "0x2602278ee1882889b946eb11dc0e810075650983",
          "name": "Vader Protocol",
          "symbol": "VADER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20497/thumb/AcF08Jk1_400x400.jpg?1637131991"
        },
        {
          "chainId": 1,
          "address": "0x486f4641ef2b50cc130dadbd27b6f271723873b8",
          "name": "Adventurer Gold",
          "symbol": "GOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28427/thumb/anon-npc-pixel.png?1670548056"
        },
        {
          "chainId": 1,
          "address": "0x394a16744dcd805bb0ca7252e70691f0dcac56aa",
          "name": "Trazable",
          "symbol": "TRZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png?1649133506"
        },
        {
          "chainId": 1,
          "address": "0x3758e00b100876c854636ef8db61988931bb8025",
          "name": "Uniqly",
          "symbol": "UNIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14808/thumb/Hnet-com-image.png?1618538742"
        },
        {
          "chainId": 1,
          "address": "0x38e3b07d607def629b4f3f46a0082006fdd6cda2",
          "name": "Shibosu",
          "symbol": "SHIBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28670/thumb/shibo.png?1673003919"
        },
        {
          "chainId": 1,
          "address": "0x94e0bab2f6ab1f19f4750e42d7349f2740513ad5",
          "name": "Unicly",
          "symbol": "UNIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14720/thumb/Unicly.png?1621132059"
        },
        {
          "chainId": 1,
          "address": "0x692accdd8b86692427e0aa4752ae917df01cc56f",
          "name": "Sunrise",
          "symbol": "SUNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17601/thumb/ticker_sunc_200_200.png?1628864229"
        },
        {
          "chainId": 1,
          "address": "0x5c8c8d560048f34e5f7f8ad71f2f81a89dbd273e",
          "name": "CryptoArt Ai",
          "symbol": "CART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17583/thumb/logo-64.png?1629277823"
        },
        {
          "chainId": 1,
          "address": "0x5394794be8b6ed5572fcd6b27103f46b5f390e8f",
          "name": "Aave AMM UniYFIWETH",
          "symbol": "AAMMUNIYFIWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17258/thumb/aAmmUniYFIWETH.png?1626958146"
        },
        {
          "chainId": 1,
          "address": "0xd779eea9936b4e323cddff2529eb6f13d0a4d66e",
          "name": "EnterDAO",
          "symbol": "ENTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18684/thumb/entr.png?1635826690"
        },
        {
          "chainId": 1,
          "address": "0xc64500dd7b0f1794807e67802f8abbf5f8ffb054",
          "name": "Locus Chain",
          "symbol": "LOCUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6903/thumb/32169759_2050428658545017_7287666225423843328_n.jpg?1547043264"
        },
        {
          "chainId": 1,
          "address": "0x6f38e0f1a73c96cb3f42598613ea3474f09cb200",
          "name": "Draggable Aktionariat AG",
          "symbol": "DAKS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/28988/thumb/AKS.png?1675819486"
        },
        {
          "chainId": 1,
          "address": "0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26",
          "name": "Measurable Data",
          "symbol": "MDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2441/thumb/mdt_logo.png?1569813574"
        },
        {
          "chainId": 1,
          "address": "0x2f4404c4012476929b6503e1397707480bf23b7f",
          "name": "AITravis",
          "symbol": "TAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28933/thumb/ava_%283%29.png?1675500690"
        },
        {
          "chainId": 1,
          "address": "0x2604fa406be957e542beb89e6754fcde6815e83f",
          "name": "PlayKey",
          "symbol": "PKT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2116/thumb/playkey.png?1548331394"
        },
        {
          "chainId": 1,
          "address": "0x327673ae6b33bd3d90f0096870059994f30dc8af",
          "name": "Lympo Market",
          "symbol": "LMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15155/thumb/coin_%282%29.png?1619995483"
        },
        {
          "chainId": 1,
          "address": "0x786e995a975c2ab7c1dd252a9019fb0c91c9ca83",
          "name": "Landworld",
          "symbol": "LWD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25642/thumb/v-MhJXeSQuKSjz5gJRpptF5E5v95862DXJhkSeVabQcVsbBxzryaaQQ-eqwp0hvjrXQQmQRUKYW3gFfzUi3zVjhWoZKg79rmFF5dL6igKT0iWWpDtfNRcr6XA1mL9WiLgg6EOqwfmuLNu1-qan38OQJve32FKgJ7FL_fAyZRHvSuuIwO4qmcLPVqWo08oYELCC5ParMmS7FfNsHtu38u6j0gxw.jpg?1652949037"
        },
        {
          "chainId": 1,
          "address": "0xc4e8a9d47000ab8e59c7031e311762c68215e467",
          "name": "STARX",
          "symbol": "STARX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8417/thumb/starworks.PNG?1640681958"
        },
        {
          "chainId": 1,
          "address": "0x517abf1fcdbd76bc75b532683ada9113e313a128",
          "name": "Doke Inu",
          "symbol": "DOKE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29234/thumb/Doke_logo.jpg?1677555400"
        },
        {
          "chainId": 1,
          "address": "0x6fc2f1044a3b9bb3e43a43ec8f840843ed753061",
          "name": "Robo",
          "symbol": "ROBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14611/thumb/rlogo200.jpg?1617247446"
        },
        {
          "chainId": 1,
          "address": "0x3cbb7f5d7499af626026e96a2f05df806f2200dc",
          "name": "PandaDAO",
          "symbol": "PANDA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24926/thumb/PandaDAO_logo_512.png?1649388983"
        },
        {
          "chainId": 1,
          "address": "0x61107a409fffe1965126aa456af679719695c69c",
          "name": "Umi Digital",
          "symbol": "UMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17874/thumb/Umi-200-x-200.png?1629700077"
        },
        {
          "chainId": 1,
          "address": "0x3543638ed4a9006e4840b105944271bcea15605d",
          "name": "U Network",
          "symbol": "UUU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3877/thumb/2645.png?1547038930"
        },
        {
          "chainId": 1,
          "address": "0xae697f994fc5ebc000f8e22ebffee04612f98a0d",
          "name": "LGCY Network",
          "symbol": "LGCY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12181/thumb/LGCY_network.jpg?1597926587"
        },
        {
          "chainId": 1,
          "address": "0x48fb253446873234f2febbf9bdeaa72d9d387f94",
          "name": "Bancor Governance",
          "symbol": "VBNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14053/thumb/bancorvbnt_32.png?1614048819"
        },
        {
          "chainId": 1,
          "address": "0x38a94e92a19e970c144ded0b2dd47278ca11cc1f",
          "name": "Falcon Nine",
          "symbol": "F9",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16858/thumb/logo-f9.png?1625465513"
        },
        {
          "chainId": 1,
          "address": "0xd228ceca8560ddb74137f44d9c582e1a646be763",
          "name": "TeleBridge",
          "symbol": "TB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29777/thumb/TB.jpg?1681273191"
        },
        {
          "chainId": 1,
          "address": "0xfae4ee59cdd86e3be9e8b90b53aa866327d7c090",
          "name": "CPChain",
          "symbol": "CPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2760/thumb/cpchain.png?1547036953"
        },
        {
          "chainId": 1,
          "address": "0x8eb1779a32678452eb273a22d413207299904d90",
          "name": "Pochi Inu",
          "symbol": "POCHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23816/thumb/logo-gold.png?1645513711"
        },
        {
          "chainId": 1,
          "address": "0x1b5036bec1b82d44d52fa953a370b3c6cd9328b5",
          "name": "Elan",
          "symbol": "ELAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27635/thumb/coin_logo_512x512.png?1675184763"
        },
        {
          "chainId": 1,
          "address": "0x6cadf6abbceb53e631c288778daacf125481c1bb",
          "name": "The Citadel",
          "symbol": "CITADEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20445/thumb/2023-03-16_22.55.22.jpg?1679066452"
        },
        {
          "chainId": 1,
          "address": "0xea319e87cf06203dae107dd8e5672175e3ee976c",
          "name": "Surf Finance",
          "symbol": "SURF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12982/thumb/surf_200x200.png?1604050261"
        },
        {
          "chainId": 1,
          "address": "0xbc6e06778708177a18210181b073da747c88490a",
          "name": "MOBLAND",
          "symbol": "SYNR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22868/thumb/FullML_Yellow_200px.png?1648031600"
        },
        {
          "chainId": 1,
          "address": "0xce20bb92ccf9bbf5091ef85649e71e552819ad8c",
          "name": "Smart Game Finance",
          "symbol": "SMART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29072/thumb/smart.png?1676438925"
        },
        {
          "chainId": 1,
          "address": "0x90de74265a416e1393a450752175aed98fe11517",
          "name": "Unlock Protocol",
          "symbol": "UDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14545/thumb/unlock.jpg?1616948136"
        },
        {
          "chainId": 1,
          "address": "0x7690202e2c2297bcd03664e31116d1dffe7e3b73",
          "name": "Cat in a Box Ether",
          "symbol": "BOXETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29927/thumb/boxETH.png?1682139665"
        },
        {
          "chainId": 1,
          "address": "0x662b67d00a13faf93254714dd601f5ed49ef2f51",
          "name": "Orbit Chain",
          "symbol": "ORC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9782/thumb/-p1Br7oh_400x400.png?1571716593"
        },
        {
          "chainId": 1,
          "address": "0x2b1d36f5b61addaf7da7ebbd11b35fd8cfb0de31",
          "name": "Interport Token",
          "symbol": "ITP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28338/thumb/ITP_Logo_200.png?1669794314"
        },
        {
          "chainId": 1,
          "address": "0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3",
          "name": "Fodl Finance",
          "symbol": "FODL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19040/thumb/Fodl_Symbol_Gradient.png?1651644153"
        },
        {
          "chainId": 1,
          "address": "0x8d610e20481f4c4f3acb87bba9c46bef7795fdfe",
          "name": "Unity Network",
          "symbol": "UNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17143/thumb/unity.PNG?1626658763"
        },
        {
          "chainId": 1,
          "address": "0x9cb1aeafcc8a9406632c5b084246ea72f62d37b6",
          "name": "LBK",
          "symbol": "LBK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9492/thumb/lbk.jpeg?1586144855"
        },
        {
          "chainId": 1,
          "address": "0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c",
          "name": "apM Coin",
          "symbol": "APM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10069/thumb/PvDCvh5h_400x400.png?1575460334"
        },
        {
          "chainId": 1,
          "address": "0x0fd67b4ceb9b607ef206904ec73459c4880132c9",
          "name": "ShoeFy",
          "symbol": "SHOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg?1634371392"
        },
        {
          "chainId": 1,
          "address": "0x2a2550e0a75acec6d811ae3930732f7f3ad67588",
          "name": "PathDAO",
          "symbol": "PATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21146/thumb/thumbnail.png?1655283979"
        },
        {
          "chainId": 1,
          "address": "0x0e192d382a36de7011f795acc4391cd302003606",
          "name": "Futureswap",
          "symbol": "FST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14520/thumb/futureswap_logo.png?1634122916"
        },
        {
          "chainId": 1,
          "address": "0xd82df0abd3f51425eb15ef7580fda55727875f14",
          "name": "DAV Network",
          "symbol": "DAV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2842/thumb/DAV-Network-logo.jpg?1547037011"
        },
        {
          "chainId": 1,
          "address": "0xa696a63cc78dffa1a63e9e50587c197387ff6c7e",
          "name": "WBTC yVault",
          "symbol": "YVWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/28796/thumb/yvWBTC-128-0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E.png?1674226331"
        },
        {
          "chainId": 1,
          "address": "0x6f620ec89b8479e97a6985792d0c64f237566746",
          "name": "WePiggy Coin",
          "symbol": "WPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945"
        },
        {
          "chainId": 1,
          "address": "0x6b32022693210cd2cfc466b9ac0085de8fc34ea6",
          "name": "Maximus DECI",
          "symbol": "DECI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27693/thumb/Deci.png?1665222159"
        },
        {
          "chainId": 1,
          "address": "0x5408245a4d7c685f59ce6d3b8b35916dd6c11a99",
          "name": "AnonZK",
          "symbol": "AZK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29491/thumb/200x200.png?1679215896"
        },
        {
          "chainId": 1,
          "address": "0x1900e8b5619a3596745f715d0427fe617c729ba9",
          "name": "Chainbing",
          "symbol": "CBG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18052/thumb/j3saoPU.png?1630330010"
        },
        {
          "chainId": 1,
          "address": "0xb7c9e99da8a857ce576a830a9c19312114d9de02",
          "name": "Maximus TEAM",
          "symbol": "TEAM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27711/thumb/TEAM.png?1665451692"
        },
        {
          "chainId": 1,
          "address": "0xea4a2327e75252517535fd013b7c6706609819db",
          "name": "Shibarium Name Service",
          "symbol": "SNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29486/thumb/shibarium.jpg?1679136962"
        },
        {
          "chainId": 1,
          "address": "0x2162f572b25f7358db9376ab58a947a4e45cede1",
          "name": "LABEL Foundation",
          "symbol": "LBL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png?1634680838"
        },
        {
          "chainId": 1,
          "address": "0x6247c86b016bc4d9ae141849c0a9eb38c004b742",
          "name": "Hotelium",
          "symbol": "HTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8812/thumb/hotelium.PNG?1561539590"
        },
        {
          "chainId": 1,
          "address": "0x1b396cf90504686b7ede2760eebb053d30d1f389",
          "name": "Reality Metaverse",
          "symbol": "RLTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29806/thumb/rltm.png?1681362836"
        },
        {
          "chainId": 1,
          "address": "0x2a8e1e676ec238d8a992307b495b45b3feaa5e86",
          "name": "Origin Dollar",
          "symbol": "OUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12589/thumb/ousd-logo-200x200.png?1600943287"
        },
        {
          "chainId": 1,
          "address": "0xc3681a720605bd6f8fe9a2fabff6a7cdecdc605d",
          "name": "Nihao",
          "symbol": "NIHAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30619/thumb/nihaowhitecat.png?1685772299"
        },
        {
          "chainId": 1,
          "address": "0xb1f66997a5760428d3a87d68b90bfe0ae64121cc",
          "name": "LuaSwap",
          "symbol": "LUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12627/thumb/Screenshot_2020-09-28_at_6.24.59_PM.jpg?1601288721"
        },
        {
          "chainId": 1,
          "address": "0x85225ed797fd4128ac45a992c46ea4681a7a15da",
          "name": "Hyperbolic Protocol",
          "symbol": "HYPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30743/thumb/HP_200x200.png?1686725112"
        },
        {
          "chainId": 1,
          "address": "0x59e9261255644c411afdd00bd89162d09d862e38",
          "name": "ETHA Lend",
          "symbol": "ETHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14141/thumb/etha_logo200x200.png?1614646986"
        },
        {
          "chainId": 1,
          "address": "0x20c36f062a31865bed8a5b1e512d9a1a20aa333a",
          "name": "DefiDollar DAO",
          "symbol": "DFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12959/thumb/DFD.jpg?1604415975"
        },
        {
          "chainId": 1,
          "address": "0xb13de094cc5cee6c4cc0a3737bf0290166d9ca5d",
          "name": "GoWithMi",
          "symbol": "GMAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7162/thumb/qXNbxVny_400x400.png?1564433322"
        },
        {
          "chainId": 1,
          "address": "0x6e765d26388a17a6e86c49a8e41df3f58abcd337",
          "name": "Kangal",
          "symbol": "KANGAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1622341641"
        },
        {
          "chainId": 1,
          "address": "0x866f8a50a64e68ca66e97e032c5da99538b3f942",
          "name": "eBlockStock",
          "symbol": "EBSO",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/18636/thumb/eBSO_logo_szines.png?1632730502"
        },
        {
          "chainId": 1,
          "address": "0xf8ebf4849f1fa4faf0dff2106a173d3a6cb2eb3a",
          "name": "Troll",
          "symbol": "TROLL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29897/thumb/TROLL.jpeg?1681983038"
        },
        {
          "chainId": 1,
          "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
          "name": "Meter Governance mapped by Meter io",
          "symbol": "EMTRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12175/thumb/Dark-blue-icon-transparent-vector-white-icon200x200.png?1597819237"
        },
        {
          "chainId": 1,
          "address": "0xa01199c61841fce3b3dafb83fefc1899715c8756",
          "name": "Cirus",
          "symbol": "CIRUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17798/thumb/8p0Bvi90_400x400.jpg?1629281912"
        },
        {
          "chainId": 1,
          "address": "0x049715c70fdbdd2be4814f76a53dc3d6f4367756",
          "name": "Nezuko",
          "symbol": "NEZUKO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30014/thumb/Nezuko_Logo.png?1682497967"
        },
        {
          "chainId": 1,
          "address": "0xdc5864ede28bd4405aa04d93e05a0531797d9d59",
          "name": "Falcon Project",
          "symbol": "FNT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/11579/thumb/falcon_ava_black.png?1591317863"
        },
        {
          "chainId": 1,
          "address": "0xd54619e0b9899d74cc9b981354eb6b59732c43b1",
          "name": "GalleryCoin",
          "symbol": "GLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29372/thumb/%EA%B0%A4%EB%9F%AC%EB%A6%AC%EC%BD%94%EC%9D%B8_%EB%A1%9C%EA%B3%A0%28200_200%29.png?1678350274"
        },
        {
          "chainId": 1,
          "address": "0xfffffffff15abf397da76f1dcc1a1604f45126db",
          "name": "Falconswap",
          "symbol": "FSW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12256/thumb/falconswap.png?1598534184"
        },
        {
          "chainId": 1,
          "address": "0x47e67ba66b0699500f18a53f94e2b9db3d47437e",
          "name": "PlayGame",
          "symbol": "PXG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7242/thumb/pxg-coin_3x.png?1547043841"
        },
        {
          "chainId": 1,
          "address": "0xd6710559656f2ecd336b153b0719500fff8c2435",
          "name": "Platonic Quintessence",
          "symbol": "PLAQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29851/thumb/PLAQ_200.PNG?1681783872"
        },
        {
          "chainId": 1,
          "address": "0x7cb683151a83c2b10a30cbb003cda9996228a2ba",
          "name": "IYKYK",
          "symbol": "IYKYK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30022/thumb/iykyk200.png?1682566426"
        },
        {
          "chainId": 1,
          "address": "0x922e2708462c7a3d014d8344f7c4d92b27ecf332",
          "name": "Neuroni AI",
          "symbol": "NEURONI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28887/thumb/200X200.png?1675154213"
        },
        {
          "chainId": 1,
          "address": "0xa27a6fa698a63f82e559914c11c6cd888060660b",
          "name": "Shinsekai",
          "symbol": "SHIN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29482/thumb/Shinsekai_200x200.jpg?1679131662"
        },
        {
          "chainId": 1,
          "address": "0x6149c26cd2f7b5ccdb32029af817123f6e37df5b",
          "name": "Launchpool",
          "symbol": "LPOOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg?1613976219"
        },
        {
          "chainId": 1,
          "address": "0xbeab712832112bd7664226db7cd025b153d3af55",
          "name": "Bright Union",
          "symbol": "BRIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17552/thumb/BrightToken_Token-only_200x200-1.png?1628227007"
        },
        {
          "chainId": 1,
          "address": "0xc3c221fe28c33814c28c822b631fd76047ef1a63",
          "name": "Millimeter",
          "symbol": "MM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12941/thumb/mm%EB%A1%9C%EA%B3%A0-2.png?1603765868"
        },
        {
          "chainId": 1,
          "address": "0xef2e239b21a81719abaabc6239e2e90859b44d9b",
          "name": "Talent TTX",
          "symbol": "TTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12770/thumb/ttx.png?1640758151"
        },
        {
          "chainId": 1,
          "address": "0x28b5e12cce51f15594b0b91d5b5adaa70f684a02",
          "name": "Napoleon X",
          "symbol": "NPX",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg?1547035570"
        },
        {
          "chainId": 1,
          "address": "0xbf52f2ab39e26e0951d2a02b49b7702abe30406a",
          "name": "ODEM",
          "symbol": "ODE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3752/thumb/odem.png?1548329893"
        },
        {
          "chainId": 1,
          "address": "0xaa61d5dec73971cd4a026ef2820bb87b4a4ed8d6",
          "name": "CRE8R DAO",
          "symbol": "CRE8R",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22568/thumb/cropped-FINALwithbrick-2-1-1.png?1642060468"
        },
        {
          "chainId": 1,
          "address": "0xaf75d880b3128981d1fed3292fc02e3fb37acd53",
          "name": "TruthGPT",
          "symbol": "TRUTH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29867/thumb/TruthGPT.png?1681891825"
        },
        {
          "chainId": 1,
          "address": "0x73b708e84837ffccde2933e3a1531fe61d5e80ef",
          "name": "Betero",
          "symbol": "BTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529"
        },
        {
          "chainId": 1,
          "address": "0x8167d3b1024cb51a2dd1b4d889ddf7023420796a",
          "name": "Noku",
          "symbol": "NOKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png?1643171688"
        },
        {
          "chainId": 1,
          "address": "0x05079687d35b93538cbd59fe5596380cae9054a9",
          "name": "BitSong",
          "symbol": "BTSG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5041/thumb/logo_-_2021-01-10T210801.390.png?1610284134"
        },
        {
          "chainId": 1,
          "address": "0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3",
          "name": "Decentr",
          "symbol": "DEC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11816/thumb/Decentr.png?1594637985"
        },
        {
          "chainId": 1,
          "address": "0xaaaf91d9b90df800df4f55c205fd6989c977e73a",
          "name": "Monolith",
          "symbol": "TKN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/675/thumb/Monolith.png?1566296607"
        },
        {
          "chainId": 1,
          "address": "0xbe00734799a67a62af2819825580318ac1b1e4ec",
          "name": "ordinex",
          "symbol": "ORD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29084/thumb/tw-logo%281%29.png?1676453023"
        },
        {
          "chainId": 1,
          "address": "0xd32641191578ea9b208125ddd4ec5e7b84fcab4c",
          "name": "MDsquare",
          "symbol": "TMED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8558/thumb/bAdnHTWUIl4rFB4EfbH2L50BW7xi2T9ubci7v6xfYDipXkrG8Wb4LS8g6eAiMsSKqLJfV-FjH5TrYALqACw4MM2A9NfK_L6Ujnddhw1uEgL0hmVQnY96xZeL0C4kkFRJalreK9wUBzjZ4pRhZLdQf9h1drSTF7NE9moAdQ7KtuOvJqommn_q-TjQpF8GUB7J2Ejs7zIvhXXsUHGI1.jpg?1559198882"
        },
        {
          "chainId": 1,
          "address": "0x06677dc4fe12d3ba3c7ccfd0df8cd45e4d4095bf",
          "name": "Work Quest",
          "symbol": "WQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30040/thumb/WQT-1_%281%29.png?1682991564"
        },
        {
          "chainId": 1,
          "address": "0x0c93b616933b0cd03b201b29cd8a22681dd9e0d9",
          "name": "HollyGold",
          "symbol": "HGOLD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13652/thumb/QnfGCea3aO_Qb-Gd9n6MSFE_eBVAr87WPoxAzf0gKdcGOEL4K5J6A5cpGinzLvHclj_UHkCnw9XTlGoNYd0H8T_Ebe8voLS49MAZO2NIknCxytaYFtt5_u12RQg-kpmWHcETzAFlDgk9iNXAL-88fB_l-DRi8WEsgSJzXXjztKzQlpXuET6d4_98pykED6uyq1-4PMVg05P7tUN.jpg?1610574345"
        },
        {
          "chainId": 1,
          "address": "0xd6327ce1fb9d6020e8c2c0e124a1ec23dcab7536",
          "name": "Cuminu",
          "symbol": "CUMINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15832/thumb/CUMINU.png?1628849016"
        },
        {
          "chainId": 1,
          "address": "0xf03d5fc6e08de6ad886fca34abf9a59ef633b78a",
          "name": "Capybara Token",
          "symbol": "CAPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30223/thumb/capylogo.png?1683629494"
        },
        {
          "chainId": 1,
          "address": "0xc9d21e5a24110b67af31af464edfdc2dae5ec7d5",
          "name": "Bitmeme",
          "symbol": "BTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30879/thumb/bmpng.png?1688450158"
        },
        {
          "chainId": 1,
          "address": "0x602f65bb8b8098ad804e99db6760fd36208cd967",
          "name": "Mops",
          "symbol": "MOPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26900/thumb/mops.png?1660724129"
        },
        {
          "chainId": 1,
          "address": "0x2365a4890ed8965e564b7e2d27c38ba67fec4c6f",
          "name": "Aave AMM UniWBTCUSDC",
          "symbol": "AAMMUNIWBTCUSDC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17255/thumb/aAmmUniWBTCUSDC.png?1626956709"
        },
        {
          "chainId": 1,
          "address": "0x9f5f3cfd7a32700c93f971637407ff17b91c7342",
          "name": "Scry info",
          "symbol": "DDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2569/thumb/Scry.info.jpg?1547036695"
        },
        {
          "chainId": 1,
          "address": "0x6d60a8dfb16d09f67d46fcd36a0cd310078257ca",
          "name": "Centurion Invest",
          "symbol": "CIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28483/thumb/PHOTO-2022-05-27-11-34-42.jpg?1671073667"
        },
        {
          "chainId": 1,
          "address": "0xb131f337c45d386ceec234e194b2663d5c3d9dcf",
          "name": "iCommunity",
          "symbol": "ICOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23667/thumb/logo-icommunity.png?1644993652"
        },
        {
          "chainId": 1,
          "address": "0x6aeb95f06cda84ca345c2de0f3b7f96923a44f4c",
          "name": "Rentberry",
          "symbol": "BERRY",
          "decimals": 14,
          "logoURI": "https://assets.coingecko.com/coins/images/1787/thumb/rentberry.png?1548608046"
        },
        {
          "chainId": 1,
          "address": "0xec505c81d6a7567b5bde804870b1038832fe6da1",
          "name": "Coinhound",
          "symbol": "CND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30056/thumb/%28200x200%29_Coinhound_Icon.png?1682999957"
        },
        {
          "chainId": 1,
          "address": "0x76bc677d444f1e9d57daf5187ee2b7dc852745ae",
          "name": "Offshift",
          "symbol": "XFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png?1614570441"
        },
        {
          "chainId": 1,
          "address": "0x28fac5334c9f7262b3a3fe707e250e01053e07b5",
          "name": "IdleUSDT  Risk Adjusted ",
          "symbol": "IDLEUSDTSAFE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11931/thumb/idleusdt-safe.png?1596263270"
        },
        {
          "chainId": 1,
          "address": "0x69570f3e84f51ea70b7b68055c8d667e77735a25",
          "name": "Betswap gg",
          "symbol": "BSGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22496/thumb/betswap.jpg?1641924550"
        },
        {
          "chainId": 1,
          "address": "0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13",
          "name": "Jigstack",
          "symbol": "STAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14978/thumb/jigstack.PNG?1619216498"
        },
        {
          "chainId": 1,
          "address": "0xcbe7142f5c16755d8683ba329efa1abf7b54482d",
          "name": "MedicalVeda",
          "symbol": "MVEDA",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690"
        },
        {
          "chainId": 1,
          "address": "0xa2a54f1ec1f09316ef12c1770d32ed8f21b1fb6a",
          "name": "DigiFinex",
          "symbol": "DFT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4819/thumb/digifinextoken.png?1547040187"
        },
        {
          "chainId": 1,
          "address": "0xe2fc859c838f31c1450448f4fed92e3284ae49e9",
          "name": "Jigen",
          "symbol": "JIG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21716/thumb/MbfJ2kSM_400x400.png?1639863526"
        },
        {
          "chainId": 1,
          "address": "0x9ee91f9f426fa633d227f7a9b000e28b9dfd8599",
          "name": "Lido Staked Matic",
          "symbol": "STMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24185/thumb/stMATIC.png?1646789287"
        },
        {
          "chainId": 1,
          "address": "0x87d73e916d7057945c9bcd8cdd94e42a6f47f776",
          "name": "NFTX",
          "symbol": "NFTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13574/thumb/NFTX_%28Real%29.jpg?1613449530"
        },
        {
          "chainId": 1,
          "address": "0x356a5160f2b34bc8d88fb084745465ebbbed0174",
          "name": "INVI",
          "symbol": "INVI",
          "decimals": 13,
          "logoURI": "https://assets.coingecko.com/coins/images/17363/thumb/INVI-Ticker.png?1627374966"
        },
        {
          "chainId": 1,
          "address": "0x4fc15c91a9c4a9efb404174464687e8e128730c2",
          "name": "STAT",
          "symbol": "STAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26602/thumb/stat.png?1667291701"
        },
        {
          "chainId": 1,
          "address": "0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de",
          "name": "Spaceswap MILK2",
          "symbol": "MILK2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12771/thumb/milk.png?1633423719"
        },
        {
          "chainId": 1,
          "address": "0x52a8845df664d76c69d2eea607cd793565af42b8",
          "name": "ApeX",
          "symbol": "APEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25266/thumb/CxpMECpk_400x400_%281%29.png?1651067984"
        },
        {
          "chainId": 1,
          "address": "0x6006fc2a849fedaba8330ce36f5133de01f96189",
          "name": "Spaceswap SHAKE",
          "symbol": "SHAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12765/thumb/shake.png?1633423725"
        },
        {
          "chainId": 1,
          "address": "0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab",
          "name": "Alchemist",
          "symbol": "MIST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14655/thumb/79158662.png?1617589045"
        },
        {
          "chainId": 1,
          "address": "0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96",
          "name": "New Order",
          "symbol": "NEWO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21440/thumb/new-order-icon-256px.png?1639125759"
        },
        {
          "chainId": 1,
          "address": "0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787",
          "name": "PAID Network",
          "symbol": "PAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13761/thumb/512.png?1648630881"
        },
        {
          "chainId": 1,
          "address": "0xf90c7f66eac7e2130bf677d69a250b2136cf6697",
          "name": "HAKI Token",
          "symbol": "HAKI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7945/thumb/haki.png?1667030461"
        },
        {
          "chainId": 1,
          "address": "0x0e0989b1f9b8a38983c2ba8053269ca62ec9b195",
          "name": "Po et",
          "symbol": "POE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/910/thumb/poet.png?1548331583"
        },
        {
          "chainId": 1,
          "address": "0x947aeb02304391f8fbe5b25d7d98d649b57b1788",
          "name": "Mandala Exchange",
          "symbol": "MDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13736/thumb/png-cmc.png?1611288725"
        },
        {
          "chainId": 1,
          "address": "0xe7f72bc0252ca7b16dbb72eeee1afcdb2429f2dd",
          "name": "NFTLaunch",
          "symbol": "NFTL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG?1630652662"
        },
        {
          "chainId": 1,
          "address": "0xdfdb7f72c1f195c5951a234e8db9806eb0635346",
          "name": "Feisty Doge NFT",
          "symbol": "NFD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17834/thumb/doge-fractionalized.png?1629390495"
        },
        {
          "chainId": 1,
          "address": "0x5c8190b76e90b4dd0702740cf6eb0f7ee01ab5e9",
          "name": "Archive AI",
          "symbol": "ARCAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28939/thumb/pp.JPG?1675841452"
        },
        {
          "chainId": 1,
          "address": "0x998ffe1e43facffb941dc337dd0468d52ba5b48a",
          "name": "Rupiah Token",
          "symbol": "IDRT",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531"
        },
        {
          "chainId": 1,
          "address": "0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24",
          "name": "Gourmet Galaxy",
          "symbol": "GUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13846/thumb/gum.png?1612320864"
        },
        {
          "chainId": 1,
          "address": "0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f",
          "name": "RAMP  OLD ",
          "symbol": "RAMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12837/thumb/RAMP-Logo-v2-1000pxsq.png?1617952606"
        },
        {
          "chainId": 1,
          "address": "0x02a739710d5e469ffca483f898ee9aea27b8bb8f",
          "name": "BasedPepe",
          "symbol": "BPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30220/thumb/Based_Pepe.png?1683628948"
        },
        {
          "chainId": 1,
          "address": "0x6e8908cfa881c9f6f2c64d3436e7b80b1bf0093f",
          "name": "Bistroo",
          "symbol": "BIST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png?1659342030"
        },
        {
          "chainId": 1,
          "address": "0x3f382dbd960e3a9bbceae22651e88158d2791550",
          "name": "Aavegotchi",
          "symbol": "GHST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12467/thumb/GHST.png?1689225671"
        },
        {
          "chainId": 1,
          "address": "0xaaef88cea01475125522e117bfe45cf32044e238",
          "name": "GuildFi",
          "symbol": "GF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20933/thumb/guildfi-logo-200.png?1638003537"
        },
        {
          "chainId": 1,
          "address": "0xc97d6c52f3add91fa1c5287a453d7444aecbca83",
          "name": "Degen Zoo",
          "symbol": "DZOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29224/thumb/FAGWW5fe_400x400.jpeg?1677481253"
        },
        {
          "chainId": 1,
          "address": "0x04c618cdbc1d59142dfeb4b9864835a06983ec2d",
          "name": "Joseon Mun",
          "symbol": "JSM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30387/thumb/IMG_20230511_130419_023.jpg?1684307608"
        },
        {
          "chainId": 1,
          "address": "0x7da2641000cbb407c329310c461b2cb9c70c3046",
          "name": "Delysium",
          "symbol": "AGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29299/thumb/AGI_logo_200.png?1677915840"
        },
        {
          "chainId": 1,
          "address": "0x6d56cddd23a693ed3851fa0a5d8c67a8739537c8",
          "name": "Hedgehog Racer",
          "symbol": "SONIC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31108/thumb/LOGO.png?1690782822"
        },
        {
          "chainId": 1,
          "address": "0xff19138b039d938db46bdda0067dc4ba132ec71c",
          "name": "Snetwork",
          "symbol": "SNET",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/5382/thumb/snetwork.png?1548609904"
        },
        {
          "chainId": 1,
          "address": "0x7e77dcb127f99ece88230a64db8d595f31f1b068",
          "name": "Escrowed Illuvium 2",
          "symbol": "SILV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28860/thumb/silv2.png?1674980147"
        },
        {
          "chainId": 1,
          "address": "0x045414e728067ab3da4bceafc0d992d59183463a",
          "name": "B watch Box",
          "symbol": "BOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25324/thumb/Screenshot_from_2022-04-29_17-04-42.png?1651223108"
        },
        {
          "chainId": 1,
          "address": "0x5ceaa82ffd3b1c0193ff65fc93510846dd56f22c",
          "name": "Strider Bot",
          "symbol": "STRIDR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30739/thumb/IMG_20230611_130538_872.jpg?1686724091"
        },
        {
          "chainId": 1,
          "address": "0x8e30ea2329d95802fd804f4291220b0e2f579812",
          "name": "Decentralized Vulnerability Platform",
          "symbol": "DVP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9424/thumb/4520.png?1568598223"
        },
        {
          "chainId": 1,
          "address": "0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc",
          "name": "MyBit",
          "symbol": "MYB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1240/thumb/mybit.png?1547035264"
        },
        {
          "chainId": 1,
          "address": "0x87edffde3e14c7a66c9b9724747a1c5696b742e6",
          "name": "SWAG Finance",
          "symbol": "SWAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12805/thumb/photo_2020-10-14_23.17.02.jpeg?1602688642"
        },
        {
          "chainId": 1,
          "address": "0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a",
          "name": "Populous",
          "symbol": "PPT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/753/thumb/populous.png?1548331905"
        },
        {
          "chainId": 1,
          "address": "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
          "name": "Keep3rV1",
          "symbol": "KP3R",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458"
        },
        {
          "chainId": 1,
          "address": "0x65ad6a2288b2dd23e466226397c8f5d1794e58fc",
          "name": "GamyFi",
          "symbol": "GFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124"
        },
        {
          "chainId": 1,
          "address": "0x0c9b3ab1bd0cf0745625381f5c3aa1cd9bbc7abb",
          "name": "Exeno",
          "symbol": "EXN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185"
        },
        {
          "chainId": 1,
          "address": "0xe75ad3aab14e4b0df8c5da4286608dabb21bd864",
          "name": "Double A Chain",
          "symbol": "AAC",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/2577/thumb/acute-angle-cloud.png?1547036708"
        },
        {
          "chainId": 1,
          "address": "0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a",
          "name": "Netvrk",
          "symbol": "NTVRK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15721/thumb/netvrk_icon.png?1627536091"
        },
        {
          "chainId": 1,
          "address": "0xa1817b6d8d890f3943b61648992730373b71f156",
          "name": "Mongoose",
          "symbol": "MONGOOSE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21456/thumb/61b142508fba4235f29d57eb_Frame_24_%281%29.png?1639291023"
        },
        {
          "chainId": 1,
          "address": "0xcaabcaa4ca42e1d86de1a201c818639def0ba7a7",
          "name": "Talken",
          "symbol": "TALK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18146/thumb/4NNLx3BF_400x400.jpg?1630675291"
        },
        {
          "chainId": 1,
          "address": "0x6e2a43be0b1d33b726f0ca3b8de60b3482b8b050",
          "name": "Arkham",
          "symbol": "ARKM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30929/thumb/Arkham_Logo_CG.png?1689672102"
        },
        {
          "chainId": 1,
          "address": "0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824",
          "name": "MoonSwap",
          "symbol": "MOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12441/thumb/moon.jpg?1599880968"
        },
        {
          "chainId": 1,
          "address": "0xa2881f7f441267042f9778ffa0d4f834693426be",
          "name": "The HUSL",
          "symbol": "HUSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png?1650437671"
        },
        {
          "chainId": 1,
          "address": "0x96e61422b6a9ba0e068b6c5add4ffabc6a4aae27",
          "name": "Iron Bank EUR",
          "symbol": "IBEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17285/thumb/Iron_Bank_Euro.png?1627900506"
        },
        {
          "chainId": 1,
          "address": "0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299",
          "name": "RBX",
          "symbol": "RBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19253/thumb/output-onlinepngtools-9.png?1634801960"
        },
        {
          "chainId": 1,
          "address": "0x147faf8de9d8d8daae129b187f0d02d819126750",
          "name": "GeoDB",
          "symbol": "GEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11130/thumb/geodb.png?1588941704"
        },
        {
          "chainId": 1,
          "address": "0xfa5047c9c78b8877af97bdcb85db743fd7313d4a",
          "name": "Rook",
          "symbol": "ROOK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506"
        },
        {
          "chainId": 1,
          "address": "0x3ba925fdeae6b46d0bb4d424d829982cb2f7309e",
          "name": "RabbitX",
          "symbol": "RBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30048/thumb/RBX.png?1683253839"
        },
        {
          "chainId": 1,
          "address": "0xb9ef770b6a5e12e45983c5d80545258aa38f3b78",
          "name": "Zus",
          "symbol": "ZCN",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/4934/thumb/200x200_transparent.png?1669366739"
        },
        {
          "chainId": 1,
          "address": "0xfb19075d77a0f111796fb259819830f4780f1429",
          "name": "Fenerbah e",
          "symbol": "FB",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17711/thumb/FB_Logo.png?1629082961"
        },
        {
          "chainId": 1,
          "address": "0xf2051511b9b121394fa75b8f7d4e7424337af687",
          "name": "DAOhaus",
          "symbol": "HAUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14551/thumb/jN3kkqke_400x400.png?1616990048"
        },
        {
          "chainId": 1,
          "address": "0x618679df9efcd19694bb1daa8d00718eacfa2883",
          "name": "Universe XYZ",
          "symbol": "XYZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15809/thumb/universexyz.png?1621950483"
        },
        {
          "chainId": 1,
          "address": "0xf5cfbc74057c610c8ef151a439252680ac68c6dc",
          "name": "Octopus Network",
          "symbol": "OCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18025/thumb/octopus_network.png?1630290273"
        },
        {
          "chainId": 1,
          "address": "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
          "name": "DeFi Pulse Index",
          "symbol": "DPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053"
        },
        {
          "chainId": 1,
          "address": "0x888cea2bbdd5d47a4032cf63668d7525c74af57a",
          "name": "Poof Token",
          "symbol": "POOF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28885/thumb/C3AD4384-2AB9-4364-8DEA-AE32A091F182.jpeg?1675147686"
        },
        {
          "chainId": 1,
          "address": "0x8d008cac1a5cb08ac962b1e34e977b79abeee88d",
          "name": "Pepe Analytics",
          "symbol": "PEPEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31085/thumb/cg.jpeg?1690426151"
        },
        {
          "chainId": 1,
          "address": "0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3",
          "name": "Origin Ether",
          "symbol": "OETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29733/thumb/OETH.png?1681094770"
        },
        {
          "chainId": 1,
          "address": "0x881ba05de1e78f549cc63a8f6cabb1d4ad32250d",
          "name": "00 Token",
          "symbol": "00",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27742/thumb/200x200.png?1665623317"
        },
        {
          "chainId": 1,
          "address": "0xa444ec96ee01bb219a44b285de47bf33c3447ad5",
          "name": "LEOX",
          "symbol": "LEOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29980/thumb/LEOX-LOGO-BLACK-2.jpg?1682406402"
        },
        {
          "chainId": 1,
          "address": "0x155ff1a85f440ee0a382ea949f24ce4e0b751c65",
          "name": "Behodler",
          "symbol": "EYE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12804/thumb/etherscan-eye-2-1.png?1629881415"
        },
        {
          "chainId": 1,
          "address": "0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7",
          "name": "PhoenixDAO",
          "symbol": "PHNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11523/thumb/Token_Icon.png?1618447147"
        },
        {
          "chainId": 1,
          "address": "0xf88baf18fab7e330fa0c4f83949e23f52fececce",
          "name": "Granary",
          "symbol": "GRAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29740/thumb/Grain.png?1681096624"
        },
        {
          "chainId": 1,
          "address": "0x1a963df363d01eebb2816b366d61c917f20e1ebe",
          "name": "MEMEME",
          "symbol": "MEMEME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29965/thumb/MEMEME_token_image_200px.png?1682389841"
        },
        {
          "chainId": 1,
          "address": "0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2",
          "name": "StakeWise",
          "symbol": "SWISE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15044/thumb/stakewise200.png?1619520721"
        },
        {
          "chainId": 1,
          "address": "0x9db0fb0aebe6a925b7838d16e3993a3976a64aab",
          "name": "Bambi",
          "symbol": "BAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30597/thumb/Frame_24.png?1685670568"
        },
        {
          "chainId": 1,
          "address": "0xa4e9584daa093cb1205e17ba737c3fd015748087",
          "name": "FOREXCOIN",
          "symbol": "FOREX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6852/thumb/forex-logo.PNG?1547043174"
        },
        {
          "chainId": 1,
          "address": "0x8fc6a71be7f82373e045a97859287676a399934a",
          "name": "TradeWix",
          "symbol": "WIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28733/thumb/tradewix.png?1673763754"
        },
        {
          "chainId": 1,
          "address": "0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb",
          "name": "sETH",
          "symbol": "SETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8843/thumb/sETH.png?1616150207"
        },
        {
          "chainId": 1,
          "address": "0xa71dcaaf5ba390b1b2012323a8810fac121f90dd",
          "name": "SafeGrow",
          "symbol": "SFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29715/thumb/IMG_20230402_021040_191.png?1680768593"
        },
        {
          "chainId": 1,
          "address": "0x0563dce613d559a47877ffd1593549fb9d3510d6",
          "name": "SuperBid",
          "symbol": "SUPERBID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14179/thumb/sign_super_bid.png?1617964829"
        },
        {
          "chainId": 1,
          "address": "0xd487892bb4c57edbe7ab401d9fe801c8fe6473f5",
          "name": "Uhive",
          "symbol": "HVE2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3811/thumb/hve2.png?1639045485"
        },
        {
          "chainId": 1,
          "address": "0xbd356a39bff2cada8e9248532dd879147221cf76",
          "name": "WOM Protocol",
          "symbol": "WOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4559/thumb/wom_logo_small.png?1572098941"
        },
        {
          "chainId": 1,
          "address": "0xcb84d72e61e383767c4dfeb2d8ff7f4fb89abc6e",
          "name": "Vega Protocol",
          "symbol": "VEGA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15870/thumb/vega.PNG?1622178218"
        },
        {
          "chainId": 1,
          "address": "0x6b89b97169a797d94f057f4a0b01e2ca303155e4",
          "name": "Chad Coin",
          "symbol": "CHAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29863/thumb/UfTmUNWv_400x400.png?1681888830"
        },
        {
          "chainId": 1,
          "address": "0x940bdcb99a0ee5fb008a606778ae87ed9789f257",
          "name": "JFIN Coin",
          "symbol": "JFIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13370/thumb/JFin-Coin-Logo.png?1607984823"
        },
        {
          "chainId": 1,
          "address": "0x2de509bf0014ddf697b220be628213034d320ece",
          "name": "Don t Buy Inu",
          "symbol": "DBI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28058/thumb/1Artboard_1_%282%29.png?1667298091"
        },
        {
          "chainId": 1,
          "address": "0x34f797e7190c131cf630524655a618b5bd8738e7",
          "name": "BaconDAO",
          "symbol": "BACON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg?1630333329"
        },
        {
          "chainId": 1,
          "address": "0xddac9c604ba6bc4acec0fbb485b83f390ecf2f31",
          "name": "CyOp Protocol",
          "symbol": "CYOP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20672/thumb/cyop.png?1637549090"
        },
        {
          "chainId": 1,
          "address": "0x6aac8cb9861e42bf8259f5abdc6ae3ae89909e11",
          "name": "Bitcoin Red",
          "symbol": "BTCRED",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/1054/thumb/bitcoin-red.png?1547034993"
        },
        {
          "chainId": 1,
          "address": "0x630d98424efe0ea27fb1b3ab7741907dffeaad78",
          "name": "PEAKDEFI",
          "symbol": "PEAK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9626/thumb/PEAKDEFI_Logo_250x250.png?1603094772"
        },
        {
          "chainId": 1,
          "address": "0x4b5f49487ea7b3609b1ad05459be420548789f1f",
          "name": "LeverFi",
          "symbol": "LEVER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26205/thumb/WI72SpBl_400x400.jpeg?1656494480"
        },
        {
          "chainId": 1,
          "address": "0x72adadb447784dd7ab1f472467750fc485e4cb2d",
          "name": "Worldcore",
          "symbol": "WRC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1358/thumb/worldcore.png?1548761189"
        },
        {
          "chainId": 1,
          "address": "0xd8e163967fed76806df0097b704ba721b9b37656",
          "name": "Cope Coin",
          "symbol": "COPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29912/thumb/QxzD6QZa_400x400_1_200x200.jpg?1682048533"
        },
        {
          "chainId": 1,
          "address": "0x45080a6531d671ddff20db42f93792a489685e32",
          "name": "Finance Vote",
          "symbol": "FVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010"
        },
        {
          "chainId": 1,
          "address": "0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed",
          "name": "pNetwork",
          "symbol": "PNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11659/thumb/pNetwork.png?1592411134"
        },
        {
          "chainId": 1,
          "address": "0xd0660cd418a64a1d44e9214ad8e459324d8157f1",
          "name": "Woofy",
          "symbol": "WOOFY",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/15410/thumb/woofy.png?1622566328"
        },
        {
          "chainId": 1,
          "address": "0x17bb9f3572d0c69e017dcd048ec7304eecc981c8",
          "name": "Mr Potato Token",
          "symbol": "MRPOTATO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30716/thumb/7WqzCpjQ_400x400.jpg?1686560063"
        },
        {
          "chainId": 1,
          "address": "0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74",
          "name": "Waltonchain",
          "symbol": "WTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1093/thumb/ggx6nnW.png?1604815811"
        },
        {
          "chainId": 1,
          "address": "0xb70835d7822ebb9426b56543e391846c107bd32c",
          "name": "Game",
          "symbol": "GTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2046/thumb/game.png?1547742459"
        },
        {
          "chainId": 1,
          "address": "0xb399511642fe1666c6a07f83483e6e4feaed9a00",
          "name": "Standard Euro",
          "symbol": "SEURO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28201/thumb/sEuroLogo.png?1670480816"
        },
        {
          "chainId": 1,
          "address": "0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9",
          "name": "Voice",
          "symbol": "VOICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12926/thumb/OjCQtdL.png?1614780024"
        },
        {
          "chainId": 1,
          "address": "0x0038e74ff4dad34404e74b0cb96db8ca26efc24d",
          "name": "Scooby Doo",
          "symbol": "SODO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30440/thumb/scooby.png?1684474863"
        },
        {
          "chainId": 1,
          "address": "0xd47bdf574b4f76210ed503e0efe81b58aa061f3d",
          "name": "TRVL",
          "symbol": "TRVL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg?1649390006"
        },
        {
          "chainId": 1,
          "address": "0x4b9278b94a1112cad404048903b8d343a810b07e",
          "name": "Hifi Finance",
          "symbol": "HIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28712/thumb/hft.png?1673534804"
        },
        {
          "chainId": 1,
          "address": "0xe7eaec9bca79d537539c00c58ae93117fb7280b9",
          "name": "Doge Protocol",
          "symbol": "DOGEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23689/thumb/LjhKBldd_400x400.png?1645079751"
        },
        {
          "chainId": 1,
          "address": "0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef",
          "name": "Dogs Of Elon",
          "symbol": "DOE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19942/thumb/doe.png?1636336026"
        },
        {
          "chainId": 1,
          "address": "0xb2dbf14d0b47ed3ba02bdb7c954e05a72deb7544",
          "name": "MobiFi",
          "symbol": "MOFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14697/thumb/MOFI_Coin_Green_200x200.png?1634024056"
        },
        {
          "chainId": 1,
          "address": "0x2653891204f463fb2a2f4f412564b19e955166ae",
          "name": "Gold Fever Native Gold",
          "symbol": "NGL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg?1637338673"
        },
        {
          "chainId": 1,
          "address": "0x7a939bb714fd2a48ebeb1e495aa9aaa74ba9fa68",
          "name": "Electric Vehicle Zone",
          "symbol": "EVZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9456/thumb/kLohzILUIln6mHFYOlecpWjINVIH-BVghP2vRTeuD0XteaQa7Lpn4sLcuPN4gHw8MU2pKWZCJRNwBmyyl1CYxplCLDcgSVihMC7vvfmkepY-_O_ImWBA27s4pKNlhcBnBYrc8y5WH0ZB2CjmqPh-32nPslrv329tqFWr2DAR8dl4R5LZGgeZ1ubCdtMoUua6gEL3umYShHBxrYLto.jpg?1567563510"
        },
        {
          "chainId": 1,
          "address": "0xd8e3fb3b08eba982f2754988d70d57edc0055ae6",
          "name": "Zoracles",
          "symbol": "ZORA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/13255/thumb/zora.png?1606747018"
        },
        {
          "chainId": 1,
          "address": "0x018fb5af9d015af25592a014c4266a84143de7a0",
          "name": "MP3",
          "symbol": "MP3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13897/thumb/1_sq13-ssNvv2APEFcy8dWJg.png?1612690862"
        },
        {
          "chainId": 1,
          "address": "0xa876f27f13a9eb6e621202cefdd5afc4a90e6457",
          "name": "Icy",
          "symbol": "IC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29666/thumb/1200.png?1680571834"
        },
        {
          "chainId": 1,
          "address": "0xa6f7645ed967faf708a614a2fca8d4790138586f",
          "name": "MoonieNFT",
          "symbol": "MNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png?1631026813"
        },
        {
          "chainId": 1,
          "address": "0xf25c91c87e0b1fd9b4064af0f427157aab0193a7",
          "name": "BASIC",
          "symbol": "BASIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11050/thumb/unTgJN6U_400x400.jpg?1587540882"
        },
        {
          "chainId": 1,
          "address": "0x1337def16f9b486faed0293eb623dc8395dfe46a",
          "name": "ARMOR",
          "symbol": "ARMOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13748/thumb/armor.png?1611425846"
        },
        {
          "chainId": 1,
          "address": "0x6bba316c48b49bd1eac44573c5c871ff02958469",
          "name": "Gas DAO",
          "symbol": "GAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22086/thumb/SjrgK6sf_400x400.jpg?1640763291"
        },
        {
          "chainId": 1,
          "address": "0x00059ae69c1622a7542edc15e8d17b060fe307b6",
          "name": "AmonD",
          "symbol": "AMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8952/thumb/AmonD_512x512.png?1563227436"
        },
        {
          "chainId": 1,
          "address": "0x9d561d63375672abd02119b9bc4fb90eb9e307ca",
          "name": "Ally Direct",
          "symbol": "DRCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16807/thumb/ally_icon.png?1625148232"
        },
        {
          "chainId": 1,
          "address": "0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9",
          "name": "BoringDAO  OLD ",
          "symbol": "BOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12917/thumb/bor_logo.png?1603607502"
        },
        {
          "chainId": 1,
          "address": "0xe0151763455a8a021e64880c238ba1cff3787ff0",
          "name": "Aped",
          "symbol": "APED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29906/thumb/Aped.png?1681987144"
        },
        {
          "chainId": 1,
          "address": "0x06f3cdabae564b0546529b4dd8fef1bcd4235753",
          "name": "TilWiki",
          "symbol": "TLW",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12915/thumb/89706561_1111490899211097_8975783115158978560_n.jpg?1603441976"
        },
        {
          "chainId": 1,
          "address": "0x474021845c4643113458ea4414bdb7fb74a01a77",
          "name": "Uno Re",
          "symbol": "UNO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15073/thumb/c0vbqVE.png?1632814516"
        },
        {
          "chainId": 1,
          "address": "0x59d1e836f7b7210a978b25a855085cc46fd090b5",
          "name": "AssangeDAO",
          "symbol": "JUSTICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23555/thumb/JUSTICE_token_logo.png?1644532689"
        },
        {
          "chainId": 1,
          "address": "0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e",
          "name": "Name Change",
          "symbol": "NCT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13826/thumb/hashmasks-nct.png?1612879286"
        },
        {
          "chainId": 1,
          "address": "0x8260328d0c405d9ca061d80199102ddc9089e43c",
          "name": "Dojo Supercomputer",
          "symbol": "DOJO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29728/thumb/Dojo_super.jpeg?1681090211"
        },
        {
          "chainId": 1,
          "address": "0x38d9eb07a7b8df7d86f440a4a5c4a4c1a27e1a08",
          "name": "bloXmove",
          "symbol": "BLXM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png?1635238192"
        },
        {
          "chainId": 1,
          "address": "0x3da932456d082cba208feb0b096d49b202bf89c8",
          "name": "Dego Finance",
          "symbol": "DEGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png?1600298167"
        },
        {
          "chainId": 1,
          "address": "0xb12494c8824fc069757f47d177e666c571cd49ae",
          "name": "ImpactXP",
          "symbol": "IMPACTXP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21118/thumb/TJpEKVBW_400x400.jpg?1638340573"
        },
        {
          "chainId": 1,
          "address": "0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca",
          "name": "OST",
          "symbol": "OST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1367/thumb/ost.jpg?1547035393"
        },
        {
          "chainId": 1,
          "address": "0xba5bde662c17e2adff1075610382b9b691296350",
          "name": "SuperRare",
          "symbol": "RARE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17753/thumb/RARE.jpg?1629220534"
        },
        {
          "chainId": 1,
          "address": "0x9b8d5f3402f74c7a61d9f09c32d3ca07b45c1466",
          "name": "Gimmer",
          "symbol": "GMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6693/thumb/gimmer_token.jpg?1547042897"
        },
        {
          "chainId": 1,
          "address": "0x5f3b3c37f47d8532836c800f75e7f262623aa3bb",
          "name": "Jarvis AI",
          "symbol": "JAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29757/thumb/logo200x200PNG.png?1681176862"
        },
        {
          "chainId": 1,
          "address": "0x36c833eed0d376f75d1ff9dfdee260191336065e",
          "name": "Gitcoin Staked ETH Index",
          "symbol": "GTCETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29171/thumb/gtcETH-token-logo.png?1677060167"
        },
        {
          "chainId": 1,
          "address": "0xeda8b016efa8b1161208cf041cd86972eee0f31e",
          "name": "IHT Real Estate Protocol",
          "symbol": "IHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3158/thumb/iht-real-estate-protocol.png?1547744404"
        },
        {
          "chainId": 1,
          "address": "0xa16a609ff4e1a15b6ccb469e7a5dd14e89305283",
          "name": "Spume",
          "symbol": "SPUME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27089/thumb/D6lMeW9k_400x400.jpeg?1661824399"
        },
        {
          "chainId": 1,
          "address": "0x6a6aa13393b7d1100c00a57c76c39e8b6c835041",
          "name": "OpenAI ERC",
          "symbol": "OPENAIERC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28412/thumb/OpenAI.jpeg?1670387260"
        },
        {
          "chainId": 1,
          "address": "0x183015a9ba6ff60230fdeadc3f43b3d788b13e21",
          "name": "R",
          "symbol": "R",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29551/thumb/R_200x200.png?1685954962"
        },
        {
          "chainId": 1,
          "address": "0x4e08f03079c5cd3083ea331ec61bcc87538b7665",
          "name": "DoubleDice",
          "symbol": "DODI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21035/thumb/crEjNfhF_400x400.jpg?1638250608"
        },
        {
          "chainId": 1,
          "address": "0x1353575d76444a11730d2f286e6303990eb1e357",
          "name": "Kek Guru",
          "symbol": "KEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30213/thumb/Kek-The-Prophecy-2.jpg?1683625867"
        },
        {
          "chainId": 1,
          "address": "0xe5d2e173b120341face9e9970889c9fe64081ffd",
          "name": "Bluejay",
          "symbol": "BLU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28085/thumb/Bluejay.png?1667444163"
        },
        {
          "chainId": 1,
          "address": "0x2a304fda5a85182dca1d03741bb2f07881b9e095",
          "name": "DCOMY",
          "symbol": "DCO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29007/thumb/15-38-16-200x200.png?1675918337"
        },
        {
          "chainId": 1,
          "address": "0x428dc22668e6f3468273634067e5545ed5417a3e",
          "name": "MiraQle",
          "symbol": "MQL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12278/thumb/200_200_%EB%AF%B8%EB%9D%BC%ED%81%B4_%EB%A1%9C%EA%B3%A0.png?1671869646"
        },
        {
          "chainId": 1,
          "address": "0xb67718b98d52318240c52e71a898335da4a28c42",
          "name": "Innovative Bioresearch Coin",
          "symbol": "INNBC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/4043/thumb/INNBC.png?1582800857"
        },
        {
          "chainId": 1,
          "address": "0xc538143202f3b11382d8606aae90a96b042a19db",
          "name": "Coinsbit Token",
          "symbol": "CNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10340/thumb/cbicon.png?1578637386"
        },
        {
          "chainId": 1,
          "address": "0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef",
          "name": "CAD Coin",
          "symbol": "CADC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14149/thumb/cadc_2.png?1614666625"
        },
        {
          "chainId": 1,
          "address": "0xf831938caf837cd505de196bbb408d81a06376ab",
          "name": "Jeff",
          "symbol": "JEFF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30222/thumb/photo_2023-05-06_23.53.02.jpeg?1683629068"
        },
        {
          "chainId": 1,
          "address": "0x73484a262730d1d422610729e828346f9b2ff480",
          "name": "BRCP",
          "symbol": "BRCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14388/thumb/brcp-500x500-verde.png?1615855378"
        },
        {
          "chainId": 1,
          "address": "0xc631120155621ee625835ec810b9885cdd764cd6",
          "name": "Goldex",
          "symbol": "GLDX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17814/thumb/gldx-logo-200x200.png?1629337485"
        },
        {
          "chainId": 1,
          "address": "0xa3ee21c306a700e682abcdfe9baa6a08f3820419",
          "name": "Creditcoin",
          "symbol": "CTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10569/thumb/ctc.png?1644650695"
        },
        {
          "chainId": 1,
          "address": "0xcbcc0f036ed4788f63fc0fee32873d6a7487b908",
          "name": "Humaniq",
          "symbol": "HMQ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/647/thumb/humaniq.jpg?1547034375"
        },
        {
          "chainId": 1,
          "address": "0xf7de6def3d319811418d69bf56c532a815fc47e8",
          "name": "Two Paws",
          "symbol": "TWOPAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29064/thumb/twopaws192x192.png?1676365698"
        },
        {
          "chainId": 1,
          "address": "0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373",
          "name": "GAMEE",
          "symbol": "GMEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14716/thumb/gmee-200x200.png?1621827468"
        },
        {
          "chainId": 1,
          "address": "0xb4039240e71535100be947116c778d5d98bd9f62",
          "name": "Magic Shiba Starter",
          "symbol": "MSHIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31051/thumb/magicshiba.jpg?1689922896"
        },
        {
          "chainId": 1,
          "address": "0x9ffc3bcde7b68c46a6dc34f0718009925c1867cb",
          "name": "Huobi Polkadot",
          "symbol": "HDOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14107/thumb/hdot.png?1614333466"
        },
        {
          "chainId": 1,
          "address": "0x997507cc49fbf0cd6ce5e1ee543218556fafdebc",
          "name": "Bitenium",
          "symbol": "BT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14618/thumb/BT-token-logo-200.png?1617262055"
        },
        {
          "chainId": 1,
          "address": "0x3085154623f51b00dedfc6ceeb5197277a66b17b",
          "name": "NFTY DeFi Protocol",
          "symbol": "NFTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18584/thumb/nfty_logo.png?1632551145"
        },
        {
          "chainId": 1,
          "address": "0xb624fde1a972b1c89ec1dad691442d5e8e891469",
          "name": "SporkDAO",
          "symbol": "SPORK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23358/thumb/sporkdao.PNG?1643942687"
        },
        {
          "chainId": 1,
          "address": "0xada86b1b313d1d5267e3fc0bb303f0a2b66d0ea7",
          "name": "Covesting",
          "symbol": "COV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237"
        },
        {
          "chainId": 1,
          "address": "0x16f78145ad0b9af58747e9a97ebd99175378bd3d",
          "name": "GroupDao",
          "symbol": "GDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22204/thumb/logo.png?1641194137"
        },
        {
          "chainId": 1,
          "address": "0xf3ae5d769e153ef72b4e3591ac004e89f48107a1",
          "name": "Deeper Network",
          "symbol": "DPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14748/thumb/deeper.png?1618094356"
        },
        {
          "chainId": 1,
          "address": "0xfc09c7cfd9c175dd9423ca02ae1249579ab12f12",
          "name": "Totoro Inu",
          "symbol": "TOTORO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20277/thumb/cLogo.png?1636708928"
        },
        {
          "chainId": 1,
          "address": "0x28cca76f6e8ec81e4550ecd761f899110b060e97",
          "name": "ArGoApp",
          "symbol": "ARGO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15477/thumb/Group_118.png?1647877401"
        },
        {
          "chainId": 1,
          "address": "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd",
          "name": "Razor Network",
          "symbol": "RAZOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13797/thumb/icon.png?1611919354"
        },
        {
          "chainId": 1,
          "address": "0x9be776559fed779cabd67042a7b8987aae592541",
          "name": "Bull Market",
          "symbol": "BULL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29977/thumb/bull.jpg?1688975642"
        },
        {
          "chainId": 1,
          "address": "0xdb25f211ab05b1c97d595516f45794528a807ad8",
          "name": "STASIS EURO",
          "symbol": "EURS",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/5164/thumb/EURS_300x300.png?1550571779"
        },
        {
          "chainId": 1,
          "address": "0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9",
          "name": "cUSDT",
          "symbol": "CUSDT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11621/thumb/cUSDT.png?1592113270"
        },
        {
          "chainId": 1,
          "address": "0xa5f1dbb0e55bc31f32c6d032bee330288490e722",
          "name": "Day By Day",
          "symbol": "DBD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21691/thumb/DBD-icon_200x200_%281%29.png?1639721079"
        },
        {
          "chainId": 1,
          "address": "0xcaeaf8381d4b20b43afa42061d6f80319a8881f6",
          "name": "R34P",
          "symbol": "R34P",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13393/thumb/r34p_logo.png?1608100330"
        },
        {
          "chainId": 1,
          "address": "0x60f5672a271c7e39e787427a18353ba59a4a3578",
          "name": "Pika",
          "symbol": "PIKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14419/thumb/pika-logo-2022-nbg.png?1641971069"
        },
        {
          "chainId": 1,
          "address": "0x2920f7d6134f4669343e70122ca9b8f19ef8fa5d",
          "name": "MonoX",
          "symbol": "MONO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20901/thumb/e51c6UaU_400x400.png?1637879085"
        },
        {
          "chainId": 1,
          "address": "0x104f3152d8ebfc3f679392977356962ff36566ac",
          "name": "ChainPort",
          "symbol": "PORTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096"
        },
        {
          "chainId": 1,
          "address": "0x00b7db6b4431e345eee5cc23d21e8dbc1d5cada3",
          "name": "CyberTronchain",
          "symbol": "CTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13391/thumb/zNdoAnW.png?1608095358"
        },
        {
          "chainId": 1,
          "address": "0x128ad1ad707c3b36e6f2ac9739f9df7516fdb592",
          "name": "alfa society",
          "symbol": "ALFA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31017/thumb/token_logo_listing.png?1689820893"
        },
        {
          "chainId": 1,
          "address": "0x5274891bec421b39d23760c04a6755ecb444797c",
          "name": "IdleUSDC  Yield ",
          "symbol": "IDLEUSDCYIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11932/thumb/idleusdcv3_32.png?1596263673"
        },
        {
          "chainId": 1,
          "address": "0x0d88ed6e74bbfd96b831231638b66c05571e824f",
          "name": "Aventus",
          "symbol": "AVT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/901/thumb/1673958879448.jpeg?1676793873"
        },
        {
          "chainId": 1,
          "address": "0x33d20575f20c6a1881f8ab08e69f6fbaffaeedf2",
          "name": "BlueWizard",
          "symbol": "WIZ",
          "decimals": 12,
          "logoURI": "https://assets.coingecko.com/coins/images/15261/thumb/BW.jpg?1620270880"
        },
        {
          "chainId": 1,
          "address": "0x8727c112c712c4a03371ac87a74dd6ab104af768",
          "name": "Jetcoin",
          "symbol": "JET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2487/thumb/jetcoin.png?1547974820"
        },
        {
          "chainId": 1,
          "address": "0x525a8f6f3ba4752868cde25164382bfbae3990e1",
          "name": "Nym",
          "symbol": "NYM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/24488/thumb/NYM_Token.png?1649926353"
        },
        {
          "chainId": 1,
          "address": "0x72e364f2abdc788b7e918bc238b21f109cd634d7",
          "name": "Metaverse Index",
          "symbol": "MVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14684/thumb/MVI_logo.png?1617776444"
        },
        {
          "chainId": 1,
          "address": "0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca",
          "name": "Syntropy",
          "symbol": "NOIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3269/thumb/Component_1.png?1608275724"
        },
        {
          "chainId": 1,
          "address": "0xcb50350ab555ed5d56265e096288536e8cac41eb",
          "name": "0xCoco",
          "symbol": "COCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31102/thumb/FL1-g3Ns_400x400.jpg?1690509715"
        },
        {
          "chainId": 1,
          "address": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
          "name": "dYdX",
          "symbol": "DYDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360"
        },
        {
          "chainId": 1,
          "address": "0x10d07423fde4747542e76b7199b98a0d03cf465b",
          "name": "Shrooms",
          "symbol": "SHROOMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30372/thumb/Untitled_design_%285%29_%282%29.png?1684294976"
        },
        {
          "chainId": 1,
          "address": "0xea7cc765ebc94c4805e3bff28d7e4ae48d06468a",
          "name": "Pad Fi",
          "symbol": "PAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18117/thumb/pad.fi.png?1677317189"
        },
        {
          "chainId": 1,
          "address": "0x0ebe30595a44e5288c24161ddfc1e9fa08e33a0c",
          "name": "Nyan Meme Coin",
          "symbol": "NYAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30197/thumb/n0z-571z_400x400.png?1683609656"
        },
        {
          "chainId": 1,
          "address": "0x56d811088235f11c8920698a204a5010a788f4b3",
          "name": "bZx Protocol",
          "symbol": "BZRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11811/thumb/bzrx.png?1594563172"
        },
        {
          "chainId": 1,
          "address": "0xcf4c68db4c2fa0bf58df07b14f45ce7709a716ac",
          "name": "Dejitaru Shirudo",
          "symbol": "SHIELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28500/thumb/200x200.png?1671158806"
        },
        {
          "chainId": 1,
          "address": "0x593114f03a0a575aece9ed675e52ed68d2172b8c",
          "name": "BidiPass",
          "symbol": "BDP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3890/thumb/AxiFAoHc_400x400.jpg?1567461770"
        },
        {
          "chainId": 1,
          "address": "0xdc47f2ba852669b178699449e50682d6ceaf8c07",
          "name": "Ston",
          "symbol": "STON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13001/thumb/ston-v2-200x200.png?1604284194"
        },
        {
          "chainId": 1,
          "address": "0x0ff6ffcfda92c53f615a4a75d982f399c989366b",
          "name": "UniLayer",
          "symbol": "LAYER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12164/thumb/logo-layer.jpg?1674822983"
        },
        {
          "chainId": 1,
          "address": "0x37c997b35c619c21323f3518b9357914e8b99525",
          "name": "Unipilot",
          "symbol": "PILOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17235/thumb/QmSjCnb74Q88o9gcTMMYNggj6BDkFEPukAZ5nWrhPaRxoW.png?1626923517"
        },
        {
          "chainId": 1,
          "address": "0x3f7aff0ef20aa2e646290dfa4e67611b2220c597",
          "name": "Volt Inu  OLD ",
          "symbol": "VOLT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21832/thumb/nl250.png?1643191234"
        },
        {
          "chainId": 1,
          "address": "0xf1b99e3e573a1a9c5e6b2ce818b617f0e664e86b",
          "name": "Opyn Squeeth",
          "symbol": "OSQTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22806/thumb/DyVT5XPV_400x400.jpg?1642656239"
        },
        {
          "chainId": 1,
          "address": "0xe19f85c920b572ca48942315b06d6cac86585c87",
          "name": "PLEB Token",
          "symbol": "PLEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29923/thumb/PLEBToken.png?1682135135"
        },
        {
          "chainId": 1,
          "address": "0xc52c326331e9ce41f04484d3b5e5648158028804",
          "name": "Unizen",
          "symbol": "ZCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14830/thumb/7xjpHaG.png?1618564961"
        },
        {
          "chainId": 1,
          "address": "0xdf801468a808a32656d2ed2d2d80b72a129739f4",
          "name": "Somnium Space CUBEs",
          "symbol": "CUBE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861"
        },
        {
          "chainId": 1,
          "address": "0xd1ba9bac957322d6e8c07a160a3a8da11a0d2867",
          "name": "HUMAN Protocol",
          "symbol": "HMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16412/thumb/human_protocol.PNG?1623971316"
        },
        {
          "chainId": 1,
          "address": "0xec67005c4e498ec7f55e092bd1d35cbc47c91892",
          "name": "Enzyme",
          "symbol": "MLN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/605/thumb/Enzyme_Icon_Secondary.png?1611576629"
        },
        {
          "chainId": 1,
          "address": "0x44aad22afbb2606d7828ca1f8f9e5af00e779ae1",
          "name": "Homer",
          "symbol": "SIMPSON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30243/thumb/1111.png?1683692033"
        },
        {
          "chainId": 1,
          "address": "0x408e41876cccdc0f92210600ef50372656052a38",
          "name": "REN",
          "symbol": "REN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3139/thumb/REN.png?1589985807"
        },
        {
          "chainId": 1,
          "address": "0x5ad02305ba9a4985390170337582986e419f1a2c",
          "name": "Crypto X",
          "symbol": "CX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31084/thumb/Logo_W_kBG_%281%29.png?1690786574"
        },
        {
          "chainId": 1,
          "address": "0x035bfe6057e15ea692c0dfdcab3bb41a64dd2ad4",
          "name": "Universal Liquidity Union",
          "symbol": "ULU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12376/thumb/ulu_finance_logo.ico?1599444401"
        },
        {
          "chainId": 1,
          "address": "0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2",
          "name": "Chromia",
          "symbol": "CHR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018"
        },
        {
          "chainId": 1,
          "address": "0x47481c1b44f2a1c0135c45aa402ce4f4dde4d30e",
          "name": "Meetple",
          "symbol": "MPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14323/thumb/Tqlsvhn1_400x400.png?1615427198"
        },
        {
          "chainId": 1,
          "address": "0xf51ebf9a26dbc02b13f8b3a9110dac47a4d62d78",
          "name": "APIX",
          "symbol": "APIX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10124/thumb/5sSKmtlA_400x400.png?1576126911"
        },
        {
          "chainId": 1,
          "address": "0x469eda64aed3a3ad6f868c44564291aa415cb1d9",
          "name": "Datamine FLUX",
          "symbol": "FLUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11756/thumb/fluxres.png?1593748917"
        },
        {
          "chainId": 1,
          "address": "0x4575f41308ec1483f3d399aa9a2826d74da13deb",
          "name": "Orchid Protocol",
          "symbol": "OXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3916/thumb/download_%285%29.png?1576624060"
        },
        {
          "chainId": 1,
          "address": "0x53bd789f2cdb846b227d8ffc7b46ed4263231fdf",
          "name": "SimbCoin Swap",
          "symbol": "SMBSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16808/thumb/smb.png?1625134960"
        },
        {
          "chainId": 1,
          "address": "0x70efdc485a10210b056ef8e0a32993bc6529995e",
          "name": "Blaze Network",
          "symbol": "BLZN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12248/thumb/A8JOuPSJ_400x400.jpg?1598511402"
        },
        {
          "chainId": 1,
          "address": "0x70be04312f5f66d03708f0a1d6353b3e0f80ddbb",
          "name": "BetBot",
          "symbol": "BBOT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31076/thumb/BetBot.png?1690344194"
        },
        {
          "chainId": 1,
          "address": "0x72e203a17add19a3099137c9d7015fd3e2b7dba9",
          "name": "BlockchainPoland",
          "symbol": "BCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8143/thumb/43Stz1Tw.png?1555551797"
        },
        {
          "chainId": 1,
          "address": "0x5f18ea482ad5cc6bc65803817c99f477043dce85",
          "name": "Agility",
          "symbol": "AGI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29781/thumb/14csBMea_400x400.jpg?1681353502"
        },
        {
          "chainId": 1,
          "address": "0xb6eda82597808c96969c21a88bf6c749b441c44a",
          "name": "Smart Medical Coin",
          "symbol": "SMC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17327/thumb/smc.png?1627313618"
        },
        {
          "chainId": 1,
          "address": "0x3496b523e5c00a4b4150d6721320cddb234c3079",
          "name": "NUM Token",
          "symbol": "NUM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20495/thumb/NP_Social_media_profile_pic.png?1664026542"
        },
        {
          "chainId": 1,
          "address": "0x84ba4aecfde39d69686a841bab434c32d179a169",
          "name": "Method Finance",
          "symbol": "MTHD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14619/thumb/mthd.PNG?1617262620"
        },
        {
          "chainId": 1,
          "address": "0x80d55c03180349fff4a229102f62328220a96444",
          "name": "Opulous",
          "symbol": "OPUL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG?1624418744"
        },
        {
          "chainId": 1,
          "address": "0xade00c28244d5ce17d72e40330b1c318cd12b7c3",
          "name": "Ambire AdEx",
          "symbol": "ADX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540"
        },
        {
          "chainId": 1,
          "address": "0x80fb784b7ed66730e8b1dbd9820afd29931aab03",
          "name": "Aave  OLD ",
          "symbol": "LEND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1365/thumb/ethlend.png?1547394586"
        },
        {
          "chainId": 1,
          "address": "0x3e828ac5c480069d4765654fb4b8733b910b13b2",
          "name": "Colony Network",
          "symbol": "CLNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23269/thumb/Logo.png?1644482438"
        },
        {
          "chainId": 1,
          "address": "0xde5ed76e7c05ec5e4572cfc88d1acea165109e44",
          "name": "DEUS Finance",
          "symbol": "DEUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18778/thumb/Black_Background_200x200.png?1681374184"
        },
        {
          "chainId": 1,
          "address": "0x86eab36585eddb7a949a0b4771ba733d942a8aa7",
          "name": "Reddit",
          "symbol": "REDDIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30791/thumb/IMG_0365.jpeg?1687334890"
        },
        {
          "chainId": 1,
          "address": "0x49e833337ece7afe375e44f4e3e8481029218e5c",
          "name": "Value DeFi",
          "symbol": "VALUE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12525/thumb/value_logo_-_500x500.png?1601478115"
        },
        {
          "chainId": 1,
          "address": "0xce593a29905951e8fc579bc092eca72577da575c",
          "name": "GROM",
          "symbol": "GR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13216/thumb/gr.png?1606278269"
        },
        {
          "chainId": 1,
          "address": "0x669c01caf0edcad7c2b8dc771474ad937a7ca4af",
          "name": "Wrapped Minima",
          "symbol": "WMINIMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29011/thumb/CoinGeko_icon_%28500x500%29.png?1677058514"
        },
        {
          "chainId": 1,
          "address": "0x501e8726d06cdef66f3e0cb67f54924cca1cc894",
          "name": "BRMV",
          "symbol": "BRMV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14389/thumb/brmv-logo-256px.png?1615856173"
        },
        {
          "chainId": 1,
          "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
          "name": "0x Protocol",
          "symbol": "ZRX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/863/thumb/0x.png?1547034672"
        },
        {
          "chainId": 1,
          "address": "0x0affa06e7fbe5bc9a764c979aa66e8256a631f02",
          "name": "Polybius",
          "symbol": "PLBT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/766/thumb/polybius.png?1547034516"
        },
        {
          "chainId": 1,
          "address": "0x27778e14ce36d3b85e1effeb43816a17bbb7088a",
          "name": "Lyfe Gold",
          "symbol": "LGOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14938/thumb/LGOLD-Logo-200.png?1619082558"
        },
        {
          "chainId": 1,
          "address": "0x70008f18fc58928dce982b0a69c2c21ff80dca54",
          "name": "X7R",
          "symbol": "X7R",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27710/thumb/X7R.png?1665314632"
        },
        {
          "chainId": 1,
          "address": "0xd7d706ed3598a354a4adfce5d8c5383df99a4461",
          "name": "Dain",
          "symbol": "DAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14811/thumb/%C2%B4%C3%99%C3%80%C3%8E%C2%B7%C3%8E%C2%B0%C3%AD.png?1618541340"
        },
        {
          "chainId": 1,
          "address": "0x190fb342aa6a15eb82903323ae78066ff8616746",
          "name": "Umbrella Coin",
          "symbol": "UMC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1700/thumb/umbrellacoin.jpg?1547035958"
        },
        {
          "chainId": 1,
          "address": "0x3330bfb7332ca23cd071631837dc289b09c33333",
          "name": "Rubic",
          "symbol": "RBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12629/thumb/rubic.png?1671611765"
        },
        {
          "chainId": 1,
          "address": "0xa64dfe8d86963151e6496bee513e366f6e42ed79",
          "name": "Goku",
          "symbol": "GOKU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17947/thumb/goku.png?1642919069"
        },
        {
          "chainId": 1,
          "address": "0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8",
          "name": "Aave BUSD v1",
          "symbol": "ABUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11736/thumb/aBUSD.png?1593085489"
        },
        {
          "chainId": 1,
          "address": "0xe95a203b1a91a908f9b9ce46459d101078c2c3cb",
          "name": "Ankr Staked ETH",
          "symbol": "ANKRETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13403/thumb/aETHc.png?1625756490"
        },
        {
          "chainId": 1,
          "address": "0x7968bc6a03017ea2de509aaa816f163db0f35148",
          "name": "Hedget",
          "symbol": "HGET",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/12453/thumb/Hedget.png?1599944809"
        },
        {
          "chainId": 1,
          "address": "0x5aef5bba19e6a1644805bd4f5c93c8557b87c62c",
          "name": "DeepFakeAI",
          "symbol": "FAKEAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30633/thumb/Safeimagekit-resized-img_%286%29.png?1686015318"
        },
        {
          "chainId": 1,
          "address": "0x09ce2b746c32528b7d864a1e3979bd97d2f095ab",
          "name": "DeFIL",
          "symbol": "DFL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17708/thumb/defillogo200200.png?1629080381"
        },
        {
          "chainId": 1,
          "address": "0x1b40183efb4dd766f11bda7a7c3ad8982e998421",
          "name": "Vesper Finance",
          "symbol": "VSP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13527/thumb/vesper_logo.jpg?1609399927"
        },
        {
          "chainId": 1,
          "address": "0xa5f2211b9b8170f694421f2046281775e8468044",
          "name": "THORSwap",
          "symbol": "THOR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19292/thumb/THORSwap_Logo-removebg-preview.png?1657210461"
        },
        {
          "chainId": 1,
          "address": "0x0f71b8de197a1c84d31de0f1fa7926c365f052b3",
          "name": "Arcona",
          "symbol": "ARCONA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4312/thumb/icon_ARCONA_%281%29.png?1651823900"
        },
        {
          "chainId": 1,
          "address": "0xa9aad2dc3a8315caeee5f458b1d8edc31d8467bd",
          "name": "BTCMoon",
          "symbol": "BTCM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1081/thumb/btcmoon.png?1547035045"
        },
        {
          "chainId": 1,
          "address": "0x7220e92d418e2eb59d0c25d195fa004bfd3afc42",
          "name": "Ad Flex",
          "symbol": "ADF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5620/thumb/ad-flex-token.png?1547041485"
        },
        {
          "chainId": 1,
          "address": "0xd084944d3c05cd115c09d072b9f44ba3e0e45921",
          "name": "Manifold Finance",
          "symbol": "FOLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15928/thumb/Manifold.png?1622439811"
        },
        {
          "chainId": 1,
          "address": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
          "name": "FTX",
          "symbol": "FTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9026/thumb/F.png?1609051564"
        },
        {
          "chainId": 1,
          "address": "0xe9f1d62c671efe99896492766c0b416bd3fb9e52",
          "name": "XOYCoin",
          "symbol": "XOY",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26641/thumb/xoycoin_logo.png?1659347530"
        },
        {
          "chainId": 1,
          "address": "0xc4f6e93aeddc11dc22268488465babcaf09399ac",
          "name": "hi Dollar",
          "symbol": "HI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png?1628665739"
        },
        {
          "chainId": 1,
          "address": "0xff742d05420b6aca4481f635ad8341f81a6300c2",
          "name": "AscendEx",
          "symbol": "ASD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5003/thumb/bitmax.png?1621310871"
        },
        {
          "chainId": 1,
          "address": "0x13c99770694f07279607a6274f28a28c33086424",
          "name": "Material",
          "symbol": "MTRL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22069/thumb/square-200x200.png?1640749501"
        },
        {
          "chainId": 1,
          "address": "0x03806ce5ef69bd9780edfb04c29da1f23db96294",
          "name": "Tesla TSL",
          "symbol": "TSL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1222/thumb/energo.png?1547035249"
        },
        {
          "chainId": 1,
          "address": "0x719e7f0dadfdea25b78595da944f44d15d7e6795",
          "name": "MUSK DAO",
          "symbol": "MUSK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29464/thumb/musk.png?1679109242"
        },
        {
          "chainId": 1,
          "address": "0xaec2e87e0a235266d9c5adc9deb4b2e29b54d009",
          "name": "SingularDTV",
          "symbol": "SNGLS",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/521/thumb/singulardtv.png?1547034199"
        },
        {
          "chainId": 1,
          "address": "0x888888848b652b3e3a0f34c96e00eec0f3a23f72",
          "name": "Alien Worlds",
          "symbol": "TLM",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061"
        },
        {
          "chainId": 1,
          "address": "0x9f7fc686cfd64aa5ae15b351d03071e91533094b",
          "name": "Trace Network Labs",
          "symbol": "TRACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23266/thumb/Token_Icon_02.png?1655797843"
        },
        {
          "chainId": 1,
          "address": "0x6ad9a31f02f1e790ff85584ea3c3d0001e45cd64",
          "name": "Ethane",
          "symbol": "C2H6",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31118/thumb/Ethane200x200.png?1690530269"
        },
        {
          "chainId": 1,
          "address": "0xebf2096e01455108badcbaf86ce30b6e5a72aa52",
          "name": "XIDR",
          "symbol": "XIDR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21126/thumb/XIDR_Logo_256_X_256.png?1638343133"
        },
        {
          "chainId": 1,
          "address": "0x2c623d3cc9a2cc158951b8093cb94e80cf56deea",
          "name": "NexAI",
          "symbol": "NEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31056/thumb/NexAI-LOGO_200x200.png?1689926100"
        },
        {
          "chainId": 1,
          "address": "0x74232704659ef37c08995e386a2e26cc27a8d7b1",
          "name": "Strike",
          "symbol": "STRK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14607/thumb/Jw-36llq_400x400.jpg?1617243607"
        },
        {
          "chainId": 1,
          "address": "0x10010078a54396f62c96df8532dc2b4847d47ed3",
          "name": "Hundred Finance",
          "symbol": "HND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18445/thumb/hnd.PNG?1632054703"
        },
        {
          "chainId": 1,
          "address": "0x36ff4dae0e88113d68b1209e245b0e3af92e9d58",
          "name": "Xenlon Mars",
          "symbol": "XLON",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29721/thumb/signal-2023-04-29-113317_002.jpeg?1686731585"
        },
        {
          "chainId": 1,
          "address": "0xac8e13ecc30da7ff04b842f21a62a1fb0f10ebd5",
          "name": "BabyDoge ETH",
          "symbol": "BABYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17166/thumb/logo_256px_%281%29.png?1626684127"
        },
        {
          "chainId": 1,
          "address": "0xea7b7dc089c9a4a916b5a7a37617f59fd54e37e4",
          "name": "HyperCycle",
          "symbol": "HYPC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29917/thumb/HyperCycle-Logo-2022_icon_copy_%281%29.png?1682050109"
        },
        {
          "chainId": 1,
          "address": "0x575b57ca64d2de54fe78fae6d952251bbb6d251a",
          "name": "BlockJack",
          "symbol": "JACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31104/thumb/BJ-NEW-NB.png?1690514144"
        },
        {
          "chainId": 1,
          "address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
          "name": "ImmutableX",
          "symbol": "IMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17233/thumb/immutableX-symbol-BLK-RGB.png?1665110648"
        },
        {
          "chainId": 1,
          "address": "0x4f640f2529ee0cf119a2881485845fa8e61a782a",
          "name": "ORE Network",
          "symbol": "ORE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18917/thumb/ORE_FullColor.png?1633921977"
        },
        {
          "chainId": 1,
          "address": "0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab",
          "name": "CoW Protocol",
          "symbol": "COW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24384/thumb/cow.png?1660960589"
        },
        {
          "chainId": 1,
          "address": "0x727f064a78dc734d33eec18d5370aef32ffd46e4",
          "name": "Orion Money",
          "symbol": "ORION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png?1632710781"
        },
        {
          "chainId": 1,
          "address": "0x2c537e5624e4af88a7ae4060c022609376c8d0eb",
          "name": "BiLira",
          "symbol": "TRYB",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg?1642668342"
        },
        {
          "chainId": 1,
          "address": "0x44f5909e97e1cbf5fbbdf0fc92fd83cde5d5c58a",
          "name": "Acria AI",
          "symbol": "ACRIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28598/thumb/image002.png?1675131968"
        },
        {
          "chainId": 1,
          "address": "0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0",
          "name": "Domani Protocol",
          "symbol": "DEXTF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12634/thumb/0qgT0aMu_400x400.jpg?1639175406"
        },
        {
          "chainId": 1,
          "address": "0x97de57ec338ab5d51557da3434828c5dbfada371",
          "name": "eUSD",
          "symbol": "EUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30047/thumb/image_5.png?1683016861"
        },
        {
          "chainId": 1,
          "address": "0xdb25ca703181e7484a155dd612b06f57e12be5f0",
          "name": "YFI yVault",
          "symbol": "YVYFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28785/thumb/yvYFI-128-0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1.png?1674199541"
        },
        {
          "chainId": 1,
          "address": "0xa0d3707c569ff8c87fa923d3823ec5d81c98be78",
          "name": "Instadapp ETH v2",
          "symbol": "IETHV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29704/thumb/iETH.png?1680765220"
        },
        {
          "chainId": 1,
          "address": "0x20a8cec5fffea65be7122bcab2ffe32ed4ebf03a",
          "name": "DinoX",
          "symbol": "DNXC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png?1627292452"
        },
        {
          "chainId": 1,
          "address": "0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe",
          "name": "Sifchain",
          "symbol": "EROWAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14044/thumb/EROWAN.png?1614656300"
        },
        {
          "chainId": 1,
          "address": "0x464fdb8affc9bac185a7393fd4298137866dcfb8",
          "name": "Realm",
          "symbol": "REALM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG?1631665838"
        },
        {
          "chainId": 1,
          "address": "0x675bbc7514013e2073db7a919f6e4cbef576de37",
          "name": "Coldstack",
          "symbol": "CLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403"
        },
        {
          "chainId": 1,
          "address": "0x1c11325ec05500dd587ffe0eeb2d4499b5b91e79",
          "name": "EcoTool",
          "symbol": "ETO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31080/thumb/My_project.png?1690348140"
        },
        {
          "chainId": 1,
          "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
          "name": "Hop Protocol",
          "symbol": "HOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25445/thumb/hop.png?1665541677"
        },
        {
          "chainId": 1,
          "address": "0x940a2db1b7008b6c776d4faaca729d6d4a4aa551",
          "name": "DUSK Network",
          "symbol": "DUSK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5217/thumb/D_ticker_purple_on_circle_%282%29.png?1563781659"
        },
        {
          "chainId": 1,
          "address": "0x26fb86579e371c7aedc461b2ddef0a8628c93d3b",
          "name": "BORA",
          "symbol": "BORA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7646/thumb/mqFw8hxm_400x400.jpeg?1656657343"
        },
        {
          "chainId": 1,
          "address": "0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b",
          "name": "Shibaken Finance",
          "symbol": "SHIBAKEN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/15413/thumb/shibak.png?1660902286"
        },
        {
          "chainId": 1,
          "address": "0x8a7adc1b690e81c758f1bd0f72dfe27ae6ec56a5",
          "name": "Bolide",
          "symbol": "BLID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25548/thumb/BLID_token_logo_200x200.png?1652334831"
        },
        {
          "chainId": 1,
          "address": "0xb113c6cf239f60d380359b762e95c13817275277",
          "name": "BitMEX",
          "symbol": "BMEX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21831/thumb/bitmex-token.jpeg?1640081706"
        },
        {
          "chainId": 1,
          "address": "0x9b81686140e85d28c2236c307dd49b422a663edf",
          "name": "Combustion",
          "symbol": "FIRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31044/thumb/T-wqdlHF_400x400.jpg?1689858606"
        },
        {
          "chainId": 1,
          "address": "0x77700005bea4de0a78b956517f099260c2ca9a26",
          "name": "Root",
          "symbol": "ROOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28366/thumb/root-200x200.png?1669907946"
        },
        {
          "chainId": 1,
          "address": "0xb67beb26ebeb0dceec354ae0942256d03c01771b",
          "name": "DeStorage",
          "symbol": "DS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15984/thumb/DS_LOG_Transparent_200x200.png?1622535280"
        },
        {
          "chainId": 1,
          "address": "0x824e35f7a75324f99300afac75ecf7354e17ea26",
          "name": "TIA",
          "symbol": "TIA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23994/thumb/tia.png?1645952362"
        },
        {
          "chainId": 1,
          "address": "0xb23d80f5fefcddaa212212f028021b41ded428cf",
          "name": "Echelon Prime",
          "symbol": "PRIME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29053/thumb/prime-logo-small-border_%282%29.png?1684901261"
        },
        {
          "chainId": 1,
          "address": "0x6f3277ad0782a7da3eb676b85a8346a100bf9c1c",
          "name": "DogPad Finance",
          "symbol": "DOGPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28845/thumb/DogPad.png?1674803782"
        },
        {
          "chainId": 1,
          "address": "0x24ec2ca132abf8f6f8a6e24a1b97943e31f256a7",
          "name": "dotmoovs",
          "symbol": "MOOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626"
        },
        {
          "chainId": 1,
          "address": "0x728f30fa2f100742c7949d1961804fa8e0b1387d",
          "name": "GamerCoin",
          "symbol": "GHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440"
        },
        {
          "chainId": 1,
          "address": "0x1e917e764bc34d3bc313fe8159a6bd9d9ffd450d",
          "name": "WEWE",
          "symbol": "WEWE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30458/thumb/IMG_3939.JPG?1684656919"
        },
        {
          "chainId": 1,
          "address": "0xa02120696c7b8fe16c09c749e4598819b2b0e915",
          "name": "WXT Token",
          "symbol": "WXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8835/thumb/Wirex.jpg?1561970529"
        },
        {
          "chainId": 1,
          "address": "0x3301ee63fb29f863f2333bd4466acb46cd8323e6",
          "name": "Akita Inu",
          "symbol": "AKITA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14115/thumb/akita.png?1661666578"
        },
        {
          "chainId": 1,
          "address": "0x9b2b931d6ab97b6a887b2c5d8529537e6fe73ebe",
          "name": "All In",
          "symbol": "ALLIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28411/thumb/AllIn_V2_%281%29.png?1689738421"
        },
        {
          "chainId": 1,
          "address": "0xdb8d6d3ac21e4efe3675bbb18514010ac9c5558f",
          "name": "Energreen",
          "symbol": "EGRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30583/thumb/energreen.png?1685449348"
        },
        {
          "chainId": 1,
          "address": "0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e",
          "name": "Dragonchain",
          "symbol": "DRGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1289/thumb/dragonchain.png?1547957761"
        },
        {
          "chainId": 1,
          "address": "0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
          "name": "Rarible",
          "symbol": "RARI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953"
        },
        {
          "chainId": 1,
          "address": "0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05",
          "name": "Gelato",
          "symbol": "GEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15026/thumb/Gelato_Icon_Logo_1024x1024.png?1619491717"
        },
        {
          "chainId": 1,
          "address": "0x581dafeba7bf8fa69e4387eff5eae9eb6401657c",
          "name": "Threads",
          "symbol": "THREADS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30970/thumb/2neo02_N_400x400.jpg?1689303835"
        },
        {
          "chainId": 1,
          "address": "0x5732046a883704404f284ce41ffadd5b007fd668",
          "name": "Bluzelle",
          "symbol": "BLZ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510"
        },
        {
          "chainId": 1,
          "address": "0x50eec6d765792dcfb0913c8403ef2a12e1b861a6",
          "name": "Z Cubed",
          "symbol": "Z3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29478/thumb/Z3-BLACK-200x200.png?1679130301"
        },
        {
          "chainId": 1,
          "address": "0x61a35258107563f6b6f102ae25490901c8760b12",
          "name": "Kitty Inu",
          "symbol": "KITTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19385/thumb/KITTY.png?1684750404"
        },
        {
          "chainId": 1,
          "address": "0x909e34d3f6124c324ac83dcca84b74398a6fa173",
          "name": "Panther Protocol",
          "symbol": "ZKP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18611/thumb/panther.jpg?1671503084"
        },
        {
          "chainId": 1,
          "address": "0x6286a9e6f7e745a6d884561d88f94542d6715698",
          "name": "Cryptomeda",
          "symbol": "TECH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17983/thumb/tech.png?1654330771"
        },
        {
          "chainId": 1,
          "address": "0xe04f47ff45576249bc5083dfdf987e03d0550113",
          "name": "Moonsama",
          "symbol": "SAMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28308/thumb/Small.png?1669340181"
        },
        {
          "chainId": 1,
          "address": "0x444444444444c1a66f394025ac839a535246fcc8",
          "name": "Genius",
          "symbol": "GENI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28621/thumb/GENI200x200.png?1672707726"
        },
        {
          "chainId": 1,
          "address": "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
          "name": "Unmarshal",
          "symbol": "MARSH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14554/thumb/img_circle_256x256.png?1616997706"
        },
        {
          "chainId": 1,
          "address": "0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5",
          "name": "ZeroSwap",
          "symbol": "ZEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12861/thumb/logo.?1666110579"
        },
        {
          "chainId": 1,
          "address": "0xe9da5e227e3fa4fc933b5f540be021e7ecc3fd81",
          "name": "GMFAM",
          "symbol": "GMFAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30547/thumb/GMFAM-logo_%282%29.png?1684992285"
        },
        {
          "chainId": 1,
          "address": "0x69af81e73a73b40adf4f3d4223cd9b1ece623074",
          "name": "Mask Network",
          "symbol": "MASK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316"
        },
        {
          "chainId": 1,
          "address": "0x73374ea518de7addd4c2b624c0e8b113955ee041",
          "name": "Juggernaut",
          "symbol": "JGN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png?1602428976"
        },
        {
          "chainId": 1,
          "address": "0x61e90a50137e1f645c9ef4a0d3a4f01477738406",
          "name": "League of Kingdoms",
          "symbol": "LOKA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png?1642643271"
        },
        {
          "chainId": 1,
          "address": "0x6fc13eace26590b80cccab1ba5d51890577d83b2",
          "name": "Umbrella Network",
          "symbol": "UMB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png?1612836176"
        },
        {
          "chainId": 1,
          "address": "0xf4b5470523ccd314c6b9da041076e7d79e0df267",
          "name": "blockbank",
          "symbol": "BBANK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg.png?1675767121"
        },
        {
          "chainId": 1,
          "address": "0xfe3e6a25e6b192a42a44ecddcd13796471735acf",
          "name": "Reef",
          "symbol": "REEF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png?1610534130"
        },
        {
          "chainId": 1,
          "address": "0x0316eb71485b0ab14103307bf65a021042c6d380",
          "name": "Huobi BTC",
          "symbol": "HBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12407/thumb/hbtc.png?1687143034"
        },
        {
          "chainId": 1,
          "address": "0x2ad9addd0d97ec3cdba27f92bf6077893b76ab0b",
          "name": "Planet Token",
          "symbol": "PLANET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30767/thumb/Logo-Planet.png?1686903378"
        },
        {
          "chainId": 1,
          "address": "0xfe2e637202056d30016725477c5da089ab0a043a",
          "name": "sETH2",
          "symbol": "SETH2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16569/thumb/emerald256.png?1624494960"
        },
        {
          "chainId": 1,
          "address": "0x519c1001d550c0a1dae7d1fc220f7d14c2a521bb",
          "name": "Polkaswap",
          "symbol": "PSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15475/thumb/pswap-token-logomark-ticker-icon-200px-transparent-optimized.png?1622447028"
        },
        {
          "chainId": 1,
          "address": "0xed04915c23f00a313a544955524eb7dbd823143d",
          "name": "Alchemy Pay",
          "symbol": "ACH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266"
        },
        {
          "chainId": 1,
          "address": "0x1fee5588cb1de19c70b6ad5399152d8c643fae7b",
          "name": "Phun Token",
          "symbol": "PHTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24773/thumb/2q6YoWkb_400x400.jpg?1648822416"
        },
        {
          "chainId": 1,
          "address": "0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec",
          "name": "Shping",
          "symbol": "SHPING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2588/thumb/000103_Shping_Logos_Shping_Secondary_Logo.png?1661141481"
        },
        {
          "chainId": 1,
          "address": "0x005d1123878fc55fbd56b54c73963b234a64af3c",
          "name": "Kiba Inu",
          "symbol": "KIBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19525/thumb/kiba.png?1648549322"
        },
        {
          "chainId": 1,
          "address": "0xdaf88906ac1de12ba2b1d2f7bfc94e9638ac40c4",
          "name": "EpiK Protocol",
          "symbol": "EPK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15188/thumb/epk.PNG?1620078400"
        },
        {
          "chainId": 1,
          "address": "0x243cacb4d5ff6814ad668c3e225246efa886ad5a",
          "name": "Shina Inu",
          "symbol": "SHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25208/thumb/coingecko-shina-purple-bg.png?1655266989"
        },
        {
          "chainId": 1,
          "address": "0x0391d2021f89dc339f60fff84546ea23e337750f",
          "name": "BarnBridge",
          "symbol": "BOND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853"
        },
        {
          "chainId": 1,
          "address": "0x41545f8b9472d758bb669ed8eaeeecd7a9c4ec29",
          "name": "Forta",
          "symbol": "FORT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696"
        },
        {
          "chainId": 1,
          "address": "0x8888801af4d980682e47f1a9036e589479e835c5",
          "name": "88mph",
          "symbol": "MPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13137/thumb/yfU-_Tcj_400x400.png?1605581509"
        },
        {
          "chainId": 1,
          "address": "0xbba39fd2935d5769116ce38d46a71bde9cf03099",
          "name": "Choise com",
          "symbol": "CHO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25935/thumb/cho_%282%29.png?1663309407"
        },
        {
          "chainId": 1,
          "address": "0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0",
          "name": "dForce",
          "symbol": "DF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794"
        },
        {
          "chainId": 1,
          "address": "0x1796ae0b0fa4862485106a0de9b654efe301d0b2",
          "name": "Polychain Monsters",
          "symbol": "PMON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png?1617238350"
        },
        {
          "chainId": 1,
          "address": "0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7",
          "name": "SKALE",
          "symbol": "SKL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574"
        },
        {
          "chainId": 1,
          "address": "0x7714f320adca62b149df2579361afec729c5fe6a",
          "name": "Tenup",
          "symbol": "TUP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6254/thumb/uphIf44J_400x400.jpg?1547042290"
        },
        {
          "chainId": 1,
          "address": "0x8fac8031e079f409135766c7d5de29cf22ef897c",
          "name": "Humans ai",
          "symbol": "HEART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21273/thumb/h_logo_1x.png?1638858402"
        },
        {
          "chainId": 1,
          "address": "0x3d658390460295fb963f54dc0899cfb1c30776df",
          "name": "Circuits of Value",
          "symbol": "COVAL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950"
        },
        {
          "chainId": 1,
          "address": "0x27054b13b1b798b345b591a4d22e6562d47ea75a",
          "name": "AirSwap",
          "symbol": "AST",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/1019/thumb/Airswap.png?1630903484"
        },
        {
          "chainId": 1,
          "address": "0x6c28aef8977c9b773996d0e8376d2ee379446f2f",
          "name": "Quickswap  OLD ",
          "symbol": "QUICK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13970/thumb/quick.png?1676951177"
        },
        {
          "chainId": 1,
          "address": "0xa0246c9032bc3a600820415ae600c6388619a14d",
          "name": "Harvest Finance",
          "symbol": "FARM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180"
        },
        {
          "chainId": 1,
          "address": "0x90b831fa3bebf58e9744a14d638e25b4ee06f9bc",
          "name": "Mimo Governance",
          "symbol": "MIMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16449/thumb/mimodefi.PNG?1624240539"
        },
        {
          "chainId": 1,
          "address": "0xaaee1a9723aadb7afa2810263653a34ba2c21c7a",
          "name": "Mog Coin",
          "symbol": "MOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31059/thumb/mog_logo.png?1689931973"
        },
        {
          "chainId": 1,
          "address": "0xd8e2d95c8614f28169757cd6445a71c291dec5bf",
          "name": "Grumpy Cat",
          "symbol": "GRUMPYCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30322/thumb/logo_200x200.png?1684116469"
        },
        {
          "chainId": 1,
          "address": "0xac3211a5025414af2866ff09c23fc18bc97e79b1",
          "name": "Dovu",
          "symbol": "DOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1072/thumb/Dovu_Earth_72x72_leaf_blue.png?1615259361"
        },
        {
          "chainId": 1,
          "address": "0xf23a2bf7619ad93300499c3fc7d7145a06cca562",
          "name": "noiseGPT",
          "symbol": "NOISEGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29908/thumb/noisegpt.png?1683540039"
        },
        {
          "chainId": 1,
          "address": "0x56978e609f2cab06f77c5c8fd75166fcd8f09bd8",
          "name": "GenieBot",
          "symbol": "GENIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30874/thumb/IMG_0334.jpeg?1688375117"
        },
        {
          "chainId": 1,
          "address": "0xe94b97b6b43639e238c851a7e693f50033efd75c",
          "name": "HaloDAO",
          "symbol": "RNBW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16754/thumb/RNBW-256x256.png?1624927932"
        },
        {
          "chainId": 1,
          "address": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
          "name": "Band Protocol",
          "symbol": "BAND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9545/thumb/Band_token_blue_violet_token.png?1625881431"
        },
        {
          "chainId": 1,
          "address": "0x5f0bc16d50f72d10b719dbf6845de2e599eb5624",
          "name": "Vent Finance",
          "symbol": "VENT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png?1629804982"
        },
        {
          "chainId": 1,
          "address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
          "name": "yearn finance",
          "symbol": "YFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11849/thumb/yearn.jpg?1687142705"
        },
        {
          "chainId": 1,
          "address": "0x75858677e27c930fb622759feaffee2b754af07f",
          "name": "Phantasma",
          "symbol": "SOUL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035"
        },
        {
          "chainId": 1,
          "address": "0xd807f7e2818db8eda0d28b5be74866338eaedb86",
          "name": "Jim",
          "symbol": "JIM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30235/thumb/_jim_Logo.png?1690345305"
        },
        {
          "chainId": 1,
          "address": "0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6",
          "name": "unFederalReserve",
          "symbol": "ERSDL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12975/thumb/logo_eRSDL.png?1625755665"
        },
        {
          "chainId": 1,
          "address": "0x50d1c8e131dca8b9226c0a0f78947557497374b3",
          "name": "Bridge Bot",
          "symbol": "BRIDGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31015/thumb/bridgebot.jpeg?1689751559"
        },
        {
          "chainId": 1,
          "address": "0x0d8ca4b20b115d4da5c13dc45dd582a5de3e78bf",
          "name": "Generaitiv",
          "symbol": "GAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29158/thumb/logo-200x200.png?1677039466"
        },
        {
          "chainId": 1,
          "address": "0x4ad434b8cdc3aa5ac97932d6bd18b5d313ab0f6f",
          "name": "EverMoon ERC",
          "symbol": "EVERMOON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30720/thumb/evermoon.png?1686560671"
        },
        {
          "chainId": 1,
          "address": "0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf",
          "name": "Digix Gold",
          "symbol": "DGX",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/4171/thumb/DGX_Token.png?1547039436"
        },
        {
          "chainId": 1,
          "address": "0xa53e968b8d8a5be52d66e5bb35d9b6b6b5a5cd2f",
          "name": "BIDSHOP",
          "symbol": "BIDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30404/thumb/Logo_200_200.png?1684373802"
        },
        {
          "chainId": 1,
          "address": "0xc8807f0f5ba3fa45ffbdc66928d71c5289249014",
          "name": "Ispolink",
          "symbol": "ISP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG?1620352267"
        },
        {
          "chainId": 1,
          "address": "0xd794dd1cada4cf79c9eebaab8327a1b0507ef7d4",
          "name": "Hyve",
          "symbol": "HYVE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255"
        },
        {
          "chainId": 1,
          "address": "0x3007083eaa95497cd6b2b809fb97b6a30bdf53d3",
          "name": "PSYOP",
          "symbol": "PSYOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30485/thumb/logo_200.png?1685349333"
        },
        {
          "chainId": 1,
          "address": "0x628a3b2e302c7e896acc432d2d0dd22b6cb9bc88",
          "name": "LimeWire Token",
          "symbol": "LMWR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30356/thumb/LimeWire_Logo_Icon_200x200_%281%29.png?1684230574"
        },
        {
          "chainId": 1,
          "address": "0x4f311c430540db1d64e635eb55f969f1660b2016",
          "name": "Pepe Chain",
          "symbol": "PC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31041/thumb/Pepechain_Token.png?1689856816"
        },
        {
          "chainId": 1,
          "address": "0x7bef710a5759d197ec0bf621c3df802c2d60d848",
          "name": "SHOPX",
          "symbol": "SHOPX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14600/thumb/shopx_icon_.png?1646381164"
        },
        {
          "chainId": 1,
          "address": "0x21413c119b0c11c5d96ae1bd328917bc5c8ed67e",
          "name": "GenomesDAO",
          "symbol": "GENE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20807/thumb/1637683704200x200.png?1637708934"
        },
        {
          "chainId": 1,
          "address": "0xde7d85157d9714eadf595045cc12ca4a5f3e2adb",
          "name": "STP",
          "symbol": "STPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8713/thumb/STP.png?1560262664"
        },
        {
          "chainId": 1,
          "address": "0xf920e4f3fbef5b3ad0a25017514b769bdc4ac135",
          "name": "BABB",
          "symbol": "BAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2168/thumb/babb.png?1547036466"
        },
        {
          "chainId": 1,
          "address": "0xe60779cc1b2c1d0580611c526a8df0e3f870ec48",
          "name": "unshETHing Token",
          "symbol": "USH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29337/thumb/unsheth_large_logo.png?1678175082"
        },
        {
          "chainId": 1,
          "address": "0x1cf4592ebffd730c7dc92c1bdffdfc3b9efcf29a",
          "name": "Waves",
          "symbol": "WAVES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/425/thumb/waves.png?1548386117"
        },
        {
          "chainId": 1,
          "address": "0xcb8fb2438a805664cd8c3e640b85ac473da5be87",
          "name": "ClinTex CTi",
          "symbol": "CTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542"
        },
        {
          "chainId": 1,
          "address": "0x471ea49dd8e60e697f4cac262b5fafcc307506e4",
          "name": "RMRK",
          "symbol": "RMRK",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/18656/thumb/download_%281%29_%281%29.png?1632865271"
        },
        {
          "chainId": 1,
          "address": "0x888888435fde8e7d4c54cab67f206e4199454c60",
          "name": "DFX Finance",
          "symbol": "DFX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14091/thumb/DFX.png?1614320944"
        },
        {
          "chainId": 1,
          "address": "0x84412819ae69b10250d0d54d58f454018f1c8a42",
          "name": "Scarab Tools",
          "symbol": "DUNG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30706/thumb/scarab-cg.png?1690531726"
        },
        {
          "chainId": 1,
          "address": "0xd0929d411954c47438dc1d871dd6081f5c5e149c",
          "name": "Refereum",
          "symbol": "RFR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/2102/thumb/refereum.png?1548608001"
        },
        {
          "chainId": 1,
          "address": "0x429881672b9ae42b8eba0e26cd9c73711b891ca5",
          "name": "Pickle Finance",
          "symbol": "PICKLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12435/thumb/0M4W6Yr6_400x400.jpg?1643006080"
        },
        {
          "chainId": 1,
          "address": "0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a",
          "name": "Orion Protocol",
          "symbol": "ORN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11841/thumb/new_logo.jpeg?1691388510"
        },
        {
          "chainId": 1,
          "address": "0x0981d9774a59a703db85f5eaa23672283ea31106",
          "name": "Pepe Inu",
          "symbol": "PEPINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30941/thumb/Pepe_Inu.png?1689084428"
        },
        {
          "chainId": 1,
          "address": "0x2890df158d76e584877a1d17a85fea3aeeb85aa6",
          "name": "Alien Milady Fumo",
          "symbol": "FUMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30812/thumb/miladyfumo.jpg?1687505051"
        },
        {
          "chainId": 1,
          "address": "0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3",
          "name": "HXRO",
          "symbol": "HXRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7805/thumb/X_3D_Black_%2810%29.png?1690137100"
        },
        {
          "chainId": 1,
          "address": "0xb5b1b659da79a2507c27aad509f15b4874edc0cc",
          "name": "Dust Protocol",
          "symbol": "DUST",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24289/thumb/6388d49d-1f00-448d-92bc-f2db1441bbce.?1685976066"
        },
        {
          "chainId": 1,
          "address": "0xa2120b9e674d3fc3875f415a7df52e382f141225",
          "name": "Automata",
          "symbol": "ATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745"
        },
        {
          "chainId": 1,
          "address": "0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9",
          "name": "Wrapped Terra Classic",
          "symbol": "LUNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13628/thumb/wluna.png?1610448334"
        },
        {
          "chainId": 1,
          "address": "0xbad96ea5d43156a99a94cd1fd36a330aa7e2273e",
          "name": "JDB",
          "symbol": "JDB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28105/thumb/new_logo.png?1669370143"
        },
        {
          "chainId": 1,
          "address": "0x6be61833fc4381990e82d7d4a9f4c9b3f67ea941",
          "name": "Hotbit",
          "symbol": "HTB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5990/thumb/hotbit-token.png?1547041932"
        },
        {
          "chainId": 1,
          "address": "0x30d20208d987713f46dfd34ef128bb16c404d10f",
          "name": "Stader",
          "symbol": "SD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20658/thumb/SD_Token_Logo.png?1684410841"
        },
        {
          "chainId": 1,
          "address": "0x88800092ff476844f74dc2fc427974bbee2794ae",
          "name": "Ambire Wallet",
          "symbol": "WALLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23154/thumb/wallet.PNG?1643352408"
        },
        {
          "chainId": 1,
          "address": "0xbc6da0fe9ad5f3b0d58160288917aa56653660e9",
          "name": "Alchemix USD",
          "symbol": "ALUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14114/thumb/Alchemix_USD.png?1614410406"
        },
        {
          "chainId": 1,
          "address": "0xa11bd36801d8fa4448f0ac4ea7a62e3634ce8c7c",
          "name": "Allbridge",
          "symbol": "ABR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053"
        },
        {
          "chainId": 1,
          "address": "0xec6d73557937974077911a0b6fdc436b0ff70296",
          "name": "Skrimples",
          "symbol": "SKRIMP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30080/thumb/skrimples_logo200.png?1683015652"
        },
        {
          "chainId": 1,
          "address": "0xc7a2572fa8fdb0f7e81d6d3c4e3ccf78fb0dc374",
          "name": "Ben s Finale",
          "symbol": "FINALE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30661/thumb/tGy9rQvi_400x400.jpg?1686129722"
        },
        {
          "chainId": 1,
          "address": "0x115ec79f1de567ec68b7ae7eda501b406626478e",
          "name": "Carry",
          "symbol": "CRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3994/thumb/Carry.png?1592402610"
        },
        {
          "chainId": 1,
          "address": "0xd2b274cfbf9534f56b59ad0fb7e645e0354f4941",
          "name": "XDOGE",
          "symbol": "XDOGE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31107/thumb/photo_2023-07-25_13.59.23.jpeg?1690516177"
        },
        {
          "chainId": 1,
          "address": "0xba9d4199fab4f26efe3551d490e3821486f135ba",
          "name": "SwissBorg",
          "symbol": "CHSB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2117/thumb/YJUrRy7r_400x400.png?1589794215"
        },
        {
          "chainId": 1,
          "address": "0x612e1726435fe38dd49a0b35b4065b56f49c8f11",
          "name": "CryptoCart V2",
          "symbol": "CCV2",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png?1638928576"
        },
        {
          "chainId": 1,
          "address": "0xbc19712feb3a26080ebf6f2f7849b417fdd792ca",
          "name": "BoringDAO",
          "symbol": "BORING",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16429/thumb/Tjq3pXEH_400x400.jpg?1623997009"
        },
        {
          "chainId": 1,
          "address": "0xf0d33beda4d734c72684b5f9abbebf715d0a7935",
          "name": "NuNet",
          "symbol": "NTX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20950/thumb/8Zb2W2Wi_400x400.png?1638137477"
        },
        {
          "chainId": 1,
          "address": "0x60bb16c4a931b1a0b8a7d945c651dd90f41d42cf",
          "name": "Finance Blocks",
          "symbol": "FBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24117/thumb/financeblocksshadow.png?1646380305"
        },
        {
          "chainId": 1,
          "address": "0x397deb686c72384fad502a81f4d7fdb89e1f1280",
          "name": "XELS",
          "symbol": "XELS",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14729/thumb/Xels_logo_square_%281%29.png?1617957186"
        },
        {
          "chainId": 1,
          "address": "0x3700adfd26d5bc062cb8b8a77e68fbd43f58ecab",
          "name": "hiDOODLES",
          "symbol": "HIDOODLES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27427/thumb/hidoodles.png?1663918652"
        },
        {
          "chainId": 1,
          "address": "0x68b429161ec09a6c1d65ba70727ab1faa5bc4026",
          "name": "Ordinal Doge",
          "symbol": "ODOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29323/thumb/o-DOGE-200x200.png?1678070029"
        },
        {
          "chainId": 1,
          "address": "0x3e9bc21c9b189c09df3ef1b824798658d5011937",
          "name": "Linear",
          "symbol": "LINA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12509/thumb/1649227343-linalogo200px.png?1649229117"
        },
        {
          "chainId": 1,
          "address": "0x8ea9bedb8bb7e99643844ec79543f4faa78453e4",
          "name": "Marble Bet",
          "symbol": "MARBLE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31064/thumb/Marble_Bet.png?1690178000"
        },
        {
          "chainId": 1,
          "address": "0x3f14920c99beb920afa163031c4e47a3e03b3e4a",
          "name": "Send",
          "symbol": "SEND",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/30847/thumb/S_logomark__filled_200x200.png?1690429581"
        },
        {
          "chainId": 1,
          "address": "0xe9b076b476d8865cdf79d1cf7df420ee397a7f75",
          "name": "Unification",
          "symbol": "FUND",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/7845/thumb/DV80FOp.png?1554953278"
        },
        {
          "chainId": 1,
          "address": "0xf0655dcee37e5c0b70fffd70d85f88f8edf0aff6",
          "name": "UniDex",
          "symbol": "UNIDX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13178/thumb/unidx.png?1634888975"
        },
        {
          "chainId": 1,
          "address": "0xba50933c268f567bdc86e1ac131be072c6b0b71a",
          "name": "ARPA",
          "symbol": "ARPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357"
        },
        {
          "chainId": 1,
          "address": "0x9bf601da3aba6d4401ca2b9af85a8bd63b98e8ab",
          "name": "Custom Wallet Bot",
          "symbol": "WALLET",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31109/thumb/lgoo_3.png?1690517392"
        },
        {
          "chainId": 1,
          "address": "0x15f74458ae0bfdaa1a96ca1aa779d715cc1eefe4",
          "name": "Grai",
          "symbol": "GRAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30427/thumb/GRAI_Token.png?1684461532"
        },
        {
          "chainId": 1,
          "address": "0x24ccedebf841544c9e6a62af4e8c2fa6e5a46fde",
          "name": "BlueSparrow",
          "symbol": "BLUESPARROW",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26085/thumb/bluesparrow.?1655766000"
        },
        {
          "chainId": 1,
          "address": "0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8",
          "name": "HOKK Finance",
          "symbol": "HOKK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14985/thumb/hokk.png?1662706232"
        },
        {
          "chainId": 1,
          "address": "0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37",
          "name": "Polkacity",
          "symbol": "POLC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959"
        },
        {
          "chainId": 1,
          "address": "0x511686014f39f487e5cdd5c37b4b37606b795ae3",
          "name": "Loyalty Labs",
          "symbol": "LOYAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30605/thumb/loyalty_labs.jpg?1685678055"
        },
        {
          "chainId": 1,
          "address": "0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e",
          "name": "SWFTCOIN",
          "symbol": "SWFTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022"
        },
        {
          "chainId": 1,
          "address": "0x000000007a58f5f58e697e51ab0357bc9e260a04",
          "name": "Concave",
          "symbol": "CNV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24492/thumb/concave.jpg?1648795376"
        },
        {
          "chainId": 1,
          "address": "0xc18c07a18198a6340cf4d94855fe5eb6dd33b46e",
          "name": "QLINDO",
          "symbol": "QLINDO",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/26851/thumb/Qlindo_Token_Green.png?1689737767"
        },
        {
          "chainId": 1,
          "address": "0xc5fb36dd2fb59d3b98deff88425a3f425ee469ed",
          "name": "Dejitaru Tsuka",
          "symbol": "TSUKA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26405/thumb/Tsuka_200x200.png?1657923568"
        },
        {
          "chainId": 1,
          "address": "0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7",
          "name": "SUKU",
          "symbol": "SUKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11969/thumb/suku-200.png?1655447745"
        },
        {
          "chainId": 1,
          "address": "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b",
          "name": "Aurox",
          "symbol": "URUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png?1648524329"
        },
        {
          "chainId": 1,
          "address": "0xf1dc500fde233a4055e25e5bbf516372bc4f6871",
          "name": "Saddle Finance",
          "symbol": "SDL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20476/thumb/SDL_token.png?1679487498"
        },
        {
          "chainId": 1,
          "address": "0x6123b0049f904d730db3c36a31167d9d4121fa6b",
          "name": "Ribbon Finance",
          "symbol": "RBN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15823/thumb/RBN_64x64.png?1633529723"
        },
        {
          "chainId": 1,
          "address": "0xa2085073878152ac3090ea13d1e41bd69e60dc99",
          "name": "Escoin",
          "symbol": "ELG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13566/thumb/escoin-200.png?1609833886"
        },
        {
          "chainId": 1,
          "address": "0x14da230d6726c50f759bc1838717f8ce6373509c",
          "name": "Kambria",
          "symbol": "KAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4784/thumb/kambria.png?1547040127"
        },
        {
          "chainId": 1,
          "address": "0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
          "name": "Keep Network",
          "symbol": "KEEP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336"
        },
        {
          "chainId": 1,
          "address": "0x72953a5c32413614d24c29c84a66ae4b59581bbf",
          "name": "CLever",
          "symbol": "CLEV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27727/thumb/%E6%88%AA%E5%B1%8F2022-10-12_%E4%B8%8A%E5%8D%8810.40.19.png?1665550689"
        },
        {
          "chainId": 1,
          "address": "0x73c69d24ad28e2d43d03cbf35f79fe26ebde1011",
          "name": "Archimedes Finance",
          "symbol": "ARCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28970/thumb/arch.png?1676361582"
        },
        {
          "chainId": 1,
          "address": "0xc744df3419a8c9bd4d6b9852a503eb1c5308a326",
          "name": "RED TOKEN",
          "symbol": "RED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27153/thumb/red.png?1662204588"
        },
        {
          "chainId": 1,
          "address": "0x11eef04c884e24d9b7b4760e7476d06ddf797f36",
          "name": "MX",
          "symbol": "MX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8545/thumb/MEXC_GLOBAL_LOGO.jpeg?1670213280"
        },
        {
          "chainId": 1,
          "address": "0x3b604747ad1720c01ded0455728b62c0d2f100f0",
          "name": "WAGMI Games",
          "symbol": "WAGMIGAMES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25906/thumb/photo_2022-11-18_19-46-26.jpg?1684139896"
        },
        {
          "chainId": 1,
          "address": "0xe1bda0c3bfa2be7f740f0119b6a34f057bd58eba",
          "name": "Winkies",
          "symbol": "WNK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22260/thumb/wnk.png?1644053369"
        },
        {
          "chainId": 1,
          "address": "0xb056c38f6b7dc4064367403e26424cd2c60655e1",
          "name": "CEEK Smart VR",
          "symbol": "CEEK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2581/thumb/ceek-smart-vr-token-logo.png?1547036714"
        },
        {
          "chainId": 1,
          "address": "0x226bb599a12c826476e3a771454697ea52e9e220",
          "name": "Propy",
          "symbol": "PRO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100"
        },
        {
          "chainId": 1,
          "address": "0xcccd1ba9f7acd6117834e0d28f25645decb1736a",
          "name": "ECOx",
          "symbol": "ECOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27962/thumb/_ECOx.png?1666751574"
        },
        {
          "chainId": 1,
          "address": "0x66761fa41377003622aee3c7675fc7b5c1c2fac5",
          "name": "Clearpool",
          "symbol": "CPOOL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19252/thumb/photo_2022-08-31_12.45.02.jpeg?1662105063"
        },
        {
          "chainId": 1,
          "address": "0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099",
          "name": "Cellframe",
          "symbol": "CELL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14465/thumb/cellframe-coingecko.png?1644483414"
        },
        {
          "chainId": 1,
          "address": "0xe0ffddf8fd7d63cf907e433c9293069704d4d40d",
          "name": "Fight Out",
          "symbol": "FGHT",
          "decimals": 18
        },
        {
          "chainId": 1,
          "address": "0x6b0b3a982b4634ac68dd83a4dbf02311ce324181",
          "name": "Artificial Liquid Intelligence",
          "symbol": "ALI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22062/thumb/Logo_Circle_720_%281%29.png?1690169248"
        },
        {
          "chainId": 1,
          "address": "0x62dc4817588d53a056cbbd18231d91ffccd34b2a",
          "name": "DeHive",
          "symbol": "DHV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1626181831"
        },
        {
          "chainId": 1,
          "address": "0xfd0205066521550d7d7ab19da8f72bb004b4c341",
          "name": "Timeless",
          "symbol": "LIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28714/thumb/timeless-logo_3x.png?1673575624"
        },
        {
          "chainId": 1,
          "address": "0x81f8f0bb1cb2a06649e51913a151f0e7ef6fa321",
          "name": "VitaDAO",
          "symbol": "VITA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16580/thumb/QmRjwywiAfpzSfQTuWM3zCTghSHN7G6ohQaar7Ht6WANUp.png?1624506420"
        },
        {
          "chainId": 1,
          "address": "0x64df3aab3b21cc275bb76c4a581cf8b726478ee0",
          "name": "Cramer Coin",
          "symbol": "CRAMER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27463/thumb/cramercoin.jpg?1664176668"
        },
        {
          "chainId": 1,
          "address": "0xee06a81a695750e71a662b51066f2c74cf4478a0",
          "name": "Decentral Games  Old ",
          "symbol": "DG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13267/thumb/%28Old%29_DG.png?1639209538"
        },
        {
          "chainId": 1,
          "address": "0x841fb148863454a3b3570f515414759be9091465",
          "name": "Shih Tzu",
          "symbol": "SHIH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG?1620374274"
        },
        {
          "chainId": 1,
          "address": "0xfe60fba03048effb4acf3f0088ec2f53d779d3bb",
          "name": "3d3d",
          "symbol": "3D3D",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30232/thumb/200x200_2.PNG?1683643581"
        },
        {
          "chainId": 1,
          "address": "0x7f280dac515121dcda3eac69eb4c13a52392cace",
          "name": "Fancy Games",
          "symbol": "FNC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21367/thumb/fnc.png?1639025576"
        },
        {
          "chainId": 1,
          "address": "0xab846fb6c81370327e784ae7cbb6d6a6af6ff4bf",
          "name": "Paladin",
          "symbol": "PAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24558/thumb/Circle-Logo.png?1648180728"
        },
        {
          "chainId": 1,
          "address": "0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68",
          "name": "Inverse Finance",
          "symbol": "INV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg?1614921871"
        },
        {
          "chainId": 1,
          "address": "0x72e9d9038ce484ee986fea183f8d8df93f9ada13",
          "name": "SmartCredit",
          "symbol": "SMARTCREDIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13036/thumb/smartcredit_logo_02_white_a-1.png?1604545479"
        },
        {
          "chainId": 1,
          "address": "0xb753428af26e81097e7fd17f40c88aaa3e04902c",
          "name": "saffron finance",
          "symbol": "SFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13117/thumb/sfi_red_250px.png?1606020144"
        },
        {
          "chainId": 1,
          "address": "0x70401dfd142a16dc7031c56e862fc88cb9537ce0",
          "name": "Bird Money",
          "symbol": "BIRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13260/thumb/favicon-180x180.png?1611546646"
        },
        {
          "chainId": 1,
          "address": "0xa8388b8334beb4840d65ed80f858b080dffd7e2b",
          "name": "Son Of Pepe",
          "symbol": "SOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30895/thumb/IMG_20230701_205503_304_%283%29.png?1688542961"
        },
        {
          "chainId": 1,
          "address": "0x7ddc52c4de30e94be3a6a0a2b259b2850f421989",
          "name": "Gomining Token",
          "symbol": "GMT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15662/thumb/gmt.png?1640069038"
        },
        {
          "chainId": 1,
          "address": "0x30dcba0405004cf124045793e1933c798af9e66a",
          "name": "Yieldification",
          "symbol": "YDF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26699/thumb/logo.png?1659609811"
        },
        {
          "chainId": 1,
          "address": "0xc36ad98e62598ae24d4487d8012209f687c30d45",
          "name": "Asap Sniper Bot",
          "symbol": "ASAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30707/thumb/LOGO_%281%29.jpeg?1686548085"
        },
        {
          "chainId": 1,
          "address": "0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429",
          "name": "Golem",
          "symbol": "GLM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png?1606392013"
        },
        {
          "chainId": 1,
          "address": "0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4",
          "name": "Sora Validator",
          "symbol": "VAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13299/thumb/val-gold-256.png?1607242927"
        },
        {
          "chainId": 1,
          "address": "0x5dd57da40e6866c9fcc34f4b6ddc89f1ba740dfe",
          "name": "BrightID",
          "symbol": "BRIGHT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18415/thumb/bright.PNG?1631841211"
        },
        {
          "chainId": 1,
          "address": "0xf418588522d5dd018b425e472991e52ebbeeeeee",
          "name": "Push Protocol",
          "symbol": "PUSH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14769/thumb/aiOxYOJI_400x400.jpeg?1664499790"
        },
        {
          "chainId": 1,
          "address": "0x6393e822874728f8afa7e1c9944e417d37ca5878",
          "name": "Cryptoindex com 100",
          "symbol": "CIX100",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6106/thumb/cix.png?1563780642"
        },
        {
          "chainId": 1,
          "address": "0x3446dd70b2d52a6bf4a5a192d9b0a161295ab7f9",
          "name": "sudoswap",
          "symbol": "SUDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27151/thumb/sudo.png?1662105271"
        },
        {
          "chainId": 1,
          "address": "0x6f40d4a6237c257fff2db00fa0510deeecd303eb",
          "name": "Instadapp",
          "symbol": "INST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14688/thumb/30hFM0-n_400x400.jpg?1617786420"
        },
        {
          "chainId": 1,
          "address": "0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f",
          "name": "BnkToTheFuture",
          "symbol": "BFT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3468/thumb/bnktothefuture.png?1547351865"
        },
        {
          "chainId": 1,
          "address": "0xbb9fd9fa4863c03c574007ff3370787b9ce65ff6",
          "name": "HILO",
          "symbol": "HILO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28661/thumb/cmchiloo.png?1672976442"
        },
        {
          "chainId": 1,
          "address": "0xfb7b4564402e5500db5bb6d63ae671302777c75a",
          "name": "DexTools",
          "symbol": "DEXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188"
        },
        {
          "chainId": 1,
          "address": "0x5d65d971895edc438f465c17db6992698a52318d",
          "name": "Nebulas",
          "symbol": "NAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2431/thumb/193394331.png?1597976208"
        },
        {
          "chainId": 1,
          "address": "0xde30da39c46104798bb5aa3fe8b9e0e1f348163f",
          "name": "Gitcoin",
          "symbol": "GTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929"
        },
        {
          "chainId": 1,
          "address": "0x038a68ff68c393373ec894015816e33ad41bd564",
          "name": "Glitch Protocol",
          "symbol": "GLCH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg?1611100011"
        },
        {
          "chainId": 1,
          "address": "0xde2f7766c8bf14ca67193128535e5c7454f8387c",
          "name": "Metadium",
          "symbol": "META",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5247/thumb/META_Logo_black.png?1548833790"
        },
        {
          "chainId": 1,
          "address": "0x6adb2e268de2aa1abf6578e4a8119b960e02928f",
          "name": "ShibaDoge",
          "symbol": "SHIBDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22018/thumb/5HMWoFW0_400x400.jpg?1640596392"
        },
        {
          "chainId": 1,
          "address": "0x72bab498fa50a33a03362d0024bb27efbc50a7b7",
          "name": "BlockEscrow",
          "symbol": "BET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30965/thumb/gzKF1YgJ_400x400.jpg?1689240075"
        },
        {
          "chainId": 1,
          "address": "0xbbc2ae13b23d715c30720f079fcd9b4a74093505",
          "name": "Ethernity Chain",
          "symbol": "ERN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402"
        },
        {
          "chainId": 1,
          "address": "0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25",
          "name": "Coinmetro",
          "symbol": "XCM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3125/thumb/XCM_Token_Logo_.png?1646280053"
        },
        {
          "chainId": 1,
          "address": "0xadd5e881984783dd432f80381fb52f45b53f3e70",
          "name": "Vite",
          "symbol": "VITE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4513/thumb/Vite.png?1558014583"
        },
        {
          "chainId": 1,
          "address": "0xa7de087329bfcda5639247f96140f9dabe3deed1",
          "name": "Statera",
          "symbol": "STA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11472/thumb/Statera.png?1590415353"
        },
        {
          "chainId": 1,
          "address": "0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43",
          "name": "Chain Guardians",
          "symbol": "CGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14326/thumb/cgg_logo.png?1615429976"
        },
        {
          "chainId": 1,
          "address": "0x5a5389ba78f04d783e9f30e5a8c45727c5e018be",
          "name": "Ask Chip",
          "symbol": "CHIP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29041/thumb/Ask_Chip.png?1676103028"
        },
        {
          "chainId": 1,
          "address": "0x77e06c9eccf2e797fd462a92b6d7642ef85b0a44",
          "name": "Wrapped TAO",
          "symbol": "WTAO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29087/thumb/wtao.png?1676519685"
        },
        {
          "chainId": 1,
          "address": "0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0",
          "name": "Tellor Tributes",
          "symbol": "TRB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686"
        },
        {
          "chainId": 1,
          "address": "0x8798249c2e607446efb7ad49ec89dd1865ff4272",
          "name": "xSUSHI",
          "symbol": "XSUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13725/thumb/xsushi.png?1612538526"
        },
        {
          "chainId": 1,
          "address": "0x3f2d4708f75de6fb60b687fed326697634774deb",
          "name": "NeoBot",
          "symbol": "NEOBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31033/thumb/20230718_190506_0000.png?1689840907"
        },
        {
          "chainId": 1,
          "address": "0xa8b919680258d369114910511cc87595aec0be6d",
          "name": "LUKSO  OLD ",
          "symbol": "LYXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11423/thumb/LYX.png?1689818779"
        },
        {
          "chainId": 1,
          "address": "0x91dfbee3965baaee32784c2d546b7a0c62f268c9",
          "name": "Forj",
          "symbol": "BONDLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13322/thumb/FORJ_twitter_twitter-linked_in_profile_%281%29.png?1653026729"
        },
        {
          "chainId": 1,
          "address": "0xbc4171f45ef0ef66e76f979df021a34b46dcc81d",
          "name": "Dora Factory",
          "symbol": "DORA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14478/thumb/dora_logo.jpg?1616410086"
        },
        {
          "chainId": 1,
          "address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
          "name": "Stella",
          "symbol": "ALPHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12738/thumb/Stella200x200-06.png?1684988292"
        },
        {
          "chainId": 1,
          "address": "0x5a3e6a77ba2f983ec0d371ea3b475f8bc0811ad5",
          "name": "0x0 ai  AI Smart Contract",
          "symbol": "0X0",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28880/thumb/0x0.png?1675140105"
        },
        {
          "chainId": 1,
          "address": "0xba100000625a3754423978a60c9317c58a424e3d",
          "name": "Balancer",
          "symbol": "BAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11683/thumb/Balancer.png?1592792958"
        },
        {
          "chainId": 1,
          "address": "0x35a18000230da775cac24873d00ff85bccded550",
          "name": "cUNI",
          "symbol": "CUNI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/12696/thumb/compound-uni.png?1601789718"
        },
        {
          "chainId": 1,
          "address": "0x335f4e66b9b61cee5ceade4e727fcec20156b2f0",
          "name": "ElmoERC",
          "symbol": "ELMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30261/thumb/200x200_%282%29.png?1683772696"
        },
        {
          "chainId": 1,
          "address": "0x450e7f6e3a2f247a51b98c39297a9a5bfbdb3170",
          "name": "Elon GOAT",
          "symbol": "EGT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22301/thumb/ElonGoat.jpg?1641438513"
        },
        {
          "chainId": 1,
          "address": "0x07150e919b4de5fd6a63de1f9384828396f25fdc",
          "name": "Base Protocol",
          "symbol": "BASE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/13265/thumb/200x200green.png?1607650121"
        },
        {
          "chainId": 1,
          "address": "0x6293bbe52c3682057163172850002f18f993da6a",
          "name": "TruthGPT  ETH ",
          "symbol": "TRUTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30993/thumb/LOGO_200.png?1689568156"
        },
        {
          "chainId": 1,
          "address": "0xb8a768cd332e4461fccfbdb1d0292ceffbb2bb8a",
          "name": "COLLIE INU",
          "symbol": "COLLIE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27365/thumb/Logo_New_200.png?1678340658"
        },
        {
          "chainId": 1,
          "address": "0x7659ce147d0e714454073a5dd7003544234b6aa0",
          "name": "XCAD Network",
          "symbol": "XCAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15857/thumb/logoWhiteX.jpg?1666230966"
        },
        {
          "chainId": 1,
          "address": "0xd417144312dbf50465b1c641d016962017ef6240",
          "name": "Covalent",
          "symbol": "CQT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14168/thumb/covalent-cqt.png?1624545218"
        },
        {
          "chainId": 1,
          "address": "0xedeec5691f23e4914cf0183a4196bbeb30d027a0",
          "name": "Wrapped Statera",
          "symbol": "WSTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png?1610011862"
        },
        {
          "chainId": 1,
          "address": "0xc477d038d5420c6a9e0b031712f61c5120090de9",
          "name": "Boson Protocol",
          "symbol": "BOSON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14710/thumb/boson_logo.png?1617882472"
        },
        {
          "chainId": 1,
          "address": "0xb40b6608b2743e691c9b54ddbdee7bf03cd79f1c",
          "name": "Zunami USD",
          "symbol": "UZD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27621/thumb/UZD_Logo_200x200.png?1666428507"
        },
        {
          "chainId": 1,
          "address": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
          "name": "OMG Network",
          "symbol": "OMG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168"
        },
        {
          "chainId": 1,
          "address": "0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664",
          "name": "QuarkChain",
          "symbol": "QKC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3849/thumb/quarkchain.png?1548387524"
        },
        {
          "chainId": 1,
          "address": "0xcdf7028ceab81fa0c6971208e83fa7872994bee5",
          "name": "Threshold Network",
          "symbol": "T",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg?1641220340"
        },
        {
          "chainId": 1,
          "address": "0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a",
          "name": "BEPRO Network",
          "symbol": "BEPRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10251/thumb/logo.png?1610592699"
        },
        {
          "chainId": 1,
          "address": "0xa35923162c49cf95e6bf26623385eb431ad920d3",
          "name": "Turbo",
          "symbol": "TURBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30117/thumb/turbo.jpg?1683171808"
        },
        {
          "chainId": 1,
          "address": "0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d",
          "name": "Aeternity",
          "symbol": "AE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1091/thumb/aeternity.png?1547035060"
        },
        {
          "chainId": 1,
          "address": "0x1ce270557c1f68cfb577b856766310bf8b47fd9c",
          "name": "MongCoin",
          "symbol": "MONG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30051/thumb/h3OCGNvn_400x400.jpg?1682996331"
        },
        {
          "chainId": 1,
          "address": "0x49642110b712c1fd7261bc074105e9e44676c68f",
          "name": "DinoLFG",
          "symbol": "DINO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28239/thumb/F1giO3rXsAAFOsG.jpg?1690167727"
        },
        {
          "chainId": 1,
          "address": "0x37268c4f56ebb13dfae9c16d57d17579312d0ee1",
          "name": "0xAuto io   Contract Auto Deployer",
          "symbol": "0XA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30673/thumb/photo_2023-06-01_11-19-59_%282%29.jpg?1686211837"
        },
        {
          "chainId": 1,
          "address": "0x20cdecbf5d56870b4068a255580a58d068446c92",
          "name": "Monkeys Token",
          "symbol": "MONKEYS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29782/thumb/200x200_Monkeys_Logo.png?1683525590"
        },
        {
          "chainId": 1,
          "address": "0x6c5ba91642f10282b576d91922ae6448c9d52f4e",
          "name": "Phala",
          "symbol": "PHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12451/thumb/phala.png?1600061318"
        },
        {
          "chainId": 1,
          "address": "0xee573a945b01b788b9287ce062a0cfc15be9fd86",
          "name": "Exeedme",
          "symbol": "XED",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13518/thumb/exeedme.png?1610669597"
        },
        {
          "chainId": 1,
          "address": "0xc4c2614e694cf534d407ee49f8e44d125e4681c4",
          "name": "Chain Games",
          "symbol": "CHAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12257/thumb/chain-pfp-logo.png?1671678582"
        },
        {
          "chainId": 1,
          "address": "0x48c87cdacb6bb6bf6e5cd85d8ee5c847084c7410",
          "name": "Hamsters",
          "symbol": "HAMS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31055/thumb/hams_logo_s.png?1689925848"
        },
        {
          "chainId": 1,
          "address": "0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc",
          "name": "Tap",
          "symbol": "XTP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10291/thumb/0_3SJYkk_400x400.jpg?1577229220"
        },
        {
          "chainId": 1,
          "address": "0xfc979087305a826c2b2a0056cfaba50aad3e6439",
          "name": "Dafi Protocol",
          "symbol": "DAFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406"
        },
        {
          "chainId": 1,
          "address": "0x88a9a52f944315d5b4e917b9689e65445c401e83",
          "name": "FEAR",
          "symbol": "FEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865"
        },
        {
          "chainId": 1,
          "address": "0x80c62fe4487e1351b47ba49809ebd60ed085bf52",
          "name": "Clover Finance",
          "symbol": "CLV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15278/thumb/photo_2022-03-24_10-22-33.jpg?1648531786"
        },
        {
          "chainId": 1,
          "address": "0x607f4c5bb672230e8672085532f7e901544a7375",
          "name": "iExec RLC",
          "symbol": "RLC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202"
        },
        {
          "chainId": 1,
          "address": "0xf2a22b900dde3ba18ec2aef67d4c8c1a0dab6aac",
          "name": "Monkeys",
          "symbol": "MONKEYS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29815/thumb/Monkeys_Elon.png?1681801321"
        },
        {
          "chainId": 1,
          "address": "0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5",
          "name": "BitDAO",
          "symbol": "BIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17627/thumb/rI_YptK8.png?1653983088"
        },
        {
          "chainId": 1,
          "address": "0xa6422e3e219ee6d4c1b18895275fe43556fd50ed",
          "name": "Stobox",
          "symbol": "STBU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906"
        },
        {
          "chainId": 1,
          "address": "0x86ed939b500e121c0c5f493f399084db596dad20",
          "name": "SpaceChain  ERC 20 ",
          "symbol": "SPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6659/thumb/Spacechain.jpg?1547042861"
        },
        {
          "chainId": 1,
          "address": "0xef19f4e48830093ce5bc8b3ff7f903a0ae3e9fa1",
          "name": "BOTXCOIN",
          "symbol": "BOTX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6827/thumb/botx.jpg?1548317786"
        },
        {
          "chainId": 1,
          "address": "0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724",
          "name": "Viberate",
          "symbol": "VIB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/983/thumb/Viberate.png?1547034873"
        },
        {
          "chainId": 1,
          "address": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
          "name": "Huobi",
          "symbol": "HT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2822/thumb/huobi-token-logo.png?1547036992"
        },
        {
          "chainId": 1,
          "address": "0x5e8422345238f34275888049021821e8e08caa1f",
          "name": "Frax Ether",
          "symbol": "FRXETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28284/thumb/frxETH_icon.png?1679886981"
        },
        {
          "chainId": 1,
          "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
          "name": "Wrapped Bitcoin",
          "symbol": "WBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744"
        },
        {
          "chainId": 1,
          "address": "0x45804880de22913dafe09f4980848ece6ecbaf78",
          "name": "PAX Gold",
          "symbol": "PAXG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9519/thumb/paxgold.png?1687143101"
        },
        {
          "chainId": 1,
          "address": "0x4cff49d0a19ed6ff845a9122fa912abcfb1f68a6",
          "name": "WadzPay",
          "symbol": "WTK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13083/thumb/200xWadzToken.png?1604984329"
        },
        {
          "chainId": 1,
          "address": "0x667102bd3413bfeaa3dffb48fa8288819e480a88",
          "name": "Tokenize Xchange",
          "symbol": "TKX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4984/thumb/TKX_-_Logo_-_RGB-15.png?1672912391"
        },
        {
          "chainId": 1,
          "address": "0xc39b0d3c3da68cdaefe24a07373b9894496eca97",
          "name": "Caacon",
          "symbol": "CC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31023/thumb/Caacon_logo.png?1689836544"
        },
        {
          "chainId": 1,
          "address": "0x848896470d989f30503d8f883c331f63b73b66ea",
          "name": "Medicle",
          "symbol": "MDI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31082/thumb/Medicle%28MDI%29_NEW_.png?1690356435"
        },
        {
          "chainId": 1,
          "address": "0xc55126051b22ebb829d00368f4b12bde432de5da",
          "name": "Redacted",
          "symbol": "BTRFLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26745/thumb/redacted_v2.jpg?1660011507"
        },
        {
          "chainId": 1,
          "address": "0x925206b8a707096ed26ae47c84747fe0bb734f59",
          "name": "WhiteBIT Token",
          "symbol": "WBT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27045/thumb/wbt_token.png?1667923752"
        },
        {
          "chainId": 1,
          "address": "0xffbf315f70e458e49229654dea4ce192d26f9b25",
          "name": "Voltage",
          "symbol": "VOLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18515/thumb/volt.png?1632219924"
        },
        {
          "chainId": 1,
          "address": "0x853d955acef822db058eb8505911ed77f175b99e",
          "name": "Frax",
          "symbol": "FRAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13422/thumb/FRAX_icon.png?1679886922"
        },
        {
          "chainId": 1,
          "address": "0x4a527d8fc13c5203ab24ba0944f4cb14658d1db6",
          "name": "Morpheus Labs",
          "symbol": "MITX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3164/thumb/mitx.png?1604888269"
        },
        {
          "chainId": 1,
          "address": "0x8642a849d0dcb7a15a974794668adcfbe4794b56",
          "name": "Prosper",
          "symbol": "PROS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13668/thumb/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732"
        },
        {
          "chainId": 1,
          "address": "0xbe1a001fe942f96eea22ba08783140b9dcc09d28",
          "name": "Beta Finance",
          "symbol": "BETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18715/thumb/beta_finance.jpg?1633087053"
        },
        {
          "chainId": 1,
          "address": "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
          "name": "LEO Token",
          "symbol": "LEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8418/thumb/leo-token.png?1558326215"
        },
        {
          "chainId": 1,
          "address": "0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206",
          "name": "NEXO",
          "symbol": "NEXO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3695/thumb/nexo.png?1548086057"
        },
        {
          "chainId": 1,
          "address": "0xccba0b2bc4babe4cbfb6bd2f1edc2a9e86b7845f",
          "name": "Winter",
          "symbol": "WINTER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25367/thumb/winter.png?1651317666"
        },
        {
          "chainId": 1,
          "address": "0x4da34f8264cb33a5c9f17081b9ef5ff6091116f4",
          "name": "ELYFI",
          "symbol": "ELFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png?1645691480"
        },
        {
          "chainId": 1,
          "address": "0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b",
          "name": "Nexus Mutual",
          "symbol": "NXM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11810/thumb/NXMmain.png?1674799570"
        },
        {
          "chainId": 1,
          "address": "0x6bc40d4099f9057b23af309c08d935b890d7adc0",
          "name": "SnailBrook",
          "symbol": "SNAIL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29922/thumb/snail_200x200.png?1682498441"
        },
        {
          "chainId": 1,
          "address": "0xf36c5f04127f7470834ed6f98bddc1be62aba48d",
          "name": "CryptoAI",
          "symbol": "CAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28899/thumb/200blue.png?1675229934"
        },
        {
          "chainId": 1,
          "address": "0x530824da86689c9c17cdc2871ff29b058345b44a",
          "name": "Short term T Bill Token",
          "symbol": "STBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29239/thumb/STBT_icon.png?1677570331"
        },
        {
          "chainId": 1,
          "address": "0x544c42fbb96b39b21df61cf322b5edc285ee7429",
          "name": "InsurAce",
          "symbol": "INSUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14226/thumb/insur.png?1615124622"
        },
        {
          "chainId": 1,
          "address": "0x31c8eacbffdd875c74b94b077895bd78cf1e64a3",
          "name": "Radworks",
          "symbol": "RAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918"
        },
        {
          "chainId": 1,
          "address": "0xd8912c10681d8b21fd3742244f44658dba12264e",
          "name": "Pluton",
          "symbol": "PLU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624"
        },
        {
          "chainId": 1,
          "address": "0x378cb52b00f9d0921cb46dfc099cff73b42419dc",
          "name": "LUSD yVault",
          "symbol": "YVLUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28788/thumb/yvLUSD-128-0x378cb52b00F9D0921cb46dFc099CFf73b42419dC.png?1674224700"
        },
        {
          "chainId": 1,
          "address": "0x0100546f2cd4c9d97f798ffc9755e47865ff7ee6",
          "name": "Alchemix ETH",
          "symbol": "ALETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16271/thumb/aleth.png?1685957289"
        },
        {
          "chainId": 1,
          "address": "0x5026f006b85729a8b14553fae6af249ad16c9aab",
          "name": "Wojak",
          "symbol": "WOJAK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29856/thumb/wojak.png?1681821640"
        },
        {
          "chainId": 1,
          "address": "0x473037de59cf9484632f4a27b509cfe8d4a31404",
          "name": "STEPN Green Satoshi Token on ETH",
          "symbol": "GST-ETH",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26528/thumb/gst_eth.png?1658487279"
        },
        {
          "chainId": 1,
          "address": "0xface851a4921ce59e912d19329929ce6da6eb0c7",
          "name": "cLINK",
          "symbol": "CLINK",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23943/thumb/iShot2022-02-25_14.35.54.png?1645770966"
        },
        {
          "chainId": 1,
          "address": "0x27b5739e22ad9033bcbf192059122d163b60349d",
          "name": "Staked Yearn CRV Vault",
          "symbol": "ST-YCRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27623/thumb/st-yCRV-128-0x27B5739e22ad9033bcBf192059122d163b60349D.png?1674200862"
        },
        {
          "chainId": 1,
          "address": "0x6781a0f84c7e9e846dcb84a9a5bd49333067b104",
          "name": "Zap",
          "symbol": "ZAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476"
        },
        {
          "chainId": 1,
          "address": "0x1982b2f5814301d4e9a8b0201555376e62f82428",
          "name": "Aave Interest Bearing STETH",
          "symbol": "ASTETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24163/thumb/steth.jpg?1646634315"
        },
        {
          "chainId": 1,
          "address": "0xac3e018457b222d93114458476f3e3416abbe38f",
          "name": "Staked Frax Ether",
          "symbol": "SFRXETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28285/thumb/sfrxETH_icon.png?1679886768"
        },
        {
          "chainId": 1,
          "address": "0x4b520c812e8430659fc9f12f6d0c39026c83588d",
          "name": "Decentral Games",
          "symbol": "DG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21173/thumb/Decentral_Games_Logo-1.png?1638441275"
        },
        {
          "chainId": 1,
          "address": "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd",
          "name": "Gemini Dollar",
          "symbol": "GUSD",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278"
        },
        {
          "chainId": 1,
          "address": "0xa117000000f279d81a1d3cc75430faa017fa5a2e",
          "name": "Aragon",
          "symbol": "ANT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/681/thumb/Avatar_Circle_x6.png?1685684270"
        },
        {
          "chainId": 1,
          "address": "0x616e8bfa43f920657b3497dbf40d6b1a02d4608d",
          "name": "Aura BAL",
          "symbol": "AURABAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26538/thumb/auraBAL.png?1658721102"
        },
        {
          "chainId": 1,
          "address": "0x42bbfa2e77757c645eeaad1655e0911a7553efbc",
          "name": "Boba Network",
          "symbol": "BOBA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20285/thumb/BOBA.png?1636811576"
        },
        {
          "chainId": 1,
          "address": "0x75459a499a79ccd7c5fae3201738f9e4677d69e4",
          "name": "Prometheus Trading",
          "symbol": "PROME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31073/thumb/Artboard_2_3x.png?1690339108"
        },
        {
          "chainId": 1,
          "address": "0xac51066d7bec65dc4589368da368b212745d63e8",
          "name": "My Neighbor Alice",
          "symbol": "ALICE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968"
        },
        {
          "chainId": 1,
          "address": "0x8dbf9a4c99580fc7fd4024ee08f3994420035727",
          "name": "ECO",
          "symbol": "ECO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27961/thumb/_ECO.png?1666751247"
        },
        {
          "chainId": 1,
          "address": "0xbaac2b4491727d78d2b78815144570b9f2fe8899",
          "name": "The Doge NFT",
          "symbol": "DOG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110"
        },
        {
          "chainId": 1,
          "address": "0x80008bcd713c38af90a9930288d446bc3bd2e684",
          "name": "Karate Combat",
          "symbol": "KARATE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30384/thumb/karate.jpg?1684302528"
        },
        {
          "chainId": 1,
          "address": "0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096",
          "name": "Bounce",
          "symbol": "AUCTION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13860/thumb/1_KtgpRIJzuwfHe0Rl0avP_g.jpeg?1612412025"
        },
        {
          "chainId": 1,
          "address": "0x7051faed0775f664a0286af4f75ef5ed74e02754",
          "name": "Changex",
          "symbol": "CHANGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26487/thumb/Changex-mark-200x200.png?1665655365"
        },
        {
          "chainId": 1,
          "address": "0x4674a4f24c5f63d53f22490fb3a08eaaad739ff8",
          "name": "Brokoli",
          "symbol": "BRKL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18763/thumb/brkl.png?1633356263"
        },
        {
          "chainId": 1,
          "address": "0xa71d0588eaf47f12b13cf8ec750430d21df04974",
          "name": "Shiba Predator",
          "symbol": "QOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24430/thumb/l1KzMcL.png?1647660619"
        },
        {
          "chainId": 1,
          "address": "0x037a54aab062628c9bbae1fdb1583c195585fe41",
          "name": "LCX",
          "symbol": "LCX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008"
        },
        {
          "chainId": 1,
          "address": "0x0c7d5ae016f806603cb1782bea29ac69471cab9c",
          "name": "Bifrost",
          "symbol": "BFC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4639/thumb/BFC_Symbol.png?1688375417"
        },
        {
          "chainId": 1,
          "address": "0xe6fd75ff38adca4b97fbcd938c86b98772431867",
          "name": "Elastos",
          "symbol": "ELA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2780/thumb/Elastos.png?1597048112"
        },
        {
          "chainId": 1,
          "address": "0xfaba6f8e4a5e8ab82f62fe7c39859fa577269be3",
          "name": "Ondo Finance",
          "symbol": "ONDO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26580/thumb/YUBxfjcj_400x400.jpg?1658888594"
        },
        {
          "chainId": 1,
          "address": "0x18084fba666a33d37592fa2633fd49a74dd93a88",
          "name": "tBTC",
          "symbol": "TBTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11224/thumb/0x18084fba666a33d37592fa2633fd49a74dd93a88.png?1674474504"
        },
        {
          "chainId": 1,
          "address": "0xdc349913d53b446485e98b76800b6254f43df695",
          "name": "Bezoge Earth",
          "symbol": "BEZOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15639/thumb/token-logo.png?1621408212"
        },
        {
          "chainId": 1,
          "address": "0xdf574c24545e5ffecb9a659c229253d4111d87e1",
          "name": "HUSD",
          "symbol": "HUSD",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/9567/thumb/HUSD.jpg?1568889385"
        },
        {
          "chainId": 1,
          "address": "0x817bbdbc3e8a1204f3691d14bb44992841e3db35",
          "name": "Cudos",
          "symbol": "CUDOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13651/thumb/CudosIconTransparent.png?1610631426"
        },
        {
          "chainId": 1,
          "address": "0x549020a9cb845220d66d3e9c6d9f9ef61c981102",
          "name": "Sidus",
          "symbol": "SIDUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21401/thumb/SIDUS_coin_logo.png?1639405031"
        },
        {
          "chainId": 1,
          "address": "0x3a8d5bc8a8948b68dfc0ce9c14ac4150e083518c",
          "name": "Paralink Network",
          "symbol": "PARA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15789/thumb/para.PNG?1621893038"
        },
        {
          "chainId": 1,
          "address": "0x7de91b204c1c737bcee6f000aaa6569cf7061cb7",
          "name": "Robonomics Network",
          "symbol": "XRT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/7024/thumb/Robonomics-Network-logo.png?1547043451"
        },
        {
          "chainId": 1,
          "address": "0x7d8146cf21e8d7cbe46054e01588207b51198729",
          "name": "BOB Token",
          "symbol": "BOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29929/thumb/bob.png?1682140021"
        },
        {
          "chainId": 1,
          "address": "0x2ba592f78db6436527729929aaf6c908497cb200",
          "name": "Cream",
          "symbol": "CREAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11976/thumb/Cream.png?1596593418"
        },
        {
          "chainId": 1,
          "address": "0x16594930d16f3970e1a4317c6016555cb2e7b7fc",
          "name": "TokenBot",
          "symbol": "TKB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27106/thumb/tokenbot-logo-icon.png?1662007634"
        },
        {
          "chainId": 1,
          "address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
          "name": "Rocket Pool",
          "symbol": "RPL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2090/thumb/rocket_pool_%28RPL%29.png?1637662441"
        },
        {
          "chainId": 1,
          "address": "0x6d6554939d646f274d0fc3cecb7dab5d76bc908f",
          "name": "Morphswap",
          "symbol": "MS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773"
        },
        {
          "chainId": 1,
          "address": "0x6c059413686565d5ad6cce6eed7742c42dbc44ca",
          "name": "Laelaps",
          "symbol": "LAELAPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30986/thumb/laelaps.png?1689563378"
        },
        {
          "chainId": 1,
          "address": "0x3106a0a076bedae847652f42ef07fd58589e001f",
          "name": "Alkimi",
          "symbol": "ADS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17979/thumb/Alkimi_Alternatives_PNG.png?1669458732"
        },
        {
          "chainId": 1,
          "address": "0x7dedbce5a2e31e4c75f87fea60bf796c17718715",
          "name": "Penpie",
          "symbol": "PNP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30760/thumb/PNP_Token.png?1686886798"
        },
        {
          "chainId": 1,
          "address": "0x32353a6c91143bfd6c7d363b546e62a9a2489a20",
          "name": "Adventure Gold",
          "symbol": "AGLD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg?1630570955"
        },
        {
          "chainId": 1,
          "address": "0x275f5ad03be0fa221b4c6649b8aee09a42d9412a",
          "name": "Monavale",
          "symbol": "MONA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13298/thumb/monavale_logo.jpg?1607232721"
        },
        {
          "chainId": 1,
          "address": "0x389999216860ab8e0175387a0c90e5c52522c945",
          "name": "FEG  OLD ",
          "symbol": "FEG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/13878/thumb/F-Icon-FEG-200x200-288dpi-01_%281%29.png?1665641152"
        },
        {
          "chainId": 1,
          "address": "0xc50ef449171a51fbeafd7c562b064b6471c36caa",
          "name": "Zombie Inu  OLD ",
          "symbol": "ZINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19516/thumb/zinu.jpeg?1635321575"
        },
        {
          "chainId": 1,
          "address": "0xdd69db25f6d620a7bad3023c5d32761d353d3de9",
          "name": "Goerli ETH",
          "symbol": "GETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29217/thumb/goerli-eth.png?1677429831"
        },
        {
          "chainId": 1,
          "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
          "name": "XDAO",
          "symbol": "XDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27363/thumb/token_2.png?1688375985"
        },
        {
          "chainId": 1,
          "address": "0x77fba179c79de5b7653f68b5039af940ada60ce0",
          "name": "Ampleforth Governance",
          "symbol": "FORTH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835"
        },
        {
          "chainId": 1,
          "address": "0x68749665ff8d2d112fa859aa293f07a622782f38",
          "name": "Tether Gold",
          "symbol": "XAUT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/10481/thumb/Tether_Gold.png?1668148656"
        },
        {
          "chainId": 1,
          "address": "0x8a854288a5976036a725879164ca3e91d30c6a1b",
          "name": "GET Protocol",
          "symbol": "GET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/1927/thumb/GET_Protocol.png?1552893230"
        },
        {
          "chainId": 1,
          "address": "0x3a2348dbb9250a43226a27e21edb94882bb7de8d",
          "name": "The AI Dev Bot",
          "symbol": "AIDEV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/31052/thumb/AIDEV.png?1689925073"
        },
        {
          "chainId": 1,
          "address": "0x56015bbe3c01fe05bc30a8a9a9fd9a88917e7db3",
          "name": "Mooncat CAT",
          "symbol": "CAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12177/thumb/happy2-2.jpg?1619593488"
        },
        {
          "chainId": 1,
          "address": "0x2d886570a0da04885bfd6eb48ed8b8ff01a0eb7e",
          "name": "Blockchain Bets",
          "symbol": "BCB",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28694/thumb/200x200.jpg?1673412258"
        },
        {
          "chainId": 1,
          "address": "0xce3f08e664693ca792cace4af1364d5e220827b2",
          "name": "Saitama",
          "symbol": "SAITAMA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16353/thumb/SOIKDUWf_400x400.jpeg?1661170022"
        },
        {
          "chainId": 1,
          "address": "0xa1d6df714f91debf4e0802a542e13067f31b8262",
          "name": "RFOX",
          "symbol": "RFOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12956/thumb/rfox.png?1642926902"
        },
        {
          "chainId": 1,
          "address": "0x15b543e986b8c34074dfc9901136d9355a537e7e",
          "name": "Student Coin",
          "symbol": "STC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6260/thumb/logo_in_circle_%281%29.png?1667725082"
        },
        {
          "chainId": 1,
          "address": "0xe46091dce9c67691bcf22768bbee0bc9e20d4beb",
          "name": "WSB Classic",
          "symbol": "WSBC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30165/thumb/photo_2023-05-05_07-44-23.jpg?1683599619"
        },
        {
          "chainId": 1,
          "address": "0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a",
          "name": "Blocksquare",
          "symbol": "BST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23924/thumb/XpkkqPVR_400x400.jpg?1645689021"
        },
        {
          "chainId": 1,
          "address": "0x4e58ea38f4915850fbe1577cd818a09ba673c8ee",
          "name": "OmniaBot",
          "symbol": "OMNIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31068/thumb/omniapng.jpeg?1690179834"
        },
        {
          "chainId": 1,
          "address": "0xfd414e39155f91e94443a9fe97e856569d0f5eec",
          "name": "Shibarium Perpetuals",
          "symbol": "SERP",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29465/thumb/photo_2023-03-15_22.44.48-removebg-preview_%281%29.png?1679109995"
        },
        {
          "chainId": 1,
          "address": "0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789",
          "name": "Kromatika",
          "symbol": "KROM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20541/thumb/KROM_Transparent.png?1641398421"
        },
        {
          "chainId": 1,
          "address": "0x9bf1d7d63dd7a4ce167cf4866388226eeefa702e",
          "name": "Ben",
          "symbol": "BEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30175/thumb/BEN_COIN.png?1685348255"
        },
        {
          "chainId": 1,
          "address": "0xad8d0de33c43eefe104a279cdb6ae250c12e6214",
          "name": "Naruto",
          "symbol": "NARUTO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29963/thumb/logo.jpg?1682389582"
        },
        {
          "chainId": 1,
          "address": "0xed328e9c1179a30ddc1e7595e036aed8760c22af",
          "name": "Metacade",
          "symbol": "MCADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29764/thumb/IMG_20230407_190739_059.jpg?1681192683"
        },
        {
          "chainId": 1,
          "address": "0x082646b22a3960da69ef7a778c16dd6fb85dd999",
          "name": "Fuck Pepe",
          "symbol": "FKPEPE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29894/thumb/Screenshot_7.png?1681982267"
        },
        {
          "chainId": 1,
          "address": "0x9ff58067bd8d239000010c154c6983a325df138e",
          "name": "Propchain",
          "symbol": "PROPC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30451/thumb/Untitled_%28Instagram_Post_%28Square%29%29-2.png?1684489570"
        },
        {
          "chainId": 1,
          "address": "0xab306326bc72c2335bd08f42cbec383691ef8446",
          "name": "PPizza",
          "symbol": "PPIZZA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30063/thumb/iconArtboard_1_10x.png?1683002649"
        },
        {
          "chainId": 1,
          "address": "0x17f8e6694080c07d7414c918dd9b9c32a2981ea1",
          "name": "Ascend",
          "symbol": "ASC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31110/thumb/acdw2000.png?1690517492"
        },
        {
          "chainId": 1,
          "address": "0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24",
          "name": "StarLink",
          "symbol": "STARL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16824/thumb/ZxJbRWJ.png?1625177900"
        },
        {
          "chainId": 1,
          "address": "0xd87d72248093597df8d56d2a53c1ab7c1a0cc8da",
          "name": "HAHA",
          "symbol": "HAHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30070/thumb/2.png?1683009976"
        },
        {
          "chainId": 1,
          "address": "0x579cea1889991f68acc35ff5c3dd0621ff29b0c9",
          "name": "IQ",
          "symbol": "IQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5010/thumb/YAIS3fUh.png?1626267646"
        },
        {
          "chainId": 1,
          "address": "0x83249c6794bca5a77eb8c0af9f1a86e055459cea",
          "name": "GigaSwap",
          "symbol": "GIGA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26919/thumb/final_62fcaacdbcc9640975d3fb7c_822256.png?1660815521"
        },
        {
          "chainId": 1,
          "address": "0x2e85ae1c47602f7927bcabc2ff99c40aa222ae15",
          "name": "Katana Inu",
          "symbol": "KATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png?1640236880"
        },
        {
          "chainId": 1,
          "address": "0x06f3c323f0238c72bf35011071f2b5b7f43a054c",
          "name": "MASQ",
          "symbol": "MASQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13699/thumb/masq.png?1648364813"
        },
        {
          "chainId": 1,
          "address": "0x24da31e7bb182cb2cabfef1d88db19c2ae1f5572",
          "name": "Shikoku",
          "symbol": "SHIK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28560/thumb/shikoku_inu02.png?1671706822"
        },
        {
          "chainId": 1,
          "address": "0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24",
          "name": "GraphLinq Protocol",
          "symbol": "GLQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14474/thumb/graphlinq_logo.jpg?1616397109"
        },
        {
          "chainId": 1,
          "address": "0xb8c55c80a1cb7394088a36c6b634dc2bf3c6fb67",
          "name": "Pepe Doge",
          "symbol": "PEPEDOGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29968/thumb/Pepedoge.jpeg?1682390513"
        },
        {
          "chainId": 1,
          "address": "0x8a3d77e9d6968b780564936d15b09805827c21fa",
          "name": "Archethic",
          "symbol": "UCO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png?1665916980"
        },
        {
          "chainId": 1,
          "address": "0x4fe83213d56308330ec302a8bd641f1d0113a4cc",
          "name": "NuCypher",
          "symbol": "NU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg?1547037916"
        },
        {
          "chainId": 1,
          "address": "0x9d7b68970d2be6dc93124477b4e2e1c9a6b180aa",
          "name": "Dede",
          "symbol": "DEDE",
          "decimals": 10,
          "logoURI": "https://assets.coingecko.com/coins/images/30976/thumb/dede_png_%281%29.png?1689576324"
        },
        {
          "chainId": 1,
          "address": "0xad1a5b8538a866ecd56ddd328b50ed57ced5d936",
          "name": "Good Gensler",
          "symbol": "GENSLR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29943/thumb/goodgg-logo-.png?1683092549"
        },
        {
          "chainId": 1,
          "address": "0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006",
          "name": "pSTAKE Finance",
          "symbol": "PSTAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930"
        },
        {
          "chainId": 1,
          "address": "0x0c572544a4ee47904d54aaa6a970af96b6f00e1b",
          "name": "Wasder",
          "symbol": "WAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15374/thumb/wasderBoxedLogoWhite-200x200.png?1659095342"
        },
        {
          "chainId": 1,
          "address": "0xd971a3f840359ac4a23a9e75038934855e5ed8d5",
          "name": "Okiku Kento",
          "symbol": "OKEN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31078/thumb/OKEN_PFP_200x200.jpg?1690347048"
        },
        {
          "chainId": 1,
          "address": "0xc98d64da73a6616c42117b582e832812e7b8d57f",
          "name": "RSS3",
          "symbol": "RSS3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23575/thumb/RSS3.png?1644494645"
        },
        {
          "chainId": 1,
          "address": "0x6e39a587691b8c9d4341ce0a960998ed6f537af6",
          "name": "Meta Masters Guild",
          "symbol": "MEMAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29329/thumb/Meta_Masters_Guild_Logo_%283%29.png?1678171368"
        },
        {
          "chainId": 1,
          "address": "0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
          "name": "Serum",
          "symbol": "SRM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/11970/thumb/serum-logo.png?1597121577"
        },
        {
          "chainId": 1,
          "address": "0xec70ff4a5b09110e4d20ada4f2db4a86ec61fac6",
          "name": "Grape",
          "symbol": "GRP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31106/thumb/grape_logo-removebg-preview_%281%29.png?1690515013"
        },
        {
          "chainId": 1,
          "address": "0x467bccd9d29f223bce8043b84e8c8b282827790f",
          "name": "Telcoin",
          "symbol": "TEL",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/1899/thumb/tel.png?1547036203"
        },
        {
          "chainId": 1,
          "address": "0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d",
          "name": "Fuse",
          "symbol": "FUSE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10347/thumb/fuse.png?1685331339"
        },
        {
          "chainId": 1,
          "address": "0x4c19596f5aaff459fa38b0f7ed92f11ae6543784",
          "name": "TrueFi",
          "symbol": "TRU",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941"
        },
        {
          "chainId": 1,
          "address": "0x4f9254c83eb525f9fcf346490bbb3ed28a81c667",
          "name": "Celer Network",
          "symbol": "CELR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437"
        },
        {
          "chainId": 1,
          "address": "0x235c8ee913d93c68d2902a8e0b5a643755705726",
          "name": "tehBag",
          "symbol": "BAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28950/thumb/tehBag-Logo.png?1675577312"
        },
        {
          "chainId": 1,
          "address": "0x1776e1f26f98b1a5df9cd347953a26dd3cb46671",
          "name": "Numeraire",
          "symbol": "NMR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/752/thumb/numeraire.png?1592538976"
        },
        {
          "chainId": 1,
          "address": "0xbc396689893d065f41bc2c6ecbee5e0085233447",
          "name": "Perpetual Protocol",
          "symbol": "PERP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771"
        },
        {
          "chainId": 1,
          "address": "0x8f693ca8d21b157107184d29d398a8d082b38b76",
          "name": "Streamr",
          "symbol": "DATA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17869/thumb/DATA_new_symbol_3x.png?1629692324"
        },
        {
          "chainId": 1,
          "address": "0x12aef5c60c2c86c8ecd3079f22f285f326371340",
          "name": "hiSAND33",
          "symbol": "HISAND33",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27006/thumb/logo.ab86781.png?1661337838"
        },
        {
          "chainId": 1,
          "address": "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
          "name": "Tribe",
          "symbol": "TRIBE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954"
        },
        {
          "chainId": 1,
          "address": "0x2559813bbb508c4c79e9ccce4703bcb1f149edd7",
          "name": "Hourglass",
          "symbol": "WAIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/27516/thumb/Hourglass-Logo.png?1664353478"
        },
        {
          "chainId": 1,
          "address": "0xb1f1f47061a7be15c69f378cb3f69423bd58f2f8",
          "name": "Flashstake",
          "symbol": "FLASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13533/thumb/FLASH.png?1615182275"
        },
        {
          "chainId": 1,
          "address": "0x7b123f53421b1bf8533339bfbdc7c98aa94163db",
          "name": "dfohub",
          "symbol": "BUIDL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11131/thumb/buidllogo.png?1599577041"
        },
        {
          "chainId": 1,
          "address": "0xf5f38b0ad4163a1981c7b960bcd8c242e0038e72",
          "name": "Burn Kenny",
          "symbol": "KENNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31077/thumb/IMG_20230721_222812_566.png?1690346324"
        },
        {
          "chainId": 1,
          "address": "0x0a638f07acc6969abf392bb009f216d22adea36d",
          "name": "Brickken",
          "symbol": "BKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18474/thumb/brickken-coin.png?1632127019"
        },
        {
          "chainId": 1,
          "address": "0x8de2b2104b6ac8f9dce7755ff70f0fa452680d9a",
          "name": "First Sirius",
          "symbol": "SIRIUS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31053/thumb/imresizer-1689812083418.jpg?1689925655"
        },
        {
          "chainId": 1,
          "address": "0x12970e6868f88f6557b76120662c1b3e50a646bf",
          "name": "Milady Meme Coin",
          "symbol": "LADYS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30194/thumb/logo.png?1683607297"
        },
        {
          "chainId": 1,
          "address": "0x993864e43caa7f7f12953ad6feb1d1ca635b875f",
          "name": "SingularityDAO",
          "symbol": "SDAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15385/thumb/200x200_logo.png?1665743635"
        },
        {
          "chainId": 1,
          "address": "0x562e362876c8aee4744fc2c6aac8394c312d215d",
          "name": "Optimus AI",
          "symbol": "OPTI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29328/thumb/Optimus-AI.png?1689566667"
        },
        {
          "chainId": 1,
          "address": "0xdddddd4301a082e62e84e43f474f044423921918",
          "name": "Rhino fi",
          "symbol": "DVF",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16414/thumb/rhinofii.jpg?1657812510"
        },
        {
          "chainId": 1,
          "address": "0xddb3422497e61e13543bea06989c0789117555c5",
          "name": "COTI",
          "symbol": "COTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2962/thumb/Coti.png?1559653863"
        },
        {
          "chainId": 1,
          "address": "0x6226e00bcac68b0fe55583b90a1d727c14fab77f",
          "name": "MultiVAC",
          "symbol": "MTV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585"
        },
        {
          "chainId": 1,
          "address": "0x18aaa7115705e8be94bffebde57af9bfc265b998",
          "name": "Audius",
          "symbol": "AUDIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727"
        },
        {
          "chainId": 1,
          "address": "0x410e731c2970dce3add351064acf5ce9e33fdbf0",
          "name": "ONBUFF",
          "symbol": "ONIT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12252/thumb/d4suDHSzEZjDXc0uxFGPZXfDFp0L1tfoEusBx03zB1bvMLLbxa7Yq5naXAOutkirIlLXacFRRebX_hF2Ez69_fIPUfr2LuN0Z_UrXTLYds4aT95C6eHGXsZnezMHKAaOmjLpKto0xPTiYkXExvEvXrqL7O_J1wecb4eJsgwCoVprMLpm89BdKO9IyMmpNsfbW7JJFMiHgL8vq9fPhaeEi.jpg?1598519096"
        },
        {
          "chainId": 1,
          "address": "0x595832f8fc6bf59c85c527fec3740a1b7a361269",
          "name": "Power Ledger",
          "symbol": "POWR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082"
        },
        {
          "chainId": 1,
          "address": "0x5faa989af96af85384b8a938c2ede4a7378d9875",
          "name": "Galxe",
          "symbol": "GAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24530/thumb/galaxy.jpg?1662517258"
        },
        {
          "chainId": 1,
          "address": "0xd433138d12beb9929ff6fd583dc83663eea6aaa5",
          "name": "Bitrue Coin",
          "symbol": "BTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8873/thumb/Bittrue_logo.png?1637142758"
        },
        {
          "chainId": 1,
          "address": "0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18",
          "name": "Onyxcoin",
          "symbol": "XCN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24210/thumb/onyxlogo.jpg?1675049876"
        },
        {
          "chainId": 1,
          "address": "0x75231f58b43240c9718dd58b4967c5114342a86c",
          "name": "OKB",
          "symbol": "OKB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4463/thumb/WeChat_Image_20220118095654.png?1642471050"
        },
        {
          "chainId": 1,
          "address": "0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d",
          "name": "Cartesi",
          "symbol": "CTSI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11038/thumb/Cartesi_Logo.png?1689603517"
        },
        {
          "chainId": 1,
          "address": "0xcb8a95e76a16b58c30b01e39dd6aad5949e5e802",
          "name": "Tetris",
          "symbol": "TETRIS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31100/thumb/tetris.jpeg?1690481214"
        },
        {
          "chainId": 1,
          "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
          "name": "Loopring",
          "symbol": "LRC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/913/thumb/LRC.png?1572852344"
        },
        {
          "chainId": 1,
          "address": "0x4cc19356f2d37338b9802aa8e8fc58b0373296e7",
          "name": "SelfKey",
          "symbol": "KEY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2034/thumb/selfkey.png?1548608934"
        },
        {
          "chainId": 1,
          "address": "0x201332bd45c8628d814f870bfb584b385a7c351e",
          "name": "Astra Protocol",
          "symbol": "ASTRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24083/thumb/Astra_%281%29_%281%29.png?1673947531"
        },
        {
          "chainId": 1,
          "address": "0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
          "name": "The Virtua Kolect",
          "symbol": "TVK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png?1656043619"
        },
        {
          "chainId": 1,
          "address": "0x9b355fee784768a7b9ac388c19e818e1c0a19b76",
          "name": "Multi Wallet Suite",
          "symbol": "MWS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31074/thumb/Mws.png?1690340108"
        },
        {
          "chainId": 1,
          "address": "0x8947da500eb47f82df21143d0c01a29862a8c3c5",
          "name": "Thales",
          "symbol": "THALES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18388/thumb/CLVZJN_C_400x400.png?1631758808"
        },
        {
          "chainId": 1,
          "address": "0x57b946008913b82e4df85f501cbaed910e58d26c",
          "name": "Marlin",
          "symbol": "POND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png?1622515451"
        },
        {
          "chainId": 1,
          "address": "0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70",
          "name": "Premia",
          "symbol": "PREMIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13962/thumb/apple-touch-icon.png?1623679482"
        },
        {
          "chainId": 1,
          "address": "0xf5581dfefd8fb0e4aec526be659cfab1f8c781da",
          "name": "HOPR",
          "symbol": "HOPR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468"
        },
        {
          "chainId": 1,
          "address": "0xa1d0e215a23d7030842fc67ce582a6afa3ccab83",
          "name": "DFI money",
          "symbol": "YFII",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348"
        },
        {
          "chainId": 1,
          "address": "0xd291e7a03283640fdc51b121ac401383a46cc623",
          "name": "Rari Governance",
          "symbol": "RGT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014"
        },
        {
          "chainId": 1,
          "address": "0xd46ba6d942050d489dbd938a2c909a5d5039a161",
          "name": "Ampleforth",
          "symbol": "AMPL",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png?1561684250"
        },
        {
          "chainId": 1,
          "address": "0x584bc13c7d411c00c01a62e8019472de68768430",
          "name": "Hegic",
          "symbol": "HEGIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12454/thumb/new.png?1628668523"
        },
        {
          "chainId": 1,
          "address": "0xf17e65822b568b3903685a7c9f496cf7656cc6c2",
          "name": "Biconomy",
          "symbol": "BICO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg?1638269749"
        },
        {
          "chainId": 1,
          "address": "0xec5483804e637d45cde22fa0869656b64b5ab1ab",
          "name": "Acent",
          "symbol": "ACE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15211/thumb/acent-logo.png?1620108963"
        },
        {
          "chainId": 1,
          "address": "0xfb66321d7c674995dfcc2cb67a30bc978dc862ad",
          "name": "Pepe 2 0",
          "symbol": "PEPE20",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30853/thumb/p2.png?1688354274"
        },
        {
          "chainId": 1,
          "address": "0x5eca7b975e34567d9460fa613013a7a6993ad185",
          "name": "Blacksmith Token",
          "symbol": "BS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30806/thumb/roGLhC57_400x400.jpg?1687414409"
        },
        {
          "chainId": 1,
          "address": "0xf3b9569f82b18aef890de263b84189bd33ebe452",
          "name": "A Hunters Dream",
          "symbol": "CAW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25130/thumb/x-Logo-color-10x.png?1665831216"
        },
        {
          "chainId": 1,
          "address": "0x990f341946a3fdb507ae7e52d17851b87168017c",
          "name": "Strong",
          "symbol": "STRONG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12092/thumb/STRONG-Token-256x256.png?1597823573"
        },
        {
          "chainId": 1,
          "address": "0xe66747a101bff2dba3697199dcce5b743b454759",
          "name": "Gate",
          "symbol": "GT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8183/thumb/gate.png?1687143308"
        },
        {
          "chainId": 1,
          "address": "0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e",
          "name": "Unibright",
          "symbol": "UBT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2707/thumb/UnibrightLogo_colorful_500x500_preview.png?1547036916"
        },
        {
          "chainId": 1,
          "address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
          "name": "Sushi",
          "symbol": "SUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688"
        },
        {
          "chainId": 1,
          "address": "0xf1182229b71e79e504b1d2bf076c15a277311e05",
          "name": "Lybra Finance",
          "symbol": "LBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29958/thumb/Lybra_Finance.png?1682485104"
        },
        {
          "chainId": 1,
          "address": "0x0b9570a0194ffb8bf9e60a95e8d905bf2358abcc",
          "name": "Rat Roulette",
          "symbol": "RAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31065/thumb/Screen_Shot_2023-07-21_at_9.10.56_am.png?1690178566"
        },
        {
          "chainId": 1,
          "address": "0xb0ed33f79d89541dfdcb04a8f04bc2c6be025ecc",
          "name": "ZeroLiquid",
          "symbol": "ZERO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29499/thumb/IMG_20230726_094105_915.png?1690428095"
        },
        {
          "chainId": 1,
          "address": "0xb90b2a35c65dbc466b04240097ca756ad2005295",
          "name": "BOBO Coin",
          "symbol": "BOBO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30388/thumb/bobologo.png?1684307880"
        },
        {
          "chainId": 1,
          "address": "0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55",
          "name": "SuperVerse",
          "symbol": "SUPER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14040/thumb/SuperVerse_Logo_200x200.png?1669963064"
        },
        {
          "chainId": 1,
          "address": "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
          "name": "Liquity",
          "symbol": "LQTY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180"
        },
        {
          "chainId": 1,
          "address": "0x03ab458634910aad20ef5f1c8ee96f1d6ac54919",
          "name": "Rai Reflex Index",
          "symbol": "RAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334"
        },
        {
          "chainId": 1,
          "address": "0x6468e79a80c0eab0f9a2b574c8d5bc374af59414",
          "name": "e Radix",
          "symbol": "EXRD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13145/thumb/exrd_logo.png?1605662677"
        },
        {
          "chainId": 1,
          "address": "0x63f7b1b538a78cb699e5399621b3d2e047c40de4",
          "name": "Maxity",
          "symbol": "MAX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26933/thumb/MAX_token_200.png?1666345433"
        },
        {
          "chainId": 1,
          "address": "0xafe53eea0cfe20198328890b69107d5fd8159a77",
          "name": "XAI",
          "symbol": "X",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/31117/thumb/LOGO.jpg?1690529187"
        },
        {
          "chainId": 1,
          "address": "0x5dcd6272c3cbb250823f0b7e6c618bce11b21f90",
          "name": "Pear Swap",
          "symbol": "PEAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30409/thumb/9zYlZj3H_400x400.jpg?1684386230"
        },
        {
          "chainId": 1,
          "address": "0xfcdb9e987f9159dab2f507007d5e3d10c510aa70",
          "name": "0x1 tools  AI Multi tool",
          "symbol": "0X1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31026/thumb/a392bc3a-9e36-4e6a-bdfe-254b7bb36918.jpeg?1689838314"
        },
        {
          "chainId": 1,
          "address": "0xe387140d52ebb0dd729683dc062aefbc5f7f549a",
          "name": "NitroBot",
          "symbol": "NBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31035/thumb/LdysiUUX_400x400.jpg?1689843992"
        },
        {
          "chainId": 1,
          "address": "0x13b2f6928d7204328b0e8e4bcd0379aa06ea21fa",
          "name": "Aave AMM WBTC",
          "symbol": "AAMMWBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/17215/thumb/aAMMWBTC_2x.png?1626879028"
        },
        {
          "chainId": 1,
          "address": "0x9a96ec9b57fb64fbc60b423d1f4da7691bd35079",
          "name": "Ajna Protocol",
          "symbol": "AJNA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30187/thumb/AJNA-Icon-200.png?1683601845"
        },
        {
          "chainId": 1,
          "address": "0x668dbf100635f593a3847c0bdaf21f0a09380188",
          "name": "BNSD Finance",
          "symbol": "BNSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388"
        },
        {
          "chainId": 1,
          "address": "0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7",
          "name": "Convex CRV",
          "symbol": "CVXCRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15586/thumb/convex-crv.png?1621255952"
        },
        {
          "chainId": 1,
          "address": "0x9754d5e5ea788c62f11d34a818aeae927aeac84c",
          "name": "YoloNolo",
          "symbol": "NOLO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31067/thumb/Nolo_200x200_logo.png?1690179373"
        },
        {
          "chainId": 1,
          "address": "0x1a7e4e63778b4f12a199c062f3efdd288afcbce8",
          "name": "agEUR",
          "symbol": "AGEUR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19479/thumb/agEUR.png?1635283566"
        },
        {
          "chainId": 1,
          "address": "0x5165d24277cd063f5ac44efd447b27025e888f37",
          "name": "Aave YFI",
          "symbol": "AYFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17257/thumb/aYFI_2x.png?1626957974"
        },
        {
          "chainId": 1,
          "address": "0x4c3bae16c79c30eeb1004fb03c878d89695e3a99",
          "name": "Autumn",
          "symbol": "AUTUMN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25366/thumb/autumn.png?1651317533"
        },
        {
          "chainId": 1,
          "address": "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
          "name": "Kyber Network Crystal Legacy",
          "symbol": "KNCL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/947/thumb/logo-kncl.png?1618984814"
        },
        {
          "chainId": 1,
          "address": "0x17a79792fe6fe5c95dfe95fe3fcee3caf4fe4cb7",
          "name": "Aave AMM USDT",
          "symbol": "AAMMUSDT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17200/thumb/aAMMUSDT_2x.png?1626873261"
        },
        {
          "chainId": 1,
          "address": "0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04",
          "name": "Coinweb",
          "symbol": "CWEB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21607/thumb/Logo_200x200.png?1641566717"
        },
        {
          "chainId": 1,
          "address": "0x3dd98c8a089dbcff7e8fc8d4f532bd493501ab7f",
          "name": "Town Star",
          "symbol": "TOWN",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19581/thumb/town.png?1635840875"
        },
        {
          "chainId": 1,
          "address": "0xf256cc7847e919fac9b808cc216cac87ccf2f47a",
          "name": "Aave XSUSHI",
          "symbol": "AXSUSHI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17256/thumb/aXSUSHI_2x.png?1626957111"
        },
        {
          "chainId": 1,
          "address": "0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a",
          "name": "Aave REN",
          "symbol": "AREN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14249/thumb/aREN.382be386.png?1615528633"
        },
        {
          "chainId": 1,
          "address": "0xac6df26a590f08dcc95d5a4705ae8abbc88509ef",
          "name": "Aave ENJ",
          "symbol": "AENJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14251/thumb/aENJ.ed76293b.png?1615528574"
        },
        {
          "chainId": 1,
          "address": "0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1",
          "name": "Aave UNI",
          "symbol": "AUNI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17247/thumb/aUNI_2x.png?1626942594"
        },
        {
          "chainId": 1,
          "address": "0xf9fb4ad91812b704ba883b11d2b576e890a6730a",
          "name": "Aave AMM WETH",
          "symbol": "AAMMWETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17259/thumb/aAMMWETH_2x.png?1626958225"
        },
        {
          "chainId": 1,
          "address": "0xc5fdf3569af74f3b3e97e46a187a626352d2d508",
          "name": "Zombie Inu",
          "symbol": "ZINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28562/thumb/zinu_new.png?1671770347"
        },
        {
          "chainId": 1,
          "address": "0xc713e5e149d5d0715dcd1c156a020976e7e56b88",
          "name": "Aave MKR",
          "symbol": "AMKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14248/thumb/aMKR.ac965178.png?1615528346"
        },
        {
          "chainId": 1,
          "address": "0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00",
          "name": "Aave BAT v1",
          "symbol": "ABAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11726/thumb/aBAT.png?1593086569"
        },
        {
          "chainId": 1,
          "address": "0x5f98805a4e8be255a32880fdec7f6728c6568ba0",
          "name": "Liquity USD",
          "symbol": "LUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14666/thumb/Group_3.png?1617631327"
        },
        {
          "chainId": 1,
          "address": "0xa685a61171bb30d4072b338c80cb7b2c865c873e",
          "name": "Aave MANA",
          "symbol": "AMANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14267/thumb/aMANA.f89a1098.png?1615528680"
        },
        {
          "chainId": 1,
          "address": "0x101cc05f4a51c0319f570d5e146a8c625198e636",
          "name": "Aave TUSD",
          "symbol": "ATUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14317/thumb/aTUSD.08592b49.png?1615528207"
        },
        {
          "chainId": 1,
          "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
          "name": "Dai",
          "symbol": "DAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9956/thumb/Badge_Dai.png?1687143508"
        },
        {
          "chainId": 1,
          "address": "0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b",
          "name": "Goldfinch",
          "symbol": "GFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19081/thumb/GOLDFINCH.png?1634369662"
        },
        {
          "chainId": 1,
          "address": "0x35f6b052c598d933d69a4eec4d04c73a191fe6c2",
          "name": "Aave SNX",
          "symbol": "ASNX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14265/thumb/aSNX.3238f3e7.png?1615528264"
        },
        {
          "chainId": 1,
          "address": "0x7deb5e830be29f91e298ba5ff1356bb7f8146998",
          "name": "Aave MKR v1",
          "symbol": "AMKR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11731/thumb/aMKR.png?1593084715"
        },
        {
          "chainId": 1,
          "address": "0xc383a3833a87009fd9597f8184979af5edfad019",
          "name": "iETH v1",
          "symbol": "IETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25822/thumb/iETH_100x100.png?1654055637"
        },
        {
          "chainId": 1,
          "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
          "name": "WETH",
          "symbol": "WETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        },
        {
          "chainId": 1,
          "address": "0x06450dee7fd2fb8e39061434babcfc05599a6fb8",
          "name": "XEN Crypto",
          "symbol": "XEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27713/thumb/Xen.jpeg?1665453190"
        },
        {
          "chainId": 1,
          "address": "0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa",
          "name": "Polkastarter",
          "symbol": "POLS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702"
        },
        {
          "chainId": 1,
          "address": "0x4a615bb7166210cce20e6642a6f8fb5d4d044496",
          "name": "NAOS Finance",
          "symbol": "NAOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15144/thumb/bafybeibkztkshitabrf7yqqkqtbjqestjknpgv7lsjfzdsa3ufspagqs2e.ipfs.infura-ipfs.io.png?1622176770"
        },
        {
          "chainId": 1,
          "address": "0x27c70cd1946795b66be9d954418546998b546634",
          "name": "Doge Killer",
          "symbol": "LEASH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15802/thumb/Leash.png?1621931543"
        },
        {
          "chainId": 1,
          "address": "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
          "name": "SXP",
          "symbol": "SXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311"
        },
        {
          "chainId": 1,
          "address": "0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4",
          "name": "Morpheus Network",
          "symbol": "MNW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2379/thumb/MRPH_CoinGecko.png?1635847791"
        },
        {
          "chainId": 1,
          "address": "0x2a3bff78b79a009976eea096a51a948a3dc00e34",
          "name": "Wilder World",
          "symbol": "WILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15407/thumb/WWLogo_Gradient_Cirlce.png?1620743969"
        },
        {
          "chainId": 1,
          "address": "0xc0c293ce456ff0ed870add98a0828dd4d2903dbf",
          "name": "Aura Finance",
          "symbol": "AURA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25942/thumb/logo.png?1654784187"
        },
        {
          "chainId": 1,
          "address": "0xa2b4c0af19cc16a6cfacce81f192b024d625817d",
          "name": "Kishu Inu",
          "symbol": "KISHU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14890/thumb/uVLzCoP.png?1622445866"
        },
        {
          "chainId": 1,
          "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
          "name": "Decentraland",
          "symbol": "MANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745"
        },
        {
          "chainId": 1,
          "address": "0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b",
          "name": "UFO Gaming",
          "symbol": "UFO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16801/thumb/ufo.png?1644048038"
        },
        {
          "chainId": 1,
          "address": "0xdb298285fe4c5410b05390ca80e8fbe9de1f259b",
          "name": "handle fi",
          "symbol": "FOREX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18533/thumb/handle.fiFOREXLogoDark200x200.png?1632755675"
        },
        {
          "chainId": 1,
          "address": "0x3212b29e33587a00fb1c83346f5dbfa69a458923",
          "name": "The Tokenized Bitcoin",
          "symbol": "IMBTC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/10702/thumb/imbtc.png?1585124381"
        },
        {
          "chainId": 1,
          "address": "0x330528172778cc5196d5f6742886c72505e0613d",
          "name": "XBot",
          "symbol": "XBOT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31050/thumb/xbot.jpeg?1689915853"
        },
        {
          "chainId": 1,
          "address": "0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2",
          "name": "Ternoa",
          "symbol": "CAPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15921/thumb/e55393fa-7b4d-40f5-9f36-9a8a6bdcb570.png?1622430581"
        },
        {
          "chainId": 1,
          "address": "0x6f80310ca7f2c654691d1383149fa1a57d8ab1f8",
          "name": "Silo Finance",
          "symbol": "SILO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21454/thumb/y0iYKZOv_400x400.png?1639269432"
        },
        {
          "chainId": 1,
          "address": "0xe6f1966d04cfcb9cd1b1dc4e8256d8b501b11cba",
          "name": "SafeEarth",
          "symbol": "SAFEEARTH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15449/thumb/SafeEarthLogo2000.png?1620833647"
        },
        {
          "chainId": 1,
          "address": "0x72377f31e30a405282b522d588aebbea202b4f23",
          "name": "Varen",
          "symbol": "VRN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17633/thumb/vrn.png?1636075314"
        },
        {
          "chainId": 1,
          "address": "0x4463e6a3ded0dbe3f6e15bc8420dfc55e5fea830",
          "name": "TXA",
          "symbol": "TXA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17746/thumb/jwjcN5Kx_400x400.jpg?1646381259"
        },
        {
          "chainId": 1,
          "address": "0xd5930c307d7395ff807f2921f12c5eb82131a789",
          "name": "Bolt",
          "symbol": "BOLT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/4489/thumb/bolt.png?1555648257"
        },
        {
          "chainId": 1,
          "address": "0xe0c8b298db4cffe05d1bea0bb1ba414522b33c1b",
          "name": "nuco cloud",
          "symbol": "NCDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8932/thumb/nucloud_logo.png?1650524741"
        },
        {
          "chainId": 1,
          "address": "0x3a856d4effa670c54585a5d523e96513e148e95d",
          "name": "TriasLab",
          "symbol": "TRIAS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958"
        },
        {
          "chainId": 1,
          "address": "0x7d29a64504629172a429e64183d6673b9dacbfce",
          "name": "Vectorspace AI",
          "symbol": "VXV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2063/thumb/vectorspace-ai-logo.jpeg?1547036362"
        },
        {
          "chainId": 1,
          "address": "0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6",
          "name": "Dentacoin",
          "symbol": "DCN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/850/thumb/dentacoin.png?1547034647"
        },
        {
          "chainId": 1,
          "address": "0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd",
          "name": "FREEdom coin",
          "symbol": "FREE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5585/thumb/free.png?1639547764"
        },
        {
          "chainId": 1,
          "address": "0xc770eefad204b5180df6a14ee197d99d808ee52d",
          "name": "ShapeShift FOX",
          "symbol": "FOX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622"
        },
        {
          "chainId": 1,
          "address": "0x6243d8cea23066d098a15582d81a598b4e8391f4",
          "name": "Reflexer Ungovernance",
          "symbol": "FLX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14123/thumb/EAfYdwgd_400x400.jpg?1614564508"
        },
        {
          "chainId": 1,
          "address": "0x4385328cc4d643ca98dfea734360c0f596c83449",
          "name": "tomiNet",
          "symbol": "TOMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28730/thumb/logo_for_token.png?1673690005"
        },
        {
          "chainId": 1,
          "address": "0xcda4e840411c00a614ad9205caec807c7458a0e3",
          "name": "PureFi",
          "symbol": "UFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG?1627362147"
        },
        {
          "chainId": 1,
          "address": "0xe55d97a97ae6a17706ee281486e98a84095d8aaf",
          "name": "AIPad",
          "symbol": "AIPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28894/thumb/JZadeHu.jpeg?1675214438"
        },
        {
          "chainId": 1,
          "address": "0x9663677b81c2d427e81c01ef7315ea96546f5bb1",
          "name": "TENET",
          "symbol": "TENET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30108/thumb/tenet_original.png?1684922942"
        },
        {
          "chainId": 1,
          "address": "0x0b38210ea11411557c13457d4da7dc6ea731b88a",
          "name": "API3",
          "symbol": "API3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424"
        },
        {
          "chainId": 1,
          "address": "0x04fa0d235c4abf4bcf4787af4cf447de572ef828",
          "name": "UMA",
          "symbol": "UMA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/10951/thumb/UMA.png?1586307916"
        },
        {
          "chainId": 1,
          "address": "0xd7efb00d12c2c13131fd319336fdf952525da2af",
          "name": "Proton",
          "symbol": "XPR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/10941/thumb/Proton-Icon.png?1588283737"
        },
        {
          "chainId": 1,
          "address": "0xdf4ef6ee483953fe3b84abd08c6a060445c01170",
          "name": "Wrapped Accumulate",
          "symbol": "WACME",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/27207/thumb/accumulate-logo-200x200.png?1662607409"
        },
        {
          "chainId": 1,
          "address": "0x69d47846bb7b884062fabc08a4a7deadcd98c45b",
          "name": "AlphaRushAI",
          "symbol": "RUSHAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29536/thumb/logo200x200png.png?1679479296"
        },
        {
          "chainId": 1,
          "address": "0xd04e772bc0d591fbd288f2e2a86afa3d3cb647f8",
          "name": "LayerAI",
          "symbol": "LAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29223/thumb/Favicon_200x200px.png?1677480836"
        },
        {
          "chainId": 1,
          "address": "0xb69753c06bb5c366be51e73bfc0cc2e3dc07e371",
          "name": "POOH",
          "symbol": "POOH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30091/thumb/Pooh-icon.png?1683020168"
        },
        {
          "chainId": 1,
          "address": "0x013062189dc3dcc99e9cee714c513033b8d99e3c",
          "name": "Bware",
          "symbol": "INFRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30546/thumb/infra-token-square.png?1684989653"
        },
        {
          "chainId": 1,
          "address": "0x88aa4a6c5050b9a1b2aa7e34d0582025ca6ab745",
          "name": "Vela Exchange",
          "symbol": "DXP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25114/thumb/1_0w4P8R5heRFwRomJ4vPCWQ.png?1650348942"
        },
        {
          "chainId": 1,
          "address": "0xa735a3af76cc30791c61c10d585833829d36cbe0",
          "name": "Image Generation AI",
          "symbol": "IMGNAI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28666/thumb/200x200-Nai.png?1672990319"
        },
        {
          "chainId": 1,
          "address": "0x7a73839bd0e5cded853cb01aa9773f8989381065",
          "name": "UpBots",
          "symbol": "UBXN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12476/thumb/UBXT.png?1600132967"
        },
        {
          "chainId": 1,
          "address": "0x7d5121505149065b562c789a0145ed750e6e8cdd",
          "name": "Victoria VR",
          "symbol": "VR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21178/thumb/vr.png?1638496975"
        },
        {
          "chainId": 1,
          "address": "0x08c32b0726c5684024ea6e141c50ade9690bbdcc",
          "name": "Stratos",
          "symbol": "STOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16213/thumb/token_512x512.png?1623313898"
        },
        {
          "chainId": 1,
          "address": "0x6e5970dbd6fc7eb1f29c6d2edf2bc4c36124c0c1",
          "name": "Polytrade",
          "symbol": "TRADE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png?1623979654"
        },
        {
          "chainId": 1,
          "address": "0x2f109021afe75b949429fe30523ee7c0d5b27207",
          "name": "OccamFi",
          "symbol": "OCC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG?1618531140"
        },
        {
          "chainId": 1,
          "address": "0x6ff2241756549b5816a177659e766eaf14b34429",
          "name": "AQTIS",
          "symbol": "AQTIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29910/thumb/Screenshot_2023-04-14_at_17.23.46.png?1681990052"
        },
        {
          "chainId": 1,
          "address": "0x01ba67aac7f75f647d94220cc98fb30fcc5105bf",
          "name": "Lyra Finance",
          "symbol": "LYRA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21490/thumb/Add-a-heading-26.png?1639364177"
        },
        {
          "chainId": 1,
          "address": "0xc0a4df35568f116c370e6a6a6022ceb908eeddac",
          "name": "Umee",
          "symbol": "UMEE",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20635/thumb/Umee_Logo.png?1672478000"
        },
        {
          "chainId": 1,
          "address": "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
          "name": "Merit Circle",
          "symbol": "MC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154"
        },
        {
          "chainId": 1,
          "address": "0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697",
          "name": "Ovr",
          "symbol": "OVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911"
        },
        {
          "chainId": 1,
          "address": "0x656c00e1bcd96f256f224ad9112ff426ef053733",
          "name": "Efinity",
          "symbol": "EFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16558/thumb/efi-200px_%281%29.png?1624439132"
        },
        {
          "chainId": 1,
          "address": "0x490e3f4af13e1616ec97a8c6600c1061a8d0253e",
          "name": "Terran Coin",
          "symbol": "TRR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066"
        },
        {
          "chainId": 1,
          "address": "0x849a226f327b89e3133d9930d927f9eb9346f8c9",
          "name": "Crypto Global United",
          "symbol": "CGU",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png?1666606977"
        },
        {
          "chainId": 1,
          "address": "0xa670d7237398238de01267472c6f13e5b8010fd1",
          "name": "Sommelier",
          "symbol": "SOMM",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/23308/thumb/somm_new.png?1650884424"
        },
        {
          "chainId": 1,
          "address": "0xf68d4d917592f3a62417ace42592f15296cc33a0",
          "name": "COINHUB",
          "symbol": "CHB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/26818/thumb/a08jRqRh_400x400.jpg?1687342978"
        },
        {
          "chainId": 1,
          "address": "0xbf628dfd72cf51752574a970c91f42591b173a77",
          "name": "X Social Network",
          "symbol": "X-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29214/thumb/log.png?1677398800"
        },
        {
          "chainId": 1,
          "address": "0x59f4f336bf3d0c49dbfba4a74ebd2a6ace40539a",
          "name": "Catcoin",
          "symbol": "CAT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/25279/thumb/cat5.png?1679887181"
        },
        {
          "chainId": 1,
          "address": "0x5e7f20e72c21f6d0bf0a2814fd4164176401cf8e",
          "name": "Demeter",
          "symbol": "DEO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25582/thumb/7zyskNC.png?1652767188"
        },
        {
          "chainId": 1,
          "address": "0x772722b55cdc2a086abd064267a17855eb15e8b3",
          "name": "MintMe com Coin",
          "symbol": "MINTME",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801"
        },
        {
          "chainId": 1,
          "address": "0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034",
          "name": "Banano",
          "symbol": "BAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/6226/thumb/banano-transparent.png?1619589798"
        },
        {
          "chainId": 1,
          "address": "0x046eee2cc3188071c02bfc1745a6b17c656e3f3d",
          "name": "Rollbit Coin",
          "symbol": "RLB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24552/thumb/unziL6wO_400x400.jpg?1648134494"
        },
        {
          "chainId": 1,
          "address": "0x73fbd93bfda83b111ddc092aa3a4ca77fd30d380",
          "name": "SophiaVerse",
          "symbol": "SOPH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30050/thumb/Icon_200x200.png?1682996025"
        },
        {
          "chainId": 1,
          "address": "0xf2b2f7b47715256ce4ea43363a867fdce9353e3a",
          "name": "Bitgert",
          "symbol": "BRISE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17388/thumb/bitgert.png?1660272192"
        },
        {
          "chainId": 1,
          "address": "0x37613d64258c0fe09d5e53eecb091da5b8fa8707",
          "name": "Pi Protocol",
          "symbol": "PIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27476/thumb/LOGO_%281%29.png?1664197877"
        },
        {
          "chainId": 1,
          "address": "0xd3ac016b1b8c80eeadde4d186a9138c9324e4189",
          "name": "Okcash",
          "symbol": "OK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/274/thumb/ok-logo-200px.png?1665792994"
        },
        {
          "chainId": 1,
          "address": "0xdd13dedecebda566322195bc4451d672a148752c",
          "name": "PRIMAL",
          "symbol": "PRIMAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28435/thumb/PRIMAL_ICON_200px.jpg?1670567426"
        },
        {
          "chainId": 1,
          "address": "0x1416946162b1c2c871a73b07e932d2fb6c932069",
          "name": "Energi",
          "symbol": "NRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/5795/thumb/3218.png?1604798557"
        },
        {
          "chainId": 1,
          "address": "0xb990d93c308a31c737aa91839e8ba8eaf4017d7a",
          "name": "PirateCash",
          "symbol": "PIRATE",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png?1637683371"
        },
        {
          "chainId": 1,
          "address": "0x5f1f11a3dd7a0c39da1baa3c7b8585b52a77f435",
          "name": "ASAN VERSE",
          "symbol": "ASAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28189/thumb/200200_copy.png?1668324848"
        },
        {
          "chainId": 1,
          "address": "0x5bdc32663ec75e85ff4abc2cae7ae8b606a2cfca",
          "name": "Cookies Protocol",
          "symbol": "CP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28432/thumb/IMG_20221207_200805_897.jpg?1670556204"
        },
        {
          "chainId": 1,
          "address": "0x25ec98773d7b4ced4cafab96a2a1c0945f145e10",
          "name": "Staked USDT",
          "symbol": "STUSDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31135/thumb/2veJywVy_400x400.jpg?1690884367"
        },
        {
          "chainId": 1,
          "address": "0x706f280cdb92260fe2d50eda545f834ff1fbfd35",
          "name": "Rabbit2023",
          "symbol": "RABBIT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28476/thumb/200.png?1671003986"
        },
        {
          "chainId": 1,
          "address": "0xa31dfe74cda04de990cafc6a0ab2bb6d6c9077cd",
          "name": "Abachi",
          "symbol": "ABI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22331/thumb/abachi.PNG?1641514867"
        },
        {
          "chainId": 1,
          "address": "0xb2cabf797bc907b049e4ccb5b84d13be3a8cfc21",
          "name": "Arable Protocol",
          "symbol": "ACRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23659/thumb/acre_token-02.png?1644989289"
        },
        {
          "chainId": 1,
          "address": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
          "name": "Wrapped Fantom",
          "symbol": "WFTM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16036/thumb/Fantom.png?1622679930"
        },
        {
          "chainId": 1,
          "address": "0x56c03b8c4fa80ba37f5a7b60caaaef749bb5b220",
          "name": "CANTO",
          "symbol": "CANTO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26959/thumb/canto-network.png?1661215219"
        },
        {
          "chainId": 1,
          "address": "0x388d819724dd6d71760a38f00dc01d310d879771",
          "name": "JustMoney",
          "symbol": "JM",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25450/thumb/jm.png?1651803017"
        },
        {
          "chainId": 1,
          "address": "0x4b19c70da4c6fa4baa0660825e889d2f7eabc279",
          "name": "Gamium",
          "symbol": "GMM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22749/thumb/14304.png?1642557454"
        },
        {
          "chainId": 1,
          "address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
          "name": "Cronos",
          "symbol": "CRO",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/7310/thumb/cro_token_logo.png?1669699773"
        },
        {
          "chainId": 1,
          "address": "0x7cfea0dd176651e7b5a1ced9c4faf8ee295315fd",
          "name": "Prime Numbers Ecosystem",
          "symbol": "PRNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22669/thumb/primenumers_favicon_01.png?1685956148"
        },
        {
          "chainId": 1,
          "address": "0xf33893de6eb6ae9a67442e066ae9abd228f5290c",
          "name": "GroveCoin",
          "symbol": "GRV",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/25549/thumb/200x200.png?1652335189"
        },
        {
          "chainId": 1,
          "address": "0xf1264873436a0771e440e2b28072fafcc5eebd01",
          "name": "Kenshi",
          "symbol": "KNS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30759/thumb/kenshi.jpg?1686834886"
        },
        {
          "chainId": 1,
          "address": "0x3c917054e03485808137eb306eafa8da0ab695cd",
          "name": "Orbcity",
          "symbol": "ORB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24332/thumb/OrbCity_enlarged.jpg?1669370209"
        },
        {
          "chainId": 1,
          "address": "0xb8c77482e45f1f44de1745f52c74426c631bdd52",
          "name": "BNB",
          "symbol": "BNB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/825/thumb/bnb-icon2_2x.png?1644979850"
        },
        {
          "chainId": 1,
          "address": "0x96543ef8d2c75c26387c1a319ae69c0bee6f3fe7",
          "name": "Kujira",
          "symbol": "KUJI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20685/thumb/kuji-200x200.png?1637557201"
        },
        {
          "chainId": 1,
          "address": "0x1b3c515f58857e141a966b33182f2f3feecc10e9",
          "name": "USK",
          "symbol": "USK",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/27274/thumb/usk.png?1663073635"
        },
        {
          "chainId": 1,
          "address": "0x8c223a82e07fecb49d602150d7c2b3a4c9630310",
          "name": "NFTEarth",
          "symbol": "NFTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29116/thumb/20230223_224134.jpg?1677224110"
        },
        {
          "chainId": 1,
          "address": "0xc03b43d492d904406db2d7d57e67c7e8234ba752",
          "name": "Wrapped USDR",
          "symbol": "WUSDR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/28800/thumb/wUSDRlogo.png?1674283345"
        },
        {
          "chainId": 1,
          "address": "0x6f9c26fa731c7ea4139fa669962cf8f1ce6c8b0b",
          "name": "OATH",
          "symbol": "OATH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24075/thumb/OATH_ICON_Updated.png?1677061912"
        },
        {
          "chainId": 1,
          "address": "0xcc503242b574bc01145da7e2a743b43fb395ec91",
          "name": "ROVI Protocol",
          "symbol": "ROVI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28936/thumb/rovi-coin.png?1675503123"
        },
        {
          "chainId": 1,
          "address": "0x2512c545a0117353e4e9acddff860342640d3cf6",
          "name": "WHEE",
          "symbol": "WHEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30285/thumb/whee.jpg?1683863968"
        },
        {
          "chainId": 1,
          "address": "0x1f3f677ecc58f6a1f9e2cf410df4776a8546b5de",
          "name": "VNDC",
          "symbol": "VNDC",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png?1571032826"
        },
        {
          "chainId": 1,
          "address": "0xa64c3a85ddc4cd351eeb7aecebc6a44a64a76392",
          "name": "Rice Wallet",
          "symbol": "RICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877"
        },
        {
          "chainId": 1,
          "address": "0xf823fd9ac0abb8d779076a41daa049844299f6ed",
          "name": "Squid Game",
          "symbol": "SQUID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20506/thumb/squid.png?1638506382"
        },
        {
          "chainId": 1,
          "address": "0x837d904a3799c0769079be9ecbaddf1abd4ccd6e",
          "name": "Tarot",
          "symbol": "TAROT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17881/thumb/tarot-200px.png?1629704943"
        },
        {
          "chainId": 1,
          "address": "0xdcfa8c46ec015d4bf13d6be492cb7a8eb4580899",
          "name": "impactMarket",
          "symbol": "PACT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21907/thumb/PACT_Token_Ticker_Blue_2x.png?1640236076"
        },
        {
          "chainId": 1,
          "address": "0xcdb37a4fbc2da5b78aa4e41a432792f9533e85cc",
          "name": "CheckDot",
          "symbol": "CDT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20370/thumb/token-200x200_%281%29.png?1636949075"
        },
        {
          "chainId": 1,
          "address": "0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8",
          "name": "dForce USD",
          "symbol": "USX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920"
        },
        {
          "chainId": 1,
          "address": "0x4184aa04215e5d716dd4c213fed519acadc68f92",
          "name": "ONUS",
          "symbol": "ONUS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24599/thumb/ONUS_200.png?1669452755"
        },
        {
          "chainId": 1,
          "address": "0xd3c325848d7c6e29b574cb0789998b2ff901f17e",
          "name": "OneArt",
          "symbol": "1ART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19307/thumb/token_light_3x.png?1642936901"
        },
        {
          "chainId": 1,
          "address": "0x0ff534801e98a4976246d1f418e441783fc9aa15",
          "name": "Future AI",
          "symbol": "FUTURE-AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28841/thumb/200x200.png?1674797020"
        },
        {
          "chainId": 1,
          "address": "0xf05897cfe3ce9bbbfe0751cbe6b1b2c686848dcb",
          "name": "CateCoin",
          "symbol": "CATE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15364/thumb/logo.png?1620647627"
        },
        {
          "chainId": 1,
          "address": "0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19",
          "name": "Pleasure Coin",
          "symbol": "NSFW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15315/thumb/pleasurecoin-icon.png?1648724879"
        },
        {
          "chainId": 1,
          "address": "0x06fce0dab5b26e503e1c4db48aa165dcaaaa6e11",
          "name": "EverestCoin",
          "symbol": "EVCOIN",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/19137/thumb/evcoin.png?1634525553"
        },
        {
          "chainId": 1,
          "address": "0xc5a927ad2d1a92dc5f9662ce03f7140a8b738b19",
          "name": "AiWallet",
          "symbol": "AIWALLET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29118/thumb/logo-ai.png?1676778904"
        },
        {
          "chainId": 1,
          "address": "0x25931894a86d47441213199621f1f2994e1c39aa",
          "name": "ChainGPT",
          "symbol": "CGPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29306/thumb/200x200.png?1677992972"
        },
        {
          "chainId": 1,
          "address": "0xb92ba0a6a843379499770de82aa936d6ba0fd8ca",
          "name": "Youwho",
          "symbol": "YOU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25353/thumb/youwho_200.png?1651312259"
        },
        {
          "chainId": 1,
          "address": "0x761626b095a85131646403c158d8fe81fc7092d9",
          "name": "DollarSqueeze",
          "symbol": "DSQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30764/thumb/DS.png?1690430235"
        },
        {
          "chainId": 1,
          "address": "0x69c2fcae7e30b429166bd616a322e32bec036bcf",
          "name": "MuratiAI",
          "symbol": "MURATIAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30433/thumb/Muratilogo.png?1684466716"
        },
        {
          "chainId": 1,
          "address": "0xae788f80f2756a86aa2f410c651f2af83639b95b",
          "name": "GensoKishi Metaverse",
          "symbol": "MV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23143/thumb/geno.png?1643626421"
        },
        {
          "chainId": 1,
          "address": "0x888888888888f195e27a2e0f98d712952ab9348e",
          "name": "Shorter Finance",
          "symbol": "IPISTR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27002/thumb/box-1.png?1661329648"
        },
        {
          "chainId": 1,
          "address": "0xc7b89491bb148551547837ea6ccb4bb5144d8e47",
          "name": "Sonar",
          "symbol": "PING",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16463/thumb/sonar_logo-200.png?1651140004"
        },
        {
          "chainId": 1,
          "address": "0x9b25889c493ae6df34ceef1ecb10d77c1ba73318",
          "name": "Mean DAO",
          "symbol": "MEAN",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21557/thumb/89934951.png?1639466364"
        },
        {
          "chainId": 1,
          "address": "0xb0c7a3ba49c7a6eaba6cd4a96c55a1391070ac9a",
          "name": "Magic",
          "symbol": "MAGIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18623/thumb/magic.png?1656052146"
        },
        {
          "chainId": 1,
          "address": "0xf3a3023e6dede84ad88a656a3269f2a36e83c9a9",
          "name": "Sharbi",
          "symbol": "SHARBI",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29075/thumb/WhatsApp_Image_2023-01-25_at_12.08.22_PM-removebg-1.png?1676436684"
        },
        {
          "chainId": 1,
          "address": "0x9f826324bb9bdcf7e7eb274bedc417bd45d74f39",
          "name": "MilkAI",
          "symbol": "MILKAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/29055/thumb/MilkAI.jpeg?1676337492"
        },
        {
          "chainId": 1,
          "address": "0x83d6c8c06ac276465e4c92e7ac8c23740f435140",
          "name": "HMX",
          "symbol": "HMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/31206/thumb/HMXlogo_CG.png?1691381608"
        },
        {
          "chainId": 1,
          "address": "0xfc0b60e0df5dc9d4b72d957ca2d251cee308019a",
          "name": "Shattered Legion",
          "symbol": "SLG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26436/thumb/shattered_legion.png?1682647718"
        },
        {
          "chainId": 1,
          "address": "0x5b52bfb8062ce664d74bbcd4cd6dc7df53fd7233",
          "name": "ZENIQ",
          "symbol": "ZENIQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29284/thumb/zeniq.png?1677754455"
        },
        {
          "chainId": 1,
          "address": "0xb012be90957d70d9a070918027655f998c123a88",
          "name": "Hermes DAO",
          "symbol": "HMX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29283/thumb/Hermes_DAO.png?1677750686"
        },
        {
          "chainId": 1,
          "address": "0x4c584cd339bdde73b7f5210486dd8bbeee3fde6d",
          "name": "ShibElon",
          "symbol": "SHIBELON",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/20434/thumb/shibelon.png?1637037218"
        },
        {
          "chainId": 1,
          "address": "0xa64d5d1eb67748226d84812b45711453f1118c32",
          "name": "Cheems Inu  NEW ",
          "symbol": "CINU",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29393/thumb/cinu.png?1678506316"
        },
        {
          "chainId": 1,
          "address": "0x075067a4b236f68f123fc0553f4560311937491d",
          "name": "CATpay",
          "symbol": "CATPAY",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/24731/thumb/catpay.png?1661765563"
        },
        {
          "chainId": 1,
          "address": "0xf3e07812ebc8604fddb0aa35ff79a03f48f48948",
          "name": "JournArt",
          "symbol": "JART",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29412/thumb/JournArt%28200%29.png?1678597820"
        },
        {
          "chainId": 1,
          "address": "0xbccd27062ae1a2bea5731c904b96edfb163aba21",
          "name": "Dogcoin",
          "symbol": "DOGS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/29065/thumb/1024_%281%29.png?1676366022"
        },
        {
          "chainId": 1,
          "address": "0xf124ed9ec309907808b1fbc6bedb2a76927b3665",
          "name": "Empire Network",
          "symbol": "EMPIRE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29240/thumb/empire.jpeg?1677572473"
        },
        {
          "chainId": 1,
          "address": "0xe28027c99c7746ffb56b0113e5d9708ac86fae8f",
          "name": "KING",
          "symbol": "KING",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30183/thumb/king.png?1683600013"
        },
        {
          "chainId": 1,
          "address": "0x0d229c3ff2d76e4b88ca4f9d3d3353f4545ec53f",
          "name": "Sheikh Inu",
          "symbol": "SHINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28948/thumb/photo_2023-02-04_17-32-00_prev_ui_%282%29.png?1675572486"
        },
        {
          "chainId": 1,
          "address": "0x9f90b457dea25ef802e38d470dda7343691d8fe1",
          "name": "Crosschain IOTX",
          "symbol": "CIOTX",
          "decimals": 17,
          "logoURI": "https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG?1631579018"
        },
        {
          "chainId": 1,
          "address": "0xc4f521d163d593a759f526dabf012cc7581d808f",
          "name": "Digital Files",
          "symbol": "DIFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29282/thumb/DIFI_logo.png?1683022800"
        },
        {
          "chainId": 1,
          "address": "0x188fb5f5ae5bbe4154d5778f2bbb2fb985c94d25",
          "name": "OpenBlox",
          "symbol": "OBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26150/thumb/OBX_token-black_background_preview.png?1656042112"
        },
        {
          "chainId": 1,
          "address": "0x02c3296c6eb50249f290ae596f2be9454bffadab",
          "name": "Rejuve AI",
          "symbol": "RJV",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29366/thumb/2023_Rejuve_Logo_-_Square_-_Teal.jpg?1678345338"
        },
        {
          "chainId": 1,
          "address": "0xd23ed8ca350ce2631f7ecdc5e6bf80d0a1debb7b",
          "name": "Planq",
          "symbol": "PLQ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29100/thumb/Planq_256x256-circle.png?1676604362"
        },
        {
          "chainId": 1,
          "address": "0x263b6b028f3e4ed8c4329eb2b5f409ee38d97296",
          "name": "World Mobile Token",
          "symbol": "WMT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/17333/thumb/Colored_Token.png?1627346708"
        },
        {
          "chainId": 1,
          "address": "0xc0b314a8c08637685fc3dafc477b92028c540cfb",
          "name": "Wombat Exchange",
          "symbol": "WOM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26946/thumb/Wombat_Token.png?1660982422"
        },
        {
          "chainId": 1,
          "address": "0x5fab9761d60419c9eeebe3915a8fa1ed7e8d2e1b",
          "name": "DIMO",
          "symbol": "DIMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28383/thumb/Token_Logo.png?1670052785"
        },
        {
          "chainId": 1,
          "address": "0x672f4fa517894496b8a958b4b3fca068ce513a39",
          "name": "DexCheck",
          "symbol": "DCK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26412/thumb/DexCheck_logo_%282%29.png?1687250248"
        },
        {
          "chainId": 1,
          "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
          "name": "SPACE ID",
          "symbol": "ID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29468/thumb/sid_token_logo_%28green2%29.png?1679454702"
        },
        {
          "chainId": 1,
          "address": "0x6731827cb6879a2091ce3ab3423f7bf20539b579",
          "name": "Empower",
          "symbol": "MPWR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25456/thumb/MPWR_200x200.png?1651815610"
        },
        {
          "chainId": 1,
          "address": "0xb50721bcf8d664c30412cfbc6cf7a15145234ad1",
          "name": "Arbitrum",
          "symbol": "ARB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16547/thumb/photo_2023-03-29_21.47.00.jpeg?1680097630"
        },
        {
          "chainId": 1,
          "address": "0x77f2be773ca0887ba2b3ef8344c8cf13c98d8ca7",
          "name": "FloraChain",
          "symbol": "FYT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29078/thumb/flora.png?1676438723"
        },
        {
          "chainId": 1,
          "address": "0xa89bf95c5f15a847c8eb8d348cd7fed719ad7d80",
          "name": "Chat AI",
          "symbol": "AI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29441/thumb/200.png?1678865520"
        },
        {
          "chainId": 1,
          "address": "0x1c98b54d673c026c8286badca3e840aaf72931a3",
          "name": "Stackswap",
          "symbol": "STSW",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29727/thumb/22.png?1680967675"
        },
        {
          "chainId": 1,
          "address": "0x1151cb3d861920e07a38e03eead12c32178567f6",
          "name": "Bonk",
          "symbol": "BONK",
          "decimals": 5,
          "logoURI": "https://assets.coingecko.com/coins/images/28600/thumb/bonk.jpg?1672304290"
        },
        {
          "chainId": 1,
          "address": "0x510975eda48a97e0ca228dd04d1217292487bea6",
          "name": "PROJECT XENO",
          "symbol": "GXE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29000/thumb/gxe.png?1675845303"
        },
        {
          "chainId": 1,
          "address": "0xc9c4fd7579133701fa2769b6955e7e56bb386db1",
          "name": "Bridge Oracle",
          "symbol": "BRG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12512/thumb/brg.png?1661754280"
        },
        {
          "chainId": 1,
          "address": "0xd1420af453fd7bf940573431d416cace7ff8280c",
          "name": "Answer Governance",
          "symbol": "AGOV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14383/thumb/AgoV_Logo1.png?1622448433"
        },
        {
          "chainId": 1,
          "address": "0xb4a3b0faf0ab53df58001804dda5bfc6a3d59008",
          "name": "Sperax",
          "symbol": "SPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12232/thumb/sperax_logo.jpg?1598342904"
        },
        {
          "chainId": 1,
          "address": "0x969786c4a8884013d1c9ff18dcca2aedbbbfaa8f",
          "name": "Gamerse",
          "symbol": "LFG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19582/thumb/gamerse.PNG?1635470285"
        },
        {
          "chainId": 1,
          "address": "0x152649ea73beab28c5b49b26eb48f7ead6d4c898",
          "name": "PancakeSwap",
          "symbol": "CAKE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12632/thumb/pancakeswap-cake-logo_%281%29.png?1629359065"
        },
        {
          "chainId": 1,
          "address": "0xd38de430d355a7a8337204c2a4c80392e61475a6",
          "name": "DuckyCoinAI",
          "symbol": "DUCKYAI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29791/thumb/1681233790515-ea5417cc3842eeef09aa84987708f438.png?1681293667"
        },
        {
          "chainId": 1,
          "address": "0x8b9f7e9586633ca4abffb1f746da8daed7849cb2",
          "name": "Cremation Coin",
          "symbol": "CREMAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29793/thumb/photo_2023-02-07_04-43-14.jpg?1681346838"
        },
        {
          "chainId": 1,
          "address": "0x995c0e3b9af4da89fc76c5c0784e97c7a36eec06",
          "name": "Pige",
          "symbol": "PIGE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25885/thumb/Pige.png?1681376641"
        },
        {
          "chainId": 1,
          "address": "0xdad33e12e61dc2f2692f2c12e6303b5ade7277ba",
          "name": "Brewlabs",
          "symbol": "BREWLABS",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21928/thumb/7xgmOCBW_400x400.jpg?1640298648"
        },
        {
          "chainId": 1,
          "address": "0xf89060c99853bb52eaf5f2247d007d73de660252",
          "name": "Wrapped BESC",
          "symbol": "WBESC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29849/thumb/photo_2023-04-16_05-49-19.jpg?1681782223"
        },
        {
          "chainId": 1,
          "address": "0x87da7bbd398d6610ded42617e34069d0083ff8ab",
          "name": "Mind Connect",
          "symbol": "MIND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29749/thumb/our_logo.png?1681112823"
        },
        {
          "chainId": 1,
          "address": "0x186d0ba3dfc3386c464eecd96a61fbb1e2da00bf",
          "name": "Trava Finance",
          "symbol": "TRAVA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17553/thumb/TRAVA_OFFICIAL_LOGO.png?1628509820"
        },
        {
          "chainId": 1,
          "address": "0x333000333b26ee30214b4af6419d9ab07a450400",
          "name": "MELD",
          "symbol": "MELD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30170/thumb/Twitter.jpg?1683534747"
        },
        {
          "chainId": 1,
          "address": "0x5b8650cd999b23cf39ab12e3213fbc8709c7f5cb",
          "name": "MaziMatic",
          "symbol": "MAZI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30490/thumb/4de906d9d4104a16b4334d8ec8f75284.png?1684718591"
        },
        {
          "chainId": 1,
          "address": "0x9ed7e4b1bff939ad473da5e7a218c771d1569456",
          "name": "Reunit Wallet",
          "symbol": "REUNI",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/29492/thumb/reunit_200.png?1679280627"
        },
        {
          "chainId": 1,
          "address": "0x91a69021b0baef3445e51726458a0ce601471846",
          "name": "Ethos Reserve Note",
          "symbol": "ERN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29744/thumb/ERN200x200.png?1681106290"
        },
        {
          "chainId": 1,
          "address": "0x9776191f4ebbba7f358c1663bf82c0a0906c77fa",
          "name": "Phoenix Chain",
          "symbol": "PHX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29480/thumb/PHOENlX_%281%29_%281%29_%281%29.png?1680845163"
        },
        {
          "chainId": 1,
          "address": "0x9d1089802ee608ba84c5c98211afe5f37f96b36c",
          "name": "Fluid USDC",
          "symbol": "FUSDC",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/28471/thumb/fUSDC-200x200.png?1671002126"
        },
        {
          "chainId": 1,
          "address": "0xa75e7928d3de682e3f44da60c26f33117c4e6c5c",
          "name": "Propel",
          "symbol": "PEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21290/thumb/img_circle_1.png?1677318804"
        },
        {
          "chainId": 1,
          "address": "0x15a3081b541e8dad25c4a5e0c4c4b4e8d105b2e8",
          "name": "Pepe Original Version",
          "symbol": "POV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30121/thumb/our_logo.png?1683257729"
        },
        {
          "chainId": 1,
          "address": "0x1bd55090b8878630e57fae6ebd11fd61d16dfc9f",
          "name": "ArchLoot",
          "symbol": "ALT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/28919/thumb/7.png?1675591504"
        },
        {
          "chainId": 1,
          "address": "0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef",
          "name": "unshETH Ether",
          "symbol": "UNSHETH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30365/thumb/ush.png?1684250737"
        },
        {
          "chainId": 1,
          "address": "0xe46091dce9c67691bcf22768bbee0bc9e20d4beb",
          "name": "WSB Classic",
          "symbol": "WSBC",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/30165/thumb/photo_2023-05-05_07-44-23.jpg?1683599619"
        },
        {
          "chainId": 1,
          "address": "0xb81c3edb46f9054f2b1edb477674a19f690c9a83",
          "name": "ScapesMania",
          "symbol": "MANIA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30406/thumb/token_scapesmania_200x200px.png?1684374712"
        },
        {
          "chainId": 1,
          "address": "0xffa188493c15dfaf2c206c97d8633377847b6a52",
          "name": "Wefi Finance",
          "symbol": "WEFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30540/thumb/wefi.png?1684981490"
        },
        {
          "chainId": 1,
          "address": "0xd8fa690304d2b2824d918c0c7376e2823704557a",
          "name": "SquidGrow",
          "symbol": "SQUIDGROW",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/26573/thumb/Squidgrow-Logo.png?1673589821"
        },
        {
          "chainId": 1,
          "address": "0x395e925834996e558bdec77cd648435d620afb5b",
          "name": "ThreeFold",
          "symbol": "TFT",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/6704/thumb/black_logo_on_white_background_%282%29.jpg?1649742107"
        },
        {
          "chainId": 1,
          "address": "0x8db4beaccd1698892821a9a0dc367792c0cb9940",
          "name": "Ignore Fud",
          "symbol": "4TOKEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29626/thumb/200x200.png?1680079963"
        },
        {
          "chainId": 1,
          "address": "0x26aad156ba8efa501b32b42ffcdc8413f90e9c99",
          "name": "Open Campus",
          "symbol": "EDU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/29948/thumb/EDU_Logo.png?1682327357"
        },
        {
          "chainId": 1,
          "address": "0x6c249b6f6492864d914361308601a7abb32e68f8",
          "name": "Brillion",
          "symbol": "DUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27976/thumb/Screenshot_2023-06-13_at_11.58.02_AM.png?1686628721"
        },
        {
          "chainId": 1,
          "address": "0xaef420fd77477d9dc8b46d704d44dd09d6c27866",
          "name": "Cogito Protocol",
          "symbol": "CGV",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/30731/thumb/CGV_logo_32.png?1686648683"
        },
        {
          "chainId": 1,
          "address": "0x50327c6c5a14dcade707abad2e27eb517df87ab5",
          "name": "Wrapped Tron",
          "symbol": "WTRX",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/22471/thumb/xOesRfpN_400x400.jpg?1641884861"
        },
        {
          "chainId": 1,
          "address": "0x983d8edb44ca96c0595f3c456ebdd47855911f34",
          "name": "War Legends",
          "symbol": "WAR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30672/thumb/wZBMVBr2_400x400.jpg?1686211615"
        },
        {
          "chainId": 1,
          "address": "0x7cf551258d6871b72ee1bd1624588a6245bf48c4",
          "name": "ChitCAT",
          "symbol": "CHITCAT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/30289/thumb/ChitCAT.png?1683865504"
        },
        {
          "chainId": 1,
          "address": "0x9348e94a447bf8b2ec11f374d3f055fd47d936df",
          "name": "For Loot And Glory",
          "symbol": "FLAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20726/thumb/token_logo.ico?1637595445"
        },
        {
          "chainId": 1,
          "address": "0xc3589f56b6869824804a5ea29f2c9886af1b0fce",
          "name": "Honey",
          "symbol": "HNY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12895/thumb/hnys.png?1614100588"
        },
        {
          "chainId": 1,
          "address": "0x377d552914e7a104bc22b4f3b6268ddc69615be7",
          "name": "NEXT",
          "symbol": "NEXT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/7014/thumb/NEXT.exchange_Logo.png?1569212613"
        },
        {
          "chainId": 1,
          "address": "0xe2dc070524a6e305ddb64d8513dc444b6a1ec845",
          "name": "Nash",
          "symbol": "NEX",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/3246/thumb/Nash_M.png?1680864855"
        },
        {
          "chainId": 1,
          "address": "0x0f3adc247e91c3c50bc08721355a41037e89bc20",
          "name": "Anchor Protocol",
          "symbol": "ANC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14420/thumb/anchor_protocol_logo.jpg?1615965420"
        },
        {
          "chainId": 1,
          "address": "0x499568c250ab2a42292261d6121525d70691894b",
          "name": "KROWN",
          "symbol": "KRW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058"
        },
        {
          "chainId": 1,
          "address": "0x1350fbe8ce27762ec19134bf8fc405a427fe9bf1",
          "name": "Trips Community",
          "symbol": "TRIPS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14648/thumb/trips_32.png?1617491133"
        },
        {
          "chainId": 1,
          "address": "0xfeea0bdd3d07eb6fe305938878c0cadbfa169042",
          "name": "8Pay",
          "symbol": "8PAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14667/thumb/8pay.jpeg?1617639682"
        },
        {
          "chainId": 1,
          "address": "0x6dc02164d75651758ac74435806093e421b64605",
          "name": "XAYA",
          "symbol": "WCHI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png?1656561479"
        },
        {
          "chainId": 1,
          "address": "0x71ba91dc68c6a206db0a6a92b4b1de3f9271432d",
          "name": "MobieCoin",
          "symbol": "MBX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697"
        },
        {
          "chainId": 1,
          "address": "0xfb62ae373aca027177d1c18ee0862817f9080d08",
          "name": "My DeFi Pet",
          "symbol": "DPET",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG?1620512455"
        },
        {
          "chainId": 1,
          "address": "0x92a42db88ed0f02c71d439e55962ca7cab0168b5",
          "name": "TRDGtoken",
          "symbol": "TRDG",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14371/thumb/200x200.png?1675163127"
        },
        {
          "chainId": 1,
          "address": "0x3079f61704e9efa2bcf1db412f735d8d4cfa26f4",
          "name": "HappyFans",
          "symbol": "HAPPY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19027/thumb/O1pUoR8G_400x400.jpeg?1654248996"
        },
        {
          "chainId": 1,
          "address": "0xee1cea7665ba7aa97e982edeaecb26b59a04d035",
          "name": "ORAO Network",
          "symbol": "ORAO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15189/thumb/orao.PNG?1620078596"
        },
        {
          "chainId": 1,
          "address": "0x946112efab61c3636cbd52de2e1392d7a75a6f01",
          "name": "Hydro",
          "symbol": "HYDRO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/3678/thumb/hydro-drop.png?1671962568"
        },
        {
          "chainId": 1,
          "address": "0x7b4328c127b85369d9f82ca0503b000d09cf9180",
          "name": "Dogechain",
          "symbol": "DC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26828/thumb/dogechain.jpeg?1660292741"
        },
        {
          "chainId": 1,
          "address": "0x05fb86775fd5c16290f1e838f5caaa7342bd9a63",
          "name": "Hacken",
          "symbol": "HAI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11081/thumb/hacken-symbol-with-bg.png?1676512641"
        },
        {
          "chainId": 1,
          "address": "0xa771b49064da011df051052848477f18dba1d2ac",
          "name": "Handshake",
          "symbol": "HNS",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/10562/thumb/circle-handshakeLogo.png?1613668983"
        },
        {
          "chainId": 1,
          "address": "0xf4657ab08681214bcb1893aa8e9c7613459250ec",
          "name": "RealT   1617 S Avers Ave  Chicago  IL 6",
          "symbol": "REALT-S-1617-SAVERS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16675/thumb/1617-S-Avers-hero-1.jpg?1624628860"
        },
        {
          "chainId": 1,
          "address": "0xe7b6de709ffc3bd237c2f2c800e1002f97a760f3",
          "name": "RealT   4852 4854 W Cortez St  Chicago ",
          "symbol": "REALT-S-4852-4854-W",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16670/thumb/Cortez-facade-1.jpg?1624627398"
        },
        {
          "chainId": 1,
          "address": "0xe887dc4fcb5240c0c080aeab8870421d3ebd0b28",
          "name": "RealT   14066 Santa Rosa Dr  Detroit  M",
          "symbol": "REALT-S-14066-SANTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16677/thumb/14066-Santa-Rosa-hero-1.5.jpg?1624629250"
        },
        {
          "chainId": 1,
          "address": "0x45fdb1b92a649fb6a64ef1511d3ba5bf60044838",
          "name": "SpiceUSD",
          "symbol": "USDS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25697/thumb/USDS.png?1653440948"
        },
        {
          "chainId": 1,
          "address": "0x8bcd06492416a749c9369009b3429861b7f27f6e",
          "name": "BlackHat Coin",
          "symbol": "BLKC",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png?1622537905"
        },
        {
          "chainId": 1,
          "address": "0xae12c5930881c53715b369cec7606b70d8eb229f",
          "name": "Coin98",
          "symbol": "C98",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904"
        },
        {
          "chainId": 1,
          "address": "0x396ec402b42066864c406d1ac3bc86b575003ed8",
          "name": "Buying com",
          "symbol": "BUY",
          "decimals": 2,
          "logoURI": "https://assets.coingecko.com/coins/images/5290/thumb/buying_200x200.png?1628587856"
        },
        {
          "chainId": 1,
          "address": "0x180dae91d6d56235453a892d2e56a3e40ba81df8",
          "name": "DOJO",
          "symbol": "DOJO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16396/thumb/074606deafab3872.png?1623910120"
        },
        {
          "chainId": 1,
          "address": "0x8287c7b963b405b7b8d467db9d79eec40625b13a",
          "name": "Swingby",
          "symbol": "SWINGBY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11861/thumb/swingby.png?1595822302"
        },
        {
          "chainId": 1,
          "address": "0x1d7ca62f6af49ec66f6680b8606e634e55ef22c1",
          "name": "Starter xyz",
          "symbol": "START",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386"
        },
        {
          "chainId": 1,
          "address": "0x6c7b97c7e09e790d161769a52f155125fac6d5a1",
          "name": "Polylauncher",
          "symbol": "ANGEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17739/thumb/Polylauncher_-_200_x_200.png?1629121147"
        },
        {
          "chainId": 1,
          "address": "0x31f69de127c8a0ff10819c0955490a4ae46fcc2a",
          "name": "Obyte",
          "symbol": "GBYTE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361"
        },
        {
          "chainId": 1,
          "address": "0x8848812bd31aeee33313c10a840ffc3169078c5b",
          "name": "CrossFi",
          "symbol": "CRFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png?1620006681"
        },
        {
          "chainId": 1,
          "address": "0x667fd83e24ca1d935d36717d305d54fa0cac991c",
          "name": "Collector Coin",
          "symbol": "AGS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16918/thumb/AGS.png?1625626120"
        },
        {
          "chainId": 1,
          "address": "0x9393fdc77090f31c7db989390d43f454b1a6e7f3",
          "name": "Dark Energy Crystals",
          "symbol": "DEC",
          "decimals": 3,
          "logoURI": "https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png?1603683753"
        },
        {
          "chainId": 1,
          "address": "0x2aad9dbc82611485a52325923e1187734e951b78",
          "name": "BYTZ",
          "symbol": "BYTZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/4017/thumb/bytz.png?1584519997"
        },
        {
          "chainId": 1,
          "address": "0x8861cff2366c1128fd699b68304ad99a0764ef9a",
          "name": "Cyclone Protocol",
          "symbol": "CYC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14065/thumb/b3_DFjFp_400x400.jpg?1614128740"
        },
        {
          "chainId": 1,
          "address": "0xdb726152680ece3c9291f1016f1d36f3995f6941",
          "name": "Media Network",
          "symbol": "MEDIA",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15142/thumb/media50x50.png?1620122020"
        },
        {
          "chainId": 1,
          "address": "0x34965f73cfa05bf8d8af37cb4af64fa950605ea8",
          "name": "CoinWind",
          "symbol": "COW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15766/thumb/logo.c3d2c062.png?1621827743"
        },
        {
          "chainId": 1,
          "address": "0x9ac07635ddbde5db18648c360defb00f5f22537e",
          "name": "Museum of Crypto Art",
          "symbol": "MOCA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15829/thumb/photo_2021-06-04_09.36.16.jpeg?1622770606"
        },
        {
          "chainId": 1,
          "address": "0x7645ddfeeceda57e41f92679c4acd83c56a81d14",
          "name": "Flux Protocol",
          "symbol": "FLUX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png?1619402947"
        },
        {
          "chainId": 1,
          "address": "0xf99d58e463a2e07e5692127302c20a191861b4d6",
          "name": "Anyswap",
          "symbol": "ANY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg?1598443880"
        },
        {
          "chainId": 1,
          "address": "0x9fda7ceec4c18008096c2fe2b85f05dc300f94d0",
          "name": "Gaj Finance",
          "symbol": "GAJ",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15257/thumb/logo200x200.png?1629887093"
        },
        {
          "chainId": 1,
          "address": "0xe0b0c16038845bed3fcf70304d3e167df81ce225",
          "name": "CrossSwap",
          "symbol": "CSWAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18002/thumb/cross.png?1630078540"
        },
        {
          "chainId": 1,
          "address": "0x939b462ee3311f8926c047d2b576c389092b1649",
          "name": "LiquidApps",
          "symbol": "DAPP",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/8116/thumb/dapp-logo.jpg?1554996565"
        },
        {
          "chainId": 1,
          "address": "0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d",
          "name": "Stafi",
          "symbol": "FIS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/12423/thumb/FIS.png?1681125834"
        },
        {
          "chainId": 1,
          "address": "0x154e35c2b0024b3e079c5c5e4fc31c979c189ccb",
          "name": "Raid",
          "symbol": "RAID",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18133/thumb/raid_200_oswlvz.png?1630631876"
        },
        {
          "chainId": 1,
          "address": "0x9d79d5b61de59d882ce90125b18f74af650acb93",
          "name": "Neutrino System Base",
          "symbol": "NSBT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/13268/thumb/2023-02-16_19.47.32.jpg?1676548081"
        },
        {
          "chainId": 1,
          "address": "0xdb0170e2d0c1cc1b2e7a90313d9b9afa4f250289",
          "name": "ADAPad",
          "symbol": "ADAPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg?1631181091"
        },
        {
          "chainId": 1,
          "address": "0x43044f861ec040db59a7e324c40507addb673142",
          "name": "Cap",
          "symbol": "CAP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/11775/thumb/cap.png?1673549056"
        },
        {
          "chainId": 1,
          "address": "0xc2708a3a4ba7f64bddc1a49f92f941bc77cad23a",
          "name": "Waves Ducks",
          "symbol": "EGG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17298/thumb/200x200_pixel.png?1627288546"
        },
        {
          "chainId": 1,
          "address": "0x8bbf1dccbedd5c70d8e793d432fb56b848dd1698",
          "name": "Ape In",
          "symbol": "APEIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18262/thumb/apein.PNG?1631158082"
        },
        {
          "chainId": 1,
          "address": "0xb8e3bb633f7276cc17735d86154e0ad5ec9928c0",
          "name": "VelasPad",
          "symbol": "VLXPAD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18535/thumb/11654.png?1632297834"
        },
        {
          "chainId": 1,
          "address": "0x9798df2f5d213a872c787bd03b2b91f54d0d04a1",
          "name": "TeraBlock",
          "symbol": "TBC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15622/thumb/200x200.png?1671681562"
        },
        {
          "chainId": 1,
          "address": "0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
          "name": "WOWswap",
          "symbol": "WOW",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14101/thumb/Group-423.png?1614317472"
        },
        {
          "chainId": 1,
          "address": "0x12bb890508c125661e03b09ec06e404bc9289040",
          "name": "Radio Caca",
          "symbol": "RACA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17841/thumb/ez44_BSs_400x400.jpg?1629464170"
        },
        {
          "chainId": 1,
          "address": "0xca1262e77fb25c0a4112cfc9bad3ff54f617f2e6",
          "name": "Jax Network",
          "symbol": "WJXN",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg?1633360887"
        },
        {
          "chainId": 1,
          "address": "0x968f6f898a6df937fc1859b323ac2f14643e3fed",
          "name": "Newscrypto Coin",
          "symbol": "NWC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png?1614745476"
        },
        {
          "chainId": 1,
          "address": "0xfbc4f3f645c4003a2e4f4e9b51077d2daa9a9341",
          "name": "Zedxion",
          "symbol": "ZEDXION",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png?1633585913"
        },
        {
          "chainId": 1,
          "address": "0xdb85f6685950e285b1e611037bebe5b34e2b7d78",
          "name": "Zano",
          "symbol": "ZANO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/8370/thumb/zano.png?1638343828"
        },
        {
          "chainId": 1,
          "address": "0xf32122561d51e891b823dec2b42f644884c1cd91",
          "name": "DeFido",
          "symbol": "DEFIDO",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/18861/thumb/defido.png?1635638445"
        },
        {
          "chainId": 1,
          "address": "0xf6719e1a8fcbb1b9c290019e37e004966a8916c9",
          "name": "Polygen",
          "symbol": "PGEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21476/thumb/polygen-logo_1.jpeg?1639350369"
        },
        {
          "chainId": 1,
          "address": "0x2fd8bc03d9e827f77fac20b5130ee98b7f80149d",
          "name": "CRB Coin",
          "symbol": "CRB",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/19399/thumb/crb.png?1635158925"
        },
        {
          "chainId": 1,
          "address": "0x16ba8efe847ebdfef99d399902ec29397d403c30",
          "name": "Oh  Finance",
          "symbol": "OH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19493/thumb/oh-token-logo-200.png?1635303795"
        },
        {
          "chainId": 1,
          "address": "0xd373576a9e738f37dc6882328358ff69c4caf4c6",
          "name": "Zam io",
          "symbol": "ZAM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19522/thumb/zam.png?1635324134"
        },
        {
          "chainId": 1,
          "address": "0x0106a1122fe94a9cf151097c1fe17229ec78ffad",
          "name": "Eco DeFi",
          "symbol": "ECOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG?1635374624"
        },
        {
          "chainId": 1,
          "address": "0x65e6b60ea01668634d68d0513fe814679f925bad",
          "name": "PixelVerse",
          "symbol": "PIXEL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG?1636325521"
        },
        {
          "chainId": 1,
          "address": "0x081131434f93063751813c619ecca9c4dc7862a3",
          "name": "Mines of Dalarnia",
          "symbol": "DAR",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223"
        },
        {
          "chainId": 1,
          "address": "0xac57de9c1a09fec648e93eb98875b212db0d460b",
          "name": "Baby Doge Coin",
          "symbol": "BABYDOGE",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/16125/thumb/babydoge.jpg?1676303229"
        },
        {
          "chainId": 1,
          "address": "0x55af5865807b196bd0197e0902746f31fbccfa58",
          "name": "Spookyswap",
          "symbol": "BOO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15223/thumb/logo_200x200.png?1621992076"
        },
        {
          "chainId": 1,
          "address": "0x9a257c90fa239fba07771ef7da2d554d148c2e89",
          "name": "MEDIA EYE",
          "symbol": "EYE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20643/thumb/eye.png?1656052464"
        },
        {
          "chainId": 1,
          "address": "0x9fb83c0635de2e815fd1c21b3a292277540c2e8d",
          "name": "RealFevr",
          "symbol": "FEVR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849"
        },
        {
          "chainId": 1,
          "address": "0x0343131c0257ac21ea5a8dc83841f071efd9285c",
          "name": "Zenith Chain",
          "symbol": "ZENITH",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png?1638837643"
        },
        {
          "chainId": 1,
          "address": "0xd8c1232fcd219286e341271385bd70601503b3d7",
          "name": "Dogira",
          "symbol": "DOGIRA",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/14634/thumb/IQgaRw0.png?1622439620"
        },
        {
          "chainId": 1,
          "address": "0xb6dd77fd132dcaa10f1858734e838a0fa7431580",
          "name": "Filipcoin",
          "symbol": "FCP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG?1639000706"
        },
        {
          "chainId": 1,
          "address": "0x93e32efafd24973d45f363a76d73ccb9edf59986",
          "name": "Bitlocus",
          "symbol": "BTL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/20913/thumb/btl.png?1637922710"
        },
        {
          "chainId": 1,
          "address": "0x42baf1f659d765c65ade5bb7e08eb2c680360d9d",
          "name": "Cornucopias",
          "symbol": "COPI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg?1639174968"
        },
        {
          "chainId": 1,
          "address": "0xe1590a6fa0cff9c960181cb77d8a873601772f64",
          "name": "WallStreetBets DApp",
          "symbol": "WSB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15093/thumb/Pe1mrDu.png?1629882480"
        },
        {
          "chainId": 1,
          "address": "0xdf09a216fac5adc3e640db418c0b956076509503",
          "name": "Poken",
          "symbol": "PKN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg?1639490463"
        },
        {
          "chainId": 1,
          "address": "0x45c2f8c9b4c0bdc76200448cc26c48ab6ffef83f",
          "name": "Domi",
          "symbol": "DOMI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21633/thumb/Transparent_Circle_Logo_2.png?1639627183"
        },
        {
          "chainId": 1,
          "address": "0xa87135285ae208e22068acdbff64b11ec73eaa5a",
          "name": "Lunr",
          "symbol": "LUNR",
          "decimals": 4,
          "logoURI": "https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807"
        },
        {
          "chainId": 1,
          "address": "0x965b85d4674f64422c4898c8f8083187f02b32c0",
          "name": "Filecoin Standard Full Hashrate",
          "symbol": "SFIL",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg?1639706531"
        },
        {
          "chainId": 1,
          "address": "0xa47c8bf37f92abed4a126bda807a7b7498661acd",
          "name": "Wrapped USTC",
          "symbol": "USTC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058"
        },
        {
          "chainId": 1,
          "address": "0x2f25d402829ca4085b8ea4d3bc68bf203f5a9fab",
          "name": "EagonSwap",
          "symbol": "EAGON",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180"
        },
        {
          "chainId": 1,
          "address": "0xaad483f97f13c6a20b9d05d07c397ce85c42c393",
          "name": "Woonkly Power",
          "symbol": "WOOP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14494/thumb/Icono_Infinito_Circular_Blanco_Fondo_Azul_2x.png?1658830979"
        },
        {
          "chainId": 1,
          "address": "0xaf9db9e362e306688af48c4acb9618c06db38ac3",
          "name": "ACY Finance",
          "symbol": "ACY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg?1640214613"
        },
        {
          "chainId": 1,
          "address": "0xbd100d061e120b2c67a24453cf6368e63f1be056",
          "name": "iDypius",
          "symbol": "IDYP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21976/thumb/iDYP-200x200px.png?1671016252"
        },
        {
          "chainId": 1,
          "address": "0x77a1f4e744d810239f465043e35d067ca33de259",
          "name": "Voice Street",
          "symbol": "VST",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg?1643348841"
        },
        {
          "chainId": 1,
          "address": "0xe57425f1598f9b0d6219706b77f4b3da573a3695",
          "name": "Bitcoin BR",
          "symbol": "BTCBR",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691"
        },
        {
          "chainId": 1,
          "address": "0x7118057ff0f4fd0994fb9d2d94de8231d5cca79e",
          "name": "ReSource Protocol",
          "symbol": "SOURCE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20740/thumb/source.png?1637749215"
        },
        {
          "chainId": 1,
          "address": "0x9ab70e92319f0b9127df78868fd3655fb9f1e322",
          "name": "WeWay",
          "symbol": "WWY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22418/thumb/wwy.png?1661499324"
        },
        {
          "chainId": 1,
          "address": "0x86d49fbd3b6f989d641e700a15599d3b165002ab",
          "name": "HUH",
          "symbol": "HUH",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21549/thumb/huh.png?1639462106"
        },
        {
          "chainId": 1,
          "address": "0x25b24b3c47918b7962b3e49c4f468367f73cc0e0",
          "name": "AXL INU",
          "symbol": "AXL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png?1641188792"
        },
        {
          "chainId": 1,
          "address": "0xbba6c7c7d673c48d90069ad2e9d2fe587fcb6bc3",
          "name": "Lepasa",
          "symbol": "LEPA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975"
        },
        {
          "chainId": 1,
          "address": "0xa693b19d2931d498c5b318df961919bb4aee87a5",
          "name": "TerraUSD  Wormhole ",
          "symbol": "UST",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png?1644223065"
        },
        {
          "chainId": 1,
          "address": "0x845e2e8b42dced7dedcdba9bde32c9e338224f97",
          "name": "Satozhi",
          "symbol": "SATOZ",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg?1618416420"
        },
        {
          "chainId": 1,
          "address": "0x2f75113b13d136f861d212fa9b572f2c79ac81c4",
          "name": "Ekta",
          "symbol": "EKTA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22350/thumb/token.jpg?1641882684"
        },
        {
          "chainId": 1,
          "address": "0xc669928185dbce49d2230cc9b0979be6dc797957",
          "name": "BitTorrent",
          "symbol": "BTT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22457/thumb/btt_logo.png?1643857231"
        },
        {
          "chainId": 1,
          "address": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
          "name": "Smooth Love Potion",
          "symbol": "SLP",
          "decimals": 0,
          "logoURI": "https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057"
        },
        {
          "chainId": 1,
          "address": "0xc4bb7277a74678f053259cb1f96140347efbfd46",
          "name": "Coin of the champions",
          "symbol": "COC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18478/thumb/COC-Yellow-Transparent-1.png?1632148454"
        },
        {
          "chainId": 1,
          "address": "0x9b17baadf0f21f03e35249e0e59723f34994f806",
          "name": "NFTmall",
          "symbol": "GEM",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471"
        },
        {
          "chainId": 1,
          "address": "0xee9801669c6138e84bd50deb500827b776777d28",
          "name": "O3 Swap",
          "symbol": "O3",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316"
        },
        {
          "chainId": 1,
          "address": "0x7825e833d495f3d1c28872415a4aee339d26ac88",
          "name": "Wrapped Telos",
          "symbol": "WTLOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23952/thumb/tL4cEmvt_400x400.png?1645772510"
        },
        {
          "chainId": 1,
          "address": "0x2217e5921b7edfb4bb193a6228459974010d2198",
          "name": "Qmall",
          "symbol": "QMALL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg?1644891636"
        },
        {
          "chainId": 1,
          "address": "0xd3c51de3e6dd9b53d7f37699afb3ee3bf9b9b3f4",
          "name": "MContent",
          "symbol": "MCONTENT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg?1653476766"
        },
        {
          "chainId": 1,
          "address": "0x1f36fb2d91d9951cf58ae4c1956c0b77e224f1e9",
          "name": "VCGamers",
          "symbol": "VCG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png?1643187590"
        },
        {
          "chainId": 1,
          "address": "0xe580074a10360404af3abfe2d524d5806d993ea3",
          "name": "PayBolt",
          "symbol": "PAY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269"
        },
        {
          "chainId": 1,
          "address": "0x723bd1f87a327e94ceabf68d8f022e0f54b9cc1a",
          "name": "Philcoin",
          "symbol": "PHL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24178/thumb/2ZFyoMSk.png?1646901982"
        },
        {
          "chainId": 1,
          "address": "0x22c5543d1a35178cb03b33f929a959145e538532",
          "name": "The Wasted Lands",
          "symbol": "WAL",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png?1647178350"
        },
        {
          "chainId": 1,
          "address": "0x20d60c6eb195868d4643f2c9b0809e4de6cc003d",
          "name": "PlayNity",
          "symbol": "PLY",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21479/thumb/ply.png?1639651574"
        },
        {
          "chainId": 1,
          "address": "0x809e130e10e787139c54e1d12d3d1971b7a675bf",
          "name": "Minted",
          "symbol": "MTD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26893/thumb/MTD_Token_%282%29.png?1660708482"
        },
        {
          "chainId": 1,
          "address": "0x1ab43204a195a0fd37edec621482afd3792ef90b",
          "name": "Aurigami",
          "symbol": "PLY",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24074/thumb/EbB5N8IN_400x400.jpg?1646230298"
        },
        {
          "chainId": 1,
          "address": "0x525ef76138bf76118d786dbedeae5f87aabf4a81",
          "name": "Scalara NFT Index",
          "symbol": "NFTI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23647/thumb/NFTI-logo-white-bg.png?1644910969"
        },
        {
          "chainId": 1,
          "address": "0xf655c8567e0f213e6c634cd2a68d992152161dc6",
          "name": "Impermax",
          "symbol": "IBEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27606/thumb/IqwOmX-c_400x400.jpeg?1664846656"
        },
        {
          "chainId": 1,
          "address": "0x19193f450086d0442157b852081976d41657ad56",
          "name": "Nunu Spirits",
          "symbol": "NNT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059"
        },
        {
          "chainId": 1,
          "address": "0xc17c30e98541188614df99239cabd40280810ca3",
          "name": "EverRise",
          "symbol": "RISE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670"
        },
        {
          "chainId": 1,
          "address": "0x32462ba310e447ef34ff0d15bce8613aa8c4a244",
          "name": "Dohrnii",
          "symbol": "DHN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24966/thumb/dhn.png?1649586405"
        },
        {
          "chainId": 1,
          "address": "0x198d14f2ad9ce69e76ea330b374de4957c3f850a",
          "name": "APENFT",
          "symbol": "NFT",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/15687/thumb/apenft.jpg?1621562368"
        },
        {
          "chainId": 1,
          "address": "0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd",
          "name": "Sombra",
          "symbol": "SMBR",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204"
        },
        {
          "chainId": 1,
          "address": "0x446f2a8a39cc730ef378be759a3c57f1a3fe824c",
          "name": "NanoByte",
          "symbol": "NBT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/23698/thumb/WpcmdLW.png?1645080885"
        },
        {
          "chainId": 1,
          "address": "0x420a24c9c65bd44c48bfb1cc8d6cd1ea8b1ac840",
          "name": "JumpToken",
          "symbol": "JMPT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22603/thumb/200x200.png?1642162597"
        },
        {
          "chainId": 1,
          "address": "0x4550003152f12014558e5ce025707e4dd841100f",
          "name": "Kaizen Finance",
          "symbol": "KZEN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756"
        },
        {
          "chainId": 1,
          "address": "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6",
          "name": "USDD",
          "symbol": "USDD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25380/thumb/UUSD.jpg?1651823371"
        },
        {
          "chainId": 1,
          "address": "0xb5be7557fe8f69a2b5707d25fa0aee80dfda512e",
          "name": "POLYSPORTS",
          "symbol": "PS1",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg?1650024620"
        },
        {
          "chainId": 1,
          "address": "0xfceb206e1a80527908521121358b5e26caabaa75",
          "name": "Main",
          "symbol": "MAIN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25258/thumb/logo_circle.png?1651042603"
        },
        {
          "chainId": 1,
          "address": "0x614d7f40701132e25fe6fc17801fbd34212d2eda",
          "name": "SafeBlast",
          "symbol": "BLAST",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png?1621561206"
        },
        {
          "chainId": 1,
          "address": "0x8fc8f8269ebca376d046ce292dc7eac40c8d358a",
          "name": "DeFiChain",
          "symbol": "DFI",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/11757/thumb/symbol-defi-blockchain_200.png?1597306538"
        },
        {
          "chainId": 1,
          "address": "0xf6e06b54855eff198a2d9a8686113665499a6134",
          "name": "Celestial",
          "symbol": "CELT",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18683/thumb/celt.png?1632966755"
        },
        {
          "chainId": 1,
          "address": "0x9767203e89dcd34851240b3919d4900d3e5069f1",
          "name": "A4 Finance",
          "symbol": "A4",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786"
        },
        {
          "chainId": 1,
          "address": "0xd35c06a2781f648c75290976ecf71e71582188b7",
          "name": "Quarashi",
          "symbol": "QUA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25589/thumb/Lk2A7ta.png?1652769197"
        },
        {
          "chainId": 1,
          "address": "0x7a5d3a9dcd33cb8d527f7b5f96eb4fef43d55636",
          "name": "RadioShack",
          "symbol": "RADIO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260"
        },
        {
          "chainId": 1,
          "address": "0x26dcfbfa8bc267b250432c01c982eaf81cc5480c",
          "name": "Ankr Staked MATIC",
          "symbol": "ANKRMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25742/thumb/a-matic-c-da4ec10dc9723e695700e25dbf8c8edf.png?1653462321"
        },
        {
          "chainId": 1,
          "address": "0xafcdd4f666c84fed1d8bd825aa762e3714f652c9",
          "name": "Vita Inu",
          "symbol": "VINU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20594/thumb/vita-inu-head-wallet-icon-transparent.png?1637286122"
        },
        {
          "chainId": 1,
          "address": "0x9e6b19874e97fe8e8cad77f2c0ab5e7a693e5dbf",
          "name": "StrongHands Finance",
          "symbol": "ISHND",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898"
        },
        {
          "chainId": 1,
          "address": "0x2b95a1dcc3d405535f9ed33c219ab38e8d7e0884",
          "name": "Aladdin cvxCRV",
          "symbol": "ACRV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg?1651707422"
        },
        {
          "chainId": 1,
          "address": "0x5f018e73c185ab23647c82bd039e762813877f0e",
          "name": "Shack",
          "symbol": "SHACK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433"
        },
        {
          "chainId": 1,
          "address": "0x40fed5691e547885cabd7a2990de719dcc8497fc",
          "name": "Safe Haven",
          "symbol": "SHA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471"
        },
        {
          "chainId": 1,
          "address": "0x18a1ea69a50a85752b7bc204a2c45a95ce6e429d",
          "name": "Spice Trade",
          "symbol": "SPICE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25770/thumb/SPICE.png?1653607602"
        },
        {
          "chainId": 1,
          "address": "0xcafe34bae6f1b23a6b575303edcc0578d2188131",
          "name": "Minter Network",
          "symbol": "BIP",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/9982/thumb/Nvoj_6Mu_400x400.jpg?1587968303"
        },
        {
          "chainId": 1,
          "address": "0x57d579f483854c62fef850b8a5332b0d8424b7e2",
          "name": "OpenSwap One",
          "symbol": "OPENX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/19274/thumb/X_color.png?1654833718"
        },
        {
          "chainId": 1,
          "address": "0x80cd73badb406ea36b9a7cdeb8df06aefa7e12d9",
          "name": "SleepFuture",
          "symbol": "SLEEPEE",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png?1651916574"
        },
        {
          "chainId": 1,
          "address": "0x93581991f68dbae1ea105233b67f7fa0d6bdee7b",
          "name": "Evmos",
          "symbol": "EVMOS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24023/thumb/evmos.png?1653958927"
        },
        {
          "chainId": 1,
          "address": "0x4c3a8eceb656ec63eae80a4ebd565e4887db6160",
          "name": "SokuSwap",
          "symbol": "SOKU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png?1653547690"
        },
        {
          "chainId": 1,
          "address": "0x88536c9b2c4701b8db824e6a16829d5b5eb84440",
          "name": "Atlas USV",
          "symbol": "USV",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png?1640744823"
        },
        {
          "chainId": 1,
          "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
          "name": "Elk Finance",
          "symbol": "ELK",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17813/thumb/elk.png?1629336971"
        },
        {
          "chainId": 1,
          "address": "0x2e7b0d4f9b2eaf782ed3d160e3a0a4b1a7930ada",
          "name": "Ceres",
          "symbol": "CERES",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/17959/thumb/sQLDgqx.png?1648442923"
        },
        {
          "chainId": 1,
          "address": "0x22ad96acf613539d7900b5ec6e0e65dbcc668d2a",
          "name": "Xerium",
          "symbol": "XERM",
          "decimals": 7,
          "logoURI": "https://assets.coingecko.com/coins/images/26284/thumb/Xerium_Logo_200x200_PNG.png?1657077128"
        },
        {
          "chainId": 1,
          "address": "0x92df60c51c710a1b1c20e42d85e221f3a1bfc7f2",
          "name": "ApeSwap",
          "symbol": "BANANA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/14870/thumb/banana.png?1648887183"
        },
        {
          "chainId": 1,
          "address": "0xc72633f995e98ac3bb8a89e6a9c4af335c3d6e44",
          "name": "Omnisea",
          "symbol": "OSEA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766"
        },
        {
          "chainId": 1,
          "address": "0x2b867efd2de4ad2b583ca0cb3df9c4040ef4d329",
          "name": "Lucky Block",
          "symbol": "LBLOCK",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png?1643268144"
        },
        {
          "chainId": 1,
          "address": "0xe3c408bd53c31c085a1746af401a4042954ff740",
          "name": "STEPN",
          "symbol": "GMT",
          "decimals": 8,
          "logoURI": "https://assets.coingecko.com/coins/images/23597/thumb/gmt.png?1644658792"
        },
        {
          "chainId": 1,
          "address": "0x2c4f1df9c7de0c59778936c9b145ff56813f3295",
          "name": "AssetMantle",
          "symbol": "MNTL",
          "decimals": 6,
          "logoURI": "https://assets.coingecko.com/coins/images/25181/thumb/thumbnail.png?1658821784"
        },
        {
          "chainId": 1,
          "address": "0x57b59f981730c6257df57cf6f0d98283749a9eeb",
          "name": "BUILD",
          "symbol": "BUILD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842"
        },
        {
          "chainId": 1,
          "address": "0xde16ce60804a881e9f8c4ebb3824646edecd478d",
          "name": "MagicCraft",
          "symbol": "MCRT",
          "decimals": 9,
          "logoURI": "https://assets.coingecko.com/coins/images/21318/thumb/mcrt.png?1638932009"
        },
        {
          "chainId": 1,
          "address": "0x839e71613f9aa06e5701cf6de63e303616b0dde3",
          "name": "VVS Finance",
          "symbol": "VVS",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/20210/thumb/8glAYOTM_400x400.jpg?1636667919"
        },
        {
          "chainId": 1,
          "address": "0x8616e8ea83f048ab9a5ec513c9412dd2993bce3f",
          "name": "handleUSD",
          "symbol": "FXUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26954/thumb/fxUSDlogo.png?1677465474"
        },
        {
          "chainId": 1,
          "address": "0x256d1fce1b1221e8398f65f9b36033ce50b2d497",
          "name": "Alvey Chain",
          "symbol": "WALV",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png?1661079637"
        },
        {
          "chainId": 1,
          "address": "0xb28f803a8772e6584a65ab6dfc535ae6fef8a0b2",
          "name": "Lunafi",
          "symbol": "LFI",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24594/thumb/lfi.png?1648310927"
        },
        {
          "chainId": 1,
          "address": "0xc285b7e09a4584d027e5bc36571785b515898246",
          "name": "Coin98 Dollar",
          "symbol": "CUSD",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049"
        },
        {
          "chainId": 1,
          "address": "0x2f32b39023da7d6a6486a85d12b346eb9c2a0d19",
          "name": "Ferro",
          "symbol": "FER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26111/thumb/FER_Token.png?1655887206"
        },
        {
          "chainId": 1,
          "address": "0xd6e460f70e1cf60e55b770f66e568b44bf3657d0",
          "name": "Funex",
          "symbol": "FUNEX",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png?1660276769"
        },
        {
          "chainId": 1,
          "address": "0x8d6cebd76f18e1558d4db88138e2defb3909fad6",
          "name": "MAI",
          "symbol": "MIMATIC",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018"
        },
        {
          "chainId": 1,
          "address": "0x97bbbc5d96875fb78d2f14b7ff8d7a3a74106f17",
          "name": "Astrafer",
          "symbol": "ASTRAFER",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/26246/thumb/ATSRA_Token.png?1657276411"
        },
        {
          "chainId": 1,
          "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
          "name": "BOB",
          "symbol": "BOB",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27266/thumb/Bob-logo.png?1663073030"
        },
        {
          "chainId": 1,
          "address": "0xa719cb79af39a9c10eda2755e0938bce35e9de24",
          "name": "Starfish Finance",
          "symbol": "SEAN",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27533/thumb/SEAN_Token_icon.png?1664411041"
        },
        {
          "chainId": 1,
          "address": "0x3b79a28264fc52c7b4cea90558aa0b162f7faf57",
          "name": "Wonderful Memories",
          "symbol": "WMEMO",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png?1665832927"
        },
        {
          "chainId": 1,
          "address": "0xe4a6f23fb9e00fca037aa0ea0a6954de0a6c53bf",
          "name": "tGOLD",
          "symbol": "TXAU",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27828/thumb/tGOLD_token_2D.jpg?1666165789"
        },
        {
          "chainId": 1,
          "address": "0x34abce75d2f8f33940c721dca0f562617787bff3",
          "name": "tSILVER",
          "symbol": "TXAG",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/27829/thumb/tSILVER_token_2D.jpg?1666165851"
        },
        {
          "chainId": 1,
          "address": "0x967fb0d760ed3ce53afe2f0a071674cccae73550",
          "name": "XANA",
          "symbol": "XETA",
          "decimals": 18,
          "logoURI": "https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388"
        }
      ]

}
