import MyRouts from './routers/routes'

function App() {
  return (
    <>
      <MyRouts />     
    </>
  );
}

export default App;